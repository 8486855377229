import React, { useRef, useState, useEffect } from 'react'
import EmailEditor from 'react-email-editor'
import { Row, Col, CustomInput, Label, FormGroup, Container, Button } from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'
import ReactSelect from 'react-select'

// import DefaultJSON from '../Template/default.json'
// import TemplateJSON from '../Template/template1.json'
// import Template2 from '../Template/template3.json'

// import Datepicker from '../../components/DatePicker'
// import MediaDatabase from '../MediaDatabase'

// import WithMediaDatabases from '../MediaDatabase/actions'
import Lang from 'Lang/General'

const ArkEmailEditor = props => {
  const emailEditorRef = useRef(null);
  const [ showMediaDatabase, updateShowMediaDatabase ] = useState( false )
  const [ selectedImage, updateSelectedImage ] = useState( '' )

  const loadTemplate = ( template ) => emailEditorRef.current.editor.loadDesign ( typeof template === 'string' ? JSON.parse( template ) : template )

  const onLoad = () => {
    if( emailEditorRef.current ) {
      loadInstance()
    } else {
      setTimeout(() => {
        loadInstance()
      }, 3000);
    }
  };

  const loadInstance = () => {
    if( !_.isEmpty( props.data.json_content ) ){
      loadTemplate( props.data.json_content )
    }

    Promise.all([
      props.onChangeMailingListingHOC( 'emailEditor', emailEditorRef.current.editor )
    ]).then(
      props.onChangeMailingListingHOC( 'submitButton', true )
    )
    props.onChangeMailingListingHOC( 'emailEditor', emailEditorRef.current.editor )
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <EmailEditor
            ref={ emailEditorRef }
            onLoad={ onLoad }
            options={{
              version: 'latest',
              customJS: [ `${ window.location.origin }/custom.js` ],
              locale: props.selectedLanguage,
              features: {
                stockImages: {
                  enabled: false
                }
              },
              tools: {
                image: {
                  properties: {
                    src: {
                      value: {
                        url: 'https://via.placeholder.com/500x100?text=IMAGE',
                        width: 500,
                        height: 100
                      }
                    }
                  }
                },
                'custom#content_service': {
                  data: {
                    options: []
                  },
                  properties: {
                    contentServices: {
                      editor: {
                        data: {
                          label: 'Content Services',
                          options: []
                        }
                      }
                    },
                    centerNews: {
                      editor: {
                        data: {
                          label: 'News',
                          options: []
                        }
                      }
                    }
                  }
                }
              }
            }}
            projectId={ 28706 }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={ 6 }>
          {
            props.processType === 'create' && <>
              <FormGroup className="mt-3">
                <CustomInput
                  id='checkbox-do-save-json'
                  type="checkbox"
                  label={ Lang[ 'SAVE_TEMPLATE' ][ props.selectedLanguage ] }
                  checked={ props.data.do_save_json }
                  onChange={ () => props.onChangeData( 'do_save_json', !props.data.do_save_json ) } />
              </FormGroup>
            </>
          }
          <FormGroup>
            {/*<CustomInput
              id='radio-do-send-now'
              type='radio'
              label={ Lang[ 'SEND_NOW' ][ props.selectedLanguage ] }
              checked={ props.data.do_send_now }
              onChange={ () => props.onChangeData( 'do_send_now' ) } />*/}
            <CustomInput
              id='radio-is-draft'
              type='checkbox'
              label={ 'is draft' }
              checked={ props.data.is_draft }
              onChange={ () => props.onChangeData( 'is_draft' ) } />
            {/*<CustomInput
              id='radio-is-scheduled'
              type='radio'
              label={ 'is scheduled' }
              checked={ props.data.is_scheduled }
              onChange={ () => props.onChangeData( 'is_scheduled' ) } />*/}
          </FormGroup>
          {
            // props.data.is_scheduled &&
            //   <FormGroup>
            //     <Label>{ Lang[ 'DELIVER_AT' ][ props.selectedLanguage ] }</Label>
            //     <Datepicker
            //       showTimeSelect
            //       selectedDate={ props.data.delivered_at.toDate() }
            //       dateFormat='dd/MM/yyyy, hh:mm a'
            //       onChange={ e => props.onChangeData( 'delivered_at',  Moment( e ) ) }/>
            //   </FormGroup>
          }
        </Col>
      </Row>
      {/*<MediaDatabase
        emailEditorRef={ emailEditorRef }
        images={ props.images }
        showMediaDatabase={ showMediaDatabase }
        updateShowMediaDatabase={ updateShowMediaDatabase }
        data={ props.data }
        selectedImage={ selectedImage }
        updateSelectedImage={ updateSelectedImage } />*/}
    </Container>
  )
}

export default ArkEmailEditor
