import { STORE_LANG } from 'actions/type'

const initialState = {
  userSettings: {}
}

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case STORE_LANG:
      return {
        ...state,
        userSettings: action.payload
      }
    default: return state
  }
}
