import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const MailingTemplateHOC = ( WrappedComponent ) => {
  class MailingTemplatesWrappedComponent extends Component{
    state = {
      loading: false,
      mailingTemplates: [],
      totalPagesMailingTemplates: [],
      showCreateMailingTemplate: false,
      newMailingTemplateData: {
        name: '',
        html_content: '',
        json_content: {},
        image: {
          raw: '',
        },
        is_global: false
      },
      files: [],
      selectedMailingTemplate: {
        name: '',
        html_content: '',
        json_content: {},
        image: {
          url: '',
          raw: ''
        },
        is_global: false
      },
      showUpdateMailingTemplate: false,
      mailTemplateErrMsg: {},
      name: '',
      template_id: 0
    }

    onChangeSystemMailingTemplateHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      // outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getAllMailingTemplates = (query) => Get(
      `/api/v1/funnel_mailing_templates?query=${query}`,
      this.getAllMailingTemplatesSuccess,
      this.getAllMailingTemplatesError,
      this.load
    )
    getAllMailingTemplatesSuccess = payload => {
      let tmpForSelect = []
      if( payload.data && payload.data.length > 0 ){
        payload.data.map( item => {
          tmpForSelect.push({
            ...item,
            label: item.name[this.props.data.languageReducer.lang],
            value: item.name[this.props.data.languageReducer.lang]
          })
        })
      }
      this.setState({ mailingTemplates: tmpForSelect })
    }
    getAllMailingTemplatesError = error => this.requestError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadMailingTemplate={ this.state.loading }
            mailingTemplates={ this.state.mailingTemplates }
            getAllMailingTemplates={ this.getAllMailingTemplates }
            selectedMailingTemplate={ this.state.selectedMailingTemplate }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( MailingTemplatesWrappedComponent )
}

export default MailingTemplateHOC
