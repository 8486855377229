import React, { useState, useEffect } from 'react'
import { Card, CardBody, Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap'
import { Tooltip, ResponsiveContainer, LineChart, Line, XAxis, YAxis } from 'recharts'
import { cloneDeep } from 'lodash'
import ReactTable from 'react-table'

import Lang from 'Lang/General'

const Overview = props => {
  const [ realtimeVisits, setRealtimeVisits ] = useState([
    {
      visits: 0,
      actions: 0,
      date: Lang[ 'LAST_24_HOURS' ][ props.data.languageReducer.userSettings.admin_panel_language ]
    },
    {
      visits: 0,
      actions: 0,
      date: Lang[ 'LAST_30_MINUTES' ][ props.data.languageReducer.userSettings.admin_panel_language ]
    }
  ])

  useEffect( () => {
    if( props.matomoVisitsLastHalfHour[ 0 ] || props.matomoVisitsYesterday[ 0 ] ){
      let tmpRealtimeVisits = cloneDeep( realtimeVisits )

      if( props.matomoVisitsYesterday[ 0 ] ){
        tmpRealtimeVisits[ 0 ] = {
          ...tmpRealtimeVisits[ 0 ],
          ...props.matomoVisitsYesterday[ 0 ]
        }
      }
      if( props.matomoVisitsLastHalfHour[ 0 ] ){
        tmpRealtimeVisits[ 1 ] = {
          ...tmpRealtimeVisits[ 1 ],
          ...props.matomoVisitsLastHalfHour[ 0 ]
        }
      }

      setRealtimeVisits( tmpRealtimeVisits )
    }
  }, [ props.matomoVisitsLastHalfHour, props.matomoVisitsYesterday ] )

  return(
    <>
      <ResponsiveContainer width="100%" height={ 350 } className='mb-4' >
        <LineChart data={ props.matomoVisitsGraph } >
          <XAxis 
            dataKey="date" 
            tickMargin={ 8 }
            interval="equidistantPreserveStart"
            padding={{ left: 40, right: 20 }}
          />
          <YAxis 
            tickCount={ 10 } 
            tickMargin={ 8 }
            interval="preserveStartEnd" 
            padding={{ top: 60, bottom: 40 }} 
            domain={ ( [ dataMin, dataMax ] ) => { return [ Math.max( 0, dataMin-20 ), dataMax+20 ] } }
            label={{ 
              offset: 0,
              position: 'insideTopLeft',
              value: Lang[ "VISITS" ][ props.data.languageReducer.userSettings.admin_panel_language ]
            }}
          />
          <Tooltip />
          <Line type="monotone" dataKey="nb_visits" name={ Lang[ "VISITS" ][ props.data.languageReducer.userSettings.admin_panel_language ] } stroke="#04244c" strokeWidth={ 2 } activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
      <Label className='mb-4' style={{ fontSize: "150%" }} >
        <strong>
          { Lang[ 'VISITS_IN_REAL_TIME' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        </strong>
      </Label>
      <ReactTable
        style={{ marginBottom: "3rem" }}
        showPagination={ false }
        defaultPageSize={ 2 }
        NoDataComponent={ () => <span></span> }
        data={ realtimeVisits }
        columns={[
          {
            Header: Lang[ 'DATE' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            accessor: 'date'
          },
          {
            Header: Lang[ 'VISITS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            accessor: 'visits'
          },
          {
            Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            accessor: 'actions'
          },
        ]}
      />
      <Label className='mb-4' style={{ fontSize: "150%" }} >
        <strong>
          { Lang[ 'VISITOR_LOG' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        </strong>
      </Label>
      {
        props.matomoVisitorLogs.map( ( visitor, index ) => (
          <Card className='mb-2' >
            <CardBody>
              <Row className='m-0' >
                <Col md={ 3 } >
                  <Row>
                    <Label style={{ fontWeight: "500" }}>{ `${ visitor.serverDatePretty } - ${ visitor.serverTimePretty }` }</Label>
                  </Row>
                  <Row>
                    <Label>
                      { Lang[ 'TIME_ON_SITE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }:{ ` ${ visitor.visitDurationPretty }` }
                    </Label>
                  </Row>
                </Col>
                <Col md={ 3 } >
                  <Row>
                    <Label style={{ fontWeight: "500" }}>{ visitor.location }</Label>
                  </Row>
                  <Row>
                    <Label>
                      { `IP: ${ visitor.visitIp }` }
                    </Label>
                  </Row>
                </Col>
                <Col md={ 2 } >
                  <Row>
                    <img
                      className='mr-2 mb-4'
                      id={ `visitor-browser-${ index }` }
                      src={ `https://matomo.aciso-suite.com/${ visitor.browserIcon }` }
                      style={{ 
                        width: '20px', 
                        objectFit: "contain",
                        objectPosition: "bottom left",
                      }}
                    />
                    <UncontrolledTooltip
                      style={{ textAlign: 'left' }}
                      target={ `visitor-browser-${ index }` }
                      placement="bottom ">
                      <p className='mb-0' >{ `${ Lang[ 'BROWSER' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ visitor.browser }` }</p>
                      <p className='mb-0' >{ `${ Lang[ 'BROWSER_ENGINE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ visitor.browserFamily }` }</p>
                      <span className='mr-2' >{ Lang[ 'PLUGINS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</span>
                      {
                        visitor.pluginsIcons.map( icon => (
                          <img 
                            className='mr-2' 
                            src={ `https://matomo.aciso-suite.com/${ icon.pluginIcon }` }
                            style={{ 
                              width: '15px', 
                              objectFit: "contain",
                              objectPosition: "bottom left"
                            }}
                          />
                        ))
                      }
                    </UncontrolledTooltip>
                    <img
                      className='mr-2 mb-4'
                      id={ `visitor-os-${ index }` }
                      src={ `https://matomo.aciso-suite.com/${ visitor.operatingSystemIcon }` }
                      style={{ 
                        width: '20px',
                        objectFit: "contain",
                        objectPosition: "bottom left",
                      }}
                    />
                    <UncontrolledTooltip
                      style={{ textAlign: 'left' }}
                      target={ `visitor-os-${ index }` }
                      placement="bottom ">
                      <p className='mb-0' >{ `${ Lang[ 'OPERATING_SYSTEM' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ visitor.operatingSystem }` }</p>
                    </UncontrolledTooltip>
                    <img
                      className='mr-2 mb-4'
                      id={ `visitor-device-${ index }` }
                      src={ `https://matomo.aciso-suite.com/${ visitor.deviceTypeIcon }` }
                      style={{ 
                        width: '20px', 
                        objectFit: "contain",
                        objectPosition: "bottom left",
                      }}
                    />
                    <UncontrolledTooltip
                      style={{ textAlign: 'left' }}
                      target={ `visitor-device-${ index }` }
                      placement="bottom ">
                      <p className='mb-0' >{ `${ Lang[ 'DEVICE_TYPE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ visitor.deviceType }` }</p>
                      <p className='mb-0' >{ `${ Lang[ 'DEVICE_BRAND' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ visitor.deviceBrand }` }</p>
                      <p className='mb-0' >{ `${ Lang[ 'DEVICE_MODEL' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ visitor.deviceModel }` }</p>
                      <p className='mb-0' >{ `${ Lang[ 'RESOLUTION' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ visitor.resolution }` }</p>
                    </UncontrolledTooltip>
                  </Row>
                </Col>
                <Col md={ 4 } >
                  {
                    visitor.actionDetails.map( ( action, index ) => (
                      <>
                        <Row 
                          style={{ position: "relative" }}
                          className='mb-3 flex flex-row flex-nowrap' >
                          {
                            ( index !== visitor.actionDetails.length - 1 ) && (
                              <div 
                                style={{ 
                                  position: "absolute",
                                  top: "25px",
                                  left: "8.5px",
                                  width: '3px',
                                  height: "70%",
                                  backgroundColor: '#000'
                                }} 
                              />
                            )
                          }
                          <img
                            className='mb-4 mr-2'
                            src={ `https://matomo.aciso-suite.com/${ action.iconSVG }` }
                            style={{ 
                              width: '20px', 
                              objectFit: "contain",
                              objectPosition: "top center"
                            }}
                          />
                          <div className='d-flex flex-column flex-1' >
                            {
                              action.pageTitle && (
                                <p style={{ fontWeight: "500" }}>{ action.pageTitle }</p>
                              )
                            }
                            <p style={{ overflow: 'hidden', wordBreak: "break-word" }} >
                              { action.url?.replace( props.siteDomain, '' ).replace( 'https://', '') }
                            </p>
                          </div>
                        </Row>
                      </>
                    ))
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))
      }
      {
        props.matomoVisitorLogs.length > 0 && (
          <Button  
            color='primary'
            className='w-100'
            onClick={ () => props.getMatomoStats({ 
              ...props.matomoSearchParams, 
              filterOffset: props.matomoSearchParams.filterOffset + props.PAGE_SIZE
            })}>
            { Lang[ 'MORE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
          </Button>
        )
      }
    </>
  )
}

export default Overview