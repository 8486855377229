import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Label
} from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import AlertNotSubscribe from 'components/Alert'
import BulkUploadHOC from './actions'
import RecipientGroupHOC from 'containers/Dashboard/MarketingFunnels/Groups/actions'
import Summary from './Summary'

import Lang from 'Lang/General'

import 'filepond/dist/filepond.min.css'
registerPlugin( FilePondPluginFileValidateType )

const BulkUploadListings = props => {
  const [ subscribedPackage, setSubscribePackage ] = useState( true )

  useEffect( () => {
    let tmpIndex = _.findIndex( props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Marketing Funnel' })
    setSubscribePackage( tmpIndex > -1 )
    props.getAllRecipientGroup()
  }, [])

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_create && subscribedPackage
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang['BULK_UPLOAD_RECIPIENT'][props.data.languageReducer.userSettings.admin_panel_language] }
        icon="pe-7s-cloud-upload icon-gradient bg-happy-itmeo"
        actionsButtons={[]} />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          {
            !subscribedPackage && (
              <AlertNotSubscribe { ...props } />
            )
          }
          <Row>
            <Col md={ 12 }>
              <Card body className="card-shadow-primary mb-3">
                <Form onSubmit={ e => e.preventDefault() }>
                  <FormGroup className="mt-4">
                    <Label>{ Lang['CHOOSE_FILE'][props.data.languageReducer.userSettings.admin_panel_language] }<span style={{ color: 'red'}}>*</span></Label>
                    {
                      props.bulkData.raw !== '' && <span style={{ color: 'green', marginLeft: '15px' }}>{ Lang['UPLOAD_COMPLETED'][props.data.languageReducer.userSettings.admin_panel_language]}</span>
                    }
                    <FilePond
                      files={ props.files }
                      acceptedFileTypes={ ['text/csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] }
                      allowFileTypeValidation={ true }
                      onupdatefiles={ fileItems => {
                        if( fileItems.length > 0 ) {
                          let reader = new FileReader()
                          reader.onload = e => {
                            let tmp = _.cloneDeep( props.bulkData )
                            let index = reader.result.indexOf(',')
                            let tmpBase64 = reader.result.substring( index + 1 )
                            tmp['raw'] = tmpBase64
                            tmp['type'] = fileItems[0].fileExtension
                            Promise.all([
                              props.onChangeBulkHOC( 'files', fileItems )
                            ]).then(
                              props.onChangeBulkHOC( 'bulkData', tmp )
                            )
                          }
                          reader.readAsDataURL( fileItems[0].file )
                        }
                      }}
                      allowMultiple={false}
                    />
                  </FormGroup>
                  <FormGroup className="mt-4">
                    <Label>{ Lang['RECIPIENT_GROUP'][props.data.languageReducer.userSettings.admin_panel_language] }<span style={{ color: 'red'}}>*</span></Label>
                    <ReactSelect
                      options={ props.recipientGroupForSelect }
                      value={ props.bulkData.recipient_groups }
                      isMulti
                      onChange={ value => {
                        let tmp = _.cloneDeep( props.bulkData )
                        tmp['recipient_groups'] = value
                        props.onChangeBulkHOC( 'bulkData', tmp )
                      }}/>
                  </FormGroup>
                </Form>
                <Button
                  className="mt-3"
                  disabled={ !handleSecurityModulesCreate() }
                  onClick={ () => {
                    if( _.isEmpty( props.bulkData.recipient_groups) ){
                      toast.error( Lang['RECIPIENT_GROUP_REQUIRED'][props.data.languageReducer.userSettings.admin_panel_language] )
                    } else {
                      props.createBulkRecipient( props.bulkData )
                    }
                  }} 
                  color="primary">
                  { Lang['SUBMIT'][props.data.languageReducer.userSettings.admin_panel_language] }
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>
        <Summary {...props} />
        { props.onLoadBulkUpload && <LoadingOverlay/> }
      </ReactCSSTransitionGroup>
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  BulkUploadHOC,
  RecipientGroupHOC,
  connect( mapStateToProps ),
)( BulkUploadListings )
