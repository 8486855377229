import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const editingLanguages = [ 'en', 'de', 'es', 'fr', 'it', 'nl' ]

const CookieContentForm = ({
  currentCookieContent,
  cookieContentType,
  onChangeCookieContentHOC,
  selectedLanguage
}) => {
  const[ editingLanguage, updateEditingLanguage ] = useState( selectedLanguage )

  const onScriptInput = ( e ) =>{
    if( e.keyCode == '9' && !e.shiftKey ){
      e.preventDefault()
      document.execCommand( "insertText", false, "  " )
      updateCurrentContent( 'cookie_script', e.target.value )
    }
  }

  const updateCurrentContent = ( key, val ) => {
    let tmpCookieContent = _.cloneDeep( currentCookieContent )
    if( key === 'title' || key === 'description' ){
      tmpCookieContent[ key ][ editingLanguage ] = val
    } else {
      tmpCookieContent[ key ] = val
    }
    onChangeCookieContentHOC( cookieContentType, tmpCookieContent )
  }
  
  return(
    <Container>
      <Row>
        <Col md={ 12 } >
          <FormGroup>
            <Label style={{ width: "max-content", marginRight: "1rem" }} >{ Lang[ 'CURRENT_EDITING_LANGUAGE' ][ selectedLanguage ] }</Label>
            {
              editingLanguages.map( lang => (
                <Button
                  className='mr-2'
                  color={ editingLanguage === lang ? 'success' : 'primary' }
                  onClick={ () => updateEditingLanguage( lang ) }>{ lang }</Button>
              ))
            }
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={ 8 } >
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'TITLE' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                value={ currentCookieContent.title[ editingLanguage ] }
                onChange={ e => updateCurrentContent( 'title', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'COOKIE_BANNER_DESCRIPTION' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                value={ currentCookieContent.description[ editingLanguage ] }
                onChange={ e => updateCurrentContent( 'description', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'COOKIE_NAME' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                value={ currentCookieContent.cookie_name }
                onChange={ e => updateCurrentContent( 'cookie_name', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'COOKIE_CODE' ][ selectedLanguage ] }</Label>
              <textarea
                style={{ 
                  width: '100%', 
                  border: "1px solid #ced4da",
                  outline: "none" }}
                rows={ 10 }
                value={ currentCookieContent.cookie_script }
                onKeyDown= { e => onScriptInput( e ) }
                onChange={ e => updateCurrentContent( 'cookie_script', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'EDITOR' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                value={ currentCookieContent.editor }
                onChange={ e => updateCurrentContent( 'editor', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'URL_PUBLISHER' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                value={ currentCookieContent.url_publisher }
                onChange={ e => updateCurrentContent( 'url_publisher', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'RUNNING_TIME' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                value={ currentCookieContent.running_time }
                onChange={ e => updateCurrentContent( 'running_time', e.target.value ) } />
            </FormGroup>
            <Row>
              <Col md={ 6 } >
                <FormGroup>
                  <Label>{ Lang[ 'POSITION' ][ selectedLanguage ] }</Label>
                  <Input
                    type='number'
                    min={ 0 }
                    value={ currentCookieContent.position }
                    onChange={ e => updateCurrentContent( 'position', parseInt( e.target.value ) ) } />
                </FormGroup>
              </Col>
              <Col md={ 6 } >
                <FormGroup>
                  <Label>{ Lang[ 'STATUS' ][ selectedLanguage ] }</Label>
                  <Input
                    type='select'
                    value={ currentCookieContent.status }
                    onChange={ e => updateCurrentContent( 'status', e.target.value ) }>
                    <option value={ 'ACTIVE' } >{ Lang[ 'ACTIVE' ][ selectedLanguage ] }</option>
                    <option value={ 'INACTIVE' } >{ Lang[ 'INACTIVE' ][ selectedLanguage ] }</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CookieContentForm
