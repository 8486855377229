import React, { useState } from 'react'
import {
  Card,
  CardFooter,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import _ from 'lodash'
import Lang from 'Lang/General'

import '../index.scss'

const Templates = ({
  newNewsletterData,
  templates,
  getSelectedTemplate,
  selectedLanguage
}) => {

  const [ fullViewTemplate, setFullView ] = useState( null )

  return (
    <>
      <div className="aciso-listing-masonry">
        {
          templates.length > 0 && templates.map( item => (
            <Card className="mr-3 mb-3">
              <CardHeader>
                { item.name || 'N/A' }
                <i class="pe-7s-expand1" 
                  style={{ cursor: 'pointer', fontSize: 17, marginLeft: 'auto' }} 
                  onClick={ () => setFullView( item )}
                />
              </CardHeader>
                <img
                  style={{ maxHeight: 400, objectFit: 'cover' }}
                  src={ !_.isEmpty( item.image.url ) ? item.image.url : '/static/images/sample-template-email.png' } 
                />
              <CardFooter>
                <Button
                  color={ newNewsletterData.template_id === item.id ? 'success' : 'primary'}
                  className="ml-auto"
                  onClick={ () => getSelectedTemplate( item.id ) }>
                  { Lang[ 'SELECT_TEMPLATE' ][ selectedLanguage ] }</Button>
              </CardFooter>
            </Card>
          ))
        }
      </div>
      
      <Modal isOpen={ fullViewTemplate } size={ 'xl' }>
        <ModalHeader toggle={ () => setFullView( null )}>
          { fullViewTemplate?.name??'N/A'}
        </ModalHeader>
        {
          fullViewTemplate && (
            <ModalBody style={{ textAlign: 'center' }}>
              <img src={ fullViewTemplate.image.url } alt={ `${ fullViewTemplate.name }_preview`} />
            </ModalBody>
          )
        }
      </Modal>
        {/*<Card
          className="mr-3 mb-3"
          style={{ width: '220px', display: 'inline-block' }}>
          <CardBody>
            <img src={ '/static/images/sample-template-email.png' } style={{ width: '175px' }} />
          </CardBody>
          <CardFooter>
            <Button
              color={ newNewsletterData.selectedTemplate && newNewsletterData.selectedTemplate === 'default' ? 'success' : 'primary'}
              className="ml-auto"
              onClick={ () => onChangeData( 'selectedTemplate', 'default' ) }>{ Lang[ 'SELECT_TEMPLATE' ][ selectedLanguage ] }</Button>
          </CardFooter>
        </Card>
        <Card
          className="mr-3 mb-3"
          style={{ width: '220px', display: 'inline-block' }}>
          <CardBody>
            <img src={ '/static/images/template1.png' } style={{ width: '175px' }} />
          </CardBody>
          <CardFooter>
            <Button
              color={ newNewsletterData.selectedTemplate && newNewsletterData.selectedTemplate === 'template 1' ? 'success' : 'primary'}
              className="ml-auto"
              onClick={ () => onChangeData( 'selectedTemplate', 'template 1' ) }>{ Lang[ 'SELECT_TEMPLATE' ][ selectedLanguage ] }</Button>
          </CardFooter>
        </Card>
        <Card
          className="mr-3 mb-3"
          style={{ width: '220px', display: 'inline-block' }}>
          <CardBody>
            <img src={ '/static/images/template2.png' } style={{ width: '175px' }} />
          </CardBody>
          <CardFooter>
            <Button
              color={ newNewsletterData.selectedTemplate && newNewsletterData.selectedTemplate === 'template 2' ? 'success' : 'primary'}
              className="ml-auto"
              onClick={ () => onChangeData( 'selectedTemplate', 'template 2' ) }>{ Lang[ 'SELECT_TEMPLATE' ][ selectedLanguage ] }</Button>
          </CardFooter>
        </Card>*/}
    </>
  )
}

export default Templates
