import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const LandingPages = ({
  onChangeLatestCampaignHOC,
  showLandingPageModal,
  selectedCampaign,
  lang
}) => {
  return (
    <Modal
      isOpen={ showLandingPageModal }
      size={ 'md' }
      toggle={ () => onChangeLatestCampaignHOC( 'showLandingPageModal', false ) }>
      <ModalHeader toggle={ () => onChangeLatestCampaignHOC( 'showLandingPageModal', false ) }>{ Lang[ 'PREVIEW' ][ lang ] }</ModalHeader>
      <ModalBody>
        <Label className="w-100">{ selectedCampaign.preview_text?.[ lang ] }</Label>
        <img
          src={ selectedCampaign.preview_image_url }
          style={{ width: '100%' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={ () => onChangeLatestCampaignHOC( 'showLandingPageModal', false )}>{ Lang[ 'CLOSE' ][ lang ]}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default LandingPages
