import React, { useEffect } from 'react'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap'
import ReactSelect from 'react-select'
import _ from 'lodash'
import Moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/nl'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const BlacklistedRecipientsForm = ({
  newBlacklistRecipientData,
  onChangeRecipientsHOC,
  headerText,
  toggle,
  isOpen,
  status,
  mode,
  postRecipient,
  recipientGroup,
  errorMessage,
  onLoadRecipients,
  selectedLanguage,
  countries,
  blacklistedRecipientLogs,
  data
}) => {

  useEffect(() => {
    Moment.locale( selectedLanguage )
  }, [])

  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( newBlacklistRecipientData )
    let tmpIDs = []
    tmp[key] = value


    onChangeRecipientsHOC( 'newBlacklistRecipientData', tmp )
  }
  let groupOptions = []
  recipientGroup && recipientGroup.map( item => {
    groupOptions.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })
  const processData = () => {
    let tmpValue = []
    newBlacklistRecipientData.recipient_groups && newBlacklistRecipientData.recipient_groups.map( item => {
      tmpValue.push(
        {
          id: item.id,
          label: item.name,
          value: item.name
        }
      )
    })
    return tmpValue
  }
  return(
    <Modal
      toggle={ toggle }
      isOpen={ isOpen }
      size="xl">
      <ModalHeader toggle={ toggle }>{ headerText }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 9 }>
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>{ Lang[ 'EMAIL' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'email' }
                        value={ newBlacklistRecipientData.email }
                        onChange={ e => {
                          errorMessage["email"] = ""
                          onChangeData( 'email', e.target.value )
                        }}
                        invalid={ errorMessage && errorMessage.email ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="salutation">{ Lang[ 'SALUTATION' ][ selectedLanguage ] }</Label>
                      <Input
                        type="text"
                        name="salutation"
                        id="salutation"
                        value={ newBlacklistRecipientData.salutation }
                        onChange={ e => onChangeData( 'salutation', e.target.value ) }/>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'FIRST_NAME' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ newBlacklistRecipientData.first_name }
                        onChange={ e => onChangeData( 'first_name', e.target.value ) }
                        invalid={ errorMessage && errorMessage.first_name ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.first_name ? errorMessage.first_name : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'LAST_NAME' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ newBlacklistRecipientData.last_name }
                        onChange={ e => onChangeData( 'last_name', e.target.value ) }
                        invalid={ errorMessage && errorMessage.last_name ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.last_name ? errorMessage.last_name : '' }</FormFeedback>
                    </FormGroup>
                    {/* <FormGroup>
                      <Label>{ Lang[ 'MOBILE_NUMBER' ][ selectedLanguage ] }</Label>
                      <Row md={ 12 }>
                        <Col md={ 3 }>
                          <Input
                            type="select"
                            onChange={ e => onChangeData( 'country_code', e.target.value ) }>
                            <option>{ 'Select a country' }</option>
                            {
                              countries.length > 0 && countries.map( item => {
                                return(
                                  <option key={ item.id } value={ item.calling_code[0] }>{ item.name }</option>
                                )
                              })
                            }
                          </Input>
                        </Col>
                        <Col md={ 3 }>
                          <Input
                            type={ 'text' }
                            disabled
                            value={ `+${ newBlacklistRecipientData.country_code }` } />
                        </Col>
                        <Col md={ 6 }>
                          <Input
                            type={ 'text' }
                            value={ newBlacklistRecipientData.mobile_number }
                            onChange={ e => {
                              onChangeData( 'mobile_number', e.target.value )
                            }}
                            invalid={ errorMessage && errorMessage.mobile_number ? true : false } />
                          <FormFeedback>{ errorMessage && errorMessage.mobile_number ? errorMessage.mobile_number : '' }</FormFeedback>
                        </Col>
                      </Row>
                    </FormGroup> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={ 3 }>
            {
              blacklistedRecipientLogs && blacklistedRecipientLogs.map( item => {
                return (
                  <Card key={ item.id } className="mb-2">
                    <CardBody>
                      <p style={{ fontWeight: '600' }}>{ item[ data.languageReducer.lang ] }</p>
                      <span>{ Moment( item.created_at ).format( 'DD MMM YYYY HH:mm:ss' ) }</span>
                    </CardBody>
                  </Card>
                )
              })
            }
          </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ () => {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
            let tmp = _.cloneDeep( errorMessage )
            if( !pattern.test( newBlacklistRecipientData.email ) ){
              tmp[ "email" ] = "Please enter valid email address."
              onChangeRecipientsHOC( 'errorMessage', tmp )
            } else {
              tmp[ "email" ] = ""
              let tmpBlacklistedRecipient = {
                ...newBlacklistRecipientData,
                mobile_number: !_.isEmpty( newBlacklistRecipientData.mobile_number )
                  ? `+${ newBlacklistRecipientData.country_code }${ newBlacklistRecipientData.mobile_number }`
                  : ''
              }
              postRecipient( tmpBlacklistedRecipient )
              onChangeRecipientsHOC( 'errorMessage', tmp )
            }
          }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
        <Button color="primary" onClick={ toggle }>{ Lang[ 'CANCEL' ][ selectedLanguage ] }</Button>
      </ModalFooter>
      { onLoadRecipients && <LoadingOverlay /> }
    </Modal>
  )
}

export default BlacklistedRecipientsForm
