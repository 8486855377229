import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const ChangeBudget = ({
  isOneTimer,
  selectedBookedCampaign,
  onChangeCampaignHOC,
  showChangeModal,
  updateBudget,
  lang,
  onLoadOLSCampaigns
}) => {
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( selectedBookedCampaign )
    tmp[key] = value
    return onChangeCampaignHOC( 'selectedBookedCampaign', tmp )
  }
  return (
    <Modal
      isOpen={ showChangeModal }
      size={ 'md' }
      toggle={ () => onChangeCampaignHOC( 'showChangeModal', false ) }>
      <ModalHeader toggle={ () => onChangeCampaignHOC( 'showChangeModal', false ) }>{ Lang[ 'CHANGE_BUDGET' ][ lang ] }</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{ Lang[ 'FACEBOOK_BUDGET' ][ lang ] }</Label>
            <Input
              type="text"
              onChange={ e => onChangeData( 'fa_budget', parseInt( e.target.value ) ) }
              value={ selectedBookedCampaign.fa_budget }
              invalid={ false }/>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => updateBudget( selectedBookedCampaign.id, isOneTimer ) }>{ Lang[ 'SUBMIT' ][ lang ] }</Button>
      </ModalFooter>
      { onLoadOLSCampaigns && <LoadingOverlay/> }
    </Modal>
  )
}

export default ChangeBudget
