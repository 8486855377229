import React, { Component } from 'react'
import { Get } from 'utils/axios-token'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

const DictionaryHOC = ( WrappedComponent ) => {
  class WithHOC extends Component{
    state={
      dictionaries: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getDictionary = () => {
      Get(
        `/api/v1/dictionaries`,
        this.getDictionarySuccess,
        this.getDictionaryError,
        this.load
      )
    }
    getDictionarySuccess = payload => this.setState({ dictionaries: payload })
    getDictionaryError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          dictionaries={ this.state.dictionaries }
          getDictionary={ this.getDictionary } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}
export default DictionaryHOC
