import React, { useState, useEffect } from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Tabs from 'react-responsive-tabs'
import DatePicker, { registerLocale } from 'react-datepicker'
import _ from 'lodash'
import el from "date-fns/locale/el"
import de from "date-fns/locale/de"
import nl from "date-fns/locale/nl"
import it from "date-fns/locale/it"

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SiteSettingsHOC from 'actions/SiteSettings'
import getDomainURL from 'utils/api'
import Lang from 'Lang/General'
import Overview from './components/Overview'
import Sites from './components/Sites'
import Transitions from './components/Transitions'
import MatomoStatsHOC from './actions'

import "react-datepicker/dist/react-datepicker.css"

const MatomoStats = props => {
  const [ siteDomain, setSiteDomain ] = useState( '' )

  useEffect( () => {
    registerLocale( "el", el )
    registerLocale( "de", de )
    registerLocale( "nl", nl )
    registerLocale( "it", it )
    if( handleSecurityModulesRead() ){
      let tmpSiteDomain = getDomainURL().indexOf( 'staging' ) === -1 
        ? _.find( props.data.selectCenterReducer.selectedCenter.center.domains, [ 'is_default_domain', true ] )?.site_domain || ''
        : 'www.hardys-freizeit.de'
      props.getSiteSettings( props.data.selectCenterReducer.selectedCenter.center?.site_id )
      setSiteDomain( tmpSiteDomain )
    }
  }, [] )

  useEffect( () => {
    if ( 
      handleSecurityModulesRead() &&
      !_.isEmpty( siteDomain ) &&
      !_.isEmpty( props.siteSettings ) &&
      ( props.siteSettings?.matomo_site_id > 0 || getDomainURL().indexOf( 'staging' ) > -1 )
    ) {
      props.getMatomoStats({
        ...props.matomoSearchParams,
        filterLimit: PAGE_SIZE,
        url: `https://${ siteDomain }/`
      })
    }
  }, [ props.siteSettings, siteDomain ] )

  const handleSecurityModulesRead = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Reports' })
      return tmpModules.is_read
    }
  }

  return(
    <>
      <style>{ '.RRT__container { padding-left: 0; } .react-datepicker__input-container input { min-width: 14rem; text-align: center; }' }</style>
      <PageTitle
        heading={ Lang[ 'MATOMO_STATISTICS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-news-paper icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        {
          handleSecurityModulesRead() && (
            <Card>
              <CardBody>
                <div className="d-flex flex-row flex-wrap align-items-center mb-4" >
                  <div style={{ display: 'block', flex: 1 }} >
                    <Tabs
                      showMore
                      transform
                      showInkBar
                      transformWidth={ 400 }
                      activeTab={ props.matomoSearchParams.currentTab }
                      selectedTabKey={ props.matomoSearchParams.currentTab }
                      tabsWrapperClass="body-tabs w-100"
                      onChange={ val => props.getMatomoStats({ ...props.matomoSearchParams, currentTab: val, filterOffset: 0 })} 
                      items={[
                        { 
                          key: 'overview',
                          title: Lang[ 'OVERVIEW_SITE_VISITS' ][ props.data.languageReducer.userSettings.admin_panel_language ]
                        },
                        { 
                          key: 'sites',
                          title: Lang[ 'SITES' ][ props.data.languageReducer.userSettings.admin_panel_language ]
                        },
                        { 
                          key: 'transitions',
                          title: Lang[ 'TRANSITIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ]
                        },
                      ]}
                    />
                  </div>
                  <div className="d-flex flex-row" >
                    <DatePicker
                      selectsRange
                      dateFormat="dd/MM/yyyy"
                      locale={ props.data.languageReducer.userSettings.admin_panel_language || 'el' }
                      className="form-control"
                      popperPlacement='left-start'
                      startDate={ props.matomoSearchParams.startDate }
                      endDate={ props.matomoSearchParams.endDate }
                      onKeyDown={ e => e.preventDefault() }
                      popperModifiers={{
                        flip: { behavior: [ 'bottom' ] },
                        preventOverflow: { enabled: false },
                        hide: { enabled: false }
                      }}
                      onChange={ ([ startDate, endDate ] ) => props.onChangeMatomoStatsHOC(
                        'matomoSearchParams',
                        { 
                          ...props.matomoSearchParams, 
                          filterOffset: 0,
                          startDate, 
                          endDate 
                        }
                      )}
                    />
                    <Button 
                      className='ml-2'
                      color='primary'
                      onClick={ () => props.getMatomoStats( props.matomoSearchParams ) } >
                      { Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                    </Button>
                  </div>
                </div>
                { props.matomoSearchParams.currentTab === 'overview' && <Overview { ...props } siteDomain={ siteDomain } PAGE_SIZE={ PAGE_SIZE } /> }
                { props.matomoSearchParams.currentTab === 'sites' && <Sites { ...props } PAGE_SIZE={ PAGE_SIZE } /> }
                { props.matomoSearchParams.currentTab === 'transitions' && <Transitions { ...props } siteDomain={ siteDomain } PAGE_SIZE={ PAGE_SIZE } /> }
              </CardBody>
            </Card>
          )
        }
      </ReactCSSTransitionGroup>
      { ( props.onLoadMatomoStatsHOC || props.onLoadSiteSettingsHOC ) && <LoadingOverlay/> }
    </>
  )
}

const PAGE_SIZE = 20

export default compose(
  SiteSettingsHOC,
  MatomoStatsHOC
)( MatomoStats )