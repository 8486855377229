import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Put } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      images: {},
      fbImages: {},
      googleImages: {},
      openModal: '',
      showFilePondModal: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string' 
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )
    
    onChangeImagesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getImages = location_id => Get(
      `/api/v1/locations/${ location_id }/leadhub_images`,
      this.getImagesSuccess,
      this.getImagesError,
      this.load
    )
    getImagesSuccess = payload => this.setState({ images: payload })
    getImagesError = error => this.requestError( error )

    updateImages = ( location_id, data ) => Put(
      `/api/v1/locations/${ location_id }/leadhub_images`,
      data,
      this.updateImagesSuccess,
      this.updateImagesError,
      this.load
    )
    updateImagesSuccess = payload => {
      this.requestSuccess( 'Images is successfully uploaded' )
      this.getImages( this.props.selectedLocation.id )
    }
    updateImagesError = error => this.requestError( error )

    getFbImages = location_id => Get(
      `/api/v1/locations/${ location_id }/leadhub_facebook`,
      this.getFbImagesSuccess,
      this.getFbImagesError,
      this.load
    )
    getFbImagesSuccess = payload => this.setState({ fbImages: payload })
    getFbImagesError = error => this.requestError( error )

    updateFbImages = ( location_id, data ) => Put(
      `/api/v1/locations/${ location_id }/leadhub_facebook`,
      data,
      this.updateFbImagesSuccess,
      this.updateFbImagesError,
      this.load
    )
    updateFbImagesSuccess = payload => {
      this.requestSuccess( 'Facebook images is successfully uploaded' )
      this.getFbImages( this.props.selectedLocation.id )
    }
    updateFbImagesError = error => this.requestError( error )

    getGoogleImages = location_id => Get(
      `/api/v1/locations/${ location_id }/leadhub_google`,
      this.getGoogleImagesSuccess,
      this.getGoogleImagesError,
      this.load
    )
    getGoogleImagesSuccess = payload => this.setState({ googleImages: payload })
    getGoogleImagesError = error => this.requestError( error )

    updateGoogleImages = ( location_id, data ) => Put(
      `/api/v1/locations/${ location_id }/leadhub_google`,
      data,
      this.updateGoogleImagesSuccess,
      this.updateGoogleImagesError,
      this.load
    )
    updateGoogleImagesSuccess = payload => {
      this.requestSuccess( 'Google images is successfully uploaded' )
      this.getGoogleImages( this.props.selectedLocation.id )
    }
    updateGoogleImagesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getImages={ this.getImages }
          updateImages={ this.updateImages }
          getFbImages={ this.getFbImages }
          updateFbImages={ this.updateFbImages }
          getGoogleImages={ this.getGoogleImages }
          updateGoogleImages={ this.updateGoogleImages }
          images={ this.state.images }
          fbImages={ this.state.fbImages }
          googleImages={ this.state.googleImages }
          openModal={ this.state.openModal }
          onLoadImages={ this.state.loading }
          onChangeImagesHOC={ this.onChangeImagesHOC }
          showFilePondModal={ this.state.showFilePondModal } />
      )
    }
  }
  return WithHOC
}

export default HOC