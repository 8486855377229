import React from 'react'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import ACMainRouter from './router'
import configureStore from './store/config'

import 'stylesheets/base.scss'

const store = configureStore();
const persistor = persistStore( store )

const App = () => {
  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <HashRouter>
          <ACMainRouter />
        </HashRouter>
      </PersistGate>
    </Provider>
  )
}

export default App;
