import React, { useState, useEffect } from 'react'
import {
  Modal, ModalBody, ModalHeader, 
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap'
import { connect } from 'react-redux'
import classnames from 'classnames'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import General from './components/general'
import AddMailing from './components/addMailing'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const Update = ( props ) => {
  const [activeTab, setActiveTab] = useState('1');

  useEffect( () => {
    props.getAllSiteFunnelSelect( convertSearchObjectToBase64({ is_paginated: false }) )
  }, [])

  useEffect( () => {
    props.onChangeFunnelsListingHOC( 'siteFunnelErrMsg', {} )
  }, [props.showUpdateSiteFunnel] )

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return(
    <Modal
      isOpen={ props.showUpdateSiteFunnel }
      size="lg">
      <ModalHeader toggle={ () => props.onChangeFunnelsListingHOC('showUpdateSiteFunnel', false ) }>{ Lang['UPDATE_FUNNEL'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggle('1'); }}>
              { Lang['GENERAL'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggle('2'); }}>
              Add Mailing
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <General
              {...props }/>
          </TabPane>
          <TabPane tabId="2">
            <AddMailing
              {...props }/>
          </TabPane>
        </TabContent>
      </ModalBody>
      { props.onLoadSiteFunnels && <LoadingOverlay /> }
    </Modal>

  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( Update )
