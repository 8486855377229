import React, { useEffect } from 'react'
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const Recipients = ({
  recipientGroups,
  senders,
  onChangeData,
  data,
  errorMessage,
  selectedLanguage
}) => {
  useEffect(() => {
    if( data.sender_id === 0 && senders.length > 0 ) {
      onChangeData( 'sender_id', senders[0].id )
    }
  }, [ data.sender_id ])
  return (
    <Container>
      <Form>
        <FormGroup>
          <Label>{ Lang[ 'NEWSLETTER_SUBJECT' ][ selectedLanguage ] }</Label>
          <Col md={ 6 } className="pl-0">
            <Input
              type="text"
              onChange={ e => onChangeData( 'subject', e.target.value ) }
              value={ data.subject }
              invalid={ errorMessage && errorMessage.subject ? true : false }/>
            <FormFeedback>{ errorMessage && errorMessage.subject ? errorMessage.subject : '' }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'NEWSLETTER_TITLE' ][ selectedLanguage ] }</Label>
          <Col md={ 6 } className="pl-0">
            <Input
              type="text"
              onChange={ e => onChangeData( 'title', e.target.value ) }
              value={ data.title }
              invalid={ errorMessage && errorMessage.title ? true : false }/>
            <FormFeedback>{ errorMessage && errorMessage.title ? errorMessage.title : '' }</FormFeedback>
          </Col>
        </FormGroup>
        <Row className="mb-3">
          <Col md={ 12 }>
            <Label className="mb-1">{ Lang[ 'SELECT_SENDERS' ][ selectedLanguage ] }</Label>
          </Col>
          <Col md={ 6 } className="d-inline-block">
            {
              senders.map( item => {
                return (
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={ data.sender_id === item.id }
                        type="radio"
                        onChange={ () => onChangeData( 'sender_id', item.id ) } />
                      { item.email }
                    </Label>
                  </FormGroup>
                )
              })
            }
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={ 12 }>
            <Label className="mb-1">{ Lang[ 'SELECT_RECIPIENT_GROUPS' ][ selectedLanguage ] }</Label>
          </Col>
          <Col md={ 6 } className="d-inline-block">
            {
              recipientGroups.map( item => {
                return (
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={ _.find( data.recipient_groups, { id: item.id } ) ? true : false }
                        value={ item.id }
                        type="checkbox"
                        onChange={ e => onChangeData( 'recipient_groups', e.target.value ) } />
                      { item.name } { item.name !== 'Alle' && `( ${ item.active_recipient_count } )` }
                    </Label>
                  </FormGroup>
                )
              })
            }
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default Recipients
