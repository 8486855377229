import React from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  CardHeader
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const Templates = ( props ) => {
  return (
    <Row>
      <Col md={ 12 } className='mb-3' >
        <p>{ Lang[ 'SELECT_A_TEMPLATE' ][ props.selectedLanguage ] }</p>
      </Col>
        {
          props.templates.length > 0 && props.templates.map( item => {
            return(
              <Col md={ 4 }>
                <Card className="mr-3 mb-3">
                  <CardHeader>{ item.name }</CardHeader>
                  <CardBody>
                    <div 
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundImage: _.isEmpty( item.image.url ) ? '' : `url(${ item.image.url })`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                      }} 
                    />
                  </CardBody>
                  <CardFooter>
                    <Button
                      color={ props.data.template_id === item.id ? 'success' : 'primary'}
                      className="ml-auto"
                      onClick={ () => {
                        props.getSelectedMailingTemplate( item.id )
                      }}>
                      { Lang[ 'SELECT_TEMPLATE' ][ props.selectedLanguage ] }</Button>
                  </CardFooter>
                </Card>
              </Col>
          )
          })
        }
    </Row>
  )
}

export default Templates
