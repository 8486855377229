import React, { Component } from 'react'
import { 
  Col, 
  Row
} from 'reactstrap'
import { connect } from 'react-redux'
import Slider from "react-slick"
import _ from 'lodash'

import TemplateContainerMain from 'Template/main'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import InputPassword from './InputPassword'

import bg1 from 'assets/TemplateImages/media/start_ziel_03_.jpg'

import WithPassword from './actions'
import { ApplyBranding } from 'utils/apply-color'

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true
}

const SliderImageItemStyle = { 
  opacity: "0.4", 
  height: "100vh", 
  minHeight: "100vh",
  width: "100%",
  objectFit: "cover",
  objectPosition: "center" 
}

class SetPassword extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: ''
  }

  componentDidMount = () => {
    if( !_.isEmpty( this.props.match.params ) ){
      this.props.onChangeSetPasswordHOC( 'token', this.props.match.params.setPasswordToken )
    } else {
      this.props.onChangeSetPasswordHOC( 'token', '' )
    }
  }

  render = () => {
    return (
      <>
        {
          !_.isEmpty( this.props.data.platformConfigReducer.platformConfig ) 
          && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } )
          && <ApplyBranding colors={{ primary: _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } ).value }} />
        }
        <TemplateContainerMain>
          <div style={{ height: "100vh" }} >
            <Row className="no-gutters" style={{ height: "100vh" }}>
              <Col lg="4" className="d-none d-lg-block">
                <div className="slider-light">
                  <Slider { ...SliderSettings }>
                    <div
                      className="d-flex justify-content-start align-items-start bg-plum-plate">
                      <img 
                        src={ bg1 } 
                        style={ SliderImageItemStyle } />
                    </div>
                  </Slider>
                </div>
              </Col>
              <Col 
                lg="8" 
                md="12" 
                style={{ height: "100vh", overflow: "scroll" }}
                className="d-flex bg-white justify-content-center align-items-center" >
                <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                  <img 
                    src={
                      !_.isEmpty( this.props.data.platformConfigReducer.platformConfig ) 
                      && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ) 
                        ? _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ).value
                        : ''
                    }
                    style={{ width: '150px', marginBottom: '0' }}/>
                  <h6 className="mt-3">
                    Noch kein Account?{' '}
                    <a href="javascript:void(0);" className="text-primary">Anmelden</a>
                  </h6>
                  <Row className="divider"/>
                  {
                    this.props.token !== '' && (
                      <InputPassword
                        success={ this.props.success }
                        error={ this.props.error }
                        password={ this.state.password }
                        confirmPassword={ this.state.confirmPassword }
                        token={ this.props.token }
                        onChangePassword={ val => this.setState({ password: val }) }
                        onChangeConfirmPassword={ val => this.setState({ confirmPassword: val }) }
                        setPassword={ this.props.setPassword }
                        history={ this.props.history } />
                    )
                  }
                </Col>
              </Col>
            </Row>
          </div>
          { this.props.onLoadPassword && <LoadingOverlay /> }
        </TemplateContainerMain>
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( 
  WithPassword( SetPassword )
)