import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'
import DefaultRecipients from 'utils/defaultRecipients'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      errorMessage: {},
      step: 1,
      blacklistedRecipientSearchParams: [
        {
          label: 'Email',
          value: 'email',
          param: ''
        },
      ],
      blacklistedRecipients: [],
      showCreateBlacklistedRecipient: false,
      showUpdateBlacklistedRecipient: false,
      newBlacklistedRecipient: DefaultRecipients( 'ACTIVE' ),
      selectedBlacklistedRecipient: DefaultRecipients( 'ACTIVE' ),
      errorMessage: {},
      showViewBlacklistedRecipient: false
    }

    onChangeBlacklistedRecipientHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error, {
      position: "bottom-right"
    })

    getAllBlacklistedRecipient = ( query ) => Get(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/blacklisted_recipients?query=${ query }`,
      this.getAllBlacklistedRecipientSuccess,
      this.getAllBlacklistedRecipientError,
      this.load
    )
    getAllBlacklistedRecipientSuccess = payload => {
      let tmp = []
      let tmpTotalPages = []

      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else{
        this.requestError( Lang['NO_RESULT_FOUND'][this.props.data.languageReducer.userSettings.admin_panel_language] )
      }

      this.setState({ blacklistedRecipients: payload })
    }
    getAllBlacklistedRecipientError = error => this.requestError( error )

    createBlacklistedRecipient = data => Post(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/blacklisted_recipients`,
      data,
      this.createBlacklistedRecipientSuccess,
      this.createBlacklistedRecipientError,
      this.load
    )
    createBlacklistedRecipientSuccess = () => {
      this.setState({ 
        showCreateBlacklistedRecipient: false,
        newBlacklistedRecipient: DefaultRecipients( 'ACTIVE' ),
      })
      this.requestSuccess('created successfully.')
      this.getAllBlacklistedRecipient( convertSearchObjectToBase64({ is_paginated: true, page: 1 }) )
    }
    createBlacklistedRecipientError = error => {
      this.setState({ errorMessage: error })
    }

    getSelectedBlacklistedRecipient = id => Get(
      `/api/v1/sites/${this.props.data.selectCenterReducer.selectedCenter.center.site.id}/blacklisted_recipients/${ id }`,
      this.getSelectedBlacklistedRecipientSuccess,
      this.getSelectedBlacklistedRecipientError,
      this.load
    )
    getSelectedBlacklistedRecipientSuccess = payload => {
      this.setState({ selectedBlacklistedRecipient: payload })
    }
    getSelectedBlacklistedRecipientError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadBlacklistedRecipient={ this.state.loading }
          getAllBlacklistedRecipient={ this.getAllBlacklistedRecipient }
          blacklistedRecipients={ this.state.blacklistedRecipients }
          onChangeBlacklistedRecipientHOC={ this.onChangeBlacklistedRecipientHOC }
          showCreateBlacklistedRecipient={ this.state.showCreateBlacklistedRecipient }
          newBlacklistedRecipient={ this.state.newBlacklistedRecipient }
          errorMessage={ this.state.errorMessage }
          createBlacklistedRecipient={ this.createBlacklistedRecipient }
          getSelectedBlacklistedRecipient={ this.getSelectedBlacklistedRecipient }
          selectedBlacklistedRecipient={ this.state.selectedBlacklistedRecipient }
          updateBlacklistedRecipient={ this.updateBlacklistedRecipient }
          showUpdateBlacklistedRecipient={ this.state.showUpdateBlacklistedRecipient }
          showViewBlacklistedRecipient={ this.state.showViewBlacklistedRecipient }
          blacklistedRecipientSearchParams={ this.state.blacklistedRecipientSearchParams } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
