import React, { useState, useEffect } from 'react'
import {
  Form, FormGroup, Label, Input, Card, CardBody,
  Modal, ModalBody, ModalHeader, ModalFooter, Button, CustomInput
} from 'reactstrap'
import { connect } from 'react-redux'
import _ from 'lodash'
import ReactSelect from 'react-select'
import Moment from 'moment'
import { toast } from 'react-toastify'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const CreateSiteFunnelForm = props => {
  const langButton = [ 'en', 'de', 'es', 'it', 'fr', 'nl' ]
  const [ selectedTitleLang, setLangTitle ] = useState( 'en' )
  const [ selectedDescLang, setLangDescription ] = useState( 'en' )

  useEffect( () => {
    setLangTitle( props.dataReducer.languageReducer.userSettings.admin_panel_language )
    setLangDescription( props.dataReducer.languageReducer.userSettings.admin_panel_language )
    props.getAllSiteFunnelSelect( convertSearchObjectToBase64({ is_paginated: false }) )
  }, [])

  useEffect( () => {
    if( !_.isEmpty( props.selectedFunnelTemplate ) ){
      onChangeData( 'scenario_id', props.selectedFunnelTemplate.scenario_id )
    }
  }, [ props.selectedFunnelTemplate ])

  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( props.newSiteFunnel )
    tmp[key] = value
    props.onChangeFunnelsListingHOC( 'newSiteFunnel', tmp )
  }

  return(
    <Modal
      size={'xl'}
      isOpen={ props.showCreateSiteFunnel }>
      <ModalHeader toggle={ () => props.onChangeFunnelsListingHOC( 'showCreateSiteFunnel', false ) }>{ Lang['CREATE_SITE_FUNNEL'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form>
              <table style={{ tableLayout: "auto", width: "95%", borderCollapse: "separate", borderSpacing: "0 10px", marginTop: '30px' }}>
                <tr>
                  <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                    <Label>{ Lang['SCENARIO'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }<span style={{ color: 'red'}}>*</span></Label>
                  </td>
                  <td>
                    <FormGroup style={{ position: "relative", zIndex: "2" }} >
                      <ReactSelect
                        name="scenario"
                        value={ _.find( props.scenariosForSelect, { id: props.newSiteFunnel.scenario_id } ) }
                        options={ props.scenariosForSelect }
                        placeholder={ Lang['SCENARIO'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                        isDisabled={ 
                          props.creatingFromTemplate &&
                          _.find( props.selectedFunnelTemplate.center_editable_config, { name: 'funnel_scenario_id' } )?.is_editable === false
                        }
                        onChange={ (value, action) => {
                          onChangeData('scenario_id', value.id )
                        }}/>
                    </FormGroup>
                    {
                      props.siteFunnelErrMsg.scenario_id && (
                        <span style={{ color: 'red' }}>{ props.siteFunnelErrMsg.scenario_id }</span>
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td className="col-md-3"></td>
                  <td>
                    <FormGroup className="mb-0" style={{ marginLeft: '20px' }}>
                      <Label
                        className="ml-1 mb-1">
                        <Input
                          type="checkbox"
                          checked={ props.creatingFromTemplate }
                          onChange={ (e) => {
                            props.onChangeFunnelsListingHOC( 'creatingFromTemplate', e.target.checked )
                          }}/>{' '}
                        { Lang['ARE_YOU_CREATING_FROM_TEMPLATE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
                {
                  props.creatingFromTemplate && (
                    <tr>
                      <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label>{ Lang['SELECT_TEMPLATE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }<span style={{ color: 'red'}}>*</span></Label>
                      </td>
                      <td>
                        <FormGroup>
                          <ReactSelect
                            name="scenario"
                            value={ props.funnelTemplate }
                            options={ props.funnelTemplates }
                            onChange={ (value, action) => {
                              props.onChangeFunnelsListingHOC('funnelTemplate', value )
                              props.getSelectedFunnelTemplate( parseInt(value.id) )
                            }}/>
                        </FormGroup>
                      </td>
                    </tr>
                  )
                }
                {
                  !_.isEmpty( props.selectedFunnelTemplate ) && props.creatingFromTemplate && (
                    <tr>
                      <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="funnel_title">{ Lang['TITLE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      </td>
                      <td>
                        <div className="d-flex pt-1">
                          {
                            langButton.map( button => {
                              return(
                                <Button
                                  className="btn-square btn-wide mr-0" size="sm"
                                  color={ `${ selectedTitleLang === button ? 'primary' : 'light' }` }
                                  onClick={ () => setLangTitle( button ) }>{ button }</Button>
                              )
                            })
                          }
                        </div>
                        <FormGroup className='m-0'>
                          <Input
                            id="funnel_title"
                            name="funnel_title"
                            placeholder={ Lang[ 'ENTER_FUNNEL_TITLE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                            value={ props.selectedFunnelTemplate.title[ selectedTitleLang ] }
                            disabled={ _.find( props.selectedFunnelTemplate.center_editable_config, { name: 'title' } )?.is_editable === false }
                            onChange={ e => {
                              let tmp = _.cloneDeep( props.selectedFunnelTemplate )
                              tmp[ 'title' ][ selectedTitleLang ] = e.target.value
                              props.onChangeFunnelTemplateHOC( 'selectedFunnelTemplate', tmp )
                            }} />
                        </FormGroup>
                      </td>
                    </tr>
                  )
                }
                {
                  !props.creatingFromTemplate && (
                    <>
                    <tr>
                      <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="funnel_title">{ Lang['TITLE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red'}}>*</span></Label>
                      </td>
                      <td>
                        <div className="d-flex pt-1">
                          {
                            langButton.map( button => {
                              return(
                                <Button
                                  className="btn-square btn-wide mr-0" size="sm"
                                  color={ `${ selectedTitleLang === button ? 'primary' : 'light' }` }
                                  onClick={ () => setLangTitle( button ) }>{ button }</Button>
                              )
                            })
                          }
                        </div>
                        <FormGroup className='m-0'>
                          <Input
                            name="funnel_title" id="funnel_title"
                            placeholder={ Lang[ 'ENTER_FUNNEL_TITLE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                            value={ props.newSiteFunnel.title[selectedTitleLang] }
                            onChange={ e => {
                              let tmp = _.cloneDeep( props.newSiteFunnel.title )
                              tmp[selectedTitleLang] = e.target.value
                              onChangeData( 'title', tmp )
                            }} />
                        </FormGroup>
                        {
                          props.siteFunnelErrMsg.title && (
                            <span style={{ color: 'red' }}>{ props.siteFunnelErrMsg.title }</span>
                          )
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="funnel_title">{ Lang['DESCRIPTION'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }<span style={{ color: 'red'}}>*</span></Label>
                      </td>
                      <td>
                        <div className="d-flex pt-1">
                          {
                            langButton.map( button => {
                              return(
                                <Button
                                  className="btn-square btn-wide mr-0" size="sm"
                                  color={ `${ selectedDescLang === button ? 'primary' : 'light' }` }
                                  onClick={ () => setLangDescription( button ) }>{ button }</Button>
                              )
                            })
                          }
                        </div>
                        <FormGroup className='m-0'>
                          <Input
                            type="textarea"
                            name="funnel_title" id="funnel_title"
                            placeholder={ Lang[ 'ENTER_FUNNEL_DESC' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                            value={ props.newSiteFunnel.description[selectedDescLang] }
                            onChange={ e => {
                              let tmp = _.cloneDeep( props.newSiteFunnel.description )
                              tmp[selectedDescLang] = e.target.value
                              onChangeData( 'description', tmp )
                            }} />
                        </FormGroup>
                        {
                          props.siteFunnelErrMsg.description && (
                            <span style={{ color: 'red', marginBottom: '15px' }}>{ props.siteFunnelErrMsg.description }</span>
                          )
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label sm={ 2 }>{ '' }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0 ml-3 mt-2'>
                          <CustomInput
                            id="radio-start-if-triggered"
                            type="radio"
                            label={ Lang['START_IF_TRIGGERED'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                            checked={ props.newSiteFunnel.start_type === 'start_if_triggered' }
                            onChange={ () => {
                              onChangeData('start_type', 'start_if_triggered')
                            }} />
                          <CustomInput
                            id="radio-start-on-fixed-date"
                            type="radio"
                            label={ Lang['START_ON_FIX_DAY'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                            checked={ props.newSiteFunnel.start_type === 'start_on_fixed_date' }
                            onChange={ () => {
                              onChangeData('start_type', 'start_on_fixed_date')
                            }} />
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td className="col-md-3">
                      </td>
                      <td>
                      {
                        props.newSiteFunnel.start_type === 'start_on_fixed_date' && (
                          <FormGroup>
                            <Input
                              type="date"
                              value={ props.newSiteFunnel.start_fix_date }
                              min={ Moment().format( 'YYYY-MM-DD' ) }
                              onChange={ e => onChangeData( 'start_fix_date', e.target.value )} 
                            />
                          </FormGroup>
                        )
                      }
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="7">
                        <hr/>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={ 3 } style={{ width: 'initial', whiteSpace: "nowrap", paddingLeft: "15px" }}>
                        <Label>{ Lang['STOP_FUNNEL_IF'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      </td>
                    </tr>
                    <tr>
                      <td className="col-md-5">
                        <Label
                          checked={ props.newSiteFunnel.is_stop_if_enter_funnel }
                          className="ml-1 mb-1 pl-1">
                          <Input
                            type="checkbox" name="radio1"
                            checked={ props.newSiteFunnel.is_stop_if_enter_funnel }
                            onChange={ (e) => {
                              onChangeData( 'is_stop_if_enter_funnel', e.target.checked )
                            }}/>{' '}
                          { Lang['USER_ENTER_ONE_FOLLOWING_FUNNEL'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                        </Label>
                      </td>
                      <td>
                        <ReactSelect
                          isMulti
                          options={ props.allSiteFunnelSelection.data }
                          onChange={ (value, action) => {
                            onChangeData( 'mailing_stops', value )
                          }} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="7">
                        <hr/>
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="funnel_title" sm={ 2 }>{ '' }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0 ml-3'>
                          <Label
                            checked={ props.newSiteFunnel.has_expiry_date }
                            className="ml-1 mb-1">
                            <Input
                              type="checkbox" name="radio1"
                              checked={ props.newSiteFunnel.has_expiry_date }
                              onChange={ (e) => {
                                onChangeData( 'has_expiry_date', e.target.checked )
                              }}/>{' '}
                            { Lang['HAS_EXPIRY_DATE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                          </Label>
                        </FormGroup>
                      </td>
                    </tr> */}
                    <tr>
                      <td colSpan={ 3 }><p style={{ width: 'initial', paddingLeft: '15px' }}>{ Lang['START_END_DATE_TITLE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</p></td>
                    </tr>
                    <tr>
                      <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="funnel_start_date">{ Lang['START_DATE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            type="date"
                            value={ Moment( props.newSiteFunnel.start_date ).format( 'YYYY-MM-DD' ) }
                            min={ Moment( new Date() ).format( 'YYYY-MM-DD' ) }
                            max={ Moment( props.newSiteFunnel.end_date ).format( 'YYYY-MM-DD' ) }
                            onChange={ e => {
                              let tmp = Moment( e.target.value ).utc().format()
                              onChangeData( 'start_date', tmp )
                            }} />
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td className="col-md-3" style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="funnel_end_date">{ Lang['END_DATE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      </td>
                      <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="date"
                          value={ Moment( props.newSiteFunnel.end_date ).format( 'YYYY-MM-DD' ) }
                          min={ Moment( props.newSiteFunnel.start_date ).format( 'YYYY-MM-DD' ) }
                          onChange={ e => {
                            let tmp = Moment( e.target.value ).utc().format()
                            onChangeData( 'end_date', tmp )
                          }} />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="funnel_end_date" sm={ 2 }>{ Lang['STATUS'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      </td>
                      <td>
                        <Input
                          type="select"
                          name="status"
                          id="status"
                          value={ props.newSiteFunnel.status }
                          onChange={ e => onChangeData( 'status', e.target.value ) }>
                          <option value="ACTIVE">{ Lang[ 'ACTIVE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</option>
                          <option value="INACTIVE">{ Lang[ 'INACTIVE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</option>
                          <option value="ARCHIVED">{ Lang[ 'ARCHIVED' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</option>
                        </Input>
                      </td>
                    </tr>
                    </>
                  )
                }
              </table>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ () => {
            if( props.creatingFromTemplate ) {
              props.createSiteFunnel( props.selectedFunnelTemplate, props.funnelTemplate.id )
            } else {
              if( (props.newSiteFunnel.start_date === null && props.newSiteFunnel.end_date === null) || ( (props.newSiteFunnel.start_date !== null && props.newSiteFunnel.end_date !== null ) ) ){
                let tmp = _.cloneDeep( props.newSiteFunnel )
                tmp.start_fix_date = Moment( tmp.start_fix_date ).utc().format()
                props.createSiteFunnel( tmp )
              } else {
                toast.error( Lang['END_DATE_REQUIRED'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] )
              }
            }
          }}>
          { Lang['SUBMIT'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
        </Button>
      </ModalFooter>
      { ( props.onLoadSiteFunnels || props.onLoadScenario ) && <LoadingOverlay /> }
    </Modal>
  )
}
const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( CreateSiteFunnelForm )
