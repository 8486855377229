import React, { Component } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from 'reactstrap'
import _ from 'lodash'
import ReactHtmlParser from "react-html-parser"

import Lang from 'Lang/General'
import LangTepe from 'Lang/TePe'

let TmpLang = window.location.host.indexOf( 'tennis' ) > -1 ? LangTepe : Lang

class ViewInfo extends Component {
  render = () => {
    return(
      <Modal isOpen={ this.props.showInfo } size="xl" toggle={ () => this.props.onChangeNewsHOC( 'showInfo', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeNewsHOC( 'showInfo', false ) }>{ TmpLang[ 'NEWS_INFO' ][ this.props.lang ] }</ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedNews ) && (
              <div style={{ padding: '15px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <h2>{ this.props.selectedNews.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] }</h2>
                <img src={ this.props.selectedNews.image_url } style={{ width: '70%', height: '100%', marginBottom: '20px' }}/>
                <span>
                  {
                    ReactHtmlParser( this.props.selectedNews.description[ this.props.data.languageReducer.userSettings.admin_panel_language ] )
                  }
                </span>
              </div>
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={ () => this.props.onChangeNewsHOC( 'showInfo', false ) }>{ TmpLang[ 'CLOSE' ][ this.props.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default ViewInfo
