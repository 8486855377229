import React, { useEffect } from 'react'
import { Col } from 'reactstrap'
import { InputGroup, InputGroupAddon } from 'reactstrap'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker, { registerLocale } from 'react-datepicker'
import el from "date-fns/locale/el";
import de from "date-fns/locale/de";
import nl from "date-fns/locale/nl";
import it from "date-fns/locale/it";

import "react-datepicker/dist/react-datepicker.css"

const TemplateDatepicker = ({
  locale,
  selectedDate,
  onChange,
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat,
  zIndex
}) => {

  useEffect(() => {
    registerLocale("el", el);
    registerLocale("de", de);
    registerLocale("nl", nl);
    registerLocale("it", it);
  }, [])
  return (
    <Col className='p-0' md={ 12 } style={{ zIndex: zIndex }}  >
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <div className="input-group-text">
            <FontAwesomeIcon icon={faCalendarAlt}/>
          </div>
        </InputGroupAddon>
        <DatePicker
          locale={ locale || 'el' }
          className="form-control"
          popperPlacement='bottom-start'
          popperModifiers={{
            flip: { behavior: [ 'bottom' ] },
            preventOverflow: { enabled: false },
            hide: { enabled: false }
          }}
          // onChangeRaw={ e => e.preventDefault() }
          dateFormat={ dateFormat }
          selected={ selectedDate }
          onChange={ onChange }
          showTimeSelect={ showTimeSelect }
          showTimeSelectOnly={ showTimeSelectOnly }
        />
      </InputGroup>
    </Col>
  )
}

export default TemplateDatepicker
