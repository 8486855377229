import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      errorMessage: {},
      step: 1,
      senderSearchParams: [
        {
          label: 'Email',
          value: 'email',
          param: ''
        }
      ],
      senders: [],
      showCreateSender: false,
      showUpdateSender: false,
      newSender: {
        name: '',
        email: ''
      },
      selectedSender: {
        name: '',
        email: ''
      },
      errorMessage: {},
      showViewSender: false
    }

    onChangeSenderHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error, {
      position: "bottom-right"
    })

    getAllSender = ( query ) => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_senders?query=${ query }`,
      this.getAllSenderSuccess,
      this.getAllSenderError,
      this.load
    )
    getAllSenderSuccess = payload => {
      let tmp = []
      let tmpTotalPages = []

      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else{
        this.requestError( Lang['NO_RESULT_FOUND'][this.props.data.languageReducer.userSettings.admin_panel_language] )
      }

      this.setState({ senders: payload })
    }
    getAllSenderError = error => this.requestError( error )

    createSender = data => Post(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_senders`,
      data,
      this.createSenderSuccess,
      this.createSenderError,
      this.load
    )
    createSenderSuccess = () => {
      this.setState({ showCreateSender: false })
      this.requestSuccess('created successfully.')
      this.getAllSender( convertSearchObjectToBase64({ is_paginated: true, page: 1 }) )
    }
    createSendertError = error => {
      this.setState({ errorMessage: error })
    }

    getSelectedSender = id => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_senders/${ id }`,
      this.getSelectedSenderSuccess,
      this.getSelectedSenderError,
      this.load
    )
    getSelectedSenderSuccess = payload => {
      this.setState({ selectedSender: payload })
    }
    getSelectedSenderError = error => this.requestError( error )

    updateSender = ( id, data ) => Put(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_senders/${ id }`,
      data,
      this.updateSenderSuccess,
      this.updateSenderError,
      this.load
    )
    updateSenderSuccess = () => {
      this.setState({ showUpdateSender: false })
      this.getAllSender( convertSearchObjectToBase64({ is_paginated: true, page: 1 }) )
      this.requestSuccess( Lang['UPDATE_SENDER_SUCCESS'][ this.props.dataReducer.languageReducer.userSettings.admin_panel_language ] )
    }
    updateSenderError = error => {
      this.setState({ errorMessage: error })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadSenders={ this.state.loading }
          getAllSender={ this.getAllSender }
          senders={ this.state.senders }
          onChangeSenderHOC={ this.onChangeSenderHOC }
          showCreateSender={ this.state.showCreateSender }
          newSender={ this.state.newSender }
          errorMessage={ this.state.errorMessage }
          createSender={ this.createSender }
          getSelectedSender={ this.getSelectedSender }
          selectedSender={ this.state.selectedSender }
          updateSender={ this.updateSender }
          showUpdateSender={ this.state.showUpdateSender }
          showViewSender={ this.state.showViewSender }
          senderSearchParams={ this.state.senderSearchParams } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
