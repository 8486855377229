import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import _ from 'lodash'

import DigitalOrderForm from './DigitalOrderForm'
import LocalListing from './LocalListing'
import OLS from './OLS'
import Main from './Main'
import MessageConsultant from './MessageConsultant'
import Newsletter from './Newsletter/Listings'
import ActiveRecipients from './Newsletter/Recipients'
import InactiveRecipients from './Newsletter/Recipients/inactive'
import BlacklistedRecipients from './Newsletter/Recipients/blacklisted'
import DOIRecipients from './Newsletter/Recipients/DOI'
import RecipientBulkUpload from './Newsletter/Recipients/BulkUpload'
import RecipientGroup from './Newsletter/RecipientGroup'
import Senders from './Newsletter/Senders'
import NewsletterTemplate from './Newsletter/Templates'
import Reports from './Report'
import MatomoStats from './Website/Matomo'
import MailGunStats from './PerformanceReporting/MailGunStats'

import ArticleFourteen from './GDPRCenter/ArticleFourteen'
import PrivacyPolicy from './GDPRCenter/PrivacyPolicy'
import Imprint from './GDPRCenter/Imprint'
import AVContract from './GDPRCenter/AVContract'
import GDPRDataConfiguration from './GDPRCenter/DataConfiguration'
import CookieBannerCategories from './GDPRCenter/CookieBanner/CookieCategories'
import CookieBannerContent from './GDPRCenter/CookieBanner/CookieContent'
import MarketingFunnels from './MarketingFunnels'
import Injoy360OpeningHour from './Injoy360OpeningHour'

import TemplateContainerMain from 'Template/main'
import TemplateHeader from 'components/Header'
import TemplateSidemenu from 'components/Menu/Sidemenu'
import InitialConsentModal from 'components/Modal/initialConsent'

import AVContractHOC from './GDPRCenter/AVContract/actions'
import ImprintHOC from './GDPRCenter/Imprint/actions'
import PrivacyPolicyHOC from './GDPRCenter/PrivacyPolicy/actions'
import { getToken } from 'utils/findCookieToken'
import { ApplyBranding } from 'utils/apply-color'
import { getSelectedRole } from 'actions/role'
import { getUserProfile } from 'actions/profile'
import { getUserSetting } from 'actions/language'

import AxiosInterceptors from 'Sec/AxiosInterceptors' // do not remove this

class Dashboard extends Component {
  state = {
    showProfileModal : false,
    showInitialConsentModal: true
  }

  componentDidMount = () => {
    if( getToken( 'ACISO_SESSION_TOKEN' ).length <= 2 ) {
      this.props.history.push( '/' )
    } else {
      Promise.all([
        this.props.getUserProfile()
      ]).then(() => {
        toast.success(
          <>
            <p>Welcome to Cockpit!</p>
            <span>Nutze Deine Favoriten oder navigiere zu den Produkten</span>
          </>
        )
        this.props.getUserSetting()
        if( this.props.data.profileReducer.profile.role_id > 0 ) {
          this.props.getSelectedRole( this.props.data.profileReducer.profile.role_id )
        }
        if ( !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) ) {
          this.props.getAVContract( this.props.data.selectCenterReducer.selectedCenter.center.client_id )
        } else {
          if ( this.props.data.profileReducer.profile.centers && this.props.data.profileReducer.profile.centers.length > 0 ) {
            this.props.getAVContract( this.props.data.profileReducer.profile.centers[ 0 ].client_id )
          }
        }
      })
    }
  }

  componentDidUpdate = pp => {
    if( this.props.data.profileReducer.profile.role_id !== pp.data.profileReducer.profile.role_id ) {
      if( this.props.data.profileReducer.profile.role_id > 0 ) {
        this.props.getSelectedRole( this.props.data.profileReducer.profile.role_id )
      }
      if ( !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) ) {
        this.props.getAVContract( this.props.data.selectCenterReducer.selectedCenter.center.client_id )
        this.props.getPrivacyPolicy( this.props.data.selectCenterReducer.selectedCenter.center.id )
        this.props.getImprint( this.props.data.selectCenterReducer.selectedCenter.center.id )
      } else {
        if ( this.props.data.profileReducer.profile.centers && this.props.data.profileReducer.profile.centers.length > 0 ) {
          this.props.getAVContract( this.props.data.profileReducer.profile.centers[ 0 ].client_id )
          this.props.getPrivacyPolicy( this.props.data.profileReducer.profile.centers[ 0 ].id )
          this.props.getImprint( this.props.data.profileReducer.profile.centers[ 0 ].id )
        }
      }
    }
  }

  onClickToggleProfileModal = () => this.setState({ showProfileModal: !this.state.showProfileModal })

  closeInitialConsentModal = () => this.setState({ showInitialConsentModal: false })

  render = () => {
    return (
      <>
        {
          !_.isEmpty( this.props.data.platformConfigReducer.platformConfig )
          && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } )
          && <ApplyBranding colors={{ primary: _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } ).value }} />
        }
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal= { () => this.onClickToggleProfileModal() }
            history={ this.props.history }
            data={ this.props.data } />
          <div className="app-main">
            {
              !_.isEmpty( this.props.data.roleReducer.role ) && (
                <TemplateSidemenu
                  role={ this.props.data.roleReducer.role }
                  profile={ this.props.data.profileReducer.profile } />
              )
            }
            <div className="app-main__outer">
              <div className="app-main__inner">
                <Route path="/dashboard/ols" component={ OLS } />
                <Route path="/dashboard/digital-order-form" component={ DigitalOrderForm } />
                <Route path="/dashboard/local-listing" component={ LocalListing } />
                <Route path="/dashboard/marketing-funnels" component={ MarketingFunnels } />
                <Route exact path="/dashboard/main" component={ Main } />
                <Route exact path="/dashboard/message-consultant" component={ MessageConsultant } />
                <Route exact path="/dashboard/newsletter" component={ Newsletter } />
                <Route exact path="/dashboard/newsletter-active-recipients" component={ ActiveRecipients } />
                <Route exact path="/dashboard/newsletter-inactive-recipients" component={ InactiveRecipients } />
                <Route exact path="/dashboard/newsletter-doi-recipients" component={ DOIRecipients } />
                <Route exact path="/dashboard/newsletter-blacklisted-recipients" component={ BlacklistedRecipients } />
                <Route exact path="/dashboard/newsletter-bulk-upload" component={ RecipientBulkUpload } />
                <Route exact path="/dashboard/newsletter-recipient-group" component={ RecipientGroup } />
                <Route exact path="/dashboard/newsletter-senders" component={ Senders } />
                <Route exact path="/dashboard/newsletter-templates" component={ NewsletterTemplate } />
                <Route exact path="/dashboard/local-listing/reports" component={ Reports } />
                <Route exact path="/dashboard/website/matomo" component={ MatomoStats } />
                <Route exact path="/dashboard/article-fourteen" component={ ArticleFourteen } />
                <Route exact path="/dashboard/privacy-policy" component={ PrivacyPolicy } />
                <Route exact path="/dashboard/imprint" component={ Imprint } />
                <Route exact path="/dashboard/av-contract" component={ AVContract } />
                <Route exact path="/dashboard/gdpr-data-configuration" component={ GDPRDataConfiguration } />
                <Route exact path="/dashboard/cookie-banner/categories" component={ CookieBannerCategories } />
                <Route exact path="/dashboard/cookie-banner/content" component={ CookieBannerContent } />
                <Route exact path="/dashboard/performance-report/mail-gun-stats" component={ MailGunStats } />
                <Route exact path="/dashboard/injoy360/opening-hours" component={ Injoy360OpeningHour } />
              </div>
            </div>
          </div>
          { 
            (
              this.state.showInitialConsentModal && 
              !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) &&
              ( 
                ( !_.isEmpty( this.props.contract ) && !this.props.contract.is_agreed ) || 
                ( !_.isEmpty( this.props.imprint ) && !Object.values( this.props.imprint.content ).every( item => item === '' ) && !this.props.imprint.is_signed_imprint ) || 
                ( !_.isEmpty( this.props.privacyPolicy ) && !Object.values( this.props.privacyPolicy.content ).every( item => item === '' ) && !this.props.privacyPolicy.is_signed_policy ) 
              ) 
            ) && (
              <InitialConsentModal 
                data={ this.props.data }
                contract={ this.props.contract }
                imprint={ this.props.imprint }
                policy={ this.props.privacyPolicy }
                closeInitialConsentModal={ this.closeInitialConsentModal }
                lang={ this.props.data.languageReducer.userSettings.admin_panel_language } 
              />
            )
          }
          <ToastContainer position={ toast.POSITION.TOP_RIGHT }/>
        </TemplateContainerMain>
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  connect( mapStateToProps, {
  getSelectedRole,
  getUserProfile,
  getUserSetting
}),
  AVContractHOC,
  ImprintHOC,
  PrivacyPolicyHOC
)( Dashboard )
