export default function DefaultRecipients( status ) {
  return ({
    email: '',
    salutation: '',
    first_name: '',
    last_name: '',
    mobile_number: '',
    recipient_groups: [],
    ... status !== 'BLACKLIST' 
      ? { status: status } 
      : {
        is_blacklisted: true,
        country_code: '' 
      }
  })
}
