import React, { useEffect } from 'react'
import { 
  Form, Label, FormGroup, Input, Button,
  Card, CardHeader, CardBody,
  Row, Col
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'
import DatePicker from 'react-datepicker'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

import GunMailStatsHOC from './actions'
import StatsCard from './statsCard'
import { 
  defaultSearchParam 
} from './asset'

const StatsOptions = lang => {
  return [
    {
      label: Lang[ 'NEWSLETTER' ][ lang ],
      value: 'newsletters'
    },
    {
      label: Lang[ 'FUNNEL_MARKETING' ][ lang ],
      value: 'funnels'
    },
    {
      label: Lang[ 'OTHERS' ][ lang ],
      value: 'others'
    },
    {
      label: Lang[ 'SUMMARY' ][ lang ],
      value: 'summary'
    }
  ]
}

const GunMailStats = props => {
  useEffect(() => {
    props.getGunMailStats( props.searchParams[0].param, props.searchParams[1].param )
  }, [ props.currentStatOption ])
    
  return(
    <>
      <PageTitle
        heading={ Lang[ 'MAIL_GUN_STATISTICS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-mail icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Card className="main-card mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
          <CardBody>
            <Form onSubmit={ e => e.preventDefault()}>
              <Row>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>
                      { Lang[ props.searchParams[0].label ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                    </Label>
                    <DatePicker
                      className="form-control"
                      maxDate={new Date()}
                      showMonthDropdown={ true }
                      dateFormat={ 'yyyy-MM-dd' }
                      selected={ props.searchParams[0].param }
                      onChange={ val => {
                        let tmp = _.cloneDeep( props.searchParams )
                        tmp[ 0 ][ 'param' ] =  val
                        return props.onChangeGunMailHOC( 'searchParams', tmp )
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>
                      { Lang[ props.searchParams[1].label ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                    </Label>
                    <DatePicker
                      className="form-control"
                      maxDate={new Date()}
                      dateFormat={ 'yyyy-MM-dd' }
                      showMonthDropdown={ true }
                      selected={ props.searchParams[1].param }
                      onChange={ val => {
                        let tmp = _.cloneDeep( props.searchParams )
                        tmp[1][ 'param' ] = val
                        return props.onChangeGunMailHOC( 'searchParams', tmp )
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex">
                <Button
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    props.getGunMailStats( props.searchParams[0].param, props.searchParams[1].param )
                  }}
                >
                  { Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                </Button>
                <Button
                  color="danger"
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    props.getGunMailStats ( defaultSearchParam[0].param, defaultSearchParam[1].param )
                    props.onChangeGunMailHOC( 'searchParams', defaultSearchParam )
                  }}
                >
                  { Lang[ 'RESET' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <div className="mb-4">
        {
          StatsOptions( props.data.languageReducer.userSettings.admin_panel_language ).map(( option, optionIndex ) => (
            <Button 
              color={ props.currentStatOption === option.value ? 'primary' : 'secondary' }
              className={ `${ optionIndex !== 3 ? 'mr-2' : '' }`}
              onClick={ () => props.onChangeGunMailHOC( 'currentStatOption', option.value )}
            >
              { option.label }
            </Button>
          ))
        }
        </div>
        {
          ( !props.gunMailStats || props.gunMailStats.length < 1 ) && (
            <p>
              { Lang[ 'NO_MAILGUN_STAT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </p>
          )
        }
        {
          props.gunMailStats.length > 0 && (
            <div className="card p-4" style={{ backgroundColor: '#ccd2d7' }}>
              <Row>
                {
                  props.gunMailStats.map( stat => (
                    <>
                      {
                        stat.total_value !== undefined && (
                          <Col sm={ 12 } lg={ 12 } xl={ 6 }>
                            <Card className={ 'mb-3' }>
                              <CardBody>
                                <StatsCard 
                                  item={ stat }
                                  admin_panel_language={ props.data.languageReducer.userSettings.admin_panel_language }
                                /> 
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      }
                      { 
                        stat.total_value === undefined && (
                          <Col sm={ 12 } lg={ 4 } xl={ 3 }>
                            {
                              stat.children.map(( statChild, index ) => (
                                <Card className={ `mb-3 ${ statChild.className || '' }` }>
                                  <CardBody style={{ height: 'fit-content' }}>
                                  <div className="d-flex align-items-center">
                                    {
                                      statChild.icon
                                    }
                                    <div className='d-flex ml-auto' style={{ flexDirection: 'column', textAlign: 'end' }}>
                                      <h6 style={{ opacity: 0.5 }}>
                                        { statChild.label }
                                      </h6>
                                      <h1 className='mb-0' style={{ fontWeight: 'bold' }}>
                                        { statChild.value }
                                      </h1>
                                    </div>
                                  </div>
                                  </CardBody>
                                </Card>
                              ))
                            }
                          </Col>
                        )
                      }
                    </>
                  ))
                }
              </Row>
            </div>
          )
        }
      </ReactCSSTransitionGroup>
      { props.onLoadStats && <LoadingOverlay/> }
    </>
  )
}

export default GunMailStatsHOC( GunMailStats )