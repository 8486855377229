import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Progress
} from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import NewslettersRecipients from './Recipients'
import EmailEditor from './EmailEditor'
import { toast } from 'react-toastify'
import ClosingAlert from './alert'

import Lang from 'Lang/General'

class FromTemplate extends Component {
  state = {
    view: 'recipients',
    emailEditor: '',
    submitButton: false
  }

  stepTitle = ( step ) => {
    switch (step) {
      case 1:
        return Lang[ 'FILL_IN_DETAILS' ][ this.props.selectedLanguage ]
      case 2:
        return Lang[ 'DESIGN_NEWSLETTER' ][ this.props.selectedLanguage ]
      default:
        return ''
    }
  }

  onChangeCreateNewsletter = ( key, val ) => this.setState({ [ key ]: val })

  onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.newNewsletterData )
    if( key === 'recipient_groups' ){
      if( _.find( tmp.recipient_groups, { id: parseInt( value ) } ) ) {
        if( parseInt( value ) === 0 ){
          tmp['recipient_groups'] = []
        } else {
          _.remove( tmp.recipient_groups, { id: parseInt( value ) })
          _.remove( tmp.recipient_groups, { id: 0 } )
        }
      } else {
        if( parseInt( value ) === 0 ){
          this.props.recipientGroups && this.props.recipientGroups.length > 0 && this.props.recipientGroups.map( item => {
            if( !_.find( tmp.recipient_groups, { id: parseInt( item.id ) } )) {
              tmp['recipient_groups'].push({
                id: parseInt( item.id )
              })
            }
          })
        } else {
          tmp['recipient_groups'].push({
            id: parseInt( value )
          })
        }
      }
    } else if ( key === 'do_send_now' || key === 'is_draft' || key === 'is_scheduled' ) {
      tmp[ 'do_send_now' ] = key === 'do_send_now'
      tmp[ 'is_draft' ] = key === 'is_draft'
      tmp[ 'is_scheduled' ] = key === 'is_scheduled'
    } else {
      tmp[ key ] = value
    }
    this.props.onChangeNewsletterHOC( 'newNewsletterData', tmp )
  }

  SaveDesign = async () => {
    let tmp = _.cloneDeep( this.props.newNewsletterData )
    this.state.emailEditor.exportHtml((data) => {
      const { design, html } = data
      tmp.json_content = design
      tmp.html_content = html
      tmp.delivered_at = Moment( tmp.delivered_at ).utc().format()
      tmp.domain = window.location.origin

      this.props.processType === 'create'
        ? this.props.postNewsletter( tmp )
        : this.props.updateNewsletter( tmp )
    });
  }

  render = () => {
    return (
      <Modal
        size={ 'xl' }
        isOpen={ this.props.showCreateFromTemplate }
        toggle={() => {
          this.props.onChangeNewsletterHOC( 'closingConfirmation', true )
        }}
        style={{ zIndex: 996 }}>
        <ModalHeader
          toggle={() => {
            this.props.onChangeNewsletterHOC( 'closingConfirmation', true )
          }}>{
            this.props.processType === 'create'
              ? Lang[ 'CREATE_NEWSLETTER' ][ this.props.selectedLanguage ]
              : Lang[ 'UPDATE_NEWSLETTER_FROM_TEMPLATE' ][ this.props.selectedLanguage ]
          }
        </ModalHeader>
        <ModalBody>
          {
            this.props.processType === 'create' && <>
              <div className="text-left">
                {`${ Lang[ 'STEP' ][ this.props.selectedLanguage ] } ${ this.props.step }: ${ this.stepTitle( this.props.step )}`}
              </div>
              <Progress className="mb-3" value={ this.props.step } max="2" />
            </>
          }
          {
            this.props.step === 1 &&
              <NewslettersRecipients
                senders={ this.props.senders }
                recipientGroups={ this.props.recipientGroups }
                onChangeData={ this.onChangeData }
                data={ this.props.newNewsletterData }
                errorMessage={ this.props.errorMessage }
                selectedLanguage={ this.props.selectedLanguage } />
          }
          {
            this.props.step === 2 &&
              <EmailEditor
                templates={ this.props.templates }
                getSelectedTemplate={ this.props.getSelectedTemplate }
                data={ this.props.data }
                onLoadNewsletters={ this.props.onLoadNewsletters }
                onChangeData={ this.onChangeData }
                newNewsletterData={ this.props.newNewsletterData }
                onChangeCreateNewsletter={ this.onChangeCreateNewsletter }
                selectedLanguage={ this.props.selectedLanguage }
                closingConfirmation={ this.props.closingConfirmation }
                onChangeNewsletterHOC={ this.props.onChangeNewsletterHOC }
                processType={ this.props.processType }
                setEmailEditorRef={ val => this.setState({ emailEditor: val }) }
                contentServices={ this.props.contentServices }
                centerNews={ this.props.centerNews }
                getSelectedContentService={ this.props.getSelectedContentService }
                selectedContentService={ this.props.selectedContentService }
                onChangeContentServicesHOC={ this.props.onChangeContentServicesHOC }
                toggleMediaDatabase={ this.props.toggleMediaDatabase } />
          }
          { this.props.onLoadNewsletters && <LoadingOverlay/> }
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={ () => this.props.onChangeNewsletterHOC(
              'step',
              this.props.step - 1
            )}
            disabled={ this.props.step === 1 }>{ Lang[ 'BACK' ][ this.props.selectedLanguage ] }</Button>
          <Button
            color="primary"
            onClick={ () => {
              if( this.props.newNewsletterData.sender_id === 0 ) {
                toast.error( Lang[ 'PLEASE_SELECT_SENDER' ][ this.props.selectedLanguage ] )
              } else {
                this.props.onChangeNewsletterHOC(
                  'step',
                  this.props.step + 1
                )
              }
            }}
            disabled={ this.props.step === 2 }>{ Lang[ 'NEXT' ][ this.props.selectedLanguage ] }</Button>
          {
            this.state.submitButton && this.props.step === 2 && (
              <Button
                color="primary"
                onClick={ async () => {
                  await this.SaveDesign()
                }}
              >
                { Lang[ 'SUBMIT' ][ this.props.selectedLanguage ] }
              </Button>
            )
          }
        </ModalFooter>
        {
          this.props.closingConfirmation && <ClosingAlert
            isOpen={ this.props.closingConfirmation }
            selectedLanguage={ this.props.selectedLanguage }
            label={ Lang[ 'CLOSE_CONFIRMATION' ][ this.props.selectedLanguage ] }
            handleModalClose={ () => this.props.onChangeNewsletterHOC( 'closingConfirmation', false ) }
            confirmAction={ () => {
              Promise.all([
                this.props.onChangeNewsletterHOC( 'showCreateFromTemplate', false ),
                this.props.onChangeNewsletterHOC( 'step', 1 ),
                this.props.onChangeNewsletterHOC( 'closingConfirmation', false ),
              ]).then( () => {
                if( this.props.processType === 'update' ){
                  this.props.onChangeNewsletterHOC( 'showCreateNewsletterModal', false )
                }
              })
            }} />
        }
      </Modal>
    )
  }
}

export default FromTemplate
