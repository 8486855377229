import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post } from 'utils/axios-token'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      productRequests: [],
      products: [],
      selectedProduct: {},
      requestedProduct: [],
      newRequest: {
        "product_id": 1,
        "client_id": 1,
        "consultant_id": 4,
        "user_id": 1,
        "from_date": "2020-02-02T00:00:00Z",
        "to_date": "2020-02-02T00:00:00Z",
        "status_id": 1
      },
      showRequestInfoModal: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeProductRequestsHOC = ( key, value ) => this.setState({ [ key ] : value })

    getProducts = () => Get(
      `/api/v1/admin_panel/products`,
      this.getProductsSuccess,
      this.getProductsError,
      this.load
    )
    getProductsSuccess = payload => {
      let tmp = []
      payload.data.map( item => {
        if( _.find( this.state.requestedProduct, { product_id: item.id, center_id: !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) && this.props.data.selectCenterReducer.selectedCenter.center.id }) ){
          tmp.push({
            ...item,
            is_requested: true
          })
        } else {
          tmp.push({ ...item })
        }
      })
      this.setState({ products: tmp })
    }
    getProductsError = () => {}

    getAllProductRequests = query => Get(
      `/api/v1/admin_panel_product_requests?query=${ query }`,
      this.getAllProductRequestsSuccess,
      this.getAllProductRequestsError,
      this.load
    )
    getAllProductRequestsSuccess = payload => this.setState({ productRequests: payload })
    getAllProductRequestsError = () => {}

    getSelectedProduct = id => Get(
      `/api/v1/admin_panel/products/${ id }`,
      this.getSelectedProductSuccess,
      this.getSelectedProductError,
      this.load
    )
    getSelectedProductSuccess = payload => {
      let tmp = {}
      if( _.find( this.state.requestedProduct, { product_id: payload.id, center_id: !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) && this.props.data.selectCenterReducer.selectedCenter.center.id }) ){
        tmp = {
          ...payload,
          is_requested: true
        }
      } else {
        tmp = payload
      }
      this.setState({ selectedProduct: tmp })
    }
    getSelectedProductError = error => this.requestError( error )

    createProductsRequest = ( data, product_id ) => Post(
      `/api/v1/admin_panel/products/${ product_id }/requests`,
      data,
      this.createProductsRequestSuccess,
      this.createProductsRequestError,
      this.load
    )
    createProductsRequestSuccess = payload => {
      this.requestSuccess( Lang[ 'SEND_REQUEST_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getRequestedProduct()
      this.setState({ showRequestInfoModal: false })
    }
    createProductsRequestError = error => this.requestError( error )

    getRequestedProduct = () => Get(
      `/api/v1/user_admin_panel_products_requests`,
      this.getRequestedProductSuccess,
      this.getRequestedProductError,
      this.load
    )
    getRequestedProductSuccess = payload => {
      this.setState({ requestedProduct: payload })
    }
    getRequestedProductError = () => {}

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadProductRequest={ this.state.loading }
            getAllProductRequests={ this.getAllProductRequests }
            getProducts={ this.getProducts }
            getSelectedProduct={ this.getSelectedProduct }
            createProductsRequest={ this.createProductsRequest }
            getRequestedProduct={ this.getRequestedProduct }
            newRequest={ this.state.newRequest }
            productRequests={ this.state.productRequests }
            products={ this.state.products }
            selectedProduct={ this.state.selectedProduct }
            showRequestInfoModal={ this.state.showRequestInfoModal }
            onChangeProductRequestsHOC={ this.onChangeProductRequestsHOC } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
