import React from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import _ from 'lodash'

import { getFileSizeValidity } from 'utils/fileSizeValidator'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
registerPlugin(FilePondPluginImagePreview)

const Create = ({
  path,
  showCreateModal,
  newFolderFile,
  onChangeShareMediaHOC,
  files,
  tags,
  onChange,
  createMedia,
  createMediaFolder,
  onLoadCenterDataDrive,
  selectedLanguage
}) => {
  let tagList = []
  tags && tags.map( item => {
    tagList.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })
  return (
    <Modal
      size={ 'md' }
      toggle={ () => onChangeShareMediaHOC( 'showCreateModal', false ) }
      isOpen={ showCreateModal }>
      <ModalHeader toggle={ () => onChangeShareMediaHOC( 'showCreateModal', false ) }>Create folder / file</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>{ Lang[ 'NAME' ][ selectedLanguage ] }</Label>
            <Input
              type="text"
              value={ newFolderFile.file_name }
              onChange={ e => {
                let tmp = _.cloneDeep( newFolderFile )
                tmp[ 'file_name' ] = e.target.value
                return onChangeShareMediaHOC( 'newFolderFile', tmp )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'TYPE' ][ selectedLanguage ] }</Label>
            <Input
              type="select"
              value={ newFolderFile.type }
              onChange={ e => {
                let tmp = _.cloneDeep( newFolderFile )
                tmp[ 'type' ] = e.target.value
                return onChangeShareMediaHOC( 'newFolderFile', tmp )
              }}>
              <option value="Folder">{ 'Folder' }</option>
              <option value="File">{ 'File' }</option>
            </Input>
          </FormGroup>
          {
            newFolderFile.type === 'File' && (
              <FormGroup>
                <Label style={{ fontSize: '11px', color: '#212529' }}>{ Lang[ 'MAXIMUM_FILE_SIZE_10_MB' ][ selectedLanguage ]}</Label>
                <FilePond
                  files={ files }
                  instantUpload={ false }
                  allowMultiple={ false }
                  onupdatefiles={ fileItems => {
                    getFileSizeValidity( fileItems, selectedLanguage ) && 
                    onChange( 'files', fileItems )
                  }}
                />
              </FormGroup>
            )
          }
        </Form>
        { onLoadCenterDataDrive && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
      {
        newFolderFile.type === 'File' && (
          <Button
            color="primary"
            onClick={() => {
              const tmp = {
                file: files.length > 0
                  ? {
                    filename : !_.isEmpty( newFolderFile.file_name )
                      ? `${ newFolderFile.file_name.replace( /\s/g, '-' ) }.${ files[ 0 ].fileExtension }`
                      : files[ 0 ].filename.replace( /\s/g, '-' )
                  }
                  : {
                    filename: newFolderFile.file_name.replace( /\s/g, '-' ) + '.jpg',
                    raw: newFolderFile.media_raw
                  },
                parent_folder_id: path[ path.length - 1 ].id
              }

              if( files.length > 0 ){
                let reader = new FileReader()
                reader.onload = () => {
                  tmp.file.raw = reader.result
                  createMedia( tmp, path[ path.length - 1 ].id )
                }
                reader.readAsDataURL( files[ 0 ].file )
              } else {
                createMedia( tmp, path[ path.length - 1 ].id )
              }
            }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
        )
      }
      {
        newFolderFile.type === 'Folder' && (
          <Button
            color="primary"
            onClick={() => {
              let tmp = _.cloneDeep( newFolderFile )
              delete tmp['media_raw']
              delete tmp['tags']

              createMediaFolder({
                ...tmp,
                parent_folder_id: path[ path.length - 1 ].id,
                folder_name: tmp.file_name
              })
            }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
        )
      }
      </ModalFooter>
    </Modal>
  )
}

export default Create
