import React, { Component } from 'react'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container
} from 'reactstrap'
import classnames from 'classnames'

import LocationForm from './locationForm'
import Categories from '../Update/Categories'

import Lang from 'Lang/General'

class CreateLocation extends Component {
  render = () => {
    return(
      <>
        <Nav tabs={ true } >
          <NavItem>
            <NavLink
              href="javascript:void(0);"
              className={ classnames({ active: this.props.activeTab === 0 }) }
              onClick={ () => this.props.onChangeLocations( 'activeTab', 0 ) } >
              { Lang[ 'GENERAL' ][ this.props.selectedLanguage ] }</NavLink>
          </NavItem>
          {/*<NavItem>
            <NavLink
              href="javascript:void(0);"
              className={ classnames({ active: this.props.activeTab === 1 }) }
              onClick={ () => {
                this.props.onChangeLocations( 'activeTab', 1 )
                this.props.getCategories()
              }} >
              { Lang[ 'CATEGORIES' ][ this.props.selectedLanguage ] }</NavLink>
          </NavItem>*/}
       </Nav>
        <Container>
          <TabContent activeTab={ this.props.activeTab } >
            <TabPane tabId={ 0 } className='pt-3'>
              <LocationForm
                currentLocation={ this.props.newLocation }
                locationType={ 'newLocation' }
                onChangeLocationsHOC={ this.props.onChangeLocationsHOC }
                getStates={ this.props.getStates }
                states={ this.props.states }
                countries={ this.props.countries }
                selectedLanguage={ this.props.selectedLanguage }
                leadhubCategoriesOptions={ this.props.leadhubCategoriesOptions } />
            </TabPane>
            {/*<TabPane tabId={ 1 } >
              <Categories { ...this.props } />
            </TabPane>*/}
          </TabContent>
        </Container>
      </>
    )
  }
}

export default CreateLocation
