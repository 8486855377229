import React, { useEffect, useState } from 'react'
import {
  Form, FormGroup,
  Container, Row, Col,
  Card, CardBody, CardHeader,
  Button, Label, Input, UncontrolledTooltip
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ArkPagination from 'components/Pagination'
import AlertNotSubscribe from 'components/Alert'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import ActiveRecipientHOC from './actions'
import RecipientGroupHOC from 'containers/Dashboard/MarketingFunnels/Groups/actions'
import Create from './Create'
import Update from './Update'
import View from './View'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const ActiveRecipientListings = props => {
  const [ subscribedPackage, setSubscribePackage ] = useState( true )

  useEffect( () => {
    let tmp = {
      is_paginated: true,
      page: 1,
      filter: {
        status: 'ACTIVE'
      }
    }
    let tmpIndex = _.findIndex( props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Marketing Funnel' })
    setSubscribePackage( tmpIndex > -1 )
    props.getAllActiveRecipient( convertSearchObjectToBase64( tmp ) )
    props.getAllRecipientGroup()
  }, [])

  return (
    <>
      <PageTitle
        heading={ Lang[ 'ACTIVE_RECIPIENTS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-news-paper icon-gradient bg-happy-itmeo"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_RECIPIENT' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            onClick: () => {
              props.onChangeActiveRecipientHOC( 'showCreateActiveRecipient', true )
            },
            display: false
          }
        ]} />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          {
            !subscribedPackage && (
              <AlertNotSubscribe { ...props } />
            )
          }
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                <CardBody>
                  <Form onSubmit={ e => e.preventDefault()}>
                    <FormGroup>
                      <Label>{ Lang[ 'EMAIL' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.activeRecipientSearchParams[0].param }
                        onChange={ e => {
                          let tmp = _.cloneDeep( props.activeRecipientSearchParams )
                          tmp[ 0 ][ 'param' ] = e.target.value
                          return props.onChangeActiveRecipientHOC( 'activeRecipientSearchParams', tmp )
                        }} />
                    </FormGroup>
                    <div className="d-flex">
                      <Button
                        color="primary"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          let tmp = {
                            is_paginated: true,
                            page: 1,
                            filter: {
                              email: {
                                $like: `%${props.activeRecipientSearchParams[0].param}%`
                              }
                            }
                          }
                          props.getAllActiveRecipient( convertSearchObjectToBase64( tmp ) )
                        }}>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      <Button
                        color="danger"
                        style={{ marginLeft: '10px' }}
                        onClick={() => {
                          let tmp = [
                            {
                              label: 'Email',
                              value: 'email',
                              param: ''
                            },
                            {
                              label: 'Recipient Groups',
                              value: 'recipient_groups',
                              param: [],
                              ids: []
                            }
                          ]
                          props.onChangeActiveRecipientHOC( 'activeRecipientSearchParams', tmp )
                          props.getAllActiveRecipient( convertSearchObjectToBase64({ page: 1, is_paginated: true }) )
                        }}>{ Lang[ 'RESET' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={ 12 }>
              <Card className="main-card mb-3 p-4">
                <ReactTable
                  NoDataComponent={ () => <span></span> }
                  data={ props.activeRecipients.data }
                  columns={[
                    {
                      Header: Lang[ 'EMAIL' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'email'
                    },
                    {
                      Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: "id",
                      Cell: ({row}) => (
                        <div>
                          <Button
                            id={ `active-recipient-${ row.id }-view` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => {
                              props.getSelectedActiveRecipient( row.id )
                              props.onChangeActiveRecipientHOC( 'showViewActiveARecipient', true )
                            } }
                            disabled={ !subscribedPackage }>
                            <i className="pe-7s-look"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `active-recipient-${ row.id }-view` }
                            placement="top">
                            { Lang[ 'VIEW' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                          </UncontrolledTooltip>
                          <Button
                            id={ `active-recipient-${ row.id }-delete` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => props.onChangeActiveRecipientHOC( 'showDeleteActiveRecipientId', row.id ) }
                            disabled={ !subscribedPackage }>
                            <i className="pe-7s-trash"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `active-recipient-${ row.id }-delete` }
                            placement="top">
                            { Lang[ 'DELETE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                          </UncontrolledTooltip>
                        </div>
                      )
                    }
                  ]}
                  showPagination={ false }
                  defaultPageSize={ 10 } />
                  {
                    props.activeRecipients.data && props.activeRecipients.data.length > 0 && (
                      <ArkPagination
                        meta={ props.activeRecipients.meta }
                        onChangePage={ page => {
                          let tmp = {
                            page: page,
                            is_paginated: true
                          }
                          let tmpFilter = {}

                          props.activeRecipientSearchParams.map( searchItem => {
                            if( !_.isEmpty( searchItem.param ) && searchItem.value !== 'recipient_groups' ){
                              tmpFilter = {
                                ...tmpFilter,
                                [searchItem.value]: searchItem.value === 'email' ? { $like: `%${ searchItem.param }%` } : searchItem.param
                              }
                            }
                            if( searchItem.value === 'recipient_groups' && !_.isEmpty( searchItem.param ) ){
                              tmpFilter = {
                                ...tmpFilter,
                                $or: searchItem.ids
                              }
                            }
                          })

                          let tmpString = convertSearchObjectToBase64( !_.isEmpty( tmpFilter ) ? { ...tmp, filter: tmpFilter } : tmp )
                          props.getAllActiveRecipient( tmpString )
                        }} />
                    )
                  }
              </Card>
            </Col>
          </Row>
        </Container>
        <Create
          {...props} />
        <Update
          {...props} />
        <View
          {...props} />
        {
          props.showDeleteActiveRecipientId > 0 &&(
            <DeleteConfirmation
              handleModalClose={ () => props.onChangeActiveRecipientHOC( 'showDeleteActiveRecipientId', 0 ) }
              deleteOpen={ props.showDeleteActiveRecipientId > 0 }
              confirmAction={ () => props.deleteActiveRecipient() }
              content={ Lang[ 'ARE_YOU_SURE_DELETE_RECIPIENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
              lang={ props.data.languageReducer.userSettings.admin_panel_language }
            />
          )
        }
      </ReactCSSTransitionGroup>
      { props.onLoadActiveRecipient && <LoadingOverlay /> }
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  ActiveRecipientHOC,
  RecipientGroupHOC,
  connect( mapStateToProps ),
)( ActiveRecipientListings )
