import Axios from 'axios'

import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax';
import {
  AUTH_USER
} from './type'
import getDomainURL from 'utils/api';

export const auth = ({ email, password }) => {
  return ( dispatch ) => {
    dispatch( beginAjaxCall( false ) );
    Axios.post(
      `${ getDomainURL() }/login`, 
      {
        email,
        password,
        platform: 'aciso-cockpit'
      },
      { headers: { 'Accept-Language': '' } }
    ).then( response => {
      document.cookie = `ACISO_SESSION_TOKEN=${ response.data.token }`
      dispatch( authSuccess( response.data ) )
      dispatch( ajaxCallSuccess( 'Login Success!' ) )
    }).catch( error => {
      if( error && error.response ) {
        if( error.response.data.Errors ) {
          let tmp = ''
          if ( typeof error.response.data.Errors === 'string' ) {
            tmp = error.response.data.Errors
          } else {
            Object.values( 
              error.response.data.Errors 
            ).map( errorMsg => {
              tmp += `${ errorMsg }. `
            })
          }
          dispatch( ajaxCallError( tmp ) )
        } else {
          dispatch( ajaxCallError( error.response.data.Error ) )
        }
      } else {
        dispatch( ajaxCallError( 'Disconnected from internet, please connect to the internet and retry again.' ) );
      }
    });
  };
}

const authSuccess = payload => ({
  type: AUTH_USER,
  payload
})