import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import RenderDOM from './RenderDOM'

const DynamicForm = props => {
  const [ dom, setDom ] = useState( [] )
  const [ formValues, setFormValues ] = useState( [] )
  const [ currentLanguage, setCurrentLanguage ] = useState( 'de' )

  useEffect( () => {
    if( props.selectedCampaign.id > 0 ){
      setDom( props.selectedCampaign.content || [] )
      setCurrentLanguage( props.lang )
      setFormValues( props.selectedCampaign.values || [] )
    }
  }, [ props.selectedCampaign ] )

  const onChangeFormBuilderState = ( key, val ) => {
    switch( key ) {
      case 'dom': 
        setDom( val )
        break
      case 'formValues': 
        setFormValues( val )
        break
      case 'currentLanguage': 
        setCurrentLanguage( val )
        break
    }
  }

  const onPatchFormValues = ( 
    index, 
    key, 
    val, 
    type,
    currentSelection, 
    previousSelection
  ) => {
    let tmp = _.cloneDeep( formValues )

    if ( type?.indexOf( 'Radio' ) > -1 ) {
      if( 
        val !== tmp[ index ][ 'value' ] &&
        currentSelection.dependentField &&
        currentSelection.dependentField.id &&
        tmp[ currentSelection.dependentField.dataMapIndex ][ 'isRequired' ]
      ){
        tmp[ currentSelection.dependentField.dataMapIndex ][ 'validation' ] = tmp[ currentSelection.dependentField.dataMapIndex ].validation
        tmp[ currentSelection.dependentField.dataMapIndex ][ 'isRequired' ] = true
        tmp[ currentSelection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = true
      }
  
      if( previousSelection && previousSelection.dependentField && previousSelection.dependentField.id ) {
        tmp[ previousSelection.dependentField.dataMapIndex ][ 'value' ] = ''
        tmp[ previousSelection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = false
      }
    } else if ( type?.indexOf( 'Checkbox' ) > -1 ) {
      let tmpFormValue = _.cloneDeep( tmp[ index ][ 'value' ] )
      if( tmpFormValue.indexOf( currentSelection.value ) > -1 ) {
        if (currentSelection.dependentField && currentSelection.dependentField.id) {
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'value' ] = '' 
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = false 
        }
      } else {     
        if( 
          currentSelection.dependentField &&
          currentSelection.dependentField.id && 
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'isRequired' ]
        ){
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'validation' ] = formValues[ currentSelection.dependentField.dataMapIndex ].validation
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'isRequired' ] = true
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = true
        }
      }
    }
    tmp[ index ][ key ] = val

    return setFormValues( tmp )
  }

  const findObjectAndPatch = ( data, target ) => {
    data.map(( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1, target )
      } else {
        if( item.children ) {
          findObjectAndPatch( item.children, target )
        }
      }
    })
    return data
  }

  return(
    <div 
      className="row d-flex flex-row flex-wrap"
      onMouseOut={ () => props.onChangeOlsCampaign(
        props.olsCampaignType,
        {
          ...props.currentOlsCampaign,
          content: dom,
          values: formValues,
        }
      )} >
      {
        dom.map( ( item, index ) => (
          <RenderDOM   
            { ...props }                    
            content={{ ...item, rowComponentIndex: index }}
            mode='fill'
            formValues={ formValues }
            onPatchFormValues={ onPatchFormValues }
            dom={ dom }
            currentLanguage={ currentLanguage }
            findObjectAndPatch={ findObjectAndPatch }
            onChangeFormBuilderState={ onChangeFormBuilderState }
          />
        ))
      }
    </div>
  )
}

export default DynamicForm