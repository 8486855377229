import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  UncontrolledTooltip,
  Form,
  FormGroup,
  CardBody,
  Label,
  Input,
  CardHeader
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ArkPagination from 'components/Pagination'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import AlertNotSubscribe from 'components/Alert'
import SiteFunnelsHOC from './actions'
import ScenariosHOC from './actions/scenarios'
import FunnelTemplatesHOC from './actions/template'
import MailingsHOC from './actions/mailing'
import Create from './Create'
import Update from './Update'
import Archived from './Archived'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const SiteFunnels = props => {
  const [ subscribedPackage, setSubscribePackage ] = useState( true )

  useEffect( () => {
    props.getAllSystemFunnels( convertSearchObjectToBase64({ is_paginated: false, filter: { status: 'ACTIVE' } }) )
    props.getAllScenarios( convertSearchObjectToBase64({ is_paginated: false }) )
    props.getSiteMailing( convertSearchObjectToBase64({ is_paginated: false, filter: { status: 'ACTIVE' } }) )
    let tmpIndex = _.findIndex( props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Marketing Funnel' })
    setSubscribePackage( tmpIndex > -1 )
  }, [])

  useEffect( () => {
    if( !props.showArchivedSiteFunnels ){
      props.getAllSiteFunnel( 
        convertSearchObjectToBase64({ 
          is_paginated: true, 
          page: 1, 
          filter: { status: { $neq: 'ARCHIVED' } },
        }) 
      )
    }
  }, [ props.showArchivedSiteFunnels ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_create && subscribedPackage
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_update
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'FUNNEL' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-arc icon-gradient bg-happy-itmeo"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_FUNNEL' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            onClick: () => {
              props.onChangeFunnelsListingHOC( 'showCreateSiteFunnel', true )
            },
            display: handleSecurityModulesCreate()
          },
          {
            buttonText: Lang[ 'ARCHIVED_FUNNELS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            onClick: () => {
              props.getAllSiteFunnel( 
                convertSearchObjectToBase64({ 
                  is_paginated: true, 
                  page: 1, 
                  filter: { status: 'ARCHIVED' },
                }) 
              )
              props.onChangeFunnelsListingHOC( 'showArchivedSiteFunnels', true )
            },
            display: handleSecurityModulesUpdate() && subscribedPackage
          }
        ]}
         />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          {
            !subscribedPackage && (
              <AlertNotSubscribe { ...props } />
            )
          }
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                <CardBody>
                  <Form onSubmit={ e => e.preventDefault()}>
                    <FormGroup>
                      <Label>{ Lang[ 'TITLE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.siteFunnelSearchParams[0].param }
                        onChange={ e => {
                          let tmp = _.cloneDeep( props.siteFunnelSearchParams )
                          tmp[ 0 ][ 'param' ] = e.target.value
                          return props.onChangeFunnelsListingHOC( 'siteFunnelSearchParams', tmp )
                        }} />
                    </FormGroup>
                    <div className="d-flex">
                      <Button
                        color="primary"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          let tmp = {
                            is_paginated: true,
                            page: 1,
                            filter: {
                              title: {
                                $like: `%${props.siteFunnelSearchParams[0].param}%`
                              }
                            }
                          }
                          props.getAllSiteFunnel( convertSearchObjectToBase64( tmp ) )
                        }}>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      <Button
                        color="danger"
                        style={{ marginLeft: '10px' }}
                        onClick={() => {
                          let tmp = [
                            {
                              label: 'Title',
                              value: 'title',
                              param: ''
                            }
                          ]
                          props.onChangeFunnelsListingHOC( 'siteFunnelSearchParams', tmp )
                          props.getAllSiteFunnel( convertSearchObjectToBase64({ page: 1, is_paginated: true }) )
                        }}>{ Lang[ 'RESET' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={ 12 }>
              <Card className="main-card mb-3 p-4">
                <ReactTable
                  NoDataComponent={ () => <span></span> }
                  data={ props.siteFunnels.data }
                  columns={[
                    {
                      Header: Lang[ 'TITLE' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'title',
                      width: 350,
                      Cell: ({value} ) => (
                        <>
                          <span>
                          { value[ props.data.languageReducer.userSettings.admin_panel_language ] }
                          </span>
                        </>
                      )
                    },
                    {
                      Header: Lang[ 'STATUS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'status',
                      Cell: ({ value }) => Lang[ value ][ props.data.languageReducer.userSettings.admin_panel_language ]
                    },
                    {
                      Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: "id",
                      Cell: ({row}) => (
                        handleSecurityModulesUpdate() ? (
                          <>
                            <Button
                              id={ `site-funnel-${ row.id }-view` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => {
                                props.getSelectedSiteFunnel( row.id )
                                props.onChangeFunnelsListingHOC('showUpdateSiteFunnel', true )
                              } }
                              disabled={ !subscribedPackage }>
                              <i className="pe-7s-pen"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `site-funnel-${ row.id }-view` }
                              placement="top">
                              { Lang[ 'UPDATE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                            </UncontrolledTooltip>
                            <Button
                              id={ `site-funnel-${ row.id }-test` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => props.funnelTesting( row.id ) }
                              disabled={ !subscribedPackage }>
                              <i className="pe-7s-timer"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `site-funnel-${ row.id }-test` }
                              placement="top">
                              { Lang[ 'TEST_BUTTON' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                            </UncontrolledTooltip>
                            <Button
                              id={ `site-funnel-${ row.id }-archive` }
                              className='mb-2 mr-2 btn-icon btn-icon-only'
                              color='warning'
                              disabled={ !subscribedPackage }
                              onClick={ () => props.getSelectedSiteFunnel( row.id, true ) }>
                              <i className='pe-7s-notebook'> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `site-funnel-${ row.id }-archive` }
                              placement='top'>{ Lang[ 'ARCHIVE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</UncontrolledTooltip>
                          </>
                        ) : (
                          <></>
                        )
                      )
                    }
                  ]}
                  showPagination={ false }
                  defaultPageSize={ 10 } />
                  {
                    props.siteFunnels.data && props.siteFunnels.data.length > 0 && (
                      <ArkPagination
                        meta={ props.siteFunnels.meta }
                        onChangePage={ page => {
                          let tmp = {
                            page: page,
                            is_paginated: true
                          }
                          let tmpFilter = {
                            status: { $neq: 'ARCHIVED' }
                          }
                          props.siteFunnelSearchParams.map( searchItem => {
                            if( !_.isEmpty( searchItem.param ) && searchItem.value !== 'recipient_groups' ){
                              tmpFilter = {
                                ...tmpFilter,
                                [searchItem.value]: searchItem.value === 'title' ? { $like: `%${ searchItem.param }%` } : searchItem.param
                              }
                            }
                          })

                          let tmpString = convertSearchObjectToBase64( !_.isEmpty( tmpFilter ) ? { ...tmp, filter: tmpFilter } : tmp )
                          props.getAllSiteFunnel( tmpString )
                        }} />
                    )
                  }
              </Card>
            </Col>
          </Row>
        </Container>
        <Create { ...props } />
        <Update { ...props } />
        <Archived { ...props } />
        {
          props.showArchiveSelectedSiteFunnel && (
            <DeleteConfirmation
              handleModalClose={ () => props.onChangeFunnelsListingHOC( 'showArchiveSelectedSiteFunnel', false ) }
              deleteOpen={ props.showArchiveSelectedSiteFunnel }
              confirmAction={ () => props.updateSiteFunnel(
                props.selectedSiteFunnel.id,
                { 
                  ...props.selectedSiteFunnel,
                  status: props.showArchivedSiteFunnels ? 'ACTIVE' : 'ARCHIVED'
                }
              )}
              content={ Lang[ 'ARCHIVE_UNARCHIVE_CONFIRMATION' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
              lang={ props.data.languageReducer.userSettings.admin_panel_language }/>
          )
        }
        { props.onLoadSiteFunnels && <LoadingOverlay />}
      </ReactCSSTransitionGroup>
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  SiteFunnelsHOC,
  ScenariosHOC,
  FunnelTemplatesHOC,
  MailingsHOC,
  connect( mapStateToProps ),
)( SiteFunnels )
