import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'


import { Get, Post, Put, Delete } from 'utils/axios-token'
// import { getRefreshToken } from 'actions/token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const FunnelScenarioHOC = ( WrappedComponent ) => {
  class FunnelScenarioWrappedComponent extends Component{
    state = {
      loading: false,
      scenariosForSelect: []
    }

    onChangeScenarioHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    // ajaxError = ( error ) => {
    //   outdatedToken( error, this.props.history )
    //   this.requestError( error )
    // }

    getAllScenarios = (query) => Get(
      `/api/v1/funnel_scenarios?query=${ query }`,
      this.getAllScenariosSuccess,
      this.getAllScenariosError,
      this.load
    )
    getAllScenariosSuccess = payload => {
      let tmpTotalPages = []
      let tmp = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmp.push({
            ...item,
            label: item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ],
            value: item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ]
          })
        })
      }
      this.setState({ scenariosForSelect: tmp })
    }
    getAllScenariosError = error => this.requestError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadScenario={ this.state.loading }
            getAllScenarios={ this.getAllScenarios }
            scenariosForSelect={ this.state.scenariosForSelect }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( FunnelScenarioWrappedComponent )
}

export default FunnelScenarioHOC
