import React, { useEffect } from 'react'
import {
  Container, Row, Col, Button,
  Card, CardHeader, CardBody
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import OpeningHourHOC from './actions'

import Lang from 'Lang/General'

const OpeningHour = props => {

  useEffect( () => {
    props.getOpeningHour()
  }, [] )

  return (
    <>
      <PageTitle
        heading={ Lang[ 'INJOY360_OPENING_HOUR' ][ props.selectedLanguage ] }
        icon="pe-7s-file icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardHeader>{ Lang[ 'INJOY360_OPENING_HOUR_HEADER' ][ props.selectedLanguage ] }</CardHeader>
                  <CardBody>
                    <CKEditor
                      editor={ ClassicEditor }
                      onReady={ editor => {
                        editor.editing.view.change(( writer ) => {
                          writer.setStyle( 'height', '400px', editor.editing.view.document.getRoot() )
                        })
                      }}
                      data={ props.openingHour }
                      onChange={ ( event, editor ) => {
                        let data = editor.getData()
                        props.onChangeOpeningHourHOC( "openingHour", data )
                      }}
                      config={{
                        heading: {
                          options: [
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'span', view: 'div', title: 'Span' }
                          ]
                        },
                        link: {
                          decorators: {
                            openInNewTab: {
                              mode: 'manual',
                              label: 'Open in a new tab',
                              attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                              }
                            }
                          }
                        }
                      }}
                    />
                    {
                      (
                        (
                          !_.isEmpty( props.selectedCenter.center ) &&
                          (
                            ( props.profile.id === props.selectedCenter.center.client.user_id ) ||
                            ( 
                              props.profile.role === 'Center Admin' &&
                              _.findIndex( props.profile.centers, { id: props.selectedCenter.center.id } ) > -1
                            )
                          )
                        ) ||
                        props.profile.role === 'Super Admin' ||
                        props.profile.role === 'Management Admin'
                      ) && (
                        <Button
                          color="primary"
                          className="float-right mt-2"
                          onClick={ () => props.isNewOpeningHour
                            ? props.createOpeningHour( props.openingHour )
                            : props.updateOpeningHour( props.openingHour )
                          }>
                          { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
                        </Button>
                      )
                    }
                  </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
      { props.onLoadOpeningHour && <LoadingOverlay /> }
    </>
  )
}

export default OpeningHourHOC( OpeningHour )
