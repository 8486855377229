import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter,
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import Select from 'react-select'
import _ from 'lodash'

import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import Lang from 'Lang/General'

const UpdateRecipientGroupForm = ( props ) => {
  const langButton = [ 'en', 'de', 'es', 'it', 'fr', 'nl' ]
  const [ selectedTitleLang, setLangTitle ] = useState( 'en' )
  const [ activeTab, updateActiveTab ] = useState( '1' )

  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( props.selectedRecipientGroup )
    let dataKey = ''
    tmp[key] = value

    props.onChangeRecipientGroupHOC( 'selectedRecipientGroup', tmp )
  }

  return(
    <Modal
      isOpen={ props.showUpdateRecipientGroup }
      size="xl">
      <ModalHeader toggle={ () => props.onChangeRecipientGroupHOC( 'showUpdateRecipientGroup', false ) }>{ Lang['RECIPIENT_GROUP_DETAILS'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        <Container className="mt-3 mb-5">
          <Row>
            <Col md={ 12 }>
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label for="funnel_title" sm={ 2 }>{ Lang['NAME'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red'}}>*</span></Label>
                      <div className="d-flex pt-1">
                        {
                          langButton.map( button => {
                            return(
                              <Button
                                className="btn-square btn-wide mr-0" size="sm"
                                color={ `${ selectedTitleLang === button ? 'primary' : 'light' }` }
                                onClick={ () => setLangTitle( button ) }>{ button }</Button>
                            )
                          })
                        }
                      </div>
                      <Input
                        name="funnel_title" id="funnel_title"
                        placeholder={ Lang[ 'NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                        value={ props.selectedRecipientGroup.name[selectedTitleLang] }
                        onChange={ e => {
                          let tmp = {}
                          tmp[selectedTitleLang] = e.target.value

                          onChangeData( 'name', tmp )
                        }}
                        />
                    </FormGroup>
                    <FormGroup>
                      <Label for="status">{ Lang[ 'STATUS' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        invalid={ /*errorMessage && errorMessage.status ? true :*/ false }
                        value={ props.selectedRecipientGroup.status }
                        onChange={ e => onChangeData( 'status', e.target.value ) }
                        disabled>
                        <option>{ Lang[ 'ACTIVE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</option>
                        <option>{ Lang[ 'INACTIVE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</option>
                      </Input>
                      <FormFeedback>{ /*errorMessage && errorMessage.status ? errorMessage.status : '' */}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ 'Recipients' }</Label>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                          props.selectedRecipientGroup.recipients && props.selectedRecipientGroup.recipients.length > 0 && props.selectedRecipientGroup.recipients.map( item => {
                            return(
                              <div
                                style={{
                                  background: '#c2c2c2',
                                  color: '#000',
                                  height: '35px',
                                  borderRadius: '5px',
                                  width: 'fit-content',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                className="mr-2 p-2 mt-2">
                                <span style={{ textAlign: 'center' }}>{ item.email }</span>
                              </div>
                            )
                          })
                        }
                        </div>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          className="mr-2"
          color="primary"
          onClick={ () => {
            props.updateRecipientGroup( props.selectedRecipientGroup.id, props.selectedRecipientGroup )
          } }>{ 'Submit' }</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( UpdateRecipientGroupForm )
