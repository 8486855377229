import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post } from 'utils/axios-token'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      settings: {},
      newOLSSettings: {
        "center_id": 0,
        consultant: {},
        contract_period: '8 weeks',
        "is_pure_woman_studio": false,
        "studio_name": '',
        "road": "",
        "postcode": "",
        "contact_person": "",
        "contact_email": "",
        "contact_telephone": "",
        "lead_receive_email_address": "",
        "facebook_url": "",
        campaign_id: 0,
        "data_protection_url": "",
        "monthly_fb_click_through_budget": 0,
        "is_place_name_standard": true,
        "is_aciso_website_online": true,
        "fb_advertising_account_id": "",
        "monthly_google_budget": 0,
        "google_ads_customer_number": "",
        "comment": "",
        "is_consent_to_data_processing": false,
        "is_center_based" : false,
        "is_one_timer": true
      },
      campaigns: [],
      hasOLSSettings: false,
      settingsCenterId: '',
      showPromptModal: false,
      olsSettingsError: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeOLSSettingsHOC = ( key, value ) => this.setState({ [key]: value }, () => {
      if( key === 'settingsCenterId' ){
        let tmpNewOLSSettings = _.cloneDeep( this.state.newOLSSettings )
        tmpNewOLSSettings.center_id = parseInt( value )
        tmpNewOLSSettings.is_center_based = true
        tmpNewOLSSettings.is_one_timer = false
        this.getOLSSettings( false )
        this.setState({ newOLSSettings: tmpNewOLSSettings })
      }
    })

    getCampaigns = query => Get(
      `/api/v1/ols_campaigns?query=${ query }`,
      this.getCampaignsSuccess,
      this.getCampaignsError,
      this.load
    )
    getCampaignsSuccess = payload => this.setState({ campaigns: payload.data })
    getCampaignsError = error => this.requestError( error )

    getOLSSettings = isOneTimer => Get(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.settingsCenterId }` }/ols_settings`,
      this.getOLSSettingsSuccess,
      this.getOLSSettingsError,
      this.load
    )
    getOLSSettingsSuccess = payload => this.setState({ settings: payload, hasOLSSettings: true })
    getOLSSettingsError = error => {
      this.setState({ hasOLSSettings: false })
    }

    createOLSSettings = ( data, isOneTimer ) => Post(
      `/api/v1/ols_settings`,
      data,
      () => this.createOLSSettingsSuccess( isOneTimer ),
      this.createOLSSettingsError,
      this.load
    )
    createOLSSettingsSuccess = isOneTimer => {
      this.requestSuccess( Lang[ 'OLS_SETTINGS_IS_CREATED_SUCCESSFULLY' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ showPromptModal: false, olsSettingsError: {} })
      this.getOLSSettings( isOneTimer )
    }
    createOLSSettingsError = error => {
      this.setState(
        { showPromptModal: false },
        () => (
          typeof error !== 'string' && this.setState({ 
            olsSettingsError: _.reduce( 
              error,  
              ( resError, errVal, errKey ) => ({ 
                ...resError, 
                [ errKey ] : errVal.replace( /_/g, ' ' )
              }),
              {}
            )
          })
        )
      )
      this.requestError( Lang[ 'OLS_SETTINGS_CANT_BE_SAVED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getOLSSettings={ this.getOLSSettings }
          onChangeOLSSettingsHOC={ this.onChangeOLSSettingsHOC }
          settings={ this.state.settings }
          createOLSSettings={ this.createOLSSettings }
          newOLSSettings={ this.state.newOLSSettings }
          getCampaigns={ this.getCampaigns }
          campaigns={ this.state.campaigns }
          onLoadSettings={ this.state.loading }
          hasOLSSettings={ this.state.hasOLSSettings }
          showPromptModal={ this.state.showPromptModal }
          olsSettingsError={ this.state.olsSettingsError } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
