import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row
} from 'reactstrap'
import { toast } from 'react-toastify'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmDeleteModal from 'components/Indicator/Prompt'
import _ from 'lodash'

import RenderDOM from '../Builder/RenderDOM'

import Lang from 'Lang/General'

class ModalFormFill extends Component {
  state = {
    dom: [],
    formValues: [],
    formContent: [],
    currentPage: 1,
    editingPage: 1,
    foundObject: {},
    showConfirmDeselectPage: false,
    showConfirmCloseForm: false
  }

  componentDidMount = () => this.mountForm()

  componentDidUpdate = pp => {
    if( pp.selectedDigitalOrderForm.id !== this.props.selectedDigitalOrderForm.id || _.isEmpty( pp.selectedDigitalOrderForm ) ){
      this.setState({ formValues: [], dom: [], currentPage: 1 }, () => this.mountForm() )
    }
  }

  mountForm = () => {
    let tmpDom = _.cloneDeep( this.state.dom )
    let tmpFormValues = this.props.selectedDigitalOrderForm.values
      ? _.cloneDeep( this.props.selectedDigitalOrderForm.values )
      : []
    tmpFormValues.map( item => {
      if( item[ 'isPrefilled' ] && !_.isEmpty( item[ 'isPrefilled' ] ) ){
        switch ( item[ 'isPrefilled' ] ){
          case 'CENTER_NAME' :
            item.value = ( 
              this.props.data.selectCenterReducer && 
              this.props.data.selectCenterReducer.selectedCenter && 
              this.props.data.selectCenterReducer.selectedCenter.center &&
              !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter.center.center_name ) 
            ) ? this.props.data.selectCenterReducer.selectedCenter.center.center_name : ''
            break
          case 'CONSULTANT_NAME' :
            item.value = ( 
              this.props.data.selectCenterReducer && 
              this.props.data.selectCenterReducer.selectedCenter && 
              this.props.data.selectCenterReducer.selectedCenter.center &&
              this.props.data.selectCenterReducer.selectedCenter.center.consultant &&
              this.props.data.selectCenterReducer.selectedCenter.center.consultant.user 
            ) ? (
              `${ this.props.data.selectCenterReducer.selectedCenter.center.consultant.user.first_name } ${ this.props.data.selectCenterReducer.selectedCenter.center.consultant.user.last_name }`
            ) : ''
            break
        }
      }
    })
    tmpDom.push( this.props.selectedDigitalOrderForm.content[0] )
    this.props.onChangeDigitalOrderFormHOC( 'uploadedMedia', [] ) 
    return this.setState({ dom: tmpDom, formValues: tmpFormValues })
  }

  onChangeFormBuilderState = ( key, val ) => this.setState({ [key]: val })

  onPatchFormValues = async ( index, key, val, isReset ) => {
    let tmp = _.cloneDeep( this.state.formValues )
    tmp[ index ][ key ] = val
    if( isReset ){
      delete tmp[ index ][ 'isRequired' ]
      delete tmp[ index ][ 'validation' ]
    }
    return this.setState({ formValues: tmp })
  }

  findObjectAndPatch = ( data, target ) => {
    data.map(( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1, target )
      } else {
        if( item.children ) {
          this.findObjectAndPatch( item.children, target )
        }
      }
    })
    return data
  }

  findObjectByMapWithProp = ( data, mapWith ) => {
    data.map(( item, index ) => {
      if( item.mapWith === mapWith ) {
        this.setState({ foundObject: item })
      } else {
        if( item.children ) {
          return this.findObjectByMapWithProp( item.children, mapWith )
        }
      }
    })
  }

  togglePageSelection = async ( pageToEdit ) => {
    if ( !pageToEdit ) {
      pageToEdit = this.state.currentPage
    }
    await this.setState({ editingPage: pageToEdit })

    if ( this.state.dom[0].children[ pageToEdit - 1 ].selected ) {
      this.setState({ showConfirmDeselectPage: true })
    } else {
      this.onClickTogglePageSelection( )
    }
  }

  onClickTogglePageSelection = async ( ) => {
    let tmpDOM = _.cloneDeep( this.state.dom )
    let tmpFormValues = _.cloneDeep( this.state.formValues )
    let pageSelectorCheckbox
    
    let pageDOM = tmpDOM[0].children[ this.state.editingPage - 1 ]
    pageDOM.selected = !pageDOM.selected
    tmpDOM[0].children[ this.state.editingPage - 1 ] = pageDOM

    await this.findObjectByMapWithProp( pageDOM.children[0].children, 'page-selector' )
    pageSelectorCheckbox = this.state.foundObject
    if ( pageDOM.selected ) {
      tmpFormValues[ pageSelectorCheckbox.dataMapIndex ][ 'value' ].push( pageSelectorCheckbox.selections[0].value )
    } else {
      this.clearFormValuesOfPage( tmpFormValues, this.state.editingPage )
    }

    this.setState({
      dom: tmpDOM,
      formValues: tmpFormValues,
      showConfirmDeselectPage: false
    })
  }

  clearFormValuesOfPage = ( tmpFormValues, pageToEdit ) => {
    _.filter( 
      tmpFormValues, 
      formVal => !formVal.page || ( formVal.page && formVal.page === pageToEdit ) 
    ).map( formVal => { 
      if( ['textField', 'textarea', 'dateField', 'inputFile'].indexOf( formVal.type ) !== -1  ) {
        formVal.value = ""
      } else if ( ['radio', 'checkbox'].indexOf( formVal.type ) !== -1  ) {
        formVal.value = []
      }
    })
  }

  jumpToPage = ( pageType ) => {
    let page = this.state.currentPage
    if ( pageType === "OVERVIEW" ) {
      for ( let i = 0; i < this.state.dom[0].totalPages; i++ ) {
        if ( this.state.dom[0].children[i].mapWith === "content" ) {
          page = this.state.dom[0].children[i].page
          break
        }
      }
    } else if ( pageType === "SUMMARY" ) {
      for ( let i = 0; i < this.state.dom[0].totalPages; i++ ) {
        if ( this.state.dom[0].children[i].mapWith === "summary" ) {
          page = this.state.dom[0].children[i].page
          break
        }
      }
    } else if ( pageType === "NEXT" ) {
      if ( this.state.currentPage < this.state.dom[0].totalPages ) {
        page = this.state.currentPage + 1
      }
    } 
    this.onChangeFormBuilderState( 'currentPage', page ) 
  }

  onClickSubmitForm = () => {
    let tmp = {
      values: this.state.formValues,
      uuid: _.isEmpty( this.props.match.params ) ? '' : this.props.match.params.order_id,
      remark: [],
      digital_order_form_id: this.props.selectedDigitalOrderForm.id
    }
    let tmpFormValues = _.cloneDeep( this.state.formValues )
    tmpFormValues.map( ( item, index ) => {
      if( item.type === 'inputFile' ){
        tmpFormValues[index].value = this.props.uploadedMedia.filter( x => x.formContentId === item.id )
      } else if ( item.type === 'textarea' ) {
        item.value = item.value.trim()
      } else if ( item.type === 'dateField' && item.value === 'Invalid date') {
        item.value = ''
      }
    })
    tmp.values = tmpFormValues

    this.props.createSubmittedForm( this.props.data.selectCenterReducer.selectedCenter.center.id, tmp )
  }

  render = () => {
    let {
      showFillSubmittedForm,
      onChangeDigitalOrderFormHOC,
      createSubmittedFormMedia,
      removeSubmittedFormMedia,
      onLoadDigitalOrderForms
    } = this.props
    return(
      <Modal isOpen={ showFillSubmittedForm } size='xl' >
        <ModalHeader toggle={ () => this.setState({ showConfirmCloseForm: true }) }></ModalHeader>
        <ModalBody>
          <Row>
            <div className='col-md-12' >
            { 
              this.state.dom.map( item => {
                return <RenderDOM
                  content={ item }
                  onChangeFormBuilderState={ this.onChangeFormBuilderState }
                  mode={ 'fill' }
                  formValues={ this.state.formValues }
                  onPatchFormValues={ this.onPatchFormValues }
                  onClickSubmitForm={ this.onClickSubmitForm }
                  createSubmittedFormMedia={ createSubmittedFormMedia }
                  removeSubmittedFormMedia={ removeSubmittedFormMedia }
                  uploadedMedia={ this.props.uploadedMedia }
                  currentPage={ this.state.currentPage }
                  dom={ this.state.dom }
                  findObjectAndPatch={ this.findObjectAndPatch }
                  data={ this.props.data }
                  togglePageSelection={ this.togglePageSelection }
                  currentLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }
                /> 
              }) 
            }
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.jumpToPage( "OVERVIEW" ) }>
            { Lang[ 'MEDIA_OVERVIEW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          </Button>
          <Button
            color='primary'
            disabled={ !_.isEmpty( this.state.dom[0] ) && this.state.currentPage >= this.state.dom[0].totalPages }
            onClick={ () => this.jumpToPage( "NEXT" ) }>
            { Lang[ 'NEXT_MEDIUM' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          </Button>
          <Button
            color='primary'
            onClick={ () => this.jumpToPage( "SUMMARY" ) }>
            { Lang[ 'TO_THE_SUMMARY' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          </Button>
          <Button
            color='link'
            onClick={ () => this.setState({ showConfirmCloseForm: true }) }>
            { Lang[ 'CLOSE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          </Button>
        </ModalFooter>
        <ConfirmDeleteModal
          showPromptModal={ this.state.showConfirmDeselectPage }
          content={ Lang[ 'CONFIRM_DESELECT_FORM_PAGE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          onClickYes={() => this.onClickTogglePageSelection( this.state.editingPage )}
          onClickNo={() => this.setState({ showConfirmDeselectPage: false })}
          lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
        <ConfirmDeleteModal
          showPromptModal={ this.state.showConfirmCloseForm }
          content={ Lang[ 'CONFIRM_CLOSE_DOF' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          onClickYes={() => onChangeDigitalOrderFormHOC( 'showFillSubmittedForm', false )}
          onClickNo={() => this.setState({ showConfirmCloseForm: false })}
          lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
        { onLoadDigitalOrderForms && <LoadingOverlay/> }
      </Modal>
    )
  }
}

export default ModalFormFill