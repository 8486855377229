import React, { Component } from 'react'
import _ from 'lodash'
import { Button, UncontrolledTooltip } from 'reactstrap'

import Banner from './Banner'
import ShortcutsHOC from './actions'

class ShortcutMenus extends Component {
  state= {
    shortcutmenu: [],
    isOpen: false,
    selectedInfo: "",
    selectedSource: ""
  }

  toggleModalState = () => {
    this.setState({isOpen:!this.state.isOpen})
  }

  render = () => {
    return (
      <>
          {
            this.props.data.shortcutReducer.assignedSidemenu && this.props.data.shortcutReducer.assignedSidemenu.map( ( item, index ) => {
              return(
                <div className="main-button_title_cont">
                  <Button
                    onClick={() => {
                      if ( item.to.match( /^https?:\/\//i ) ) {
                        window.open( item.to )
                      } else if ( item.to.length > 0 ){
                        this.props.history.push( item.to.substring( item.to.indexOf( '#' ) + 1, item.to.length ) )
                      }
                    }}
                    id={ `shortcut-${ index }` }
                    className="main-button_title">
                    <i className={ item.icon } style={{ color: '#04254e', fontSize: '15px' }}/>
                    <span className="ml-1" style={{ color: '#04254e' }}>{ item.label[ this.props.lang ] }</span>
                  </Button>
                </div>
              )
            })
          }
        <Banner isOpen={this.state.isOpen} desc={this.state.selectedInfo} src={this.state.selectedSource} toggleModalState={this.toggleModalState}/>
      </>
    )
  }
}

export default ShortcutsHOC( ShortcutMenus )
