import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Select from 'react-select'
import cx from 'classnames'
import _ from 'lodash'

import { setSelectCenter } from 'actions/selectCenter'
import { getToken } from 'utils/findCookieToken'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import tmpLang from 'Lang/General'
import HeaderLogo from './Logo'
import Userbox from './Userbox'
import { SITE_DEPENDENT_ROLES } from './assets'

const Header = ({
  headerBackgroundColor,
  enableMobileMenuSmall,
  enableHeaderShadow,
  history,
  data,
  onClickToggleProfileModal,
  setSelectCenter,
  enableClosedSidebar
}) => {
  const [ arkSiteNav, updateArkSiteNav ] = useState( [] )
  const [ cockpitCenters, setCockpitCenters ] = useState( [] )

  useEffect(() => {
    if( data.profileReducer.profile.centers ) {
      if( _.isEmpty( data.selectCenterReducer.selectedCenter ) && data.profileReducer.profile.centers.length > 0 ) {
        setSelectCenter({ center: data.profileReducer.profile.centers[ 0 ] })
      }
      let tmpArkSiteNav = _.cloneDeep( arkSiteNav )
      let isSiteDependentRole = _.indexOf( SITE_DEPENDENT_ROLES, data.profileReducer.profile.role ) > -1
      data.profileReducer.profile.centers.map( item => {
        if( 
          item.site_domain &&
          !_.find( tmpArkSiteNav, { label: item.center_name } ) &&
          (
            !isSiteDependentRole ||
            ( isSiteDependentRole && item.site?.[0]?.status === 'ACTIVE' )
          )
        ){
          tmpArkSiteNav.push({
            icon: 'pe-7s-note2',
            label: item.center_name,
            to: `https://${ item.site_domain }/admin/login/${ getToken( 'ACISO_SESSION_TOKEN' ) }`,
            externalLink: true
          })
        }
      })
      updateArkSiteNav( 
        _.orderBy( 
          tmpArkSiteNav, 
          item => item.label.toLowerCase(),
          [ 'asc' ]
        )
      )

      setCockpitCenters( 
        _.orderBy( 
          data.profileReducer.profile.centers || [], 
          item => item.label.toLowerCase(),
          [ 'asc' ]
        )
      )
    }
  }, [ data.profileReducer.profile.centers ])

  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        className={ cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow}) }
        transitionName="HeaderAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 1500 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <HeaderLogo />
        <div className={ cx( "app-header__content", { 'header-mobile-open': enableMobileMenuSmall } ) }>
          <div className="app-header-left">
            <div style={{ marginLeft: `${ enableClosedSidebar ? '80px' : '15px' }`, alignItems: 'center' }}>
              <p style={{
                fontSize: '10px'
              }} className="app-sidebar__heading mr-2 mb-0">{tmpLang['SELECT_CENTER'][ data.languageReducer.userSettings.admin_panel_language ]}</p>
              {
                data.selectCenterReducer?.selectedCenter?.from === 'ark-site-redirect' ? (
                  <p>{ data.selectCenterReducer.selectedCenter.center.center_name }</p>
                ) : (
                  <Select
                    isDisabled={ cockpitCenters.length <= 1 }
                    styles={{
                      container: () => ({
                        width: '200px',
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        width: state.selectProps.width,
                        borderBottom: '1px dotted pink',
                        color: state.selectProps.menuColor,
                        padding: 20,
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? '#b9e0f0' :'white',
                        color: state.isSelected ? 'blue' : 'black',
                        padding: 20,
                      })
                    }}
                    value={[ data.selectCenterReducer.selectedCenter?.center ]}
                    placeholder= { tmpLang[ 'SEARCH_CENTER' ][ data.languageReducer.userSettings.admin_panel_language ] }
                    options={ cockpitCenters }
                    onChange={ val => setSelectCenter( { center: val }, true ) }
                    {
                      ...( 
                        cockpitCenters.length <= 1 
                          ? { components: { DropdownIndicator:() => null, IndicatorSeparator:() => null } }
                          : {}
                      )
                    }
                  />
                )
              }
            </div>
            {
              arkSiteNav.length > 0 && (
                <div style={{ width: '250px', marginLeft: '80px', alignItems: 'center' }}>
                  <p 
                    style={{ fontSize: '10px', }}
                    className="app-sidebar__heading mr-2 mb-0">
                    { tmpLang['LOGIN_TO_WEBSITE'][ data.languageReducer.userSettings.admin_panel_language ] }
                  </p>
                  <div className='d-flex flex-row flex-nowrap' >
                    <Select
                      isDisabled={ arkSiteNav.length === 1 }
                      styles={{
                        container: () => ({
                          width: '200px',
                          marginRight: '10px'
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          width: state.selectProps.width,
                          borderBottom: '1px dotted pink',
                          color: state.selectProps.menuColor,
                          padding: 20,
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected ? '#b9e0f0' :'white',
                          color: state.isSelected ? 'blue' : 'black',
                          padding: 20,
                        })
                      }}
                      placeholder= { tmpLang['SEARCH_SITE'][ data.languageReducer.userSettings.admin_panel_language ] }
                      options={ arkSiteNav }
                      onChange={ e => window.open( e.to, "blank") }
                      getOptionValue={ option => option.label } 
                      value={ arkSiteNav.length === 1 ? arkSiteNav : null }
                      {
                        ...( 
                          arkSiteNav.length <= 1 
                            ? { components: { DropdownIndicator:() => null, IndicatorSeparator:() => null } }
                            : {}
                        )
                      }
                    />
                    { 
                      arkSiteNav.length === 1 && <Button
                        color="primary"
                        onClick={ () => window.open( arkSiteNav[0].to, "blank" ) } >
                        { tmpLang[ 'GO_TO_ARK_SITE' ][ data.languageReducer.userSettings.admin_panel_language ] }
                      </Button>
                    }
                  </div>
                </div>
              )
            }
          </div>
          <div className="app-header-right">
            <Userbox
              onClickToggleProfileModal={ onClickToggleProfileModal }
              history={ history }
              profileReducer={ data.profileReducer }
              selectCenterReducer={ data.selectCenterReducer }
              lang={ data.languageReducer.userSettings.admin_panel_language ? data.languageReducer.userSettings.admin_panel_language : 'en' } />
          </div>
        </div>
      </ReactCSSTransitionGroup>
      { data.ajaxReducer.ajaxCallProgress > 0 && <LoadingOverlay /> }
    </>
  )
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar
})

export default connect( mapStateToProps, {
  setSelectCenter
} )( Header )
