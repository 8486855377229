import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Button
} from 'reactstrap'
import Lang from 'Lang/General'

const ClosingAlert = ({
  label,
  isOpen,
  handleModalClose,
  confirmAction,
  selectedLanguage
}) => {
  return(
    <Modal
      style={{ zIndex: 999 }}
      isOpen={ isOpen }>
      <ModalBody>
        <Label>{ label }</Label>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={ handleModalClose }>{ Lang[ 'NO' ][ selectedLanguage ] }</Button>
        <Button color="primary" onClick={ confirmAction }>{ Lang[ 'YES' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ClosingAlert
