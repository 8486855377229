import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  UncontrolledTooltip,
  Form,
  FormGroup,
  CardBody,
  Label,
  Input,
  CardHeader
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import ArkPagination from 'components/Pagination'
import AlertNotSubscribe from 'components/Alert'
import BlacklistedRecipientHOC from './actions'
import Create from './Create'
import View from './View'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const BlacklistedRecipientListings = props => {

  const [ subscribedPackage, setSubscribePackage ] = useState( true )

  useEffect( () => {
    let tmpIndex = _.findIndex( props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Marketing Funnel' })
    setSubscribePackage( tmpIndex > -1 )
    props.getAllBlacklistedRecipient( convertSearchObjectToBase64({ is_paginated: true, page: 1 }) )
  }, [])

  return (
    <>
      <PageTitle
        heading={ Lang[ 'BLACKLISTED_RECIPIENTS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-news-paper icon-gradient bg-happy-itmeo"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_RECIPIENT' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            onClick: () => {
              props.onChangeBlacklistedRecipientHOC( 'showCreateBlacklistedRecipient', true )
            },
            display: false
          }
        ]}
         />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          {
            !subscribedPackage && (
              <AlertNotSubscribe { ...props } />
            )
          }
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                <CardBody>
                  <Form onSubmit={ e => e.preventDefault()}>
                    <FormGroup>
                      <Label>{ Lang[ 'EMAIL' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.blacklistedRecipientSearchParams[0].param }
                        onChange={ e => {
                          let tmp = _.cloneDeep( props.blacklistedRecipientSearchParams )
                          tmp[ 0 ][ 'param' ] = e.target.value
                          return props.onChangeBlacklistedRecipientHOC( 'blacklistedRecipientSearchParams', tmp )
                        }} />
                    </FormGroup>
                    <div className="d-flex">
                      <Button
                        color="primary"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          let tmp = {
                            is_paginated: true,
                            page: 1,
                            filter: {
                              email: {
                                $like: `%${props.blacklistedRecipientSearchParams[0].param}%`
                              }
                            }
                          }
                          props.getAllBlacklistedRecipient( convertSearchObjectToBase64( tmp ) )
                        }}>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      <Button
                        color="danger"
                        style={{ marginLeft: '10px' }}
                        onClick={() => {
                          let tmp = [
                            {
                              label: 'Email',
                              value: 'email',
                              param: ''
                            },
                          ]
                          props.onChangeBlacklistedRecipientHOC( 'blacklistedRecipientSearchParams', tmp )
                          props.getAllBlacklistedRecipient( convertSearchObjectToBase64({ page: 1, is_paginated: true }) )
                        }}>{ Lang[ 'RESET' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={ 12 }>
              <Card className="main-card mb-3 p-4">
                <ReactTable
                  NoDataComponent={ () => <span></span> }
                  data={ props.blacklistedRecipients.data }
                  columns={[
                    {
                      Header: Lang[ 'EMAIL' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'email'
                    },
                    {
                      Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: "id",
                      Cell: ({row}) => (
                        <div>
                          <Button
                            id={ `blacklisted-recipient-${ row.id }-view` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => {
                              props.getSelectedBlacklistedRecipient( row.id )
                              props.onChangeBlacklistedRecipientHOC( 'showViewBlacklistedRecipient', true )
                            } }
                            disabled={ !subscribedPackage }>
                            <i className="pe-7s-look"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `blacklisted-recipient-${ row.id }-view` }
                            placement="top">
                            { Lang[ 'VIEW' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                          </UncontrolledTooltip>
                        </div>
                      )
                    }
                  ]}
                  showPagination={ false }
                  defaultPageSize={ 10 } />
                  {
                    props.blacklistedRecipients.data && props.blacklistedRecipients.data.length > 0 && (
                      <ArkPagination
                        meta={ props.blacklistedRecipients.meta }
                        onChangePage={ page => {
                          let tmp = {
                            page: page,
                            is_paginated: true
                          }
                          let tmpFilter = {}

                          props.blacklistedRecipientSearchParams.map( searchItem => {
                            if( !_.isEmpty( searchItem.param ) && searchItem.value !== 'recipient_groups' ){
                              tmpFilter = {
                                ...tmpFilter,
                                [searchItem.value]: searchItem.value === 'email' ? { $like: `%${ searchItem.param }%` } : searchItem.param
                              }
                            }
                            if( searchItem.value === 'recipient_groups' && !_.isEmpty( searchItem.param ) ){
                              tmpFilter = {
                                ...tmpFilter,
                                $or: searchItem.ids
                              }
                            }
                          })

                          let tmpString = convertSearchObjectToBase64( !_.isEmpty( tmpFilter ) ? { ...tmp, filter: tmpFilter } : tmp )
                          props.getAllBlacklistedRecipient( tmpString )
                        }} />
                    )
                  }
              </Card>
            </Col>
          </Row>
        </Container>
        <Create
          {...props} />
        {/*<Update
          {...props} />*/}
        <View
          {...props} />
      </ReactCSSTransitionGroup>
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  BlacklistedRecipientHOC,
  connect( mapStateToProps ),
)( BlacklistedRecipientListings )
