import React from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"

import Lang from 'Lang/General'

registerPlugin( FilePondPluginImagePreview )

const PeriodStartContracts = [
  {
    label: 'January 1st',
    value: `${ Moment().format( 'YYYY' ) }-01-01T00:00:00+00:00`
  },
  {
    label: 'February 2nd',
    value: `${ Moment().format( 'YYYY' ) }-02-01T00:00:00+00:00`
  },
  {
    label: 'March 1st',
    value: `${ Moment().format( 'YYYY' ) }-03-01T00:00:00+00:00`
  },
  {
    label: 'April 1st',
    value: `${ Moment().format( 'YYYY' ) }-04-01T00:00:00+00:00`
  },
  {
    label: 'May 1st',
    value: `${ Moment().format( 'YYYY' ) }-05-01T00:00:00+00:00`
  },
  {
    label: 'June 1st',
    value: `${ Moment().format( 'YYYY' ) }-06-01T00:00:00+00:00`
  },
  {
    label: 'July 1st',
    value: `${ Moment().format( 'YYYY' ) }-07-01T00:00:00+00:00`
  },
  {
    label: 'August 1st',
    value: `${ Moment().format( 'YYYY' ) }-08-01T00:00:00+00:00`
  },
  {
    label: 'September 1st',
    value: `${ Moment().format( 'YYYY' ) }-09-01T00:00:00+00:00`
  },
  {
    label: 'October 1st',
    value: `${ Moment().format( 'YYYY' ) }-10-01T00:00:00+00:00`
  },
  {
    label: 'November 1st',
    value: `${ Moment().format( 'YYYY' ) }-11-01T00:00:00+00:00`
  },
  {
    label: 'December 1st',
    value: `${ Moment().format( 'YYYY' ) }-12-01T00:00:00+00:00`
  }
]

const Create = ({
  isOneTimer,
  showCreateModal,
  onChangeCampaignHOC,
  newCampaignData,
  userSettings,
  createCampaign,
  centers,
  onLoadOLSCampaigns
}) => {

  const updateCenterCampaignData = ( key, val ) => {
    let tmp = _.cloneDeep( newCampaignData )
    tmp[ key ] = val
    return onChangeCampaignHOC( 'newCampaignData', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateModal }
      size={ 'md' }
      toggle={() => onChangeCampaignHOC( 'showCreateModal', false )}>
      <ModalHeader toggle={() => onChangeCampaignHOC( 'showCreateModal', false )}>{ Lang[ 'CREATE_CAMPAIGN' ][ userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>{ Lang[ 'CAMPAIGN_NAME' ][ userSettings.admin_panel_language ] }</Label>
          <Input
            type="text"
            value={ newCampaignData.title }
            onChange={ e => {
              updateCenterCampaignData( 'title', e.target.value )
            }} />
        </FormGroup>
        {
          centers.length > 0 && <FormGroup>
            <Label>{ Lang[ 'CENTER' ][ userSettings.admin_panel_language ] }</Label>
            <Input
              disabled
              type='select'
              value={ newCampaignData.center_id }
              onChange={ e => updateCenterCampaignData( 'center_id', parseInt( e.target.value ) ) } >
              { centers.map( center => <option value={ center.id } >{ center.center_name }</option> ) }
            </Input>
          </FormGroup>
        }
        <FormGroup>
          <Label>{ Lang[ 'FACEBOOK_BUDGET' ][ userSettings.admin_panel_language ] }</Label>
          <Input
            type="number"
            value={ newCampaignData.fa_budget }
            onChange={ e => {
              updateCenterCampaignData( 'fa_budget', parseInt( e.target.value, 10 ) )
            }}
            placeholder={ 'EUR' } />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'GOOGLE_BUDGET' ][ userSettings.admin_panel_language ] }</Label>
          <Input
            type="number"
            value={ newCampaignData.ga_budget }
            onChange={ e => {
              updateCenterCampaignData( 'ga_budget', parseInt( e.target.value, 10 ) )
            }}
            placeholder={ 'EUR' } />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'START' ][ userSettings.admin_panel_language ] }</Label>
          <Input
            type="select"
            value={ newCampaignData.start_date }
            required
            onChange={ e => updateCenterCampaignData( 'start_date', e.target.value ) }>
            <option>{ 'Please select one from below' }</option>
            {
              PeriodStartContracts.map( item => {
                if( item.value > Moment().format() ) {
                  return (
                    <option key={ item.value } value={ item.value }>{ item.label }</option>
                  )
                }
              })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'REMARK' ][ userSettings.admin_panel_language ] }</Label>
          <Input
            type="text"
            value={ newCampaignData.remark }
            onChange={ e => {
              updateCenterCampaignData( 'remark', e.target.value )
            }} />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'PREVIEW_IMAGE' ][ userSettings.admin_panel_language ] }</Label>
          <FilePond
            allowMultiple={ false }
            allowFileTypeValidation={ true }
            allowImagePreview={ true }
            acceptedFileTypes='image/*'
            onaddfile={( error, file ) => {
              const reader = new FileReader()
              reader.onload = e => {
                updateCenterCampaignData( 'preview_image_raw', e.target.result )
              }
              reader.readAsDataURL( file.file )
            }}
            onremovefile={ e => {
              updateCenterCampaignData( 'preview_image_raw', '' )
            }}>
          </FilePond>
        </FormGroup>
        { onLoadOLSCampaigns && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ () => createCampaign( isOneTimer, newCampaignData ) } >
          { Lang[ 'SUBMIT' ][ userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({
  userSettings: state.languageReducer.userSettings
})

export default connect( mapStateToProps )( Create )
