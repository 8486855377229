import React from 'react'

import Column from './components/Column'
import Image from './components/Image'
import TextField from './components/TextField'
import TextArea from './components/TextArea'
import ButtonComponent from './components/Button'
import RadioButton from './components/RadioButton'
import TextComponent from './components/Text'
import CheckboxComponent from  './components/Checkbox'
import InputFile from './components/InputFile'
import DateField from './components/DateField'

const RenderDOM = props => {
  switch( props.content.type ) {
    case 'Col': return <Column { ...props } />
    case 'Image': return <Image { ...props } />
    case 'TextField': return <TextField { ...props } />
    case 'TextArea': return <TextArea { ...props } />
    case 'Button': return <ButtonComponent { ...props } />
    case 'Radio': return <RadioButton { ...props } />
    case 'RadioFlexible': return <RadioButton { ...props } />
    case 'Text': return <TextComponent { ...props } />
    case 'Checkbox': return <CheckboxComponent { ...props } />
    case 'CheckboxFlexible': return <CheckboxComponent { ...props } />
    case 'InputFile': return <InputFile { ...props } />
    case 'DateField': return <DateField { ...props } />
    default: return <div></div>
  }
}

export default RenderDOM
