import React, { Component } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from 'reactstrap'
import _ from 'lodash'
import { compose } from 'redux'
import ReactTable from 'react-table'

import Lang from 'Lang/General'
import LangTepe from 'Lang/TePe'

let TmpLang = window.location.host.indexOf( 'tennis' ) > -1 ? LangTepe : Lang

class ViewAllNews extends Component {
  closeModal = () => {
    this.props.onChangeNewsHOC( 'showAllNews', false )
    if ( window.location.href.indexOf( 'all-news' ) > -1 ) {
      window.location.href = window.location.href.replace('/all-news','')
    }
  }

  render = () => {
    return (
      <Modal isOpen={ this.props.showAllNews } size="xl" toggle={ () => this.closeModal() }>
        <ModalHeader toggle={ () => this.closeModal() }>{ TmpLang[ 'ALL_NEWS_FROM_ACISO' ][ this.props.lang ] }</ModalHeader>
        <ModalBody style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            this.props.allNews.length > 0 && this.props.allNews.map( item => {
              return(
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    margin: '35px',
                    alignItems: 'center',
                    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.2)',
                    border: 'none',
                    borderRadius: '5px',
                    width: '250px',
                    cursor: 'pointer'
                  }}
                  onClick={ () => {
                    this.props.onChangeNewsHOC( 'showInfo', true )
                    this.props.getSelectedNews( item.id )
                  }}>
                  <img src={ item.image_url } style={{ width: '100%', height: '200px'}}/>
                  <p className="p-2 mt-2">{ item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] }</p>
                </div>
              )
            })
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={ () => this.closeModal() }>{ TmpLang[ 'CLOSE' ][ this.props.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ViewAllNews
