import React, { useState, useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

import UpdateLocation from './UpdateLocation'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const Update = props => {
  const [ activeTab, updateActiveTab ] = useState(0)

  useEffect(() => {
    if( props.showUpdateLocation ) {
      props.getCategories()
    } else {
      updateActiveTab( 0 )
    }
  }, [ props.showUpdateLocation ])

  return (
    <Modal isOpen={ props.showUpdateLocation } size='xl' >
      <ModalHeader toggle={ () => props.onChangeLocationsHOC( 'showUpdateLocation', false ) }>
        { Lang[ 'UPDATE_LOCATION' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        <UpdateLocation
          { ...props }
          activeTab={ activeTab }
          selectedLanguage={ props.data.languageReducer.userSettings.admin_panel_language }
          onChangeLocations={ ( key, val ) => {
            if( key === 'activeTab' ) {
              updateActiveTab( val )
            } else {
              props.onChangeLocations( key, val )
            }
          }}
          categories={ props.categories } />
      </ModalBody>
      <ModalFooter>
        {
          activeTab === 0 && (
            <Button
              color='primary'
              onClick={ () => {
                let tmp = _.cloneDeep( props.selectedLocation )
                tmp.phone_number = _.find( props.countries, { name: tmp.country } ).calling_code[ 0 ] + '-' + tmp.area_code + '-' + tmp.phone_number

                props.updateLocation( props.data.selectCenterReducer.selectedCenter.center.id, tmp )
              }} >
              { Lang[ 'SUBMIT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </Button>
          )
        }
        {
          activeTab === 1 && (
            <Button
              color='primary'
              onClick={ () => {
                if ( !_.isEmpty( props.selectedOpeningHour.opening_hour ) ){
                  let tmp = _.cloneDeep( props.selectedOpeningHour.opening_hour )
                  tmp.days.map( day => {
                    if( day.timeIntervals.length === 0 ){
                      day.openingDayType = 'closed'
                    }
                    switch( day.timeIntervals.length ){
                      case 0 :
                        day.openingDayType = 'closed'
                        break
                      case 1 :
                        day.openingDayType = 'singleInterval'
                        break
                      case 2 :
                        day.openingDayType = 'doubleInterval'
                        break
                      default:
                        day.openingDayType = 'closed'
                        break
                    }
                  })
                  props.updateOpeningHour( props.selectedLocation.id, props.selectedOpeningHour.id, { opening_hour: tmp } )
                } else {
                  let tmpDays = _.cloneDeep( props.newOpeningHourData.days )
                  let tmpIntervalObject = {}
                  tmpDays.map( dayItem => {
                    let tmpIntervals = []
                    dayItem.timeIntervals.map( intervalItem => {
                      tmpIntervalObject = {
                        ...intervalItem,
                        to: Moment( intervalItem.to ).format( 'H:mm' ),
                        from: Moment( intervalItem.from ).format( 'H:mm' )
                      }
                      tmpIntervals.push( tmpIntervalObject )
                      let tmpFoundIndex = _.findIndex( tmpDays, { day: dayItem.day })
                      tmpDays[ tmpFoundIndex ].timeIntervals = tmpIntervals
                    })
                  })
                  let tmp = {
                    ...props.newOpeningHourData,
                    days: tmpDays
                  }
                  tmp.days.map( day => {
                    if( day.timeIntervals.length === 0 ){
                      day.openingDayType = 'closed'
                    }
                    switch( day.timeIntervals.length ){
                      case 0 :
                        day.openingDayType = 'closed'
                        break
                      case 1 :
                        day.openingDayType = 'singleInterval'
                        break
                      case 2 :
                        day.openingDayType = 'doubleInterval'
                        break
                      default:
                        day.openingDayType = 'closed'
                        break
                    }

                    day.timeIntervals.map( ( timeInterval, index ) => {
                      let tmpInterval = _.cloneDeep( timeInterval )
                      if( tmpInterval.to.substring( 0, 2 ).indexOf( ':' ) > -1 ){
                        tmpInterval.to = '0' + tmpInterval.to
                      }
                      if( tmpInterval.from.substring( 0, 2 ).indexOf( ':' ) > -1 ){
                        tmpInterval.from = '0' + tmpInterval.from
                      }
                      day.timeIntervals[ index ] = tmpInterval
                    })
                  })
                  props.createOpeningHour( props.selectedLocation.id, { opening_hour: tmp } )
                }
              }} >
              {
                !_.isEmpty( props.selectedOpeningHour.opening_hour )
                  ? Lang[ 'UPDATE_OPENING_HOUR' ][ props.data.languageReducer.userSettings.admin_panel_language ]
                  : Lang[ 'CREATE_OPENING_HOUR' ][ props.data.languageReducer.userSettings.admin_panel_language ]
              }
            </Button>
          )
        }
        {
          activeTab === 2 && (
            <Button
              color='primary'
              onClick={ () => {
                props.updateLocationCategories( props.selectedLocation.id )
                // let tmp = _.cloneDeep( props.selectedLocation )
                // tmp.phone_number = _.find( props.countries, { name: tmp.country } ).calling_code[ 0 ] + '-' + tmp.area_code + '-' + tmp.phone_number
                // props.updateLocation( props.data.selectCenterReducer.selectedCenter.center.id, tmp )
              }} >
              { Lang[ 'SUBMIT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </Button>
          )
        }
        {
          activeTab === 3 && (
            <Button
              color='primary'
              onClick={ () => props.updateContact( props.selectedLocation.id, props.contact ) } >
              { Lang[ 'UPDATE_CONTACT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </Button>
          )
        }
        {
          activeTab === 4 && (
            <Button
              color='primary'
              onClick={ () => props.updateCurrentOffer( props.selectedLocation.id, props.currentOffer ) } >
              { Lang[ 'UPDATE_CURRENT_OFFER' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </Button>
          )
        }
        {
          activeTab === 5 && (
            <Button
              color='primary'
              onClick={ () => props.updateAdditionalInfo( props.selectedLocation.id, props.additionalInfo ) } >
              { Lang[ 'UPDATE_ADDITIONAL_INFO' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </Button>
          )
        }
        {
          activeTab === 6 && (
            <Button
              color='primary'
              onClick={ () => {
                props.updateImages( props.selectedLocation.id, props.images )
                props.updateFbImages( props.selectedLocation.id, props.fbImages )
                props.updateGoogleImages( props.selectedLocation.id, props.googleImages )
              }}>
              { Lang[ 'UPDATE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </Button>
          )
        }
        {
          activeTab === 7 && (
            <Button
              color='primary'
              onClick={ () => props.updateExternalUrl( props.selectedLocation.id, props.externalUrl ) }>
              { Lang[ 'UPDATE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </Button>
          )
        }
      </ModalFooter>
      { 
        ( 
          props.onLoadLocationsHOC || props.onLoadImages || props.onLoadCountries ||
          props.onLoadCategories || props.onLoadContacts || props.onLoadCurrentOffer ||
          props.onLoadAdditionalInfo || props.onLoadImages || props.onLoadExternalUrl ||
          props.onLoadRequest
        ) && 
          <LoadingOverlay/>
      }
    </Modal>
  )
}

export default Update
