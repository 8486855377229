import React, { useState, useEffect } from 'react'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button,
  Modal, ModalBody, ModalHeader, ModalFooter,
  Collapse
} from 'reactstrap'
import { AiOutlineSearch } from 'react-icons/ai'
import ReactTable from 'react-table'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ArkPagination from 'components/Pagination'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

let defaultFilter = {
  first_name: '',
  email: ''
}

const View = ({
  data,
  onLoadRecipients,
  toggle,
  isOpen,
  onChangeRecipientHOC,
  getSelectedRecipient,
  viewedRecipientLists,
  getRecipientLists,
  onChangeRecipientGroupHOC,
  selectedLanguage,
  removeRecipientGroupRecipient,
  onLoadRecipientGroup
}) => {
  const [ showFilter, setShowFilter ] = useState( false )
  const [ filterValue, setFilter ] = useState( defaultFilter )
  const [ selectedRecipientToRemove, updateSelectedRecipientToRemove ] = useState({
    recipient_group_id: 0,
    recipient_id: 0
  })

  const onChangeField = ( context, val ) => {
    setFilter({
      ... filterValue,
      [context]: val
    })
  }

  useEffect(() => {
    !isOpen && setFilter( defaultFilter )
  }, [isOpen])

  return(
    <Modal
      toggle={ toggle }
      isOpen={ isOpen }
      size="xl">
      <ModalHeader toggle={ toggle }>{ Lang[ 'VIEW_RECIPIENT_GROUP' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( data ) && <Container>
            <Row>
              <Col md={ 12 }>
                <Card>
                  <CardBody>
                    <Form>
                      <FormGroup row className="pl-3">
                        <Label>{ Lang[ 'NAME' ][ selectedLanguage ] }: { data.name }</Label>
                      </FormGroup>
                      <FormGroup row className="pl-3">
                        <Label>{ Lang[ 'STATUS' ][ selectedLanguage ] }: { Lang[ data.status ][ selectedLanguage ] }</Label>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          { Lang[ 'RECIPIENT_LIST' ][ selectedLanguage ] }
                          <AiOutlineSearch
                            style={{ 
                              cursor: 'pointer', 
                              color: showFilter ? '#3f8dde' : 'black' 
                            }}
                            onClick={ () => setShowFilter( prevShow => !prevShow )}
                          />
                        </Label>
                        <Collapse isOpen={ showFilter }>
                          <Card className={ 'mb-4 mt-2' }>
                            <CardBody>
                              <Row>
                                <Col md={ 12 }>
                                  <FormGroup>
                                    <Label>
                                      { Lang[ 'FIRST_NAME' ][ selectedLanguage ] }
                                    </Label>
                                    <Input
                                      value={ filterValue.first_name }
                                      onChange={ e => onChangeField( 'first_name', e.target.value )}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={ 12 }>
                                  <FormGroup>
                                    <Label>
                                      { Lang[ 'EMAIL' ][ selectedLanguage ] }
                                    </Label>
                                    <Input
                                      value={ filterValue.email }
                                      onChange={ e => onChangeField( 'email', e.target.value )}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className="d-flex">
                                <Button
                                  color="primary"
                                  className={ 'mr-2' }
                                  onClick={() => {
                                    let tmp = {
                                      page: 1,
                                      is_paginated: true,
                                      is_blacklisted: false,
                                      filter: {
                                        ... filterValue.first_name && {
                                          first_name  : { $like: `%${ filterValue.first_name }%` }
                                        },
                                        ... filterValue.email && {
                                          email: { $like: `%${ filterValue.email }%` }
                                        }
                                      }
                                    }
  
                                    getRecipientLists( convertSearchObjectToBase64( tmp ), data.id )
                                  }}>
                                  { Lang[ 'SEARCH' ][ selectedLanguage ] }
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    let tmp = {
                                      page: 1,
                                      is_paginated: true,
                                      is_blacklisted: false
                                    }
                                    setFilter( defaultFilter )
                                    getRecipientLists( convertSearchObjectToBase64( tmp ), data.id )
                                  }}>
                                  { Lang[ 'RESET' ][ selectedLanguage ] }
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </Collapse>
                        <ReactTable
                          NoDataComponent={ () => <span></span> }
                          data={ viewedRecipientLists.data }
                          columns={[
                            {
                              Header: Lang[ 'NAME' ][ selectedLanguage ],
                              accessor: 'first_name'
                            },
                            {
                              Header: Lang[ 'EMAIL' ][ selectedLanguage ],
                              accessor: 'email',
                              style: { 'line-break': 'anywhere' }
                            },
                            {
                              Header: Lang[ 'STATUS' ][ selectedLanguage ],
                              accessor: 'status',
                              Cell: row => Lang[ row.original.status ][ selectedLanguage ]
                            },
                            {
                              Header: Lang[ 'ACTIONS' ][ selectedLanguage ],
                              accessor: 'id',
                              Cell: ( row ) => (
                                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                                  <Button
                                    className="mr-2 btn-icon btn-icon-only"
                                    color="primary"
                                    onClick={() => {
                                      onChangeRecipientHOC( 'showEditRecipientModal', true )
                                      getSelectedRecipient( row.original.id )
                                    }}>
                                    <i className="pe-7s-note"></i>
                                  </Button>
                                  <Button
                                    className="mr-2 btn-icon btn-icon-only"
                                    color="danger"
                                    onClick={() => {
                                      updateSelectedRecipientToRemove({
                                        recipient_id: row.original.id,
                                        recipient_group_id: data.id
                                      })
                                    }}>
                                    <i className="pe-7s-trash"></i>
                                  </Button>
                                </div>
                              )
                            }
                          ]}
                          showPagination={ false }
                          defaultPageSize={ 10 } />
                          {
                            viewedRecipientLists.data && viewedRecipientLists.data.length > 0 && (
                              <ArkPagination
                                meta={ viewedRecipientLists.meta }
                                onChangePage={ page => {
                                  let tmp = {
                                    page: page,
                                    is_paginated: true,
                                    is_blacklisted: false,
                                    filter: {
                                      ... filterValue.first_name && {
                                        first_name  : { $like: `%${ filterValue.first_name }%` }
                                      },
                                      ... filterValue.email && {
                                        email: { $like: `%${ filterValue.email }%` }
                                      }
                                    }
                                  }
                                  getRecipientLists( convertSearchObjectToBase64( tmp ), data.id )
                                }} />
                            )
                          }
                          {
                            selectedRecipientToRemove.recipient_id > 0 && (
                              <DeleteConfirmation
                                handleModalClose={ () => (
                                  updateSelectedRecipientToRemove({ 
                                    selectedRecipientToRemove: {
                                      recipient_group_id: 0,
                                      recipient_id: 0
                                    }
                                  })
                                )}
                                deleteOpen={ selectedRecipientToRemove.recipient_id > 0 }
                                confirmAction={ async() => {
                                  let tmp = {
                                    page: 1,
                                    is_paginated: true,
                                    is_blacklisted: false
                                  }
                                  await removeRecipientGroupRecipient( selectedRecipientToRemove )
                                  getRecipientLists( convertSearchObjectToBase64( tmp ), data.id )
                                  updateSelectedRecipientToRemove({
                                    selectedRecipientToRemove: {
                                      recipient_group_id: 0,
                                      recipient_id: 0
                                    }
                                  })
                                }}
                                content={ Lang[ 'REMOVE_RECIPIENT_FROM_GROUP_CONFIRMATION' ][ selectedLanguage ] }
                                lang={ selectedLanguage }/>
                            )
                          }
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
        { ( onLoadRecipients || onLoadRecipientGroup ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ toggle }>{ 'Ok' }</Button>
        <Button color="alternate" onClick={ () => onChangeRecipientGroupHOC( 'showEditModal', true ) }>{ Lang[ 'EDIT' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}
export default View
