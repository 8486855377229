const Lang = {
  SUMMARY: {
    "en": "Summary",
    "de": "Zusammenfassung",
    "it": "Sommario",
    "nl": "Samenvatting"
  },
  DETAILS: {
    "en": "Details",
    "de": "Einzelheiten",
    "it": "Dettagli",
    "nl": "Details"
  },
  EMAIL_CREATED: {
    "en": "Email(s) that created",
    "de": "E-Mail (s), die erstellt wurden",
    "it": "Email che hanno creato",
    "nl": "E-mail (s) die zijn aangemaakt"
  },
  TOTAL_REJECTED: {
    "en": "Total emails rejected",
    "de": "Insgesamt abgelehnte E-Mails",
    "it": "Totale messaggi di posta elettronica rifiutati",
    "nl": "Totaal aantal afgewezen e-mails"
  },
  EMAIL_ACTIVE: {
    "en": "Email(s) that existed as active recipient:",
    "de": "E-Mail (s), die als aktiver Empfänger vorhanden waren:",
    "it": "Email esistenti come destinatari attivi:",
    "nl": "E-mail (s) die bestonden als actieve ontvanger:"
  },
  EMAIL_INACTIVE: {
    "en": "Email(s) that existed as inactive recipient:",
    "de": "E-Mail (s), die als inaktiver Empfänger vorhanden waren:",
    "it": "Email che esistevano come destinatario inattivo:",
    "nl": "E-mail (s) die bestonden als inactieve ontvanger:"
  },
  EMAIL_BLACKLISTED: {
    "en": "Email(s) that existed as blacklisted recipient:",
    "de": "E-Mail (s), die als Empfänger auf der schwarzen Liste vorhanden waren:",
    "it": "Email che esistevano come destinatari nella lista nera:",
    "nl": "E-mail (s) die bestonden als ontvanger op de zwarte lijst:"
  },
  EMAIL_DOI: {
    "en": "Email(s) that existed as DOI:",
    "de": "E-Mail (s), die als DOI existierten:",
    "it": "Email che esistevano come DOI:",
    "nl": "E-mail (s) die bestonden als DOI:"
  },
  EMAIL_DUPLICATED: {
    "en": "Email(s) that found duplicated:",
    "de": "E-Mail (s), die doppelt gefunden wurden:",
    "it": "Email che sono state trovate duplicate:",
    "nl": "E-mail (s) die gedupliceerd zijn gevonden:"
  },
  CLOSE: {
    "en": "Close",
    "de": "Schließen",
    "it": "Vicino",
    "nl": "Dichtbij"
  },
  BULK_UPLOAD_RECIPIENT: {
    "en": "Bulk upload for recipients",
    "de": "Massen-Upload für Empfänger",
    "it": "Caricamento collettivo per i destinatari",
    "nl": "Bulkupload voor ontvangers"
  },
  CLICK: {
    "en": "Click ",
    "de": "Klicken ",
    "it": "Fare clic ",
    "nl": "Klik "
  },
  HERE: {
    "en": "here ",
    "de": "Sie hier ",
    "it": "qui ",
    "nl": "hier "
  },
  DOWNLOAD_SAMPLE: {
    "en": "to download sample CSV file",
    "de": "um eine CSV-Beispieldatei herunterzuladen",
    "it": "per scaricare un file CSV di esempio",
    "nl": "om een ​​voorbeeld van een CSV-bestand te downloaden"
  },
  CHOOSE_FILE: {
    "en": "Choose a CSV file or Excel file to upload",
    "de": "Wählen Sie eine CSV- oder Excel-Datei zum Hochladen aus",
    "it": "Scegli un file CSV o un file Excel da caricare",
    "nl": "Kies een CSV-bestand of Excel-bestand om te uploaden"
  },
  RECIPIENT_GROUP: {
    "en": "Recipient group",
    "de": "Empfängergruppe",
    "it": "Gruppo di destinatari",
    "nl": "Ontvangersgroep"
  },
  STATUS: {
    "en": "Status",
    "de": "Status",
    "it": "Stato",
    "nl": "Toestand"
  },
  SUBMIT: {
    "en": "Submit",
    "de": "Senden",
    "it": "Invia",
    "nl": "Verzenden"
  },
  UPLOAD_FILE: {
    "en": "Upload a file",
    "de": "Eine Datei hochladen",
    "it": "Caricare un file",
    "nl": "Een bestand uploaden"
  },
  UPLOAD_SUCCESS: {
    "en": "Upload success",
    "de": "Erfolgreich hochgeladen",
    "it": "Caricamento riuscito",
    "nl": "Upload geslaagd"
  },
  UPLOAD_COMPLETED: {
    en: 'Upload completed',
    de: 'Upload abgeschlossen',
    it: 'Carica completato',
    nl: 'Upload voltooid'
  }
}
export default Lang
