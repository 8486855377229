import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import { Get, Post, Put, Delete } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreateSenders: false,
      loading: false,
      senders: [],
      searchParams: [
        {
          label: 'Email',
          value: 'email',
          param: ''
        },
      ],
      newSenderData: {
        name: '',
        email: ''
      },
      selectedSender: {},
      showEditSenderModal: false,
      showDeleteSenderConfirmation: false,
      errorMessage: {}
    }

    onChangeSendersHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => {
      toast.success( success )
      this.setState({
        newSenderData: {
          name: '',
          email: ''
        },
        selectedSender: {},
      })
    }
    requestError = error => toast.error( error )

    getSenders = ( query ) => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/senders?query=${ query }`,
        this.getSendersSuccess,
        this.getSendersError,
        this.load
      )
    }
    getSendersSuccess = payload => this.setState({ senders: payload })
    getSendersError = error => this.requestError( error.toString() )

    postSender = () => {
      Post(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/senders`,
        this.state.newSenderData,
        this.postSenderSuccess,
        this.postSenderError,
        this.load
      )
    }
    postSenderSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( 'Create sender successfully' )
      this.setState({ showCreateSenders: false })
      this.getSenders( convertSearchObjectToBase64( tmp ) )
    }
    postSenderError = error => this.setState({ errorMessage: error }, () => this.requestError( error ) )

    getSelectedSender = id => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/senders/${ id }`,
        this.getSelectedSenderSuccess,
        this.getSelectedSenderError,
        this.load
      )
    }
    getSelectedSenderSuccess = payload => this.setState({ selectedSender: payload })
    getSelectedSenderError = error => this.requestError( error )

    updateSender = () => {
      Put(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/senders/${ this.state.selectedSender.id }`,
        this.state.selectedSender,
        this.updateSenderSuccess,
        this.updateSenderError,
        this.load
      )
    }
    updateSenderSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( 'Update successfully' )
      this.setState({ showEditSenderModal: false })
      this.getSenders( convertSearchObjectToBase64( tmp ) )
    }
    updateSenderError = error => this.requestError( error )

    deleteSender = () => {
      Delete(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/senders/${ this.state.selectedSender.id }`,
        this.deleteSenderSuccess,
        this.deleteSenderError,
        this.load
      )
    }
    deleteSenderSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( 'Deleted successfully.' )
      this.getSenders( convertSearchObjectToBase64( tmp ) )
    }
    deleteSenderError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          showCreateSenders={ this.state.showCreateSenders }
          onLoadSenders={ this.state.loading }
          onChangeSendersHOC={ this.onChangeSendersHOC }
          getSenders={ this.getSenders }
          senders={ this.state.senders }
          searchParams={ this.state.searchParams }
          newSenderData={ this.state.newSenderData }
          postSender={ this.postSender }
          getSelectedSender={ this.getSelectedSender }
          selectedSender={ this.state.selectedSender }
          showEditSenderModal={ this.state.showEditSenderModal }
          updateSender={ this.updateSender }
          showDeleteSenderConfirmation={ this.state.showDeleteSenderConfirmation }
          deleteSender={ this.deleteSender }
          errorMessage={ this.state.errorMessage } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
