import ReactHTMLParser from 'react-html-parser'

export const generateFieldLabelStr = ( content, formValues, currentLanguage ) => {
  let labelText = content.label[ currentLanguage? currentLanguage: 'en' ]
  let closingTag = ""

  if ( typeof(ReactHTMLParser( labelText )[0]) === 'object' ) {
    const tag = ReactHTMLParser( labelText )[0].type
    closingTag = "</" + tag + ">"
    labelText = labelText.slice(0, -1*closingTag.length)
  }

  if ( labelText.length !== 0 && formValues && formValues[ content.dataMapIndex ][ 'isRequired' ] ) {
    labelText += `<span style='color: #ff0000;'> * </span>`
  }
  if ( content.showQuickInfo || (formValues && formValues[ content.dataMapIndex ][ 'showQuickInfo' ]) ) {
    labelText += `<i id="quick-info-tooltip-${content.id}" class="pe-7s-info ml-2" style="font-size: 20px; cursor: pointer" />`
  }

  labelText += closingTag
  return labelText
}