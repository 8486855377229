export const TePeMainNav = [
  {
    icon: 'pe-7s-rocket',
    label: 'DASHBOARD',
    to: '#/dashboard/main'
  },
  {
    icon: 'pe-7s-speaker',
    label: 'OLS',
    secLabel: 'OLS',
    content: [
      {
        icon: 'pe-7s-home',
        label: 'LEAD_CENTRE',
        to: 'https://leadcenter.aciso-suite.com/login',
        externalLink: true
      },
      {
        icon: 'pe-7s-ticket',
        label: 'CAMPAIGNS',
        to: '#/dashboard/ols/listings'
      },
      {
        icon: 'pe-7s-settings',
        label: 'SETTINGS',
        to: '#/dashboard/ols/settings'
      },
      {
        icon: 'pe-7s-check',
        label: 'COMPLETED_CAMPAIGN',
        to: '#/dashboard/ols/completed-campaigns'
      }
    ]
  },
  {
    icon: 'pe-7s-global',
    label: 'WEBSITE',
    content: [ 
      {
        icon: 'pe-7s-graph',
        label: 'MATOMO_STATISTICS',
        to: '#/dashboard/website/matomo'
      }
    ]
  },
  {
    icon: 'pe-7s-news-paper',
    label: 'NEWSLETTER',
    secLabel: 'Newsletters',
    content: [
      {
        icon: 'pe-7s-news-paper',
        label: 'LISTINGS',
        to: '#/dashboard/newsletter'
      },
      {
        icon: 'pe-7s-add-user',
        label: 'RECIPIENTS',
        content: [
          {
            icon: 'pe-7s-add-user',
            label: 'ACTIVE',
            to: '#/dashboard/newsletter-active-recipients'
          },
          {
            icon: 'pe-7s-add-user',
            label: 'INACTIVE',
            to: '#/dashboard/newsletter-inactive-recipients'
          },
          {
            icon: 'pe-7s-way',
            label: 'DOI',
            to: '#/dashboard/newsletter-doi-recipients'
          },
          {
            icon: 'pe-7s-shield',
            label: 'BLACKLISTS',
            to: '#/dashboard/newsletter-blacklisted-recipients'
          },
          {
            icon: 'pe-7s-cloud-upload',
            label: 'BULK_UPLOAD',
            to: '#/dashboard/newsletter-bulk-upload'
          }
        ]
      },
      {
        icon: 'pe-7s-users',
        label: 'GROUPS',
        to: '#/dashboard/newsletter-recipient-group'
      },
      {
        icon: 'pe-7s-next-2',
        label: 'SENDER',
        to: '#/dashboard/newsletter-senders'
      },
      {
        icon: 'pe-7s-disk',
        label: 'TEMPLATE',
        to: '#/dashboard/newsletter-templates'
      }
    ]
  },
  {
    icon: 'pe-7s-shield',
    label: 'GDPR_CENTER',
    secLabel: 'GDPR Center',
    content: [
      {
        icon: 'pe-7s-copy-file',
        label: 'AV_CONTRACT',
        secLabel: 'AV Contract',
        to: '#/dashboard/av-contract'
      },
      {
        icon: 'pe-7s-note',
        label: 'INFOCENTER',
        to: '#/dashboard/article-fourteen'
      },
      {
        icon: 'pe-7s-menu',
        label: 'PRIVACY_POLICY',
        to: '#/dashboard/privacy-policy'
      },
      {
        icon: 'pe-7s-file',
        label: 'IMPRINT',
        to: '#/dashboard/imprint'
      },
      {
        icon: 'pe-7s-info',
        label: 'INQUIRY_CONCERNED'
      },
      {
        icon: 'pe-7s-settings',
        label: 'DATA_CONFIGURATION',
        to: '#/dashboard/gdpr-data-configuration'
      },
      {
        icon: 'pe-7s-box1',
        label: 'COOKIE_BANNER',
        content: [
          {
            icon: 'pe-7s-notebook',
            label: 'CATEGORIES',
            to: '#/dashboard/cookie-banner/categories'
          },
          {
            icon: 'pe-7s-bookmarks',
            label: 'CONTENT',
            to: '#/dashboard/cookie-banner/content'
          }
        ]
      }
    ]
  },
  {
    icon: 'pe-7s-info',
    label: 'HELP_TUTORIALS',
    secLabel: '',
    content: [
      {
        icon: 'pe-7s-video',
        label: 'EXPLANATORY_VIDEOS',
        to: 'https://tutorials.aciso-suite.com',
        externalLink: true
      },
      {
        icon: 'pe-7s-help1',
        label: 'FAQ'
      }
    ]
  }
]
