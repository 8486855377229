import React, { Component } from 'react'
import EmailEditor from 'react-email-editor'
import {
  Row, Col, Label, Input, Button, Alert,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

class TemplateEditor extends Component {

  templateEditor = ''

  loadTemplate = ( template ) => {
    return this.templateEditor.editor.loadDesign ( typeof template === 'string' ? JSON.parse( template ) : template )
  }

  onLoad = () => {
    if ( this.templateEditor?.editor?.loadDesign ) {
      this.loadTemplate( this.props.selectedTemplate.json_content )
    } else {
      setTimeout(
        () => {
          this.loadTemplate( this.props.selectedTemplate.json_content )
        }, 3000);
    }
  }

  saveDesign = async () => {
    let test = await this.templateEditor.saveDesign
    let tmp = _.cloneDeep( this.props.selectedTemplate )
    await test( async design => {
      tmp.json_content = design
      let test2 = await this.templateEditor.exportHtml
      await test2( html => {
        tmp.html_content = html.html
        tmp.center_groups.length > 0
          ? this.props.updateTemplate( tmp )
          : this.props.updateOwnTemplate( tmp )
      })
    })
  }

  render = () => {
    return (
      <Modal size='xl' isOpen={ this.props.showUpdateTemplate }>
        <ModalHeader toggle={ () => this.props.onChangeTemplatesHOC( 'showUpdateTemplate', false ) } >
          { Lang[ 'EDIT_TEMPLATE' ][ this.props.selectedLanguage ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedTemplate ) && this.props.showUpdateTemplate && <Form>
              <Row>
                <Col md={ 12 }>
                  <FormGroup>
                    <Label>{ Lang[ 'NAME' ][ this.props.selectedLanguage ] }</Label>
                    <Input
                      type='text'
                      value={ this.props.selectedTemplate.name }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.selectedTemplate )
                        tmp.name = e.target.value
                        this.props.onChangeTemplatesHOC( 'selectedTemplate', tmp )
                      }}/>
                  </FormGroup>
                  <Alert color="primary" className="mt-2">
                    { Lang[ 'USE_NEWSLETTER_PLACEHOLDER' ][ this.props.selectedLanguage ] }
                    <ul>
                      <li>{ '{{ $.recipient_salutation }}' }</li>
                      <li>{ '{{ $.recipient_email }}' }</li>
                      <li>{ '{{ $.recipient_name }}' }</li>
                      <li>{ '{{ $.recipient_first_name }}' }</li>
                      <li>{ '{{ $.recipient_last_name }}' }</li>
                    </ul>
                  </Alert>
                  <EmailEditor
                    ref={ templateEditor => this.templateEditor = templateEditor }
                    onLoad={ this.onLoad }
                    options={{
                      version: 'latest',
                      customJS: [ `${ window.location.origin }/custom.js` ],
                      locale: this.props.selectedLanguage,
                      features: {
                        stockImages: {
                          enabled: false
                        }
                      },
                      tools: {
                        image: {
                          properties: {
                            src: {
                              value: {
                                url: 'https://via.placeholder.com/500x100?text=IMAGE',
                                width: 500,
                                height: 100
                              }
                            }
                          }
                        },
                        'custom#content_service': {
                          data: {
                            options: this.props.contentServices
                          },
                          properties: {
                            contentServices: {
                              editor: {
                                data: {
                                  label: 'Content Services',
                                  options: this.props.contentServices
                                }
                              }
                            },
                            centerNews: {
                              editor: {
                                data: {
                                  label: 'News',
                                  options: []
                                }
                              }
                            }
                          }
                        }
                      }
                    }}
                    projectId={ 28706 } />
                </Col>
              </Row>
            </Form>
          }
          { this.props.onLoadTemplatesHOC && <LoadingOverlay/> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.saveDesign() }>
            { Lang[ 'SUBMIT' ][ this.props.selectedLanguage ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default TemplateEditor
