import React from 'react'
import { Route } from 'react-router-dom'

import FunnelListings from './Listings/Funnels'
import MailingListings from './Listings/Mailings'
import ActiveRecipients from './Recipient/Active'
import BlacklistedRecipients from './Recipient/Blacklist'
import Sender from './Senders'
import Template from './Template'
import RecipientGroup from './Groups'
import BulkUpload from './Recipient/BulkUpload'

const MarketingFunnels = props => {
  return (
    <>
      <Route exact path="/dashboard/marketing-funnels/listings/funnels" component={ FunnelListings } />
      <Route exact path="/dashboard/marketing-funnels/listings/mailings" component={ MailingListings } />
      <Route exact path="/dashboard/marketing-funnels/recipient/active" component={ ActiveRecipients } />
      <Route exact path="/dashboard/marketing-funnels/recipient/blacklisted" component={ BlacklistedRecipients } />
      <Route exact path="/dashboard/marketing-funnels/recipient/bulk-upload" component={ BulkUpload } />
      <Route exact path="/dashboard/marketing-funnels/sender" component={ Sender } />
      <Route exact path="/dashboard/marketing-funnels/template" component={ Template } />
      <Route exact path="/dashboard/marketing-funnels/recipient-group" component={ RecipientGroup } />
    </>
  )
}

export default MarketingFunnels
