import Axios from 'axios'

import getDomainURL from 'utils/api'
import { getCipherHeader } from 'utils/cipher-text'

export const Get = ( url, response, error, load, host ) => {
  load( true )
  Axios.defaults.headers = {
    common: {
      Authorization: getCipherHeader()
    }
  }
  return Axios.get( `${ getDomainURL() }${ url }`, {
    headers: {
      'X-Host': host ? host : ''
    }
  }).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      error( err.response.data.message )
    } else if( err && err.message ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Cockpit. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Post = ( url, data, response, error, load, host ) => {
  load( true )
  Axios.defaults.headers = {
    common: {
      'Authorization': getCipherHeader()
    }
  }
  return Axios.post( `${ getDomainURL() }${ url }`, data, {
    headers: {
      'X-Host': host ? host : ''
    }
  }).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        if( err.response.data.Errors ){
          error( err.response.data.Errors )
        }
        if( err.response.data.Error ){
          error( err.response.data.Error )
        }
      }
    } else if( err ) {
      error( err )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Cockpit. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Put = ( url, data, response, error, load, host ) => {
  load( true )
  Axios.defaults.headers = {
    common: {
      'Authorization': getCipherHeader()
    }
  }
  return Axios.put( `${ getDomainURL() }${ url }`, data, {
    headers: {
      'X-Host': host ? host : ''
    }
  }).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        if( err.response.data.Error ) {
          error( err.response.data.Error )
        }
        if( err.response.data.Errors ){
          error( err.response.data.Errors )
        }
      }
    } else if( err ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Cockpit. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Delete = ( url, response, error, load, data, host ) => {
  load( true )
  Axios.defaults.headers = {
    common: {
      'Authorization': getCipherHeader()
    }
  }
  return Axios.delete( `${ getDomainURL() }${ url }`, { data: data }, {
    headers: {
      'X-Host': host ? host : ''
    }
  }).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        error( err.response.data.message )
      }
    } else if( err ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Cockpit. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}
