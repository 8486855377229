import React from 'react'
import Moment from 'moment'

export const defaultSearchParam = [
  {
    label: 'START_DATE',
    value: 'start_date',
    param: Moment().subtract(1, 'months').toDate()
  },
  {
    label: 'END_DATE',
    value: 'end_date',
    param: Moment().toDate()
  },
]