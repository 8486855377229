import React, { Component } from 'react'
import Axios from 'axios'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { getPlatformConfig } from 'actions/platformConfig'
import { setSelectCenter } from 'actions/selectCenter'

import WithCenters from './actions'

class StoreToken extends Component {

  componentDidMount = () => {
    document.cookie = `ACISO_SESSION_TOKEN=${ this.props.match.params.id }`
    Promise.all([
      this.props.getPlatformConfig()
    ]).then(() => {
      Axios.defaults.headers = {
        common : {
          Authorization: `${ this.props.match.params.id }`
        }
      }
      this.props.getSelectedCenter( this.props.match.params.center_id, this.props.match.params.id )
    })
  }

  render = () => {
    return (
      <div></div>
    )
  }
}

const mapStateToProps = state => ({
  data: state,
  ajaxReducer: state.ajaxReducer
})

export default compose(
  connect( mapStateToProps, {
    getPlatformConfig,
    setSelectCenter
  }),
  WithCenters
)( StoreToken )