import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container, Row, Col,
  Card, CardBody,
  Button, Alert,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { compose } from 'redux'
import ReactTable from 'react-table'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateLocation from './components/CreateLocation'
import UpdateLocation from './Update'
import CountryHOC from 'actions/country'

import LocationsHOC from './actions'
import CategoriesHOC from './actions/Categories'
import ContactsHOC from './actions/Contacts'
import CurrentOfferHOC from './actions/CurrentOffer'
import AdditionalInfoHOC from './actions/AdditionalInfo'
import ImagesHOC from './actions/Images'
import ExternalUrlHOC from './actions/ExternalUrl'
import ProductRequestHOC from './actions/productRequest'

import AdBanner from 'assets/images/local-listing-banner.jpg'

import Lang from 'Lang/General'

class Locations extends Component {
  state = {
    activeTab: 0,
    selectedLanguage: 'en',
    centers: [],
    selectedCenterID: this.props.data.selectCenterReducer.selectedCenter.center.id,
    showDescription: false,
    subscribedPackage: true
  }

  componentDidMount = async () => {
    await this.props.getCountries()
    this.reloadStates()
    this.props.getLocations( this.props.data.selectCenterReducer.selectedCenter.center.id, '' )
    this.props.getLeadhubCategories()
    let tmpCenters = []
    if( !_.isEmpty( this.props.data.profileReducer.profile ) ) {
      this.props.data.profileReducer.profile.centers.map( item => {
        tmpCenters.push({
          label: item.center_name,
          value: item.id,
          ...item
        })
      })
      this.setState({ centers: tmpCenters })
    }
    this.props.getProduct()
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Local Listings' })
    this.setState({ subscribedPackage: tmpIndex > -1 })
  }

  componentDidUpdate = pp => {
    if( pp.showCreateLocation && !this.props.showCreateLocation ){
      this.reloadStates()
    }
    if( pp.showUpdateLocation && !this.props.showUpdateLocation ){
      this.reloadStates()
      this.props.onChangeLocationsHOC( 'selectedLocation', {} )
    }
  }

  onChangeLocations = ( key, val ) => this.setState({ [ key ]: val })

  reloadStates = async() => {
    let tmp = {
      name: '',
        ext_field_1: '',
        ext_field_2: '',
        fax_number: '',
        phone_number: '',
        area_code: '',
        country: '',
        state: '',
        city: '',
        postcode: '',
        street: '',
        house_number: '',
        categories: [],
        email: ''
    }
    await this.props.getStates( this.props.countries[ 0 ].id )
    tmp.country = this.props.countries[ 0 ].name
    tmp.state = this.props.states[ 0 ].name
    this.props.onChangeLocationsHOC( 'newLocation', tmp )
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Local Listings' })
      return tmpModules.is_create && this.state.subscribedPackage
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Local Listings' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Local Listings' })
      return tmpModules.is_delete
    }
  }

  renderCreateLocationForm = () => (
    <Modal isOpen={ this.props.showCreateLocation } size='xl' >
      <ModalHeader toggle={ () => this.props.onChangeLocationsHOC( 'showCreateLocation', false ) }>
        { Lang[ 'CREATE_LOCATION' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        <CreateLocation
          { ...this.props }
          activeTab={ this.state.activeTab }
          onChangeLocations={ this.onChangeLocations }
          selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language } />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( this.props.newLocation )
            tmp.phone_number = _.find( this.props.countries, { name: tmp.country } ).calling_code[ 0 ] + '-' + tmp.area_code + '-' + tmp.phone_number
            this.props.createLocation( this.props.data.selectCenterReducer.selectedCenter.center.id, tmp ) }} >
          { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        </Button>
      </ModalFooter>
      { this.props.onLoadLocationsHOC && <LoadingOverlay/> }
    </Modal>
  )

  renderUpdateLocationForm = () => <UpdateLocation { ...this.props } />

  renderDescription = () => {
    return(
      <Modal
       isOpen={ this.state.showDescription }
       toggle={ () => this.setState({ showDescription: false }) }>
       <ModalHeader toggle={ () => this.setState({ showDescription: false }) }>
       { !_.isEmpty( this.props.product.name ) && this.props.product.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] }
       </ModalHeader>
        <ModalBody>
        { !_.isEmpty( this.props.product.description ) && ReactHtmlParser( this.props.product.description[ this.props.data.languageReducer.userSettings.admin_panel_language ] ) }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={ () => this.setState({ showDescription: false }) }>{'Close'}</Button>
          <Button color="primary" onClick={ () => {
            let tmp = {
              client_id: this.props.data.selectCenterReducer.selectedCenter.center.client_id,
              consultant_id: this.props.data.selectCenterReducer.selectedCenter.center.consultant_id,
              user_id: this.props.data.profileReducer.profile.id,
              status_id: 1,
              product_id: this.props.product.id
            }
            this.props.createProductsRequest( tmp, this.props.product.id )
            this.setState({ showDescription: false })
          } }>{ Lang['REQUEST_INFO' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'LOCATIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          subheading={ Lang[ 'SETUP_LOCATIONS_OVER_HERE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-map-marker icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'CREATE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
              onClick: () => this.props.onChangeLocationsHOC( 'showCreateLocation', true ),
              display: this.handleSecurityModulesCreate()
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <img src={ AdBanner } style={{ width: '100%', marginBottom: '15px'}}/>
          {
            !this.state.subscribedPackage && (
              <Alert color="info">
                { Lang['NOT_SUBSCRIBE'][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
              </Alert>
            )
          }
          <Container fluid>
            <Row>
              <Col md={ 12 }>
                <Card className='main-card mb-3'>
                  <CardBody>
                    <ReactTable
                      pageSize={ 20 }
                      showPagination={ true }
                      NoDataComponent={ () => <span></span> }
                      data={ this.props.locations }
                      columns={[
                        {
                          Header: Lang[ 'NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'name'
                        },
                        {
                          Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'id',
                          Cell: row => (
                            <div className="d-flex flex-row align-items-center justify-content-center w-100">
                              {
                                this.handleSecurityModulesUpdate() && (
                                  <Button
                                    className="mb-2 mr-2 btn-icon btn-icon-only"
                                    disabled={ !this.state.subscribedPackage }
                                    color='primary'
                                    onClick={ () => {
                                      if( _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Local Listings' } ) > -1 ){
                                        this.props.getLocationCategories( row.original.id )
                                        this.props.getSelectedCenterOpeningHours( this.props.data.selectCenterReducer.selectedCenter.center.id, row.original.id )
                                      }
                                      this.props.getSelectedLocation( this.props.data.selectCenterReducer.selectedCenter.center.id, row.original.id )
                                    }}>
                                    <i className="pe-7s-pen btn-icon-wrapper" />
                                  </Button>
                                )
                              }
                              {
                                this.handleSecurityModulesDelete() && (
                                  <Button
                                    className="mb-2 mr-2 btn-icon btn-icon-only"
                                    disabled={ !this.state.subscribedPackage }
                                    color='danger'
                                    onClick={ () => {
                                      this.props.removeLocation( this.props.data.selectCenterReducer.selectedCenter.center.id, row.original.id )
                                    }} >
                                    <i className="pe-7s-close btn-icon-wrapper" />
                                  </Button>
                                )
                              }
                            </div>
                          )
                        }
                      ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
        { this.renderCreateLocationForm() }
        { this.renderUpdateLocationForm() }
        { this.renderDescription() }
        { this.props.onLoadLocationsHOC && <LoadingOverlay/> }
      </>
    )
  }
}

export default compose(
  CountryHOC,
  LocationsHOC,
  CategoriesHOC,
  ContactsHOC,
  CurrentOfferHOC,
  AdditionalInfoHOC,
  ImagesHOC,
  ExternalUrlHOC,
  ProductRequestHOC
)( Locations )
