import React, { Component } from 'react'
import Axios from 'axios'
import { connect } from 'react-redux'

import { getUserProfile } from 'actions/profile'

import getDomainURL from 'utils/api'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      token: '',
      errors: []
    }

    auth = async({ email, password }) => {
      this.setState({ loading: true })
      Axios.post( 
        `${ getDomainURL() }/login`,
        {
          email,
          password,
          platform: 'aciso-cockpit'
        }
      ).then( response => {
        document.cookie = `ACISO_SESSION_TOKEN=${ response.data.token }`
        this.props.getUserProfile()
        this.setState({ loading: false, token: response.data.token })
      }).catch( error => {
        this.setState({ loading: false, errors: error.response.data.Errors })
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadLogin={ this.state.loading }
          token={ this.state.token }
          errors={ this.state.errors }
          auth={ this.auth } />
      )
    }
  }
  const mapStateToProps = state => ({
    data: state,
    ajaxReducer: state.ajaxReducer
  })
  return connect( mapStateToProps, {
    getUserProfile
  })( WithHOC )
}

export default HOC