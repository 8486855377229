import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { storeSidemenu, storeAssignedSidemenu } from 'actions/shortcuts'
import { getSelectedRole } from 'actions/role'
import { getUserProfile } from 'actions/profile'
import { getUserSetting } from 'actions/language'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showAssign: false,
    }

    onChangeMainHOC = ( key, value ) => this.setState({ [key]: value })

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            showAssign={ this.state.showAssign }
            onChangeMainHOC={ this.onChangeMainHOC }
            getSelectedRole={ this.props.getSelectedRole }
            getUserProfile={ this.props.getUserProfile }
            getUserSetting={ this.props.getUserSetting }
            />
        </>
      )
    }

  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    storeSidemenu,
    storeAssignedSidemenu,
    getSelectedRole,
    getUserProfile,
    getUserSetting
  })( WithHOC )
}

export default HOC
