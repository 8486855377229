import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import _ from 'lodash'

import { Get } from 'utils/axios-token'
import { Post, Delete } from 'utils/axios-template'
import DefaultRecipients from 'utils/defaultRecipients'
import Lang from './Lang'
import GeneralLang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreateRecipients: false,
      loading: false,
      recipients: [],
      searchParams: [
        {
          label: 'Email',
          value: 'email',
          param: ''
        },
      ],
      newRecipientData: DefaultRecipients( 'DOI' ),
      selectedRecipient: DefaultRecipients( 'DOI' ),
      errorMessage: {},
      showViewRecipient: false,
      DOIRecipientLog: [],
      showDeleteRecipientConfirmation: false
    }

    onChangeRecipientsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getRecipients = ( query ) => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipients?query=${ query }`,
        this.getRecipientsSuccess,
        this.getRecipientsError,
        this.load
      )
    }
    getRecipientsSuccess = payload => this.setState({ recipients: payload })
    getRecipientsError = error => this.requestError( error.toString() )

    postRecipient = () => {
      let tmpCenterDomain = _.find( this.props.data.selectCenterReducer.selectedCenter.center.domains, { is_default_domain: true } )
      Post(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipients`,
        {
          ...this.state.newRecipientData,
          domain: tmpCenterDomain?.site_domain || '',
          center_id: this.props.data.selectCenterReducer.selectedCenter.center.id
        },
        this.postRecipientSuccess,
        this.postRecipientError,
        this.load
      )
    }
    postRecipientSuccess = () => {
      let tmp = {
        page: this.state.recipients.meta.current_page,
        is_paginated: true,
        status: 'DOI'
      }
      this.requestSuccess( Lang[ 'CONFIRMATION_EMAIL_FOR_NEWSLETTER_SUBSCRIPTION_HAS_BEEN_SENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ 
        showCreateRecipients: false,
        newRecipientData: DefaultRecipients( 'DOI' )
      })
      this.getRecipients( convertSearchObjectToBase64( tmp ) )
    }
    postRecipientError = error => {
      if( error.email ) {
        this.setState({ errorMessage: error }, () => this.requestError( Lang[ 'NEWSLETTER_SUBSCRIPTION_FAILED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] ) )
      } else {
        this.requestError( error )
      }
    }

    getSelectedRecipient = id => Get(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipients/${ id }`,
      this.getSelectedRecipientSuccess,
      this.getSelectedRecipientError,
      this.load
    )
    getSelectedRecipientSuccess = payload => {
      if( payload.email ){
        this.getRecipientLog( payload.email )
      }
      this.setState({ selectedRecipient: payload })
    }
    getSelectedRecipientError = error => this.requestError( error )

    getRecipientLog = email => Get(
      `/api/v1/recipients/${ email }/logs`,
      this.getRecipientLogSuccess,
      this.getRecipientLogError,
      this.load
    )
    getRecipientLogSuccess = payload => this.setState({ DOIRecipientLog: payload })
    getRecipientLogError = error => this.requestError( error )

    deleteRecipient = ( query ) => {
      Delete(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipients/${ this.state.selectedRecipient.id }`,
        ( payload ) => this.deleteRecipientSuccess( payload, query ),
        this.deleteRecipientError,
        this.load
      )
    }
    deleteRecipientSuccess = ( payload, query ) => {
      this.requestSuccess( GeneralLang[ 'DATA_REMOVED_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getRecipients( convertSearchObjectToBase64( query ) )
    }
    deleteRecipientError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          showCreateRecipients={ this.state.showCreateRecipients }
          onLoadRecipients={ this.state.loading }
          onChangeRecipientsHOC={ this.onChangeRecipientsHOC }
          getRecipients={ this.getRecipients }
          recipients={ this.state.recipients }
          searchParams={ this.state.searchParams }
          newRecipientData={ this.state.newRecipientData }
          postRecipient={ this.postRecipient }
          getSelectedRecipient={ this.getSelectedRecipient }
          selectedRecipient={ this.state.selectedRecipient }
          errorMessage={ this.state.errorMessage }
          showViewRecipient={ this.state.showViewRecipient }
          DOIRecipientLog={ this.state.DOIRecipientLog }
          showDeleteRecipientConfirmation={ this.state.showDeleteRecipientConfirmation }
          deleteRecipient={ this.deleteRecipient } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
