import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button, Container, Row, Col,
  Card, CardHeader, CardBody,
  Form, FormGroup, Label, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Table, UncontrolledTooltip
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import WithRecipients from './actions'
import ArkPagination from 'components/Pagination'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import AlertNotSubscribe from 'components/Alert'
import RecipientsForm from './components/activeForm'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import DictionaryHOC from 'utils/dictionary'
import WithRecipientGroup from '../RecipientGroup/actions'

import Lang from 'Lang/General'

class RecipientsListings extends Component {
  state = {
    view: 'scheduled',
    subscribedPackage: true
  }

  componentDidMount = () => {
    let tmp = {
      is_paginated: true,
      page: 1,
      status: 'ACTIVE'
    }
    let tmpString = convertSearchObjectToBase64( tmp )
    this.props.getDictionary()
    this.props.getRecipients( tmpString )
    this.props.getRecipientGroup( convertSearchObjectToBase64( { is_paginated: false } ))
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Newsletters' })
    this.setState({ subscribedPackage: tmpIndex > -1 })
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_create && this.state.subscribedPackage
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_delete
    }
  }

  renderCreateModal = () => {
    return(
      <RecipientsForm
        data={ this.props.newRecipientData }
        onChangeRecipientsHOC={ this.props.onChangeRecipientsHOC }
        toggle={ () => {
          this.props.onChangeRecipientsHOC( 'showCreateRecipients', false )
          this.props.onChangeRecipientsHOC( 'errorMessage', {} )
        }}
        mode="create"
        isOpen={ this.props.showCreateRecipients }
        headerText={ Lang[ 'CREATE_RECIPIENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        status={ this.props.dictionaries.status }
        onSubmit={ () => this.props.postRecipient() }
        recipientGroup={ this.props.recipientGroup.data }
        errorMessage={ this.props.errorMessage }
        onLoadRecipients={ this.props.onLoadRecipients }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }/>
    )
  }

  renderViewModal = () => {
    return(
      <Modal
        size="lg"
        isOpen={ this.props.showViewRecipient }
        toggle={ () => this.props.onChangeRecipientsHOC( 'showViewRecipient', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeRecipientsHOC( 'showViewRecipient', false ) }>{ Lang[ 'VIEW_DETAILS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <th>{ Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ this.props.selectedRecipient.first_name }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ this.props.selectedRecipient.last_name }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ this.props.selectedRecipient.email }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'SALUTATION' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ this.props.selectedRecipient.salutation }</td>
                  </tr>
                  {/* <tr>
                    <th>{ Lang[ 'TELEPHONE_OR_MOBILE_NUMBER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ this.props.selectedRecipient.mobile_number }</td>
                  </tr> */}
                  <tr>
                    <th>{ Lang[ 'RECIPIENT_GROUPS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>
                      <ul>
                        {
                          this.props.selectedRecipient.recipient_groups && this.props.selectedRecipient.recipient_groups.length > 0 && this.props.selectedRecipient.recipient_groups.map( item => {
                            return(
                              <li>{ item.name }</li>
                            )
                          })
                        }
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ this.props.selectedRecipient.status }</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={ () => this.props.onChangeRecipientsHOC( 'showViewRecipient', false ) }>{ 'OK' }</Button>
          <Button color="alternate" onClick={ () => this.props.onChangeRecipientsHOC( 'showEditActiveRecipientModal', true ) }>{ Lang[ 'EDIT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditModal = () => {
    return(
      <RecipientsForm
        data={ this.props.selectedRecipient }
        onChangeRecipientsHOC={ this.props.onChangeRecipientsHOC }
        toggle={ () => {
          this.props.onChangeRecipientsHOC( 'showEditActiveRecipientModal', false )
          this.props.onChangeRecipientsHOC( 'errorMessage', {} )
        }}
        isOpen={ this.props.showEditActiveRecipientModal }
        headerText={ Lang[ 'EDIT_ACTIVE_RECIPIENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        status={ this.props.dictionaries.status }
        mode="edit"
        onSubmit={ () => {
          let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
          let tmp = _.cloneDeep( this.props.errorMessage )
          if( !pattern.test( this.props.selectedRecipient.email ) ){
            tmp[ "email" ] = "Please enter valid email address."
            this.props.onChangeRecipientsHOC( 'errorMessage', tmp )
          } else {
            tmp[ "email" ] = ""
            this.props.updateRecipient()
            this.props.onChangeRecipientsHOC( 'errorMessage', tmp )
          }
        }}
        recipientGroup={ this.props.recipientGroup.data }
        errorMessage={ this.props.errorMessage }
        onLoadRecipients={ this.props.onLoadRecipients }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }
        activeRecipientLogs={ this.props.activeRecipientLogs } />
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'RECIPIENTS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-news-paper icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_RECIPIENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
              onClick: () => {
                this.props.onChangeRecipientsHOC( 'showCreateRecipients', true )
              },
              display: this.handleSecurityModulesCreate()
            }
          ]}
           />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            {
              !this.state.subscribedPackage && (
                <AlertNotSubscribe { ...this.props } />
              )
            }
            <Row>
              <Col md={ 12 }>
                {/*<Alert color="primary">
                  { Lang[ 'MESSAGE_CREATE_RECIPIENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                </Alert>*/}
                <Card className="main-card mb-3">
                  <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                  <CardBody>
                    <Form onSubmit={ e => e.preventDefault()}>
                      <FormGroup>
                        <Label>{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                        <Input
                          type={ 'text' }
                          value={ this.props.searchParams[0].params }
                          onChange={ e => {
                            let tmp = _.cloneDeep( this.props.searchParams )
                            tmp[ 0 ][ 'param' ] = e.target.value
                            return this.props.onChangeRecipientsHOC( 'searchParams', tmp )
                          }} />
                      </FormGroup>
                      <div className="d-flex">
                        <Button
                          color="primary"
                          style={{ marginLeft: 'auto' }}
                          onClick={() => {
                            let tmpSearchParams = {
                              is_paginated: true,
                              page: 1,
                              email: this.props.searchParams[ 0 ].param,
                              status: 'ACTIVE'
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipients( tmpSearchString )
                          }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                        <Button
                          color="danger"
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            let tmpSearchParams = {
                              is_paginated: true,
                              page: 1,
                              status: 'ACTIVE'
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipients( tmpSearchString )
                          }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col md={ 12 }>
                <Card className="main-card mb-3">
                  <CardBody>
                    <ReactTable
                      NoDataComponent={ () => <span></span> }
                      data={ this.props.recipients.data }
                      columns={[
                        {
                          Header: Lang[ 'EMAIL' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'email'
                        },
                        {
                          Header: Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          Cell: row => (
                            <p>{ row.original.first_name || '-' }</p>
                          )
                        },
                        {
                          Header: Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          Cell: row => (
                            <p>{ row.original.last_name || '-' }</p>
                          )
                        },
                        {
                          Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'id',
                          Cell: ( row ) => (
                            <div className="d-flex flex-row align-items-center justify-content-center w-100">
                              <Button
                                id={ `Recipient-${ row.original.id }-view` }
                                className="mr-2 btn-icon btn-icon-only"
                                color="primary"
                                onClick={() => {
                                  this.props.onChangeRecipientsHOC( 'showViewRecipient', true )
                                  this.props.getSelectedRecipient( row.original.id )
                                }}
                                disabled={ !this.state.subscribedPackage }>
                                <i className="pe-7s-look"></i>
                              </Button>
                              <UncontrolledTooltip
                                target={ `Recipient-${ row.original.id }-view` }
                                placement="top">
                                { Lang[ 'VIEW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                              </UncontrolledTooltip>
                              {
                                this.handleSecurityModulesUpdate() && (
                                  <>
                                    <Button
                                      id={ `Recipient-${ row.original.id }-update` }
                                      className="mr-2 btn-icon btn-icon-only"
                                      color="primary"
                                      onClick={() => {
                                        this.props.onChangeRecipientsHOC( 'showEditActiveRecipientModal', true )
                                        this.props.getSelectedRecipient( row.original.id )
                                      }}
                                      disabled={ !this.state.subscribedPackage }>
                                      <i className="pe-7s-note"></i>
                                    </Button>
                                    <UncontrolledTooltip
                                      target={ `Recipient-${ row.original.id }-update` }
                                      placement="top">
                                      { Lang[ 'UPDATE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                    </UncontrolledTooltip>
                                  </>
                                )
                              }
                              {
                                this.handleSecurityModulesDelete() && (
                                  <>
                                    <Button
                                      id={ `Recipient-${ row.original.id }-delete` }
                                      className="mr-2 btn-icon btn-icon-only"
                                      color="danger"
                                      onClick={() => {
                                        this.props.onChangeRecipientsHOC( 'showDeleteRecipientConfirmation', true )
                                        this.props.getSelectedRecipient( row.original.id )
                                      }}
                                      disabled={ !this.state.subscribedPackage }>
                                      <i className="pe-7s-trash"></i>
                                    </Button>
                                    <UncontrolledTooltip
                                      target={ `Recipient-${ row.original.id }-delete` }
                                      placement="top">
                                      { Lang[ 'DELETE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                    </UncontrolledTooltip>
                                  </>
                                )
                              }
                            </div>
                          )
                        }
                      ]}
                      showPagination={ false }
                      defaultPageSize={ 10 } />
                    {
                      this.props.recipients.data && this.props.recipients.data.length > 0 && (
                        <ArkPagination
                          meta={ this.props.recipients.meta }
                          onChangePage={ page => {
                            let tmpSearchParams = {
                              email: this.props.searchParams[ 0 ].param,
                              page: page,
                              is_paginated: true,
                              status: 'ACTIVE'
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipients( tmpSearchString )
                          }} />
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
        {
          this.props.showDeleteRecipientConfirmation && (
            <DeleteConfirmation
               handleModalClose={ () => this.props.onChangeRecipientsHOC( 'showDeleteRecipientConfirmation', false ) }
               deleteOpen={ this.props.showDeleteRecipientConfirmation }
               confirmAction={ () => {
                 this.props.onChangeRecipientsHOC( 'showDeleteRecipientConfirmation', false )
                 this.props.deleteRecipient({
                   page: 1,
                   is_paginated: true,
                   status: 'ACTIVE'
                 })
               }}
               content={ Lang[ 'ARE_YOU_SURE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
               lang={ this.props.data.languageReducer.userSettings.admin_panel_language }/>
          )
        }
        { this.renderViewModal() }
        { this.props.showCreateRecipients && this.renderCreateModal() }
        { this.props.showEditActiveRecipientModal && this.renderEditModal() }
        { this.props.onLoadRecipients && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithRecipientGroup,
  WithRecipients,
  DictionaryHOC
)( RecipientsListings )
