import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Put, DownloadFile } from 'utils/axios-token'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      contract: {},
      is_agreed: false
    }

    onChangeAVContractHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getAVContract = id => Get(
      `/api/v1/gdpr_av_contracts/client/${ id }`,
      this.getAVContractSuccess,
      this.getAVContractError,
      this.load
    )
    getAVContractSuccess = payload => this.setState({ contract: payload, is_agreed: payload.is_agreed })
    getAVContractError = error => {}

    updateAVContract = ( id, data ) => Put(
      `/api/v1/gdpr_av_contracts/${ id }`,
      data,
      this.updateAVContractSuccess,
      this.updateAVContractError,
      this.load
    )
    updateAVContractSuccess = payload => {
      this.requestSuccess( Lang[ 'AGREED_TO_CONTRACT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getAVContract( this.props.data.selectCenterReducer.selectedCenter.center.client_id )
    }
    updateAVContractError = error => this.requestError( error )

    downloadContractPDF = ( id, data ) => DownloadFile(
      `/api/v1/gdpr_av_contracts/${ id }/pdf`,
      data.client.name,
      this.downloadContractPDFError,
      this.load
    )
    downloadContractPDFError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadContracts={ this.state.loading }
            contract={ this.state.contract }
            onChangeAVContractHOC={ this.onChangeAVContractHOC }
            getAVContract={ this.getAVContract }
            updateAVContract={ this.updateAVContract }
            is_agreed={ this.state.is_agreed }
            downloadContractPDF={ this.downloadContractPDF } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
