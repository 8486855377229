import { STORE_SIDEMENU, STORE_ASSIGNED_SIDEMENU } from 'actions/type'

let initialState = {
  sidemenu: [],
  assignedSidemenu: []
}

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case STORE_SIDEMENU:
      return {
        ...state,
        sidemenu: action.payload
      }
    case STORE_ASSIGNED_SIDEMENU:
      return {
        ...state,
        assignedSidemenu: action.payload
      }
    default: return state
  }
}
