import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateMessageConsultant: false,
      messages: [],
      newMessageConsultant: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string' 
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )
    
    onChangeMessageConsultantHOC = ( key, val ) => this.setState({ [ key ]: val })

    getMessageConsultant = consultant_id => Get(
      `/api/v1/consultants/${ consultant_id }/messages`,
      this.getMessageConsultantSuccess,
      this.getMessageConsultantError,
      this.load
    )
    getMessageConsultantSuccess = payload => this.setState({ messages: payload })
    getMessageConsultantError = error => this.requestError( error )

    createMessageConsultant = ( consultant_id, data, center_id ) => {
      data.user_id = this.props.data.profileReducer.profile.id
      Post(
        `/api/v1/consultants/${ consultant_id }/messages?center_id=${ center_id }`,
        data,
        this.createMessageConsultantSuccess,
        this.createMessageConsultantError,
        this.load
      )
    }
    createMessageConsultantSuccess = payload => {
      this.requestSuccess( 'Message is successfully sent' )
      this.setState({ newMessageConsultant: {}, showCreateMessageConsultant: false })
      this.getMessageConsultant( this.props.data.selectCenterReducer.selectedCenter.center.consultant_id )
    }
    createMessageConsultantError = error => this.requestError( error )
    
    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          messages={ this.state.messages }
          newMessageConsultant={ this.state.newMessageConsultant }
          showCreateMessageConsultant={ this.state.showCreateMessageConsultant }
          getMessageConsultant={ this.getMessageConsultant }
          createMessageConsultant={ this.createMessageConsultant }
          onChangeMessageConsultantHOC={ this.onChangeMessageConsultantHOC }
          onLoadMessageConsultant={ this.state.loading } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC