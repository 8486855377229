import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import AlertNotSubscribe from 'components/Alert'
import Lang from 'Lang/General'

const TemplateListings = props => {

  const [ subscribedPackage, setSubscribePackage ] = useState( true )

  useEffect( () => {
    let tmpIndex = _.findIndex( props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Marketing Funnel' })
    setSubscribePackage( tmpIndex > -1 )
  }, [])

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_update
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'TEMPLATES' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-date icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          {
            // _.chunk( this.props.templates, 2 ).map( templateList => this.renderTemplateCard( templateList ) )
          }
        </Container>
      </ReactCSSTransitionGroup>
      {/*<TemplateEditor
        onChangeTemplatesHOC={ this.props.onChangeTemplatesHOC }
        onLoadTemplatesHOC={ this.props.onLoadTemplatesHOC }
        selectedTemplate={ this.props.selectedTemplate }
        showUpdateTemplate={ this.props.showUpdateTemplate }
        updateTemplate={ this.props.updateTemplate }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language } />*/}
      {
        // this.props.showDeleteTemplate && (
        //   <DeleteConfirmation
        //      handleModalClose={ () => this.props.onChangeTemplatesHOC( 'showDeleteTemplate', false ) }
        //      deleteOpen={ this.props.showDeleteTemplate }
        //      confirmAction={ () => this.props.removeTemplate() }
        //      content={ Lang[ 'ARE_YOU_SURE_TO_DELETE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        //      lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
        // )
      }
      {/* this.props.onLoadTemplatesHOC && <LoadingOverlay /> */}
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  // SiteMailingHOC,
  connect( mapStateToProps ),
)( TemplateListings )
