import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios-token'
import { Put } from 'utils/axios-token'

import Lang from 'Lang/General'

const DEFAULT_CONFIGURATIONS_SETTINGS = {
  days_webforms_delete: {
    topic: 'WEBFORMS',
    statement: 'DATA_CONFIG_WEBFORMS',
    instruction: 'DATA_CONFIG_WEBFORMS_UPDATE_INSTRUCTION',
    isEditing: false,
    value: 0
  }, 
  days_shop_orders_anonymise: {
    topic: 'SHOP',
    statement: 'DATA_CONFIG_SHOP',
    instruction: 'DATA_CONFIG_SHOP_UPDATE_INSTRUCTION',
    isEditing: false,
    value: 0
  }, 
  days_member_login_expires_anonymise: {
    topic: 'MEMBER_LOGINS',
    statement: 'DATA_CONFIG_MEMBER_LOGIN',
    instruction: 'DATA_CONFIG_MEMBER_LOGIN_UPDATE_INSTRUCTION',
    isEditing: false,
    value: 0
  }, 
  days_courses_anonymise: {
    topic: 'COURSES',
    statement: 'DATA_CONFIG_COURSES',
    instruction: 'DATA_CONFIG_COURSES_UPDATE_INSTRUCTION',
    isEditing: false,
    value: 0
  }, 
  days_funnel_participants_anonymise: {
    topic: 'FUNNEL_MARKETING',
    statement: 'DATA_CONFIG_FUNNEL',
    instruction: 'DATA_CONFIG_FUNNEL_UPDATE_INSTRUCTION',
    isEditing: false,
    value: 0
  }, 
}

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      gdprDataConfigSettings: DEFAULT_CONFIGURATIONS_SETTINGS,
      savedGdprDataConfig: {}
    }

    onChangeDataConfigurationHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : error && Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getGDPRDataConfigs = () => Get(
      `/api/v1/site_id/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/gdpr_data_configs`,
      this.getGDPRDataConfigsSuccess,
      this.getGDPRDataConfigsError,
      this.load
    )
    getGDPRDataConfigsSuccess = payload => {
      if ( payload.content ) {
        let tmp = _.cloneDeep( this.state.gdprDataConfigSettings )
        Object.keys( payload.content ).map( ( key, index ) => {
          tmp[ key ]['value'] = payload.content[ key ]
        })
        this.setState({ savedGdprDataConfig: payload.content, gdprDataConfigSettings: tmp })
      }
    }
    getGDPRDataConfigsError = error => this.requestError( error )

    updateGDPRDataConfigs = ( configKey ) => {
      let data = _.cloneDeep( this.state.savedGdprDataConfig )
      data[ configKey ] = Math.max( 0, this.state.gdprDataConfigSettings[ configKey ].value )
      data = { "content": data }
      Put(
      `/api/v1/site_id/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/gdpr_data_configs`,
        data,
        payload => this.updateGDPRDataConfigsSuccess( payload, configKey ),
        this.updateGDPRDataConfigsError,
        this.load
      )
    }
    updateGDPRDataConfigsSuccess = ( payload, configKey ) => {
      this.requestSuccess( Lang[ 'DATA_CONFIG_UPDATE_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      let tmpSavedConfig = _.cloneDeep( this.state.savedGdprDataConfig )
      let tmpSettings = _.cloneDeep( this.state.gdprDataConfigSettings )
      tmpSavedConfig[ configKey ] = Math.max( 0, this.state.gdprDataConfigSettings[ configKey ].value )
      tmpSettings[ configKey ].isEditing = false
      this.setState({ savedGdprDataConfig: tmpSavedConfig, gdprDataConfigSettings: tmpSettings })
    }
    updateGDPRDataConfigsError = ( error ) => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            savedGdprDataConfig={ this.state.savedGdprDataConfig }
            gdprDataConfigSettings={ this.state.gdprDataConfigSettings }
            onChangeDataConfigurationHOC={ this.onChangeDataConfigurationHOC }
            getGDPRDataConfigs={ this.getGDPRDataConfigs }
            updateGDPRDataConfigs={ this.updateGDPRDataConfigs }
            onLoadGDPRDataConfigs={ this.state.loading } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC