import React, { Component } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from 'reactstrap'
import _ from 'lodash'
import Lang from 'Lang/General'

class ViewAllCampaigns extends Component {

  componentDidMount = () => {

  }

  render = () => {
    return (
      <Modal isOpen={ this.props.showAll } size="xl" toggle={ () => this.props.onChangeLatestCampaignHOC( 'showAll', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeLatestCampaignHOC( 'showAll', false ) }>{ Lang[ 'ALL_OLS_CAMPAIGNS' ][ this.props.lang ] }</ModalHeader>
        <ModalBody style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            this.props.allCampaigns.map( item => {
              return(
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    margin: '35px',
                    alignItems: 'center',
                    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.2)',
                    border: 'none',
                    borderRadius: '5px',
                    width: '250px'
                  }}>
                  <img src={ item.teaser_image_url } style={{ width: '100%', height: '200px'}}/>
                  <p className="p-2 mt-2">{ item.title}</p>
                </div>
              )
            })
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={ () => this.props.onChangeLatestCampaignHOC( 'showAll', false ) }>{ Lang[ 'CLOSE' ][ this.props.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ViewAllCampaigns
