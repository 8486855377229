import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-token'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newRequest: {
        "product_id": 1,
        "client_id": 1,
        "consultant_id": 4,
        "user_id": 1,
        "from_date": "2020-02-02T00:00:00Z",
        "to_date": "2020-02-02T00:00:00Z",
        "status_id": 1
      },
      requestedProduct: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeRequestHOC = ( key, value ) => this.setState({ [key]: value })

    createProductsRequest = ( data, product_id, isBooking ) => Post(
      `/api/v1/admin_panel/products/${ product_id }/requests`,
      data,
      payload => this.createProductsRequestSuccess( payload, isBooking ),
      this.createProductsRequestError,
      this.load
    )
    createProductsRequestSuccess = ( payload, isBooking ) => {
      if ( isBooking ) {
        this.requestSuccess( Lang[ 'BOOKED_PRODUCT_SUCCESSFULLY' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      } else {
        this.requestSuccess( Lang[ 'SEND_REQUEST_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      }
      this.getRequestedProduct()
    }
    createProductsRequestError = error => this.requestError( error )

    getRequestedProduct = () => Get(
      `/api/v1/user_admin_panel_products_requests`,
      this.getRequestedProductSuccess,
      this.getRequestedProductError,
      this.load
    )
    getRequestedProductSuccess = payload => {
      this.setState({ requestedProduct: payload })
    }
    getRequestedProductError = () => {}

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadRequest={ this.state.loading }
            onChangeProductsOrderingHOC={ this.onChangeProductsOrderingHOC }
            onChangeRequestHOC={ this.onChangeRequestHOC }
            newRequest={ this.state.newRequest }
            createProductsRequest={ this.createProductsRequest }
            getRequestedProduct={ this.getRequestedProduct }
            getStatus={ this.getStatus }
            requestedProduct={ this.state.requestedProduct }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
