import { combineReducers } from "redux";

import ajaxReducer from './ajax'
import profileReducer from './profile'
import roleReducer from './role'
import languageReducer from './language'
import ThemeOptions from './ThemeOptions'
import MediaDatabaseToggle from './MediaDatabaseToggle'
import LoginBoxToggle from './LoginBoxToggle'
import shortcutReducer from './shortcuts'
import platformConfigReducer from './platformConfig'
import selectCenterReducer from './selectCenter'

export default combineReducers({
  ajaxReducer,
  profileReducer,
  ThemeOptions,
  roleReducer,
  languageReducer,
  MediaDatabaseToggle,
  LoginBoxToggle,
  shortcutReducer,
  platformConfigReducer,
  selectCenterReducer
});
