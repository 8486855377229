import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import el from "date-fns/locale/el"
import de from "date-fns/locale/de"
import nl from "date-fns/locale/nl"
import it from "date-fns/locale/it"

import "react-datepicker/dist/react-datepicker.css"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'
import DynamicForm from 'containers/Dashboard/OLS/Campaigns/components/Form'
import { ParseFormValues } from 'containers/Dashboard/OLS/Campaigns/components/Form/utils'

const BookCampaign = props => {
  let {
    isOneTimer,
    selectedCampaign,
    showBookModal,
    onChangeLatestCampaignHOC,
    budgetData,
    createBookedCampaign,
    bookedCampaignError,
    lang,
    onLoadLatestCampaign
  } = props
  const [ bookableMonths, setBookableMonths ] = useState( [] )
  const [ pageErrors, setPageErrors ] = useState( [] )

  useEffect( () => {
    registerLocale( "el", el )
    registerLocale( "de", de )
    registerLocale( "nl", nl )
    registerLocale( "it", it )
    Moment.locale( 'en' )
  }, [] )

  useEffect( () => {
    selectedCampaign.bookable_months && setBookableMonths(
      selectedCampaign.bookable_months.map( month => Moment( month, 'MMMM' ).month() )
    )
  }, [ selectedCampaign.bookable_months ] )

  useEffect( () => {
    if( props.selectedCampaign?.id > 0 ){
      setPageErrors( [] )
      onChangeLatestCampaignHOC(
        'budgetData',
        {
          campaign_id: 1,
          remark: '',
          internal_remark: 'internal remark 2',
          fa_budget: 0,
          ga_budget: 0,
          start_date: null,
          status: "NEW",
          end_date: null,
          priority: 0,
          values: selectedCampaign.values
        }
      )
    }
  }, [ props.selectedCampaign ] )

  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( budgetData )
    tmp = {
      ...tmp,
      [key]: value,
      campaign_id: selectedCampaign.id
    }
    if( key === 'start_date' ){
      tmp.end_date = ( new Date( value ) ).setDate( value.getDate() + 56 )
    }
    return onChangeLatestCampaignHOC( 'budgetData', tmp )
  }

  return (
    <Modal
      size='lg'
      isOpen={ showBookModal }
      toggle={ () => onChangeLatestCampaignHOC( 'showBookModal', false ) }>
      <ModalHeader toggle={ () => onChangeLatestCampaignHOC( 'showBookModal', false ) }>{ Lang[ 'BOOK_CAMPAIGN' ][ lang ] }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( bookedCampaignError ) && typeof( bookedCampaignError ) === 'string' &&  (
            <span className="text-danger">{ bookedCampaignError }</span>
          )
        }
        <Form>
          <FormGroup>
            <Label>{ Lang[ 'CAMPAIGN_NAME' ][ lang ] }</Label>
            <Input
              type="text"
              readOnly
              value={ selectedCampaign.title }
              invalid={ false }/>
          </FormGroup>
          {
            !selectedCampaign.is_app_campaign && (
              <FormGroup>
                <Label>{ Lang[ 'FACEBOOK_BUDGET' ][ lang ] }</Label>
                <Input
                  step='1'
                  type='number'
                  onChange={ e => onChangeData( 'fa_budget', e.target.value ) }
                  value={ budgetData.fa_budget }
                  invalid={ false }/>
              </FormGroup>
            )
          }
          <FormGroup>
            <Label>
              { Lang[ 'START_ON' ][ lang ] }
              <span style={{ color: "#ff0000" }}> * </span>
            </Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className={ `form-control ${ bookedCampaignError.start_date ? 'is-invalid' : '' }` }
              selected={ budgetData.start_date }
              popperModifiers={{
                flip: { behavior: [ 'bottom' ] },
                preventOverflow: { enabled: false },
                hide: { enabled: false }
              }}
              minDate={ new Date() }
              filterDate={ date => bookableMonths.length > 0
                ? bookableMonths.indexOf( date.getMonth() ) > -1
                : true
              }
              onChange={ e => onChangeData(  'start_date', e ) }
            />
            { 
              (
                !selectedCampaign.is_app_campaign &&
                !_.isEmpty( selectedCampaign?.start_date_hint?.[ lang ] )
              ) && (
                <FormText>{ selectedCampaign.start_date_hint[ lang ] }</FormText> 
              )
            }
            <FormText color="danger">{ bookedCampaignError.start_date }</FormText>
          </FormGroup>
          {
            selectedCampaign.is_app_campaign && (
              <FormGroup>
                <Label>
                  { Lang[ 'END_DATE' ][ lang ] }
                  <span style={{ color: "#ff0000" }}> * </span>
                </Label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className={ `form-control ${ bookedCampaignError.end_date ? 'is-invalid' : '' }` }
                  selected={ budgetData.end_date }
                  popperModifiers={{
                    flip: { behavior: [ 'bottom' ] },
                    preventOverflow: { enabled: false },
                    hide: { enabled: false }
                  }}
                  minDate={ 
                    budgetData.start_date
                      ? ( new Date( budgetData.start_date ) ).setDate( budgetData.start_date.getDate() + 1 ) 
                      : null
                  }
                  onChange={ e => onChangeData( 'end_date', e ) }
                />
                <FormText color="danger">{ bookedCampaignError.end_date }</FormText>
              </FormGroup>
            )
          }
          {
            selectedCampaign.is_app_campaign && (
              <FormGroup>
                <Label>{ Lang[ 'PRIORITY' ][ lang ] }</Label>
                <Input
                  step='1'
                  type='number'
                  onChange={ e => onChangeData( 'priority', e.target.value ) }
                  value={ budgetData.priority }
                  invalid={ false }/>
                <FormText>{ Lang[ 'PRIORITY_NOTE' ][ lang ] }</FormText>
                {
                  !_.isEmpty( bookedCampaignError ) && (
                    <span className="text-danger">{ bookedCampaignError[ 'priority' ] }</span>
                  )
                }
              </FormGroup>
            )
          }
          {
            !_.isEmpty( selectedCampaign.remark_label ) && (
              <FormGroup>
                <Label>
                  { selectedCampaign.remark_label?.[ lang ] }
                  { selectedCampaign.is_remark_required && <span style={{ color: "#ff0000" }}> * </span> }
                </Label>
                <Input
                  rows="3"
                  type="textarea"
                  onChange={ e => onChangeData( 'remark', e.target.value ) }
                  value={ budgetData.remark }
                  invalid={ bookedCampaignError.remark }
                />
                <FormFeedback>{ bookedCampaignError.remark }</FormFeedback>
              </FormGroup>
            )
          }
          {
            selectedCampaign.content?.length > 0 && (
              <DynamicForm
                { ...props }
                pageErrors={ pageErrors }
                olsCampaignType='budgetData'
                currentOlsCampaign={ budgetData }
                onChangeOlsCampaign={ onChangeLatestCampaignHOC }
              />
            )
          }
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            let tmp = {
              ...budgetData,
              status: 'NEW',
              campaign_id: selectedCampaign.id
            }

            let tmpErrors = _.filter( budgetData.values, formVal => { 
              if ( 
                formVal.validation && 
                formVal.isRequired && 
                ( formVal.isDependentFieldRequired || formVal.isDependentFieldRequired === undefined ) 
              ) {
                if ( formVal.type === 'dateField' && formVal.value === 'Invalid date' ) {
                  return true
                } else if ( formVal.requiredSelections?.length > 0 ) {
                  return ( 
                    _.isEmpty( formVal.value ) || 
                    formVal.requiredSelections.indexOf( '' ) > -1 ||
                    _.difference( formVal.requiredSelections, formVal.value ).length !== 0 
                  )
                } else if ( formVal.type !== 'inputFile' ) {
                  return _.isEmpty( formVal.value ) 
                } else {
                  return props.uploadedMedia.filter( x => x.formContentId === formVal.id ).length <= 0
                }
              } else {
                return false
              }
            })
            if ( tmpErrors.length > 0 ) {
              setPageErrors( tmpErrors )
              return
            } else {
              setPageErrors( [] )
            }
            
            if ( 
              _.isEmpty( tmp.remark ) &&
              selectedCampaign.is_remark_required 
            ) {
              onChangeLatestCampaignHOC( 
                'bookedCampaignError', 
                {
                  remark: (
                    Lang[ 'FIELD' ][ lang ] +
                    ` "${ selectedCampaign.remark_label[ lang ] || '' }" ` +
                    Lang[ 'IS_REQUIRED' ][ lang ]
                  )
                }
              )
            } else {
              let tmpFormValues = !_.isEmpty( tmp.values ) 
                ? _.cloneDeep( tmp.values )
                : []
              tmpFormValues.map( ( item, index ) => {
                if( item.type === 'inputFile' ){
                  tmpFormValues[index].value = props.uploadedMedia.filter( x => x.formContentId === item.id )
                } else if ( item.type === 'textarea' ) {
                  item.value = item.value.trim()
                } else if ( item.type === 'dateField' && item.value === 'Invalid date') {
                  item.value = ''
                }
              })
              tmp.values = tmpFormValues
              tmp.submitted_form_content = tmp.content
              tmp.email_values = ParseFormValues( tmp.content, tmp.values, lang )
              tmp.ga_budget = parseInt( tmp.ga_budget ) || 0
              tmp.fa_budget = parseInt( tmp.fa_budget ) || 0
              tmp.priority = parseInt( tmp.priority ) || 0
              tmp.end_date = selectedCampaign.is_app_campaign && tmp.end_date 
                ? Moment( tmp.end_date ).utc().format() 
                : !selectedCampaign.is_app_campaign && tmp.start_date
                  ? Moment( tmp.start_date ).add( 56, 'days' ).utc().format()
                  : null
              tmp.start_date = tmp.start_date ? Moment( tmp.start_date ).utc().format() : null
              createBookedCampaign( tmp, isOneTimer )
            }
          }}>{ Lang[ 'SUBMIT' ][ lang ] }</Button>
      </ModalFooter>
      { onLoadLatestCampaign && <LoadingOverlay/> }
    </Modal>
  )
}

export default BookCampaign
