import React, { useState, useEffect } from 'react'
import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Nav, NavItem, NavLink,
  TabContent, TabPane,
  Button 
} from 'reactstrap'
import _ from 'lodash'
import ReactTable from 'react-table'
import Moment from 'moment'
import classnames from 'classnames'

import Lang from 'Lang/General'

const SubmittedFormView = props => {
  const [ activeTab, setActiveTab ] = useState( 0 )
  const [ pageValuesCollection, setPageValuesCollection ] = useState( [] )

  useEffect(() => {
    if( !props.showViewSubmittedForm ) {
      props.onChangeDigitalOrderFormHOC( 'selectedSubmittedForm', {
        values: []
      } )
    }
  }, [ props.showViewSubmittedForm ])

  useEffect( () => {
    if ( !_.isEmpty( props.selectedSubmittedForm ) ) {
      InitialiseFormResults( 
        props.selectedSubmittedForm, 
        setPageValuesCollection,
        setActiveTab,
        props.lang
      )
    }
  }, [ props.selectedSubmittedForm ])

  const renderPageName = ( page ) => {
    let pageName = page[ 'name' ][ props.lang ].replace(/<\/?[^>]+(>|$)/g, "")
    if ( pageName !== "" && pageName !== Lang[ 'PAGE' ][ props.lang ] ) {
      return pageName 
    } else {
      return `${ Lang[ 'PAGE' ][ props.lang ] } ${ page.number }`
    }
  }
  
  return (
    <Modal isOpen={ props.showViewSubmittedForm } size='xl'>
      <ModalHeader 
        toggle={ () => props.onChangeDigitalOrderFormHOC( 'showViewSubmittedForm', false ) } >
        { props.selectedSubmittedForm[ 'digital_order_form' ][ 'name' ][ props.lang ] }
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          {
            pageValuesCollection.map( ( page ) => {
              return (
                <NavItem>
                  <NavLink href="javascript:void(0);"
                    className={ classnames({ active: activeTab === page.id }) }
                    onClick={ () => setActiveTab( page.id ) } >
                    { renderPageName( page ) }
                  </NavLink>
                </NavItem>
              )
            })
          }
        </Nav>
        <TabContent activeTab={ activeTab }>
          {
            pageValuesCollection.map( ( page ) => {
              return (
                <TabPane tabId={ page.id }>
                  <ReactTable
                    defaultPageSize={ 10 }
                    showPagination={ true }
                    nextText={ Lang[ 'NEXT_PAGE' ][ props.lang ]}
                    previousText={ Lang[ 'PREVIOUS' ][ props.lang ]}
                    pageText={ Lang[ 'PAGE' ][ props.lang ]}
                    rowsText={ Lang[ 'ROWS' ][ props.lang ]}
                    NoDataComponent={ () => <span></span> }
                    data={ page.values.filter( val => !_.isEmpty( val.value ) ) }
                    columns={[
                      {
                        Header: Lang[ 'LABEL' ][ props.lang ],
                        accessor: 'dataLabel',
                        width: Math.round( window.innerWidth * 0.3 ),
                        Cell: ({ value }) => <div style={{ wordBreak: "break-all" }}>{ value }</div>
                      },
                      {
                        Header: Lang[ 'VALUE' ][ props.lang ],
                        accessor: 'value'
                      }
                    ]}
                  />
                </TabPane>
              )
            })
          }
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button 
          color='link'
          onClick={ () => props.onChangeDigitalOrderFormHOC( 'showViewSubmittedForm', false ) }>
          { Lang[ 'CLOSE' ][ props.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const InitialiseFormResults = ( individualResult, setPageValuesCollection, setActiveTab, lang ) => {
  let values = _.cloneDeep( individualResult.values.filter( val => !_.isEmpty( val.value ) ) )
  let pageValuesMappings = _.groupBy( values, 'page' )
  let pages = _.map( 
    pageValuesMappings, 
    ( pageValues, page ) => {
      const tmpPageValues = pageValues.map( value => {
        let formValue

        if ( value.dataLabel === 'DELIVERY_REMARK' ) {
          value.dataLabel = Lang[ 'REMARK' ][ lang ]
        } else {
          let domOfValue = getDomOfValue( individualResult, value )
          if ( _.isEmpty( domOfValue ) || _.isEmpty( domOfValue.label[ lang ] ) ) {
            value.dataLabel = value.dataLabel
          } else {
            value.dataLabel = processFieldLabel( domOfValue.label[ lang ] ) 
          }
        }

        if ( typeof value.value === 'string' ) {
          if ( value.type === 'inputFile' ) {
            formValue = (
              <a 
                style={{ overflow: "hidden", color: "#0275d8" }} 
                href={ value.value } >
                { value.value }
              </a>
            )
          } 
          else if ( value.type === 'dateField' ) {
            formValue =  Moment( value.value ).format( 'YYYY-MM-DD' )
          } 
          else if ( value.type === 'radio' ) {
            let domOfValue = getDomOfValue( individualResult, value )
            let label = value.value
            if ( !_.isEmpty( domOfValue ) && !_.isEmpty( domOfValue.selections ) ) {
              formValue =  getRadioSelectionLabelByValue( 
                domOfValue, 
                value, 
                lang,
                pageValuesMappings[ page ]
              )
            } else {
              formValue = label
            }
          } 
          else {
            formValue = value.value
          }
        } else {
          if ( value.type === 'checkbox' ) {
            let domOfValue = getDomOfValue( individualResult, value )
            let labels = value.value
            if ( !_.isEmpty( domOfValue ) && !_.isEmpty( domOfValue.selections ) ) {
              labels = getCheckboxSelectionLabelByValue( 
                domOfValue, 
                value, 
                lang, 
                pageValuesMappings[ page ]
              )
            }
            formValue = (
              <ul className='pl-3 m-0'>
                {
                  labels.map( item => {
                    return item
                  })
                }
              </ul>
            )
          } else { 
            formValue = (
              <ul style={{ margin: "0px" }}>
                {
                  value.value.map( item => {
                    if ( value.type === 'inputFile' ) {
                      return (
                        <li>
                          <a 
                            style={{ overflow: "hidden", color: "#0275d8" }} 
                            href={ item.url } >
                            { item.filename }
                          </a>
                        </li> 
                      )
                    } else {
                      return <li>{ item }</li> 
                    }
                  })
                }
              </ul>
            )
          }
        }
        value.value = formValue
        return value
      })

      if ( page != 'undefined' ) {
        return {
          id: page,
          number: page,
          name: individualResult.current_digital_order_form.content[0].children[ parseInt( page ) - 1 ].name,
          values: _.filter( tmpPageValues, value => !_.isEmpty( value ) && !value.isDependentFieldExtracted )
        }
      } else {
        return {
          id: 'REMARKS',
          name: Lang[ 'REMARKS' ],
          values: _.filter( tmpPageValues, value => !_.isEmpty( value ) && !value.isDependentFieldExtracted )
        }
      }
    }
  )

  setPageValuesCollection( pages )
  if ( pages.length > 0 ) {
    setActiveTab( pages[0].id )
  } 
}

const getDomOfValue = ( individualResult, value ) => {
  let pageDom = individualResult.current_digital_order_form.content[0].children[ parseInt( value.page ) - 1 ]
  let dom = searchDomWithId( pageDom, value.id )
  return dom
}

const processFieldLabel = label => {
  label = label.split( "</p>" )[ 0 ]
  label = label.split( "</div>" )[ 0 ]
  label = label.split( "<br" )[ 0 ]
  label = label.replace( /<[^>]+>/g, '' ).replace( /&nbsp;/g, "" )  
  return label
}

const searchDomWithId = ( dom, targetId ) => {
  let foundDom = {}
  if ( _.isEmpty( dom ) || dom.id == targetId ) {
    return dom
  }
  else if ( !_.isEmpty( dom.children ) ) {
    for ( let i = 0; i < dom.children.length; i++ ) {
      let tmpDom = searchDomWithId( dom.children[i], targetId )
      if ( !_.isEmpty( tmpDom ) ) {
        foundDom = tmpDom
        break
      }
    }
  } 
  else if ( ["Radio", "RadioFlexible", "Checkbox", "CheckboxFlexible"].indexOf( dom.type ) !== -1 && !_.isEmpty( dom.selections )) {
    for ( let i = 0; i < dom.selections.length; i++ ) {
      let selection = dom.selections[i]
      if ( !_.isEmpty( selection.dependentField ) ) {
        if ( selection.dependentField.id == targetId ) {
          foundDom = selection.dependentField
          break
        }
      }
    }
  }
  return foundDom
}

const getRadioSelectionLabelByValue = ( dom, value, lang, currentPageValues ) => {
  if ( !_.isEmpty( value.flexibleInputValue ) ) {
    return <div style={{ whiteSpace: "pre-line" }}>{ value.flexibleInputValue }</div>
  } else {
    for ( let i = 0; i < dom.selections.length; i++ ) {
      if ( dom.selections[ i ].value == value.value ) {
        let selectionDependentFieldIndex = (
          ( 
            !_.isEmpty( dom.selections[ i ].dependentField ) && 
            _.findIndex( currentPageValues, { id: dom.selections[ i ].dependentField.id } ) 
          ) ||
          -1
        )

        if( selectionDependentFieldIndex > -1 ){
          currentPageValues[ selectionDependentFieldIndex ].isDependentFieldExtracted = true
        }

        return (
          <div style={{ whiteSpace: "pre-line" }}>
            { dom.selections[ i ].label[ lang ] }
            {
              selectionDependentFieldIndex > -1 ? (
                <p>
                  { `${ dom.selections[ i ].dependentField.label[ lang ] }: ` } 
                  { 
                    typeof currentPageValues[ selectionDependentFieldIndex ].value === 'string' ? (
                      currentPageValues[ selectionDependentFieldIndex ].value 
                    ) : (
                      <a 
                        style={{ overflow: "hidden", color: "#0275d8" }} 
                        href={ currentPageValues[ selectionDependentFieldIndex ].value[ 0 ].url } >
                        { currentPageValues[ selectionDependentFieldIndex ].value[ 0 ].url }
                      </a>
                    ) 
                  }
                </p>
              ) : (
                <></>
              )
            }
          </div>
        )
      }
    }
  }
}

const getCheckboxSelectionLabelByValue = ( checkboxDom, checkboxValue, lang, currentPageValues ) => {
  let dom = _.cloneDeep( checkboxDom )
  let value = _.cloneDeep( checkboxValue )
  let labels = []
  let index

  dom.selections.map( ( selection ) => {
    let selectionDependentFieldIndex = (
      ( 
        !_.isEmpty( selection.dependentField ) && 
        _.findIndex( currentPageValues, { id: selection.dependentField.id } ) 
      ) ||
      -1
    )

    if( selectionDependentFieldIndex > -1 ){
      currentPageValues[ selectionDependentFieldIndex ].isDependentFieldExtracted = true
    }

    index = value.value.indexOf( selection.value )
    if ( index >= 0 ) {
      value.value.splice( index, 1 )
      labels.push( 
        <li>
          { selection.label[ lang ] }
          {
            !_.isEmpty( selection.nestedSelections ) ? (
              <ul className='pl-3' >
                {
                  selection.nestedSelections.map( nestedSelection => {
                    index = value.value.indexOf( nestedSelection.value )
                    index > -1 && value.value.splice( index, 1 )
                    return index > -1 ? (
                      <li>
                        { nestedSelection.label[ lang ] }
                        {
                          !_.isEmpty( nestedSelection.subCheckboxes ) ? (
                            <ul className='pl-3' >
                              {
                                nestedSelection.subCheckboxes.map( subselection => {
                                  index = value.value.indexOf( subselection.value )
                                  index > -1 && value.value.splice( index, 1 )
                                  return index > -1 ? (
                                    <li>{ subselection.label[ lang ] }</li>
                                  ) : (
                                    <></>
                                  )
                                })
                              }
                            </ul>
                          ) : (
                            <></>
                          )
                        }
                      </li>
                    ) : (
                      <></>
                    )
                  })
                }
              </ul>
            ) : (
              <></>
            )
          }
          {
            selectionDependentFieldIndex > -1 ? (
              <ul className='pl-3' >
                <li>
                  { `${ selection.dependentField.label[ lang ] }: ` }
                  { 
                    typeof currentPageValues[ selectionDependentFieldIndex ].value === 'string' ? (
                      currentPageValues[ selectionDependentFieldIndex ].value 
                    ) : (
                      <a 
                        style={{ overflow: "hidden", color: "#0275d8" }} 
                        href={ currentPageValues[ selectionDependentFieldIndex ].value[ 0 ].url } >
                        { currentPageValues[ selectionDependentFieldIndex ].value[ 0 ].url }
                      </a>
                    ) 
                  }
                </li>
              </ul>
            ) : (
              <></>
            )
          }
        </li> 
      )
    }
  })

  if ( !_.isEmpty( value.flexibleInputValue ) ) {
    labels.push( <li>{ value.flexibleInputValue }</li> )
    let flexibleOptionValue = value.flexibleInputValue.replaceAll( ' ', '-' )
    index = value.value.indexOf( flexibleOptionValue )
    value.value.splice( index, 1 )
  }

  return [ ...labels, ...value.value ]
}

export default SubmittedFormView