export const MainNav = [
  {
    icon: 'pe-7s-rocket',
    label: 'DASHBOARD',
    to: '#/dashboard/main'
  },
  {
    icon: 'pe-7s-target',
    label: 'ANNUAL_MARKETING_PLAN',
    secLabel: 'Annual Marketing Plan'
  },
  {
    icon: 'pe-7s-news-paper',
    label: 'MY_ORDERS',
    secLabel: 'Digital Order Form',
    content: [
      {
        icon: 'pe-7s-note',
        label: 'AVAILABLE_FORMS',
        to: '#/dashboard/digital-order-form/listings/available_forms' 
      },
      {
        icon: 'pe-7s-news-paper',
        label: 'SUBMITTED_FORMS',
        to: '#/dashboard/digital-order-form/listings/submitted_forms' 
      },
      {
        icon: 'pe-7s-box1',
        label: 'ARCHIVED_FORMS',
        to: '#/dashboard/digital-order-form/listings/archived_forms'  
      }
    ]
  },
  {
    icon: 'pe-7s-speaker',
    label: 'OLS',
    secLabel: 'OLS',
    content: [
      {
        icon: 'pe-7s-home',
        label: 'LEAD_CENTRE',
        to: 'https://leadcenter.aciso-suite.com/login',
        externalLink: true
      },
      {
        icon: 'pe-7s-ticket',
        label: 'CAMPAIGNS',
        to: '#/dashboard/ols/listings'
      },
      {
        icon: 'pe-7s-settings',
        label: 'SETTINGS',
        to: '#/dashboard/ols/settings'
      },
      {
        icon: 'pe-7s-check',
        label: 'COMPLETED_CAMPAIGN',
        to: '#/dashboard/ols/completed-campaigns'
      }
    ]
  },
  {
    icon: 'pe-7s-news-paper',
    label: 'NEWSLETTER',
    secLabel: 'Newsletters',
    content: [
      {
        icon: 'pe-7s-news-paper',
        label: 'LISTINGS',
        to: '#/dashboard/newsletter'
      },
      {
        icon: 'pe-7s-add-user',
        label: 'RECIPIENTS',
        content: [
          {
            icon: 'pe-7s-add-user',
            label: 'ACTIVE',
            to: '#/dashboard/newsletter-active-recipients'
          },
          {
            icon: 'pe-7s-add-user',
            label: 'INACTIVE',
            to: '#/dashboard/newsletter-inactive-recipients'
          },
          {
            icon: 'pe-7s-way',
            label: 'DOI',
            to: '#/dashboard/newsletter-doi-recipients'
          },
          {
            icon: 'pe-7s-shield',
            label: 'BLACKLISTS',
            to: '#/dashboard/newsletter-blacklisted-recipients'
          },
          {
            icon: 'pe-7s-cloud-upload',
            label: 'BULK_UPLOAD',
            to: '#/dashboard/newsletter-bulk-upload'
          }
        ]
      },
      {
        icon: 'pe-7s-users',
        label: 'GROUPS',
        to: '#/dashboard/newsletter-recipient-group'
      },
      {
        icon: 'pe-7s-next-2',
        label: 'SENDER',
        to: '#/dashboard/newsletter-senders'
      },
      {
        icon: 'pe-7s-disk',
        label: 'TEMPLATE',
        to: '#/dashboard/newsletter-templates'
      }
    ]
  },
  {
    icon: 'pe-7s-lintern',
    label: 'FUNNEL',
    secLabel: 'Marketing Funnels',
    content: [
      {
        icon: 'pe-7s-speaker',
        label: 'LISTINGS',
        content: [
          {
            icon: 'pe-7s-arc',
            label: 'FUNNELS',
            to: '#/dashboard/marketing-funnels/listings/funnels'
          },
          {
            icon: 'pe-7s-mail',
            label: 'MAILING',
            to: '#/dashboard/marketing-funnels/listings/mailings'
          }
        ]

      },
      {
        icon: 'pe-7s-users',
        label: 'RECIPIENTS',
        content: [
          {
            icon: 'pe-7s-add-user',
            label: 'ACTIVE',
            to: '#/dashboard/marketing-funnels/recipient/active'
          },
          {
            icon: 'pe-7s-shield',
            label: 'BLACKLISTS',
            to: '#/dashboard/marketing-funnels/recipient/blacklisted'
          },
          {
            icon: 'pe-7s-cloud-upload',
            label: 'BULK_UPLOAD',
            to: '#/dashboard/marketing-funnels/recipient/bulk-upload'
          }
        ]
      },
      {
        icon: 'pe-7s-paper-plane',
        label: 'SENDER',
        to: '#/dashboard/marketing-funnels/sender'
      },
      {
        icon: 'pe-7s-drawer',
        label: 'GROUPS',
        to: '#/dashboard/marketing-funnels/recipient-group'
      }
    ]
  },
  {
    icon: 'pe-7s-global',
    label: 'WEBSITE',
    content: [
      {
        icon: 'pe-7s-graph',
        label: 'MATOMO_STATISTICS',
        to: '#/dashboard/website/matomo'
      },
      {
        icon: 'pe-7s-display2',
        label: 'MULTITRAINING',
        to: 'https://multitraining.fitness/',
        externalLink: true
      }
    ]
  },
  {
    icon: 'pe-7s-display1',
    label: 'EMAIL_REPORTING',
    content: [
      {
        icon: 'pe-7s-mail',
        label: 'MAIL_GUN_STATISTICS',
        to: '#/dashboard/performance-report/mail-gun-stats'
      }
    ]
  },
  {
    icon: 'pe-7s-network',
    label: 'LOCAL_LISTINGS',
    secLabel: 'Local Listings',
    content: [
      {
        icon: 'pe-7s-map-marker',
        label: 'LOCATIONS',
        to: '#/dashboard/local-listing/locations'
      },
      {
        icon: 'pe-7s-graph1',
        label: 'REPORTS',
        to: '#/dashboard/local-listing/reports'
      }
    ]
  },
  {
    icon: 'pe-7s-shield',
    label: 'GDPR_CENTER',
    secLabel: 'GDPR Center',
    content: [
      {
        icon: 'pe-7s-copy-file',
        label: 'AV_CONTRACT',
        secLabel: 'AV Contract',
        to: '#/dashboard/av-contract'
      },
      {
        icon: 'pe-7s-note',
        label: 'INFOCENTER',
        to: '#/dashboard/article-fourteen'
      },
      {
        icon: 'pe-7s-menu',
        label: 'PRIVACY_POLICY',
        to: '#/dashboard/privacy-policy'
      },
      {
        icon: 'pe-7s-file',
        label: 'IMPRINT',
        to: '#/dashboard/imprint'
      },
      {
        icon: 'pe-7s-info',
        label: 'INQUIRY_CONCERNED'
      },
      {
        icon: 'pe-7s-settings',
        label: 'DATA_CONFIGURATION',
        to: '#/dashboard/gdpr-data-configuration'
      },
      {
        icon: 'pe-7s-box1',
        label: 'COOKIE_BANNER',
        content: [
          {
            icon: 'pe-7s-notebook',
            label: 'CATEGORIES',
            to: '#/dashboard/cookie-banner/categories'
          },
          {
            icon: 'pe-7s-bookmarks',
            label: 'CONTENT',
            to: '#/dashboard/cookie-banner/content'
          }
        ]
      }
    ]
  }
]
