import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback, Collapse,
  Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap'
import ReactSelect from 'react-select'
import Moment from 'moment'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const Summary = ( props ) => {
  const [detailsOpen, setDetailsOpen ] = useState( false )


  return(
    <Modal
      isOpen={ props.openSummaryModal }
      size={'xl'}>
      <ModalHeader toggle={ () => props.onChangeBulkHOC('openSummaryModal', false ) }>{ Lang['SUMMARY'][props.dataReducer.languageReducer.userSettings.admin_panel_language] }</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <p>{ props.summary.msg }</p>
            <Button color="link" onClick={ () => setDetailsOpen( !detailsOpen ) }>{ Lang['DETAILS'][props.dataReducer.languageReducer.userSettings.admin_panel_language] }</Button>
            <Collapse isOpen={ detailsOpen } style={{ marginTop: '20px' }}>
              <Card>
                <CardBody>
                  {
                    props.summary.total_emails_created > 0 && (
                      <>
                        <p>{ Lang['EMAIL_CREATED'][props.dataReducer.languageReducer.userSettings.admin_panel_language] }</p>
                        {
                          props.summary.accepted.created_emails.map( item => {
                            return(
                              <p>{`- ${ item }`}</p>
                            )
                          })
                        }
                      </>
                    )
                  }
                  {
                    props.summary.total_emails_rejected > 0 && (
                      <>
                        <p>{`${ Lang['TOTAL_REJECTED'][props.dataReducer.languageReducer.userSettings.admin_panel_language]}: ${ props.summary.total_emails_rejected }` }</p>
                        <p>{ Lang['EMAIL_ACTIVE'][props.dataReducer.languageReducer.userSettings.admin_panel_language] }</p>
                        {
                          props.summary.rejected.active_emails.map( item => {
                            return(
                              <p>{`- ${ item }`}</p>
                            )
                          })
                        }
                        <p>{ Lang['EMAIL_INACTIVE'][props.dataReducer.languageReducer.userSettings.admin_panel_language] }</p>
                        {
                          props.summary.rejected.inactive_emails.map( item => {
                            return(
                              <p>{`- ${ item }`}</p>
                            )
                          })
                        }
                        <p>{ Lang['EMAIL_BLACKLISTED'][props.dataReducer.languageReducer.userSettings.admin_panel_language] }</p>
                        {
                          props.summary.rejected.blacklisted_emails.map( item => {
                            return(
                              <p>{`- ${ item }`}</p>
                            )
                          })
                        }
                        <p>{ Lang['EMAIL_DUPLICATED'][props.dataReducer.languageReducer.userSettings.admin_panel_language] }</p>
                        {
                          props.summary.rejected.duplicated_emails.map( item => {
                            return(
                              <p>{`- ${ item }`}</p>
                            )
                          })
                        }
                      </>
                    )
                  }
                </CardBody>
              </Card>
            </Collapse>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button onClick={ () => props.onChangeBulkHOC('openSummaryModal', false ) } color="primary">{ Lang['CLOSE'][props.dataReducer.languageReducer.userSettings.admin_panel_language] }</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( Summary )
