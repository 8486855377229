import { GET_PROFILE, CLEAR_PROFILE } from 'actions/type'

let initialState = {
  profile: {
    role_id: 0
  }
}

export default function profileReducer( state = initialState, action ) {
  switch( action.type ) {
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload
      }
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: {
          role_id: 0
        }
      }
    default: return state
  }
}
