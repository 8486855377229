import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input, Alert,
  UncontrolledTooltip
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ArkPagination from 'components/Pagination'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import AlertNotSubscribe from 'components/Alert'
import SendersForm from './components/form'

import WithSenders from './actions'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

class SendersListings extends Component {
  state = {
    view: 'scheduled',
    subscribedPackage: true
  }

  componentDidMount = () => {
    let tmp = {
      is_paginated: true,
      page: 1,
      is_blacklisted: true,
    }
    let tmpString = convertSearchObjectToBase64( tmp )
    this.props.getSenders( tmpString )
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Newsletters' })
    this.setState({ subscribedPackage: tmpIndex > -1 })
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_create && this.state.subscribedPackage
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_update
    }
  }

  renderCreateModal = () => {
    return(
      <SendersForm
        data={ this.props.newSenderData }
        onChangeSendersHOC={ this.props.onChangeSendersHOC }
        toggle={ () => this.props.onChangeSendersHOC( 'showCreateSenders', false ) }
        isOpen={ this.props.showCreateSenders }
        headerText={ Lang[ 'CREATE_SENDER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        mode="create"
        onSubmit={ () => this.props.postSender() }
        errorMessage={ this.props.errorMessage }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language } />
    )
  }

  renderEditModal = () => {
    return(
      <SendersForm
        data={ this.props.selectedSender }
        onChangeSendersHOC={ this.props.onChangeSendersHOC }
        toggle={ () => this.props.onChangeSendersHOC( 'showEditSenderModal', false ) }
        isOpen={ this.props.showEditSenderModal }
        headerText={ Lang[ 'EDIT_SENDER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        mode="edit"
        onSubmit={ () => this.props.updateSender() }
        errorMessage={ this.props.errorMessage }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language } />
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'SENDERS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-next icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_SENDER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
              onClick: () => {
                this.props.onChangeSendersHOC( 'showCreateSenders', true )
              },
              display: this.handleSecurityModulesCreate()
            }
          ]} />
        <Container fluid>
          {
            !this.state.subscribedPackage && (
              <AlertNotSubscribe { ...this.props } />
            )
          }
          <Row>
            <Col md={ 12 }>
              <Alert color="danger">
                { Lang[ 'NEWSLETTER_SENDERS_CANNOT_DELETE_DESC' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
              </Alert>
              <Card className="main-card mb-3">
                <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                <CardBody>
                  <Form onSubmit={ e => e.preventDefault()}>
                    <FormGroup>
                      <Label>{ 'Email' }</Label>
                      <Input
                        type={ 'text' }
                        value={ this.props.searchParams[0].params }
                        onChange={ e => {
                          let tmp = _.cloneDeep( this.props.searchParams )
                          tmp[ 0 ][ 'param' ] = e.target.value
                          return this.props.onChangeSendersHOC( 'searchParams', tmp )
                        }} />
                    </FormGroup>
                    <div className="d-flex">
                      <Button
                        color="primary"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          let tmpSearchParams = {
                            is_paginated: true,
                            page: 1,
                            email: this.props.searchParams[ 0 ].param,
                          }
                          let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                          this.props.getSenders( tmpSearchString )
                        }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      <Button
                        color="danger"
                        style={{ marginLeft: '10px' }}
                        onClick={() => {
                          let tmpSearchParams = {
                            is_paginated: true,
                            page: 1
                          }
                          let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                          this.props.getSenders( tmpSearchString )
                        }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    NoDataComponent={ () => <span></span> }
                    data={ this.props.senders.data }
                    columns={[
                      {
                        Header: 'Email',
                        accessor: 'email'
                      },
                      {
                        Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                        accessor: 'id',
                        Cell: ( row ) => (
                          <div className="d-flex flex-row align-items-center justify-content-center w-100">
                            {
                              this.handleSecurityModulesUpdate() && (
                                <>
                                  <Button
                                    id={ `Sender-${ row.original.id }-Update` }
                                    className="mr-2 btn-icon btn-icon-only"
                                    color="primary"
                                    onClick={() => {
                                      this.props.onChangeSendersHOC( 'showEditSenderModal', true )
                                      this.props.onChangeSendersHOC( 'errorMessage', {} )
                                      this.props.getSelectedSender( row.original.id )
                                    }}
                                    disabled={ !this.state.subscribedPackage }>
                                    <i className="pe-7s-note"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={ `Sender-${ row.original.id }-Update` }
                                    placement="top">
                                    { Lang[ 'UPDATE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                  </UncontrolledTooltip>
                                </>
                              )
                            }
                          </div>
                        )
                      }
                    ]}
                    showPagination={ false }
                    defaultPageSize={ 10 } />
                  {
                    this.props.senders.data && this.props.senders.data.length > 0 && (
                      <ArkPagination
                        meta={ this.props.senders.meta }
                        onChangePage={ page => {
                          let tmpSearchParams = {
                            email: this.props.searchParams[ 0 ].param,
                            page: page,
                            is_paginated: true
                          }
                          let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                          this.props.getSenders( tmpSearchString )
                        }} />
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {
          this.props.showDeleteSenderConfirmation && (
            <DeleteConfirmation
               handleModalClose={ () => this.props.onChangeSendersHOC( 'showDeleteSenderConfirmation', false ) }
               deleteOpen={ this.props.showDeleteSenderConfirmation }
               confirmAction={ () => {
                 this.props.deleteSender()
                 this.props.onChangeSendersHOC( 'showDeleteSenderConfirmation', false )
               }}
               content={ Lang[ 'ARE_YOU_SURE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
               lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
          )
        }
        { this.props.showCreateSenders && this.renderCreateModal() }
        { this.props.showEditSenderModal && this.renderEditModal() }
        { this.props.onLoadSenders && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithSenders
)( SendersListings )
