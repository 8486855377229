import React from 'react'
import { Route } from 'react-router-dom'

import Locations from './Locations'

const LocalListing = () => {
  return(
    <Route exact path="/dashboard/local-listing/locations" component={ Locations } />
  )
}

export default LocalListing