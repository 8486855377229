import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Get, Post, Put } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      allNews: [],
      showAllNews: false,
      selectedNews: {},
      showInfo: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeNewsHOC = ( key, value ) => this.setState({ [key]: value })

    getAllNews = () => Get(
      `/api/v1/admin_panel/news`,
      this.getAllNewsSuccess,
      this.getAllNewsError,
      this.load
    )
    getAllNewsSuccess = payload => {
      let tmp = []
      payload.data && payload.data.length > 0 && payload.data.map( item => {
        if( item.has_expiry_date ) {
          if( item.publish_from <= Moment().endOf( 'day' ).add( Moment().utcOffset(), 'minutes' ).utc().format() && item.publish_to >= Moment().startOf( 'day' ).add( Moment().utcOffset(), 'minutes' ).utc().format() ) {
            tmp.push( item )
          }
        } else {
          if( item.publish_from <= Moment().endOf( 'day' ).add( Moment().utcOffset(), 'minutes' ).utc().format() ) {
            tmp.push( item )
          }
        }
      })
      this.setState({ allNews: tmp })
    }
    getAllNewsError = error => {}

    getSelectedNews = id => Get(
      `/api/v1/admin_panel/news/${ id }`,
      this.getSelectedNewsSuccess,
      this.getSelectedNewsError,
      this.load
    )
    getSelectedNewsSuccess = payload => this.setState({ selectedNews: payload })
    getSelectedNewsError = error => {}

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            getAllNews={ this.getAllNews }
            allNews={ this.state.allNews }
            onLoadAllNews={ this.state.loading }
            showAllNews={ this.state.showAllNews }
            onChangeNewsHOC={ this.onChangeNewsHOC }
            selectedNews={ this.state.selectedNews }
            getSelectedNews={ this.getSelectedNews }
            showInfo={ this.state.showInfo } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
