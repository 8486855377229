import React, { useState, useEffect } from 'react'
import { 
  Row, Col,
  Label, Input, 
  Card, CardBody, CardHeader
} from 'reactstrap'
import { FaCaretRight } from 'react-icons/fa'

import Lang from 'Lang/General'

const Transitions = props => {
  const [ incomingTraffics, setIncomingTraffics ] = useState([])
  const [ incomingTrafficsDetails, setIncomingTrafficsDetails ] = useState([])
  const [ outgoingTraffics, setOutgoingTraffics ] = useState([])
  const [ outgoingTrafficsDetails, setOutgoingTrafficsDetails ] = useState([])

  useEffect( () => {
    let tmpTraffic = []
    let tmpOutgoingTraffic = []
    let tmpTotalPageVisits = props.matomoTransitions?.pageMetrics?.pageviews || 0

    if ( props.matomoTransitions?.previousPages ) {
      let tmpSiteDomain = props.siteDomain.replace( 'https://', '' ).replace( '/', '' ).replace( 'www.', '' )
      let totalTraffics = props.matomoTransitions.previousPages.reduce(
        ( res, traffic ) => res + traffic.referrals,
        0
      )

      tmpTraffic.push({
        label: Lang[ 'FROM_INTERNAL_SITES' ][ props.data.languageReducer.userSettings.admin_panel_language ],
        visits: totalTraffics,
        percentage: parseFloat( totalTraffics / tmpTotalPageVisits * 100 ).toFixed( 2 ),
        details: props.matomoTransitions.previousPages.map( internalTraffic => ({
          label: internalTraffic.label.replace( tmpSiteDomain, '' ),
          percentage: parseFloat( internalTraffic.referrals/totalTraffics * 100 ).toFixed( 2 )
        }))
      })
    }
    if ( props.matomoTransitions?.referrers ) {
      props.matomoTransitions.referrers.map( referrer => {
        tmpTraffic.push({
          ...referrer,
          percentage: parseFloat( referrer.visits / tmpTotalPageVisits * 100 ).toFixed( 2 ),
          details: referrer?.details.map( referrerDetail => ({
            ...referrerDetail,
            percentage: parseFloat( parseInt( referrerDetail.referrals )/referrer.visits * 100 ).toFixed( 2 )
          }))
        })
      })
    }
    if ( props.matomoTransitions?.previousSiteSearches ) {
      let totalTraffics = props.matomoTransitions.previousSiteSearches.reduce(
        ( res, traffic ) => res + traffic.referrals,
        0
      )

      tmpTraffic.push({
        label: Lang[ 'INTERNAL_SEARCHES' ][ props.data.languageReducer.userSettings.admin_panel_language ],
        visits: totalTraffics,
        percentage: parseFloat( totalTraffics / tmpTotalPageVisits * 100 ).toFixed( 2 ),
        details: props.matomoTransitions.previousSiteSearches.map( siteSearch => ({
          ...siteSearch,
          percentage: parseFloat( siteSearch.referrals/totalTraffics * 100 ).toFixed( 2 )
        }))
      })
    }

    if ( props.matomoTransitions?.followingPages ) {
      let tmpSiteDomain = props.siteDomain.replace( 'https://', '' ).replace( '/', '' ).replace( 'www.', '' )
      let totalTraffics = props.matomoTransitions.followingPages.reduce(
        ( res, traffic ) => res + traffic.referrals,
        0
      )

      tmpOutgoingTraffic.push({
        label: Lang[ 'TO_INTERNAL_SITES' ][ props.data.languageReducer.userSettings.admin_panel_language ],
        exits: totalTraffics,
        percentage: parseFloat( totalTraffics / tmpTotalPageVisits * 100 ).toFixed( 2 ),
        details: props.matomoTransitions.followingPages.map( outgoingTraffic => ({
          label: outgoingTraffic.label.replace( tmpSiteDomain, '' ),
          percentage: parseFloat( outgoingTraffic.referrals/totalTraffics * 100 ).toFixed( 2 )
        }))
      })
    }
    if ( props.matomoTransitions?.outlinks ) {
      let totalOutlinks = props.matomoTransitions.outlinks.reduce(
        ( res, traffic ) => res + traffic.referrals,
        0
      )
      tmpOutgoingTraffic.push({
        label: Lang[ 'OUTLINKS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
        exits: totalOutlinks,
        percentage: parseFloat( totalOutlinks / tmpTotalPageVisits * 100 ).toFixed( 2 ),
        details: props.matomoTransitions.outlinks.map( outlink => ({
          ...outlink,
          percentage: parseFloat( outlink.referrals/totalOutlinks * 100 ).toFixed( 2 )
        }))
      })
    }
    if ( props.matomoTransitions?.followingSiteSearches ) {
      let totalTraffics = props.matomoTransitions.followingSiteSearches.reduce(
        ( res, traffic ) => res + traffic.referrals,
        0
      )

      tmpOutgoingTraffic.push({
        label: Lang[ 'INTERNAL_SEARCHES' ][ props.data.languageReducer.userSettings.admin_panel_language ],
        exits: totalTraffics,
        percentage: parseFloat( totalTraffics / tmpTotalPageVisits * 100 ).toFixed( 2 ),
        details: props.matomoTransitions.followingSiteSearches.map( siteSearch => ({
          ...siteSearch,
          percentage: parseFloat( siteSearch.referrals/totalTraffics * 100 ).toFixed( 2 )
        }))
      })
    }
    if ( props.matomoTransitions?.downloads ) {
      let totalTraffics = props.matomoTransitions.downloads.reduce(
        ( res, traffic ) => res + traffic.referrals,
        0
      )

      tmpOutgoingTraffic.push({
        label: Lang[ 'DOWNLOADS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
        exits: totalTraffics,
        percentage: parseFloat( totalTraffics / tmpTotalPageVisits * 100 ).toFixed( 2 ),
        details: props.matomoTransitions.downloads.map( download => ({
          ...download,
          percentage: parseFloat( download.referrals/totalTraffics * 100 ).toFixed( 2 )
        }))
      })
    }
    if ( props.matomoTransitions?.pageMetrics?.exits ) {
      tmpOutgoingTraffic.push({
        label: Lang[ 'EXITS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
        exits: props.matomoTransitions.pageMetrics.exits,
        percentage: parseFloat( props.matomoTransitions.pageMetrics.exits / tmpTotalPageVisits * 100 ).toFixed( 2 ),
      })
    }

    setIncomingTraffics( tmpTraffic )
    setIncomingTrafficsDetails( tmpTraffic.filter( traffic => traffic.visits > 0 ) )
    setOutgoingTraffics( tmpOutgoingTraffic )
    setOutgoingTrafficsDetails( tmpOutgoingTraffic.filter( traffic => traffic.exits > 0 ) )
  }, [ props.matomoTransitions ] )

  return(
    <>
      <style>
        {`
            .caret-down {
              @media( max-width: 991px ) {
                transform: rotate(90deg);
              } 
            }
        `}
      </style>
      <Card className="mb-4" >
        <CardBody>
          <div className="d-flex flex-row flex-nowrap align-items-center" >
            <Label 
              className='mb-0 mr-2'
              style={{ whiteSpace: 'nowrap' }} >
              { Lang[ 'SITE_URL' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            </Label>
            <Input
              type="select"
              value={ props.matomoSearchParams.url }
              onChange={ e => props.getMatomoStats({ 
                ...props.matomoSearchParams,  
                url: e.target.value
              }) }>
              { 
                props.matomoVisitsByPage.map( page => (
                  <option value={ page.url } >{ page.label === '/index' ? '/' : page.label }</option>
                ))
              }
            </Input>
          </div>
        </CardBody>
      </Card>
      <Row>
        <Col sm={ 12 } lg={ 4 } >
          <Card>
            <CardBody>
              {
                incomingTrafficsDetails.map( ( traffic, index ) => (
                  <div >
                    <Label 
                      style={{ 
                        fontWeight: "500", 
                        fontSize: "150%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "1.5rem"
                      }}>
                      <span>
                        <FaCaretRight style={{ marginRight: "5px" }} />{ traffic.label }
                      </span>
                      <span>{ traffic.percentage }%</span>
                    </Label>
                    {
                      traffic?.details.map( trafficDetail => (
                        <div className="d-flex flex-row flex-nowrap mb-2" >
                          <span style={{ display: 'flex', flex: '1', wordBreak: 'break-all', paddingRight: "1rem" }} >
                            { trafficDetail.label }
                          </span>
                          <span>{ trafficDetail.percentage }%</span>
                        </div>
                      ))
                    }
                    { index !== incomingTrafficsDetails.length -1 && <hr className="my-4" /> }
                  </div>
                ))
              }
            </CardBody>
          </Card>
        </Col>
        <Col sm={ 12 } lg={ 4 } >
          <Row>
            <Col sm={ 12 } lg={ 2 } >
              <div className="d-flex flex-row justify-content-center align-items-center h-100" >
                <FaCaretRight style={{ fontSize: "700%", color: "#545cd8" }} className="caret-down" />
              </div>
            </Col>
            <Col sm={ 12 } lg={ 8 } >
              <Card>
                <CardHeader style={{ textTransform: "none" }} >
                  <Label className="text-center w-100" >
                    { props.matomoSearchParams.url.replace( props.siteDomain, '' ).replace( 'https://', '' ) }
                  </Label>
                </CardHeader>
                <CardBody>
                  <Label style={{  fontWeight: "500",  fontSize: "150%", textAlign: "center", width: "100%" }}>
                    { `${ props.matomoTransitions?.pageMetrics?.pageviews } ` }
                    { Lang[ 'SITE_VISITS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                  </Label>
                  <hr className="my-4" />
                  <div className="mb-4" >
                    <Label 
                      className='mb-4'
                      style={{ 
                        fontWeight: "500", 
                        fontSize: "150%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}>
                      <FaCaretRight style={{ marginRight: "5px" }} />
                      { Lang[ 'INCOMING_TRAFFIC' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                    </Label>
                    {
                      incomingTraffics.map( traffic => (
                        <div className="d-flex flex-row flex-nowrap mb-2" >
                          <span style={{ display: 'flex', flex: '1', wordBreak: 'break-all', paddingRight: "1rem" }} >
                            { traffic.label }
                          </span>
                          <span>{ traffic.visits }</span>
                        </div>
                      ))
                    }
                  </div>
                  <div>
                    <Label 
                      className='mb-4'
                      style={{ 
                        fontWeight: "500", 
                        fontSize: "150%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}>
                      { Lang[ 'OUTGOING_TRAFFIC' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                      <FaCaretRight style={{ marginLeft: "5px" }} />
                    </Label>
                    {
                      outgoingTraffics.map( traffic => (
                        <div className="d-flex flex-row flex-nowrap mb-2" >
                          <span style={{ display: 'flex', flex: '1', wordBreak: 'break-all', paddingRight: "1rem" }} >
                            { traffic.label }
                          </span>
                          <span>{ traffic.exits }</span>
                        </div>
                      ))
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={ 12 } lg={ 2 } >
              <div className="d-flex flex-row justify-content-center align-items-center h-100" >
                <FaCaretRight style={{ fontSize: "700%", color: "#545cd8" }} className="caret-down" />
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={ 12 } lg={ 4 } >
          <Card>
            <CardBody>
              {
                outgoingTrafficsDetails.filter( traffic => traffic.exits > 0 ).map( ( traffic, index ) => (
                  <div >
                    <Label 
                      style={{ 
                        fontWeight: "500", 
                        fontSize: "150%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "1.5rem"
                      }}>
                      <span>
                        <FaCaretRight style={{ marginRight: "5px" }} />{ traffic.label }
                      </span>
                      <span>{ traffic.percentage }%</span>
                    </Label>
                    {
                      traffic.details?.map( trafficDetail => (
                        <div className="d-flex flex-row flex-nowrap mb-2" >
                          <span style={{ display: 'flex', flex: '1', wordBreak: 'break-all', paddingRight: "1rem" }} >
                            { trafficDetail.label }
                          </span>
                          <span>{ trafficDetail.percentage }%</span>
                        </div>
                      ))
                    }
                    { index !== outgoingTrafficsDetails.length -1 && <hr className="my-4" /> }
                  </div>
                ))
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Transitions