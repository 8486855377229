import React from 'react'
import {
  FormGroup, FormText,
  Label, Input, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import RenderDOM from '../RenderDOM'
import { generateFieldLabelStr } from '../utils'

const RadioButton = ({
  content,
  onChangeFormBuilderState,
  mode,
  formValues,
  onPatchFormValues,
  dom,
  findObjectAndPatch,
  isInputDisabled,
  currentLanguage,
  pageErrors,
  createSubmittedFormMedia,
  removeSubmittedFormMedia,
  uploadedMedia
}) => {

  return (
    <FormGroup 
      tag="fieldset"
      style={{ padding: content.padding, ...content.style }}>
      <Label className="ck-content" style={{ fontSize: content.fontSize, width: "100%" }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }  
        {
          content.showQuickInfo && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      {
        content.selections.map( ( item, index ) => {
          return (
            <>
              <FormGroup 
                key={ item.id }
                check
                onChange={ async e => {
                  const previousSelection = _.find(content.selections, { value: formValues[ content.dataMapIndex ][ 'value' ] })
                  await onPatchFormValues( content.dataMapIndex, 'value', e.target.value )
                  if ( item.value !== formValues[ content.dataMapIndex ][ 'value' ] ) {
                    if( 
                      item.dependentField &&
                      item.dependentField.id &&
                      formValues[ item.dependentField.dataMapIndex ][ 'isRequired' ]
                    ){
                      await onPatchFormValues( item.dependentField.dataMapIndex, 'validation', formValues[ item.dependentField.dataMapIndex ].validation )
                      await onPatchFormValues( item.dependentField.dataMapIndex, 'isRequired', true )
                      await onPatchFormValues( item.dependentField.dataMapIndex, 'isDependentFieldRequired', true )
                    }
                  }
                  if ( previousSelection && previousSelection.dependentField && previousSelection.dependentField.id ) {
                    await onPatchFormValues( previousSelection.dependentField.dataMapIndex, 'value', '' )
                    await onPatchFormValues( previousSelection.dependentField.dataMapIndex, 'isDependentFieldRequired', false )
                  }
                }}
                value={ formValues[ content.dataMapIndex ][ 'value' ] }>
                <Label check>
                  <Input 
                    type="radio" 
                    disabled={ isInputDisabled }
                    name={ content.id } 
                    value={ item.value }
                    checked={ item.value === formValues[ content.dataMapIndex ][ 'value' ] } />
                  {' '}
                  { content.type.indexOf( 'Flexible' ) !== -1 && index === content.selections.length - 1
                    ? <Input
                        type='text'
                        disabled={ isInputDisabled }
                        value={ item.inputValue? item.inputValue : '' }
                        invalid={ _.findIndex( pageErrors, { id: content.id } ) > -1 }
                        placeholder={ item.label[ currentLanguage? currentLanguage: 'en' ] }
                        readOnly= { formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1 ? false : true }
                        style={{ 
                          height: "auto",
                          border: "none",
                          borderRadius: "0",
                          padding: "0",
                          fontSize: "14px",
                          borderBottom: "1px dotted #000000" }}
                        onFocus={(e) => e.target.placeholder = ''}
                        onBlur={(e) => e.target.placeholder = item.label[ currentLanguage? currentLanguage: 'en' ]}
                        onChange={ async e => {
                          let tmpFormValues = _.cloneDeep( formValues )
                          let tmpDOM = _.cloneDeep( dom )
                          let tmpContent = _.cloneDeep( content )
                          let tmpValue = _.cloneDeep( formValues[ content.dataMapIndex ][ 'value' ] )

                          tmpValue = e.target.value.replaceAll( ' ', '-' )
                          tmpFormValues[ content.dataMapIndex ][ 'value' ] = tmpValue
                          tmpFormValues[ content.dataMapIndex ][ 'flexibleInputValue' ] = e.target.value

                          tmpContent.selections[ index ][ 'value' ] = e.target.value.replaceAll( ' ', '-' )
                          tmpContent.selections[ index ][ 'label' ][ currentLanguage ] = e.target.value
                          tmpContent.selections[ index ][ 'inputValue' ] = e.target.value

                          await findObjectAndPatch( tmpDOM, tmpContent )
                          Promise.all([
                            onChangeFormBuilderState( 'dom', tmpDOM ),
                            onChangeFormBuilderState( 'formValues', tmpFormValues )
                          ])
                        }}/>
                    : item.label[ currentLanguage? currentLanguage: 'en' ]  } 
                </Label>
              </FormGroup>
              {
                item.value === formValues[ content.dataMapIndex ][ 'value' ]
                && item.dependentField
                && item.dependentField.id 
                && (
                  <RenderDOM
                    content={ item.dependentField }
                    onChangeFormBuilderState={ onChangeFormBuilderState }
                    mode={ mode }
                    formValues={ formValues }
                    onPatchFormValues={ onPatchFormValues }
                    dom={ dom }
                    findObjectAndPatch={ findObjectAndPatch }
                    isInputDisabled={ isInputDisabled }
                    currentLanguage={ currentLanguage }
                    pageErrors={ pageErrors }
                    createSubmittedFormMedia={ createSubmittedFormMedia }
                    removeSubmittedFormMedia={ removeSubmittedFormMedia }
                    uploadedMedia={ uploadedMedia }
                  /> 
                )
              }
            </>
          )
        })
      }
      <FormText color="danger">
        { 
          (
            _.find( pageErrors, { id: content.id } ) &&
            _.find( pageErrors, { id: content.id } ).validation[ currentLanguage ]
          ) || ''
        }
      </FormText>
    </FormGroup>
  )
}

export default RadioButton