import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import { Get, Post, Put, Delete } from 'utils/axios-token'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreateRecipientGroup: false,
      loading: false,
      recipientGroup: [],
      searchParamsRG: [
        {
          label: 'Name',
          value: 'name',
          param: ''
        },
      ],
      newRecipientGroupData: {
        name: '',
        status: 'ACTIVE'
      },
      selectedRecipientGroup: {},
      showEditModal: false,
      showDeleteConfirmation: false,
      showViewRecipientGroupModal: false,
      recipientGroupForSelect: [],
      confirmEmptyGroup: false,
      showMoveRecipientModal: false,
      summary: {},
      recipientGroupWithoutPagination: []
    }

    onChangeRecipientGroupHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getRecipientGroup = ( query ) => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups?query=${ query }`,
        this.getRecipientGroupSuccess,
        this.getRecipientGroupError,
        this.load
      )
    }
    getRecipientGroupSuccess = payload => {
      let tmp = payload
      let tmpRecipientGroupForSelect = []
      payload.data.map( item => {
        tmpRecipientGroupForSelect.push({
          ...item,
          value: item.id,
          label: item.name
        })
      })
      payload.data.push({
        id: 0,
        name: 'Alle',
        status: 'ACTIVE'
      })

      this.setState({ recipientGroup: tmp, recipientGroupForSelect: tmpRecipientGroupForSelect })
    }
    getRecipientGroupError = error => this.requestError( error.toString() )

    postRecipientGroup = () => {
      Post(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups`,
        this.state.newRecipientGroupData,
        this.postRecipientGroupSuccess,
        this.postRecipientGroupError,
        this.load
      )
    }
    postRecipientGroupSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CREATE_NEWSLETTER_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ showCreateRecipientGroup: false })
      this.getRecipientGroup( convertSearchObjectToBase64( tmp ) )
    }
    postRecipientGroupError = error => {
      if( error.name ){
        this.requestError( error.name )
      } else{
      this.requestError( error )
    }
    }

    getSelectedRecipientGroup = id => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups/${ id }`,
        this.getSelectedRecipientGroupSuccess,
        this.getSelectedRecipientGroupError,
        this.load
      )
    }
    getSelectedRecipientGroupSuccess = payload => this.setState({ selectedRecipientGroup: payload })
    getSelectedRecipientGroupError = error => this.requestError( error )

    updateRecipientGroup = () => {
      Put(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups/${ this.state.selectedRecipientGroup.id }`,
        this.state.selectedRecipientGroup,
        this.updateRecipientGroupSuccess,
        this.updateRecipientGroupError,
        this.load
      )
    }
    updateRecipientGroupSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'RECEIVER_UPDATE_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ showEditModal: false })
      this.getRecipientGroup( convertSearchObjectToBase64( tmp ) )
    }
    updateRecipientGroupError = error => this.requestError( error )

    deleteRecipientGroup = () => {
      Delete(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups/${ this.state.selectedRecipientGroup.id }`,
        this.deleteRecipientGroupSuccess,
        this.deleteRecipientGroupError,
        this.load
      )
    }
    deleteRecipientGroupSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'RECEIVER_DELETE_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getRecipientGroup( convertSearchObjectToBase64( tmp ) )
      this.setState({ showMoveRecipientModal: false })
    }
    deleteRecipientGroupError = error => this.requestError( error )

    emptyRecipientFromGroup = () => Delete(
      `/api/v1/recipient_groups/${ this.state.selectedRecipientGroup.id }/recipients`,
      this.emptyRecipientFromGroupSuccess,
      this.emptyRecipientFromGroupError,
      this.load
    )
    emptyRecipientFromGroupSuccess = payload => {
      this.setState({ confirmEmptyGroup: false })
      this.requestSuccess( payload.msg )
      this.props.getRecipientLists( convertSearchObjectToBase64({ is_paginated: false, is_blacklisted: false }), this.state.selectedRecipientGroup.id )
    }
    emptyRecipientFromGroupError = error => this.requestError( error )

    removeRecipientGroupRecipient = selectedRecipientToRemove => Delete(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups/${ selectedRecipientToRemove.recipient_group_id }/recipients/${ selectedRecipientToRemove.recipient_id }`,
      this.removeRecipientGroupRecipientSuccess,
      this.removeRecipientGroupRecipientError,
      this.load
    )
    removeRecipientGroupRecipientSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'RECEIVER_DELETE_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getRecipientGroup( convertSearchObjectToBase64( tmp ) )
    }
    removeRecipientGroupRecipientError = error => this.requestError( error )

    moveRecipientToAnotherGroup = ( groupIdToRemove, targetGroupId ) => Post(
      `/api/v1/recipient_groups/${ groupIdToRemove }/move_recipients/${ targetGroupId }`,
      '',
      this.moveRecipientToAnotherGroupSuccess,
      this.moveRecipientToAnotherGroupError,
      this.load
    )
    moveRecipientToAnotherGroupSuccess = payload => {
      this.requestSuccess( Lang[ 'RECEIVER_MOVE_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ summary: payload })
      this.getRecipientGroup( convertSearchObjectToBase64({
        page: 1,
        is_paginated: true
      }) )
    }
    moveRecipientToAnotherGroupError = error => this.requestError( error )

    getRecipientGroupWithoutPagination = ( query ) => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups?query=${ query }`,
        this.getRecipientGroupWithoutPaginationSuccess,
        this.getRecipientGroupWithoutPaginationError,
        this.load
      )
    }
    getRecipientGroupWithoutPaginationSuccess = payload => this.setState({ recipientGroupWithoutPagination: payload.data })
    getRecipientGroupWithoutPaginationError = error => this.requestError( error )
    
    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          showCreateRecipientGroup={ this.state.showCreateRecipientGroup }
          onLoadRecipientGroup={ this.state.loading }
          onChangeRecipientGroupHOC={ this.onChangeRecipientGroupHOC }
          getRecipientGroup={ this.getRecipientGroup }
          recipientGroup={ this.state.recipientGroup }
          searchParamsRG={ this.state.searchParamsRG }
          newRecipientGroupData={ this.state.newRecipientGroupData }
          postRecipientGroup={ this.postRecipientGroup }
          getSelectedRecipientGroup={ this.getSelectedRecipientGroup }
          selectedRecipientGroup={ this.state.selectedRecipientGroup }
          showEditModal={ this.state.showEditModal }
          updateRecipientGroup={ this.updateRecipientGroup }
          showDeleteConfirmation={ this.state.showDeleteConfirmation }
          deleteRecipientGroup={ this.deleteRecipientGroup }
          showViewRecipientGroupModal={ this.state.showViewRecipientGroupModal }
          recipientGroupForSelect={ this.state.recipientGroupForSelect }
          emptyRecipientFromGroup={ this.emptyRecipientFromGroup }
          confirmEmptyGroup={ this.state.confirmEmptyGroup }
          removeRecipientGroupRecipient={ this.removeRecipientGroupRecipient }
          moveRecipientToAnotherGroup={ this.moveRecipientToAnotherGroup }
          showMoveRecipientModal={ this.state.showMoveRecipientModal }
          summary={ this.state.summary }
          getRecipientGroupWithoutPagination={ this.getRecipientGroupWithoutPagination }
          recipientGroupWithoutPagination={ this.state.recipientGroupWithoutPagination } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
