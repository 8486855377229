import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Put } from 'utils/axios-template'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      externalUrl: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string' 
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )
    
    onChangeExternalUrlHOC = ( key, val ) => this.setState({ [ key ]: val })

    getExternalUrl = location_id => Get(
      `/api/v1/locations/${ location_id }/leadhub_external_url`,
      this.getExternalUrlSuccess,
      this.getExternalUrlError,
      this.load
    )
    getExternalUrlSuccess = payload => {
      let tmp = _.cloneDeep( payload )
      if ( tmp.youtube_url.length === 0 ) {
        tmp.youtube_url.push({
          id: 0,
          url: ''
        })
      }
      this.setState({ externalUrl: tmp })
    }
    getExternalUrlError = error => this.requestError( error )

    updateExternalUrl = ( location_id, data ) => Put(
      `/api/v1/locations/${ location_id }/leadhub_external_url`,
      data,
      this.updateExternalUrlSuccess,
      this.updateExternalUrlError,
      this.load
    )
    updateExternalUrlSuccess = payload => this.requestSuccess( 'External URL is successfully updated' )
    updateExternalUrlError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getExternalUrl={ this.getExternalUrl }
          updateExternalUrl={ this.updateExternalUrl }
          onLoadExternalUrl={ this.state.loading }
          externalUrl={ this.state.externalUrl }
          onChangeExternalUrlHOC={ this.onChangeExternalUrlHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC