import React, { useState, useEffect } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { compose } from 'redux'
import { toast } from 'react-toastify'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import getDomainURL from 'utils/api'

import CookieContentHOC from './actions'
import CookieCategoriesHOC from './actions/categories'
import CookieContentForm from './components/cookieContentForm'
import CookieTemplates from './utils/cookieTemplates'

import Lang from 'Lang/General'

const CookieContent = props => {

  const [ selectedCategory, updateSelectedCategory ] = useState( {} )
  const [ targetingCategorySelectedId, updateTargetingCategorySelectedId ] = useState( 0 )

  useEffect( () => {
    props.getCookieCategories()
    props.getCookieContents()
  }, [] )

  useEffect( () => {
    if ( !props.showCreateCookieContent ) {
      props.onChangeCookieContentHOC( 'newCookieContent', {
        title: {
          en: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          nl: ''
        },
        description: {
          en: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          nl: ''
        },
        cookie_name: '',
        cookie_script: '',
        editor: '',
        url_publisher: '',
        running_time: '',
        position: 1,
        status: 'ACTIVE',
        center_id: 0,
        cb_category_id: 0,
      })
    }
  }, [ props.showCreateCookieContent ] )

  useEffect( () => {
    if ( !props.showUpdateCookieContent ) {
      props.onChangeCookieContentHOC( 'selectedCookieContent', {} )
    }
  }, [ props.showUpdateCookieContent ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_delete
    }
  }

  const renderCreateCookieContentForm = () => (
    <Modal isOpen={ props.showCreateCookieContent } size='xl' >
      <ModalHeader toggle={ () => props.onChangeCookieContentHOC( 'showCreateCookieContent', false ) } >
        { Lang[ 'CREATE_COOKIE_CONTENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        { !_.isEmpty( selectedCategory ) && ` ( ${ selectedCategory.title[ props.data.languageReducer.userSettings.admin_panel_language ] } )` }</ModalHeader>
      <ModalBody>
        <CookieContentForm
          currentCookieContent={ props.newCookieContent }
          cookieContentType={ 'newCookieContent' }
          onChangeCookieContentHOC={ props.onChangeCookieContentHOC }
          selectedLanguage={ props.data.languageReducer.userSettings.admin_panel_language } />
        { props.onLoadCookieContentHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp =  _.cloneDeep( props.newCookieContent )
            tmp.cb_category_id = selectedCategory.id
            
            if ( 
              _.findIndex( 
                props.cookieContents.filter( content => content.cb_category_id === selectedCategory.id ), 
                { position: props.newCookieContent.position } ) > -1
            ) {
              toast.error( Lang[ 'PLEASE_HAVE_A_DIFFERENT_POSITION_VALUE' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
            } else if ( 
              _.isEmpty( _.reduce( Object.values( props.newCookieContent.title ), ( result, current ) => ( result + current ) ) ) ||
              _.isEmpty( _.reduce( Object.values( props.newCookieContent.description ), ( result, current ) => ( result + current ) ) )
            ) {
              toast.error( Lang[ 'THE_CONTENT_IS_NOT_AVAILABLE' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
            } else {
              props.createCookieContent( tmp )
            } 
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateCookieContentForm = () => (
    <Modal isOpen={ props.showUpdateCookieContent } size='xl' >
      <ModalHeader toggle={ () => props.onChangeCookieContentHOC( 'showUpdateCookieContent', false ) } >
        { Lang[ 'UPDATE_COOKIE_CONTENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        { !_.isEmpty( selectedCategory ) && ` ( ${ selectedCategory.title[ props.data.languageReducer.userSettings.admin_panel_language ] } )` }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedCookieContent ) && <CookieContentForm
          currentCookieContent={ props.selectedCookieContent }
          cookieContentType={ 'selectedCookieContent' }
          onChangeCookieContentHOC={ props.onChangeCookieContentHOC }
          selectedCategory={ selectedCategory }
          selectedLanguage={ props.data.languageReducer.userSettings.admin_panel_language } />
        }
        { props.onLoadCookieContentHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            if ( 
              _.findIndex( 
                props.cookieContents.filter( content => content.cb_category_id === selectedCategory.id && content.id !== props.selectedCookieContent.id ), 
                { position: props.selectedCookieContent.position } ) > -1
            ) {
              toast.error( Lang[ 'PLEASE_HAVE_A_DIFFERENT_POSITION_VALUE' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
            } else if ( 
              _.isEmpty( _.reduce( Object.values( props.selectedCookieContent.title ), ( result, current ) => ( result + current ) ) ) ||
              _.isEmpty( _.reduce( Object.values( props.selectedCookieContent.description ), ( result, current ) => ( result + current ) ) )
            ) {
              toast.error( Lang[ 'THE_CONTENT_IS_NOT_AVAILABLE' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
            } else {
              props.updateCookieContent( props.selectedCookieContent )
            } 
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'COOKIE_CONTENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-plus icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          <Row>
            <Col md={ 12 }>
              {
                props.cookieCategories.length > 0 && _.orderBy( props.cookieCategories, [ 'position' ], [ 'asc' ] ).map( category => (
                  <Card className='main-card mb-3' >
                    <CardHeader 
                      className='d-flex justify-content-between'
                      style={{ padding: "3rem 2rem" }} >
                      <span>{ category.title[ props.data.languageReducer.userSettings.admin_panel_language ] }</span>
                      {
                        handleSecurityModulesCreate() && (
                          category.cookie_type === 'TARGETING' ? (
                            <Button
                              color="primary"
                              className='pl-2 pr-1'
                              style={{ zIndex: "10" }}
                              onBlur={ () => setTimeout( () => updateTargetingCategorySelectedId( 0 ), 50 ) }
                              onClick={ () => updateTargetingCategorySelectedId( category.id ) }>
                              <div className='d-flex flex-row flex-nowrap align-items-center' >
                                <span 
                                  className='pr-2'
                                  style={{ borderRight: "1px solid #fff" }}>
                                  { Lang[ 'CREATE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</span>
                                <i 
                                  className="pe-7s-angle-down"
                                  style={{ fontSize: "20px", fontWeight: "800" }}/>
                              </div>
                              {
                                (
                                  targetingCategorySelectedId === category.id && <div
                                    className='d-flex flex-column'
                                    style={{ 
                                      position: "absolute", 
                                      top: "120%", 
                                      right: "0", 
                                      border: "1px solid #e9ecef", 
                                      borderRadius: ".25rem",
                                      whiteSpace: "nowrap" }}>
                                    {
                                      _.map( CookieTemplates, ( value, key ) => 
                                      <Button
                                          style={{ 
                                            outline: "none", 
                                            background: "#fff", 
                                            color: "#000", 
                                            border: "0",
                                            borderBottom: "1px solid #e9ecef",
                                            borderRadius: "0"
                                          }}
                                          onClick={ () => {
                                            updateSelectedCategory( category )
                                            let tmpCookie = _.cloneDeep( value )
                                            let tmpCategoryCookieList = props.cookieContents.filter( content => content.cb_category_id === category.id )
                                            tmpCookie.position = _.maxBy( tmpCategoryCookieList, 'position' ) ? _.maxBy( tmpCategoryCookieList, 'position' ).position + 1 : 1
                                            if( key === 'MATOMO_STATISTICS' ){
                                              let tmpHost = getDomainURL( '' ).split( '.' )[ 1 ]
                                              if( tmpHost !== 'aciso-suite' ){
                                                tmpCookie.cookie_script = tmpCookie.cookie_script.replaceAll( 'aciso-suite', tmpHost )
                                                tmpCookie.editor = tmpCookie.editor.replaceAll( 'aciso-suite', tmpHost )
                                                tmpCookie.url_publisher = tmpCookie.url_publisher.replaceAll( 'aciso-suite', tmpHost )
                                              }
                                            }
                                            Promise.all([
                                              props.onChangeCookieContentHOC( 'newCookieContent', tmpCookie ),
                                              props.onChangeCookieContentHOC( 'showCreateCookieContent', true )
                                            ])
                                          }}>
                                          { Lang[ key ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                                        </Button>
                                      )
                                    }
                                  </div>
                                )
                              }
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              onClick={ () => {
                                updateSelectedCategory( category )
                                props.onChangeCookieContentHOC( 'showCreateCookieContent', true )
                              }}>
                              { Lang[ 'CREATE_COOKIE_CONTENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                            </Button>
                          )
                        )
                      }
                    </CardHeader>
                    <CardBody style={{ padding: "2rem" }} >
                      <ReactTable
                        defaultPageSize={ 10 }
                        showPagination={ true }
                        NoDataComponent={ () => <span></span> }
                        data={ props.cookieContents.filter( content => content.cb_category_id === category.id ) }
                        columns={[
                          {
                            Header: Lang[ 'TITLE' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                            accessor: 'title',
                            Cell: row => row.original.title[ props.data.languageReducer.userSettings.admin_panel_language ]
                          },
                          {
                            Header: Lang[ 'POSITION' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                            accessor: 'position'
                          },
                          {
                            Header: Lang[ 'STATUS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                            accessor: 'status'
                          },
                          {
                            Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                            accessor: 'id',
                            style: {
                              "justify-content": "center"
                            },
                            Cell: row => (
                              <>
                                {
                                  handleSecurityModulesUpdate() && (
                                    <Button
                                      className="mb-2 mr-2 mt-2 btn-icon btn-icon-only"
                                      color='primary'
                                      onClick={ () => {
                                        updateSelectedCategory( category )
                                        props.getSelectedCookieContent( row.original.id )
                                      }}>
                                      <i className="pe-7s-pen btn-icon-wrapper" />
                                    </Button>
                                  )
                                }
                                {
                                  handleSecurityModulesDelete() && (
                                    <Button
                                      className="mb-2 mr-2 mt-2 btn-icon btn-icon-only"
                                      color='danger'
                                      onClick={ () => {
                                        Promise.all([
                                          props.onChangeCookieContentHOC( 'toRemoveID', row.original.id )
                                        ]).then(
                                          props.onChangeCookieContentHOC( 'showPromptModal', true )
                                        )
                                      }}>
                                      <i className="pe-7s-close btn-icon-wrapper" />
                                    </Button>
                                  )
                                }
                              </>
                            )
                          }
                        ]}
                      />
                    </CardBody>
                  </Card>
                ))
              }
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
      { renderCreateCookieContentForm() }
      { renderUpdateCookieContentForm() } 
      {
        props.showPromptModal && (
          <PromptModal
            showPromptModal={ props.showPromptModal }
            content={ Lang[ 'DELETE_CONFIRMATION_COOKIE_CONTENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            lang={ props.data.languageReducer.userSettings.admin_panel_language }
            onClickNo={() => {
              props.onChangeCookieContentHOC( 'showPromptModal', false )
            }}
            onClickYes={() => {
              props.removeCookieContent( props.toRemoveID )
            }} />
        )
      }
      { props.onLoadCookieContentHOC && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  CookieContentHOC,
  CookieCategoriesHOC
)( CookieContent )
