import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import el from "date-fns/locale/el"
import de from "date-fns/locale/de"
import nl from "date-fns/locale/nl"
import it from "date-fns/locale/it"

import "react-datepicker/dist/react-datepicker.css"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const EditDuration = ({
  isOneTimer,
  selectedBookedCampaign,
  onChangeCampaignHOC,
  showEditDurationModal,
  updateDuration,
  lang,
  onLoadOLSCampaigns
}) => {
  const [ bookableMonths, setBookableMonths ] = useState( [] )

  useEffect( () => {
    registerLocale( "el", el )
    registerLocale( "de", de )
    registerLocale( "nl", nl )
    registerLocale( "it", it )
    Moment.locale( 'en' )
  }, [] )

  useEffect( () => {
    selectedBookedCampaign.bookable_months && setBookableMonths(
      selectedBookedCampaign.bookable_months.map( month => Moment( month, 'MMMM' ).month() )
    )
  }, [ selectedBookedCampaign.bookable_months ] )

  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( selectedBookedCampaign )
    tmp[ key ] = value
    if( key === 'start_date' ){
      tmp.end_date = ( new Date( value ) ).setDate( value.getDate() + 7 )
    }
    return onChangeCampaignHOC( 'selectedBookedCampaign', tmp )
  }

  return (
    <Modal
      isOpen={ showEditDurationModal }
      size={ 'md' }
      toggle={ () => onChangeCampaignHOC( 'showEditDurationModal', false ) }>
      <ModalHeader toggle={ () => onChangeCampaignHOC( 'showEditDurationModal', false ) }>{ Lang[ 'EDIT_DURATION' ][ lang ] }</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{ Lang[ 'START_ON' ][ lang ] }</Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="form-control"
              selected={ selectedBookedCampaign.start_date }
              popperModifiers={{
                flip: { behavior: [ 'bottom' ] },
                preventOverflow: { enabled: false },
                hide: { enabled: false }
              }}
              minDate={ new Date() }
              filterDate={ date => bookableMonths.length > 0
                ? bookableMonths.indexOf( date.getMonth() ) > -1
                : true
              }
              onChange={ e => onChangeData(  'start_date', e ) }
            />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'END_DATE' ][ lang ] }</Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="form-control"
              selected={ selectedBookedCampaign.end_date }
              popperModifiers={{
                flip: { behavior: [ 'bottom' ] },
                preventOverflow: { enabled: false },
                hide: { enabled: false }
              }}
              minDate={ 
                selectedBookedCampaign.start_date && 
                ( new Date( selectedBookedCampaign.start_date ) ).setDate( selectedBookedCampaign.start_date.getDate() + 1 ) 
              }
              onChange={ e => onChangeData( 'end_date', e ) }
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => updateDuration( selectedBookedCampaign.id, isOneTimer ) }>{ Lang[ 'SUBMIT' ][ lang ] }</Button>
      </ModalFooter>
      { onLoadOLSCampaigns && <LoadingOverlay/> }
    </Modal>
  )
}

export default EditDuration
