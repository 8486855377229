import React from 'react'
import { 
  Modal, ModalBody, ModalHeader, ModalFooter,
  Button, UncontrolledTooltip
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import ArkPagination from 'components/Pagination'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const Archived = props => {
  return(
    <Modal size='lg' isOpen={ props.showArchivedSiteMailings } >
      <ModalHeader toggle={ () => props.onChangeMailingListingHOC( 'showArchivedSiteMailings', false ) } >
        { Lang[ 'ARCHIVED_MAILINGS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
      </ModalHeader>
      <ModalBody>
        <ReactTable
          NoDataComponent={ () => <span></span> }
          data={ props.siteMailings.data }
          columns={[
            {
              Header: Lang[ 'TITLE' ][ props.data.languageReducer.userSettings.admin_panel_language ],
              accessor: 'title'
            },
            {
              Header: Lang[ 'STATUS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
              accessor: 'status',
              Cell: ({ value }) => Lang[ value ][ props.data.languageReducer.userSettings.admin_panel_language ],
            },
            {
              Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
              accessor: "id",
              Cell: ({ value }) => (
                <>
                  <Button
                    id={ `Site-mailing-${ value }-archive` }
                    className='mb-2 mr-2 btn-icon btn-icon-only'
                    color='warning'
                    onClick={ () => props.getSelectedSiteMailing( value, true ) }>
                    <i className='pe-7s-notebook'/>
                  </Button>
                  <UncontrolledTooltip
                    target={ `Site-mailing-${ value }-archive` }
                    placement='top'>{ Lang[ 'UNARCHIVE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</UncontrolledTooltip>
                </>
              )
            }
          ]}
          showPagination={ false }
          defaultPageSize={ 10 } />
          {
            props.siteMailings.data && props.siteMailings.data.length > 0 && (
              <ArkPagination
                meta={ props.siteMailings.meta }
                onChangePage={ page => {
                  let tmp = {
                    page: page,
                    is_paginated: true
                  }
                  let tmpFilter = {
                    status: 'ARCHIVED'
                  }
                  props.siteMailingSearchParams.map( searchItem => {
                    if( !_.isEmpty( searchItem.param ) && searchItem.value !== 'recipient_groups' ){
                      tmpFilter = {
                        ...tmpFilter,
                        [searchItem.value]: searchItem.value === 'title' ? { $like: `%${ searchItem.param }%` } : searchItem.param
                      }
                    }
                  })

                  let tmpString = convertObjToBase64( !_.isEmpty( tmpFilter ) ? { ...tmp, filter: tmpFilter } : tmp )
                  props.getAllSiteMailing( tmpString )
                }} />
            )
          }
        { props.onLoadSiteMailings && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.onChangeMailingListingHOC( 'showArchivedSiteMailings', false ) } >
          { Lang[ 'CLOSE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Archived