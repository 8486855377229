import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-token'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = { 
      loading: false,
      selectedUser: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast( item.replaceAll( '_', ' ' ) ) )

    getSelectedUser = id => Get(
      `/api/v1/users/${ id }`,
      this.getSelectedUserSuccess,
      this.getSelectedUserError,
      this.load
    )
    getSelectedUserSuccess = payload => {
      let tmp = _.cloneDeep( payload )
      if ( payload.role === 'OLS Team' || payload.role === 'Management Admin' ){
        tmp.centers = _.cloneDeep( this.props.data.profileReducer.profile.centers )
      }
      this.setState({ selectedUser: tmp })
    }
    getSelectedUserError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadUsersHOC={ this.state.loading }
          selectedUser={ this.state.selectedUser }
          getSelectedUser={ this.getSelectedUser } />
      )
    }
  }

  return WithHOC
}

export default HOC