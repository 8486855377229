import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-token'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      allProducts: [],
      showAllProducts: false,
      selectedProduct: {},
      showInfo: false,
      showBookConfirmation: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeProductsHOC = ( key, value ) => this.setState({ [key]: value })

    getAllProducts = () => Get(
      `/api/v1/admin_panel/products`,
      this.getAllProductsSuccess,
      this.getAllProductsError,
      this.load
    )
    getAllProductsSuccess = payload => {
      let tmp = []
      payload.data.map( item => {
        if( _.find( this.props.requestedProduct, { product_id: item.id, center_id: !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) && this.props.data.selectCenterReducer.selectedCenter.center.id }) ){
          tmp.push({
            ...item,
            is_requested: true
          })
        } else {
          tmp.push({ ...item })
        }
      })
      this.setState({ allProducts: tmp })
    }
    getAllProductsError = error => this.requestError( error )

    getSelectedProduct = ( id, flag ) => Get(
      `/api/v1/admin_panel/products/${ id }`,
      payload => this.getSelectedProductSuccess( payload, flag ),
      this.getSelectedProductError,
      this.load
    )
    getSelectedProductSuccess = ( payload, isBooking ) => {
      let tmp = {}
      if( _.find( this.props.requestedProduct, { product_id: payload.id, center_id: !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) && this.props.data.selectCenterReducer.selectedCenter.center.id }) ){
        tmp = {
          ...payload,
          is_requested: true
        }
      } else {
        tmp = payload
      }
      this.setState({ selectedProduct: tmp })
      if ( isBooking ) {
        this.setState({ showBookConfirmation: true })
      } else {
        this.setState({ showInfo: true })
      }
    }
    getSelectedProductError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            getAllProducts={ this.getAllProducts }
            allProducts={ this.state.allProducts }
            onLoadProducts={ this.state.loading }
            showAllProducts={ this.state.showAllProducts }
            onChangeProductsHOC={ this.onChangeProductsHOC }
            selectedProduct={ this.state.selectedProduct }
            getSelectedProduct={ this.getSelectedProduct }
            showInfo={ this.state.showInfo }
            showBookConfirmation={ this.state.showBookConfirmation } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
