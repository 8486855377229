import React from 'react'
import { Button } from 'reactstrap'
import _ from 'lodash'

const LanguageSelector = ({
  currentLang,
  updateLang
}) => {

  const langList = [ 'en', 'de', 'nl', 'it' ]

  return(
    <div 
      style={{ 
        padding: "0 15px",
        width: "max-content" }} >
      {
        langList.map( lang => {
          return (
            <Button
              key={ lang }
              type='button'
              className='mr-1 mb-1'
              color={ lang === currentLang ? 'success' : 'primary' }
              onClick={ () => updateLang( lang ) }
              onMouseDown={ e => e.preventDefault() } >
              { lang }
            </Button> 
          )
        })
      }
    </div>
  )
}

export default LanguageSelector
