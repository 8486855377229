import React, { Component } from 'react'

import { Get } from 'utils/axios-template'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      siteSettings: {}
    }

    load = param => this.setState({ loading: param })

    getSiteSettings = site_id => Get(
      `/api/v1/sites/${ site_id }/settings`,
      this.getSiteSettingsSuccess,
      this.getSiteSettingsError,
      this.load
    )
    getSiteSettingsSuccess = payload => this.setState({ siteSettings: payload })
    getSiteSettingsError = () => {}

    render = () => {
      return (
        <WrappedComponent
        { ...this.props }
          onLoadSiteSettingsHOC={ this.state.loading } 
          siteSettings={ this.state.siteSettings }
          getSiteSettings={ this.getSiteSettings }
        />
      )
    }
  }
  return WithHOC
}

export default HOC