import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Put } from 'utils/axios-template'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      categories: [],
      locationCategories: {
        categories: []
      }
    }

    onChangeCategoriesHOC = (key, value) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )


    getCategories = () => Get(
      `/api/v1/leadhub_categories`,
      this.getCategoriesSuccess,
      this.getCategoriesError,
      this.load
    )
    getCategoriesSuccess = payload => {
      let tmp = []
      tmp = payload && payload.data && payload.data.map( item => ({
        key: item.key,
        label: item.value,
        value: item.value
      }))
      this.setState({ categories: tmp })
    }
    getCategoriesError = error => this.requestError( error )

    getLocationCategories = location_id => Get(
      `/api/v1/locations/${location_id}/leadhub_categories`,
      this.getLocationCategoriesSuccess,
      this.getLocationCategoriesError,
      this.load
    )
    getLocationCategoriesSuccess = payload => {
      this.setState({ locationCategories: payload })
    }
    getLocationCategoriesError = error => this.requestError( error )

    updateLocationCategories = location_id => Put(
      `/api/v1/locations/${location_id}/leadhub_categories`,
      this.state.locationCategories,
      this.updateLocationCategoriesSuccess,
      this.updateLocationCategoriesError,
      this.load
    )
    updateLocationCategoriesSuccess = payload => {
      this.requestSuccess('Update success')
    }
    updateLocationCategoriesError = error => {
      this.requestError( error )
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getCategories={ this.getCategories }
          onLoadCategories={ this.state.loading }
          categories={ this.state.categories }
          getLocationCategories={ this.getLocationCategories }
          updateLocationCategories={ this.updateLocationCategories }
          locationCategories={ this.state.locationCategories }
          onChangeCategoriesHOC={ this.onChangeCategoriesHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC
