import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'


import { Get, Post, Put, Delete } from 'utils/axios-token'
// import { getRefreshToken } from 'actions/token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component{
    state = {
      loading: false,
      funnelTemplates: [],
      selectedFunnelTemplate: {}
    }

    onChangeFunnelTemplateHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getAllSystemFunnels = (query) => Get(
      `/api/v1/system_funnels?query=${query}`,
      this.getAllSystemFunnelsSuccess,
      this.getAllSystemFunnelsError,
      this.load
    )
    getAllSystemFunnelsSuccess = payload => {
      let tmpFunnelTemplate = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          if( Moment( new Date() ).isBetween( item.start_date, item.end_date ) ){
            tmpFunnelTemplate.push({
              ...item,
              label: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
              value: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ]
            })
          }

        })
      }
      this.setState({ funnelTemplates: tmpFunnelTemplate })
    }
    getAllSystemFunnelsError = error => this.requestError( error )

    getSelectedFunnelTemplate = id => Get(
      `/api/v1/system_funnels/${id}`,
      this.getSelectedFunnelTemplateSuccess,
      this.getSelectedFunnelTemplateError,
      this.load
    )
    getSelectedFunnelTemplateSuccess = payload => this.setState({ selectedFunnelTemplate: payload })
    getSelectedFunnelTemplateError = error => this.requestError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadScenario={ this.state.loading }
            getAllSystemFunnels={ this.getAllSystemFunnels }
            funnelTemplates={ this.state.funnelTemplates }
            getSelectedFunnelTemplate={ this.getSelectedFunnelTemplate }
            selectedFunnelTemplate={ this.state.selectedFunnelTemplate }
            onChangeFunnelTemplateHOC={ this.onChangeFunnelTemplateHOC }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
