import React from 'react'
import { 
  FormGroup, FormFeedback,
  Label, Input, UncontrolledTooltip 
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import { generateFieldLabelStr } from '../utils'

const TextArea = ({
  content,
  formValues,
  onPatchFormValues,
  isInputDisabled,
  currentLanguage,
  pageErrors
}) => {
  return (
    <FormGroup 
      id={ content.id } 
      style={{ padding: content.padding, marginBottom: 0, ...content.style }}>
      <Label className="ck-content" style={{ fontSize: content.fontSize }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }
        {
          ( content.showQuickInfo || formValues[ content.dataMapIndex ][ 'showQuickInfo' ] ) && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      <Input
        type={ 'textarea' }
        disabled={ isInputDisabled }
        value={ formValues[ content.dataMapIndex ][ 'value' ] }
        invalid={ _.findIndex( pageErrors, { id: content.id } ) > -1 }
        onChange={ e => {
          onPatchFormValues( content.dataMapIndex, 'value', e.target.value )
        }}
        rows={ content.rows }
        style={{ fontSize: content.fontSize, resize: content.resizable }} 
      />
      <FormFeedback>
        { 
          _.find( pageErrors, { id: content.id } ) &&
          _.find( pageErrors, { id: content.id } ).validation[ currentLanguage ] 
        }
      </FormFeedback>
    </FormGroup>
  )
}

export default TextArea