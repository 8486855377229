import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-template'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      figures: {
        fb_lead: 0,
        google_lead: 0,
        total_lead: 0,
        total_request_form: 0,
        total_sales: 0,
        total_sales_count: 0,
        website_lead: 0
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeHOC = ( key, value ) => this.setState({ [key]: value })

    getFigures = (center_id) => {
      if( center_id ) {
        return Get(
          `/api/v1/cockpit_dashboard?center_id=${ center_id }`,
          this.getFiguresSuccess,
          this.getFiguresError,
          this.load
        )
      } else {
        return Get(
          `/api/v1/cockpit_dashboard`,
          this.getFiguresSuccess,
          this.getFiguresError,
          this.load
        )
      }

    }
    getFiguresSuccess = payload => {
      this.setState({ figures: payload })
    }
    getFiguresError = error => {}

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            getFigures={ this.getFigures }
            onLoadFigures={ this.state.loading }
            figures={ this.state.figures } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
