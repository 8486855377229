import React, { Component } from 'react'
import Axios from 'axios'

import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      error: '',
      token: '',
      success: ''
    }

    onChangeSetPasswordHOC = ( key, val ) => this.setState({ [ key ]: val })

    setPassword = async( password, password_confirmation, password_reset_token ) => {
      this.setState({
        loading: true
      }, async () => {
        let setPassword = await Axios.put( `${ getDomainURL() }/passwords`, { 
          password,
          password_confirmation,
          password_reset_token
        }).catch( error => error )
        if( setPassword.isAxiosError ) {
          this.setState({ 
            error: setPassword.response.data.Errors && Object.values( setPassword.response.data.Errors ).length > 0 
              ? Object.values( setPassword.response.data.Errors )[ 0 ]
              : setPassword.response.data.Error
                ? setPassword.response.data.Error
                : '', 
            loading: false })
        } else {
          this.setState({ loading: false, success: setPassword.data })
        }
      })
    }

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadSetPassword={ this.state.loading }
          token={ this.state.token }
          error={ this.state.error }
          success={ this.state.success }
          setPassword={ this.setPassword }
          onChangeSetPasswordHOC={ this.onChangeSetPasswordHOC } />
      )
    }
  } 
  return WithHOC
}

export default HOC