import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Row, Col,
  Card, CardHeader, CardBody, CardFooter,
  Label, Input, Form, FormGroup,
  Button, UncontrolledTooltip
} from 'reactstrap' 
import ARKTable from 'components/Table'
import Tabs from 'react-responsive-tabs'
import { compose } from 'redux'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import _ from 'lodash'
import Moment from 'moment'

import AlertNotSubscribe from 'components/Alert'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ModalFormFill from './Fill'
import SubmittedFormView from './SubmittedFormView'
import ModalShowMessage from './Messages'

import WithDigitalOrderForms from '../Builder/actions'

import Lang from 'Lang/General'

const TabTitles = [
  Lang[ 'AVAILABLE_FORMS' ],
  Lang[ 'SUBMITTED_FORMS' ],
  Lang[ 'ARCHIVED_FORMS' ]
]

class Listings extends Component {
  state = {
    currentTab: 0,
    isMounting: false,
    subscribedPackage: true,
    tabsConfig: {
      activeTab: '1',
      showMore: true,
      transform: true,
      showInkBar: true,
      items: _.map(TabTitles, 'en'),
      selectedTabKey: 0,
      transformWidth: 400,
    }
  }

  componentDidMount = async() => {
    let tmpTabsConfig = _.cloneDeep(this.state.tabsConfig)
    tmpTabsConfig['items'] = []
    TabTitles.map( tabTitle => {
      tmpTabsConfig['items'].push( { title: tabTitle[ this.props.data.languageReducer.userSettings.admin_panel_language ] })
    })
    this.setState({ 
      tabsConfig: tmpTabsConfig, 
      currentTab: Math.max( 0, TabTitles.map(title => title['en'].toLowerCase().replace(' ', '_')).indexOf(this.props.match.params.type) ),
      isMounting: true
    }, () => {
      Promise.all([
        this.props.getUserProfile(),
        this.props.getCenterDigitalOrderForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64({ 
          page: 1, 
          is_paginated: true,
          filter: {
            status: 'ACTIVE'
          }
        })),
        this.props.getSubmittedForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64({ 
          page: 1, 
          is_paginated: true,
          filter: {
            status_id: {
              $neq: 7
            }
          }
        })),
        this.props.getArchivedSubmittedForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64({ 
          page: 1, 
          is_paginated: true,
          filter: {
            status_id: 7
          }
        })),
        this.props.getSubmittedFormStatus(),
        this.props.getDigitalOrderFormsCategories( convertSearchObjectToBase64({
          is_paginated: false
        })),
        (
          !_.isEmpty( this.props.match.params ) && this.props.match.params.id
            ? this.props.getSelectedDigitalOrderForm( this.props.match.params.id )
            : () => true
        )
      ]).then( () => {
        if( !_.isEmpty( this.props.match.params ) && this.props.match.params.id ) {
          this.props.onChangeDigitalOrderFormHOC( 'showFillSubmittedForm', true )
        }
        this.setState({ isMounting: false })
      })
    })
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Digital Order Form' })
    this.setState({ subscribedPackage: tmpIndex > -1 })
  }

  componentDidUpdate = pp => {
    if( this.props.match.params.type !== pp.match.params.type ) {
      this.setState({ currentTab: Math.max( 0, TabTitles.map(title => title['en'].toLowerCase().replace(' ', '_')).indexOf(this.props.match.params.type) ) })
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Digital Order Form' })
      return tmpModules.is_update && this.state.subscribedPackage
    }
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'DIGITAL_ORDER_FORM_LISTING_HEADING' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          subheading={ Lang[ 'DIGITAL_ORDER_FORM_LISTING_SUBHEADING' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-note2 icon-gradient bg-happy-itmeo" />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          {
            !this.state.subscribedPackage && (
              <AlertNotSubscribe { ...this.props } />
            )
          }
          <Row>
            <Col md={ 12 }>
              <Card>
                <CardBody>
                  <Tabs
                    tabsWrapperClass="body-tabs"
                    { ...this.state.tabsConfig }
                    selectedTabKey={ this.state.currentTab }
                    onChange={ val => this.setState({ currentTab: val }) } />
                  {
                    this.state.currentTab === 0 && (
                      <>
                        <Card className="mb-3">
                          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                          </CardHeader>
                          <CardBody>
                            <Form>
                              <FormGroup>
                                <Row>
                                  <Col md={ 6 }>
                                    <Label>{ Lang[ 'NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                                    <Input
                                      type="text"
                                      value={ this.props.searchParams.name }
                                      onChange={ e => {
                                        let tmp = _.cloneDeep( this.props.searchParams )
                                        tmp[ 'name' ] = e.target.value
                                        this.props.onChangeDigitalOrderFormHOC( 'searchParams', tmp )
                                      }}>
                                    </Input>
                                  </Col>
                                  <Col md={ 6 }>
                                    <Label>{ Lang[ 'CATEGORY' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                                    <Input
                                      type="select"
                                      value={ this.props.searchParams.categoryId }
                                      onChange={ e => {
                                        let tmp = _.cloneDeep( this.props.searchParams )
                                        tmp[ 'categoryId' ] = parseInt( e.target.value )
                                        this.props.onChangeDigitalOrderFormHOC( 'searchParams', tmp )
                                      }}>
                                      <option value={-1}>{ Lang[ 'PLEASE_SELECT_ONE_FROM_BELOW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</option>
                                      {
                                        this.props.digitalOrderFormsCategories.map( item => {
                                          return(
                                            <option key={ item.id } value={ item.id }>{ item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] }</option>
                                          )
                                        })
                                      }
                                    </Input>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Form>
                          </CardBody>
                          <CardFooter>
                            <Button
                              color="primary"
                              className="mr-1"
                              onClick={ () => {
                                this.props.getCenterDigitalOrderForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64({ 
                                  page: 1, 
                                  is_paginated: true,
                                  filter: {
                                    status: 'ACTIVE',
                                    name: { $like: `%${ this.props.searchParams.name }%` },
                                    ... this.props.searchParams.categoryId !== -1 && {
                                      category: this.props.searchParams.categoryId
                                    }
                                  }
                                }))
                              }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                            <Button
                              color="danger"
                              onClick={ () => {
                                this.props.getCenterDigitalOrderForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64({ 
                                  page: 1, 
                                  is_paginated: true,
                                  filter: {
                                    status: 'ACTIVE'
                                  }
                                }))
                                this.props.onChangeDigitalOrderFormHOC( 'searchParams', { ...this.props.searchParams, categoryId: -1, title: "" } )
                              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                          </CardFooter>
                        </Card>
                        <ARKTable
                          NoDataComponent={ () => <span></span> }
                          data={ this.props.centerDigitalOrderForms.data }
                          columns={[
                            {
                              Header: Lang[ 'NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              Cell: ( row ) => (
                                <span>{ row.original.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] }</span>
                              )
                            },
                            {
                              Header: Lang[ 'ADDITIONAL_INFO_DESCRIPTION' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              Cell: ( row ) => (
                                <span>{ row.original.description[ this.props.data.languageReducer.userSettings.admin_panel_language ] }</span>
                              )
                            },
                            {
                              Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              maxWidth: 150,
                              Cell: ( row ) => (
                                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                                  <Button 
                                    color={ this.handleSecurityModulesUpdate()? "primary": "secondary" }
                                    disabled={ !this.handleSecurityModulesUpdate() }
                                    id={ `select-form-${ row.original.id }-btn` }
                                    className="mr-2"
                                    onClick={() => {
                                      this.props.getSelectedDigitalOrderForm( row.original.id )
                                      this.props.onChangeDigitalOrderFormHOC( 'showFillSubmittedForm', true ) 
                                    }}>
                                    { Lang[ 'CONFIGURE_NOW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                  </Button>
                                </div>
                              )
                            }
                          ]}
                          totalPages={ this.props.digitalOrderFormsPagesList }
                          page={ this.props.centerDigitalOrderForms.meta ? this.props.centerDigitalOrderForms.meta.current_page : 1 }
                          onChangePage={ pg => {
                            this.props.getCenterDigitalOrderForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64({ 
                              page: pg, 
                              is_paginated: true,
                              filter: {
                                status: 'ACTIVE',
                                name: { $like: `%${ this.props.searchParams.name }%` },
                                ... this.props.searchParams.categoryId !== -1 && {
                                  category: this.props.searchParams.categoryId
                                }
                              } 
                            }))
                          }}
                          showPagination={ false } 
                        />
                      </>
                    )
                  }
                  {
                    this.state.currentTab === 1 && (
                      <>
                        <Card className="mb-3">
                          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                          </CardHeader>
                          <CardBody>
                            <Form>
                              <FormGroup>
                                <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                                <Input
                                  type="select"
                                  value={ this.props.searchParams.status_id }
                                  onChange={ e => {
                                    let tmp = _.cloneDeep( this.props.searchParams )
                                    tmp[ 'status_id' ] = parseInt( e.target.value )
                                    this.props.onChangeDigitalOrderFormHOC( 'searchParams', tmp )
                                  }}>
                                  <option value={0}>{ Lang[ 'PLEASE_SELECT_ONE_FROM_BELOW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</option>
                                  {
                                    this.props.submittedFormStatus.length > 0 && this.props.submittedFormStatus.map( item => {
                                      return(
                                        <option key={ item.id } value={ item.id }>{ item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] }</option>
                                      )
                                    })
                                  }
                                </Input>
                              </FormGroup>
                            </Form>
                          </CardBody>
                          <CardFooter>
                            <Button
                              color="primary"
                              className="mr-1"
                              onClick={ () => {
                                let tmp = {}
                                if( this.props.searchParams.status_id !== 0 ) {
                                  tmp = {
                                    page: 1,
                                    is_paginated: true,
                                    filter: {
                                      $or: [
                                        { status_id: this.props.searchParams.status_id }
                                      ]
                                    }
                                  }
                                }
                                this.props.getSubmittedForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64( tmp ) )
                              }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                            <Button
                              color="danger"
                              onClick={ () => {
                                let tmp = {
                                  page: 1,
                                  is_paginated: true
                                }
                                this.props.getSubmittedForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64( tmp ) )
                                this.props.onChangeDigitalOrderFormHOC( 'searchParams', { ...this.props.searchParams, status_id: 0 } )
                              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                          </CardFooter>
                        </Card>
                        <ARKTable
                          data={ this.props.submittedForms.data }
                          columns={[
                            {
                              Header: Lang[ 'PR_NUMBER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'pr_number'
                            },
                            {
                              Header: Lang[ 'NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'digital_order_form.name.'+this.props.data.languageReducer.userSettings.admin_panel_language
                            },
                            {
                              Header: Lang[ 'ADDITIONAL_INFO_DESCRIPTION' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'digital_order_form.description.'+this.props.data.languageReducer.userSettings.admin_panel_language
                            },
                            {
                              Header: Lang[ 'SUBMITTED_ON' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'created_at',
                              Cell: ({value}) => (
                                Moment( value ).format( 'DD-MM-YYYY h:mm:ss a' )
                              ),
                              className: "d-flex flex-row align-items-center justify-content-center"
                            },
                            {
                              Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'status.name.'+this.props.data.languageReducer.userSettings.admin_panel_language,
                              className: "d-flex flex-row align-items-center justify-content-center"
                            },
                            {
                              Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              maxWidth: 150,
                              Cell: ( row ) => (
                                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                                  <Button 
                                    color="primary" 
                                    id={ `form-result-${ row.original.id }-view-btn` }
                                    className="mr-2"
                                    onClick={ () => { 
                                      this.props.getSelectedSubmittedForm( 
                                        this.props.data.selectCenterReducer.selectedCenter.center.id, 
                                        row.original.id 
                                      )
                                      this.props.onChangeDigitalOrderFormHOC( 'showViewSubmittedForm', true )
                                    }}>
                                    <i className="pe-7s-look"/>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={ `form-result-${ row.original.id }-view-btn` }
                                    placement="top">
                                    { Lang[ 'DISPLAY' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                  </UncontrolledTooltip>
                                  <Button 
                                    color="primary" 
                                    id={ `form-result-${ row.original.id }-messages` }
                                    className="mr-2"
                                    onClick={ () => { 
                                      this.props.getSelectedSubmittedForm( 
                                        this.props.data.selectCenterReducer.selectedCenter.center.id, 
                                        row.original.id 
                                      )
                                      this.props.onChangeDigitalOrderFormHOC( 'showMessage', true )
                                    }}>
                                    <i className="pe-7s-chat"/>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={ `form-result-${ row.original.id }-messages` }
                                    placement="top">
                                    { Lang[ 'MESSAGES' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                  </UncontrolledTooltip>
                                </div>
                              )
                            }
                          ]} 
                          totalPages={ this.props.submittedFormsPagesList }
                          page={ this.props.submittedForms.meta ? this.props.submittedForms.meta.current_page : 1 }
                          onChangePage={ pg => {
                            let tmp = {
                              is_paginated: true,
                              page: pg,
                              filter: {
                                status_id: {
                                  $neq: 7
                                }
                              }
                            }
                            if( this.props.searchParams.status_id !== 0 ) {
                              tmp.filter.status_id = this.props.searchParams.status_id
                            }
                            this.props.getSubmittedForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64( tmp ))
                          }}
                          showPagination={ false }
                        />
                      </>
                    )
                  }
                  {
                    this.state.currentTab === 2 && (
                      <>
                        <ARKTable
                          data={ this.props.archivedSubmittedForms.data }
                          columns={[
                            {
                              Header: Lang[ 'PR_NUMBER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'pr_number'
                            },
                            {
                              Header: Lang[ 'NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'digital_order_form.name.'+this.props.data.languageReducer.userSettings.admin_panel_language
                            },
                            {
                              Header: Lang[ 'ADDITIONAL_INFO_DESCRIPTION' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'digital_order_form.description.'+this.props.data.languageReducer.userSettings.admin_panel_language
                            },
                            {
                              Header: Lang[ 'SUBMITTED_ON' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'created_at',
                              Cell: ({value}) => (
                                Moment( value ).format( 'DD-MM-YYYY h:mm:ss a' )
                              ),
                              className: "d-flex flex-row align-items-center justify-content-center"
                            },
                            {
                              Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              accessor: 'status.name.'+this.props.data.languageReducer.userSettings.admin_panel_language,
                              className: "d-flex flex-row align-items-center justify-content-center"
                            },
                            {
                              Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                              maxWidth: 150,
                              Cell: ( row ) => (
                                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                                  <Button 
                                    color="primary" 
                                    id={ `form-result-${ row.original.id }-view-btn` }
                                    className="mr-2"
                                    onClick={ () => { 
                                      this.props.getSelectedSubmittedForm( 
                                        this.props.data.selectCenterReducer.selectedCenter.center.id, 
                                        row.original.id 
                                      )
                                      this.props.onChangeDigitalOrderFormHOC( 'showViewSubmittedForm', true )
                                    }}>
                                    <i className="pe-7s-look"/>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={ `form-result-${ row.original.id }-view-btn` }
                                    placement="top">
                                    { Lang[ 'DISPLAY' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                  </UncontrolledTooltip>
                                  <Button 
                                    color="primary" 
                                    id={ `form-result-${ row.original.id }-messages` }
                                    className="mr-2"
                                    onClick={ () => { 
                                      this.props.getSelectedSubmittedForm( 
                                        this.props.data.selectCenterReducer.selectedCenter.center.id, 
                                        row.original.id 
                                      )
                                      this.props.onChangeDigitalOrderFormHOC( 'showMessage', true )
                                    }}>
                                    <i className="pe-7s-chat"/>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={ `form-result-${ row.original.id }-messages` }
                                    placement="top">
                                    { Lang[ 'MESSAGES' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                  </UncontrolledTooltip>
                                </div>
                              )
                            }
                          ]} 
                          totalPages={ this.props.submittedFormsPagesList }
                          page={ this.props.submittedForms.meta ? this.props.submittedForms.meta.current_page : 1 }
                          onChangePage={ pg => {
                            this.props.getArchivedSubmittedForms( this.props.data.selectCenterReducer.selectedCenter.center.id, convertSearchObjectToBase64({ 
                              page: pg, 
                              is_paginated: true,
                              filter: {
                                status_id: 7
                              }
                            }))
                          }}
                          showPagination={ false }
                        />
                      </>
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        { !_.isEmpty( this.props.selectedDigitalOrderForm ) && this.props.showFillSubmittedForm && <ModalFormFill { ...this.props } /> }
        { 
          !_.isEmpty( this.props.selectedSubmittedForm ) && this.props.showViewSubmittedForm && (
            <SubmittedFormView 
              { ...this.props } 
              lang={ this.props.data.languageReducer.userSettings.admin_panel_language }
            /> 
          )
        }
        { ( this.props.onLoadDigitalOrderForms || this.state.isMounting ) && <LoadingOverlay/> }
        <ModalShowMessage { ...this.props } />
      </>
    )
  }
}

export default compose(
  WithDigitalOrderForms
)( Listings )