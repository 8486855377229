import React from 'react'
import { Route } from 'react-router-dom'

import Listings from './Listings'

const DigitalOrderForm = () => {
  return (
    <>
      <Route exact path="/dashboard/digital-order-form/listings/:type" component={ Listings } />
      <Route exact path="/dashboard/digital-order-form/listings-form/:id" component={ Listings } />
      <Route exact path="/dashboard/digital-order-form/listings-form/:id/:order_id" component={ Listings } />
    </>
  )
}

export default DigitalOrderForm