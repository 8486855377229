import React, { useState } from 'react'
import { Row, Col, FormGroup, Label } from 'reactstrap'
import _ from 'lodash'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import LanguageSelector from 'components/LanguageSelector'

const MultilingualInput = ({
  label,
  value,
  onChange,
  selectedLanguage
}) => {
  const [ buttonLang, updateButtonLang ] = useState( selectedLanguage ? selectedLanguage : 'en' )

  const langList = [ 'en', 'de', 'nl', 'it' ]

  const updateContent = val => {
    let tmpValue = _.cloneDeep( value )
    tmpValue[ buttonLang ] = val
    return onChange( tmpValue )
  }

  return(
    <FormGroup>
      <Row 
        className='mb-2 d-flex align-items-center'
        style={{ justifyContent: label ? "space-between" : "flex-end" }} >
        { 
          label && <Col>
            <Label className='mb-0'>{ label }</Label> 
          </Col>
        }
        <LanguageSelector 
          currentLang = { buttonLang }
          updateLang = { updateButtonLang }
        />
      </Row>
      {
        langList.map( item => {
          if( buttonLang === item ) {
            return (
              <CKEditor
                key={ item }
                editor={ ClassicEditor }
                onReady={ editor => {
                  editor.editing.view.change(( writer ) => {
                    writer.setStyle( 'height', '400px', editor.editing.view.document.getRoot() )
                  })
                }}
                data={ value ? value[ item ] : '' }
                onChange={ ( event, editor ) => {
                  let data = editor.getData()
                  updateContent( data )
                }}
                config={{
                  heading: {
                    options: [
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                      { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'span', view: 'div', title: 'Span' }
                    ]
                  },
                  link: {
                    decorators: {
                      openInNewTab: {
                        mode: 'manual',
                        label: 'Open in a new tab',
                        attributes: {
                          target: '_blank',
                          rel: 'noopener noreferrer'
                        }
                      }
                    }
                  }
                }}
              />
            )
          }
        })
      }
    </FormGroup>
  )
}

export default MultilingualInput
