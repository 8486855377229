import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios-token'
import Lang from 'Lang/General'

const MailingsHOC = ( WrappedComponent ) => {
  class MailingsWrappedComponent extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      systemMailings: [],
      mailings: [
        {
          "mail_id": 1,
          "send_email_days": 1,
          "time_to_send": "2021-02-02T00:00:00Z"
        }
      ]
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    // ajaxError = ( error ) => {
    //   outdatedToken( error, this.props.history )
    //   this.requestError( error )
    // }

    getSiteMailing = query => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/mailings?query=${ query }`,
      this.getSiteMailingSuccess,
      this.getSiteMailingError,
      this.load
    )
    getSiteMailingSuccess = payload => {
      let tmpData = []

      payload.data.map( item => {
        tmpData.push({
          ...item,
          label: item.title,
          value: `${item.title}-${item.id}`
        })
      })

      this.setState({ systemMailings: tmpData })
    }
    getSiteMailingError = error => this.requestError(error)

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            onLoadMailings={ this.state.loading }
            mailings={ this.state.mailings }
            systemMailings={ this.state.systemMailings }
            getSiteMailing={ this.getSiteMailing } />
        </Fragment>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( MailingsWrappedComponent )
}

export default MailingsHOC
