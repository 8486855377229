import React from 'react'
import { Alert } from 'reactstrap'

import Lang from './Lang'

const NotSubscribeAlert = props => {
  return (
    <Alert color="danger">
      { Lang[ 'NOT_SUBSCRIBED' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
    </Alert>
  )
}

export default NotSubscribeAlert
