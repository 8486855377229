import React, { Component } from 'react';
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get } from 'utils/axios-token'
import { Get as GetPublic } from 'utils/axios-template'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      contentServices: [],
      selectedContentService: {
        website_preview_img: {
          url: ''
        },
        website_detailed_img: {
          url: ''
        },
        newletter_img: {
          url: ''
        },
        fb_teaser_img: {
          url: ''
        }
      },
      page: 0,
      limit: 10,
      searchParams: [
        {
          label: 'Title',
          value: 'title',
          param: ''
        },
        {
          label: 'Language',
          value: 'language',
          param: ''
        },
        {
          label: 'Categories',
          value: 'categories',
          param: []
        }
      ],
      showContentServiceDetails: false,
      centerNews: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeContentServicesHOC = ( key, value ) => this.setState({ [ key ]: value })

    getContentServices = query => Get(
      `/api/v1/contents?query=${ query }`,
      this.getContentServicesSuccess,
      this.getContentServicesError,
      this.load
    )
    getContentServicesSuccess = payload => this.setState({ 
      contentServices: [  
        {
          title: Lang[ 'TITLE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
          teaser_text: Lang[ 'TEASER_TEXT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
          content: Lang[ 'CONTENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
          newsletter_image_url: '',
          label: Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
          value: 'Please select one from below'
        },
        ...payload.data.map( item => ({
          ...item,
          title: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          teaser_text: item.teaser_text[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          content: `<p>${ Lang[ 'CONTENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</p>`,
          label: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          value: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          newsletter_image_url: item.newletter_img_url
        }))
      ]
    })
    getContentServicesError = () => {}

    getSelectedContentService = ( id ) => {
      Get(
        `/api/v1/contents/${ id }`,
        this.getSelectedContentServiceSuccess,
        this.getSelectedContentServiceError,
        this.load
      )
    }
    getSelectedContentServiceSuccess = payload => this.setState({
      selectedContentService: payload,
      showContentServiceDetails: true
    })
    getSelectedContentServiceError = error => this.requestError( error.toString() )

    getNews = () => GetPublic(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/news?query=${ convertSearchObjectToBase64({ is_paginated: false }) }`,
      this.getNewsSuccess,
      this.getNewsError,
      this.load
    )
    getNewsSuccess = payload => this.setState({ 
      centerNews: [  
        {
          title: Lang[ 'TITLE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
          teaser_text: Lang[ 'TEASER_TEXT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
          content: Lang[ 'CONTENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
          newsletter_image_url: '',
          label: Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
          value: 'Please select one from below'
        },
        ...payload.data.map( item => ({
          ...item,
          title: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          teaser_text: item.teaser_text[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          content: `<p>${ Lang[ 'CONTENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</p>`,
          label: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          value: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          newsletter_image_url: item.image_url
        }))
      ]
    })
    getNewsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          page={ this.state.page }
          limit={ this.state.limit }
          onLoadContentServices={ this.state.loading }
          contentServices={ this.state.contentServices }
          selectedContentService={ this.state.selectedContentService }
          centerNews={ this.state.centerNews }
          searchParams={ this.state.searchParams }
          showContentServiceDetails={ this.state.showContentServiceDetails }
          getContentServices={ this.getContentServices }
          getSelectedContentService={ this.getSelectedContentService }
          onChangeContentServicesHOC={ this.onChangeContentServicesHOC }
          getNews={ this.getNews } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
