import { GET_PLATFORM_CONFIG } from 'actions/type'

let initialState = {
  platformConfig: {}
}

export default ( state = initialState, action ) => {
  switch( action.type ){
    case GET_PLATFORM_CONFIG:
      return{
        ...state,
        platformConfig: action.payload
      }
    default: return state
  }
}