import React, { useEffect, useState } from 'react'
import {
  Row, Col, Button,
  Form, FormGroup, Label, Input
} from 'reactstrap'
import Slider from "react-slick"
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import TemplateContainerMain from 'Template/main'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import bg1 from 'assets/TemplateImages/media/start_ziel_03_.jpg'
import TennisBg from 'assets/images/tennis.jpg'

import { ApplyBranding } from 'utils/apply-color'
import { getUserProfile } from 'actions/profile'
import { getSelectedRole } from 'actions/role'
import { getPlatformConfig } from 'actions/platformConfig'
import { setSelectCenter } from 'actions/selectCenter'

import WithLogin from './actions'

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true
}

const SliderImageItemStyle = { 
  opacity: "0.4", 
  height: "100vh", 
  minHeight: "100vh",
  width: "100%",
  objectFit: "cover",
  objectPosition: "center" 
}

const ExternalLink = props => {
  const [ email, updateEmail ] = useState( '' )
  const [ password, updatePassword ] = useState( '' )
  useEffect(() => {
    props.getPlatformConfig()
  }, [])
  useEffect(() => {
    if( props.data.profileReducer.profile.centers && props.data.profileReducer.profile.centers.length > 0 ){
      if ( props.data.profileReducer.profile.centers.length === 1 ) {
        let tmpRoute = `/dashboard/digital-order-form/listings-form/${ props.match.params.id }`
        if( !_.isEmpty( props.match.params.order_id ) ){
          tmpRoute = tmpRoute + `/${ props.match.params.order_id }`
        }
        props.setSelectCenter({ center: props.data.profileReducer.profile.centers[ 0 ] })
        props.history.push( tmpRoute )
      } else {
        props.setSelectCenter({ center: props.data.profileReducer.profile.centers[ 0 ] })
      }
    }
  },[ props.data.profileReducer.profile.centers ])
  return (
    <>
      {
        !_.isEmpty( props.data.platformConfigReducer.platformConfig ) 
        && _.find( props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } )
        && <ApplyBranding colors={{ primary: _.find( props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } ).value }} />
      }
      <TemplateContainerMain>
        <div style={{ height: "100vh" }} >
          <Row className="no-gutters" style={{ height: "100vh" }}>
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                {
                  window.location.host.indexOf( 'tennis' ) > -1 
                    ? (
                      <Slider { ...SliderSettings }>
                        <div >
                          <img 
                            src={ TennisBg } 
                            style={ SliderImageItemStyle } />
                        </div>
                      </Slider>
                    ) : (
                      <Slider { ...SliderSettings }>
                        <div
                          className="d-flex justify-content-start align-items-start bg-plum-plate">
                          <img 
                            src={ bg1 } 
                            style={ SliderImageItemStyle } />
                        </div>
                      </Slider>
                    )
                }
              </div>
            </Col>
            <Col 
              lg="8" 
              md="12" 
              style={{ height: "100vh" }}
              className="d-flex bg-white justify-content-center align-items-center" >
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <img 
                  src={
                    !_.isEmpty( props.data.platformConfigReducer.platformConfig ) 
                    && _.find( props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ) 
                      ? _.find( props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ).value
                      : ''
                  }
                  style={{ width: '150px', marginBottom: '0' }} />
                <h4 className="mb-0">
                  <div>Welcome to Cockpit</div>
                  <span>Bitte Daten eintragen</span>
                </h4>
                <Row className="divider" />
                {
                  props.token === '' 
                    ? (
                      <>
                        <Form onSubmit={ e => e.preventDefault()}>
                          <FormGroup>
                            <Label>Email Adresse</Label>
                            <Input
                              type="email"
                              name="email"
                              value={ email }
                              onChange={ e => updateEmail( e.target.value )}
                              required />
                          </FormGroup>
                          <FormGroup>
                            <Label>Passwort</Label>
                            <Input
                              type="password"
                              name="password"
                              value={ password }
                              onChange={ e => updatePassword( e.target.value )}
                              required />
                          </FormGroup>
                        </Form>
                        <Row className="divider" />
                        <div className="d-flex align-items-center">
                          <div className="ml-auto">
                            <Button
                              color="primary"
                              onClick={() => {
                                props.auth({ email: email, password: password })
                              }}>Login</Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Form onSubmit={ e => e.preventDefault()}>
                          <FormGroup>
                            <Label>{ `Für welches Center möchten Sie den digitalen Bestellschein ausfüllen` }</Label>
                            <Input
                              type="select"
                              value={ props.data.selectCenterReducer.selectedCenter.center?.site.id || '' }
                              onChange={ e => {
                                let tmp = _.find( props.data.profileReducer.profile.centers, { id: parseInt( e.target.value, 10 ) })
                                props.setSelectCenter( tmp ? { center: tmp } : {} )
                              }}>
                              <option value='' ></option>
                              {
                                props.data.profileReducer.profile.centers && props.data.profileReducer.profile.centers.map( item => {
                                  return (
                                    <option key={ item.id } value={ item.id }>
                                      { item.center_name }
                                    </option>
                                  )
                                })
                              }
                            </Input>
                          </FormGroup>
                          <div className="d-flex align-items-center">
                            <div className="ml-auto">
                              <Button
                                color="primary"
                                disabled={ _.isEmpty( props.data.selectCenterReducer.selectedCenter ) || _.isEmpty( props.match.params ) }
                                onClick={() => {
                                  let tmpRoute = `/dashboard/digital-order-form/listings-form/${ props.match.params.id }`
                                  if( !_.isEmpty( props.match.params.order_id ) ){
                                    tmpRoute = tmpRoute + `/${ props.match.params.order_id }`
                                  }
                                  props.history.push( tmpRoute )
                                }}>Select</Button>
                            </div>
                          </div>
                        </Form>
                      </>
                    )
                }
              </Col>
            </Col>
          </Row>
        </div>
        { ( props.onLoadLogin || props.ajaxReducer.ajaxCallProgress > 0 ) && <LoadingOverlay /> }
      </TemplateContainerMain>
    </>
  )
}

const mapStateToProps = state => ({
  data: state,
  ajaxReducer: state.ajaxReducer
})

export default compose(
  connect( mapStateToProps, {
    getUserProfile,
    getSelectedRole,
    getPlatformConfig,
    setSelectCenter
  }),
  WithLogin
)( ExternalLink )