import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import _ from 'lodash'

import { Get, Post } from 'utils/axios-token'
import { Delete } from 'utils/axios-template'
import Lang from './Lang'

import DefaultRecipients from 'utils/defaultRecipients'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreateRecipients: false,
      loading: false,
      recipients: [],
      searchParams: [
        {
          label: 'Email',
          value: 'email',
          param: ''
        },
      ],
      newBlacklistRecipientData: DefaultRecipients( 'BLACKLIST' ),
      selectedRecipient: {},
      showEditRecipientModal: false,
      showDeleteRecipientConfirmation: false,
      errorMessage: {},
      showBlacklistedView: false
    }

    onChangeRecipientsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getRecipients = ( query ) => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/blacklisted_recipients?query=${ query }`,
        this.getRecipientsSuccess,
        this.getRecipientsError,
        this.load
      )
    }
    getRecipientsSuccess = payload => this.setState({ recipients: payload })
    getRecipientsError = error => this.requestError( error.toString() )

    getSelectedRecipient = id => Get(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/blacklisted_recipients/${ id }`,
      this.getSelectedRecipientSuccess,
      this.getSelectedRecipientError,
      this.load
    )
    getSelectedRecipientSuccess = payload => {
      if( payload.email ){
        this.getRecipientLog( payload.email )
      }
      this.setState({ selectedRecipient: payload })
    }
    getSelectedRecipientError = error => this.requestError( error )

    getRecipientLog = email => Get(
      `/api/v1/recipients/${ email }/logs`,
      this.getRecipientLogSuccess,
      this.getRecipientLogError,
      this.load
    )
    getRecipientLogSuccess = payload => this.setState({ blacklistedRecipientLogs: payload })
    getRecipientLogError = error => this.requestError( error )

    postRecipient = data => {
      let tmpCenterDomain = _.find( this.props.data.selectCenterReducer.selectedCenter.center.domains, { is_default_domain: true } )
      Post(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/blacklisted_recipients`,
        { ...data, domain: tmpCenterDomain?.site_domain || '' },
        this.postRecipientSuccess,
        this.postRecipientError,
        this.load
      )
    }
    postRecipientSuccess = () => {
      let tmp = { page: 1, is_paginated: true, is_blacklisted: true }
      this.requestSuccess( Lang[ 'CREATE_BLACKLISTED_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ 
        showCreateRecipients: false,
        newBlacklistRecipientData: DefaultRecipients( 'BLACKLIST' )
      })
      this.getRecipients( convertSearchObjectToBase64( tmp ) )
    }
    postRecipientError = error => {
      if( error.email || error.mobile ){
        this.setState({ errorMessage: error }, () => this.requestError( Lang[ 'CREATE_BLACKLISTED_FAILED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] ) )
      } else {
        this.requestError( error )
      }
    }

    deleteRecipient = ( query ) => {
      Delete(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/blacklisted_recipients/${ this.state.selectedRecipient.id }`,
        ( payload ) => this.deleteRecipientSuccess( payload, query ),
        this.deleteRecipientError,
        this.load
      )
    }
    deleteRecipientSuccess = ( payload, query ) => {
      let tmp = {
        page: this.state.recipients.meta.current_page,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'DELETE_BLACKLISTED_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getRecipients( convertSearchObjectToBase64( query ) )
    }
    deleteRecipientError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          showCreateRecipients={ this.state.showCreateRecipients }
          onLoadRecipients={ this.state.loading }
          onChangeRecipientsHOC={ this.onChangeRecipientsHOC }
          getRecipients={ this.getRecipients }
          recipients={ this.state.recipients }
          searchParams={ this.state.searchParams }
          newBlacklistRecipientData={ this.state.newBlacklistRecipientData }
          postRecipient={ this.postRecipient }
          getSelectedRecipient={ this.getSelectedRecipient }
          selectedRecipient={ this.state.selectedRecipient }
          showEditRecipientModal={ this.state.showEditRecipientModal }
          showDeleteRecipientConfirmation={ this.state.showDeleteRecipientConfirmation }
          deleteRecipient={ this.deleteRecipient }
          errorMessage={ this.state.errorMessage }
          showBlacklistedView={ this.state.showBlacklistedView }
          blacklistedRecipientLogs={ this.state.blacklistedRecipientLogs } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
