import React from 'react'
import {
  Row,
  Col,
  Label,
  Button,
  Alert
} from 'reactstrap'
import {
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation'

const InputEmail = ({
  error,
  email,
  sendEmailPasswordReset,
  onChangeEmail,
  history,
  success
}) => {
  return (
    <AvForm>
      <Row form>
        {
          error !== '' && (
            <Col md={ 12 }>
              <Alert color="danger">
                { error }
              </Alert>
            </Col>
          )
        }
        {
          success !== '' && (
            <Col md={ 12 }>
              <Alert color="success">
                { success }
                <Button 
                  color="success" 
                  onClick={() => history.push( '/' )} 
                  style={{ marginLeft: '10px' }}>
                  Zurück zum Login</Button>
              </Alert>
            </Col>
          )
        }
        <Col md={ 12 }>
          <AvGroup>
            <Label for="exampleEmail">Email Adresse</Label>
            <AvInput
              onChange={ e => onChangeEmail( e.target.value )}
              type="email"
              name="email"
              value={ email }
              required />
          </AvGroup>
        </Col>
      </Row>
      <Row className="divider" />
      <div className="d-flex align-items-center">
        <div className="ml-auto">
          <Button color="link" onClick={() => history.push( '/' )}>Zurück zum Login</Button>
          <Button
            color="primary"
            onClick={() => {
              sendEmailPasswordReset( email )
            }}>Reset</Button>
        </div>
      </div>
    </AvForm>
  )
}

export default InputEmail