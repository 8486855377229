import React, { Component } from 'react'
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'

import Lang from 'Lang/General'

class AdvancedSearch extends Component {
  render = () => {
    return (
      <>
        <div className="d-flex pt-1">
          {
            this.props.searchColumn && this.props.searchColumn.length > 0 && this.props.searchColumn.map( (item, index ) => {
              return(
                <FormGroup style={{ marginLeft: '30px' }} key={ index }>
                  <Label>
                    <Input
                      type="checkbox"
                      value={ item.val }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.selectedSearchColumn )
                        if( _.find( tmp, { name: item.name } ) ) {
                          _.remove( tmp, { name: item.name })
                        } else {
                          tmp && tmp.push({
                            ...item,
                            searchValue: ''
                          })
                        }
                        this.props.onChange( 'selectedSearchColumn', tmp )
                      }}/>{' '}
                    { item.name }
                  </Label>
                </FormGroup>
              )
            })
          }
        </div>
        <Row>
        {
          this.props.selectedSearchColumn && this.props.selectedSearchColumn.length > 0 && this.props.selectedSearchColumn.map(( item, index ) => {
            return (
              <>
                {
                  item.type === 'text' && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label>{ item.name }</Label>
                        <Input
                          name="search-value"
                          value={ item.searchValue }
                          onChange={ e => this.props.updateSelectedSearchColumn( item.val, e.target.value ) }/>
                      </FormGroup>
                    </Col>
                  )
                }
                {
                  item.val === 'status' && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label for="exampleSelect">{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                        <Input
                          onChange={ e => {
                            this.props.updateSelectedSearchColumn( item.val, e.target.value )
                          }}
                          value={ item.searchValue }
                          type="select"
                          name="select"
                          id="exampleSelect">
                            <option>{ Lang[ 'PLEASE_SELECT_ONE' ][ this.props.data.languageReducer.lang ] }</option>
                            <option>{ Lang[ 'ACTIVE' ][ this.props.data.languageReducer.lang ] }</option>
                            <option>{ Lang[ 'INACTIVE' ][ this.props.data.languageReducer.lang ] }</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  )
                }
                {
                  item.val === 'type' && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label for="exampleSelect">Type</Label>
                        <Input
                          onChange={ e => {
                            this.props.updateSelectedSearchColumn( item.val, e.target.value )
                          }}
                          type="select"
                          name="select"
                          id="exampleSelect">
                          {
                            this.props.types && this.props.types.map( item => {
                              return(
                                <option value={ item.name } key={ item.id }>{ item.name }</option>
                              )
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  )
                }
              </>
            )
          })
        }
        </Row>
      </>
    )
  }
}
const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( AdvancedSearch )
