import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import Moment from 'moment'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ViewCampaign from './components/View'
import BookCampaign from './components/BookCampaign'
import ChangeBudget from './components/ChangeBudget'
import CreateCampaign from './components/Create'
import LandingPages from './components/LandingPages'
import ProductInfo from '../info'
import EditDuration from './components/EditDuration'

import WithOLSCampaigns from './actions'
import WithOLSCampaignTypes from './actions/Types'
import WithOLSSettings from './actions/Settings'
import WithUser from './actions/User'
import WithProductRequest from '../actions'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

class OLSListings extends Component {
  state = {
    subscribedPackage: true
  }

  componentDidMount = async() => {
    this.props.getCampaignTypes( convertSearchObjectToBase64({
      is_paginated: false,
      filter: {
        $or: [
          { status: 'ACTIVE' }
        ]
      }
    }))
    if( this.props.data.roleReducer.role.name === 'OLS One Timer' ){
      this.props.getOLSSettings( true )
      this.props.getCampaigns( true )
    } else {
      await this.props.getSelectedUser( this.props.data.profileReducer.profile.id )
      this.props.onChangeCampaignHOC( 'campaignsCenterId', this.props.data.selectCenterReducer.selectedCenter.center.id )
      this.props.getAllProductRequests()
      this.props.getProducts()
      this.props.getRequestedProduct()
      this.props.getOLSCampaignSettings( false, this.props.data.selectCenterReducer.selectedCenter.center.id )
      let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'OLS' })
      this.setState({ subscribedPackage: tmpIndex > -1 })
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_create && this.state.subscribedPackage
    }
  }

  checkHasBookedCampaign = () => {
    let tmp = _.find( this.props.campaigns, { is_booked: true } )
    return tmp ? tmp.is_booked : false
  }

  findNewCampaigns = ( type ) => {
    let tmp = type === 'new' ? _.filter( this.props.campaigns, item => item.is_new && !item.is_booked ) : _.filter( this.props.campaigns, item => !item.is_booked )
    let bookedAppCampaigns = _.filter( this.props.campaigns, { is_booked: true })
    if ( bookedAppCampaigns && bookedAppCampaigns.length > 0 ) {
      bookedAppCampaigns.map( item => {
        _.remove( tmp, { id: item.id, is_booked: false } )
      })
    }
   return tmp
  }

  renderAlertModal = () => {
    let tmpProduct = _.find( this.props.products, product => product.name.en === 'OLS' )
    return (
      <Modal
        isOpen={ this.props.showAlertModal }
        size={ 'md' }>
        <ModalHeader toggle={ () => this.props.onChangeCampaignHOC( 'showAlertModal', false ) }>{ Lang[ 'ALERT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
        <ModalBody>
          <Alert color="info">
            { Lang[ 'BANNER_MESSAGE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
            {
              tmpProduct && (
                <Button 
                  style={{
                    border: 'none',
                    boxShadow: 'none'
                  }}
                  color="link"
                  onClick={ () => {
                    this.props.onChangeProductRequestsHOC( 'showRequestInfoModal', true )
                    this.props.getSelectedProduct( tmpProduct.id )
                  }}>
                  { Lang[ 'CLICK_HERE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                </Button>
              )
            }
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={ () => this.props.onChangeCampaignHOC( 'showAlertModal', false ) }>{ Lang[ 'CLOSE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
        </ModalFooter>
        { this.props.onLoadOLSCampaigns && <LoadingOverlay/> }
      </Modal>
    )
  }

  render = () => {
    let tmpProduct = _.find( this.props.products, x => x.name.en === 'OLS' )
    return (
      <>
        <PageTitle
          heading={ Lang[ 'OLS_CAMPAIGNS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          subheading={ Lang[ 'LISTING_OLS_CAMPAIGNS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-keypad icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_CAMPAIGN' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
              onClick: () => this.props.onChangeCampaignHOC( 'showCreateModal', true ),
              display: !this.checkHasBookedCampaign()
                && this.props.data.roleReducer.role.name === 'Center Admin'
                && !_.isEmpty( this.props.selectedUser )
                && this.props.selectedUser.centers.length > 0
                && this.handleSecurityModulesCreate()
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            {
              this.props.data.selectCenterReducer.selectedCenter.center && _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'OLS' } ) < 0 && (
                <Alert color="info">
                  { Lang[ 'BANNER_MESSAGE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                  {
                    tmpProduct && (
                      <Button 
                        style={{
                          border: 'none',
                          boxShadow: 'none'
                        }}
                        color="link"
                        onClick={ () => {
                          this.props.onChangeProductRequestsHOC( 'showRequestInfoModal', true )
                          this.props.getSelectedProduct( tmpProduct.id )
                        }}>{ Lang[ 'CLICK_HERE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                    )
                  }
                </Alert>
              )
            }
            <Row>
              <Col md={ 12 }>
                <Card className="mb-2">
                  <CardHeader>{ Lang[ 'CURRENT_BOOK_CAMPAIGN' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                  <CardBody>
                    <Row className='m-0' >
                      {
                        this.props.campaigns.length > 0 && this.props.campaigns.map( campaign => {
                          if( campaign.is_booked ) {
                            return(
                              <Col
                                md={ 6 }
                                sm={ 12 }
                                className='p-2'
                                style={{
                                  display: 'flex',
                                  position: 'relative' }}>
                                <Card
                                  key={ campaign.id }
                                  className='w-100' >
                                  <CardHeader
                                    className='d-flex justify-content-between'
                                    style={{ textTransform: "none" }} >
                                    { campaign.title }
                                    {
                                      campaign.is_app_campaign && (
                                        <div
                                          style={{
                                            background: '#2252a1',
                                            color: '#fff',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            padding: "5px 10px"
                                          }}>{ Lang[ 'APP_CAMPAIGN' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                        </div>
                                      )
                                    }
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col sm={ 7 }>
                                        <p>{ `${ Lang[ 'START' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }: ${ Moment( campaign.start_date ).format( 'DD.MM.YYYY' ) }` }</p>
                                        <p>{ `${ Lang[ 'END' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }: ${ Moment( campaign.end_date ).format( 'DD.MM.YYYY' ) }` }</p>
                                        <p>{ `${ Lang[ 'FACEBOOK_BUDGET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }: EUR ${ Number( campaign.fa_budget ).toFixed( 2 ) }` }</p>
                                        <p>{ Lang[ 'LEADS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }:</p>
                                        <div>
                                          <h3 className="text-success" style={{ display: 'inline-block' }}>{ campaign.total_leads }</h3>
                                          <span>{ `( ${ campaign.average_leads } )` }</span>
                                        </div>
                                      </Col>
                                      <Col sm={ 5 }>
                                        <img src={ campaign.teaser_image_url } style={{ width: "150px", height: "150px", objectFit: 'cover' }} />
                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter style={{ height: "60px", justifyContent: 'flex-end' }}>
                                    <Button
                                      className="mr-2"
                                      color="primary"
                                      onClick={ () => {
                                        this.props.getSelectedCampaign( campaign.id )
                                        this.props.onChangeCampaignHOC( 'showViewModal', true )
                                      }}>{ Lang[ 'VIEW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                                    {
                                      !campaign.is_app_campaign ? (
                                        <Button
                                          color="secondary"
                                          onClick={ () => {
                                            this.props.onChangeCampaignHOC( 'showChangeModal', true )
                                            this.props.getSelectedBookedCampaign(
                                              campaign.booked_campaign_id,
                                              this.props.data.roleReducer.role.name === 'OLS One Timer' )
                                          } }>{ Lang[ 'CHANGE_BUDGET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                        </Button>
                                      ) : (
                                        <Button
                                          color="secondary"
                                          onClick={ () => {
                                            this.props.onChangeCampaignHOC( 'showEditDurationModal', true )
                                            this.props.getSelectedBookedCampaign(
                                              campaign.booked_campaign_id,
                                              this.props.data.roleReducer.role.name === 'OLS One Timer' )
                                          } }>{ Lang[ 'EDIT_DURATION' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                        </Button>
                                      )
                                    }
                                  </CardFooter>
                                </Card>
                              </Col>
                            )
                          }
                        })
                      }
                    </Row>
                  </CardBody>
                </Card>
                <Card className="mb-2">
                  <CardHeader>{ Lang[ 'NEW_CAMPAIGN' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                  <CardBody>
                    <Row className='m-0' >
                      {
                        this.props.campaigns.length > 0 && this.findNewCampaigns( 'new' ).map( ( campaign, index ) => {
                          return(
                            <Col
                              md={ 6 }
                              sm={ 12 }
                              className='p-2'
                              style={{
                                display: 'flex',
                                position: 'relative' }}>
                              <Card
                                key={ campaign.id }
                                className='w-100' >
                                <CardHeader
                                  className='d-flex justify-content-between'
                                  style={{ textTransform: "none" }} >
                                  { campaign.title }
                                  {
                                    campaign.is_app_campaign && (
                                      <div
                                        style={{
                                          background: '#2252a1',
                                          color: '#fff',
                                          borderRadius: '5px',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          padding: "5px 10px"
                                        }}>{ Lang[ 'APP_CAMPAIGN' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                      </div>
                                    )
                                  }
                                </CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col sm={ 7 }>
                                      <p>{ `${ Lang[ 'FACEBOOK_BUDGET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }: EUR ${ Number( campaign.fa_budget ).toFixed( 2 ) }` }</p>
                                      <p>{ Lang[ 'LEADS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }:</p>
                                      <div>
                                        <h3 className="text-success" style={{ display: 'inline-block' }}>{ campaign.total_leads }</h3>
                                        <span>{ `( ${ campaign.average_leads } )` }</span>
                                      </div>
                                    </Col>
                                    <Col sm={ 5 }>
                                      <img src={ campaign.teaser_image_url } style={{ width: "150px", height: "150px", objectFit: 'cover' }} />
                                    </Col>
                                  </Row>
                                </CardBody>
                                <CardFooter style={{ height: "60px", justifyContent: 'flex-end' }}>
                                  <Button
                                    className="mr-2"
                                    color="link"
                                    onClick={ () => {
                                      this.props.getSelectedCampaign( campaign.id )
                                      this.props.onChangeCampaignHOC( 'showLandingPageModal', true )
                                    }}>{ Lang[ 'PREVIEW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                                  <Button
                                    className="mr-2"
                                    color="primary"
                                    onClick={ () => {
                                      this.props.getSelectedCampaign( campaign.id )
                                      this.props.onChangeCampaignHOC( 'showViewModal', true )
                                    }}>{ Lang[ 'VIEW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                                  <Button
                                    color="secondary"
                                    onClick={ () => {
                                      this.props.getSelectedCampaign( campaign.id )
                                      this.props.data.selectCenterReducer.selectedCenter.center && _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'OLS' } ) > -1 
                                        ? this.props.onChangeCampaignHOC( 'showBookModal', true ) 
                                        : this.props.onChangeCampaignHOC( 'showAlertModal', true )
                                    }}>{ Lang[ 'BOOK' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                                </CardFooter>
                              </Card>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </CardBody>
                </Card>
                {
                  this.props.campaignTypes.data && this.props.campaignTypes.data.length > 0 && this.props.campaignTypes.data.map( item => {
                    let tmpCampaigns = _.filter(
                      this.findNewCampaigns( 'campaign_types' ), camp => _.findIndex( camp.types, { id: item.id } ) > -1 && !camp.is_booked
                    )

                    return tmpCampaigns.length > 0 && (
                      <Card key={ item.id } className="mb-2">
                        <CardHeader>{ item.title }</CardHeader>
                        <CardBody>
                          <Row className='m-0'>
                            {
                              tmpCampaigns.map( campaign => {
                                return(
                                  <Col
                                    md={ 6 }
                                    sm={ 12 }
                                    className='p-2'
                                    style={{
                                      display: 'flex',
                                      position: 'relative' }}>
                                    <Card
                                      key={ campaign.id }
                                      className='w-100' >
                                      <CardHeader
                                        className='d-flex justify-content-between'
                                        style={{ textTransform: "none" }} >
                                        { campaign.title }
                                        {
                                          campaign.is_app_campaign && (
                                            <div
                                              style={{
                                                background: '#2252a1',
                                                color: '#fff',
                                                borderRadius: '5px',
                                                textAlign: 'center',
                                                fontSize: '14px',
                                                padding: "5px 10px"
                                              }}>{ Lang[ 'APP_CAMPAIGN' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                            </div>
                                          )
                                        }
                                      </CardHeader>
                                      <CardBody>
                                        <Row>
                                          <Col sm={ 7 }>
                                            <p>{ `${ Lang[ 'FACEBOOK_BUDGET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }: EUR ${ Number( campaign.fa_budget ).toFixed( 2 ) }` }</p>
                                            <p>{ Lang[ 'LEADS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</p>
                                            <div>
                                              <h3 className="text-success" style={{ display: 'inline-block' }}>{ campaign.total_leads }</h3>
                                              <span>{ `( ${ campaign.average_leads } )` }</span>
                                            </div>
                                          </Col>
                                          <Col sm={ 5 }>
                                            <img src={ campaign.teaser_image_url } style={{ width: "150px", height: "150px", objectFit: 'cover' }} />
                                          </Col>
                                        </Row>
                                      </CardBody>
                                      <CardFooter style={{ height: "60px", justifyContent: 'flex-end' }}>
                                        <Button
                                          className="mr-2"
                                          color="link"
                                          onClick={ () => {
                                            this.props.getSelectedCampaign( campaign.id )
                                            this.props.onChangeCampaignHOC( 'showLandingPageModal', true )
                                          }}>{ Lang[ 'PREVIEW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                                        <Button
                                          className="mr-2"
                                          color="primary"
                                          onClick={ () => {
                                            this.props.getSelectedCampaign( campaign.id )
                                            this.props.onChangeCampaignHOC( 'showViewModal', true )
                                          }}>{ Lang[ 'VIEW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                                        <Button
                                          color="secondary"
                                          onClick={ () => {
                                            this.props.getSelectedCampaign( campaign.id )
                                            this.props.data.selectCenterReducer.selectedCenter.center && _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'OLS' } ) > -1 
                                              ? this.props.onChangeCampaignHOC( 'showBookModal', true ) 
                                              : this.props.onChangeCampaignHOC( 'showAlertModal', true )
                                          }}>{ Lang[ 'BOOK' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                                      </CardFooter>
                                    </Card>
                                  </Col>
                                )
                              })
                            }
                          </Row>
                        </CardBody>
                      </Card>
                    )
                  })
                }
              </Col>
            </Row>
          </Container>
          <CreateCampaign
            isOneTimer={ this.props.data.roleReducer.role.name === 'OLS One Timer' }
            showCreateModal={ this.props.showCreateModal }
            onChangeCampaignHOC={ this.props.onChangeCampaignHOC }
            newCampaignData={ this.props.newCampaignData }
            oneTimerCreateCampaign={ this.props.oneTimerCreateCampaign }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language }
            createCampaign={ this.props.createCampaign }
            centers={ !_.isEmpty( this.props.selectedUser ) && this.props.selectedUser.centers?.length > 0
              ? this.props.selectedUser.centers
              : [] }
            onLoadOLSCampaigns={ this.props.onLoadOLSCampaigns } />
          <ViewCampaign
            selectedCampaign={ this.props.selectedCampaign }
            onChangeCampaignHOC={ this.props.onChangeCampaignHOC }
            showViewModal={ this.props.showViewModal }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
          <BookCampaign
            { ...this.props }
            isOneTimer={ this.props.data.roleReducer.role.name === 'OLS One Timer' }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
          <ChangeBudget
            isOneTimer={ this.props.data.roleReducer.role.name === 'OLS One Timer' }
            selectedBookedCampaign={ this.props.selectedBookedCampaign }
            onChangeCampaignHOC={ this.props.onChangeCampaignHOC }
            showChangeModal={ this.props.showChangeModal }
            updateBudget={ this.props.updateBudget }
            siteSettings={ this.props.siteSettings }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language }
            onLoadOLSCampaigns={ this.props.onLoadOLSCampaigns } />
          <LandingPages
            onChangeCampaignHOC={ this.props.onChangeCampaignHOC }
            showLandingPageModal={ this.props.showLandingPageModal }
            selectedCampaign={ this.props.selectedCampaign }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
          <ProductInfo 
            data={ this.props.data }
            selectedProduct={ this.props.selectedProduct }
            showRequestInfoModal={ this.props.showRequestInfoModal }
            onChangeProductRequestsHOC={ this.props.onChangeProductRequestsHOC }
            createProductsRequest={ this.props.createProductsRequest }
            newRequest={ this.props.newRequest }
            onChangeCampaignHOC={ this.props.onChangeCampaignHOC }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
          <EditDuration
            isOneTimer={ this.props.data.roleReducer.role.name === 'OLS One Timer' }
            selectedBookedCampaign={ this.props.selectedBookedCampaign }
            onChangeCampaignHOC={ this.props.onChangeCampaignHOC }
            showEditDurationModal={ this.props.showEditDurationModal }
            updateDuration={ this.props.updateDuration }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language }
            onLoadOLSCampaigns={ this.props.onLoadOLSCampaigns } />
        </ReactCSSTransitionGroup>
        { this.renderAlertModal() }
        { ( this.props.onLoadOLSCampaigns || this.props.onLoadCampaignTypes || this.props.onLoadSettings ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithOLSCampaigns,
  WithOLSCampaignTypes,
  WithOLSSettings,
  WithUser,
  WithProductRequest
)( OLSListings )
