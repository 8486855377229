import React from 'react'
import {
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label,
  Button
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'
import FilePondModal from './filePond'

const Images = ({
  images,
  fbImages,
  googleImages,
  openModal,
  onChangeImagesHOC,
  showFilePondModal,
  getImages,
  updateImages,
  getFbImages,
  updateFbImages,
  getGoogleImages,
  updateGoogleImages,
  selectedLocation,
  selectedLanguage
}) => {
  return(
    <Container>
      <h3>{ Lang[ 'IMAGES' ][ selectedLanguage ] }</h3>
      <Row>
        <Col md= { 5 }>
          <Label>{ Lang[ 'BUSINESS_LOGO' ][ selectedLanguage ] }</Label>
          <div
            style={{
              height: 150,
              width: 150,
              borderStyle: 'groove',
              marginRight: '10px',
              marginBottom: '10px'
            }}>
            {
              images.business_logo && (
                <img src={ images.business_logo.url } style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              )
            }
          </div>
          <div className='d-flex justify-content-start'>
            <Button
              color='primary'
              onClick={ () => {
                onChangeImagesHOC( 'showFilePondModal', true )
                onChangeImagesHOC( 'openModal', Lang[ 'BUSINESS_LOGO' ][ selectedLanguage ] )
              }} >
              { Lang[ 'UPLOAD' ][ selectedLanguage ] }
            </Button>
            {
              images.business_logo !== null &&
              <Button
                color='danger'
                style={{ marginLeft: 10 }}
                onClick={ () => {
                  let tmp = _.cloneDeep( images )
                  tmp.business_logo = null
                  onChangeImagesHOC( 'images', tmp )
                }} >
                { Lang[ 'REMOVE' ][ selectedLanguage ] }
              </Button>
            }
          </div>
          <FilePondModal
            contents={ images }
            contentName={ 'images' }
            imageKey={ 'business_logo' }
            modalHeader={ Lang[ 'BUSINESS_LOGO' ][ selectedLanguage ] }
            onChangeImagesHOC={ onChangeImagesHOC }
            showFilePondModal={ showFilePondModal }
            getImages={ getImages }
            updateImages={ updateImages }
            openModal={ openModal }
            selectedLocation={ selectedLocation }
            selectedLanguage={ selectedLanguage } />
        </Col>
        <Col md= { 5 }>
          <Label>Google 360</Label>
          <div
            style={{
              height: 150,
              width: 150,
              borderStyle: 'groove',
              marginRight: '10px',
              marginBottom: '10px'
            }}>
            {
              images.google_360 && (
                <img src={ images.google_360.url } style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              )
            }
          </div>
          <div className='d-flex justify-content-start'>
            <Button
              color='primary'
              onClick={ () => {
                onChangeImagesHOC( 'showFilePondModal', true )
                onChangeImagesHOC( 'openModal', 'Google 360' )
              }} >
              { Lang[ 'UPLOAD' ][ selectedLanguage ] }
            </Button>
            {
              images.google_360 !== null &&
              <Button
                color='danger'
                style={{ marginLeft: 10 }}
                onClick={ () => {
                  let tmp = _.cloneDeep( images )
                  tmp.google_360 = null
                  onChangeImagesHOC( 'images', tmp )
                }} >
                { Lang[ 'REMOVE' ][ selectedLanguage ] }
              </Button>
            }
          </div>
          <FilePondModal
            contents={ images }
            contentName={ 'images' }
            imageKey={ 'google_360' }
            modalHeader={ 'Google 360' }
            openModal={ openModal }
            onChangeImagesHOC={ onChangeImagesHOC }
            showFilePondModal={ showFilePondModal }
            updateImages={ updateImages }
            getImages={ getImages }
            selectedLocation={ selectedLocation }
            selectedLanguage={ selectedLanguage } />
        </Col>
      </Row>
      <Button
        color='primary'
        style={{ margin: '20px 0' }}
        onClick={ () => {
          onChangeImagesHOC( 'showFilePondModal', true )
          onChangeImagesHOC( 'openModal', Lang[ 'CUSTOM_IMAGES' ][ selectedLanguage ] )
        }} >
        { Lang[ 'ADD_IMAGES' ][ selectedLanguage ] }
      </Button>
      <Row>
        {
          images.custom_images && images.custom_images.length > 0 && images.custom_images.map( ( item , index ) => {
            return (
              <Col md={ 5 }>
                <Label>{ Lang[ 'CUSTOM_IMAGES' ][ selectedLanguage ] }</Label>
                <div
                  style={{
                    height: 150,
                    width: 150,
                    borderStyle: 'groove',
                    marginRight: '10px',
                    marginBottom: '10px'
                  }}>
                  <img key={ item.id } src={ item && item.url } style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
                {
                  <Button
                    color='danger'
                    onClick={ () => {
                      let tmp = _.cloneDeep( images )
                      tmp.custom_images.splice( index, 1 )
                      onChangeImagesHOC( 'images', tmp )
                    }} >
                    { Lang[ 'REMOVE' ][ selectedLanguage ] }
                  </Button>
                }
              </Col>
            )
          })
        }
        <FilePondModal
          contents={ images }
          contentName={ 'images' }
          imageKey={ 'custom_images' }
          modalHeader={ Lang[ 'CUSTOM_IMAGES' ][ selectedLanguage ] }
          openModal={ openModal }
          onChangeImagesHOC={ onChangeImagesHOC }
          showFilePondModal={ showFilePondModal }
          updateImages={ updateImages }
          getImages={ getImages }
          selectedLocation={ selectedLocation }
          selectedLanguage={ selectedLanguage } />
      </Row>
      <hr/>
      <h3>Facebook</h3>
      <Row>
        <Col md= { 5 }>
          <Label>{ Lang[ 'FACEBOOK_PROFILE' ][ selectedLanguage ] }</Label>
          <div
            style={{
              height: 150,
              width: 150,
              borderStyle: 'groove',
              marginRight: '10px',
              marginBottom: '10px'
            }}>
            {
              fbImages.facebook_profile && (
                <img src={ fbImages.facebook_profile.rl } style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              )
            }
          </div>
          <div className='d-flex justify-content-start'>
            <Button
              color='primary'
              onClick={ () => {
                onChangeImagesHOC( 'showFilePondModal', true )
                onChangeImagesHOC( 'openModal', Lang[ 'FACEBOOK_PROFILE' ][ selectedLanguage ] )
              }} >
              { Lang[ 'UPLOAD' ][ selectedLanguage ] }
            </Button>
            {
              fbImages.facebook_profile !== null &&
              <Button
                color='danger'
                style={{ marginLeft: 10 }}
                onClick={ () => {
                  let tmp = _.cloneDeep( fbImages )
                  tmp.facebook_profile = null
                  onChangeImagesHOC( 'fbImages', tmp )
                }} >
                { Lang[ 'REMOVE' ][ selectedLanguage ] }
              </Button>
            }
          </div>
          <FilePondModal
            contents={ fbImages }
            contentName={ 'fbImages' }
            imageKey={ 'facebook_profile' }
            modalHeader={ Lang[ 'FACEBOOK_PROFILE' ][ selectedLanguage ] }
            onChangeImagesHOC={ onChangeImagesHOC }
            showFilePondModal={ showFilePondModal }
            getFbImages={ getFbImages }
            updateFbImages={ updateFbImages }
            openModal={ openModal }
            selectedLocation={ selectedLocation }
            selectedLanguage={ selectedLanguage } />
        </Col>
        <Col md= { 5 }>
        <Label>{ Lang[ 'FACEBOOK_COVER' ][ selectedLanguage ] }</Label>
          <div
            style={{
              height: 150,
              width: 150,
              borderStyle: 'groove',
              marginRight: '10px',
              marginBottom: '10px'
            }}>
            {
              fbImages.facebook_cover && (
                <img src={ fbImages.facebook_cover.rl } style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              )
            }
          </div>
          <div className='d-flex justify-content-start'>
            <Button
              color='primary'
              onClick={ () => {
                onChangeImagesHOC( 'showFilePondModal', true )
                onChangeImagesHOC( 'openModal', Lang[ 'FACEBOOK_COVER' ][ selectedLanguage ] )
              }} >
              { Lang[ 'UPLOAD' ][ selectedLanguage ] }
            </Button>
            {
              fbImages.facebook_cover !== null &&
              <Button
                color='danger'
                style={{ marginLeft: 10 }}
                onClick={ () => {
                  let tmp = _.cloneDeep( fbImages )
                  tmp.facebook_cover = null
                  onChangeImagesHOC( 'fbImages', tmp )
                }} >
                { Lang[ 'REMOVE' ][ selectedLanguage ] }
              </Button>
            }
          </div>
          <FilePondModal
            contents={ fbImages }
            contentName={ 'fbImages' }
            imageKey={ 'facebook_cover' }
            modalHeader={ Lang[ 'FACEBOOK_COVER' ][ selectedLanguage ] }
            openModal={ openModal }
            onChangeImagesHOC={ onChangeImagesHOC }
            showFilePondModal={ showFilePondModal }
            getFbImages={ getFbImages }
            updateFbImages={ updateFbImages }
            selectedLocation={ selectedLocation }
            selectedLanguage={ selectedLanguage } />
        </Col>
        <Col md= { 6 } className='mt-4'>
          <FormGroup>
            <Label>{ 'Facebook URL' }</Label>
            <Input
              type='text'
              placeholder={ 'Facebook URL' }
              value={ fbImages.facebook_url ? fbImages.facebook_url.url : '' }
              onChange={ e => {
                let tmp = _.cloneDeep( fbImages )
                tmp.facebook_url = { url: e.target.value }
                onChangeImagesHOC( 'fbImages', tmp )
              }} />
          </FormGroup>
        </Col>
      </Row>
      <hr/>
      <h3>Google</h3>
      <Row>
        <Col md= { 5 }>
          <Label>{ Lang[ 'GOOGLE_PROFILE' ][ selectedLanguage ] }</Label>
          <div
            style={{
              height: 150,
              width: 150,
              borderStyle: 'groove',
              marginRight: '10px',
              marginBottom: '10px'
            }}>
            {
              googleImages.google_profile && (
                <img src={ googleImages.google_profile.rl } style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              )
            }
          </div>
          <div className='d-flex justify-content-start'>
            <Button
              color='primary'
              onClick={ () => {
                onChangeImagesHOC( 'showFilePondModal', true )
                onChangeImagesHOC( 'openModal', Lang[ 'GOOGLE_PROFILE' ][ selectedLanguage ] )
              }} >
              { Lang[ 'UPLOAD' ][ selectedLanguage ] }
            </Button>
            {
              googleImages.google_profile !== null &&
              <Button
                color='danger'
                style={{ marginLeft: 10 }}
                onClick={ () => {
                  let tmp = _.cloneDeep( googleImages )
                  tmp.google_profile = null
                  onChangeImagesHOC( 'googleImages', tmp )
                }} >
                { Lang[ 'REMOVE' ][ selectedLanguage ] }
              </Button>
            }
          </div>
          <FilePondModal
            contents={ googleImages }
            contentName={ 'googleImages' }
            imageKey={ 'google_profile' }
            modalHeader={ Lang[ 'GOOGLE_PROFILE' ][ selectedLanguage ] }
            onChangeImagesHOC={ onChangeImagesHOC }
            showFilePondModal={ showFilePondModal }
            getGoogleImages={ getGoogleImages }
            updateGoogleImages={ updateGoogleImages }
            openModal={ openModal }
            selectedLocation={ selectedLocation }
            selectedLanguage={ selectedLanguage } />
        </Col>
        <Col md= { 5 }>
        <Label>{ Lang[ 'GOOGLE_COVER' ][ selectedLanguage ] }</Label>
          <div
            style={{
              height: 150,
              width: 150,
              borderStyle: 'groove',
              marginRight: '10px',
              marginBottom: '10px'
            }}>
            {
              googleImages.google_cover && (
                <img src={ googleImages.google_cover.rl } style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              )
            }
          </div>
          <div className='d-flex justify-content-start'>
            <Button
              color='primary'
              onClick={ () => {
                onChangeImagesHOC( 'showFilePondModal', true )
                onChangeImagesHOC( 'openModal', Lang[ 'GOOGLE_COVER' ][ selectedLanguage ] )
              }} >
              { Lang[ 'UPLOAD' ][ selectedLanguage ] }
            </Button>
            {
              googleImages.google_cover !== null &&
              <Button
                color='danger'
                style={{ marginLeft: 10 }}
                onClick={ () => {
                  let tmp = _.cloneDeep( googleImages )
                  tmp.google_cover = null
                  onChangeImagesHOC( 'googleImages', tmp )
                }} >
                { Lang[ 'REMOVE' ][ selectedLanguage ] }
              </Button>
            }
          </div>
          <FilePondModal
            contents={ googleImages }
            contentName={ 'googleImages' }
            imageKey={ 'google_cover' }
            modalHeader={ Lang[ 'GOOGLE_COVER' ][ selectedLanguage ] }
            openModal={ openModal }
            onChangeImagesHOC={ onChangeImagesHOC }
            showFilePondModal={ showFilePondModal }
            getGoogleImages={ getGoogleImages }
            updateGoogleImages={ updateGoogleImages }
            selectedLocation={ selectedLocation }
            selectedLanguage={ selectedLanguage } />
        </Col>
      </Row>
    </Container>
  )
}

export default Images
