import React, { useEffect } from 'react'
import {
  Container, Row, Col, Button,
  Card, CardHeader, CardBody,
  FormGroup, Input, Label
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import MultiLangRTE from 'components/MultiLangRTE'
import _ from 'lodash'
import { toast } from 'react-toastify'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ImprintHOC from './actions'

import Lang from 'Lang/General'

const Imprint = props => {

  useEffect( () => {
    props.getImprint( props.data.selectCenterReducer.selectedCenter.center.id )
  }, [] )

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_update
    }
  }
  
  return (
    <>
      <PageTitle
        heading={ Lang[ 'IMPRINT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-file icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                {
                  handleSecurityModulesUpdate() && (
                    <CardHeader>{ Lang[ 'IMPRINT_HEADER' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                  ) 
                }
                {
                  !_.isEmpty( props.imprint ) && (
                    <CardBody>
                      <MultiLangRTE
                        label={ Lang[ 'CONTENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                        value={ props.imprint.content } 
                        onChange={ val => {
                          if( !_.isEmpty( props.imprint ) ) {
                            let tmp = _.cloneDeep( props.imprint )
                            tmp.content = val
                            props.onChangeImprintHOC( 'imprint', tmp )
                          }
                        }}
                        selectedLanguage={ props.data.languageReducer.userSettings.admin_panel_language } />
                      {
                        (
                          (
                            !_.isEmpty( props.data.selectCenterReducer.selectedCenter.center ) &&
                            (
                              ( props.data.profileReducer.profile.id === props.data.selectCenterReducer.selectedCenter.center.client.user_id ) ||
                              ( 
                                props.data.profileReducer.profile.role === 'Center Admin' &&
                                _.findIndex( props.data.profileReducer.profile.centers, { id: props.data.selectCenterReducer.selectedCenter.center.id } ) > -1
                              )
                            )
                          ) ||
                          props.data.profileReducer.profile.role === 'Super Admin' ||
                          props.data.profileReducer.profile.role === 'Management Admin'
                        ) &&
                        !_.isEmpty( props.imprint.content ) && (
                          <>
                            <FormGroup check className="mt-3">
                              <Input
                                type="checkbox"
                                checked={ props.is_assume_responsibility }
                                onChange={ e => props.onChangeImprintHOC( 'is_assume_responsibility', e.target.checked ) } />
                              <Label>{ Lang[ 'CONSENT_STATEMENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                            </FormGroup>
                            <Button
                              disabled={ !props.is_assume_responsibility }
                              color="primary"
                              className="float-right mt-2"
                              onClick={ () => {
                                if ( _.isEmpty( props.imprint.content ) ) {
                                  toast.error( Lang[ 'IMPRINT_ERROR' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
                                } else {
                                  props.updateImprint( props.imprint )
                                  props.submitConsent()
                                }
                              }}>
                              { Lang[ 'SUBMIT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                            </Button>
                          </>
                        )
                      }
                    </CardBody>
                  )
                }
              </Card>
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
      { props.onLoadImprint && <LoadingOverlay /> }
    </>
  )
}

export default ImprintHOC( Imprint )
