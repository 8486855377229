import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Post, Put, Delete } from 'utils/axios-token'

const emptyOpeningHourData = {
  days: [
    {
      id: 0,
      day: 'Mon',
      timeIntervals: [],
      openingDayType: 'closed'
    },
    {
      id: 1,
      day: 'Tue',
      timeIntervals: [],
      openingDayType: 'closed'
    },
    {
      id: 2,
      day: 'Wed',
      timeIntervals: [],
      openingDayType: 'closed'
    },
    {
      id: 3,
      day: 'Thu',
      timeIntervals: [],
      openingDayType: 'closed'
    },
    {
      id: 4,
      day: 'Fri',
      timeIntervals: [],
      openingDayType: 'closed'
    },
    {
      id: 5,
      day: 'Sat',
      timeIntervals: [],
      openingDayType: 'closed'
    },
    {
      id: 6,
      day: 'Sun',
      timeIntervals: [],
      openingDayType: 'closed'
    }
  ],
  dateInterval: {
    from: "2020-08-08"
  },
  openingHoursType: "default"
}

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateLocation: false,
      showUpdateLocation: false,
      locations: [],
      newLocation: {},
      selectedLocation: {},
      selectedOpeningHour: {},
      newOpeningHourData: emptyOpeningHourData,
      leadhubCategoriesOptions: []
    }

    componentDidMount = () => this.sortTimeIntervals()

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeLocationsHOC = ( key, val, isCountry ) => this.setState({
      [ key ] : val,
      loading : isCountry ? true : false
    }, async() => {
      if( isCountry ){
        let tmp = _.cloneDeep( this.state[ key ] )
        let tmpId = _.find( this.props.countries, { name: val.country } ).id
        if( tmpId > 0 ){
          await this.props.getStates( tmpId )
          tmp.state = this.props.states.length > 0 ? this.props.states[ 0 ].name : ''
          this.setState({ [ key ] : tmp, loading: false })
        } else {
          this.setState({ loading: false })
        }
      }
    })

    createLocation = ( center_id, data ) => Post(
      `/api/v1/centers/${ center_id }/locations`,
      data,
      () => this.createLocationSuccess( center_id ),
      this.createLocationError,
      this.load
    )
    createLocationSuccess = center_id => {
      this.requestSuccess( 'Location is successfully created' )
      this.getLocations( center_id, '' )
      this.setState({ showCreateLocation: false })
    }
    createLocationError = error => this.requestError( error )

    getLocations = ( center_id, query ) => Get(
      `/api/v1/centers/${ center_id }/locations?query=${ query }`,
      this.getLocationsSuccess,
      this.getLocationsError,
      this.load
    )
    getLocationsSuccess = payload => this.setState({ locations: payload.data })
    getLocationsError = error => this.requestError( error )

    getSelectedLocation = ( center_id, location_id ) => Get(
      `/api/v1/centers/${ center_id }/locations/${ location_id }`,
      this.getSelectedLocationSuccess,
      this.getSelectedLocationError,
      this.load
    )
    getSelectedLocationSuccess = payload => {
      let tmpSelectedLocation = _.cloneDeep( payload )
      let phoneComponents = tmpSelectedLocation.phone_number.split('-')
      tmpSelectedLocation.area_code = phoneComponents[1]
      tmpSelectedLocation.phone_number = phoneComponents[2]
      // tmpSelectedLocation.categories = !tmpSelectedLocation.categories === null && tmpSelectedLocation.categories.map( item => {
      //   return {
      //     id: item.key,
      //     key: item.key,
      //     value: item.value,
      //     label: item.value
      //   }
      // })
      this.props.getStates( _.find( this.props.countries, { name: tmpSelectedLocation.country } ).id )
      this.setState({ selectedLocation: tmpSelectedLocation, showUpdateLocation: true }) }
    getSelectedLocationError = error => this.requestError( error )

    updateLocation = ( center_id, data, is_update_show_footer ) => Put(
      `/api/v1/centers/${ center_id }/locations/${ data.id }`,
      data,
      () => this.updateLocationSuccess( center_id, is_update_show_footer ),
      this.updateLocationError,
      this.load
    )
    updateLocationSuccess = ( center_id, is_update_show_footer ) => {
      if( is_update_show_footer ){
        this.requestSuccess( 'Opening hour updated successfully.' )
      } else {
        this.requestSuccess( 'Location is successfully updated' )
      }
      this.getLocations( center_id, '' )
      this.setState({ showUpdateLocation: false }) }
    updateLocationError = error => this.requestError( error )

    removeLocation = ( center_id, location_id ) => Delete(
      `/api/v1/centers/${ center_id }/locations/${ location_id }`,
      () => this.removeLocationSuccess( center_id ),
      this.removeLocationError,
      this.load
    )
    removeLocationSuccess = center_id => {
      this.requestSuccess( 'Location is successfully removed' )
      this.getLocations( center_id, '' ) }
    removeLocationError = error => this.requestError( error )

    sortTimeIntervals = () => {
      let tmpSelectedGroup = _.cloneDeep( this.state.newOpeningHourData )
      tmpSelectedGroup.days.map( day => day.timeIntervals.sort( ( a, b ) => (
          new Date( a.from ).getHours() < new Date( b.from ).getHours()
          ? -1
          : new Date( a.from ).getHours() > new Date( b.from ).getHours()
          ? 1
          : 0
        )
      ))
      this.setState({ newOpeningHourData: tmpSelectedGroup })
    }

    getSelectedCenterOpeningHours = ( center_id, location_id ) => Get(
      `/api/v1/centers/${ center_id }`,
      ( payload ) => this.getSelectedCenterOpeningHoursSuccess( payload, location_id, center_id ),
      this.getSelectedCenterOpeningHoursError,
      this.load
    )
    getSelectedCenterOpeningHoursSuccess = ( payload, location_id ) => {
      if( payload.opening_hours.length > 0 ) {
        let tmpOpeningHour = _.find( payload.opening_hours, { location: { id: location_id } } )
        if( !_.isEmpty( tmpOpeningHour ) ){
          this.setState({ selectedOpeningHour: tmpOpeningHour })
        } else {
          this.setState({ selectedOpeningHour: {}, newOpeningHourData: emptyOpeningHourData })
        }
      } else {
        this.setState({ selectedOpeningHour: {}, newOpeningHourData: emptyOpeningHourData })
      }
    }
    getSelectedCenterOpeningHoursError = error => this.requestError( error )

    createOpeningHour = ( location_id, data ) => Post(
      `/api/v1/center_locations/${ location_id }/opening_hours`,
      data,
      this.createOpeningHourSuccess,
      this.createOpeningHourError,
      this.load
    )
    createOpeningHourSuccess = payload => {
      this.requestSuccess( 'Opening Hour created successfully.' )
      this.setState({ showUpdateLocation: false })
    }
    createOpeningHourError = error => this.requestError( error )

    updateOpeningHour = ( location_id, opening_hour_id, data ) => Put(
      `/api/v1/center_locations/${ location_id }/opening_hours/${ opening_hour_id }`,
      data,
      this.updateOpeningHourSuccess,
      this.updateOpeningHourError,
      this.load
    )
    updateOpeningHourSuccess = () => {
      this.setState({ showUpdateLocation: false })
    }
    updateOpeningHourError = error => this.requestError( error )

    getLeadhubCategories = () => Get(
      `/api/v1/leadhub_categories`,
      this.getLeadhubCategoriesSuccess,
      this.getLeadhubCategoriesError,
      this.load
    )
    getLeadhubCategoriesSuccess = payload => {
      let tmp = []
      tmp = payload && payload.data && payload.data.map( item => ({
        id: item.key,
        key: item.key,
        label: item.value,
        value: item.value
      }) )
      this.setState({ leadhubCategoriesOptions: tmp })
    }
    getLeadhubCategoriesError = error => toast.error( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadLocationsHOC={ this.state.loading }
          onChangeLocationsHOC={ this.onChangeLocationsHOC }
          showCreateLocation={ this.state.showCreateLocation }
          showUpdateLocation={ this.state.showUpdateLocation }
          locations={ this.state.locations }
          newLocation={ this.state.newLocation }
          selectedLocation={ this.state.selectedLocation }
          createLocation={ this.createLocation }
          getLocations={ this.getLocations }
          getSelectedLocation={ this.getSelectedLocation }
          updateLocation={ this.updateLocation }
          removeLocation={ this.removeLocation }
          selectedOpeningHour={ this.state.selectedOpeningHour }
          newOpeningHourData={ this.state.newOpeningHourData }
          createOpeningHour={ this.createOpeningHour }
          getSelectedCenterOpeningHours={ this.getSelectedCenterOpeningHours }
          updateOpeningHour={ this.updateOpeningHour }
          leadhubCategoriesOptions={ this.state.leadhubCategoriesOptions }
          getLeadhubCategories={ this.getLeadhubCategories } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
