import React from 'react'
import {
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label,
  Button
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const ExternalUrl = ({
  externalUrl,
  onChangeExternalUrlHOC,
  addYoutubeUrl,
  selectedLanguage
}) => {

  const updateExternalUrl = async ( key, val ) => {
    let tmp = _.cloneDeep( externalUrl )
    tmp[ key ] = val
    onChangeExternalUrlHOC( 'externalUrl', tmp )
  }

  return(
    <Container>
      <Row>
        <Col md={ 8 }>
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'RESERVATIONS_URL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'RESERVATIONS_URL' ][ selectedLanguage ] }
                value={ externalUrl.reservations_url ? externalUrl.reservations_url.url : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( externalUrl.reservations_url )
                  tmp.url = e.target.value
                  updateExternalUrl( 'reservations_url', tmp ) 
                }} />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'MENU_URL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'MENU_URL' ][ selectedLanguage ] }
                value={ externalUrl.menu_url ? externalUrl.menu_url.url : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( externalUrl.menu_url )
                  tmp.url = e.target.value
                  updateExternalUrl( 'menu_url', tmp ) 
                }} />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'ORDER_URL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'ORDER_URL' ][ selectedLanguage ] }
                value={ externalUrl.order_url ? externalUrl.order_url.url : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( externalUrl.order_url )
                  tmp.url = e.target.value
                  updateExternalUrl( 'order_url', tmp ) 
                }} />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'INSTAGRAM_URL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'INSTAGRAM_URL' ][ selectedLanguage ] }
                value={ externalUrl.instagram_url ? externalUrl.instagram_url.url : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( externalUrl.instagram_url )
                  tmp.url = e.target.value
                  updateExternalUrl( 'instagram_url', tmp ) 
                }} />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'YOUTUBE_URL' ][ selectedLanguage ] }</Label>
              
              {
                externalUrl.youtube_url && externalUrl.youtube_url.length > 0 && externalUrl.youtube_url.map( ( item, index ) => {
                  return (
                    <div className="d-flex" style={{ marginBottom: 15 }}>
                      <Input
                        key={ item.id }
                        type='text'
                        placeholder={ Lang[ 'YOUTUBE_URL' ][ selectedLanguage ] }
                        value={ externalUrl.youtube_url[ index ].url }
                        onChange={ e => {
                          let tmp = _.cloneDeep( externalUrl.youtube_url )
                          tmp[ index ].url = e.target.value
                          updateExternalUrl( 'youtube_url', tmp ) 
                        }} />
                      <Button
                        color='primary'
                        style={{ marginLeft: 10, width: 150 }}
                        onClick={ () => {
                          let tmp = _.cloneDeep( externalUrl.youtube_url )
                          tmp.splice( index, 1 )
                          updateExternalUrl( 'youtube_url', tmp ) 
                        }}>
                        { Lang[ 'REMOVE' ][ selectedLanguage ] }
                      </Button>
                    </div>
                  )
                })
              }
            </FormGroup>
            <Button
              color='primary'
              onClick={ () => {
                let tmp = _.cloneDeep( externalUrl.youtube_url )
                tmp.push({ 
                  id: 0,
                  url: ''
                })
                updateExternalUrl( 'youtube_url', tmp ) 
              }}>
              { Lang[ 'ADD' ][ selectedLanguage ] }
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ExternalUrl