import React from 'react'
import { connect } from 'react-redux'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap'
import ReactSelect from 'react-select'
import _ from 'lodash'
import Moment from 'moment'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const CreateBlacklistedRecipientsForm = ( props ) => {
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( props.newBlacklistedRecipient )
    let tmpIDs = []
    tmp[key] = value


    props.onChangeBlacklistedRecipientHOC( 'newBlacklistedRecipient', tmp )
  }
  
  return(
    <Modal
      isOpen={ props.showCreateBlacklistedRecipient }
      size="xl">
      <ModalHeader toggle={ () => props.onChangeBlacklistedRecipientHOC( 'showCreateBlacklistedRecipient', false ) }>{ Lang['CREATE_BLACKLISTED_RECIPIENT'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 9 }>
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>{ Lang[ 'EMAIL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'email' }
                        value={ props.newBlacklistedRecipient.email }
                        onChange={ e => {
                          props.errorMessage["email"] = ""
                          onChangeData( 'email', e.target.value )
                        }}
                        invalid={ props.errorMessage && props.errorMessage.email ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.email ? props.errorMessage.email : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="salutation">{ Lang[ 'SALUTATION' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type="text"
                        name="salutation"
                        id="salutation"
                        value={ props.newBlacklistedRecipient.salutation }
                        onChange={ e => onChangeData( 'salutation', e.target.value ) }/>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'FIRST_NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.newBlacklistedRecipient.first_name }
                        onChange={ e => onChangeData( 'first_name', e.target.value ) }
                        invalid={ props.errorMessage && props.errorMessage.first_name ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.first_name ? props.errorMessage.first_name : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'LAST_NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.newBlacklistedRecipient.last_name }
                        onChange={ e => onChangeData( 'last_name', e.target.value ) }
                        invalid={ props.errorMessage && props.errorMessage.last_name ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.last_name ? props.errorMessage.last_name : '' }</FormFeedback>
                    </FormGroup>
                    {/* <FormGroup>
                      <Label>{ Lang[ 'MOBILE_NUMBER' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Row md={ 12 }>
                        <Col md={ 3 }>
                          <Input
                            type="select"
                            onChange={ e => onChangeData( 'country_code', e.target.value ) }>
                            <option>{ 'Select a country' }</option>
                            {
                              countries.length > 0 && countries.map( item => {
                                return(
                                  <option key={ item.id } value={ item.calling_code[0] }>{ item.name }</option>
                                )
                              })
                            }
                          </Input>
                        </Col>
                        <Col md={ 3 }>
                          <Input
                            type={ 'text' }
                            disabled
                            value={ `+${ newBlacklistedRecipient.country_code }` } />
                        </Col>
                        <Col md={ 6 }>
                          <Input
                            type={ 'text' }
                            value={ newBlacklistedRecipient.mobile_number }
                            onChange={ e => {
                              onChangeData( 'mobile_number', e.target.value )
                            }}
                            invalid={ errorMessage && errorMessage.mobile_number ? true : false } />
                          <FormFeedback>{ errorMessage && errorMessage.mobile_number ? errorMessage.mobile_number : '' }</FormFeedback>
                        </Col>
                      </Row>
                    </FormGroup> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={ 3 }>
            {
              // blacklistedRecipientLogs && blacklistedRecipientLogs.map( item => {
              //   return (
              //     <Card key={ item.id } className="mb-2">
              //       <CardBody>
              //         <p style={{ fontWeight: '600' }}>{ item[ data.languageReducer.lang ] }</p>
              //         <span>{ Moment( item.created_at ).format( 'DD MMM YYYY HH:mm:ss' ) }</span>
              //       </CardBody>
              //     </Card>
              //   )
              // })
            }
          </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ () => {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
            let tmp = _.cloneDeep( props.errorMessage )
            if( !pattern.test( props.newBlacklistedRecipient.email ) ){
              tmp[ "email" ] = "Please enter valid email address."
              props.onChangeBlacklistedRecipientHOC( 'errorMessage', tmp )
            } else {
              tmp[ "email" ] = ""
              let tmpBlacklistedRecipient = {
                ...props.newBlacklistedRecipient,
                mobile_number: !_.isEmpty( props.newBlacklistedRecipient.mobile_number )
                  ? `+${ props.newBlacklistedRecipient.country_code }${ props.newBlacklistedRecipient.mobile_number }`
                  : ''
              }
              props.createBlacklistedRecipient( tmpBlacklistedRecipient )
              props.onChangeBlacklistedRecipientHOC( 'errorMessage', tmp )
            }
          }}>{ Lang[ 'SUBMIT' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Button>
        <Button color="primary" onClick={ () => props.onChangeBlacklistedRecipientHOC( 'showCreateBlacklistedRecipient', false ) }>{ Lang[ 'CANCEL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
      { props.onLoadBlacklistedRecipient && <LoadingOverlay /> }
    </Modal>
  )
}
const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( CreateBlacklistedRecipientsForm )
