import React, { useEffect } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { toast } from 'react-toastify'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'

import CookieCategoriesHOC from './actions'
import CookieCategoryForm from './components/cookieCategoryForm'

import Lang from 'Lang/General'

const CookieCategories = props => {

  useEffect( () => {
    props.getCookieCategories()
  }, [] )
  
  useEffect( () => {
    if ( !props.showCreateCookieCategory ) {
      props.onChangeCookieCategoriesHOC( 'newCookieCategory', {
        title: {
          en: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          nl: ''
        },
        description: {
          en: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          nl: ''
        },
        position: 1,
        cookie_type: 'TARGETING',
        standard_condition: 'SWITCH_OFF',
        status: 'ACTIVE'
      })
    }
  }, [ props.showCreateCookieCategory ] )

  useEffect( () => {
    if( !props.showUpdateCookieCategory ){
      props.onChangeCookieCategoriesHOC( 'selectedCookieCategory', {} )
    }
  }, [ props.showUpdateCookieCategory ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_delete
    }
  }

  const renderCreateCookieCategoryForm = () => (
    <Modal isOpen={ props.showCreateCookieCategory } size='xl' >
      <ModalHeader toggle={ () => props.onChangeCookieCategoriesHOC( 'showCreateCookieCategory', false ) } >
        { Lang[ 'CREATE_COOKIE_CATEGORY' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        <CookieCategoryForm
          currentCookieCategory={ props.newCookieCategory }
          cookieCategoryType={ 'newCookieCategory' }
          onChangeCookieCategoriesHOC={ props.onChangeCookieCategoriesHOC }
          selectedLanguage={ props.data.languageReducer.userSettings.admin_panel_language } />
        { props.onLoadCookieCategoriesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            if ( _.findIndex( props.cookieCategories, { position: props.newCookieCategory.position } ) > -1  ){
              toast.error( Lang[ 'PLEASE_HAVE_A_DIFFERENT_POSITION_VALUE' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
            } 
            else if ( 
              _.isEmpty( _.reduce( Object.values( props.newCookieCategory.title ), ( result, current ) => ( result + current ) ) ) ||
              _.isEmpty( _.reduce( Object.values( props.newCookieCategory.description ), ( result, current ) => ( result + current ) ) )
            ) {
              toast.error( Lang[ 'THE_CONTENT_IS_NOT_AVAILABLE' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
            } 
            else {
              props.createCookieCategory( props.newCookieCategory )
            }
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateCookieCategoryForm = () => (
    <Modal isOpen={ props.showUpdateCookieCategory } size='xl' >
      <ModalHeader toggle={ () => props.onChangeCookieCategoriesHOC( 'showUpdateCookieCategory', false ) } >
        { Lang[ 'UPDATE_COOKIE_CATEGORY' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedCookieCategory ) && <CookieCategoryForm
            currentCookieCategory={ props.selectedCookieCategory }
            cookieCategoryType={ 'selectedCookieCategory' }
            onChangeCookieCategoriesHOC={ props.onChangeCookieCategoriesHOC }
            selectedLanguage={ props.data.languageReducer.userSettings.admin_panel_language } />
        }
        { props.onLoadCookieCategoriesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            if ( _.findIndex( props.cookieCategories.filter( item => item.id !== props.selectedCookieCategory.id ) , { position: props.selectedCookieCategory.position } ) > -1  ) {
              toast.error( Lang[ 'PLEASE_HAVE_A_DIFFERENT_POSITION_VALUE' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
            } else if (
              _.isEmpty( _.reduce( Object.values( props.selectedCookieCategory.title ), ( result, current ) => ( result + current ) ) ) ||
              _.isEmpty( _.reduce( Object.values( props.selectedCookieCategory.description ), ( result, current ) => ( result + current ) ) )
            ) {
              toast.error( Lang[ 'THE_CONTENT_IS_NOT_AVAILABLE' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
            } else {
              props.updateCookieCategory( props.selectedCookieCategory )
            }
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return (
    <>
      <PageTitle
        heading={ Lang[ 'COOKIE_CATEGORIES' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-plus icon-gradient bg-happy-itmeo"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_COOKIE_CATEGORY' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            onClick: () => props.onChangeCookieCategoriesHOC( 'showCreateCookieCategory', true ),
            display: handleSecurityModulesCreate()
          }
        ]} 
      />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          <Row>
            <Col md={ 12 }>
              <Card className='main-card mb-3 p-4' >
                <ReactTable
                  defaultPageSize={ 20 }
                  showPagination={ true }
                  NoDataComponent={ () => <span></span> }
                  data={ props.cookieCategories }
                  columns={[
                    {
                      Header: Lang[ 'TITLE' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'title',
                      Cell: row => row.original.title[ props.data.languageReducer.userSettings.admin_panel_language ]
                    },
                    {
                      Header: Lang[ 'TYPE' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'cookie_type',
                      Cell: row => Lang[ row.original.cookie_type ][ props.data.languageReducer.userSettings.admin_panel_language ],
                    },
                    {
                      Header: Lang[ 'STANDARD_CONDITION' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'standard_condition',
                      Cell: row => Lang[ row.original.standard_condition ][ props.data.languageReducer.userSettings.admin_panel_language ]
                    },
                    {
                      Header: Lang[ 'POSITION' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'position'
                    },
                    {
                      Header: Lang[ 'STATUS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'status'
                    },
                    {
                      Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'id',
                      style: {
                        "justify-content": "center"
                      },
                      Cell: row => (
                        <>
                          {
                            handleSecurityModulesUpdate() && (
                              <Button
                                className="mb-2 mr-2 mt-2 btn-icon btn-icon-only"
                                color='primary'
                                onClick={ () => props.getSelectedCookieCategory( row.original.id ) } >
                                <i className="pe-7s-pen btn-icon-wrapper" />
                              </Button>
                            )
                          }
                          {
                            handleSecurityModulesDelete() && (
                              <Button
                                className="mb-2 mt-2 btn-icon btn-icon-only"
                                color='danger'
                                onClick={() => {
                                  Promise.all([
                                    props.onChangeCookieCategoriesHOC( 'toRemoveID', row.original.id )
                                  ]).then(
                                    props.onChangeCookieCategoriesHOC( 'showPromptModal', true )
                                  )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper" />
                              </Button>
                            )
                          }
                        </>
                      )
                    }
                  ]}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
      { renderCreateCookieCategoryForm() }
      { renderUpdateCookieCategoryForm() }
      {
        props.showPromptModal && (
          <PromptModal
            showPromptModal={ props.showPromptModal }
            content={ Lang[ 'DELETE_CONFIRMATION_COOKIE_CATEGORIES' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            lang={ props.data.languageReducer.userSettings.admin_panel_language }
            onClickNo={() => {
              props.onChangeCookieCategoriesHOC( 'showPromptModal', false )
            }}
            onClickYes={() => {
              props.removeCookieCategory( props.toRemoveID )
            }} />
        )
      }
      { props.onLoadCookieCategoriesHOC && <LoadingOverlay/> }
    </>
  )
}

export default CookieCategoriesHOC( CookieCategories )
