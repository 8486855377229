import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreateNewsletterModal: false,
      showCreateFromTemplate: false,
      showCreateFromContentService: false,
      loading: false,
      newsletters: {
        data: [],
        meta: {
          current_page: 1,
          per_page: 10,
          last_page: 1,
          total_results: 1
        }
      },
      newNewsletterData: {
        subject: "",
        title:"",
        html_content: "",
        json_content: "",
        do_save_json: false,
        do_send_now: false,
        is_draft: true,
        is_scheduled: false,
        sender_id: 0,
        recipient_groups: [],
        delivered_at: Moment().add( 5, 'minutes' )
        // status: 'ACTIVE'
      },
      errorMessage: {},
      selectedNewsletter: {},
      viewNewsletterModal: false,
      step: 1,
      newNewsletterFromCS: {
        type: '',
        teaser_text: '<div></div>',
        content: '<div></div>',
        image_url: '',
        // status: 'ACTIVE',
        start_at: Moment().add( 5, 'minutes' ),
        end_at: Moment().add( 10, 'minutes' ),
        do_send_now: false,
        is_draft: false,
        is_scheduled: false,
        sender_id: 0,
        recipient_groups: []
      },
      searchParams: [
        {
          label: 'Title',
          value: 'title',
          param: ''
        },
        {
          label: 'Recipient Groups',
          value: 'recipient_groups',
          param: [],
          ids: []
        },
        {
          label: 'Status',
          value: 'status',
          param: ''
        }
      ],
      closingConfirmation: false,
      processType: '',
    }

    onChangeNewsletterHOC = ( key, val ) => this.setState({ [key]: val }, () => {
      if( key === 'showCreateNewsletterModal' ){
        this.setState({ processType: 'create' })
      }
    })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error, {
      position: "bottom-right"
    })

    getNewsletter = query => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/newsletters?query=${ query }`,
        this.getNewsletterSuccess,
        this.getNewsletterError,
        this.load
      )
    }
    getNewsletterSuccess = payload => this.setState({ newsletters: payload })
    getNewsletterError = error => this.requestError( error )

    postNewsletter = data => {
      if( _.find( data.recipient_groups, { id: 0 } ) ){
        _.remove( data.recipient_groups, { id: 0 } )
      }
      Post(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/newsletter_newsletters`,
        data,
        this.postNewsletterSuccess,
        this.postNewsletterError,
        this.load
      )
    }
    postNewsletterSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'NEWSLETTER_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({
        showCreateNewsletterModal: false,
        showCreateFromTemplate: false,
        step: 1
      })
      this.getNewsletter( convertSearchObjectToBase64( tmp ) )
    }
    postNewsletterError = error => {
      // let tmp = {
      //   page: 1,
      //   is_paginated: true
      // }
      error.title && this.requestError( error.title )
      error.subject && this.requestError( error.subject )
      this.requestError( 'Create newsletter unsuccessful.' )
      // this.setState({
      //   errorMessage: error,
      //   step: 1,
      //   newNewsletterData: {
      //     subject: "",
      //     title:"",
      //     html_content: "",
      //     json_content: "",
      //     do_save_json: false,
      //     do_send_now: true,
      //     sender_id: 0,
      //     recipient_groups: [],
      //     delivered_at: Moment().add( 5, 'minutes' )
      //   }})
      // this.getNewsletter( convertSearchObjectToBase64( tmp ) )
    }

    getSelectedNewsletter = ( id, type ) => Get(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/newsletters/${ id }`,
      payload => this.getSelectedNewsletterSuccess( payload, type ),
      this.getSelectedNewsletterError,
      this.load
    )
    getSelectedNewsletterSuccess = ( payload, type ) => this.setState( type === 'view'
      ? { selectedNewsletter: payload, viewNewsletterModal: true }
      : {
        newNewsletterData: { ...payload, delivered_at: Moment( payload.delivered_at ) },
        processType: 'update',
        showCreateFromTemplate: true
      }
    )
    getSelectedNewsletterError = error => this.requestError( error )

    updateNewsletter = data => Put(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/newsletters/${ data.id }`,
      data,
      this.updateNewsletterSuccess,
      this.updateNewsletterError,
      this.load
    )
    updateNewsletterSuccess = () => {
      this.requestSuccess( Lang[ 'NEWSLETTER_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getNewsletter( convertSearchObjectToBase64({ page: 1, is_paginated: true }) )
      this.setState({
        showCreateNewsletterModal: false,
        showCreateFromTemplate: false,
        processType: '',
        step: 1
      })
    }
    updateNewsletterError = error => this.requestError( error )

    resendNewsletter = ( id, query ) => Put(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/newsletters/${ id }/resend`,
      {},
      payload => this.resendNewsletterSuccess( payload, query ),
      this.resendNewsletterError,
      this.load
    )
    resendNewsletterSuccess = ( payload, query ) => {
      this.getNewsletter( query )
    }
    resendNewsletterError = error => this.requestError( error )

    getSelectedTemplate = template_id => Get(
      `/api/v1/newsletter_templates/${ template_id }`,
      this.getSelectedTemplateSuccess,
      this.getSelectedTemplateError,
      this.load
    )
    getSelectedTemplateSuccess = payload => {
      if( payload ){
        let tmp = _.cloneDeep( this.state.newNewsletterData )
        tmp[ 'selectedTemplate' ] = payload.json_content
        tmp[ 'template_id' ] = payload.id
        this.setState({ newNewsletterData: tmp })
      }
    }
    getSelectedTemplateError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          showCreateNewsletterModal={ this.state.showCreateNewsletterModal }
          onLoadNewsletters={ this.state.loading }
          onChangeNewsletterHOC={ this.onChangeNewsletterHOC }
          newsletters={ this.state.newsletters }
          getNewsletter={ this.getNewsletter }
          newNewsletterData={ this.state.newNewsletterData }
          postNewsletter={ this.postNewsletter }
          errorMessage={ this.state.errorMessage }
          getSelectedNewsletter={ this.getSelectedNewsletter }
          updateNewsletter={ this.updateNewsletter }
          selectedNewsletter={ this.state.selectedNewsletter }
          viewNewsletterModal={ this.state.viewNewsletterModal }
          step={ this.state.step }
          resendNewsletter={ this.resendNewsletter }
          showCreateFromTemplate={ this.state.showCreateFromTemplate }
          showCreateFromContentService={ this.state.showCreateFromContentService }
          newNewsletterFromCS={ this.state.newNewsletterFromCS }
          searchParams={ this.state.searchParams }
          closingConfirmation={ this.state.closingConfirmation }
          getSelectedTemplate={ this.getSelectedTemplate }
          processType={ this.state.processType } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    toggleMediaDatabase
  })( WithHOC ) 
}

export default HOC
