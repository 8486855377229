import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from 'reactstrap'
import ReactTable from 'react-table'
import Moment from 'moment'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateMessageConsultant from './Create'

import WithMessageConsultant from './actions'
import Lang from 'Lang/General'

class MessageConsultant extends Component {

  componentDidMount = () => this.props.getMessageConsultant( this.props.data.selectCenterReducer.selectedCenter.center.consultant_id )

  renderCreateMessageConsultantForm = () => (
    <Modal isOpen={ this.props.showCreateMessageConsultant } size='xl' >
      <ModalHeader toggle={ () => this.props.onChangeMessageConsultantHOC( 'showCreateMessageConsultant', false ) }>
        { Lang[ 'MESSAGE_CONSULTANT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        <CreateMessageConsultant
          { ...this.props }
          selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language } />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => this.props.createMessageConsultant( this.props.data.selectCenterReducer.selectedCenter.center.consultant_id, this.props.newMessageConsultant, this.props.data.selectCenterReducer.selectedCenter.center.id ) } >
          { Lang[ 'SEND' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        </Button>
      </ModalFooter>
      { this.props.onLoadMessageConsultantHOC && <LoadingOverlay/> }
    </Modal>
  )

  render = () => {
    let consultant = this.props.data.selectCenterReducer.selectedCenter.center.consultant
    return (
      <>
        <PageTitle
          heading={ Lang[ 'MESSAGE_CONSULTANT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          subheading={ Lang[ 'MESSAGE_CONSULTANT_DETAILS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'MESSAGE_CONSULTANT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
              onClick: () => this.props.onChangeMessageConsultantHOC( 'showCreateMessageConsultant', true ),
              display: true
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            <Row>
              <Col md={ 12 }>
                <Card className='main-card mb-3'>
                  <CardHeader>{ Lang[ 'YOUR_CONSULTANT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } { consultant.user.first_name } { consultant.user.last_name }</CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={ 3 }>
                        {
                          consultant.user.photo_url ? (
                            <img src={ consultant.user.photo_url } style={{ width: '100%' }}></img>
                          ) : (
                            <div style={{
                              width: '200px',
                              height: '100%',
                              border: '1px solid black',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>No Image</div>
                          )
                        }
                      </Col>
                      <Col md={ 6 }>
                        <Table className="mb-0" bordered>
                          <tbody>
                            <tr>
                              <th scope="row">{ Lang[ 'MOBILE_NUMBER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                              <td>{ consultant.mobile_number }</td>
                            </tr>
                            <tr>
                              <th scope="row">{ Lang[ 'PHONE_NUMBER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                              <td>{ consultant.phone_number }</td>
                            </tr>
                            <tr>
                              <th scope="row">{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</th>
                              <td>{ consultant.user.email }</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className='main-card mb-3'>
                  <CardBody>
                    <ReactTable
                      pageSize={ 20 }
                      showPagination={ true }
                      NoDataComponent={ () => <span></span> }
                      data={ this.props.messages }
                      columns={[
                        {
                          Header: Lang[ 'SUBJECT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'subject'
                        },
                        {
                          Header: Lang[ 'MESSAGE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'message'
                        },
                        {
                          Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'status',
                          Cell: row => {
                            return(
                              <p>{`${ row.original.status } at ${ Moment( row.original.updated_at ).format('YYYY-MM-DD hh:mm a') }`}</p>
                            )
                          }
                        }
                      ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
        { this.renderCreateMessageConsultantForm() }
        { this.props.onLoadMessageConsultantHOC && <LoadingOverlay/> }
      </>
    )
  }
}

export default WithMessageConsultant( MessageConsultant )
