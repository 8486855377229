import React from 'react'
import ReactHTMLParser from 'react-html-parser'
import { UncontrolledTooltip } from 'reactstrap'

import { generateFieldLabelStr } from '../utils'

const TextComponent = ({
  content,
  currentLanguage
}) => {
  return (
    <p 
      id={ content.id }
      className="ck-content" 
      style={{
        padding: content.padding,
        ...content.style,
        fontSize: content.fontSize || "12px",
        width: "100%" 
      }}>
      { ReactHTMLParser( generateFieldLabelStr( content, null, currentLanguage ) ) }
      {
        content.showQuickInfo && 
        document.getElementById(`quick-info-tooltip-${content.id}`) && (
          <UncontrolledTooltip
            target={`quick-info-tooltip-${content.id}`} 
            placement="top">
            { content.preview_text }
          </UncontrolledTooltip>
        )
      }
    </p>
  )
}

export default TextComponent