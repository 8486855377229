import React, { Component } from 'react'
import { MdClose} from 'react-icons/md'
import {
 FaCaretDown,
 FaCaretUp
} from 'react-icons/fa'
import { FcRight } from 'react-icons/fc'
import _ from 'lodash'
import Moment from 'moment'

class TimeRangePicker extends Component {
  state={
    isStartHourClicked: false,
    isStartMinuteClicked: false,
    isEndHourClicked: false,
    isEndMinuteClicked: false,
    hours: [
      "00", "01", "02", "03", "04", "05",
      "06", "07", "08", "09", "10", "11",
      "12", "13", "14", "15", "16", "17",
      "18", "19", "20", "21", "22", "23"
    ],
    minutes: [
      "00", "01", "02", "03", "04", "05",
      "06", "07", "08", "09", "10", "11",
      "12", "13", "14", "15", "16", "17",
      "18", "19", "20", "21", "22", "23",
      "24", "25", "26", "27", "28", "29",
      "30", "31", "32", "33", "34", "35",
      "36", "37", "38", "39", "40", "41",
      "42", "43", "44", "45", "46", "47",
      "48", "49", "50", "51", "52", "53",
      "54", "55", "56", "57", "58", "59"
    ],
    hoveredStartHour: '',
    hoveredStartMinute: '',
    hoveredEndHour: '',
    hoveredEndMinute: '',
    dayIndex: 0,
    intervalIndex: 0,
  }

  componentDidMount = () => {
    let tmpInterval = _.cloneDeep( this.props.interval )
    let tmpIntervalIndex = this.props.day.timeIntervals.findIndex( x => x.id === tmpInterval.id )
    let tmpDay = _.cloneDeep( this.props.day )
    let tmpDayIndex = 0
    if( !_.isEmpty( this.props.selectedOpeningHour.opening_hour ) ){
      tmpDayIndex = this.props.selectedOpeningHour.opening_hour.days.findIndex( x => x.day === tmpDay.day )
    } else{
      tmpDayIndex = this.props.newOpeningHourData.days.findIndex( x => x.day === tmpDay.day )
    }
    this.setState({ dayIndex: tmpDayIndex, intervalIndex: tmpIntervalIndex })
  }

  componentDidUpdate = pp => {
    if( this.props.interval !== pp.interval ){
      let tmpInterval = _.cloneDeep( this.props.interval )
      let tmpIntervalIndex = this.props.day.timeIntervals.findIndex( x => x.id === tmpInterval.id )
      this.setState({ intervalIndex: tmpIntervalIndex })
    }
  }

  handleStartHourClicked = () => this.setState({ isStartHourClicked: !this.state.isStartHourClicked })

  handleStartMinuteClicked = () => this.setState({ isStartMinuteClicked: !this.state.isStartMinuteClicked })

  handleEndHourClicked = () => this.setState({ isEndHourClicked: !this.state.isEndHourClicked })

  handleEndMinuteClicked = () => this.setState({ isEndMinuteClicked: !this.state.isEndMinuteClicked })

  render = () => {
    let {
      newOpeningHourData,
      day,
      interval,
      from,
      to,
      onChangeLocationsHOC,
      sortTimeIntervals
    } = this.props
    return(
      <div style={{
        position: "relative",
        display: "flex",
        flexGrow: "100",
        alignItems: "center",
        padding: "2px",
        border: "1px solid #cecece",
        borderRadius: "3px",
        marginRight: "10px",
        background: "#ededed" }}>
        <div>
          <button
            style={{
              border: "1px solid #cecece",
              borderRadius: "3px",
              background: "#ffffff",
              color: "#000000" }}
            onClick={ () => this.handleStartHourClicked() }>
            { new Date( from ).getHours() && new Date( from ).getHours() === 0 ? '00' : new Date( from ).getHours() < 10 ? '0' : '' }
            { new Date( from ).getHours() >= 0 ? new Date( from ).getHours().toString() : from.substring( 0, 2 ) }
            {
              this.state.isStartHourClicked
              ? <FaCaretUp style={{ marginLeft: "5px" }} />
              : <FaCaretDown style={{ marginLeft: "5px" }} />
            }
          </button>
          {
            this.state.isStartHourClicked && (
              <div
                style={{
                  width: "inherit",
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  top: "35px",
                  zIndex: "998",
                  maxHeight: "150px",
                  overflow: "scroll",
                  border: "1px solid #cecece",
                  borderRadius: "3px"
                }}>
                {
                  this.state.hours.map( hour => <button
                    onMouseEnter={ () => this.setState({ hoveredStartHour: hour }) }
                    onMouseLeave={ () => this.setState({ hoveredStartHour: '' }) }
                    onClick={ () => {
                      let tmpInterval = _.cloneDeep( interval )
                      let tmpDay = _.cloneDeep( day )
                      let tmpGroup = {}
                      if( _.isEmpty( this.props.selectedOpeningHour.opening_hour ) ){
                        tmpGroup = _.cloneDeep( newOpeningHourData )
                        tmpInterval.from = Moment( tmpInterval.from ).set({ h: parseInt( hour ) }).format()

                        tmpDay.timeIntervals[ this.state.intervalIndex ] = tmpInterval
                        tmpGroup.days[ this.state.dayIndex ] = tmpDay
                        sortTimeIntervals( tmpGroup, this.state.dayIndex, interval.id )
                        onChangeLocationsHOC( 'newOpeningHourData', tmpGroup )
                      }else{
                        tmpGroup = _.cloneDeep( this.props.selectedOpeningHour.opening_hour )
                        let tmpHourStart = tmpInterval.from.substring( 0, 2 )
                        let tmpHourEnd = tmpInterval.to.substring( 0, 2 )
                        tmpInterval.from = tmpInterval.from.replace( tmpHourStart, hour )
                        tmpDay.timeIntervals[ this.state.intervalIndex ] = tmpInterval
                        tmpGroup.days[ this.state.dayIndex ] = tmpDay
                        sortTimeIntervals( tmpGroup, this.state.dayIndex, interval.id )
                        let tmp = {
                          ...this.props.selectedOpeningHour,
                          opening_hour: tmpGroup
                        }
                        onChangeLocationsHOC( 'selectedOpeningHour', tmp )
                      }
                      this.handleStartHourClicked()
                    }}
                    style={{
                      minWidth: "100%",
                      color: "#000000",
                      background: hour === this.state.hoveredStartHour ? '#6ca1e6' : '#ffffff',
                      border: "none",
                      outline: "none" }}>
                    { hour }</button>
                  )
                }
              </div>
            )
          }
        </div>
        <span style={{ padding: "0 2px" }} > : </span>
        <div>
          <button
            style={{
              border: "1px solid #cecece",
              borderRadius: "3px",
              background: "#ffffff",
              color: "#000000" }}
            onClick={ () => this.handleStartMinuteClicked() }>
            { new Date( from ).getMinutes() !== 'NaN' &&
              (new Date( from ).getMinutes() === 0 || new Date( from ).getMinutes() < 10) ? '0' : '' }
            { new Date( from ).getMinutes() ? new Date( from ).getMinutes() : from.substr( 3, 2 ) }
            {
              this.state.isStartMinuteClicked
              ? <FaCaretUp style={{ marginLeft: "5px" }} />
              : <FaCaretDown style={{ marginLeft: "5px" }} />
            }
          </button>
          {
            this.state.isStartMinuteClicked && (
              <div
                style={{
                  width: "inherit",
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  top: "35px",
                  zIndex: "998",
                  maxHeight: "150px",
                  overflow: "scroll",
                  border: "1px solid #cecece",
                  borderRadius: "3px"
                }}>
                {
                  this.state.minutes.map( minute => <button
                    onMouseEnter={ () => this.setState({ hoveredStartMinute: minute }) }
                    onMouseLeave={ () => this.setState({ hoveredStartMinute: '' }) }
                    onClick={ () => {
                      let tmpInterval = _.cloneDeep( interval )
                      let tmpDay = _.cloneDeep( day )
                      let tmpGroup = {}
                      if( _.isEmpty( this.props.selectedOpeningHour.opening_hour ) ){
                        tmpGroup = _.cloneDeep( newOpeningHourData )
                        tmpInterval.from = Moment( tmpInterval.from ).set({ m: parseInt( minute ) }).format()
                        tmpDay.timeIntervals[ this.state.intervalIndex ] = tmpInterval
                        tmpGroup.days[ this.state.dayIndex ] = tmpDay
                        onChangeLocationsHOC( 'newOpeningHourData', tmpGroup )
                        sortTimeIntervals( tmpGroup, this.state.dayIndex, interval.id )
                      } else{
                        tmpGroup = _.cloneDeep( this.props.selectedOpeningHour.opening_hour )
                        tmpInterval.from = tmpInterval.from.substring( 0, 3 ) + minute
                        tmpDay.timeIntervals[ this.state.intervalIndex ] = tmpInterval
                        tmpGroup.days[ this.state.dayIndex ] = tmpDay
                        sortTimeIntervals( tmpGroup, this.state.dayIndex, interval.id )
                        let tmp = {
                          ...this.props.selectedOpeningHour,
                          opening_hour: tmpGroup
                        }
                        onChangeLocationsHOC( 'selectedOpeningHour', tmp )
                      }
                      this.handleStartMinuteClicked()
                    }}
                    style={{
                      minWidth: "100%",
                      color: "#000000",
                      background: this.state.hoveredStartMinute === minute ? '#6ca1e6' : '#ffffff' ,
                      border: "none",
                      outline: "none" }}>
                    { minute }</button>
                  )
                }
              </div>
            )
          }
        </div>
        <div style={{ padding: "0 10px" }}>
          <FcRight size={ 20 } />
        </div>
        <div>
          <button
            style={{
              border: "1px solid #cecece",
              borderRadius: "3px",
              background: "#ffffff",
              color: "#000000" }}
            onClick={ () => this.handleEndHourClicked() }>
            { new Date( to ).getHours() && new Date( to ).getHours() === 0 ? '00' : new Date( to ).getHours() < 10 ? '0' : '' }
            { new Date( to ).getHours() >= 0 ? new Date( to ).getHours().toString() : to.substring( 0, 2 ) }
            {
              this.state.isEndHourClicked
              ? <FaCaretUp style={{ marginLeft: "5px" }} />
              : <FaCaretDown style={{ marginLeft: "5px" }} />
            }
          </button>
          {
            this.state.isEndHourClicked && (
              <div
                style={{
                  width: "inherit",
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  top: "35px",
                  zIndex: "998",
                  maxHeight: "150px",
                  overflow: "scroll",
                  border: "1px solid #cecece",
                  borderRadius: "3px"
                }}>
                {
                  this.state.hours.map( hour => <button
                    onMouseEnter={ () => this.setState({ hoveredEndHour: hour }) }
                    onMouseLeave={ () => this.setState({ hoveredEndHour: '' }) }
                    onClick={ () => {
                      let tmpInterval = _.cloneDeep( interval )
                      let tmpDay = _.cloneDeep( day )
                      let tmpGroup = {}
                      if( _.isEmpty( this.props.selectedOpeningHour.opening_hour ) ){
                        tmpGroup = _.cloneDeep( newOpeningHourData )
                        tmpInterval.to = Moment( tmpInterval.to ).set({ h: parseInt( hour ) }).format()
                        tmpDay.timeIntervals[ this.state.intervalIndex ] = tmpInterval
                        tmpGroup.days[ this.state.dayIndex ] = tmpDay
                        sortTimeIntervals( tmpGroup, this.state.dayIndex, interval.id )
                        onChangeLocationsHOC( 'newOpeningHourData', tmpGroup )
                      } else {
                        tmpGroup = _.cloneDeep( this.props.selectedOpeningHour.opening_hour )

                        let tmpHourStart = tmpInterval.from.substring( 0, 2 )
                        let tmpHourEnd = tmpInterval.to.substring( 0, 2 )
                        tmpInterval.to = tmpInterval.to.replace( tmpHourEnd, hour )
                        tmpDay.timeIntervals[ this.state.intervalIndex ] = tmpInterval
                        tmpGroup.days[ this.state.dayIndex ] = tmpDay
                        sortTimeIntervals( tmpGroup, this.state.dayIndex, interval.id )
                        let tmp = {
                          ...this.props.selectedOpeningHour,
                          opening_hour: tmpGroup
                        }
                        onChangeLocationsHOC( 'selectedOpeningHour', tmp )
                      }
                      this.handleEndHourClicked()
                    }}
                    style={{
                      minWidth: "100%",
                      color: "#000000",
                      background: this.state.hoveredEndHour === hour ? "#6ca1e6" : "#ffffff",
                      border: "none",
                      outline: "none" }}>
                    { hour }</button>
                  )
                }
              </div>
            )
          }
        </div>
        <span style={{ padding: "0 2px" }} > : </span>
        <div style={{ marginRight: "10px" }}>
          <button
            style={{
              border: "1px solid #cecece",
              borderRadius: "3px",
              background: "#ffffff",
              color: "#000000" }}
            onClick={ () => this.handleEndMinuteClicked() }>
            { new Date( to ).getMinutes() !== 'NaN' &&
              (new Date( to ).getMinutes() === 0 || new Date( to ).getMinutes() < 10) && '0' }
            { new Date( to ).getMinutes() ? new Date( to ).getMinutes() : to.substr( 3, 2 ) }
            {
              this.state.isEndMinuteClicked
              ? <FaCaretUp style={{ marginLeft: "5px" }} />
              : <FaCaretDown style={{ marginLeft: "5px" }} />
            }
          </button>
          {
            this.state.isEndMinuteClicked && (
              <div
                style={{
                  width: "inherit",
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  top: "35px",
                  zIndex: "998",
                  maxHeight: "150px",
                  overflow: "scroll",
                  border: "1px solid #cecece",
                  borderRadius: "3px"
                }}>
                {
                  this.state.minutes.map( minute => <button
                    onMouseEnter={ () => this.setState({ hoveredEndMinute: minute }) }
                    onMouseLeave={ () => this.setState({ hoveredEndMinute: '' }) }
                    onClick={ () => {
                      let tmpInterval = _.cloneDeep( interval )
                      let tmpDay = _.cloneDeep( day )
                      let tmpGroup = {}
                      if( _.isEmpty( this.props.selectedOpeningHour.opening_hour ) ){
                        tmpGroup = _.cloneDeep( newOpeningHourData )
                        tmpInterval.to = Moment( tmpInterval.to ).set({ m: parseInt( minute ) }).format()
                        tmpDay.timeIntervals[ this.state.intervalIndex ] = tmpInterval
                        tmpGroup.days[ this.state.dayIndex ] = tmpDay
                        sortTimeIntervals( tmpGroup, this.state.dayIndex, interval.id )
                        onChangeLocationsHOC( 'newOpeningHourData', tmpGroup )
                      } else{
                        tmpGroup = _.cloneDeep( this.props.selectedOpeningHour.opening_hour )
                        tmpInterval.to = tmpInterval.to.substring( 0, 3 ) + minute
                        tmpDay.timeIntervals[ this.state.intervalIndex ] = tmpInterval
                        tmpGroup.days[ this.state.dayIndex ] = tmpDay
                        sortTimeIntervals( tmpGroup, this.state.dayIndex, interval.id )
                        let tmp = {
                          ...this.props.selectedOpeningHour,
                          opening_hour: tmpGroup
                        }
                        onChangeLocationsHOC( 'selectedOpeningHour', tmp )
                      }
                      this.handleEndMinuteClicked()
                    }}
                    style={{
                      minWidth: "100%",
                      color: "#000000",
                      background: this.state.hoveredEndMinute === minute ? "#6ca1e6" : "#ffffff",
                      border: "none",
                      outline: "none" }}>
                    { minute }</button>
                  )
                }
              </div>
            )
          }
        </div>
        <button
          style={{
            background: "none",
            border: "none",
            padding: "0",
            position: "absolute",
            top: "-4px",
            right: "0px",
            color: "#b83c3b" }}
          onClick={ () => {
            let tmpGroup = {}
            let tmpDay = _.cloneDeep( day )
            if( _.isEmpty( this.props.selectedOpeningHour.opening_hour ) ){
              tmpGroup = _.cloneDeep( newOpeningHourData )
              tmpDay.timeIntervals.splice( this.state.intervalIndex, 1 )
              tmpGroup.days[ this.state.dayIndex ] = tmpDay
              onChangeLocationsHOC( 'newOpeningHourData', tmpGroup )
            } else {
              tmpGroup = _.cloneDeep( this.props.selectedOpeningHour.opening_hour )
              tmpDay.timeIntervals.splice( this.state.intervalIndex, 1 )
              tmpGroup.days[ this.state.dayIndex ] = tmpDay
              let tmp = {
                ...this.props.selectedOpeningHour,
                opening_hour: tmpGroup
              }
              onChangeLocationsHOC( 'selectedOpeningHour', tmp )
            }
          }}>
          <MdClose size={ 10 } />
        </button>
      </div>
    )
  }
}

export default TimeRangePicker
