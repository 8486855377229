import { TOGGLE_MEDIA_DATABASE } from '../actions/type'

let initialState = {
  open: false
}

export default ( state = initialState, action ) => {
  switch ( action.type ){
    case TOGGLE_MEDIA_DATABASE:
      return {
        ...state,
        open: action.payload
      }
    default: return state
  }
}