import { TOGGLE_LOGIN_BOX } from '../actions/type'

let initialState = {
  open: false
}

export default ( state = initialState, action ) => {
  switch ( action.type ){
    case TOGGLE_LOGIN_BOX:
      return {
        ...state,
        open: action.payload
      }
    default: return state
  }
}