import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button, Container, Row, Col,
  Card, CardHeader, CardBody, CardDeck, CardImg,
  Label, UncontrolledTooltip
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Moment from 'moment'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import AlertNotSubscribe from 'components/Alert'
import TemplateEditor from './TemplateEditor'

import WithContentServices from '../Listings/actions/contentServices'
import WithTemplates from './actions'
import Lang from 'Lang/General'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

class TemplatesListing extends Component {
  state = {
    subscribedPackage: true
  }

  componentDidMount = () => {
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Newsletters' })
    this.props.getTemplates()
    this.props.getOwnTemplates()
    this.setState({ subscribedPackage: tmpIndex > -1 })
    this.props.getContentServices( convertSearchObjectToBase64({ 
      is_paginated: false, 
      filter: {
        status: 'ACTIVE',
        ...( 
          this.props.data.selectCenterReducer.selectedCenter.center.center_groups && this.props.data.selectCenterReducer.selectedCenter.center.center_groups.length > 0 && (
            {
              $or: [
                ...( this.props.data.selectCenterReducer.selectedCenter.center.center_groups.map( item => {
                  return { center_group_id: item.id }
                }))
              ]
            }
          )
        )
      }
    }))
  }

  componentDidUpdate = pp => {
    if( !this.props.showUpdateTemplate && pp.showUpdateTemplate ){
      this.props.onChangeTemplatesHOC( 'selectedTemplate', {} )
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_delete
    }
  }

  renderTemplateCard = templateList => (
    <Row className='pt-2'>
      <Col md={ 12 } >
        <CardDeck>
          {
            templateList.map( template => {
            return <Card className='mt-3' style={{ maxWidth: "45%" }} >
                <CardHeader className='d-flex flex-row justify-content-end'>
                  {
                    this.handleSecurityModulesUpdate() && <>
                      <Button
                        id={ `Newsletter-template-${ template.id }-update` }
                        disabled={ !this.state.subscribedPackage }
                        color='link'
                        onClick={ () => this.props.getSelectedTemplate( template.id ) }>
                        <i className="pe-7s-pen" style={{ fontSize: '30px' }}/></Button>
                      <UncontrolledTooltip
                        target={ `Newsletter-template-${ template.id }-update` }
                        placement="top">{ Lang[ 'EDIT_TEMPLATE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</UncontrolledTooltip>
                    </>
                  }
                  {
                    this.handleSecurityModulesDelete() && <>
                      <Button
                        id={ `Newsletter-template-${ template.id }-delete` }
                        disabled={ !this.state.subscribedPackage }
                        color='link'
                        onClick={ () => {
                          this.props.onChangeTemplatesHOC( 'selectedTemplate', { id: template.id } )
                          this.props.onChangeTemplatesHOC( 'showDeleteTemplate', true ) }}>
                        <i className="pe-7s-trash" style={{ fontSize: '30px' }}/></Button>
                      <UncontrolledTooltip
                        target={ `Newsletter-template-${ template.id }-delete` }
                        placement="top">{ Lang[ 'DELETE_TEMPLATE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</UncontrolledTooltip>
                    </>
                  }
                </CardHeader>
                <CardImg
                  top
                  src={ _.isEmpty( template.image.url ) ? '/static/assets/images/imagePreview.jpg' : template.image.url }
                  alt="Card image cap"
                  style={{ maxWidth: '100%', padding: '10px', height: "300px", objectFit: 'cover' }} />
                <CardBody className='d-flex flex-column'>
                  <Label size='lg' >{ template.name }</Label>
                  <Label size='md' >{ `${ Lang[ 'CREATED_AT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ]}: ${ Moment( template.created_at ).format('DD/MM/YYYY, h:mm:ss a') } `}</Label>
                </CardBody>
              </Card>}
            )
          }
        </CardDeck>
      </Col>
    </Row>
  )

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'TEMPLATES' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-next icon-gradient bg-happy-itmeo" />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            {
              !this.state.subscribedPackage && (
                <AlertNotSubscribe { ...this.props } />
              )
            }
            {
              _.chunk( 
                _.union( this.props.templates, this.props.ownTemplates ), 
                2 
              ).map( templateList => this.renderTemplateCard( templateList ) )
            }
          </Container>
        </ReactCSSTransitionGroup>
        <TemplateEditor
          contentServices={ this.props.contentServices }
          onChangeTemplatesHOC={ this.props.onChangeTemplatesHOC }
          onLoadTemplatesHOC={ this.props.onLoadTemplatesHOC }
          selectedTemplate={ this.props.selectedTemplate }
          showUpdateTemplate={ this.props.showUpdateTemplate }
          updateTemplate={ this.props.updateTemplate }
          updateOwnTemplate={ this.props.updateOwnTemplate }
          selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language } />
        {
          this.props.showDeleteTemplate && (
            <DeleteConfirmation
               handleModalClose={ () => this.props.onChangeTemplatesHOC( 'showDeleteTemplate', false ) }
               deleteOpen={ this.props.showDeleteTemplate }
               confirmAction={ () => this.props.removeTemplate() }
               content={ Lang[ 'ARE_YOU_SURE_TO_DELETE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
               lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
          )
        }
        { (this.props.onLoadTemplatesHOC || this.props.onLoadContentServices) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithTemplates,
  WithContentServices
)( TemplatesListing )
