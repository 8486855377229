import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { AiOutlineSearch } from 'react-icons/ai'
import { UncontrolledTooltip } from 'reactstrap'

import 'react-image-lightbox/style.css'
import './index.scss'

const ImageComponent = ({
  content
}) => {
  const [ isOpenLightbox, setIsOpenLightbox ] = useState( false )
  const [ isHovered, setIsHovered ] = useState( false )

  return (
    <>
      <div 
        className="enlarge" 
        style={{ 
          padding: content.padding,
          ...content.style,
          textAlign: content.mapWith && content.mapWith.indexOf( 'page' ) > -1 ? "center" : "left",
        }}>
        <div
          onMouseEnter={ () => setIsHovered( true ) }
          onMouseLeave={ () => setIsHovered( false ) }
          style={{ 
            position: 'relative',
            display: 'inline-block',
            width: content.width, 
            height: 'fit-content',
            cursor: content.is_enlarge ? 'zoom-in' : 'auto',
            transformOrigin: '10% 40%',
            transform: (isHovered && content.is_enlarge) ? `scale(${ 1.0 + parseInt(content.enlarge_image_size)/100 })` : "scale(1.0)" 
          }} 
          onClick={ () => setIsOpenLightbox( true ) }>
          <img 
            src={ content.url } 
            style={{ width: '100%', height: 'auto' }}
          />
          {
            content.is_enlarge && (
              <>
                {
                  isHovered ? (
                    <div style={{ position: 'absolute', top: '0', right: '0', backgroundColor: '#00000033', color: '#fff', padding: '2px 4px', cursor: 'pointer' }}>
                      <i className='pe-7s-expand1'></i>
                    </div>
                  ) : (
                    <div style={{ position: 'absolute', top: '0', right: '0', backgroundColor: '#00000033', color: '#fff', padding: '5x' }}>
                      <AiOutlineSearch />{ content.enlarge_image_size }
                    </div>
                  )
                }
              </>
            )
          }
        </div>
        {
          content.showQuickInfo && (
            <>
              <i id={`quick-info-tooltip-${content.id}`} className="pe-7s-info ml-2" style={{ fontSize: '20px', cursor: 'pointer' }} />
              <UncontrolledTooltip
                target={`quick-info-tooltip-${content.id}`}
                placement="top">
                { content.preview_text }
              </UncontrolledTooltip>
            </>
          )
        }
      </div>
      <div>
      {
        content.is_enlarge && isOpenLightbox && (
          <Lightbox
            mainSrc={content.url}
            onCloseRequest={() => setIsOpenLightbox( false ) }
            reactModalStyle={{
              overlay: { zIndex: 3000 }
            }}
          />
        )
      }
      </div>
    </>
  )
}

export default ImageComponent