import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Put } from 'utils/axios-token'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      imprint: {},
      is_assume_responsibility: false
    }

    onChangeImprintHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getImprint = center_id => Get(
      `/api/v1/gdpr/${ center_id }/imprints`,
      this.getImprintSuccess,
      this.getImprintError,
      this.load
    )
    getImprintSuccess = payload => this.setState({ imprint: payload, is_assume_responsibility: payload.is_signed_imprint })
    getImprintError = error => this.requestError( error )

    updateImprint = data => Put(
      `/api/v1/gdpr/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/imprints/${ data.id }`,
      data,
      this.updateImprintSuccess,
      this.updateImprintError,
      this.load
    )
    updateImprintSuccess = () => {
      this.requestSuccess( Lang[ 'IMPRINT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getImprint( this.props.data.selectCenterReducer.selectedCenter.center.id )
    }
    updateImprintError = error => this.requestError( error )

    submitConsent = () => Put(
      `/api/v1/clients/${ this.props.data.selectCenterReducer.selectedCenter.center.client_id }/consent`,
      { is_signed_imprint: true },
      this.submitConsentSuccess,
      this.submitConsentError,
      this.load
    )
    submitConsentSuccess = () => this.getImprint( this.props.data.selectCenterReducer.selectedCenter.center.id )
    submitConsentError = () => {}

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            imprint={ this.state.imprint }
            onChangeImprintHOC={ this.onChangeImprintHOC }
            getImprint={ this.getImprint }
            updateImprint={ this.updateImprint }
            is_assume_responsibility={ this.state.is_assume_responsibility }
            submitConsent={ this.submitConsent }
            onLoadImprint={ this.state.loading } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
