import React, { useRef, useEffect, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { 
  Button, Alert,
  Row, Col, CustomInput, Label, FormGroup, Container,
  Modal, ModalHeader, ModalBody
} from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'

import Datepicker from '../../components/DatePicker'
import MediaDatabase from 'SharedModules/MediaDatabase'
import TemplateSelection from './Templates'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const ArkEmailEditor = props => {
  const emailEditorRef = useRef(null);
  const [ showSelectTemplate, setTemplateModal ] = useState( false )

  useEffect(() => {
    return () => {
      emailEditorRef.current = null
      props.setEmailEditorRef( null )
    }
  }, [])

  useEffect(() => {
    if ( props.newNewsletterData.selectedTemplate && showSelectTemplate ){
      loadInstance()
    }
  }, [ showSelectTemplate, props.newNewsletterData.selectedTemplate ])

  const loadTemplate = ( template ) => emailEditorRef.current.editor.loadDesign ( typeof template === 'string' ? JSON.parse( template ) : template )

  const onLoad = () => {
    if( emailEditorRef.current ) {
      loadInstance()
    } else {
      setTimeout(() => {
        loadInstance()
      }, 3000);
    }
  };

  const loadInstance = () => {
    loadTemplate( props.processType === 'create' ? props.newNewsletterData.selectedTemplate : props.newNewsletterData.json_content )
    Promise.all([
      props.onChangeCreateNewsletter( 'emailEditor', emailEditorRef.current.editor )
    ]).then(() => {
      props.onChangeCreateNewsletter( 'submitButton', true )
    })
    props.setEmailEditorRef( emailEditorRef.current.editor )
  }

  return (
    <Container>
      {
        props.processType === 'create' && (
          <Button 
            color={ 'primary' }
            onClick={ () => setTemplateModal( true )}
          >
            { Lang[ 'CHOOSE_A_TEMPLATE' ][ props.selectedLanguage ] }
          </Button> 
        )
      }
      <Alert color="primary" className="mt-2">
        { Lang[ 'USE_NEWSLETTER_PLACEHOLDER' ][ props.selectedLanguage ] }
        <ul>
          <li>{ '{{ $.recipient_salutation }}' }</li>
          <li>{ '{{ $.recipient_email }}' }</li>
          <li>{ '{{ $.recipient_name }}' }</li>
          <li>{ '{{ $.recipient_first_name }}' }</li>
          <li>{ '{{ $.recipient_last_name }}' }</li>
        </ul>
      </Alert>
      <Row>
        <Col md={12}>
          <EmailEditor
            ref={ emailEditorRef }
            onLoad={ onLoad }
            options={{
              version: 'latest',
              customJS: [ `${ window.location.origin }/custom.js` ],
              locale: props.selectedLanguage,
              features: {
                stockImages: {
                  enabled: false
                }
              },
              tools: {
                image: {
                  properties: {
                    src: {
                      value: {
                        url: 'https://via.placeholder.com/500x100?text=IMAGE',
                        width: 500,
                        height: 100
                      }
                    }
                  }
                },
                'custom#content_service': {
                  data: {
                    options: props.contentServices
                  },
                  properties: {
                    contentServices: {
                      editor: {
                        data: {
                          label: 'Content Services',
                          options: props.contentServices
                        }
                      }
                    },
                    centerNews: {
                      editor: {
                        data: {
                          label: 'News',
                          options: props.centerNews
                        }
                      }
                    }
                  }
                }
              }
            }}
            projectId={ 28706 }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={ 6 }>
          {
            props.processType === 'create' && <>
              <FormGroup className="mt-3">
                <CustomInput
                  id='checkbox-do-save-json'
                  type="checkbox"
                  label={ Lang[ 'SAVE_TEMPLATE' ][ props.selectedLanguage ] }
                  checked={ props.newNewsletterData.do_save_json }
                  onChange={ () => props.onChangeData( 'do_save_json', !props.newNewsletterData.do_save_json ) } />
              </FormGroup>
            </>
          }
          <FormGroup>
            <CustomInput
              id='radio-do-send-now'
              type='radio'
              label={ Lang[ 'SEND_NOW' ][ props.selectedLanguage ] }
              checked={ props.newNewsletterData.do_send_now }
              onChange={ () => props.onChangeData( 'do_send_now' ) } />
            <CustomInput
              id='radio-is-draft'
              type='radio'
              label={ Lang[ 'IS_DRAFT' ][ props.selectedLanguage ] }
              checked={ props.newNewsletterData.is_draft }
              onChange={ () => props.onChangeData( 'is_draft' ) } />
            <CustomInput
              id='radio-is-scheduled'
              type='radio'
              label={ Lang[ 'IS_SCHEDULED' ][ props.selectedLanguage ] }
              checked={ props.newNewsletterData.is_scheduled }
              onChange={ () => props.onChangeData( 'is_scheduled' ) } />
          </FormGroup>
          {
            props.newNewsletterData.is_scheduled &&
              <FormGroup>
                <Label>{ Lang[ 'DELIVER_AT' ][ props.selectedLanguage ] }</Label>
                <Datepicker
                  locale={ props.selectedLanguage }
                  showTimeSelect
                  selectedDate={ props.newNewsletterData.delivered_at.toDate() }
                  dateFormat='dd/MM/yyyy, hh:mm a'
                  onChange={ e => props.onChangeData( 'delivered_at',  Moment( e ) ) }/>
              </FormGroup>
          }
        </Col>
      </Row>
      <MediaDatabase
        emailEditorRef={ emailEditorRef }
        onSelectedImage={ () => props.toggleMediaDatabase( false ) }
        selectedLanguage={ props.selectedLanguage }
        allowed={[ 'jpg', 'jpeg', 'png' ]} 
        toggleMediaDatabase={ props.toggleMediaDatabase }
      />
      <Modal size={ 'xl' } isOpen={ showSelectTemplate }>
        <ModalHeader toggle={ () => setTemplateModal( false )}>
          { Lang[ 'SELECT_A_TEMPLATE' ][ props.selectedLanguage ] }
        </ModalHeader>
        <ModalBody>
          <TemplateSelection  
            onChangeData={ props.onChangeData }
            newNewsletterData={ props.newNewsletterData }
            templates={ props.templates }
            getSelectedTemplate={ id => { 
              Promise.all([
                props.getSelectedTemplate( id )
              ]).then(() => {
                setTemplateModal( false )
              })
            }}
            selectedLanguage={ props.selectedLanguage } />
          { props.onLoadNewsletters && <LoadingOverlay/> }
        </ModalBody>
      </Modal>
    </Container>
  )
}

export default ArkEmailEditor
