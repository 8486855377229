import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      articles: [],
      selectedArticle: {},
      showViewArticle: false,
      articleTotalPages: []
    }

    onChangeArticleFourteenHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getAllArticles = query => Get(
      `/api/v1/gdpr/article_fourteens?query=${ convertObjToBase64( query ) }`,
      this.getAllArticlesSuccess,
      this.getAllArticlesError,
      this.load
    )
    getAllArticlesSuccess = payload => {
      let tmpTotalPages = []
      if ( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ articles: payload, articleTotalPages: tmpTotalPages })
    }
    getAllArticlesError = error => this.requestError( error )

    getSelectedArticle = id => Get(
      `/api/v1/gdpr/article_fourteens/${ id }`,
      this.getSelectedArticleSuccess,
      this.getSelectedArticleError,
      this.load
    )
    getSelectedArticleSuccess = payload => this.setState({ selectedArticle: payload, showViewArticle: true })
    getSelectedArticleError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadArticles={ this.state.loading }
            articles={ this.state.articles }
            selectedArticle={ this.state.selectedArticle }
            showViewArticle={ this.state.showViewArticle }
            onChangeArticleFourteenHOC={ this.onChangeArticleFourteenHOC }
            articleTotalPages={ this.state.articleTotalPages }
            getAllArticles={ this.getAllArticles }
            getSelectedArticle={ this.getSelectedArticle } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
