import React, { useEffect } from 'react'
import {
  Container, Row, Col, Button,
  Card, CardHeader, CardBody,
  FormGroup, Input, Label
} from 'reactstrap'
import Moment from 'moment'
import ReactHTMLParser from 'react-html-parser'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import AVContractHOC from './actions'

import Lang from 'Lang/General'

const AVContract = props => {

  useEffect( () => {
    if ( !_.isEmpty( props.data.selectCenterReducer.selectedCenter ) ) {
      props.getAVContract( props.data.selectCenterReducer.selectedCenter.center.client_id ) 
    }
  }, [ props.data.selectCenterReducer.selectedCenter ] )

  return (
    <>
      <PageTitle
        heading={ Lang[ 'AV_CONTRACT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-copy-file icon-gradient bg-happy-itmeo"
        buttons={[
          {
            buttonText: Lang[ 'DOWNLOAD_AV_CONTRACT' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            onClick: () => props.contract.is_new_contract ? props.downloadContractPDF( props.contract.id, props.contract ) : window.location.href = props.contract.contract_pdf_url,
            display: true
          }
        ]} />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          <Row>
            <Col md={ 12 }>
              {
                !_.isEmpty( props.contract ) && (
                  <Card className="main-card mb-3">
                    {
                      props.contract.agreed_datetime !== null && 
                        <CardHeader>
                          { Lang[ 'AGREED_AT' ][ props.data.languageReducer.userSettings.admin_panel_language ] } { Moment( props.contract.agreed_datetime ).format( 'DD.MM.YYYY' ) }
                        </CardHeader>
                    }
                    <CardBody>
                      {
                        props.contract.is_new_contract ? (
                          <div className="scroll-area-lg">{ ReactHTMLParser( props.contract.gdpr_template.html ) }</div>
                        ) : (
                          <iframe width="100%" height="800" frameborder="0" src={ `http://docs.google.com/gview?url=${ props.contract.contract_pdf_url }&embedded=true` }></iframe>
                        )
                      }
                      {
                        !_.isEmpty( props.data.selectCenterReducer.selectedCenter ) && 
                        props.data.profileReducer.profile.id === props.data.selectCenterReducer.selectedCenter.center.client.user_id && 
                        !props.contract.is_agreed && (
                          <>
                            <FormGroup check className="mt-4">
                              <Input
                                type="checkbox"
                                checked={ props.is_agreed }
                                onChange={ e => props.onChangeAVContractHOC( 'is_agreed', e.target.checked ) } />
                              <Label className="ml-2">{ Lang[ 'CONTRACT_CONSENT_STATEMENT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                            </FormGroup>
                            <Button
                              disabled={ !props.is_agreed }
                              className="float-right"
                              color='primary'
                              onClick={ () => {
                                let tmp = _.cloneDeep( props.contract )
                                tmp.is_agreed = props.is_agreed
                                tmp.agreed_datetime = Moment( new Date() ).format()
                                props.updateAVContract( props.contract.id, tmp )
                              }}>
                              { Lang[ 'SUBMIT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                            </Button>
                          </>
                        ) 
                      }
                    </CardBody>
                  </Card>
                )
              }
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
      { props.onLoadAVContract && <LoadingOverlay /> }
    </>
  )
}

export default AVContractHOC( AVContract )
