const Lang = {
  CREATE_BLACKLISTED_SUCCESS: {
    "en": "Blacklisted recipient created successfully.",
    "de": "Empfänger auf der schwarzen Liste erfolgreich erstellt.",
    "it": "Destinatario nella lista nera creato con successo.",
    "nl": "Op de zwarte lijst geplaatste ontvanger is succesvol gemaakt."
  },
  DELETE_BLACKLISTED_SUCCESS: {
    "en": "Blacklisted recipient deleted successfully.",
    "de": "Empfänger auf der schwarzen Liste erfolgreich gelöscht.",
    "it": "Il destinatario nella lista nera è stato eliminato correttamente.",
    "nl": "Op de zwarte lijst geplaatste ontvanger is verwijderd."
  },
  CREATE_BLACKLISTED_FAILED: {
    "en": "Blacklisted recipient created failed.",
    "de": "Empfänger auf der schwarzen Liste erstellt fehlgeschlagen.",
    "it": "Il destinatario nella lista nera creato non è riuscito.",
    "nl": "Op de zwarte lijst geplaatste ontvanger is mislukt."
  },
  CREATE_INACTIVE_SUCCESS: {
    "en": "Recipient is created successfully.",
    "de": "Empfänger wird erfolgreich angelegt.",
    "it": "Ontvanger is succesvol aangemaakt.",
    "nl": "De inactieve ontvanger is succesvol aangemaakt."
  },
  CREATE_INACTIVE_FAILED: {
    "en": "Recipient created failed.",
    "de": "Die Erstellung des Empfängers ist fehlgeschlagen.",
    "it": "Destinatario creato non riuscito.",
    "nl": "Ontvanger aangemaakt mislukt."
  },
  UPDATE_INACTIVE_SUCCESS: {
    "en": "Inactive recipient is updated successfully.",
    "de": "Inaktiver Empfänger wurde erfolgreich aktualisiert.",
    "it": "Il destinatario inattivo viene aggiornato correttamente.",
    "nl": "De inactieve ontvanger is succesvol bijgewerkt."
  },
  DELETE_INACTIVE_SUCCESS: {
    "en": "Inactive recipient is deleted successfully.",
    "de": "Inaktiver Empfänger wird erfolgreich gelöscht.",
    "it": "Il destinatario inattivo viene eliminato correttamente.",
    "nl": "De inactieve ontvanger is succesvol verwijderd."
  },
  CREATE_ACTIVE_SUCCESS: {
    "en": "Recipient is created successfully.",
    "de": "Empfänger wird erfolgreich angelegt.",
    "it": "Ontvanger is succesvol aangemaakt.",
    "nl": "De inactieve ontvanger is succesvol aangemaakt."
  },
  CREATE_ACTIVE_FAILED: {
    "en": "Recipient created failed.",
    "de": "Die Erstellung des Empfängers ist fehlgeschlagen.",
    "it": "Destinatario creato non riuscito.",
    "nl": "Ontvanger aangemaakt mislukt."
  },
  UPDATE_ACTIVE_SUCCESS: {
    "en": "Active recipient is updated successfully.",
    "de": "Aktiver Empfänger wird erfolgreich aktualisiert.",
    "it": "Il destinatario attivo viene aggiornato correttamente.",
    "nl": "De actieve ontvanger is succesvol bijgewerkt."
  },
  DELETE_ACTIVE_SUCCESS: {
    "en": "Active recipient is deleted successfully.",
    "de": "Aktiver Empfänger wird erfolgreich gelöscht.",
    "it": "Il destinatario attivo viene eliminato correttamente.",
    "nl": "De actieve ontvanger is succesvol verwijderd."
  },
  NEWSLETTER_SUBSCRIPTION_FAILED:{
    en: 'Newsletter subscription failed',
    de: 'Newsletter-Anmeldung fehlgeschlagen',
    it: 'Iscrizione alla newsletter fallita',
    nl: 'Nieuwsbrief aanmelding mislukt'
  },
  CONFIRMATION_EMAIL_FOR_NEWSLETTER_SUBSCRIPTION_HAS_BEEN_SENT:{
    en: 'Confirmation e-mail for newsletter subscription has been sent',
    de: 'Bestätigungs-E-Mail für die Newsletter-Anmeldung wurde versendet',
    it: 'L\'e-mail di conferma dell\'iscrizione alla newsletter è stata inviata',
    nl: 'Bevestigingse-mail voor inschrijving op de nieuwsbrief is verzonden',
  }
}

export default Lang
