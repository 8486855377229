import React, { useState } from 'react'
import {
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label,
  Button,
  FormFeedback
} from 'reactstrap'
import _ from 'lodash'
import { toast } from 'react-toastify'

import Lang from 'Lang/General'

const AdditionalInfo = ({
  additionalInfo,
  onChangeAdditionalInfoHOC,
  selectedLanguage
}) => {

  const [ products, updateProducts ] = useState( '' )
  const [ services, updateServices ] = useState( '' )
  const [ specialFields, updateSpecialFields ] = useState( '' )
  const [ memberships, updateMemberships ] = useState( '' )
  const [ trademarks, updateTrademarks ] = useState( '' )
  const [ languages, updateLanguages ] = useState( '' )
  const [ errorMessage, updateErrorMessage ] = useState('')

  const updateAdditionalInfo = async ( key, val ) => {
    let tmp = _.cloneDeep( additionalInfo )
    tmp[ key ] = val
    onChangeAdditionalInfoHOC( 'additionalInfo', tmp )
  }

  return(
    <Container>
      <Row>
        <Col md={ 10 }>
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'DATA_PRIVACY_URL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'DATA_PRIVACY_URL' ][ selectedLanguage ] }
                value={ additionalInfo.data_privacy_url }
                onChange={ e => updateAdditionalInfo( 'data_privacy_url', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'IMPRINT_URL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'IMPRINT_URL' ][ selectedLanguage ] }
                value={ additionalInfo.imprint_url }
                onChange={ e => updateAdditionalInfo( 'imprint_url', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'ADDITIONAL_INFO_DESCRIPTION' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'ADDITIONAL_INFO_DESCRIPTION' ][ selectedLanguage ] }
                value={ additionalInfo.description }
                onChange={ e => updateAdditionalInfo( 'description', e.target.value ) } />
            </FormGroup>
            <Label>{ Lang[ 'PRODUCTS' ][ selectedLanguage ] }</Label>
            <div className="d-flex">
              <Input
                type='text'
                placeholder={ Lang[ 'PRODUCTS' ][ selectedLanguage ] }
                value={ products }
                onChange={ e => updateProducts( e.target.value ) } />
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if( products.length > 0 ){
                    let tmp = _.cloneDeep( additionalInfo.products !== null ? additionalInfo.products : [] )
                    tmp.push( products )
                    updateAdditionalInfo( 'products', tmp )
                    updateProducts( '' )
                  } else {
                    toast.error( 'Cannot create empty value of Products' )
                  }
                }}>{ Lang[ 'CREATE' ][ selectedLanguage ] }
              </Button>
            </div>

            <div className="d-flex flex-wrap" style={{ marginBottom: 10 }}>
              {
                additionalInfo.products && additionalInfo.products.length > 0 && additionalInfo.products.map( ( item,index ) => {
                  return (
                    <div style={{ borderStyle: 'groove', margin: '10px 10px 0 0', padding: '0 8px' }}>
                      <Label>{ item } </Label>
                        <span
                          style={{ color: 'red', marginLeft: 10 }}
                          onClick={() => {
                            let tmp = _.cloneDeep( additionalInfo.products )
                            tmp.splice( index, 1 )
                            updateAdditionalInfo( 'products', tmp )
                          }}>x
                        </span>
                    </div>
                  )
                })
              }
            </div>

            <Label>{ Lang[ 'SERVICES' ][ selectedLanguage ] }</Label>
            <div className="d-flex">
              <Input
                type='text'
                placeholder={ Lang[ 'SERVICES' ][ selectedLanguage ] }
                value={ services }
                onChange={ e => updateServices( e.target.value ) } />
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if( services.length > 0 ){
                    let tmp = _.cloneDeep( additionalInfo.services !== null ? additionalInfo.services : [] )
                    tmp.push( services )
                    updateAdditionalInfo( 'services', tmp )
                    updateServices( '' )
                  } else {
                    toast.error( 'Cannot create empty value of Services' )
                  }
                }}>{ Lang[ 'CREATE' ][ selectedLanguage ] }
              </Button>
            </div>

            <div className="d-flex flex-wrap" style={{ marginBottom: 10 }}>
              {
                additionalInfo.services && additionalInfo.services.length > 0 && additionalInfo.services.map( ( item,index ) => {
                  return (
                    <div style={{ borderStyle: 'groove', margin: '10px 10px 0 0', padding: '0 8px' }}>
                      <Label>{ item } </Label>
                        <span
                          style={{ color: 'red', marginLeft: 10 }}
                          onClick={() => {
                            let tmp = _.cloneDeep( additionalInfo.services )
                            tmp.splice( index, 1 )
                            updateAdditionalInfo( 'services', tmp )
                          }}>x
                        </span>
                    </div>
                  )
                })
              }
            </div>

            <Label>{ Lang[ 'SPECIAL_FIELDS' ][ selectedLanguage ] }</Label>
            <div className="d-flex">
              <Input
                type='text'
                placeholder={ Lang[ 'SPECIAL_FIELDS' ][ selectedLanguage ] }
                value={ specialFields }
                onChange={ e => updateSpecialFields( e.target.value ) } />
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if( specialFields.length > 0 ){
                    let tmp = _.cloneDeep( additionalInfo.special_fields !== null ? additionalInfo.special_fields : [] )
                    tmp.push( specialFields )
                    updateAdditionalInfo( 'special_fields', tmp )
                    updateSpecialFields( '' )
                  } else{
                    toast.error( 'Cannot create empty value of Special Fields')
                  }
                }}>{ Lang[ 'CREATE' ][ selectedLanguage ] }
              </Button>
            </div>

            <div className="d-flex flex-wrap" style={{ marginBottom: 10 }}>
              {
                additionalInfo.special_fields && additionalInfo.special_fields.length > 0 && additionalInfo.special_fields.map( ( item,index ) => {
                  return (
                    <div style={{ borderStyle: 'groove', margin: '10px 10px 0 0', padding: '0 8px' }}>
                      <Label>{ item } </Label>
                        <span
                          style={{ color: 'red', marginLeft: 10 }}
                          onClick={() => {
                            let tmp = _.cloneDeep( additionalInfo.special_fields )
                            tmp.splice( index, 1 )
                            updateAdditionalInfo( 'special_fields', tmp )
                          }}>x
                        </span>
                    </div>
                  )
                })
              }
            </div>
            <Label>{ Lang[ 'MEMBERSHIPS' ][ selectedLanguage ] }</Label>
            <div className="d-flex">
              <Input
                type='text'
                placeholder={ Lang[ 'MEMBERSHIPS' ][ selectedLanguage ] }
                value={ memberships }
                onChange={ e => updateMemberships( e.target.value ) } />
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if( memberships.length > 0 ){
                    let tmp = _.cloneDeep( additionalInfo.memberships !== null ? additionalInfo.memberships : [] )
                    tmp.push( memberships )
                    updateAdditionalInfo( 'memberships', tmp )
                    updateMemberships( '' )
                  } else {
                    toast.error( 'Cannot create empty value of Memberships' )
                  }
                }}>{ Lang[ 'CREATE' ][ selectedLanguage ] }
              </Button>
            </div>

            <div className="d-flex flex-wrap" style={{ marginBottom: 10 }}>
              {
                additionalInfo.memberships && additionalInfo.memberships.length > 0 && additionalInfo.memberships.map( ( item,index ) => {
                  return (
                    <div style={{ borderStyle: 'groove', margin: '10px 10px 0 0', padding: '0 8px' }}>
                      <Label>{ item } </Label>
                        <span
                          style={{ color: 'red', marginLeft: 10 }}
                          onClick={() => {
                            let tmp = _.cloneDeep( additionalInfo.memberships )
                            tmp.splice( index, 1 )
                            updateAdditionalInfo( 'memberships', tmp )
                          }}>x
                        </span>
                    </div>
                  )
                })
              }
            </div>
            <Label>{ Lang[ 'OFFERED_BRANDS' ][ selectedLanguage ] }</Label>
            <div className="d-flex">
              <Input
                type='text'
                placeholder={ Lang[ 'OFFERED_BRANDS' ][ selectedLanguage ] }
                value={ trademarks }
                onChange={ e => updateTrademarks( e.target.value ) } />
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if( trademarks.length > 0 ){
                    let tmp = _.cloneDeep( additionalInfo.trademarks !== null ? additionalInfo.trademarks : [] )
                    tmp.push( trademarks )
                    updateAdditionalInfo( 'trademarks', tmp )
                    updateTrademarks( '' )
                  } else{
                    toast.error( 'Cannot create empty value of Offered Brands' )
                  }
                }}>{ Lang[ 'CREATE' ][ selectedLanguage ] }
              </Button>
            </div>

            <div className="d-flex flex-wrap" style={{ marginBottom: 10 }}>
              {
                additionalInfo.trademarks && additionalInfo.trademarks.length > 0 && additionalInfo.trademarks.map( ( item,index ) => {
                  return (
                    <div style={{ borderStyle: 'groove', margin: '10px 10px 0 0', padding: '0 8px' }}>
                      <Label>{ item } </Label>
                        <span
                          style={{ color: 'red', marginLeft: 10 }}
                          onClick={() => {
                            let tmp = _.cloneDeep( additionalInfo.trademarks )
                            tmp.splice( index, 1 )
                            updateAdditionalInfo( 'trademarks', tmp )
                          }}>x
                        </span>
                    </div>
                  )
                })
              }
            </div>
            <Label>{ Lang[ 'LANGUAGES' ][ selectedLanguage ] }</Label>
            <div className="d-flex">
              <Input
                type='text'
                placeholder={ Lang[ 'LANGUAGES' ][ selectedLanguage ] }
                value={ languages }
                onChange={ e => updateLanguages( e.target.value ) } />
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if( languages.length > 0 ){
                    let tmp = _.cloneDeep( additionalInfo.languages !== null ? additionalInfo.languages : [] )
                    tmp.push( languages )
                    updateAdditionalInfo( 'languages', tmp )
                    updateLanguages( '' )
                  }else{
                    toast.error( 'Cannot create empty value of Languages' )
                  }
                }}>{ Lang[ 'CREATE' ][ selectedLanguage ] }
              </Button>
            </div>

            <div className="d-flex flex-wrap" style={{ marginBottom: 10 }}>
              {
                additionalInfo.languages && additionalInfo.languages.length > 0 && additionalInfo.languages.map( ( item,index ) => {
                  return (
                    <div style={{ borderStyle: 'groove', margin: '10px 10px 0 0', padding: '0 8px' }}>
                      <Label>{ item } </Label>
                        <span
                          style={{ color: 'red', marginLeft: 10 }}
                          onClick={() => {
                            let tmp = _.cloneDeep( additionalInfo.languages )
                            tmp.splice( index, 1 )
                            updateAdditionalInfo( 'languages', tmp )
                          }}>x
                        </span>
                    </div>
                  )
                })
              }
            </div>
            <FormGroup>
              <Label>{ Lang[ 'FOUNDING_YEAR' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'FOUNDING_YEAR' ][ selectedLanguage ] }
                value={ additionalInfo.year_of_foundation }
                onChange={ e => updateAdditionalInfo( 'year_of_foundation', e.target.value ) } />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default AdditionalInfo
