import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container, Row, Col,
  Card, CardHeader, CardBody, CardFooter,
  Form, FormGroup, FormFeedback,
  Input, Label, Button, Alert, CustomInput
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import ReactSelect from 'react-select'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import ProductInfo from '../info'

import WithOLSSettings from './actions'
import WithConsultants from './actions/Consultants'
import WithUser from '../Campaigns/actions/User'
import WithProductRequest from '../actions'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'
import LangTepe from 'Lang/TePe'

let TmpLang = window.location.host.indexOf( 'tennis' ) > -1 ? LangTepe : Lang

class OLSSettings extends Component {
  componentDidMount = async() => {
    await this.props.getSelectedUser( this.props.data.profileReducer.profile.id )
    this.props.getConsultants( convertSearchObjectToBase64({
      is_paginated: false,
      consultant: {
        filter: {
          $or: [
            { status: 'ACTIVE' }
          ]
        }
      }
    }))

    if( this.props.data.roleReducer.role.name === 'OLS One Timer' ){
      this.props.getOLSSettings( true )
    } else {
      if( this.props.selectedUser.centers && this.props.selectedUser.centers.length > 0 ){
        this.props.onChangeOLSSettingsHOC( 'settingsCenterId', this.props.data.selectCenterReducer.selectedCenter.center.id )
      }
    }
    this.props.getCampaigns( convertSearchObjectToBase64({
      is_paginated: false
    }))
    this.props.getAllProductRequests()
    this.props.getProducts()
    this.props.getRequestedProduct()

    if ( !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) ) {
      let tmp = _.cloneDeep( this.props.newOLSSettings )
      let center = this.props.data.selectCenterReducer.selectedCenter.center
      tmp.studio_name = center.center_name
      tmp.contact_person = center.first_name
      tmp.contact_email = center.email
      if ( center.locations.length > 0 ) {
        tmp.road = center.locations[ 0 ].street
        tmp.postcode = center.locations[ 0 ].postcode
        tmp.contact_telephone = center.locations[ 0 ].phone_number
      }
      this.props.onChangeOLSSettingsHOC( 'newOLSSettings', tmp )
    }
  }

  onChange = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.newOLSSettings )
    tmp[ key ] = value
    return this.props.onChangeOLSSettingsHOC( 'newOLSSettings', tmp )
  }

  render = () => {
    let tmp = this.props.data.selectCenterReducer.selectedCenter.center && _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'OLS' } ) > -1
    let tmpProduct = _.find( this.props.products, x => x.name.en === 'OLS' )
    let tmpConsultant = !this.props.hasOLSSettings ? this.props.newOLSSettings.consultant : _.find( this.props.consultants, { id: this.props.settings.consultant_id } )
    return (
      <>
        {
          this.props.data.selectCenterReducer.selectedCenter.center && _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'OLS' } ) < 0 && (
            <div className="mb-5">
              <img src={ '' } style={{ width: '100%', marginBottom: '15px'}}/>
              <Alert color="info">
                { Lang[ 'BANNER_MESSAGE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                {
                  tmpProduct && (
                    <Button 
                      style={{
                        border: 'none',
                        boxShadow: 'none'
                      }}
                      color="link"
                      onClick={ () => {
                        this.props.onChangeProductRequestsHOC( 'showRequestInfoModal', true )
                        this.props.getSelectedProduct( tmpProduct.id )
                      }}>{ Lang[ 'CLICK_HERE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                  )
                }
              </Alert>
            </div>
          )
        }
        <PageTitle
          heading={ Lang[ 'OLS_SETTING_HEADING' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          subheading={ Lang[ 'OLS_SETTING_SUBHEADING' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-tools icon-gradient bg-happy-itmeo" />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            <Row>
              <Col md={ 12 }>
                <Form>
                  <Card className="main-card mb-3">
                    <CardHeader>
                      { Lang[ 'WATCH_OUT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label>{ Lang[ 'IS_PURE_WOMAN_STUDIO' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <div className='d-flex flex-row'>
                          <CustomInput
                            id='is-pure-woman-studio'
                            type='radio'
                            className='mr-2'
                            label={ Lang[ 'YES' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                            checked={ !this.props.hasOLSSettings ? this.props.newOLSSettings.is_pure_woman_studio : this.props.settings.is_pure_woman_studio }
                            onChange={ () => this.onChange( 'is_pure_woman_studio', true ) }
                            disabled={ this.props.hasOLSSettings || !tmp } />
                          <CustomInput
                            id='not-pure-woman-studio'
                            type='radio'
                            label={ Lang[ 'NO' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                            checked={ !this.props.hasOLSSettings ? !this.props.newOLSSettings.is_pure_woman_studio : !this.props.settings.is_pure_woman_studio }
                            onChange={ () => this.onChange( 'is_pure_woman_studio', false ) }
                            disabled={ this.props.hasOLSSettings || !tmp } />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'STUDIO_NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'text' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.studio_name : this.props.settings.studio_name }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'studio_name', e.target.value ) }
                          invalid={ this.props.olsSettingsError.studio_name }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.studio_name }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'ROAD' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'text' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.road : this.props.settings.road }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'road', e.target.value ) }
                          invalid={ this.props.olsSettingsError.road }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.road }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'POSTCODE_OR_TOWN' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'text' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.postcode : this.props.settings.postcode }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => /[0-9]/g.test( e.target.value ) 
                            ? this.onChange( 'postcode', e.target.value )
                            : this.onChange( 'postcode', '' ) }
                          invalid={ this.props.olsSettingsError.postcode }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.postcode }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'CONTACT_PERSON' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'text' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.contact_person : this.props.settings.contact_person }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'contact_person', e.target.value ) }
                          invalid={ this.props.olsSettingsError.contact_person }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.contact_person }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'EMAIL_CONTACT_PERSON' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'email' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.contact_email : this.props.settings.contact_email }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'contact_email', e.target.value ) }
                          invalid={ this.props.olsSettingsError.contact_email }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.contact_email }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'TELEPHONE_CONTACT_PERSON' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'text' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.contact_telephone : this.props.settings.contact_telephone }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'contact_telephone', e.target.value ) }
                          invalid={ this.props.olsSettingsError.contact_telephone }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.contact_telephone }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'MY_PERSONAL_ADVISOR' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <ReactSelect
                          name='select'
                          className='basic-multi-select'
                          classNamePrefix='select'
                          options={ this.props.consultants }
                          onChange={ value => this.onChange( 'consultant', value ) }
                          value={ tmpConsultant ? tmpConsultant : {} }
                          isDisabled={ this.props.hasOLSSettings || !tmp } />
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'SEND_LEAD_EMAIL_ADDRESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'email' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.lead_receive_email_address : this.props.settings.lead_receive_email_address }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'lead_receive_email_address', e.target.value ) }
                          invalid={ this.props.olsSettingsError.lead_receive_email_address }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.lead_receive_email_address }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'FB_LINK' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'text' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.facebook_url : this.props.settings.facebook_url }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'facebook_url', e.target.value ) }
                          invalid={ this.props.olsSettingsError.facebook_url }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.facebook_url }</FormFeedback>
                      </FormGroup>
                      {/*<FormGroup>
                        <Label>Which campaign do you want to run? <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'select' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.campaign_id : this.props.settings.campaign_id }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'campaign_id', parseInt( e.target.value ) ) }
                          required >
                          <option>{ 'Please select one from below' }</option>
                          {
                            this.props.campaigns.map( item => {
                              return (
                                <option key={ item.id } value={ item.id }>{ item.title }</option>
                              )
                            })
                          }
                        </Input>
                      </FormGroup>*/}
                      <FormGroup>
                        <Label>{ Lang[ 'DATA_PROTECTION_LINK' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <Input
                          type={ 'text' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.data_protection_url : this.props.settings.data_protection_url }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'data_protection_url', e.target.value ) }
                          invalid={ this.props.olsSettingsError.data_protection_url }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.data_protection_url }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          { Lang[ 'FB_MONTHLY_CLICK_BUDGET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                          <span style={{ color: 'red' }}>*</span>
                        </Label>
                        <Input
                          type={ 'number' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.monthly_fb_click_through_budget : this.props.settings.monthly_fb_click_through_budget }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'monthly_fb_click_through_budget', parseInt( e.target.value, 10 ) ) }
                          invalid={ this.props.olsSettingsError.monthly_fb_click_through_budget }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.monthly_fb_click_through_budget }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          { Lang[ 'BEST_PLACE_NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                          <span style={{ color: 'red' }}>*</span>
                        </Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={ !this.props.hasOLSSettings ? this.props.newOLSSettings.is_place_name_standard : this.props.settings.is_place_name_standard }
                            disabled={ this.props.hasOLSSettings || !tmp }
                            onChange={ e => this.onChange( 'is_place_name_standard', e.target.checked ) } />
                          { Lang[ 'STANDARD_PLACE_NAME_SURROUNDINGS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          { Lang[ 'FB_ADVERTISING_ACCOUNT_ID' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                          <span style={{ color: 'red' }}>*</span>
                        </Label>
                        <Input
                          type={ 'text' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.fb_advertising_account_id : this.props.settings.fb_advertising_account_id }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'fb_advertising_account_id', e.target.value ) }
                          invalid={ this.props.olsSettingsError.fb_advertising_account_id }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.fb_advertising_account_id }</FormFeedback>
                        <div className='d-flex flex-column mb-2 font-italic'>
                          <div>{ Lang[ 'CHECK_FB_ADVERTISING_ACCOUNT_ID' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</div>
                          <a href='https://www.facebook.com/ads/manager/accounts' target='_blank'>Werbeanzeigenmanager: Werbekonten (facebook.com)</a>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          { Lang[ 'MONTHLY_CLICK_GOOGLE_ADWORDS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                          <span style={{ color: 'red' }}>*</span>
                        </Label>
                        <Input
                          type={ 'number' }
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.monthly_google_budget : this.props.settings.monthly_google_budget }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'monthly_google_budget', parseInt( e.target.value, 10 ) ) }
                          invalid={ this.props.olsSettingsError.monthly_google_budget }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.monthly_google_budget }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'GOOGLE_ADS_CUSTOMER_NUMBER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                        <p>{ Lang[ 'FIND_GOOGLE_ACCOUNT_TOP_RIGHT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</p>
                        <Input
                          type="text"
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.google_ads_customer_number : this.props.settings.google_ads_customer_number }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'google_ads_customer_number', e.target.value ) }
                          invalid={ this.props.olsSettingsError.google_ads_customer_number }
                          required />
                        <FormFeedback>{ this.props.olsSettingsError.google_ads_customer_number }</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'COMMENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                        <Input
                          type="text"
                          value={ !this.props.hasOLSSettings ? this.props.newOLSSettings.comment : this.props.settings.comment }
                          disabled={ this.props.hasOLSSettings || !tmp }
                          onChange={ e => this.onChange( 'comment', e.target.value ) } />
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'CONSENT_DATA_PROCESSING' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red' }}>*</span></Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={ !this.props.hasOLSSettings ? this.props.newOLSSettings.is_consent_to_data_processing : this.props.settings.is_consent_to_data_processing }
                            disabled={ this.props.hasOLSSettings || !tmp }
                            onChange={ e => this.onChange( 'is_consent_to_data_processing', e.target.checked ) } />
                          { Lang[ 'YES_CONSENT_STATEMENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                        </FormGroup>
                      </FormGroup>
                    </CardBody>
                    {
                      !this.props.hasOLSSettings && (
                        <CardFooter>
                          <Button
                            color="primary"
                            className="ml-auto"
                            disabled={ this.props.hasOLSSettings || !tmp }
                            onClick={ () => this.props.onChangeOLSSettingsHOC( 'showPromptModal', true ) }>
                            { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                        </CardFooter>
                      )
                    }
                    <PromptModal
                      showPromptModal={ this.props.showPromptModal }
                      onClickYes={() => {
                        let tmp = _.cloneDeep( this.props.newOLSSettings )
                        tmp.consultant_id = tmp.consultant.id
                        this.props.createOLSSettings( tmp ) 
                      }}
                      onClickNo={() => this.props.onChangeOLSSettingsHOC( 'showPromptModal', false )}
                      content={ Lang[ 'CONFIRMATION_MESSAGE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                      lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
          <ProductInfo 
            data={ this.props.data }
            selectedProduct={ this.props.selectedProduct }
            showRequestInfoModal={ this.props.showRequestInfoModal }
            onChangeProductRequestsHOC={ this.props.onChangeProductRequestsHOC }
            createProductsRequest={ this.props.createProductsRequest }
            newRequest={ this.props.newRequest }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
        </ReactCSSTransitionGroup>
        { this.props.onLoadSettings && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithOLSSettings,
  WithConsultants,
  WithUser,
  WithProductRequest
)( OLSSettings )
