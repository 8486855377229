import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button } from 'reactstrap'

import RenderDOM from '../RenderDOM'
import Pagination from './Pagination'
import Lang from 'Lang/General'

const FormFrame = ({
  content,
  onChangeFormBuilderState,
  mode,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  removeSubmittedFormMedia,
  uploadedMedia,
  currentPage,
  dom,
  findObjectAndPatch,
  togglePageSelection,
  data,
  currentLanguage
}) => {
  const [ formCompleted, updateFormCompleted ] = useState( false )
  const [ pageErrors, updatePageErrors ] = useState( [] )

  useEffect( () => {
    if( currentPage > 0 && dom && dom[ 0 ].children[ currentPage - 1 ] ){
      let tmpSummaryPage = _.cloneDeep( dom[ 0 ].children[ currentPage - 1 ] )
      if( tmpSummaryPage.mapWith && tmpSummaryPage.mapWith === 'summary' ){
        let tmpDOM = _.cloneDeep( dom )
        let isFormCompleted = true

        let tmpNonTemplatesPages = tmpDOM[ 0 ].children.filter( page => !page.mapWith || ( page.mapWith && page.mapWith === 'form' ) )
       
        tmpNonTemplatesPages.map( page => {
          if ( page.selectable && !page.selected ) {
            page[ 'completionStatus' ] = "NOT_ORDERED"
          } else {
            if( isRequiredFieldsFilled( page ) ) {
              page[ 'completionStatus' ] = 'COMPLETED'
            } else {
              page[ 'completionStatus' ] = 'INCOMPLETE'
              isFormCompleted = false
            }
          }
        })

        updateFormCompleted( isFormCompleted )
        onChangeFormBuilderState( 'dom', tmpDOM )
      }
    }
    document.querySelector( '#form-frame' ).scrollIntoView({ behavior: 'smooth' })
  }, [ currentPage ] )

  const isRequiredFieldsFilled = ( currentPageItem, isPageCheck ) => {
    if ( 
      !currentPageItem.selectable || 
      ( currentPageItem.selectable && currentPageItem.selected ) 
    ) {
      let tmpErrors = _.filter( formValues, formVal => { 
        if ( 
          formVal.page === currentPageItem.page &&
          formVal.validation && 
          formVal.isRequired && 
          ( formVal.isDependentFieldRequired || formVal.isDependentFieldRequired === undefined ) 
        ) {
          if ( formVal.type === 'dateField' && formVal.value === 'Invalid date' ) {
            return true
          } else if ( formVal.requiredSelections?.length > 0 ) {
            return ( 
              _.isEmpty( formVal.value ) || 
              formVal.requiredSelections.indexOf( '' ) > -1 ||
              _.difference( formVal.requiredSelections, formVal.value ).length !== 0 
            )
          } else if ( formVal.type !== 'inputFile' ) {
            return _.isEmpty( formVal.value ) 
          } else {
            return uploadedMedia.filter( x => x.formContentId === formVal.id ).length <= 0
          }
        } else {
          return false
        }
      })
      if( tmpErrors.length > 0 ){
        isPageCheck && updatePageErrors( tmpErrors )
        return false
      }
    }
    isPageCheck && updatePageErrors( [] )
    return true
  }

  const renderPagination = ( showPageName ) => {
    return (
      <div className="d-flex flex-row flex-wrap align-items-center justify-content-end float-right mt-4 " >
        <Pagination
          currentPage={ currentPage }
          pages={ dom[0].children }
          totalPages={ Array.from({length: content.totalPages}, (_, i) => i + 1) }
          onChangePage={ (page) => { 
            if (
              page < currentPage || 
              ( page > currentPage && isRequiredFieldsFilled( dom[ 0 ].children[ currentPage - 1 ], true ) )
            ) {
              onChangeFormBuilderState( 'currentPage', page ) 
            }
          }}
          showPageName={ showPageName }
          currentLanguage={ data.languageReducer.userSettings.admin_panel_language } 
        />
        {
          mode === 'fill' && currentPage === content.totalPages && (
            <Button
              disabled={ !formCompleted }
              className="d-flex flex-column align-self-stretch mr-2 mb-2 justify-content-center"
              style={{ backgroundColor: "#e95d0f", border: "solid 1px #e95d0f" }}
              onClick={ onClickSubmitForm }>
              { Lang[ 'LEGALLY_BINDING_ORDER' ][ data.languageReducer.userSettings.admin_panel_language ] }</Button>
          )
        }
      </div>
    )
  }

  return (
    <>
      <div
        id='form-frame'
        style={{
          border: `${ content.container.borderWidth }px solid ${ content.container.borderColor }`,
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        <div 
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flex: "1" }}>
          <div
            style={{
              ...content.title
            }}
            onClick={() => {
              onChangeFormBuilderState( 'selectedComponentToEdit', {
                ...content
              })
              onChangeFormBuilderState( 'showUpdateFormFrame', true )
            }}>
            { content.content[ currentLanguage ] }
          </div>
          { renderPagination( false ) }
          {
            content.children && content.children.map( item => {
              return <RenderDOM
                content={ item }
                onChangeFormBuilderState={ onChangeFormBuilderState }
                mode={ mode }
                formValues={ formValues }
                onPatchFormValues={ onPatchFormValues }
                onClickSubmitForm={ onClickSubmitForm }
                createSubmittedFormMedia={ createSubmittedFormMedia }
                removeSubmittedFormMedia={ removeSubmittedFormMedia }
                uploadedMedia={ uploadedMedia }
                currentPage={ currentPage }
                dom={ dom }
                findObjectAndPatch={ findObjectAndPatch }
                togglePageSelection={ togglePageSelection }
                data={ data }
                currentLanguage={ currentLanguage }
                pageErrors={ pageErrors }
              />
            })
          }
        </div>
        { renderPagination( true ) }
      </div>
    </>
  )
}

export default FormFrame