import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Put } from 'utils/axios-token'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      privacyPolicy: {},
      is_assume_responsibility: false
    }

    onChangePrivacyPolicyHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getPrivacyPolicy = center_id => Get(
      `/api/v1/gdpr/${ center_id }/policies`,
      this.getPrivacyPolicySuccess,
      this.getPrivacyPolicyError,
      this.load
    )
    getPrivacyPolicySuccess = payload => this.setState({ privacyPolicy: payload, is_assume_responsibility: payload.is_signed_policy })
    getPrivacyPolicyError = error => this.requestError( error )

    updatePrivacyPolicy = data => Put(
      `/api/v1/gdpr/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/policies/${ data.id }`,
      data,
      this.updatePrivacyPolicySuccess,
      this.updatePrivacyPolicyError,
      this.load
    )
    updatePrivacyPolicySuccess = payload => {
      this.requestSuccess( Lang[ 'POLICY_UPDATE_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getPrivacyPolicy( this.props.data.selectCenterReducer.selectedCenter.center.id )
    }
    updatePrivacyPolicyError = error => this.requestError( error )

    submitConsent = () => Put(
      `/api/v1/clients/${ this.props.data.selectCenterReducer.selectedCenter.center.client_id }/consent`,
      { is_signed_policy: true },
      this.submitConsentSuccess,
      this.submitConsentError,
      this.load
    )
    submitConsentSuccess = payload => this.getPrivacyPolicy( this.props.data.selectCenterReducer.selectedCenter.center.id )
    submitConsentError = error => {}

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            privacyPolicy={ this.state.privacyPolicy }
            onChangePrivacyPolicyHOC={ this.onChangePrivacyPolicyHOC }
            getPrivacyPolicy={ this.getPrivacyPolicy }
            updatePrivacyPolicy={ this.updatePrivacyPolicy }
            is_assume_responsibility={ this.state.is_assume_responsibility }
            submitConsent={ this.submitConsent }
            onLoadPrivacyPolicy={ this.state.loading } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
