import { SELECT_CENTER } from 'actions/type'

let initialState = {
  selectedCenter: {}
}

export default ( state = initialState, action ) => {
  switch( action.type ){
    case SELECT_CENTER:
      return{
        ...state,
        selectedCenter: action.payload
      }
    default: return state
  }
}