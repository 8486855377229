import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Button,
  Table
} from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import Frame from 'react-frame-component'

import Lang from 'Lang/General'

const NewsletterView = ({
  viewNewsletterModal,
  toggle,
  data,
  selectedLanguage
}) => {
  return (
    <Modal
      size={ 'xl' }
      isOpen={ viewNewsletterModal }
      toggle={ toggle }>
      <ModalHeader toggle={ toggle }>{ Lang[ 'VIEW_NEWSLETTER_DETAILS' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        <Container>
          <PerfectScrollbar>
            <Table className="mb-0" bordered>
              <tbody>
                <tr>
                  <th scope="row">{ Lang[ 'SUBJECT' ][ selectedLanguage ] }</th>
                  <td>{ data.subject }</td>
                </tr>
                <tr>
                  <th scope="row">{ Lang[ 'TITLE' ][ selectedLanguage ] }</th>
                  <td>{ data.title }</td>
                </tr>
                <tr>
                  <th scope="row">{ Lang[ 'CONTENT' ][ selectedLanguage ] }</th>
                  <td>
                    <Frame
                      id={ 'editor-site-renderer' }
                      style={{
                        width: '100%',
                        height: '55vh',
                        border: 'none'
                      }}>
                      { ReactHtmlParser( data.content ) }
                    </Frame>
                  </td>
                </tr>
                <tr>
                  <th scope="row">{ Lang[ 'DELIVERED_AT' ][ selectedLanguage ] }</th>
                  <td>{ moment( data.delivered_at ).format( 'DD.MM.YYYY hh:mm a' ) }</td>
                </tr>
                <tr>
                  <th scope="row">{ Lang[ 'RECIPIENT_GROUPS' ][ selectedLanguage ] }</th>
                  <td>
                    <ul>
                    {
                      data.recipient_groups && data.recipient_groups.map( item => (
                        <li>{ item.name }</li>
                      ))
                    }
                    </ul>
                  </td>
                </tr>
              </tbody>
            </Table>
          </PerfectScrollbar>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ toggle }>{ Lang[ 'CLOSE' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}
export default NewsletterView
