import { toast } from 'react-toastify'

import Lang from 'Lang/General'

export const getFileSizeValidity = ( fileItems, selectedLanguage ) => {
  let isValidFile = true
  if( fileItems.length > 0 ){
    fileItems.map( file =>  {
      if( file.fileSize > 10000000 ){
        isValidFile = false
      }
    })
  }
  (
    !isValidFile && 
    !toast.isActive( 'file-size-toast' ) 
  ) && toast.error( 
    Lang[ 'FILE_IS_TOO_LARGE' ][ selectedLanguage ],
    { toastId: 'file-size-toast' }
  )
  return isValidFile
}