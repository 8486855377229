import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Post, Put, Delete } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      userProfile:{},
      submittedForms: [],
      archivedSubmittedForms: [],
      centerDigitalOrderForms: [],
      showFillSubmittedForm: false,
      showViewSubmittedForm: false,
      selectedSubmittedForm: {
        digital_order_form: {
          name: ''
        },
        values: []
      },
      digitalOrderForms: [],
      digitalOrderFormsPagesList: [],
      submittedFormsPagesList: [],
      archivedSubmittedFormsPagesList: [],
      digitalOrderFormsCategories: [],
      showFormBuilder: false,
      showInfoModal: false,
      showUpdateModal: false,
      showArchivedForms: false,
      showViewModalResults: false,
      selectedDigitalOrderForm: {},
      selectedDigitalOrderFormResults: {},
      uploadedMedia: [],
      showPromptModal: false,
      toRemoveID: '',
      submittedFormStatus: [],
      searchParams: {
        status_id: 0,
        categoryId: -1,
        name: ""
      },
      showMessage: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error !== 'string' 
      ? Object.values( error ).map( val => toast.error( val ) )
      : toast.error( error )

    onChangeDigitalOrderFormHOC = ( key, val ) => this.setState({ [ key ]: val })

    getUserProfile = () => Get(
      `/api/v1/profiles/me`,
      this.getUserProfileSuccess,
      this.getUserProfileError,
      this.load
    )
    getUserProfileSuccess = payload => this.setState({ userProfile: payload })
    getUserProfileError = error => this.requestError( error )

    getDigitalOrderForms = query => Get(
      `/api/v1/digital_order_forms?query=${ query }`,
      this.getDigitalOrderFormsSuccess,
      this.getDigitalOrderFormsError,
      this.load
    )
    getDigitalOrderFormsSuccess = payload => this.setState({ digitalOrderForms: payload })
    getDigitalOrderFormsError = error => this.requestError( error )

    getSelectedDigitalOrderForm = id => Get(
      `/api/v1/digital_order_forms/${ id }`,
      this.getSelectedDigitalOrderFormSuccess,
      this.getSelectedDigitalOrderFormError,
      this.load
    )
    getSelectedDigitalOrderFormSuccess = payload => this.setState({ selectedDigitalOrderForm: payload })
    getSelectedDigitalOrderFormError = error => this.requestError( error )

    getSelectedDigitalOrderFormResults = ( id, query ) => Get(
      `/api/v1/digital_order_forms/${ id }/data?query=${ query }`,
      this.getSelectedDigitalOrderFormResultSuccess,
      this.getSelectedDigitalOrderFormResultError,
      this.load
    )
    getSelectedDigitalOrderFormResultSuccess = payload => this.setState({ selectedDigitalOrderFormResults: payload.data, showViewModalResults: true })
    getSelectedDigitalOrderFormResultError = error => this.requestError( error )

    createDigitalOrderForm = data => Post(
      `/api/v1/digital_order_forms`,
      data,
      this.createDigitalOrderFormSuccess,
      this.createDigitalOrderFormError,
      this.load
    )
    createDigitalOrderFormSuccess = payload => {
      this.getDigitalOrderForms( convertSearchObjectToBase64({ page: 1, is_paginated: true }) )
      this.requestSuccess( 'Form is successfully created' )
      this.setState({ showInfoModal: false, showFormBuilder: false })
    }
    createDigitalOrderFormError = error => this.requestError( error )

    updateDigitalOrderForm = ( id, data, isArchive ) => Put(
      `/api/v1/digital_order_forms/${ id }`,
      data,
      () => this.updateDigitalOrderFormSuccess( isArchive ),
      this.updateDigitalOrderFormError,
      this.load
    )
    updateDigitalOrderFormSuccess = isArchive => {
      this.requestSuccess( 'Form is successfully updated' )
      if( isArchive ){
        this.getDigitalOrderForms( convertSearchObjectToBase64({ 
          page: 1, 
          is_paginated: true,
          filter: {
            status: 'ARCHIVED'
          } 
        }))
      }
      else{
        this.getDigitalOrderForms( convertSearchObjectToBase64({ 
          page: 1, 
          is_paginated: true,
          filter: {
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' }
            ]
          } 
        }))
      }
      this.setState({ 
        selectedDigitalOrderForm: [], 
        showUpdateModal: false, 
        showInfoModal: false })
    }
    updateDigitalOrderFormError = error => this.requestError( error )

    removeDigitalOrderForm = id => Delete(
      `/api/v1/digital_order_forms/${ id }`,
      this.removeDigitalOrderFormSuccess,
      this.removeDigitalOrderFormError,
      this.load
    )
    removeDigitalOrderFormSuccess = () => {
      this.requestSuccess( 'Form is successfully removed' )
      this.getDigitalOrderForms( convertSearchObjectToBase64({ page: 1, is_paginated: true }) ) 
      this.setState({ showPromptModal: false })
    }
    removeDigitalOrderFormError = error => this.requestError( error )

    unarchiveDigitalOrderForm = async( id ) => {
      await this.getSelectedDigitalOrderForm( id )
      let tmpForm = _.cloneDeep( this.state.selectedDigitalOrderForm )
      tmpForm.status = 'ACTIVE'
      await this.updateDigitalOrderForm( id, tmpForm, true )
    }

    createSubmittedFormMedia = ( formContentId, data ) => Post(
      `/api/v1/digital_order_forms_media`,
      data,
      payload => this.createSubmittedFormMediaSuccess( formContentId, data, payload ),
      this.createSubmittedFormMediaError,
      this.load
    )
    createSubmittedFormMediaSuccess = ( formContentId, data, payload ) => {
      let tmpUploadedMedia = _.cloneDeep( this.state.uploadedMedia )
      tmpUploadedMedia.push( {
        id: payload.id,
        formContentId: formContentId,
        url: payload.url,
        filename: data.name
      })
      this.setState({ uploadedMedia: tmpUploadedMedia })
    }
    createSubmittedFormMediaError = error => this.requestError( error )

    removeSubmittedFormMedia = ( id ) => {
      let tmpUploadedMedia = _.cloneDeep( this.state.uploadedMedia )
      _.remove(tmpUploadedMedia, ( media ) => {
        return media.id === id
      })
      this.setState({ uploadedMedia: tmpUploadedMedia })
    }

    createSubmittedForm = ( center_id, data ) => Post(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms`,
      data,
      () => this.createSubmittedFormSuccess( center_id ),
      this.createSubmittedFormError,
      this.load
    )
    createSubmittedFormSuccess = center_id => {
      this.requestSuccess( Lang[ 'FORM_IS_SUCCESSFULLY_SUBMITTED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getSubmittedForms( center_id, '' )
      this.getDigitalOrderForms( convertSearchObjectToBase64({ 
        page: 1, 
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        } 
      }))
      this.setState({ showFillSubmittedForm: false }) }
    createSubmittedFormError = error => this.requestError( error )

    getCenterDigitalOrderForms = ( center_id, query ) => Get(
      `/api/v1/centers/${ center_id }/digital_order_forms?query=${ query }`,
      this.getCenterDigitalOrderFormsSuccess,
      this.getCenterDigitalOrderFormsError,
      this.load
    )
    getCenterDigitalOrderFormsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ 
        centerDigitalOrderForms: payload,
        digitalOrderFormsPagesList: tmpTotalPages
      })
    }
    getCenterDigitalOrderFormsError = error => this.requestError( error )

    getSubmittedForms = ( center_id, query ) => Get(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms?query=${ query }`,
      this.getSubmittedFormsSuccess,
      this.getSubmittedFormsError,
      this.load
    )
    getSubmittedFormsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ 
        submittedForms: payload,
        submittedFormsPagesList: tmpTotalPages 
      })
    }
    getSubmittedFormsError = error => this.requestError( error )


    getArchivedSubmittedForms = ( center_id, query ) => Get(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms?query=${ query }`,
      this.getArchivedSubmittedFormsSuccess,
      this.getArchivedSubmittedFormsError,
      this.load
    )
    getArchivedSubmittedFormsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ 
        archivedSubmittedForms: payload,
        archivedSubmittedFormsPagesList: tmpTotalPages 
      })
    }
    getArchivedSubmittedFormsError = error => this.requestError( error )

    getSelectedSubmittedForm = ( center_id, id ) => Get(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms/${ id }`,
      this.getSelectedSubmittedFormSuccess,
      this.getSelectedSubmittedFormError,
      this.load
    )
    getSelectedSubmittedFormSuccess = payload => this.setState({ selectedSubmittedForm: payload })
    getSelectedSubmittedFormError = error => this.requestError( error )

    updateSubmittedForm = ( center_id, id, data ) => Put(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms/${ id }`,
      data,
      this.updateSubmittedFormSuccess,
      this.updateSubmittedFormError,
      this.load
    )
    updateSubmittedFormSuccess = () => this.requestSuccess( 'Form is successfully updated' )
    updateSubmittedFormError = error => this.requestError( error )
    
    getSubmittedFormStatus = () => Get(
      `/api/v1/digital_order_form_status`,
      this.getAllSubmittedFormStatus,
      this.getAllSubmittedFormError,
      this.load
    )
    getAllSubmittedFormStatus = payload => this.setState({ submittedFormStatus: payload })
    getAllSubmittedFormError = error => this.requestError( error )

    getDigitalOrderFormsCategories = ( query ) => Get(
      `/api/v1/digital_order_forms_categories?query=${ query }`,
      this.getDigitalOrderFormsCategoriesSuccess,
      this.getDigitalOrderFormsCategoriesError,
      this.load
    )
    getDigitalOrderFormsCategoriesSuccess = payload => this.setState({ digitalOrderFormsCategories: payload.data })
    getDigitalOrderFormsCategoriesError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadDigitalOrderForms={ this.state.loading }
            userProfile={ this.state.userProfile }
            getUserProfile={ this.getUserProfile }
            submittedForms={ this.state.submittedForms }
            archivedSubmittedForms={ this.state.archivedSubmittedForms }
            uploadedMedia={ this.state.uploadedMedia }
            centerDigitalOrderForms={ this.state.centerDigitalOrderForms }
            selectedSubmittedForm={ this.state.selectedSubmittedForm }
            showFillSubmittedForm={ this.state.showFillSubmittedForm }
            showViewSubmittedForm={ this.state.showViewSubmittedForm }
            createSubmittedFormMedia={ this.createSubmittedFormMedia }
            removeSubmittedFormMedia={ this.removeSubmittedFormMedia }
            createSubmittedForm={ this.createSubmittedForm }
            getSubmittedForms={ this.getSubmittedForms }
            getArchivedSubmittedForms={ this.getArchivedSubmittedForms }
            getCenterDigitalOrderForms={ this.getCenterDigitalOrderForms }
            getSelectedSubmittedForm={ this.getSelectedSubmittedForm }
            updateSubmittedForm={ this.updateSubmittedForm }
            digitalOrderForms={ this.state.digitalOrderForms }
            showFormBuilder={ this.state.showFormBuilder }
            showInfoModal={ this.state.showInfoModal }
            onChangeDigitalOrderFormHOC={ this.onChangeDigitalOrderFormHOC }
            getDigitalOrderForms={ this.getDigitalOrderForms }
            getSelectedDigitalOrderForm={ this.getSelectedDigitalOrderForm }
            getSelectedDigitalOrderFormResults={ this.getSelectedDigitalOrderFormResults }
            createDigitalOrderForm={ this.createDigitalOrderForm }
            showUpdateModal={ this.state.showUpdateModal }
            selectedDigitalOrderForm={ this.state.selectedDigitalOrderForm }
            selectedDigitalOrderFormResults={ this.state.selectedDigitalOrderFormResults }
            updateDigitalOrderForm={ this.updateDigitalOrderForm }
            removeDigitalOrderForm={ this.removeDigitalOrderForm }
            showArchivedForms={ this.state.showArchivedForms }
            showViewModalResults={ this.state.showViewModalResults }
            unarchiveDigitalOrderForm={ this.unarchiveDigitalOrderForm }
            submittedFormStatus={ this.state.submittedFormStatus }
            getSubmittedFormStatus={ this.getSubmittedFormStatus }
            searchParams={ this.state.searchParams }
            digitalOrderFormsPagesList={ this.state.digitalOrderFormsPagesList }
            submittedFormsPagesList={ this.state.submittedFormsPagesList }
            archivedSubmittedFormsPagesList={ this.state.archivedSubmittedFormsPagesList }
            digitalOrderFormsCategories={ this.state.digitalOrderFormsCategories }
            getDigitalOrderFormsCategories={ this.getDigitalOrderFormsCategories }
            showMessage={ this.state.showMessage }
          />
          <PromptModal
            showPromptModal={ this.state.showPromptModal }
            onClickYes={() => this.removeDigitalOrderForm( this.state.toRemoveID )}
            onClickNo={() => this.setState({ showPromptModal: false })}
            content={ Lang[ 'DELETE_CONFIRMATION_MESSAGE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
