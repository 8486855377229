import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'lodash'
import Moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/nl'

import getDomainURL from 'utils/api'
import { Get } from 'utils/axios-token'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={
      loading: false,
      matomoUserCities: [],
      matomoVisitsGraph: [],
      matomoVisitorLogs: [],
      matomoVisitsByPage: [],
      matomoVisitsLastHalfHour: [],
      matomoVisitsYesterday: [],
      matomoTransitions: {},
      matomoSearchParams: {
        currentTab: 'overview',
        startDate: Moment().subtract( 1, 'month' ).add( 1, 'day' ).toDate(),
        endDate: new Date(),
        filterLimit: 20,
        filterOffset: 0,
        url: ''
      }
    }

    load = param => this.setState({ loading: param })

    onChangeMatomoStatsHOC = ( key, val ) => this.setState({ [ key ]: val })

    getMatomoStats = searchParams => {
      if ( this.props.siteSettings?.matomo_site_id > 0 || getDomainURL().indexOf( 'staging' ) > -1 ) {
        let tmpQuery = (
          '/get?matomo_query=' + 
          encodeURIComponent( 
            'format=json&actionType=url&' +
            `idSite=${ getDomainURL().indexOf( 'staging' ) === -1 ? this.props.siteSettings.matomo_site_id : 21 }&` + 
            `period=range&date=${ Moment( searchParams.startDate ).format( 'YYYY-MM-DD' ) },${ Moment( searchParams.endDate ).format( 'YYYY-MM-DD' ) }&` +
            `actionName=${ searchParams.url }&` + 
            `filter_limit=${ searchParams.currentTab !== 'transitions' ? searchParams.filterLimit : 100 }&` + 
            `language=${ this.props.data.languageReducer.userSettings.admin_panel_language }`
          ) 
        )
        let tmpQueryWithOffset = tmpQuery + encodeURIComponent( `&filter_offset=${ searchParams.filterOffset }` )
  
        switch( searchParams.currentTab ){
          case 'overview':
            this.getSelectedMatomoStat( `VisitsSummary.get${ tmpQuery.replace( 'range', 'day' ) }`, 'matomoVisitsGraph', searchParams )
            this.getSelectedMatomoStat( `Live.getLastVisitsDetails${ tmpQueryWithOffset }`, 'matomoVisitorLogs', searchParams )
            this.getSelectedMatomoStat( `Live.getCounters${ tmpQuery }${ encodeURIComponent( '&lastMinutes=30' ) }`, 'matomoVisitsLastHalfHour', searchParams )
            this.getSelectedMatomoStat( `Live.getCounters${ tmpQuery }${ encodeURIComponent( '&lastMinutes=1440' ) }`, 'matomoVisitsYesterday', searchParams )
            break;
          case 'sites':
            this.getSelectedMatomoStat( `Actions.getPageUrls${ tmpQueryWithOffset }`, 'matomoVisitsByPage', searchParams )
            break;
          case 'transitions':
            this.getSelectedMatomoStat( `Actions.getPageUrls${ tmpQueryWithOffset }`, 'matomoVisitsByPage', searchParams )
            this.getSelectedMatomoStat( `Transitions.getTransitionsForAction${ tmpQueryWithOffset }`, 'matomoTransitions', searchParams )
            break;
        }
      }
    }

    getSelectedMatomoStat = ( query, key, searchParams ) => Get(
      `/api/v1/matomo/${ query }`,
      payload => this.getSelectedMatomoStatSuccess( payload, key, searchParams ),
      this.getSelectedMatomoStatError,
      this.load
    )
    getSelectedMatomoStatSuccess = ( payload, key, searchParams ) => this.setState({ 
      loading: false, 
      matomoSearchParams: searchParams,
      [ key ]: (
        searchParams.filterOffset === 0 ||
        key === 'matomoVisitsGraph' ||
        key === 'matomoVisitsYesterday' ||
        key === 'matomoVisitsLastHalfHour'
      ) ? (
        key === 'matomoVisitsGraph' ? (
          map( 
            payload, 
            ( visit, visitDate ) => ({ 
              ...visit,
              date: Moment( visitDate, 'YYYY-MM-DD' ).locale( this.props.data.languageReducer.userSettings.admin_panel_language ).format( 'ddd, D MMM' )
            })
          )
        ) : (
          payload
        )
      ) : (
        [ ...this.state[ key ], ...payload ] 
      )
    })
    getSelectedMatomoStatError = () => {}

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          { ...this.state }
          onLoadMatomoStatsHOC={ this.state.loading }
          onChangeMatomoStatsHOC={ this.onChangeMatomoStatsHOC }
          getMatomoStats={ this.getMatomoStats }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC