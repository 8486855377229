import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      errorMessage: {},
      step: 1,
      siteMailingSearchParams: [
        {
          label: 'Title',
          value: 'title',
          param: ''
        },
      ],
      siteMailings: [],
      selectedSiteMailing: {
        "delivered_at": "2020-02-02T00:00:00Z",
        "json_content": "",
        "sender_id": 1,
        "center_id": 1,
        "subject": "",
        "title": "",
        "content": "",
        "status": "ACTIVE",
        "type": "email",
        "is_draft": true
      },
      newSiteMailing: {
        "delivered_at": "2020-02-02T00:00:00Z",
        "json_content": "",
        "sender_id": 1,
        "center_id": 1,
        "subject": "",
        "title": "",
        "content": "",
        "status": "ACTIVE",
        "type": "email",
        "is_draft": true
      },
      showCreateSiteMailing: false,
      showUpdateSiteMailing: false,
      showArchivedSiteMailings: false,
      showArchiveSelectedSiteMailing: false,
      emailEditor: ''
    }

    onChangeMailingListingHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error, {
      position: "bottom-right"
    })

    getAllSiteMailing = ( query ) => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/mailings?query=${query}`,
      this.getAllSiteMailingSuccess,
      this.getAllSiteMailingError,
      this.load
    )
    getAllSiteMailingSuccess = payload => {
      if( payload.data.length === 0 ){
        this.requestError( Lang['NO_RESULT_FOUND'][this.props.data.languageReducer.userSettings.admin_panel_language] )
      }
      this.setState({ siteMailings: payload })
    }
    getAllSiteMailingError = error => this.requestError( error )


    getSelectedMailingTemplate = id => Get(
      `/api/v1/funnel_mailing_templates/${ id }`,
      this.getSelectedMailingTemplateSuccess,
      this.getSelectedMailingTemplateError,
      this.load
    )
    getSelectedMailingTemplateSuccess = payload => {
      if( payload ){
        let tmp = _.cloneDeep( this.state.newSiteMailing )
        tmp['json_content'] = payload.json_content
        tmp['template_id'] = payload.id

        this.setState({ newSiteMailing: tmp })
      }
    }
    getSelectedMailingTemplateError = error => this.requestError( error )

    createSiteMailing = ( data ) => Post(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/mailings`,
      data,
      this.createSiteMailingSuccess,
      this.createSiteMailingError,
      this.load
    )
    createSiteMailingSuccess = () => {
      this.setState({ showCreateSiteMailing: false })
      this.getAllSiteMailing(
        convertSearchObjectToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        }) 
      )
      this.requestSuccess( Lang['CREATE_MAILING_SUCCESS'][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
    }
    createSiteMailingError = error => this.requestError( error )

    getSelectedSiteMailing = ( id, isArchiving ) => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/mailings/${id}`,
      payload => this.getSelectedSiteMailingSuccess( payload, isArchiving ),
      this.getSelectedSiteMailingError,
      this.load
    )
    getSelectedSiteMailingSuccess = ( payload, isArchiving ) => this.setState({ 
      selectedSiteMailing: payload,
      showArchiveSelectedSiteMailing: isArchiving 
    })
    getSelectedSiteMailingError = error => this.requestError( error )

    updateSiteMailing= ( id, data ) => Put(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/mailings/${id}`,
      data,
      this.updateSiteMailingSuccess,
      this.updateSiteMailingError,
      this.load
    )
    updateSiteMailingSuccess = () => {
      this.getAllSiteMailing(
        convertSearchObjectToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        }) 
      )
      this.requestSuccess( 'Update site mailing success' )
      this.setState({ showUpdateSiteMailing: false, showArchiveSelectedSiteMailing: false })
    }
    updateSiteMailingError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadSiteMailings={ this.state.loading }
          getAllSiteMailing={ this.getAllSiteMailing }
          siteMailings={ this.state.siteMailings }
          selectedSiteMailing={ this.state.selectedSiteMailing }
          newSiteMailing={ this.state.newSiteMailing }
          showCreateSiteMailing={ this.state.showCreateSiteMailing }
          showUpdateSiteMailing={ this.state.showUpdateSiteMailing }
          onChangeMailingListingHOC={ this.onChangeMailingListingHOC }
          step={ this.state.step }
          emailEditor={ this.state.emailEditor }
          getSelectedMailingTemplate={ this.getSelectedMailingTemplate }
          createSiteMailing={ this.createSiteMailing }
          getSelectedSiteMailing={ this.getSelectedSiteMailing }
          updateSiteMailing={ this.updateSiteMailing }
          siteMailingSearchParams={ this.state.siteMailingSearchParams }
          showArchivedSiteMailings={ this.state.showArchivedSiteMailings }
          showArchiveSelectedSiteMailing={ this.state.showArchiveSelectedSiteMailing } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
