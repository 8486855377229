import React from 'react'
import { Col, Button } from 'reactstrap'
import { MdEdit } from 'react-icons/md'
import { FaRegTrashAlt } from 'react-icons/fa'
import { Draggable } from "react-beautiful-dnd"

import RenderDOM from '../RenderDOM'

import Lang from 'Lang/General'

const getItemStyle = (isDragging, draggableStyle, content) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : content.backgroundColor,
  // styles we need to apply on draggables
  ...draggableStyle
})

const ColumnComponent = ({
  content,
  formValues,
  mode,
  onChangeFormBuilderState,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  removeSubmittedFormMedia,
  uploadedMedia,
  currentPage,
  dom,
  findObjectAndPatch,
  data,
  isInputDisabled,
  togglePageSelection,
  currentLanguage,
  pageErrors
}) => {
  return (
    <>
      {
        mode === 'edit' ? (
          <Draggable key={ content.id } draggableId={ content.id } index={ content.rowComponentIndex }>
            {( provided, snapshot ) => (
              <div 
                ref={ provided.innerRef }
                { ...provided.draggableProps }
                { ...provided.dragHandleProps }
                className={ `col-md-${ content.grid }` } 
                style={{  
                  padding: '5px', 
                  position: 'relative',
                  background: content.backgroundColor,
                  ...getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                    content
                  )
                }}>
                {
                  content.children && content.children.map( item => {
                    return <RenderDOM
                      content={ item }
                      onChangeFormBuilderState={ onChangeFormBuilderState }
                      mode={ mode }
                      formValues={ formValues }
                      onPatchFormValues={ onPatchFormValues }
                      onClickSubmitForm={ onClickSubmitForm }
                      createSubmittedFormMedia={ createSubmittedFormMedia }
                      removeSubmittedFormMedia={ removeSubmittedFormMedia }
                      uploadedMedia={ uploadedMedia }
                      currentPage={ currentPage }
                      dom={ dom }
                      findObjectAndPatch={ findObjectAndPatch }
                      data={ data }
                      isInputDisabled={ isInputDisabled }
                      togglePageSelection={ togglePageSelection }
                      currentLanguage={ currentLanguage }
                      pageErrors={ pageErrors }
                    /> 
                  })
                }
                {
                  !content.children && (
                    <div>
                      <span>{ Lang[ 'PUT_SOMETHING_HERE' ][ data.languageReducer.userSettings.admin_panel_language ] }</span>
                    </div>
                  )
                }
                <Button 
                  style={{ position: 'absolute', top: 5, right: 5 }}
                  onClick={ async() => {
                    await onChangeFormBuilderState( 'selectedComponentToEdit', {
                      ...content
                    })
                    await onChangeFormBuilderState( 'showUpdateColumnModal', true )
                  }}>
                  <MdEdit color="#fff" size={ 16 } />
                </Button>
                <Button
                  color="danger"
                  style={{ position: 'absolute', top: 5, right: 50 }}
                  onClick={ async() => {
                    await onChangeFormBuilderState( 'selectedComponentToRemove', content )
                    await onChangeFormBuilderState( 'showConfirmDelete', true )
                  }}>
                  <FaRegTrashAlt color="#fff" size={ 16 } />
                </Button>
              </div>
            )}
          </Draggable>
        ) : (
          <div 
            className={ `col-md-${ content.grid }` } 
            style={{ 
              padding: '5px', 
              position: 'relative',
              background: content.backgroundColor,
             }}>
            {
              content.children && content.children.map( item => {
                return <RenderDOM
                  content={ item }
                  onChangeFormBuilderState={ onChangeFormBuilderState }
                  mode={ mode }
                  formValues={ formValues }
                  onPatchFormValues={ onPatchFormValues }
                  onClickSubmitForm={ onClickSubmitForm }
                  createSubmittedFormMedia={ createSubmittedFormMedia }
                  removeSubmittedFormMedia={ removeSubmittedFormMedia }
                  uploadedMedia={ uploadedMedia }
                  currentPage={ currentPage }
                  dom={ dom }
                  findObjectAndPatch={ findObjectAndPatch }
                  data={ data }
                  isInputDisabled={ isInputDisabled }
                  togglePageSelection={ togglePageSelection }
                  currentLanguage={ currentLanguage }
                  pageErrors={ pageErrors }
                /> 
              })
            }
            {
              !content.children && (
                <div>
                  <span>{ Lang[ 'PUT_SOMETHING_HERE' ][ data.languageReducer.userSettings.admin_panel_language ] }</span>
                </div>
              )
            }
            {
              mode === 'edit' && <Button 
                style={{ position: 'absolute', top: 5, right: 5 }}
                onClick={ async() => {
                  await onChangeFormBuilderState( 'showUpdateColumnModal', true )
                  await onChangeFormBuilderState( 'selectedComponentToEdit', {
                    ...content
                  })
                }}>
                <MdEdit color="#fff" size={ 16 } />
              </Button>
            }
            {
              mode === 'edit' && <Button
                color="danger"
                style={{ position: 'absolute', top: 5, right: 50 }}
                onClick={ async() => {
                  await onChangeFormBuilderState( 'selectedComponentToRemove', content )
                  await onChangeFormBuilderState( 'showConfirmDelete', true )
                }}>
                <FaRegTrashAlt color="#fff" size={ 16 } />
              </Button>
            }
          </div>
        )
      }
    </>
  )
}

export default ColumnComponent