import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Get, Post, Put } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newCampaigns: {},
      campaigns: [],
      selectedCampaign: {},
      showViewModal: false,
      showBookModal: false,
      showChangeModal: false,
      showCreateModal: false,
      showLandingPageModal: false,
      showAlertModal: false,
      showEditDurationModal: false,
      budgetData: {},
      selectedBookedCampaign: {
        fa_budget: 0,
        ga_budget: 0,
        start_date: '',
        end_date: ''
      },
      presentBookedCampaign: false,
      bookedCampaignError: {},
      newCampaignData: {
        center_id: 0,
        title: '',
        fa_budget: 0,
        ga_budget: 0,
        start_date: '',
        remark: '',
        preview_image_raw: '',
        bookable_months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]
      },
      campaignsCenterId: '',
      uploadedMedia: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeCampaignHOC = ( key, value ) => this.setState({ [key]: value }, () => {
      if( key === 'campaignsCenterId' ){
        let tmpNewCampaignData = _.cloneDeep( this.state.newCampaignData )
        tmpNewCampaignData.center_id = parseInt( value )
        this.getCampaigns( false )
        this.setState({ newCampaignData: tmpNewCampaignData })
      }
    })

    getCampaigns = isOneTimer => Get(
      `/api/v1/ols_dashboard${ isOneTimer
        ? `` : `?center_id=${ this.state.campaignsCenterId }` }`,
      this.getCampaignsSuccess,
      this.getCampaignsError,
      this.load
    )
    getCampaignsSuccess = payload => {
      let tmp = _.cloneDeep( payload )
      if( _.find( tmp, { is_booked: true, is_app_campaign: false }) ) {
        this.setState({ presentBookedCampaign: true })
      } else {
        this.setState({ presentBookedCampaign: false })
      }
      this.setState({ campaigns: payload })
    }
    getCampaignsError = error => this.requestError( error )

    createCampaign = ( isOneTimer, data ) => Post(
      `/api/v1/ols_center_campaigns`,
      data,
      () => this.createCampaignSuccess( isOneTimer ),
      this.createCampaignError,
      this.load
    )
    createCampaignSuccess = isOneTimer => {
      this.requestSuccess( 'Campaign is created successfully.' )
      this.setState({ showCreateModal: false })
      this.getCampaigns( isOneTimer )
    }
    createCampaignError = error => this.requestError( error )

    getSelectedCampaign = id => Get(
      `/api/v1/ols_campaigns/${ id }`,
      this.getSelectedCampaignSuccess,
      this.getSelectedCampaignError,
      this.load
    )
    getSelectedCampaignSuccess = payload => this.setState({ selectedCampaign: payload, bookedCampaignError: {} })
    getSelectedCampaignError = error => this.requestError( error )

    createBookedCampaign = ( data, isOneTimer ) => Post(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.campaignsCenterId }` }/ols_booked_campaigns`,
      data,
      () => this.createBookedCampaignSuccess( isOneTimer ),
      this.createBookedCampaignError,
      this.load
    )
    createBookedCampaignSuccess = isOneTimer => {
      this.setState({ showBookModal: false })
      this.getCampaigns( isOneTimer )
    }
    createBookedCampaignError = error => this.setState({ bookedCampaignError: error })

    getSelectedBookedCampaign = ( booked_campaign_id, isOneTimer ) => Get(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.campaignsCenterId }` }/ols_booked_campaigns/${ booked_campaign_id }`,
      this.getSelectedBookedCampaignSuccess,
      this.getSelectedBookedCampaignError,
      this.load
    )
    getSelectedBookedCampaignSuccess = payload => this.setState({ 
      selectedBookedCampaign: {
        ...payload,
        start_date: new Date( payload.start_date ),
        end_date: new Date( payload.end_date ),
      } 
    })
    getSelectedBookedCampaignError = error => this.requestError( error )

    updateBudget = ( selectedBookedCampaignID, isOneTimer ) => Put(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.campaignsCenterId }` }/ols_booked_campaigns/${ selectedBookedCampaignID }`,
      {
        fa_budget: this.state.selectedBookedCampaign.fa_budget,
        ga_budget: this.state.selectedBookedCampaign.ga_budget
      },
      () => this.updateBudgetSuccess( isOneTimer ),
      this.updateBudgetError,
      this.load
    )
    updateBudgetSuccess = isOneTimer => {
      this.setState({ showChangeModal: false })
      this.getCampaigns( isOneTimer )
    }
    updateBudgetError = error => this.requestError( error )

    updateDuration = ( selectedBookedCampaignID, isOneTimer ) => Put(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.campaignsCenterId }` }/ols_booked_campaigns/${ selectedBookedCampaignID }`,
      {
        start_date: Moment( this.state.selectedBookedCampaign.start_date ).utc().format(),
        end_date: Moment( this.state.selectedBookedCampaign.end_date ).utc().format()
      },
      () => this.updateDurationSuccess( isOneTimer ),
      this.updateDurationError,
      this.load
    )
    updateDurationSuccess = isOneTimer => {
      this.setState({ showEditDurationModal: false })
      this.getCampaigns( isOneTimer )
    }
    updateDurationError = error => this.requestError( error )

    getOLSCampaignSettings = ( isOneTimer, center_id ) => Get(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ center_id }` }/ols_settings`,
      this.getOLSSettingsSuccess,
      this.getOLSSettingsError,
      this.load
    )
    getOLSSettingsSuccess = payload => {
      let tmp = _.cloneDeep( this.state.budgetData )
      tmp.fa_budget = payload.monthly_fb_click_through_budget
      tmp.ga_budget = payload.monthly_google_budget
      this.setState({ budgetData: tmp })
    }
    getOLSSettingsError = error => {}

    createSubmittedFormMedia = ( formContentId, data ) => Post(
      `/api/v1/digital_order_forms_media`,
      data,
      payload => this.createSubmittedFormMediaSuccess( formContentId, data, payload ),
      this.createSubmittedFormMediaError,
      this.load
    )
    createSubmittedFormMediaSuccess = ( formContentId, data, payload ) => {
      let tmpUploadedMedia = _.cloneDeep( this.state.uploadedMedia )
      tmpUploadedMedia.push( {
        id: payload.id,
        formContentId: formContentId,
        url: payload.url,
        filename: data.name
      })
      this.setState({ uploadedMedia: tmpUploadedMedia })
    }
    createSubmittedFormMediaError = error => this.requestError( error )

    removeSubmittedFormMedia = ( id ) => {
      let tmpUploadedMedia = _.cloneDeep( this.state.uploadedMedia )
      _.remove(tmpUploadedMedia, ( media ) => {
        return media.id === id
      })
      this.setState({ uploadedMedia: tmpUploadedMedia })
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadOLSCampaigns={ this.state.loading }
            campaigns={ this.state.campaigns }
            getCampaigns={ this.getCampaigns }
            getSelectedCampaign={ this.getSelectedCampaign }
            selectedCampaign={ this.state.selectedCampaign }
            onChangeCampaignHOC={ this.onChangeCampaignHOC }
            showViewModal={ this.state.showViewModal }
            showBookModal={ this.state.showBookModal }
            showAlertModal={ this.state.showAlertModal }
            showEditDurationModal={ this.state.showEditDurationModal }
            budgetData={ this.state.budgetData }
            createBookedCampaign={ this.createBookedCampaign }
            getSelectedBookedCampaign={ this.getSelectedBookedCampaign }
            selectedBookedCampaign={ this.state.selectedBookedCampaign }
            showChangeModal={ this.state.showChangeModal }
            updateBudget={ this.updateBudget }
            campaignsCenterId={ this.state.campaignsCenterId }
            showCreateModal={ this.state.showCreateModal }
            newCampaignData={ this.state.newCampaignData }
            createCampaign={ this.createCampaign }
            updateDuration={ this.updateDuration }
            showLandingPageModal={ this.state.showLandingPageModal }
            presentBookedCampaign={ this.state.presentBookedCampaign }
            bookedCampaignError={ this.state.bookedCampaignError }
            getOLSCampaignSettings={ this.getOLSCampaignSettings }
            uploadedMedia={ this.state.uploadedMedia }
            createSubmittedFormMedia={ this.createSubmittedFormMedia }
            removeSubmittedFormMedia={ this.removeSubmittedFormMedia } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
