const Lang = {
  NOTIFICATIONS : {
    en: 'Notifications',
    de: 'Benachrichtigungen',
    it: 'Notifiche',
    nl: 'Kennisgevingen'
  },
  LOGOUT : {
    en: 'Logout',
    de: 'Abmelden',
    it: 'Logout',
    nl: 'Uitloggen'
  },
  MY_PROFILE : {
    en: 'My Profile',
    de: 'Mein Profil',
    it: 'Il mio profilo',
    nl: 'Mijn Profiel'
  },
  WELCOME : {
    en: 'Welcome',
    de: 'Willkommen',
    it: 'Benvenuto',
    nl: 'Welkom'
  },
  NOTIFICATION_MESSAGE : {
    en: 'You have',
    de: 'Sie haben',
    it: 'Tu hai',
    nl: 'Je hebt'
  },
  UNREAD_MESSAGE : {
    en: 'unread messages',
    de: 'ungelesene Nachrichten',
    it: 'messaggi non letti',
    nl: 'ongelezen berichten'
  },
  SEARCH_CENTER: {
    en: "Search Center",
    de: "Center Suche",
    it: "Centro di ricerca",
    nl: "Zoekcentrum"
  },
  SELECT_CENTER: {
    "en": "Select center",
    "de": "Cockpit wechseln:",
    "it": "Seleziona centro",
    "nl": "Selecteer centrum"
  },
  LOGIN_TO_WEBSITE: {
    en: 'Login to website',
    de: 'Anmeldung zur Website',
    it: 'Accesso al sito web',
    nl: 'Inloggen op de website'
  },
  SEARCH_SITE: {
    en: 'Search Site',
    de: 'Seite suchen',
    it: 'Ricerca nel sito',
    nl: 'Zoek Site'
  },
  CONFIRMATION_MODAL : {
    en: 'Confirmation modal',
    de: 'Bestätigungsmodal',
    it: 'Modale di conferma',
    nl: 'Bevestiging modaal'
  },
  YES: {
    en: 'Yes',
    de: 'Ja',
    it: 'Sì',
    nl: 'Ja'
  },
  NO: {
    en: 'No',
    de: 'Nein',
    it: 'No',
    nl: 'Nee'
  },
  ARK_MANAGEMENT : {
    en: "Ark Management",
    de: "Ark Management",
    it: "Ark Management",
    nl: "Ark Management"
  },
  DASHBOARD : {
    en: "Dashboard",
    de: "Dashboard",
    it: "Dashboard",
    nl: "Dashboard"
  },
  DIGITAL_ORDER_FORM: {
    en: "Digital Order Form",
    de: "Bestellschein",
    it: "Modulo d'ordine digitale",
    nl: "Digitaal bestelformulier"
  },
  FORM_LISTING: {
    en: "Form Listing",
    de: "Formulare",
    it: "Elenco dei moduli",
    nl: "Formulier Lijst"
  },
  FORM_BUILDER: {
    en: "Form Builder",
    de: "Formular Designer",
    it: "Costruttore di moduli",
    nl: "Formulierenbouwer"
  },
  AVAILABLE_FORMS: {
    en: "Available forms",
    de: "Bestellscheine",
    it: "Forme disponibili",
    nl: "Beschikbare formulieren"
  },
  SUBMITTED_FORMS: {
    en: "Submitted forms",
    de: "Laufende Aufträge",
    it: "Moduli inviati",
    nl: "Ingediende formulieren"
  },
  WEBFORMS: {
    en: "Webforms",
    de: "Webformulare",
    it: "Webforms",
    nl: "Webformulieren"
  },
  TENNIS_YOUTUBE_CHANNEL: {
    en: "Tennis Youtube Channel",
    de: "Tennis Youtube Kanal",
    it: "Canale Youtube del tennis",
    nl: "Tennis Youtube Kanaal"
  },
  BONUS_CLUB : {
    en: "Aciso Bonus Club",
    de: "Aciso Bonus Club",
    it: "Aciso Bonus Club",
    nl: "Aciso Bonus Club"
  },
  LEAD_CENTRE: {
    en: "LeadCentre",
    de: "LeadCenter",
    it: "Centro di comando",
    nl: "Leiding Centrum"
  },
  MULTITRAINING: {
    en: "Multitraining",
    de: "Multitraining",
    it: "Multiformazione",
    nl: "Meervoudige opleiding"
  },
  LINK_APP_PORTAL: {
    en: "Link App Portal",
    de: "Link App Portal",
    it: "Portale App Link",
    nl: "Link App Portaal"
  },
  INJOY_APP: {
    en: "Injoy App",
    de: "Injoy App",
    it: "Injoy App",
    nl: "Injoy App"
  },
  ACISO_APP: {
    en: "Aciso App",
    de: "Aciso App",
    it: "Aciso App",
    nl: "Aciso App"
  },
  APP_ERKLARVIDEOS: {
    en: "Erklarvideos",
    de: "Erklärvideos",
    it: "Erklarvideos",
    nl: "Erklarvideos"
  },
  LOCATIONS: {
    en: "Locations",
    de: "Standorte",
    it: "Posizione",
    nl: "Locatie"
  },
  USER_SETTINGS: {
    en: "User Settings",
    de: "Einstellungen",
    it: "Impostazioni utente",
    nl: "Gebruikers Instellingen"
  },
  SETTINGS: {
    en: "Settings",
    de: "Einstellungen",
    it: "Impostazioni",
    nl: "Instellingen"
  },
  LISTING: {
    en: "Listing",
    de: "Auflistung",
    it: "Lista",
    nl: "Lijst"
  },
  COMPLETED_CAMPAIGN: {
    en: "Completed Campaign",
    de: "Abgeschlossene Kampagne",
    it: "Campagna completata",
    nl: "Voltooide Campagne"
  },
  OLS: {
    en: "OLS",
    de: "OLS",
    it: "OLS",
    nl: "OLS"
  },
  ARK_SITE: {
    en: "Ark Site",
    de: "Ark Seite",
    it: "Ark Sito",
    nl: "Ark Site"
  },
  MAIN_MENU:{
    en: "Main Menu",
    de: "Hauptmenü",
    it: "Menu principale",
    nl: "Hoofdmenu"
  },
  LOCAL_LISTINGS: {
    en: "Local Listing",
    de: "Local Listing",
    it: "Annunci locali",
    nl: "Lokale vermeldingen"
  },
  ONLINE_MARKETING_SERVICE: {
    en: "Online Marketing Service",
    de: "Online Marketing Service",
    it: "Servizio di marketing online",
    nl: "Online Marketing Dienst"
  },
  RESOURCES: {
    en: "Resources",
    de: "Ressourcen",
    it: "Risorse",
    nl: "Middelen"
  },
  MESSAGE_CONSULTANT: {
    "en": "Message Consultant",
    "de": "Berater Nachrichten",
    "it": "Consulente messaggi",
    "nl": "Bericht Consultant"
  },
  OTHERS: {
    "en": "Others",
    "de": "Andere",
    "it": "Altri",
    "nl": "Anderen"
  },
  OLS_CAMPAIGNS: {
    en: "OLS Campaigns",
    de: "OLS Kampagnen",
    it: "Campagne OLS",
    nl: "OLS Campagnes"
  },
  INFO: {
    en: 'This is a 3rd party link (fitnessmarkt) that is available for all ACISO client. If you subscribe to this link, you can buy all gym centre related equipments and products eg: supplements, towel, equipment etc at a lower market price.',
    de: 'Dies ist ein Link eines Drittanbieters (Fitnessmarkt), der für alle ACISO-Kunden verfügbar ist. Wenn Sie diesen Link abonnieren, können Sie alle Geräte und Produkte, die mit dem Fitnesscenter zusammenhängen, wie z. B. Nahrungsergänzungsmittel, Handtücher, Geräte usw., zu einem niedrigeren Marktpreis kaufen.',
    it: 'Questo è un link di terze parti (fitnessmarkt) che è disponibile per tutti i clienti ACISO. Se ti iscrivi a questo link, puoi acquistare tutti i prodotti e le attrezzature relative al centro fitness, ad esempio: integratori, asciugamani, attrezzature ecc. ad un prezzo di mercato inferiore.',
    nl: 'Dit is een link van een derde partij (fitnessmarkt) die beschikbaar is voor alle ACISO-klanten. Als u zich abonneert op deze link, kunt u alle apparatuur en producten die verband houden met fitnesscentra kopen, zoals: supplementen, handdoeken, apparatuur enz. tegen een lagere marktprijs.'
  },
  CUSTOMER: {
    en: 'Dear Customer,',
    de: 'Lieber Kunde',
    it: 'Caro cliente,',
    nl: 'Beste klant,'
  },
  BONUS_CLUB_DESCRIPTION: {
    en: 'by clicking on the button "Forward to ACISO Bonus Club" you will be forwarded to your existing user account. If you no longer want this additional service to your existing ACISO offers, you can request deletion of your user account at fitnessmarkt.de by clicking on the button "Please delete my ACISO Bonus Clubs user account". We would like to point out that only the data will be deleted which was transferred to fitnessmarkt.de by ACISO during the registration process. Other data that you have given to fitnessmarkt.de in the context of an order will not be deleted.',
    de: 'mit Klick auf den Button „Jetzt Weiterleiten zum ACISO Bonus Club“ wirst du zu deinem eingerichteten Benutzerkonto weitergeleitet. Solltest du diesen zusätzlichen Service zu deinen bestehenden ACISO-Angeboten nicht mehr wünschen, kannst du für dein Benutzerkonto bei fitnessmarkt.de über den Klick auf den Button „Bitte lösche mein ACISO Bonus Clubs Benutzerkonto“ eine Löschung beantragen. Wir weisen darauf hin, dass nur die Daten gelöscht werden, welche im Rahmen der Anmeldung von ACISO an fitnessmarkt.de übergeben wurden. Weitere Daten die du fitnessmarkt.de im Rahmen einer Bestellung übergeben haben, werden nicht gelöscht.',
    it: 'cliccando sul pulsante "Inoltrare a ACISO Bonus Club" sarete inoltrati al vostro conto utente esistente. Se non desideri più questo servizio aggiuntivo alle tue offerte ACISO esistenti, puoi richiedere la cancellazione del tuo conto utente su fitnessmarkt.de cliccando sul pulsante "Cancella il mio conto utente ACISO Bonus Clubs". Vorremmo sottolineare che solo i dati che sono stati trasferiti a fitnessmarkt.de da ACISO come parte del processo di registrazione saranno cancellati. Ulteriori dati che avete trasferito a fitnessmarkt.de nel contesto di un ordine non saranno cancellati.',
    nl: 'door te klikken op de knop "Doorsturen naar ACISO Bonus Club" wordt u doorgestuurd naar uw bestaande gebruikersaccount. Indien u deze extra service niet langer wenst naast uw bestaande ACISO aanbiedingen, kunt u de verwijdering van uw gebruikersaccount aanvragen op fitnessmarkt.de door te klikken op de knop "Gelieve mijn ACISO Bonus Clubs gebruikersaccount te verwijderen". Wij wijzen erop, dat alleen de gegevens, die door ACISO in het kader van het registratieproces aan fitnessmarkt.de werden doorgegeven, worden gewist. Verdere gegevens die u in het kader van een bestelling aan fitnessmarkt.de hebt doorgegeven, worden niet gewist.'
  },
  DESCRIPTION_WITHOUT_URL: {
    en: 'As an additional service to the ACISO consulting services, you have the possibility to create a user account for the ACISO Bonus Club at fitnessmarkt.de services GmbH (hereinafter "fitnessmarkt.de") free of charge for one year. The cooperation between ACISO and fitnessmarkt.de allows you to easily transfer your existing login data to the user account at fitnessmarkt.de. In the ACISO Bonus Club you can buy a wide range of products for your gym.',
    de: 'als zusätzlichen Service zu den ACISO-Beratungsleistungen hast du die Möglichkeit, im Rahmen des ACISO Bonus Clubs kostenlos für ein Jahr ein Benutzerkonto für den ACISO Bonus Club bei der fitnessmarkt.de services GmbH (im Folgenden , fitnessmarkt.de") anzulegen. Die Kooperation zwischen ACISO und fitnessmarkt.de ermöglicht es dir, deine bestehenden Login-Daten ganz bequem auf das Benutzerkonto bei fitnessmarkt.de zu übertragen. Im ACISO Bonus Club steht dir eine Vielzahl an Produkten für dein Fitnessstudio zum Kauf zur Verfügung.',
    it: `Come servizio aggiuntivo ai servizi di consulenza ACISO, hai la possibilità di creare un account utente per l'ACISO Bonus Club su fitnessmarkt.de services GmbH (di seguito "fitnessmarkt.de") gratuitamente per un anno. La cooperazione tra ACISO e fitnessmarkt.de ti permette di trasferire comodamente i tuoi dati di accesso esistenti all'account utente di fitnessmarkt.de. Nell'ACISO Bonus Club puoi acquistare una vasta gamma di prodotti per il tuo studio fitness.`,
    nl: `Als extra service bij de ACISO adviesdiensten heeft u de mogelijkheid om bij fitnessmarkt.de services GmbH (hierna "fitnessmarkt.de") gedurende een jaar gratis een gebruikersaccount voor de ACISO Bonus Club aan te maken. De samenwerking tussen ACISO en fitnessmarkt.de maakt het voor u mogelijk, uw bestaande logingegevens comfortabel naar de gebruikersaccount bij fitnessmarkt.de over te dragen. In de ACISO Bonus Club kunt u een breed scala aan producten voor uw fitnessstudio kopen.`
  },
  START_SETUP: {
    en: 'Start setup',
    de: 'Einrichung starten',
    it: 'Iniziare la configurazione',
    nl: 'Begin instelling,'
  },
  FORWARD_TO_BONUS_CLUB: {
    en: 'Now forward to Aciso Bonus Club',
    de: 'jetzt weiterleiten zum Aciso Bonus Club',
    it: `ora avanti all'Aciso Bonus Club`,
    nl: 'nu door naar de Aciso Bonus Club'
  },
  HIGHLIGHTED_TEXT: {
    en: 'Your account has been created successfully. You can now log in to fitnessmarkt.de.',
    de: 'Dein Account wurde erfolgreich angelegt. Du kannst dich jetzt bei fitnessmarkt.de einloggen.',
    it: 'Il tuo account è stato creato con successo. Ora puoi accedere a fitnessmarkt.de.',
    nl: 'Uw account is succesvol aangemaakt. U kunt nu inloggen op fitnessmarkt.de.'
  },
  DELETE_ACCOUNT: {
    en: 'Please delete my Aciso Bonus Club account',
    de: 'Bitte lösche mein Aciso Bonus Club benutzerkonto',
    it: 'per favore cancella il mio conto Aciso Bonus Club',
    nl: 'verwijder alstublieft mijn Aciso Bonus Club account'
  },
  SETUP_ACCOUNT: {
    en: 'set up fintessmarkt.de account',
    de: 'fintessmarkt.de Account einrichten',
    it: 'creare un account su fintessmarkt.de',
    nl: 'fintessmarkt.de account opzetten'
  },
  BY_CLICKING: {
    de: 'Mit Klick auf den Button „Jetzt Konto bei fitnessmarkt.de einrichten“ willigst du ein, dass ACISO deinen Studionamen, die E-Mail-Adresse, Vor- und Nachname, die Adresse(n) des Studios an fitnessmarkt.de zum Zweck der Erstellung eines Benutzerkontos für den ACISO Bonus Clubs übermittelt. Der Datentransfer erfolgt gesichert mit gängigem Verschlüsselungsstandard.',
    en: 'By clicking on the button "Create account at fitnessmarkt.de now" you agree that ACISO transmits your studio name, e-mail address, first and last name, address(es) of the studio to fitnessmarkt.de for the purpose of creating a user account for the ACISO Bonus Club. The data transfer is secured with common encryption standards.',
    it: `Cliccando sul pulsante "Crea ora un account su fitnessmarkt.de" accetti che ACISO trasmetta a fitnessmarkt.de il nome del tuo studio, l'indirizzo e-mail, il nome e il cognome, l'indirizzo/i dello studio, allo scopo di creare un account utente per l'ACISO Bonus Club. Il trasferimento dei dati è protetto con i comuni standard di crittografia.`,
    nl: 'Door op de knop "Nu account aanmaken bij fitnessmarkt.de" te klikken, gaat u ermee akkoord dat ACISO uw studienaam, e-mailadres, voor- en achternaam, adres(sen) van de studio aan fitnessmarkt.de doorgeeft ten behoeve van het aanmaken van een gebruikersaccount voor de ACISO Bonus Club. De gegevensoverdracht is beveiligd met gangbare coderingsstandaarden.'
  },
  TERMS_OF_USE_A: {
    en: 'I agree with the ',
    de: 'Mit den ',
    it: `Sono d'accordo con `,
    nl: 'Ik ga akkoord met de '
  },
  LINK: {
    en: 'terms of use ',
    de: 'Nutzungsbedingungen ',
    it: 'i termini di utilizzo ',
    nl: 'gebruiksvoorwaarden '
  },
  TERMS_OF_USE_B: {
    en: 'of fitnessmarkt.de.',
    de: 'von fitnessmarkt.de erkläre ich mich einverstanden.',
    it: 'di fitnessmarkt.de.',
    nl: 'van fitnessmarkt.de.'
  },
  CLOSE: {
    en: 'Close',
    de: 'Schließen',
    it: 'Chiudere',
    nl: 'Sluiten'
  },
  CONTINUE_SETUP: {
    en: 'Create your account with fitnessmarkt.de now',
    de: 'Jetzt ein Konto bei fitnessmarkt.de einrichten',
    it: 'crea un account con fitnessmarkt.de ora',
    nl: 'maak nu een account aan bij fitnessmarkt.de'
  },
  DATA_PROTECTION_A: {
    en: 'I have taken note of the ',
    de: 'Die ',
    it: `Ho preso nota dell'informativa `,
    nl: 'Ik heb kennis genomen van het '
  },
  DATA_LINK: {
    en: 'privacy policy ',
    de: 'Datenschutzerklärung ',
    it: 'sulla privacy ',
    nl: 'privacybeleid '
  },
  DATA_PROTECTION_B: {
    en: 'of fitnessmarkt.de.',
    de: 'von fitnessmarkt.de habe ich zur Kenntnis genommen.',
    it: 'di fitnessmarkt.de.',
    nl: 'van fitnessmarkt.de.'
  },
  ADS_SUBSCRIBE: {
    en: 'Yes, I agree that ACISO and/or fitnessmarkt.de will send me the latest information about Bonus Club offers by mail or e-mail.',
    de: 'Ja, ich bin einverstanden, dass mir ACISO und/oder fitnessmarkt.de die neuesten Informationen zu Angeboten des Bonus Clubs postalisch oder per E-Mail schickt.',
    it: `Sì, sono d'accordo che ACISO e/o fitnessmarkt.de mi inviino le ultime informazioni sulle offerte del Bonus Club per posta o e-mail.`,
    nl: 'Ja, ik ga ermee akkoord dat ACISO en/of fitnessmarkt.de mij de laatste informatie over Bonus Club aanbiedingen per post of e-mail toestuurt.'
  },
  CANCEL_POLICY: {
    en: 'Cancellation policy',
    de: 'Widerrufsbelehrung',
    it: 'Politica di cancellazione',
    nl: 'Annuleringsbeleid'
  },
  MAIL_TO_CANCEL: {
    en: 'This consent can be revoked at any time. To do so, please send us a message by e-mail to datenschutz@fitnessmarkt.de or by mail to fitnessmarkt.de, Rollnerstr. 8, 90408 Nürnberg, Germany.',
    de: 'Diese Einwilligung kann jederzeit widerrufen werden. Dazu sende uns bitte eine Nachricht per E-Mail an datenschutz@fitnessmarkt.de oder per Post an fitnessmarkt.de, Rollnerstr. 8, 90408 Nürnberg',
    it: 'Questo consenso può essere revocato in qualsiasi momento. Per farlo, si prega di inviare un messaggio via e-mail a datenschutz@fitnessmarkt.de o per posta a fitnessmarkt.de, Rollnerstr. 8, 90408 Nürnberg, Germania.',
    nl: 'Deze toestemming kan te allen tijde worden herroepen. Stuur ons hiervoor een bericht per e-mail naar datenschutz@fitnessmarkt.de of per post naar fitnessmarkt.de, Rollnerstr. 8, 90408 Nürnberg, Duitsland.'
  },
  NO_SITE_FOUND: {
    "en": "No site is found with this profile.",
    "de": "Mit diesem Profil wurde keine Site gefunden.",
    "it": "Nessun sito trovato con questo profilo.",
    "nl": "Er is geen site gevonden met dit profiel."
  },
  PUT_SOMETHING_HERE : {
    en: 'Put something here.',
    de: 'Stellen Sie hier etwas ein.',
    it: 'Metti qualcosa qui.',
    nl: 'Zet hier iets neer.'
  },
  PREV: {
    en: 'Prev',
    de: 'Zurück',
    it: 'Precedente',
    nl: 'Vorige'
  },
  NEXT: {
    en: 'Next',
    de: 'Weiter',
    it: 'Prossimo',
    nl: 'Volgende'
  },
  SUBMIT:{
    en: 'Submit',
    de: 'Senden',
    it: 'Invia a',
    nl: 'Stuur een bericht naar'
  },
  UPDATE_COLUMN : {
    en: 'Update column',
    de: 'Spalte aktualisieren',
    it: 'Colonna di aggiornamento',
    nl: 'Kolom bijwerken'
  },
  COLUMN_WIDTH: {
    en: 'Column width',
    de: 'Spaltenbreite',
    it: 'Larghezza della colonna',
    nl: 'Kolombreedte'
  },
  SELECTED_COMPONENT: {
    en: 'Selected component',
    de: 'Ausgewählte Komponente',
    it: 'Componente selezionato',
    nl: 'Geselecteerde component'
  },
  TEXT_FIELD: {
    en: 'TextField',
    de: 'TextFeld',
    it: 'TextField',
    nl: 'TextField'
  },
  RADIO: {
    en: 'Radio',
    de: 'Radio',
    it: 'Radio',
    nl: 'Radio'
  },
  TEXT: {
    en: 'Text',
    de: 'Text',
    it: 'Testo',
    nl: 'Tekst'
  },
  CHECKBOX: {
    en: 'Checkbox',
    de: 'Checkbox',
    it: 'Checkbox',
    nl: 'Checkbox'
  },
  INPUT_FILE: {
    en: 'InputFile',
    de: 'EingabeDatei',
    it: 'InputFile',
    nl: 'InputFile'
  },
  IMAGE: {
    en: 'Image',
    de: 'Bild',
    it: 'Immagine',
    nl: 'Afbeelding'
  },
  PADDING: {
    en: 'Padding',
    de: 'Padding',
    it: 'Imbottitura',
    nl: 'Opvulling'
  },
  FIELD_LABEL: {
    en: 'Field label',
    de: 'Feldbezeichnung',
    it: 'Etichetta del campo',
    nl: 'Veld label'
  },
  SELECTED_IMAGE_URL: {
    en: 'Selected image URL',
    de: 'Ausgewählte Bild-URL',
    it: `URL dell'immagine selezionata`,
    nl: 'Geselecteerde afbeelding URL'
  },
  SELECT_IMAGE: {
    en: 'Select image',
    de: 'Bild auswählen',
    it: `Selezionare l'immagine`,
    nl: 'Selecteer afbeelding'
  },
  WIDTH: {
    en: 'Width',
    de: 'Breite',
    it: 'Larghezza',
    nl: 'Breedte'
  },
  HEIGHT: {
    en: 'Height',
    de: 'Höhe',
    it: 'Altezza',
    nl: 'Hoogte'
  },
  FIELD_NEED_THIS_TO_LINK_WITH_SERVER: {
    en: 'Field (need this to link with server)',
    de: 'Feld (wird für die Verknüpfung mit dem Server benötigt)',
    it: 'Campo (ha bisogno di questo per collegarsi con il server)',
    nl: 'Veld (dit is nodig voor de verbinding met de server)'
  },
  FIELD_VALUE: {
    en: 'Field value(need this to link with server)',
    de: 'Feldwert (wird für die Verknüpfung mit dem Server benötigt)',
    it: 'Valore del campo (serve per il collegamento con il server)',
    nl: 'Veldwaarde (nodig om te koppelen met server)'
  },
  FIELD_DESCRIPTION: {
    en: 'this field needs to be filled in and no space is allowed here.',
    de: 'Dieses Feld muss ausgefüllt werden, hier ist kein Leerzeichen erlaubt.',
    it: 'questo campo deve essere compilato e non sono ammessi spazi.',
    nl: 'dit veld moet worden ingevuld en hier is geen spatie toegestaan.'
  },
  SELECTION: {
    en: 'Selection(s)',
    de: 'Auswahl',
    it: 'Selezione',
    nl: 'Selectie'
  },
  LABEL: {
    en: 'Label',
    de: 'Feld',
    it: 'Etichetta',
    nl: 'Etiket'
  },
  ADD_SELECTION: {
    en: 'Add selection',
    de: 'Auswahl hinzufügen',
    it: 'Aggiungi la selezione',
    nl: 'Selectie toevoegen'
  },
  ENABLE_FLEXIBLE_OPTION: {
    en: 'Enable flexible option',
    de: 'Flexible Option einschalten',
    it: `Abilita l'opzione flessibile`,
    nl: 'Flexibele optie inschakelen'
  },
  DISABLE_FLEXIBLE_OPTION: {
    en: 'Disable flexible option',
    de: 'Flexible Option deaktivieren',
    it: `Disabilita l'opzione flessibile`,
    nl: 'Flexibele optie uitschakelen'
  },
  NO_CONTENT_FOUND: {
    en: 'No content found, please select a component to edit first.',
    de: 'Kein Inhalt gefunden, bitte wählen Sie zuerst eine Komponente zum Bearbeiten aus.',
    it: 'Nessun contenuto trovato, seleziona prima un componente da modificare.',
    nl: 'Geen inhoud gevonden, kies eerst een onderdeel om te bewerken.'
  },
  FONT_SIZE: {
    en: 'Font size',
    de: 'Schriftgröße',
    it: 'Dimensione del carattere',
    nl: 'Lettergrootte'
  },
  DATE_FIELD: {
    en: 'DateField',
    de: 'DatumFeld',
    it: 'Campo data',
    nl: 'Datumveld'
  },
  UPDATE_CONTENT: {
    en: 'Update content',
    de: 'Inhalt aktualisieren',
    it: 'Aggiorna il contenuto',
    nl: 'Inhoud bijwerken'
  },
  SELECT_COMPONENT: {
    en: 'Select component',
    de: 'Komponente auswählen',
    it: 'Seleziona il componente',
    nl: 'Selecteer component'
  },
  TEXT_FIELD_LABEL: {
    en: 'Your new textfield here',
    de: 'Ihr neues Textfeld hier',
    it: 'Il tuo nuovo campo di testo qui',
    nl: 'Uw nieuwe tekstveld hier'
  },
  SUBMIT_BUTTON: {
    en: 'Your submit button here',
    de: 'Ihr Sendebutton hier',
    it: 'Il tuo pulsante di invio qui',
    nl: 'Uw verzendknop hier'
  },
  RADIO_BUTTON_LABEL: {
    en: 'Your new radio button here',
    de: 'Ihr neuer Radiobutton hier',
    it: 'Il tuo nuovo pulsante radio qui',
    nl: 'Uw nieuwe radio knop hier'
  },
  TEXT_LABEL: {
    en: 'Your new text here.',
    de: 'Ihr neuer Text hier.',
    it: 'Il tuo nuovo testo qui.',
    nl: 'Uw nieuwe tekst hier.'
  },
  CHECKBOX_LABEL: {
    en: 'Your new checkbox here',
    de: 'Ihre neue checkbox hier',
    it: 'La tua nuova casella di controllo qui',
    nl: 'Uw nieuwe selectievakje hier'
  },
  INPUT_FILE_LABEL: {
    en: 'Your new input file here',
    de: 'Ihre neue Eingabedatei hier',
    it: 'Il tuo nuovo file di input qui',
    nl: 'Uw nieuwe invoerbestand hier'
  },
  DATE_FIELD_LABEL: {
    en: 'Your new datefield here',
    de: 'Ihr neues Datumsfeld hier',
    it: 'Il tuo nuovo campo data qui',
    nl: 'Je nieuwe datefield hier'
  },
  EDIT_FORM_FRAME: {
    en: 'Edit form frame',
    de: 'Formularrahmen bearbeiten',
    it: 'Modifica cornice modulo',
    nl: 'Formulier bewerken'
  },
  CONTENT: {
    en: 'Content',
    de: 'Inhalt',
    it: 'Contenuto',
    nl: 'Inhoud'
  },
  TITLE_STYLE: {
    en: 'Title style',
    de: 'Titel Stil',
    it: 'Stile del titolo',
    nl: 'Titel stijl'
  },
  COLOR: {
    en: 'Color',
    de: 'Farbe',
    it: 'Colore',
    nl: 'Kleur'
  },
  BACKGROUND_COLOR: {
    en: 'Background color',
    de: 'Hintergrundfarbe',
    it: 'Colore di sfondo',
    nl: 'Achtergrond kleur'
  },
  FONT_WEIGHT: {
    en: 'Font weight',
    de: 'Font weight',
    it: 'Peso del carattere',
    nl: 'Gewicht lettertype'
  },
  TEXT_ALIGN: {
    en: 'Text align',
    de: 'Text ausrichten',
    it: 'Allineare il testo',
    nl: 'Tekst uitlijnen'
  },
  ALIGN_LEFT: {
    en: 'Align left',
    de: 'Links ausrichten',
    it: 'Allinea a sinistra',
    nl: 'Links uitlijnen'
  },
  CENTER: {
    en: 'Center',
    de: 'Center',
    it: 'Centro',
    nl: 'Center'
  },
  ALIGN_RIGHT: {
    en: 'Align right',
    de: 'Rechts ausrichten',
    it: 'Allinea a destra',
    nl: 'Rechts uitlijnen'
  },
  CONTAINER: {
    en: 'Container',
    de: 'Container',
    it: 'Contenitore',
    nl: 'Container'
  },
  BORDER_WIDTH: {
    en: 'Border width',
    de: 'Breite des Randes',
    it: 'Larghezza del confine',
    nl: 'Breedte van de rand'
  },
  BORDER_COLOR: {
    en: 'Border color',
    de: 'Farbe der Umrandung',
    it: 'Colore del bordo',
    nl: 'Kleur rand'
  },
  VIEW: {
    en: 'View',
    de: 'Informationen',
    it: 'Vedi',
    nl: 'Bekijk'
  },
  DATA: {
    en: 'Data',
    de: 'Daten',
    it: 'Dati',
    nl: 'Gegevens'
  },
  SELECTION_LABEL: {
    en: 'Your new selection here',
    de: 'Ihre neue Auswahl hier',
    it: 'La tua nuova selezione qui',
    nl: 'Uw nieuwe selectie hier'
  },
  HEADING : {
    en: 'Digital order form builder',
    de: 'Digitaler-Bestellschein-Builder',
    it: `Costruttore di moduli d'ordine digitali`,
    nl: 'Digitaal bestelformulier maken'
  },
  SUBHEADING: {
    en: 'Builder for digital order form under Aciso.',
    de: 'Builder für digitales Bestellformular unter Aciso.',
    it: `Costruttore per il modulo d'ordine digitale sotto Aciso.`,
    nl: 'Bouwer voor digitale bestelbon onder Aciso.'
  },
  CREATE_NEW_FORM: {
    en: 'Create new form',
    de: 'Neues Formular erstellen',
    it: 'Creare un nuovo modulo',
    nl: 'Nieuw formulier maken'
  },
  ARCHIVED_FORM: {
    en: 'Archived form',
    de: 'Archivierte Form',
    it: 'Forma archiviata',
    nl: 'Gearchiveerde vorm'
  },
  CONFIRM_DESELECT_FORM_PAGE: {
    en: 'Are you sure you want to deselect this page? This action will reset the values of all input fields in this page.',
    de: 'Sind Sie sicher, dass Sie die Auswahl dieser Seite aufheben wollen? Diese Aktion setzt die Werte aller Eingabefelder auf dieser Seite zurück.',
    it: 'Sei sicuro di voler deselezionare questa pagina? Questa azione resetterà i valori di tutti i campi di input in questa pagina.',
    nl: 'Weet u zeker dat u deze pagina wilt deselecteren? Deze actie zal de waarden van alle invoervelden op deze pagina resetten.'
  },
  NAME : {
    en: 'Name',
    de: 'Name',
    it: 'Nome',
    nl: 'Naam'
  },
  STATUS: {
    en: 'Status',
    de: 'Status',
    it: 'Stato',
    nl: 'Status'
  },
  VIEW_DETAILS: {
    "en": "View Details",
    "de": "Details anzeigen",
    "it": "Visualizza dettagli",
    "nl": "Details bekijken"
  },
  BLACKLISTED_RECIPIENTS: {
    "en": "Blacklisted Recipients",
    "de": "Empfänger auf der schwarzen Liste",
    "it": "Destinatari nella lista nera",
    "nl": "Op de zwarte lijst geplaatste ontvangers"
  },
  LISTS_BLACKLISTED_RECIPIENTS: {
    "en": "Lists of the blacklisted recipients over here.",
    "de": "Listen der Empfänger auf der schwarzen Liste hier.",
    "it": "Liste dei destinatari nella lista nera qui.",
    "nl": "Lijsten met ontvangers op de zwarte lijst hier."
  },
  ACTIONS:{
    en: 'Actions',
    de: 'Aktionen',
    it: 'Azioni',
    nl: 'Acties'
  },
  CREATED_AT: {
    "en": "Created at",
    "de": "Erstellt am",
    "it": "Creato a",
    "nl": "Gemaakt bij"
  },
  EDIT: {
    en: 'Edit',
    de: 'Bearbeiten',
    it: 'Modifica',
    nl: 'Bewerk'
  },
  DELETE: {
    en: 'Delete',
    de: 'Löschen',
    it: 'Cancellare',
    nl: 'Delete'
  },
  COPY: {
    en: 'Copy',
    de: 'Kopieren',
    it: 'Copia',
    nl: 'Kopiëren'
  },
  SUBMITTED_ON: {
    en: 'Submitted On',
    de: 'Empfangen am',
    it: 'Inviato il',
    nl: 'Ingediend op'
  },
  VIEW_SUBMITTED_DATA: {
    en: 'View submitted data',
    de: 'Eingesendete Daten anzeigen',
    it: 'Visualizza i dati inviati',
    nl: 'Ingediende gegevens bekijken'
  },
  ARCHIVED_FORMS: {
    en: 'Archived Forms',
    de: 'Archivierte Aufträge',
    it: 'Moduli archiviati',
    nl: 'Gearchiveerde formulieren'
  },
  UNARCHIVED: {
    en: 'Unarchive',
    de: 'Unarchivieren',
    it: 'Unarchive',
    nl: 'Unarchive'
  },
  ACTION: {
    en: 'Action',
    de: 'Aktion',
    it: 'Azione',
    nl: 'Actie'
  },
  ADD_CONTENT: {
    en: 'Add content inside column',
    de: 'Inhalt der Spalte hinzufügen',
    it: `Aggiungere contenuto all'interno della colonna`,
    nl: 'Inhoud toevoegen in kolom'
  },
  ADD_NEW_COLUMN: {
    en: 'Add new column',
    de: 'Neue Spalte hinzufügen',
    it: 'Aggiungi una nuova colonna',
    nl: 'Nieuwe kolom toevoegen'
  },
  FORM_INFORMATION: {
    en: 'Form Information',
    de: 'Formular Informationen',
    it: 'Informazioni sul modulo',
    nl: 'Formulier Informatie'
  },
  FORM_NAME: {
    en: 'Form Name',
    de: 'Formular Name',
    it: 'Nome del modulo',
    nl: 'Vorm Naam'
  },
  CENTER_GROUPS: {
    en: 'Center Groups',
    de: 'Center Gruppe',
    it: 'Gruppi del centro',
    nl: 'Centrum Groepen'
  },
  ACTIVE: {
    en: 'Active',
    de: 'Aktiv',
    it: 'Attivo',
    nl: 'Actief'
  },
  INACTIVE: {
    en: 'Inactive',
    de: 'Inaktiv',
    it: 'Inattivo',
    nl: 'Inactief'
  },
  ARCHIVED: {
    en: 'Archived',
    de: 'Archiviert',
    it: 'Archiviato',
    nl: 'Gearchiveerd'
  },
  SUBMIT_GEN_INFO: {
    "en": "Submit General Info",
    "de": "Allgemeine Informationen einreichen",
    "it": "Invia informazioni generali",
    "nl": "Dien algemene informatie in"
  },
  RECIPIENT: {
    "en": "Recipient",
    "de": "Empfänger",
    "it": "Destinatario",
    "nl": "Ontvanger"
  },
  ACTIVE_RECIPIENT: {
    en: 'Active Recipient',
    de: 'Aktiver Empfänger',
    it: 'Destinatario Attivo',
    nl: 'Actieve Ontvanger'
  },
  ADD_RECIPIENT: {
    "en": "Add Recipient",
    "de": "Empfänger hinzufügen",
    "it": "Aggiungere il destinatario",
    "nl": "Voeg ontvanger toe"
  },
  NAME_IS_REQUIRED: {
    en: 'Name is required',
    de: 'Name ist erforderlich',
    it: 'Il nome è richiesto',
    nl: 'Naam is verplicht'
  },
  VALUE_IS_REQUIRED: {
    en: 'Form value(s) is required',
    de: 'Formularwert ist erforderlich',
    it: 'Il valore del modulo è richiesto',
    nl: 'Formulier waarde is vereist'
  },
  DELETE_CONFIRMATION: {
    en: 'Are you sure to remove selected component?',
    de: 'Sind Sie sicher, dass Sie die ausgewählte Komponente entfernen wollen?',
    it: 'Sei sicuro di rimuovere il componente selezionato?',
    nl: 'Weet u zeker dat u het geselecteerde onderdeel wilt verwijderen?'
  },
  ADD_PAGE: {
    en: 'Add Page',
    de: 'Seite hinzufügen',
    it: 'Aggiungi pagina',
    nl: 'Pagina toevoegen'
  },
  REMOVE_PAGE: {
    en: 'Remove Page',
    de: 'Seite entfernen',
    it: 'Rimuovi pagina',
    nl: 'Pagina verwijderen'
  },
  SUCCESS_MESSAGE: {
    en: 'Page is added successfully.',
    de: 'Seite wurde erfolgreich hinzugefügt.',
    it: 'La pagina viene aggiunta con successo.',
    nl: 'Pagina is succesvol toegevoegd.'
  },
  SELECT_PLACEHOLDER: {
    en: 'Please select one from below',
    de: 'Bitte wählen Sie eine der folgenden Optionen aus',
    it: 'Si prega di selezionare uno dei seguenti',
    nl: 'Kies er een van hieronder'
  },
  UPDATE: {
    en: 'Update',
    de: 'Aktualisierung',
    it: 'Aggiorna',
    nl: 'Update'
  },
  CREATE: {
    en: 'Create',
    de: 'Erstellen',
    it: 'Crea',
    nl: 'Creëer'
  },
  FORM: {
    en: 'Form',
    de: 'Formular',
    it: 'Modulo',
    nl: 'Formulier'
  },
  VIEW_RESULTS: {
    en: 'View Results',
    de: 'Ergebnisse anzeigen',
    it: 'Visualizza i risultati',
    nl: 'Bekijk resultaten'
  },
  SUBMITTED_FROM: {
    en: 'Submitted from : ',
    de: 'Eingereicht von : ',
    it: 'Inviato da : ',
    nl: 'Ingezonden van : '
  },
  VALUE: {
    en: 'Value(s)',
    de: 'Wert',
    it: 'Valore',
    nl: 'Waarde'
  },
  TEST_FORM_TITLE: {
    en: 'Test form title',
    de: 'Titel der Testform',
    it: 'Titolo del modulo di test',
    nl: 'Titel testformulier'
  },
  DELETE_CONFIRMATION_MESSAGE: {
    en: 'Are you sure you want to delete the form?',
    de: 'Sind Sie sicher, dass Sie das Formular löschen wollen?',
    it: 'Sei sicuro di voler cancellare il modulo?',
    nl: 'Weet u zeker dat u het formulier wilt verwijderen?'
  },
  FILL_FORM: {
    en: 'Fill Form',
    de: 'Formular ausfüllen',
    it: 'Compila il modulo',
    nl: 'Vul formulier'
  },
  DIGITAL_ORDER_FORM_LISTING_HEADING: {
    en: 'Digital order form listings',
    de: 'Digitale Bestellformular-Listen',
    it: `Elenchi di moduli d'ordine digitali`,
    nl: 'Digitale bestelformulier lijsten'
  },
  DIGITAL_ORDER_FORM_LISTING_SUBHEADING: {
    en: 'Listings of all the digital order form under Aciso.',
    de: 'Auflistung aller digitalen Bestellformulare unter Aciso.',
    it: 'Elenchi di tutte le forme di ordine digitale sotto Aciso.',
    nl: 'Lijst van alle digitale bestelformulieren onder Aciso.'
  },
  SELECT_FORM: {
    en: 'Select form',
    de: 'Formular auswählen',
    it: 'Selezionare la forma',
    nl: 'Selecteer vorm'
  },
  DISPLAY: {
    en: 'Display',
    de: 'Anzeige',
    it: 'Visualizza',
    nl: 'Weergave'
  },
  FORM_VALUES: {
    en: 'Form Values:',
    de: 'Inhalt Bestellschein:',
    it: 'Valori di forma:',
    nl: 'Vorm Waarden:'
  },
  PHONE_NUMBER:{
    en: 'Phone number',
    de: 'Telefonnummer',
    it: 'Numero di telefono',
    nl: 'Telefoonnummer'
  },
  SELECT_LOCATION:{
    en: 'Select location',
    de: 'Standort auswählen',
    it: 'Selezionare la posizione',
    nl: 'Selecteer locatie'
  },
  LOCATION:{
    en: 'Location',
    de: 'Standort',
    it: 'Posizione',
    nl: 'Locatie'
  },
  CREATE_LOCATION:{
    en: 'Create location',
    de: 'Standort erstellen',
    it: 'Creare la posizione',
    nl: 'Creëer locatie'
  },
  UPDATE_LOCATION:{
    en: 'Update location',
    de: 'Ort der Aktualisierung',
    it: 'Aggiornare la posizione',
    nl: 'Locatie bijwerken'
  },
  EXTRA_FIELD:{
    en: 'Extra field',
    de: 'Extra-Feld',
    it: 'Campo extra',
    nl: 'Extra veld'
  },
  COUNTRY: {
    en: 'Country',
    de: 'Land',
    it: 'Paese',
    nl: 'Land'
  },
  STATE:{
    en: 'State',
    de: 'Staat',
    it: 'Stato',
    nl: 'Geef aan'
  },
  CITY:{
    en: 'City',
    de: 'Stadt',
    it: 'Città',
    nl: 'Stad'
  },
  POSTCODE:{
    en: 'Postcode',
    de: 'Postleitzahl',
    it: 'Codice postale',
    nl: 'Postcode'
  },
  STREET:{
    en: 'Street',
    de: 'Straße',
    it: 'Via',
    nl: 'Straat'
  },
  HOUSE_NUMBER:{
    en: 'House number',
    de: 'Hausnummer',
    it: 'Numero civico',
    nl: 'Huisnummer'
  },
  AREA_CODE: {
    en: 'Enter area code',
    de: 'Vorwahl eingeben',
    it: 'Inserire il prefisso',
    nl: 'Vul het netnummer in'
  },
  ENTER_PHONE_NUMBER: {
    en: 'Enter phone number',
    de: 'Telefonnummer eingeben',
    it: 'Inserire il numero di telefono',
    nl: 'Voer het telefoonnummer in'
  },
  FAX_NUMBER:{
    en: 'Fax number',
    de: 'Fax-Nummer',
    it: 'Numero di fax',
    nl: 'Faxnummer'
  },
  CLOSED: {
    en: 'Closed',
    de: 'Geschlossen',
    it: 'Chiuso',
    nl: 'Gesloten'
  },
  DOUBLE_INTERVAL: {
    en: 'Double interval',
    de: 'Doppelter Abstand',
    it: 'Doppio intervallo',
    nl: 'Dubbel interval'
  },
  SINGLE_INTERVAL: {
    en: 'Single interval',
    de: 'Einzelnes Intervall',
    it: 'Intervallo singolo',
    nl: 'Enkel interval'
  },
  UPDATE_OPENING_HOUR: {
    en: 'Update opening hour',
    de: 'Öffnungszeit aktualisieren',
    it: 'Aggiornamento orario di apertura',
    nl: 'Update openingstijd'
  },
  CREATE_OPENING_HOUR: {
    en: 'Create opening hour',
    de: 'Öffnungsstunde erstellen',
    it: 'Creare l\'orario di apertura',
    nl: 'Creëer openingstijd'
  },
  SETUP_LOCATIONS_OVER_HERE: {
    en: 'Setup locations over here',
    de: 'Standort Settings',
    it: 'Posizioni di installazione qui',
    nl: 'Opstelplaatsen hier'
  },
  SHOW_AT_FOOTER: {
    en: 'Show at footer',
    de: 'Im footer anzeigen',
    it: 'Mostra a piè di pagina',
    nl: 'Toon bij footer'
  },
  REMOVE_RECIPIENT_GROUP: {
    "en": "Remove recipient group",
    "de": "Empfängergruppe entfernen",
    "it": "Rimuovi gruppo destinatari",
    "nl": "Verwijder ontvangergroep"
  },
  GROUP: {
    en: 'Group',
    de: 'Gruppe',
    it: 'Gruppo',
    nl: 'Groep'
  },
  SELECT_GROUP: {
    en: 'Select a group',
    de: 'Wählen Sie eine Gruppe aus',
    it: 'Seleziona un gruppo',
    nl: 'Selecteer een groep'
  },
  EMPTY_GROUP: {
    "en": "Empty group",
    "de": "Leere Gruppe",
    "it": "Gruppo vuoto",
    "nl": "Lege groep"
  },
  SURE_TO_EMPTY_GROUP: {
    "en": " Are you sure you want to empty all recipient data from this group?",
    "de": "Möchten Sie wirklich alle Empfängerdaten aus dieser Gruppe leeren?",
    "it": "Sei sicuro di voler svuotare tutti i dati del destinatario da questo gruppo?",
    "nl": "Weet u zeker dat u alle ontvangersgegevens uit deze groep wilt wissen?"
  },
  GENERAL: {
    en: 'General',
    de: 'Allgemein',
    it: 'Generale',
    nl: 'Algemeen'
  },
  OPENING_HOURS: {
    en: 'Opening hours',
    de: 'Öffnungszeiten',
    it: 'Orari di apertura',
    nl: 'Openingstijden'
  },
  ADD_TIME_WINDOW: {
    en: 'add a time window to open',
    de: 'ein Zeitfenster zum Öffnen hinzufügen',
    it: 'aggiungere una finestra temporale da aprire',
    nl: 'voeg een tijdvenster toe om te openen'
  },
  CATEGORIES: {
    en: 'Categories',
    de: 'Kategorien',
    it: 'Categorie',
    nl: 'Categorieën'
  },
  CONTACTS: {
    en: 'Contacts',
    de: 'Kontakte',
    it: 'Contatti',
    nl: 'Contacten'
  },
  UPDATE_CONTACT: {
    en: 'Update Contact',
    de: 'Update Kontakt',
    it: 'Aggiornare il contatto',
    nl: 'Contact bijwerken'
  },
  MAIN_PHONE: {
    en: 'Main Phone',
    de: 'Haupttelefon',
    it: 'Telefono principale',
    nl: 'Hoofd telefoon'
  },
  TRACKED: {
    en: 'tracked',
    de: 'Verfolgt',
    it: 'tracciato',
    nl: 'op rupsbanden'
  },
  ALTERNATIVE_PHONE: {
    en: 'Alternative Phone',
    de: 'Alternativ Telefon',
    it: 'Telefono alternativo',
    nl: 'Alternatieve telefoon'
  },
  PHONE: {
    en: 'Phone',
    de: 'Telefon',
    it: 'Telefono',
    nl: 'Telefoon'
  },
  PHONE_TYPE: {
    en: 'Phone Type',
    de: 'Telefon Typ',
    it: 'Tipo di telefono',
    nl: 'Type telefoon'
  },
  LANDLINE: {
    en: 'Landline',
    de: 'Festnetz',
    it: 'Linea fissa',
    nl: 'Landline'
  },
  FAX: {
    en: 'Fax',
    de: 'Fax',
    it: 'Fax',
    nl: 'Fax'
  },
  MOBILE: {
    en: 'Mobile',
    de: 'Mobil',
    it: 'Mobile',
    nl: 'Mobiel'
  },
  ADD: {
    en: 'Add',
    de: 'hinzufügen',
    it: 'Aggiungi',
    nl: 'Toevoegen'
  },
  REMOVE: {
    en: 'Remove',
    de: 'Entfernen',
    it: 'Rimuovi',
    nl: 'Verwijder'
  },
  WEBSITE_URL: {
    en: 'Website Url',
    de: 'Website Url',
    it: 'Url del sito web',
    nl: 'Website Url'
  },
  WEBSITE_DISPLAY: {
    en: 'Website Display',
    de: 'Website-Anzeige',
    it: 'Display del sito web',
    nl: 'Website Weergave'
  },
  CURRENT_OFFER: {
    en: 'Current Offer',
    de: 'Aktuelles Angebot',
    it: 'Offerta attuale',
    nl: 'Huidige aanbieding'
  },
  CURRENT_OFFER_TEXT: {
    en: 'Current Offer Text',
    de: 'Aktueller Angebotstext',
    it: `Testo dell'offerta attuale`,
    nl: 'Huidige aanbieding tekst'
  },
  CURRENT_OFFER_URL: {
    en: 'Current Offer URL',
    de: 'Aktuelles Angebot URL',
    it: `URL dell'offerta attuale`,
    nl: 'Huidige aanbieding URL'
  },
  UPDATE_CURRENT_OFFER: {
    en: 'Update Current Offer',
    de: 'Aktuelles Angebot aktualisieren',
    it: `Aggiorna l'offerta corrente`,
    nl: 'Huidige aanbieding bijwerken'
  },
  ADDITIONAL_INFO: {
    en: 'Additional Informations',
    de: 'Zusätzliche Informationen',
    it: 'Informazioni aggiuntive',
    nl: 'Aanvullende informatie'
  },
  UPDATE_ADDITIONAL_INFO: {
    en: 'Update Additional Info',
    de: 'Zusätzliche Informationen aktualisieren',
    it: 'Aggiornare le informazioni aggiuntive',
    nl: 'Extra informatie bijwerken'
  },
  ADDITIONAL_INFO_DESCRIPTION: {
    en: 'Description',
    de: 'Beschreibung',
    it: 'Descrizione',
    nl: 'Beschrijving'
  },
  FOUNDING_YEAR: {
    en: 'Founding Year',
    de: 'Gründungsjahr',
    it: 'Anno di fondazione',
    nl: 'Oprichtingsjaar'
  },
  PRODUCTS: {
    en: 'Products',
    de: 'Produkte',
    it: 'Prodotti',
    nl: 'Producten'
  },
  SERVICES: {
    en: 'Services',
    de: 'Dienste',
    it: 'Servizi',
    nl: 'Diensten'
  },
  SPECIAL_FIELDS: {
    en: 'Special Fields',
    de: 'Spezielle Felder',
    it: 'Campi speciali',
    nl: 'Speciale velden'
  },
  MEMBERSHIPS: {
    en: 'Memberships',
    de: 'Mitgliedschaften',
    it: 'Iscrizioni',
    nl: 'Lidmaatschappen'
  },
  OFFERED_BRANDS: {
    en: 'Offered Brands',
    de: 'Angebotene Marken',
    it: 'Marche offerte',
    nl: 'Aangeboden merken'
  },
  LANGUAGES: {
    en: 'Languages',
    de: 'Sprachen',
    it: 'Lingue',
    nl: 'Talen'
  },
  KEYWORDS: {
    en: 'Keywords',
    de: 'Stichwörter',
    it: 'Parole chiave',
    nl: 'Trefwoorden'
  },
  PAYMENT_METHODS: {
    en: 'Payment Methods',
    de: 'Bezahlmethoden',
    it: 'Metodi di pagamento',
    nl: 'Betalingswijzen'
  },
  POSSIBLE_PAYMENT_METHODS: {
    en: 'Possible Payment Methods',
    de: 'Mögliche Zahlungsarten',
    it: 'Possibili metodi di pagamento',
    nl: 'Mogelijke betalingswijzen'
  },
  IMPRINT: {
    en: 'Imprint',
    de: 'Impressum',
    it: 'Impronta',
    nl: 'Afdruk'
  },
  LOCATION_IMPRINT: {
    en: 'Location Imprint',
    de: 'Standort Impressum',
    it: 'Impronta della posizione',
    nl: 'Plaats Afdruk'
  },
  DATA_PRIVACY: {
    en: 'Data Privacy',
    de: 'Datenschutz',
    it: 'Privacy dei dati',
    nl: 'Gegevensbescherming'
  },
  LOCATION_DATA_PRIVACY: {
    en: 'Location Data Privacy',
    de: 'Datenschutz für Standortdaten',
    it: 'Privacy dei dati di localizzazione',
    nl: 'Privacy van locatiegegevens'
  },
  IMPRINT_URL: {
    en: 'Imprint URL',
    de: 'Impressum URL',
    it: `URL dell'impronta`,
    nl: 'Afdruk URL'
  },
  DATA_PRIVACY_URL: {
    en: 'Data Privacy URL',
    de: 'Datenschutz-URL',
    it: 'URL sulla privacy dei dati',
    nl: 'URL gegevensbescherming'
  },
  SEARCH: {
    en: 'Search',
    de: 'Suche',
    it: 'Cerca',
    nl: 'Zoeken op'
  },
  ADD_CATEGORY : {
    en: 'Add Category',
    de: 'Kategorie hinzufügen',
    it: 'Aggiungi categoria',
    nl: 'Categorie toevoegen'
  },
  SELECTED_CATEGORIES : {
    en: 'Selected categories:',
    de: 'Ausgewählte Kategorien:',
    it: 'Categorie selezionate:',
    nl: 'Geselecteerde categorieën:'
  },
  CREATE_BLACKLISTED_RECIPIENT: {
    en: 'Create Blacklisted Recipients',
    de: 'Empfänger auf der schwarzen Liste erstellen',
    it: 'Creare un destinatario in lista nera',
    nl: 'Maak een ontvanger op de zwarte lijst'
  },
  CANCEL: {
    en: 'Cancel',
    de: 'Abbrechen',
    it: 'Annulla',
    nl: 'Annuleer'
  },
  UPLOAD_IMAGE: {
    en: 'Upload Image',
    de: 'Bild hochladen',
    it: 'Carica immagine',
    nl: 'Afbeelding uploaden'
  },
  UPLOAD_IMAGES : {
    en: 'Upload Images',
    de: 'Bilder hochladen',
    it: 'Carica immagini',
    nl: 'Afbeeldingen uploaden'
  },
  IMAGES : {
    en: 'Images',
    de: 'Bilder',
    it: 'Immagini',
    nl: 'Afbeeldingen'
  },
  BUSINESS_LOGO : {
    en: 'Business Logo',
    de: 'Geschäftslogo',
    it: 'Logo aziendale',
    nl: 'Bedrijfslogo'
  },
  UPLOAD : {
    en: 'Upload',
    de: 'Hochladen',
    it: 'Carica',
    nl: 'Upload'
  },
  CUSTOM_IMAGES : {
    en: 'Custom Images',
    de: 'Benutzerdefinierte Bilder',
    it: 'Immagini personalizzate',
    nl: 'Aangepaste afbeeldingen'
  },
  FACEBOOK_PROFILE : {
    en: 'Facebook Profile',
    de: 'Facebook-Profil',
    it: 'Profilo Facebook',
    nl: 'Facebook profiel'
  },
  FACEBOOK_COVER : {
    en: 'Facebook Cover',
    de: 'Facebook-Cover',
    it: 'Copertina di Facebook',
    nl: 'Facebook omslag'
  },
  GOOGLE_PROFILE : {
    en: 'Google Profile',
    de: 'Google-Profil',
    it: 'Profilo di Google',
    nl: 'Google Profiel'
  },
  GOOGLE_COVER : {
    en: 'Google Cover',
    de: 'Google-Cover',
    it: 'Copertina di Google',
    nl: 'Google Cover'
  },
  ADD_IMAGES : {
    en: 'Add Images',
    de: 'Bilder hinzufügen',
    it: 'Aggiungere immagini',
    nl: 'Afbeeldingen toevoegen'
  },
  EXTERNAL_URL : {
    en: 'External URL',
    de: 'Externe URL',
    it: 'URL esterno',
    nl: 'Externe URL'
  },
  RESERVATIONS_URL : {
    en: 'Reservations URL',
    de: 'Reservierungen URL',
    it: 'Prenotazioni URL',
    nl: 'Reserveringen URL'
  },
  MENU_URL : {
    en: 'Menu URL',
    de: 'Menü-URL',
    it: 'URL del menu',
    nl: 'Menu URL'
  },
  ORDER_URL : {
    en: 'Order URL',
    de: 'Bestell-URL',
    it: 'Ordine URL',
    nl: 'Bestel URL'
  },
  INSTAGRAM_URL : {
    en: 'Instagram URL',
    de: 'Instagram-URL',
    it: 'URL di Instagram',
    nl: 'Instagram URL'
  },
  YOUTUBE_URL: {
    en: 'Youtube URL',
    de: 'Youtube-URL',
    it: 'URL di Youtube',
    nl: 'Youtube URL'
  },
  SUCCESS_SEND_REQUEST: {
    "en": "The request is sent successfully.",
    "de": "Die Anfrage wurde erfolgreich gesendet.",
    "it": "La richiesta è stata inviata con successo.",
    "nl": "Het verzoek is succesvol verzonden."
  },
  REQUEST_INFO: {
    "en": "Request Info",
    "de": "Infos anfordern",
    "it": "Richiedi informazioni",
    "nl": "Informatie aanvragen"
  },
  BOOK_PRODUCT_CONFIRMATION: {
    "en": "Are you sure you want to book this product?",
    "de": "Sind Sie sicher, dass Sie dieses Produkt buchen möchten?",
    "it": "Sei sicuro di voler prenotare questo prodotto?",
    "nl": "Weet u zeker dat u dit product wilt boeken?"
  },
  BOOKED_PRODUCT_SUCCESSFULLY: {
    "en": "Booked product successfully.",
    "de": "Das Produkt wurde erfolgreich gebucht.",
    "it": "Prodotto prenotato con successo.",
    "nl": "Product met succes geboekt."
  },
  SUBSCRIBE_LOCAL_LISTINGS: {
    en: 'You have subcribed to local listings.',
    de: 'Sie haben Local Listing abonniert.',
    it: 'Ti sei abbonato agli annunci locali.',
    nl: 'U bent geabonneerd op lokale lijsten.'
  },
  NOT_SUBSCRIBE: {
    en: 'You have not subscribed to local listings',
    de: 'Sie haben Local Listing nicht abonniert',
    nl: 'U heeft zich niet geabonneerd op lokale lijsten',
    it: 'Non ti sei iscritto agli annunci locali'
  },
  CLICK_FOR_MORE_INFO: {
    en: 'Click for more info',
    de: 'Klicken Sie für weitere Informationen',
    it: 'Clicca per maggiori informazioni',
    nl: 'Klik voor meer info'
  },
  SEND_REQUEST_SUCCESS: {
    "en": "The request is sent successfully.",
    "de": "Die Anfrage wurde erfolgreich gesendet.",
    "it": "La richiesta è stata inviata con successo.",
    "nl": "Het verzoek is succesvol verzonden."
  },
  REQUESTED_INFO: {
    "en": "Requested info",
    "de": "Angeforderte Informationen",
    "it": "Informazioni richieste",
    "nl": "Gevraagde info"
  },
  PRODUCT_INFO: {
    en: 'Product Info',
    de: 'Produkt-Infos',
    it: 'Informazioni sul prodotto',
    nl: 'Product Info'
  },
  ALL_PRODUCTS: {
    en: 'All Products',
    de: 'Alle Produkte',
    it: 'Tutti i prodotti',
    nl: 'Alle Producten'
  },
  VIEW_ALL: {
    en: 'Sales',
    de: 'Alle',
    it: 'Vendite',
    nl: 'Verkoop'
  },
  ALREADY_REQUESTED_INFO: {
    "en": "You have already requested information on this product. If you haven't received it yet, contact your advisor",
    "de": "Sie haben bereits Informationen zu diesem Produkt angefordert. Wenn Sie es noch nicht erhalten haben, wenden Sie sich an Ihren Berater",
    "it": "Hai già richiesto informazioni su questo prodotto. Se non l'hai ancora ricevuto, contatta il tuo consulente",
    "nl": "U heeft al informatie over dit product aangevraagd. Neem contact op met uw adviseur als u deze nog niet heeft ontvangen"
  },
  DOWNLOAD_PDF: {
    en: 'Download PDF',
    de: 'PDF herunterladen',
    it: 'Scaricare PDF',
    nl: 'Download PDF'
  },
  KEY_FIGURES: {
    en: 'Key Figures',
    de: 'Mein Erfolgsreport',
    it: 'Cifre chiave',
    nl: 'Kerncijfers'
  },
  LEADS : {
    en: 'Leads',
    de: 'Leads',
    it: 'Porta',
    nl: 'Leads'
  },
  LEADS_DESCRIPTION : {
    en: 'Leads across all campaigns in the selected period (for the whole month)',
    de: 'Leads über alle Kampagnen im gewählten Zeitraum (für den gesamten Monat)',
    it: 'Leads di tutte le campagne nel periodo selezionato (per tutto il mese)',
    nl: 'Leads over alle campagnes in de geselecteerde periode (voor de hele maand)'
  },
  REQUEST_FORMS : {
    en: 'Request Forms',
    de: 'Formular Anfragen',
    it: 'Moduli di richiesta',
    nl: 'Aanvraagformulieren'
  },
  INQUIRIES_FORMS : {
    en: 'Inquiries about all forms in the selected period',
    de: 'Anfragen zu allen Formularen im gewählten Zeitraum',
    it: 'Richieste su tutte le forme nel periodo selezionato',
    nl: 'Inlichtingen over alle formulieren in de gekozen periode'
  },
  NUMBER_OF_SALES : {
    en: 'Number of Sales',
    de: 'Anzahl der Verkäufe',
    it: 'Numero di vendite',
    nl: 'Aantal verkopen'
  },
  NUMBER_OF_SALES_DESCRIPTION : {
    en: 'Number of sales in the selected period',
    de: 'Anzahl der Verkäufe im gewählten Zeitraum',
    it: 'Numero di vendite nel periodo selezionato',
    nl: 'Aantal verkopen in de geselecteerde periode'
  },
  SALES : {
    en: 'Sales',
    de: 'Umsatz',
    it: 'Vendite',
    nl: 'Verkoop'
  },
  SALES_ACCUMULATED : {
    en: 'Sales accumulated in the selected period',
    de: 'Kumulierter Umsatz in der gewählten Periode',
    it: 'Vendite accumulate nel periodo selezionato',
    nl: 'Gecumuleerde verkopen in de geselecteerde periode'
  },
  LATEST_OLS_CAMPAIGNS: {
    en: 'Campaigns',
    de: 'Kampagnen',
    it: 'Campagne',
    nl: 'Campagnes'
  },
  ALL_OLS_CAMPAIGNS: {
    en: 'All OLS Campaigns',
    de: 'Alle OLS-Kampagnen',
    it: 'Tutte le campagne OLS',
    nl: 'Alle OLS-campagnes'
  },
  NEWS: {
    en: 'News',
    de: 'News',
    it: 'Notizie',
    nl: 'Nieuws'
  },
  NEWS_INFO: {
    en: 'News Info',
    de: 'Neuigkeiten',
    it: 'Informazioni sulle notizie',
    nl: 'Nieuws Info'
  },
  ALL_NEWS_FROM_ACISO: {
    en: 'All News from Aciso',
    de: 'Alle Neuigkeiten von Aciso',
    it: 'Tutte le notizie da Aciso',
    nl: 'Alle nieuws van Aciso'
  },
  ASSIGN_SHORTCUT_MENU: {
    en: 'Assign Shortcut Menu',
    de: 'Meine Startseite bearbeiten',
    it: 'Assegnazione del menu di scelta rapida',
    nl: 'Snelmenu toewijzen'
  },
  INSTRUCTION:{
    en: 'Assign your shortcut menu by dragging the items from left to right column',
    de: 'Weisen Sie Ihr Kontextmenü zu, indem Sie die Elemente von der linken zur rechten Spalte ziehen',
    it: 'Assegna il tuo menu di scelta rapida trascinando le voci da sinistra a destra della colonna',
    nl: 'Wijs uw snelmenu toe door de items van de linker naar de rechter kolom te slepen'
  },
  ASSIGN: {
    en: 'Assign',
    de: 'Zuweisen',
    it: 'Assegnare',
    nl: 'Toewijzen'
  },
  CHANGE_BUDGET: {
    en: 'Change Budget',
    de: 'Budget ändern',
    it: 'Cambiare il budget',
    nl: 'Wijziging van het budget'
  },
  START: {
    en: 'Start',
    de: 'Start',
    it: 'Iniziare',
    nl: 'Begin met'
  },
  END: {
    en: 'End',
    de: 'Ende',
    it: 'Fine',
    nl: 'Einde'
  },
  FACEBOOK_BUDGET: {
    en: 'Facebook Budget',
    de: 'Facebook-Budget',
    it: 'Facebook Budget',
    nl: 'Facebook Begroting'
  },
  GOOGLE_BUDGET: {
    en: 'Google Budget',
    de: 'Google-Budget',
    it: 'Google Budget',
    nl: 'Google Begroting'
  },
  BOOK: {
    en: 'Book',
    de: 'Buchen',
    it: 'Prenota',
    nl: 'Boeken'
  },
  SAMPLE_LANDING_PAGES: {
    en: 'Sample Landing Page',
    de: 'Muster Landingpage',
    it: 'Pagina di atterraggio campione',
    nl: 'Voorbeeld landingspagina'
  },
  CAMPAIGN: {
    en: 'Campaign',
    de: 'Kampagnen',
    it: 'Campagna',
    nl: 'Campagne'
  },
  VIEW_ALL_NEWS: {
    en: 'View All News',
    de: 'Alle News anzeigen',
    it: 'Visualizza tutte le notizie',
    nl: 'Bekijk alle nieuws'
  },
  VIEW_ALL_PRODUCTS:{
    en: 'View All Products',
    de: 'Alle Produkte anzeigen',
    it: 'Visualizza tutti i prodotti',
    nl: 'Bekijk alle producten'
  },
  ALL_INFO_HERE: {
    en: `All Info's Here`,
    de: 'Alle Informationen',
    it: 'Tutte le info sono qui',
    nl: `Alle info's hier`
  },
  BOOKED: {
    en: 'BOOKED',
    de: 'GEBUCHT',
    it: 'PRENOTATO',
    nl: 'BOEKEN'
  },
  CAMPAIGN_INFO: {
    en: 'Campaign Info',
    de: 'Kampagnen-Info',
    it: 'Info campagna',
    nl: 'Campagne Info'
  },
  BOOK_CAMPAIGN: {
    en: 'Book Campaign',
    de: 'Kampagnen Buchung',
    it: 'Campagna del libro',
    nl: 'Boek Campagne'
  },
  CAMPAIGN_NAME: {
    en: 'Campaign Name',
    de: 'Name der Kampagne',
    it: 'Nome della campagna',
    nl: 'Campagne naam'
  },
  START_ON: {
    en: 'Start on',
    de: 'Start am',
    it: 'Inizia su',
    nl: 'Starten'
  },
  END_DATE: {
    en: 'End Date',
    de: 'Enddatum',
    it: 'Data di fine',
    nl: 'Einddatum'
  },
  PRIORITY: {
    en: 'Priority',
    de: 'Priorität',
    it: 'Priorità',
    nl: 'Prioriteit'
  },
  REMARK: {
    en: 'Remark',
    de: 'Bemerkung',
    it: 'Nota',
    nl: 'Opmerking'
  },
  REMARKS: {
    en: 'Remarks',
    de: 'Bemerkungen',
    it: 'Osservazioni',
    nl: 'Opmerkingen'
  },
  PLEASE_SELECT_ONE_FROM_BELOW:{
    en: 'Please select one from below',
    de: 'Bitte wählen Sie eine der folgenden Optionen aus',
    it: 'Si prega di selezionare uno dei seguenti',
    nl: 'Kies er een van hieronder'
  },
  NOTE: {
    en: 'Note: The campaign duration is always 8 weeks',
    de: 'Hinweis: Die Kampagnendauer beträgt immer 8 Wochen',
    it: 'Nota: la durata della campagna è sempre di 8 settimane',
    nl: 'Opmerking: De duur van de campagne is altijd 8 weken'
  },
  LANDING_PAGES: {
    en: 'Landing Pages',
    de: `Landing Pages`,
    it: 'Pagine di atterraggio',
    nl: `Landingspagina's`
  },
  LANDING_PAGES_MAN: {
    en: 'Landing Pages Man: ',
    de: 'Landing Pages Man',
    it: 'Pagine di atterraggio Uomo',
    nl: `Landingspagina's Man`
  },
  LANDING_PAGES_WOMAN: {
    en: 'Landing Pages Woman: ',
    de: 'Landing Pages Frau',
    it: 'Pagine di atterraggio Donna',
    nl: `Landingspagina's Vrouw`
  },
  ADWORDS_BUDGET: {
    en: 'Adwords Budget',
    de: 'Adwords-Budget',
    it: 'Adwords Budget',
    nl: 'Adwords Begroting'
  },
  COST_PER_LEAD: {
    en: 'Cost per Lead',
    de: 'Kosten pro Lead',
    it: 'Costo per Lead',
    nl: 'Kosten per lead'
  },
  PRIORITY_NOTE: {
    en: 'Here you can specify the priority of a campaign, the higher the number the higher the priority',
    de: 'Hier können sie die Priorität einer Kampagne angeben, um so höher die Zahl um so höher die Priorität.',
    it: 'Qui è possibile specificare la priorità di una campagna, più alto è il numero più alta è la priorità',
    nl: 'Hier kunt u de prioriteit van een campagne aangeven, hoe hoger het getal hoe hoger de prioriteit.'
  },
  PRODUCT_HEADING : {
    en: 'Products',
    de: 'Produkte',
    it: 'Prodotti',
    nl: 'Producten'
  },
  PRODUCT_SUBHEADING : {
    en: 'Listings of all the products under Aciso.',
    de: 'Auflistung aller Produkte unter Aciso.',
    it: 'Elenchi di tutti i prodotti sotto Aciso.',
    nl: 'Lijst van alle producten onder Aciso.'
  },
  MESSAGE_CONSULTANT_DETAILS: {
    "en": "You can message your consultant here.",
    "de": "Hier können Sie Ihrem Berater eine Nachricht senden.",
    "it": "Puoi inviare un messaggio al tuo consulente qui.",
    "nl": "U kunt hier uw adviseur een bericht sturen."
  },
  CONSULTANT: {
    en: 'Consultant',
    de: 'Berater',
    it: 'Consulente',
    nl: 'Consultant'
  },
  SUBJECT: {
    en: 'Subject',
    de: 'Betreff',
    it: 'Soggetto',
    nl: 'Betreft'
  },
  MESSAGE: {
    en: 'Message',
    de: 'Nachricht',
    it: 'Messaggio',
    nl: 'Bericht'
  },
  SEND:{
    en: 'Send',
    de: 'Absenden',
    it: 'Invia',
    nl: 'Zenden'
  },
  WRITE_MESSAGE_HERE: {
    en: 'Write your message here...',
    de: 'Schreiben Sie hier Ihre Nachricht...',
    it: 'Scrivi qui il tuo messaggio...',
    nl: 'Schrijf hier uw bericht...'
  },
  YOUR_CONSULTANT: {
    en: 'Your Consultant: ',
    de: 'Ihr Berater: ',
    it: 'Il tuo consulente: ',
    nl: 'Uw adviseur: '
  },
  MOBILE_NUMBER: {
    en: 'Mobile Number',
    de: 'Mobiltelefon-Nummer',
    it: 'Numero di cellulare',
    nl: 'Mobiel nummer'
  },
  EMAIL: {
    en: 'Email',
    de: 'E-Mail',
    it: 'Email',
    nl: 'E-mail'
  },
  SALUTATION: {
    en: 'Salutation',
    de: 'Begrüßung',
    it: 'Saluto',
    nl: 'Salutatie'
  },
  MR: {
    en: 'Mr',
    de: 'Herr',
    it: 'Signor',
    nl: 'Mr'
  },
  MRS: {
    en: 'Mrs',
    de: 'Frau',
    it: 'Signora',
    nl: 'Mevrouw'
  },
  MR_MRS: {
    en: 'Mr & Mrs',
    de: 'Herr & Frau',
    it: 'Signore e signora',
    nl: 'De heer en mevrouw'
  },
  NEWSLETTER_TITLE: {
    en: 'Title for saving the newsletter',
    de: 'Titel um den Newsletter zu speichern',
    it: 'Titolo per il salvataggio della newsletter',
    nl: 'Titel voor het opslaan van de nieuwsbrief'
  },
  NEWSLETTER_SUBJECT: {
    en: 'Subject for the newsletter',
    de: 'Betreff des versendeten Newsletter',
    it: 'Oggetto della newsletter',
    nl: 'Onderwerp voor de nieuwsbrief'
  },
  FIRST_NAME: {
    en: 'First Name',
    de: 'Vorname',
    it: 'Primo nome',
    nl: 'Voornaam'
  },
  LAST_NAME: {
    en: 'Last Name',
    de: 'Nachname',
    it: 'Cognome',
    nl: 'Achternaam'
  },
  TELEPHONE_OR_MOBILE_NUMBER: {
    en: 'Telephone or mobile number',
    de: 'Telefon- oder Handynummer',
    it: 'Numero di telefono o di cellulare',
    nl: 'Telefoon of mobiel nummer'
  },
  IS_BLACKLISTED: {
    en: 'is blacklisted',
    de: 'auf der schwarzen Liste steht',
    it: 'è nella lista nera',
    nl: 'staat op de zwarte lijst'
  },
  VIEW_RECIPIENT_GROUP: {
    en: 'View Recipient Group',
    de: 'Empfängergruppe anzeigen',
    it: 'Visualizza il gruppo di destinatari',
    nl: 'Ontvangersgroep bekijken'
  },
  RECIPIENT_LIST: {
    en: 'Recipient List: ',
    de: 'Empfängerliste: ',
    it: 'Elenco dei destinatari: ',
    nl: 'Ontvangerslijst: '
  },
  CURRENT_BOOK_CAMPAIGN: {
    en: 'Current Book Campaign',
    de: 'Aktuell gebuchte Kampagne',
    it: 'Campagna del libro corrente',
    nl: 'Huidige boekencampagne'
  },
  NEW_CAMPAIGN: {
    en: 'New Campaign',
    de: 'Neue Kampagne',
    it: 'Nuova campagna',
    nl: 'Nieuwe campagne'
  },
  NOTIFICATION: {
    en: 'Notification',
    de: 'Benachrichtigung',
    it: 'Notifica',
    nl: 'Kennisgeving'
  },
  OLS_SETTINGS_NOT_CREATED_MESSAGE: {
    en: 'OLS settings are not created, please go to settings page to fill in the OLS settings.',
    de: 'OLS-Einstellungen werden nicht erstellt, bitte gehen Sie zur Einstellungsseite, um die OLS-Einstellungen auszufüllen.',
    it: 'Le impostazioni OLS non vengono create, si prega di andare alla pagina delle impostazioni per compilare le impostazioni OLS.',
    nl: 'OLS instellingen worden niet aangemaakt, ga naar de instellingenpagina om de OLS instellingen in te vullen.'
  },
  REDIRECT_SETTINGS: {
    en: 'Redirect to settings',
    de: 'Weiterleiten zu Einstellungen',
    it: 'Reindirizzare alle impostazioni',
    nl: 'Omleiden naar instellingen'
  },
  PREVIEW_IMAGE: {
    en: 'Preview Image',
    de: 'Vorschau-Bild',
    it: 'Anteprima immagine',
    nl: 'Voorbeeldfoto'
  },
  LISTING_OLS_CAMPAIGNS: {
    "en": "Listing of the OLS campaigns available to book.",
    "de": "Auflistung der OLS-Kampagnen, die gebucht werden können.",
    "it": "Elenco delle campagne OLS disponibili per la prenotazione.",
    "nl": "Lijst van de OLS-campagnes die beschikbaar zijn om te boeken."
  },
  CREATE_CAMPAIGN:{
    en: 'Create Campaign',
    de: 'Kampagne erstellen',
    it: 'Creare una campagna',
    nl: 'Campagne maken'
  },
  CURRENTLY_VIEWING_CAMPAIGNS_FROM:{
    en: 'Currently viewing campaign(s) from',
    de: 'Aktuell angezeigte Kampagne(n) von',
    it: 'Attualmente visualizzando la campagna(i) da',
    nl: 'U bekijkt momenteel campagne(s) van'
  },
  BANNER_MESSAGE: {
    en: 'You have not subscribed to OLS',
    de: 'Sie haben OLS nicht abonniert',
    it: 'Non ti sei iscritto a OLS',
    nl: 'U bent niet geabonneerd op OLS'
  },
  CLICK_HERE: {
    en: 'Click for more info',
    de: 'Klicken Sie für weitere Informationen',
    it: 'Clicca per maggiori informazioni',
    nl: 'Klik voor meer info'
  },
  APP_CAMPAIGN: {
    en: 'App Campaign',
    de: 'App Kampagne',
    it: 'Campagna App',
    nl: 'App Campagne'
  },
  SUBSCRIBE_OLS: {
    en: 'You have subcribed to OLS.',
    de: 'Sie haben die OLS abonniert.',
    it: 'Lei si è abbonato a OLS.',
    nl: 'U bent geabonneerd op OLS.'
  },
  COMPLETED_CAMPAIGN_HEADING : {
    en: 'Completed Booked Campaign',
    de: 'Abgeschlossene gebuchte Kampagne',
    it: 'Campagna prenotata completata',
    nl: 'Afgeronde geboekte campagne'
  },
  COMPLETED_CAMPAIGN_SUBHEADING : {
    en: 'Listing of your completed booked campaign',
    de: 'Auflistung Ihrer abgeschlossenen gebuchten Kampagne',
    it: 'Elenco della vostra campagna prenotata completata',
    nl: 'Lijst van uw voltooide geboekte campagne'
  },
  CURRENTLY_VIEWING_COMPLETED_BOOKED_CAMPAIGN_FROM:{
    en: 'Currently viewing completed booked campaign(s) from',
    de: 'Derzeit werden abgeschlossene gebuchte Kampagne (en) von angezeigt',
    it: 'Stai visualizzando le campagne prenotate completate da',
    nl: 'Bekijkt momenteel voltooide geboekte campagne (s) van'
  },
  TITLE:{
    en: 'Title',
    de: 'Titel',
    it: 'Titolo',
    nl: 'Titel'
  },
  START_DATE:{
    en: 'Start Date',
    de: 'Anfangsdatum',
    it: 'Data d\'inizio',
    nl: 'Begin datum'
  },
  NUMBER_OF_LEADS:{
    en: 'Number of Leads',
    de: 'Anzahl der Leads',
    it: 'Numero di lead',
    nl: 'Aantal Leads'
  },
  WATCH_OUT: {
    en: 'WATCH OUT!!! Be sure to fill out all fields!',
    de: 'Achten Sie darauf, alle Felder auszufüllen!',
    it: 'GUARDATE FUORI!!! Assicuratevi di compilare tutti i campi!',
    nl: 'WATCH OUT!!! Vul alle velden in!'
  },
  IS_PURE_WOMAN_STUDIO: {
    en: `Is this pure women's studio`,
    de: 'Ist dies ein reines Frauenstudio',
    it: 'Questo è uno studio di pura femminilità',
    nl: 'Is dit pure vrouwenstudio'
  },
  STUDIO_NAME: {
    en: `Studio name`,
    de: 'Name des Studios',
    it: 'Nome dello studio',
    nl: 'Naam van de studio'
  },
  ROAD: {
    en: `Road`,
    de: 'Straße',
    it: 'Strada',
    nl: 'Weg'
  },
  POSTCODE_OR_TOWN: {
    en: 'Postcode / town',
    de: 'Postleitzahl/Ort',
    it: 'Codice postale / città',
    nl: 'Postcode / plaats'
  },
  CONTACT_PERSON: {
    en: 'Contact person',
    de: 'Kontaktperson',
    it: 'Persona di contatto',
    nl: 'Contactpersoon'
  },
  EMAIL_CONTACT_PERSON: {
    en: 'Email contact person',
    de: 'E-Mail-Kontaktperson',
    it: 'Contatto via e-mail',
    nl: 'Contactpersoon voor e-mail'
  },
  TELEPHONE_CONTACT_PERSON: {
    en: 'Telephone contact person',
    de: 'Telefonische Kontaktperson',
    it: 'Contatto telefonico',
    nl: 'Telefonisch contactpersoon'
  },
  MY_PERSONAL_ADVISOR: {
    en: 'My personal advisor',
    de: 'Mein persönlicher Berater',
    it: 'Il mio consulente personale',
    nl: 'Mijn persoonlijke adviseur'
  },
  SEND_LEAD_EMAIL_ADDRESS: {
    en: 'Email address to which the leads should be sent. Pay attention to correct spelling!',
    de: 'E-Mail-Adresse, an die die Leads gesendet werden sollen. Achten Sie auf die korrekte Schreibweise!',
    it: "Indirizzo e-mail a cui inviare i cavi. Attenzione all'ortografia corretta!",
    nl: 'E-mailadres waarnaar de leads moeten worden gestuurd. Let op de juiste spelling!'
  },
  FB_LINK: {
    en: 'Link to the Facebook fan page',
    de: 'Link zur Facebook-Fanseite',
    it: 'Link alla pagina fan di Facebook',
    nl: 'Link naar de Facebook-fanpagina'
  },
  CAMPAIGN_TO_RUN: {
    en: 'Which campaign do you want to run?',
    de: 'Welche Kampagne möchten Sie durchführen?',
    it: 'Quale campagna vuoi condurre?',
    nl: 'Welke campagne wil je voeren?'
  },
  DATA_PROTECTION_LINK: {
    en: 'Link to the data protection of your website',
    de: 'Link zum Datenschutz Ihrer Website',
    it: 'Link alla protezione dei dati del vostro sito web',
    nl: 'Link naar de gegevensbescherming van uw website'
  },
  FB_MONTHLY_CLICK_BUDGET: {
    en: 'Monthly click-through budget Facebook campaign in euros. Enter the monthly amount that you want to use as a click budget (recommendation: 350 euros). This amount can be increased at any time and / or changed after the first campaign.',
    de: 'Monatliches Klickbudget Facebook-Kampagne in Euro. Geben Sie den monatlichen Betrag ein, den Sie als Klick-Budget verwenden möchten (Empfehlung: mindestens 500 Euro). Dieser Betrag kann jederzeit erhöht und / oder nach der ersten Kampagne geändert werden.',
    it: "Mensile click-through budget della campagna Facebook in euro. Inserite l'importo mensile che volete utilizzare come budget per il click (raccomandazione: 350 euro). Questo importo può essere aumentato in qualsiasi momento e/o modificato dopo la prima campagna.",
    nl: "Maandelijkse doorklikbudget Facebook-campagne in euro's. Voer het maandelijkse bedrag in dat u wilt gebruiken als klikbudget (aanbeveling: 350 euro). Dit bedrag kan op elk moment worden verhoogd en/of gewijzigd na de eerste campagne."
  },
  BEST_PLACE_NAME: {
    en: 'Best place name for text in the advertisement (People from model city and surroundings wanted)',
    de: 'Bester Ortsname für Text in der Anzeige (Menschen aus Modellstadt und Umgebung gesucht)',
    it: `Il miglior nome di luogo per il testo nell'annuncio (Cercasi persone di città modello e dintorni)`,
    nl: 'Beste plaatsnaam voor tekst in de advertentie (Mensen uit modelstad en omgeving gezocht)'
  },
  STANDARD_PLACE_NAME_SURROUNDINGS: {
    en: 'Standard (recommended): Your place name and surroundings (example: "pastures and surroundings")',
    de: 'Standard (empfohlen): Ihr Ortsname und Umgebung (Beispiel: "Weiden und Umgebung")',
    it: 'Standard (consigliato): Il vostro nome del luogo e dintorni (esempio: "pascoli e dintorni")',
    nl: `Standaard (aanbevolen): Uw plaatsnaam en omgeving (voorbeeld: "weiden en omgeving")`
  },
  FB_ADVERTISING_ACCOUNT_ID: {
    en: 'Facebook advertising account ID',
    de: 'Facebook-Werbekonto-ID',
    it: "ID dell'account pubblicitario di Facebook",
    nl: 'Facebook-reclameaccount-ID'
  },
  MONTHLY_CLICK_GOOGLE_ADWORDS: {
    en: 'Monthly click-through budget Google Adwords campaign in euros Enter the monthly amount that you want to use as a click budget (recommendation: 150 euros). This amount represents a margin and it is not certain that everything will be spent. This amount can also be changed at any time.',
    de: 'Monatliches Klickbudget Google Adwords-Kampagne in Euro Geben Sie den monatlichen Betrag ein, den Sie als Click-Budget verwenden möchten (Empfehlung: 150 Euro). Dieser Betrag stellt eine Marge dar und es ist nicht sicher, dass alles ausgegeben wird. Dieser Betrag kann auch jederzeit geändert werden.',
    it: "Budget mensile per la campagna Google Adwords in euro Inserire l'importo mensile che si desidera utilizzare come budget per i clic (raccomandazione: 150 euro). Questo importo rappresenta un margine e non è certo che tutto verrà speso. Anche questo importo può essere modificato in qualsiasi momento.",
    nl: "Maandelijks doorklikbudget Google Adwords-campagne in euro's Voer het maandelijkse bedrag in dat u als klikbudget wilt gebruiken (aanbeveling: 150 euro). Dit bedrag vertegenwoordigt een marge en het is niet zeker dat alles wordt uitgegeven. Dit bedrag kan ook op elk moment worden gewijzigd."
  },
  GOOGLE_ADS_CUSTOMER_NUMBER: {
    en: 'Google Ads customer number',
    de: 'Google Ads-Kundennummer',
    it: 'Numero cliente di Google Ads',
    nl: 'Google Advertentie klantnummer'
  },
  FIND_GOOGLE_ACCOUNT_TOP_RIGHT: {
    en: 'You can find this in your Google Account at the top right under the user name.',
    de: 'Sie finden diese in Ihrem Google-Konto oben rechts unter dem Benutzernamen.',
    it: 'Potete trovarlo nel vostro account Google in alto a destra sotto il nome utente.',
    nl: 'U vindt dit in uw Google-account rechtsboven onder de gebruikersnaam.'
  },
  COMMENT: {
    en: "Comment",
    de: 'Kommentar',
    it: 'Commento',
    nl: 'Commentaar'
  },
  CONSENT_DATA_PROCESSING: {
    en: 'Consent to data processing',
    de: 'Zustimmung zur Datenverarbeitung',
    it: 'Consenso al trattamento dei dati',
    nl: 'Toestemming voor gegevensverwerking'
  },
  YES_CONSENT_STATEMENT: {
    en: 'Yes, I consent to the data I have entered being processed to contact me.',
    de: 'Ja, ich bin damit einverstanden, dass die von mir eingegebenen Daten verarbeitet werden, um mich zu kontaktieren.',
    it: 'Sì, acconsento al trattamento dei dati che ho inserito per contattarmi.',
    nl: 'Ja, ik geef toestemming om de gegevens die ik heb ingevoerd te verwerken om contact met mij op te nemen.'
  },
  OLS_SETTING_HEADING: {
    en: "OLS Settings",
    de: 'OLS-Einstellungen',
    it: 'Impostazioni OLS',
    nl: 'OLS-instellingen'
  },
  OLS_SETTING_SUBHEADING: {
    en: "To start the online lead campaign, we need the following information from you.",
    de: 'Um die Online-Lead-Kampagne zu starten, benötigen wir von Ihnen die folgenden Informationen.',
    it: 'Per iniziare la campagna di lead online, abbiamo bisogno delle seguenti informazioni da voi.',
    nl: 'Om de online lead campagne te starten, hebben wij de volgende informatie van u nodig.'
  },
  CURRENTLY_VIEWING_OLS_CAMPAIGN_SETTINGS_FROM:{
    en: 'Currently viewing ols campaign settings from',
    de: 'Derzeit werden die Kampagneneinstellungen von angezeigt',
    it: 'Stai visualizzando le impostazioni della campagna OL da',
    nl: 'Bekijk momenteel oude campagne-instellingen van'
  },
  CONTRACT_TERM:{
    en: 'Contract term',
    de: 'Vertragslaufzeit',
    it: 'Durata del contratto',
    nl: 'Contracttermijn'
  },
  CONFIRMATION_MESSAGE: {
    en: 'You can only fill in this form once. Any changes later require you to speak to your consultant. Do you confirm this is the correct information to submit?',
    de: 'Sie können dieses Formular nur einmal ausfüllen. Bei späteren Änderungen müssen Sie mit Ihrem Berater sprechen. Bestätigen Sie, dass dies die korrekten Informationen zum Einreichen sind?',
    it: 'Puoi compilare questo modulo solo una volta. Qualsiasi cambiamento successivo richiede di parlare con il suo consulente. Conferma che queste sono le informazioni corrette da presentare?',
    nl: 'U kunt dit formulier maar één keer invullen. Voor latere wijzigingen moet u met uw consulent overleggen. Bevestigt u dat dit de juiste informatie is om in te dienen?'
  },
  LATEST_PRODUCTS: {
    en: 'Latest Products',
    de: 'Neueste Produkte',
    it: 'Ultimi prodotti',
    nl: 'Nieuwste producten'
  },
  USER_SETTING_HEADING : {
    en: 'System Settings',
    de: 'Systemeinstellungen',
    it: 'Impostazioni di sistema',
    nl: 'Systeem Instellingen'
  },
  USER_SETTING_SUBHEADING: {
    en: 'Adjust your system settings here.',
    de: 'Passen Sie hier Ihre Systemeinstellungen an.',
    it: 'Regola qui le impostazioni del tuo sistema.',
    nl: 'Pas hier uw systeeminstellingen aan.'
  },
  LANGUAGE: {
    en: 'Language',
    de: 'Sprache',
    it: 'Lingua',
    nl: 'Taal'
  },
  SAVE: {
    en: 'Save',
    de: 'Speichern',
    it: 'Salva',
    nl: 'Bespaar'
  },
  IMAGE_UPLOAD_SUCCESS: {
    en: 'Image is uploaded successfully.',
    de: 'Das Bild wurde erfolgreich hochgeladen.',
    it: `L'immagine è stata caricata con successo.`,
    nl: 'Beeld is succesvol geupload.'
  },
  MEDIA_DATABASE: {
    en: 'Media Database',
    de: 'Medien-Datenbank',
    it: 'Banca dati dei media',
    nl: 'Media Databank'
  },
  SEARCH_WITH_IMAGE_SHORTNAME: {
    en: 'Search with image shortname',
    de: 'Suche mit Bildkurzname',
    it: `Ricerca con nome breve dell'immagine`,
    nl: 'Zoeken met afbeeldingsnaam'
  },
  ORIGINAL: {
    en: 'Original',
    de: 'Original',
    it: 'Originale',
    nl: 'Original'
  },
  SMALL: {
    en: 'Small',
    de: 'Klein',
    it: 'Piccolo',
    nl: 'Small'
  },
  SLIDER: {
    en: 'Slider',
    de: 'Slider',
    it: 'Cursore',
    nl: 'Slider'
  },
  IMAGE_TYPE: {
    en: 'Image type',
    de: 'Bildtyp',
    it: 'Tipo di immagine',
    nl: 'Type afbeelding'
  },
  SELECT_MEDIA_TAGS: {
    en: 'Select media tags',
    de: 'Medien-Tags auswählen',
    it: 'Seleziona i tag dei media',
    nl: 'Selecteer media tags'
  },
  RESET: {
    en: 'Reset',
    de: 'zurücksetzen',
    it: 'Reset',
    nl: 'Reset'
  },
  NEWSLETTER: {
    en: "Newsletter",
    de: "Newsletter",
    it: "Notiziario",
    nl: "Nieuwsbrief"
  },
  SETUP_NEWSLETTER: {
    en: 'Setup of the newsletters over here.',
    de: 'Einrichtung des Newsletters hier.',
    nl: 'Opstelling van de nieuwsbrieven hier.',
    it: 'Impostazione delle newsletter qui.'
  },
  CREATE_NEWSLETTER: {
    en: 'Create Newsletter',
    de: 'Newsletter erstellen',
    nl: 'Nieuwsbrief maken',
    it: 'Creare newsletter'
  },
  CREATE_NEWSLETTER_SUCCESS: {
    en: 'Create newsletter success',
    de: 'Erstellen Sie einen erfolgreichen Newsletter.',
    nl: 'Nieuwsbrief maken',
    it: 'Creare newsletter'
  },
  RESEND: {
    en: 'Resend',
    de: 'Erneut senden',
    nl: 'Opnieuw verzenden',
    it: 'Reinvia'
  },
  DELIVERED_AT: {
    en: 'Delivered at',
    de: 'Geliefert am',
    nl: 'Afgeleverd bij',
    it: 'Consegnato a'
  },
  CHOOSE_FROM_CONTENT_SERVICE: {
    en: 'Choose from Content Service',
    de: 'Wählen Sie aus Content Service',
    nl: 'Kies uit Inhoud Service',
    it: 'Scegliere dal servizio contenuti'
  },
  CHOOSE_FROM_TEMPLATE: {
    "en": "Choose from template",
    "de": "Wählen Sie aus der Vorlage",
    "it": "Scegli dal modello",
    "nl": "Kies uit sjabloon"
  },
  CREATE_NEWSLETTER_FROM_TEMPLATE: {
    "en": "Create Newsletter( from template )",
    "de": "Newsletter erstellen (aus Vorlage)",
    "it": "Crea newsletter (da modello)",
    "nl": "Nieuwsbrief maken (van sjabloon)"
  },
  UPDATE_NEWSLETTER_FROM_TEMPLATE: {
    "en": "Update Newsletter( from template )",
    "de": "Newsletter aktualisieren (aus Vorlage)",
    "it": "Aggiorna newsletter (da modello)",
    "nl": "Update nieuwsbrief (van sjabloon)"
  },
  NEWSLETTER_NEWS_CREATED_SUCCESS: {
    "en": "Newsletter news is created successfully.",
    "de": "Newsletter-News werden erfolgreich erstellt.",
    "it": "Le notizie della newsletter sono state create con successo.",
    "nl": "Nieuwsbriefnieuws is succesvol aangemaakt."
  },
  NEWSLETTER_NEWS_UPDATED_SUCCESS: {
    "en": "Newsletter news is updated successfully.",
    "de": "Newsletter-News werden erfolgreich aktualisiert.",
    "it": "Le notizie della newsletter vengono aggiornate con successo.",
    "nl": "Nieuwsbriefnieuws is succesvol bijgewerkt."
  },
  NEWSLETTER_IS_SUCCESSFULLY_CREATED: {
    "en": "Newsletter is successfully created",
    "de": "Newsletter wurde erfolgreich erstellt",
    "it": "La newsletter è stata creata con successo",
    "nl": "De nieuwsbrief is succesvol aangemaakt"
  },
  NEWSLETTER_IS_SUCCESSFULLY_UPDATED: {
    "en": "Mailing send successfully",
    "de": "Newsletter erfolgreich versendet",
    "it": "La newsletter è stata aggiornata con successo",
    "nl": "De nieuwsbrief is succesvol bijgewerkt"
  },
  CREATE_NEWS: {
    "en": "Create News",
    "de": "Nachrichten erstellen",
    "it": "Crea notizie",
    "nl": "Nieuws maken"
  },
  RECIPIENT_GROUPS: {
    en: 'Recipient Groups',
    de: 'Empfängergruppen',
    nl: 'Ontvangersgroepen',
    it: 'Gruppi di destinatari'
  },
  SETUP_RECIPIENT_GROUPS: {
    "en": "Setup of the recipient groups over here.",
    "de": "Einrichtung der Empfängergruppen hier.",
    "it": "Configurazione dei gruppi di destinatari qui.",
    "nl": "Stel hier de ontvangergroepen in."
  },
  CREATE_RECIPIENT_GROUP: {
    "en": "Create Recipient Group",
    "de": "Empfängergruppe erstellen",
    "it": "Crea gruppo destinatari",
    "nl": "Maak een ontvangersgroep"
  },
  CREATE_RECIPIENT: {
    "en": "Create Recipient",
    "de": "Empfänger erstellen",
    "it": "Crea destinatario",
    "nl": "Creëer ontvanger"
  },
  EDIT_BLACKLISTED_RECIPIENT: {
    en: 'Edit blacklisted recipient',
    de: 'Empfänger auf der schwarzen Liste bearbeiten',
    it: 'Modifica il destinatario della lista nera',
    nl: 'Bewerk ontvanger op zwarte lijst'
  },
  EDIT_ACTIVE_RECIPIENT: {
    en: 'Edit Active Recipient',
    de: 'Aktiven Empfänger bearbeiten',
    it: 'Modifica destinatario attivo',
    nl: 'Bewerk Actieve Ontvanger'
  },
  EDIT_INACTIVE_RECIPIENT: {
    en: 'Edit Inactive Recipient',
    de: 'Inaktiven Empfänger bearbeiten',
    it: 'Modifica destinatario inattivo',
    nl: 'Wijzig Inactieve Ontvanger'
  },
  VIEW_BLACKLISTED: {
    "en": "View blacklisted recipient",
    "de": "Empfänger auf der schwarzen Liste anzeigen",
    "it": "Visualizza il destinatario nella lista nera",
    "nl": "Bekijk de ontvanger op de zwarte lijst"
  },
  DOI_RECIPIENT: {
    "en": "DOI Recipient",
    "de": "DOI-Empfänger",
    "it": "Destinatario DOI",
    "nl": "DOI-ontvanger"
  },
  INACTIVE_TO_ACTIVE: {
    en: 'Changing to Activ recipient will not send DOI email',
    de: 'Der Wechsel zu Aktiv-Empfänger sendet keine DOI-E-Mail',
    it: "Cambiando il destinatario di Activ non invierà l'email DOI",
    nl: 'Veranderen naar Activ ontvanger zal geen DOI e-mail versturen'
  },
  INACTIVE_TO_DOI: {
    en: 'Changing to DOI will send a double opt-in email to the recipient',
    de: 'Beim Wechsel zu DOI wird eine Double-Opt-In-E-Mail an den Empfänger gesendet',
    it: "Cambiando in DOI si invierà un'email di doppio opt-in al destinatario",
    nl: 'Veranderen naar DOI zal een dubbele opt-in e-mail sturen naar de ontvanger'
  },
  NOTIFICATION_LANGUAGE_STATUS_CHANGE: {
    en: 'If you are creating recipient with status ACTIVE or INACTIVE, we will not send the opt in email based on GDPR rules.',
    de: 'Wenn Sie einen Empfänger mit dem Status AKTIV oder INAKTIV anlegen, werden wir die Opt-in-E-Mail aufgrund der GDPR-Regeln nicht versenden.',
    it: "Se stai creando un destinatario con lo stato ATTIVO o INATTIVO, non invieremo l'email opt in in base alle regole del GDPR.",
    nl: 'Als u een ontvanger met de status ACTIEF of INACTIEF aanmaakt, zullen wij de opt-in e-mail niet verzenden op basis van de GDPR-regels.'
  },
  MESSAGE_CREATE_RECIPIENT: {
    en: 'Please go to DOI menu if you would like to create new recipient.',
    de: 'Bitte gehen Sie in das Menü DOI, wenn Sie einen neuen Empfänger anlegen möchten.',
    it: 'Vai al menu DOI se vuoi creare un nuovo destinatario.',
    nl: 'Ga naar menu DOI als u een nieuwe ontvanger wilt aanmaken.'
  },
  MINI_TEXT: {
    en: 'Mini Text',
    de: 'Mini Text',
    nl: 'Minitekst',
    it: 'Mini testo'
  },
  TEASER_TEXT: {
    en: 'Teaser Text',
    de: 'Teaser-Text',
    nl: 'Teaser tekst',
    it: 'Testo Teaser'
  },
  PUBLISH_FROM: {
    en: 'Publish from',
    de: 'Veröffentlichen von',
    nl: 'Publiceer van',
    it: 'Pubblicare da'
  },
  WEBSITE_PREVIEW: {
    en: 'Website Preview',
    de: 'Website-Vorschau',
    nl: 'Website Voorbeeld',
    it: 'Anteprima del sito web'
  },
  WEBSITE_DETAILED_IMAGE: {
    en: 'Website Detailed Image',
    de: 'Website Detailbild',
    nl: 'Website Gedetailleerde afbeelding',
    it: 'Immagine dettagliata del sito web'
  },
  FACEBOOK_TEASER: {
    en: 'Facebook Teaser',
    de: 'Facebook-Teaser',
    nl: 'Facebook Teaser',
    it: 'Facebook Teaser'
  },
  TITLE_ENTER_POST: {
    en: 'Title (Enter a title for the post)',
    de: 'Titel (Geben Sie einen Titel für den Beitrag ein)',
    nl: 'Titel (Voer een titel in voor de post)',
    it: 'Titolo (Inserisci un titolo per il post)'
  },
  SELECT_SENDERS: {
    "en": "Select senders",
    "de": "Absender auswählen",
    "it": "Seleziona mittenti",
    "nl": "Selecteer afzenders"
  },
  PLEASE_SELECT_SENDER: {
    en: 'Please select a sender',
    de: 'Bitte Absender auswählen',
    nl: 'Bitte Absender auswählen',
    it: 'Bitte Absender auswählen'
  },
  SENDERS: {
    "en": "Senders",
    "de": "Absender",
    "it": "Mittenti",
    "nl": "Afzenders"
  },
  LIST_SENDERS: {
    "en": "Lists of the senders over here.",
    "de": "Listen der Absender hier.",
    "it": "Liste dei mittenti qui.",
    "nl": "Lijsten met de afzenders hier."
  },
  CREATE_SENDER: {
    "en": "Create sender",
    "de": "Absender erstellen",
    "it": "Crea mittente",
    "nl": "Afzender aanmaken"
  },
  EDIT_SENDER: {
    "en": "Edit Sender",
    "de": "Absender bearbeiten",
    "it": "Modifica mittente",
    "nl": "Bewerk afzender"
  },
  SEND_NOW: {
    "en": "Send now",
    "de": "sofort versenden",
    "it": "Spedisci ora",
    "nl": "Nu verzenden"
  },
  IS_DRAFT: {
    "en": "Is draft?",
    "de": "als Entwurf speichern",
    "it": "è bozza?",
    "nl": "Is tocht?"
  },
  IS_SCHEDULED: {
    "en": "Is scheduled?",
    "de": "Versand terminieren",
    "it": "È programmato?",
    "nl": "Is gepland?"
  },
  TEMPLATES: {
    "en": "Templates",
    "de": "Vorlagen",
    "it": "Modelli",
    "nl": "Sjablonen"
  },
  EDIT_TEMPLATE: {
    en: 'Edit template',
    de: 'Vorlage bearbeiten',
    it: 'Modifica modello',
    nl: 'Sjabloon bewerken'
  },
  DELETE_TEMPLATE: {
    "en": "Delete template",
    "de": "Vorlage löschen",
    "it": "Elimina modello",
    "nl": "Sjabloon verwijderen"
  },
  SELECT_RECIPIENT_GROUPS: {
    "en": "Select recipients groups",
    "de": "Empfängergruppen auswählen",
    "it": "Seleziona i gruppi di destinatari",
    "nl": "Selecteer ontvangersgroepen"
  },
  PLEASE_ENTER_VALID_EMAIL: {
    "en": "Please enter valid email address.",
    "de": "Bitte geben Sie eine gültige Email Adresse an.",
    "it": "Inserisci indirizzo email valido.",
    "nl": "Vul alstublieft een geldig e-mailadres in."
  },
  ARE_YOU_SURE: {
    "en": "Are you sure you want to delete this recipient?",
    "de": "Möchten Sie diesen Empfänger wirklich löschen?",
    "it": "Sei sicuro di voler eliminare questo destinatario?",
    "nl": "Weet u zeker dat u deze ontvanger wilt verwijderen?"
  },
  DELETE_RECIPIENTS_GROUP_TITLE: {
    "en": "Delete recipient group",
    "de": "Empfängergruppe löschen",
    "it": "Elimina il gruppo di destinatari",
    "nl": "Ontvangersgroep verwijderen"
  },
  ARE_YOU_SURE_DELETE_RECIPIENT: {
    "en": "Are you sure you want to delete this recipient?",
    "de": "Möchten Sie diesen Empfänger wirklich löschen?",
    "it": "Sei sicuro di voler eliminare questo destinatario?",
    "nl": "Weet u zeker dat u deze ontvanger wilt verwijderen?"
  },
  ARE_YOU_SURE_TO_DELETE: {
    "en": "Are you sure to delete the selected template?",
    "de": "Sind Sie sicher, die ausgewählte Vorlage zu löschen?",
    "it": "Sei sicuro di eliminare il modello selezionato?",
    "nl": "Weet u zeker dat u de geselecteerde sjabloon wilt verwijderen?"
  },
  TEMPLATE_IS_SUCCESSFULLY_UPDATED: {
    "en": "Template is successfully updated",
    "de": "Vorlage wurde erfolgreich aktualisiert",
    "it": "Il modello è stato aggiornato con successo",
    "nl": "Sjabloon is succesvol bijgewerkt"
  },
  TEMPLATE_IS_SUCCESSFULLY_REMOVED: {
    "en": "Template is successfully removed",
    "de": "Vorlage wurde erfolgreich entfernt",
    "it": "Il modello è stato rimosso con successo",
    "nl": "Sjabloon is succesvol verwijderd"
  },
  EDIT_RECIPIENT_GROUP: {
    en: 'Edit Recipient Group',
    de: 'Empfängergruppe bearbeiten',
    it: 'Modifica gruppo di destinatari',
    nl: 'Ontvangersgroep bewerken'
  },
  EDIT_RECIPIENT: {
    en: 'Edit Recipient',
    de: 'Empfänger bearbeiten',
    it: 'Modifica destinatario',
    nl: 'Ontvanger bewerken'
  },
  SET_DATETIME_LATER_THAN_TODAY: {
    "en": "Please set a datetime later than today.",
    "de": "Bitte stellen Sie eine Uhrzeit später als heute ein.",
    "it": "Si prega di impostare un datetime successivo a oggi.",
    "nl": "Stel een datetime later in dan vandaag."
  },
  VIEW_NEWSLETTER_DETAILS: {
    "en": "View Newsletter Details",
    "de": "Newsletter-Details anzeigen",
    "it": "Visualizza i dettagli della newsletter",
    "nl": "Bekijk Nieuwsbrief Details"
  },
  UPDATE_NEWSLETTER: {
    "en": "Update Newsletter",
    "de": "Newsletter aktualisieren",
    "it": "Aggiorna newsletter",
    "nl": "Update nieuwsbrief"
  },
  SAVE_TEMPLATE: {
    en: 'Save template for future use?',
    de: 'Vorlage für zukünftige Verwendung speichern?',
    it: 'Salvare il modello per un uso futuro?',
    nl: 'Sjabloon opslaan voor toekomstig gebruik?'
  },
  SELECT_A_TEMPLATE: {
    en: 'Select a template',
    de: 'Wählen Sie eine Vorlage',
    it: 'Seleziona un modello',
    nl: 'Kies een sjabloon'
  },
  SELECT_TEMPLATE: {
    en: 'Select Template',
    de: 'Vorlage auswählen',
    it: 'Selezionare il modello',
    nl: 'Selecteer Sjabloon'
  },
  FILL_IN_DETAILS: {
    en: 'Fill in details',
    de: 'Details ausfüllen',
    it: 'Compilare i dettagli',
    nl: 'Vul de gegevens in'
  },
  CHOOSE_A_TEMPLATE: {
    en: 'Choose a template',
    de: 'Wählen Sie eine Vorlage',
    it: 'Scegliere un modello',
    nl: 'Kies een sjabloon'
  },
  DESIGN_NEWSLETTER: {
    en: 'Design and schedule your newsletter',
    de: 'Gestalten und planen Sie Ihren Newsletter',
    it: 'Progetta e programma la tua newsletter',
    nl: 'Ontwerp en plan uw nieuwsbrief'
  },
  CLOSE_CONFIRMATION: {
    en: 'Are you sure you want to close this modal without saving? Please note that any changes made will not be saved.',
    de: 'Sind Sie sicher, dass Sie dieses Modal ohne Speichern schließen möchten? Bitte beachten Sie, dass dabei vorgenommene Änderungen nicht gespeichert werden.',
    it: 'Sei sicuro di voler chiudere questo modale senza salvare? Si prega di notare che le modifiche apportate non verranno salvate.',
    nl: 'Weet u zeker dat u dit modaal wilt sluiten zonder op te slaan? Let op dat eventuele wijzigingen niet worden opgeslagen.'
  },
  STEP: {
    en: 'Step',
    de: 'Schritt',
    it: 'Passo',
    nl: 'Stap'
  },
  BACK:{
    en: 'Back',
    de: 'Zurück',
    it: 'Indietro',
    nl: 'Terug naar'
  },
  DELIVER_AT: {
    en: 'Deliver at',
    de: 'Liefern bei',
    it: 'Consegnare a',
    nl: 'Afleveren bij'
  },
  SELECTED: {
    en: 'Selected',
    de: 'Ausgewählte',
    it: 'Selezionato',
    nl: 'Geselecteerd'
  },
  SELECT_ITEM: {
    en: 'Select item',
    de: 'Artikel auswählen',
    nl: 'Kies een item',
    it: 'Selezionare la voce'
  },
  LISTINGS: {
    en: "Listing",
    de: "Mailings",
    it: "Lista",
    nl: "Lijst"
  },
  RECIPIENTS: {
    en: "Recipients",
    de: "Empfänger",
    it: "Destinatari",
    nl: "Ontvangers"
  },
  INACTIVE_RECIPIENTS: {
    "en": "Inactive Recipients",
    "de": "Inaktive Empfänger",
    "it": "Destinatari inattivi",
    "nl": "Inactieve ontvangers"
  },
  SETUP_RECIPIENTS: {
    "en": "Setup of the recipients over here.",
    "de": "Einrichtung der Empfänger hier.",
    "it": "Configurazione dei destinatari qui.",
    "nl": "Stel hier de ontvangers in."
  },
  DOI_FULL: {
    en: 'DOI (Double-Opt-In)',
    de: 'DOI (Double-Opt-In)',
    it: 'DOI (Doppio Opt-In)',
    nl: 'DOI (Dubbele-Opt-In)'
  },
  DOI: {
    en: 'DOI',
    de: 'DOI',
    it: 'DOI',
    nl: 'DOI'
  },
  BULK_UPLOAD: {
    "en": "Bulk Upload",
    "de": "Bulk-Upload",
    "it": "Caricamento collettivo",
    "nl": "Massa upload"
  },
  NON_BLACKLISTED: {
    en: "Non Blacklisted",
    de: "Empfänger",
    it: "Non in lista nera",
    nl: "Niet op de zwarte lijst"
  },
  BLACKLISTED: {
    "en": "Blacklisted",
    "de": "Auf die schwarze Liste gesetzt",
    "it": "Nella lista nera",
    "nl": "Op de zwarte lijst"
  },
  BLACKLISTS: {
    en: "Blacklists",
    de: "Blacklist",
    it: "Blacklists",
    nl: "Zwarte lijsten"
  },
  RECEIVER_CREATE_SUCCESS: {
    en: "Receiver create successful",
    de: "Empfänger erfolgreich erstellt",
    it: "Ricevitore creato correttamente",
    nl: "Ontvanger succesvol aangemaakt"
  },
  RECEIVER_UPDATE_SUCCESS: {
    en: "Receiver update successful",
    de: "Update des Empfängers erfolgreich",
    it: "Aggiornamento del ricevitore riuscito",
    nl: "Ontvanger update succesvol"
  },
  RECEIVER_DELETE_SUCCESS: {
    en: "Receiver successfully deleted",
    de: "Empfänger erfolgreich gelöscht",
    it: "Ricevitore eliminato con successo",
    nl: "Ontvanger succesvol verwijderd"
  },
  SENDER: {
    en: "Sender",
    de: "Sender",
    it: "Mittente",
    nl: "Afzender"
  },
  TEMPLATE: {
    "en": "Template",
    "de": "Vorlage",
    "it": "Modello",
    "nl": "Sjabloon"
  },
  SHOW_QUICK_INFO: {
    en: 'Show quick info',
    de: 'Kurzinfo anzeigen',
    it: 'Mostra info rapide',
    nl: 'Toon snelle info'
  },
  PREVIEW_TEXT: {
    en: 'Preview Text',
    de: 'Vorschau Text',
    it: 'Anteprima testo',
    nl: 'Voorbeeld Tekst'
  },
  PREVIEW_TEXT_PLACEHOLDER: {
    en: 'Type here your preview text',
    de: 'Geben Sie hier Ihren Vorschautext ein',
    it: 'Scrivi qui il tuo testo di anteprima',
    nl: 'Typ hier uw voorbeeldtekst'
  },
  ENLARGE_IMAGE_ON_HOVER: {
    en: 'Enlarge image on hover',
    de: 'Bild bei Hover vergrößern',
    it: `Ingrandisci l'immagine al passaggio del mouse`,
    nl: 'Vergroot beeld op hover'
  },
  ENLARGE_WIDTH: {
    en: 'Enlarge Width',
    de: 'Breite vergrößern',
    it: `Ingrandisci la larghezza`,
    nl: 'Vergroot Breedte'
  },
  ENLARGE_HEIGHT: {
    en: 'Enlarge Height',
    de: 'Höhe vergrößern',
    it: `Ingrandisci l'altezza`,
    nl: 'Vergroten Hoogte'
  },
  ADD_NESTED_SELECTION: {
    en: 'Add nested selection',
    de: 'Verschachtelte Auswahl hinzufügen',
    it: 'Aggiungere la selezione annidata',
    nl: 'Geneste selectie toevoegen'
  },
  NESTED_LABEL: {
    en: 'Nested label',
    de: 'Verschachtelte Beschriftung',
    it: 'Etichetta annidata',
    nl: 'Geneste label'
  },
  NESTED_FIELD_NAME: {
    en: 'Nested field name',
    de: 'Verschachtelter Feldname',
    it: 'Nome del campo annidato',
    nl: 'Naam van het geneste veld'
  },
  ALERT: {
    en: 'Alert',
    de: 'Alarmierung',
    it: 'Allerta',
    nl: 'Waarschuwing'
  },
  UPLOADED_FILE: {
    en: 'Uploaded file',
    de: 'Hochgeladene Datei',
    it: 'File caricato',
    nl: 'Geüpload bestand'
  },
  MESSAGES: {
    en: 'Messages',
    de: 'Nachrichten',
    it: 'Messaggi',
    nl: 'Berichten'
  },
  LEGALLY_BINDING_ORDER: {
    en: 'Legally binding order',
    de: 'Bestellung abschicken',
    it: 'Ordine legalmente vincolante',
    nl: 'Wettelijk bindend bevel'
  },
  CHAT_FOR: {
    en: 'Chat for',
    de: 'Chat zum Bestellschein',
    nl: 'Chat voor',
    it: 'Chat per'
  },
  UPLOAD_DATA: {
    en: 'Upload Data',
    de: 'Upload Datei',
    nl: 'Upload Datei',
    it: 'Upload Datei'
  },
  PLEASE_INPUT_MESSAGE_HERE: {
    en: 'Please input your message here',
    de: 'Hier Text eingeben',
    nl: 'Hier Text eingeben',
    it: 'Hier Text eingeben'
  },
  PREVIOUS:{
    en: 'Previous',
    de: 'Zurück',
    it: 'Precedente',
    nl: 'Vorige'
  },
  NEXT_PAGE:{
    en: 'Next',
    de: 'Vorwärts',
    it: 'Prossimo',
    nl: 'Volgende'
  },
  ROWS:{
    en: 'Rows',
    de: 'Zeilen',
    it: 'Righe',
    nl: 'Rijen'
  },
  NO_MAILGUN_STAT:{
    en: 'No Stats available at the moment.',
    de: 'Momentan sind keine Statistiken verfügbar.',
    it: 'Nessuna statistica disponibile al momento.',
    nl: 'Er zijn momenteel geen statistieken beschikbaar.'
  },
  PAGE: {
    en: 'Page',
    de: 'Seite',
    it: 'Pagina',
    nl: 'Pagina'
  },
  DURATION: {
    en: 'Duration', 
    de: 'Dauer',
    it: 'Durata',
    nl: 'Looptijd'
  },
  DURATION_UNIT: {
    en: 'Duration Unit', 
    de: 'Dauer Einheit', 
    it: 'Unità di durata', 
    nl: 'Duur Eenheid'
  },
  PERCENTAGE: {
    en: 'Percentage',
    de: 'Prozentsatz',
    it: 'Percentuale',
    nl: 'Percentage'
  },
  OPENED_MAIL: {
    en: 'Opened Mail',
    de: 'Mail geöffnet',
    it: 'Posta aperta',
    nl: 'geopende e-mail'
  },
  SUBSCRIBED: {
    en: 'Subscribed',
    de: 'Angemeldet',
    it: 'Abbonato',
    nl: 'Geabonneerd'
  },
  UNSUBSCRIBED: {
    en: 'Unsubscribed',
    de: 'Abgemeldet',
    it: 'Iscrizione annullata',
    nl: 'Uitgeschreven'
  },
  COMPLAINED: {
    en: 'Complained',
    de: 'Beschwerte sich',
    it: 'Lamentato',
    nl: 'Geklaagd'
  },
  OPENED_LINK: {
    en: 'Opened Link',
    de: 'Link geöffnet',
    it: 'Collegamento aperto',
    nl: 'Geopende link'
  },
  TOTAL_DELIVERED: {
    en: 'Total Delivered Mail',
    de: 'Zugestellte E-Mails insgesamt',
    it: 'Posta consegnata totale',
    nl: 'Totaal bezorgde post'
  },
  RECEIVED: {
    en: 'Received',
    de: 'Erhalten',
    it: 'Ricevuto',
    nl: 'Hebben ontvangen'
  },
  FAILED_TO_SEND: {
    en: 'Failed To Send',
    de: 'Senden fehlgeschlagen',
    it: 'Impossibile inviare',
    nl: 'Kan niet verzenden'
  },
  EDIT_DURATION: {
    en: 'Edit Duration',
    de: 'Bearbeiten Dauer',
    it: 'Modifica Durata',
    nl: 'Duur bewerken'
  },
  GO_TO_ARK_SITE: {
    en: 'Login',
    de: 'Login',
    it: 'Login',
    nl: 'Login'
  },
  ANNUAL_MARKETING_PLAN: {
    en: 'Annual Marketing Plan',
    de: 'Jährlicher Marketingplan',
    it: 'Piano annuale di marketing',
    nl: 'Jaarlijks marketingplan'
  },
  MY_ORDERS: {
    en: 'My Orders',
    de: 'Meine Bestellungen',
    it: 'I miei ordini',
    nl: 'Mijn bestellingen'
  },
  REPORTS: {
    en: 'Reports',
    de: 'Berichte',
    it: 'Rapporti',
    nl: 'Verslagen'
  },
  EMAIL_REPORTING: {
    en: 'Email reporting',
    de: 'E-Mail-Berichterstattung',
    it: 'Segnalazione via e-mail',
    nl: 'Rapportage per e-mail'
  },
  WEBSITE: {
    en: 'Website',
    de: 'Website',
    it: 'Sito web',
    nl: 'Website'
  },
  SHOP: {
    en: 'Shop',
    de: 'Shop',
    it: 'Negozio',
    nl: 'Winkelen'
  },
  MEMBERS_AREA: {
    en: 'Members Area',
    de: 'Memberbereich',
    it: 'Area membri',
    nl: 'Leden'
  },
  MEMBER_LOGINS: {
    en: "Member Logins",
    de: "Mitglieder-Logins",
    it: "Accesso ai membri",
    nl: "Leden logins"
  },
  APP: {
    en: 'App',
    de: 'App',
    it: 'App',
    nl: 'App'
  },
  CAMPAIGNS: {
    en: 'Campaigns',
    de: 'Kampagnen',
    it: 'Campagne',
    nl: 'Campagnes'
  },
  HELP_TUTORIALS: {
    en: 'Help/Tutorials',
    de: 'Hilfe/Tutorials',
    it: 'Aiuto/Tutorials',
    nl: 'Help/Gidsen'
  },
  EXPLANATORY_VIDEOS: {
    en: 'Explanatory videos',
    de: 'Erläuternde Videos',
    it: 'Video esplicativi',
    nl: `Verklarende video's`
  },
  FAQ: {
    en: 'FAQ',
    de: 'FAQ',
    it: 'FAQ',
    nl: 'FAQ'
  },
  ACISO_BENEFITS: {
    en: 'ACISO Benefits',
    de: 'ACISO Benefits',
    it: 'Vantaggi ACISO',
    nl: 'ACISO Voordelen'
  },
  ACISO_COCKPIT:{
    en: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/mfile_drive/f29c21d4897f78948b91f03172341b7b/original.jpg',
    de: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/mfile_drive/851ddf5058cf22df63d3344ad89919cf/original.jpg',
    it: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/mfile_drive/f29c21d4897f78948b91f03172341b7b/original.jpg',
    nl: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/mfile_drive/f29c21d4897f78948b91f03172341b7b/original.jpg'
  },
  MATOMO:{
    en: 'Matomo',
    de: 'Matomo',
    it: 'Matomo',
    nl: 'Matomo'
  },
  MATOMO_STATISTICS:{
    en: 'Matomo Statistics',
    de: 'Matomo Statistik',
    it: 'Matomo Statistiches',
    nl: 'Matomo Statistieken'
  },
  MAIL_GUN_STATISTICS:{
    en: 'Statistics',
    de: 'Statistiken',
    it: 'Statistiches',
    nl: 'Statistieken'
  },
  DATE:{
    en: 'Date',
    de: 'Datum',
    it: 'Data',
    nl: 'Datum'
  },
  VISIT_FREQUENCIES:{
    en: 'Visit frequencies',
    de: 'Frequenzen besuchen',
    it: 'Visita le frequenze',
    nl: 'Bezoek frequenties'
  },
  USER_CITIES:{
    en: 'User cities',
    de: 'Benutzer Städte',
    it: 'Città dell\'utente',
    nl: 'Gebruiker steden'
  },
  SINGLE_PAGE_VIEW_VISIT: {
    en: 'Single page view visit',
    de: 'Besuch einer einzelnen Seite',
    it: 'Visita a pagina singola',
    nl: 'Bezoek aan één enkele pagina'
  },
  MAXIMUM_USER_ACTIONS_TAKEN: {
    en: 'Maximum user actions taken',
    de: 'Maximale Benutzeraktionen',
    it: 'Azioni massime dell\'utente',
    nl: 'Maximale gebruikersacties'
  },
  AVERAGE_ACTIONS_PER_VISIT: {
    en: 'Average actions per visit',
    de: 'Durchschnittliche Besucheraktionen',
    it: 'Media di azioni per visita',
    nl: 'Gemiddelde acties per bezoek'
  },
  TOTAL_VISITS: {
    en: 'Total site visits',
    de: 'Besuche insgesamt',
    it: 'Totale visite in loco',
    nl: 'Totaal bezoeken ter plaatse'
  },
  TOTAL_UNIQUE_VISITS:{
    en: 'Total unique visits',
    de: 'Unabhängige Besuche insgesamt',
    it: 'Visite uniche totali',
    nl: 'Totaal unieke bezoeken'
  },
  TOTAL_ACTIONS:{
    en: 'Total actions',
    de: 'Maßnahmen insgesamt',
    it: 'Azioni totali',
    nl: 'Totaal acties'
  },
  GDPR_CENTER: {
    en: 'GDPR Center',
    de: 'DSGVO Center',
    it: 'Centro GDPR',
    nl: 'GDPR Centrum'
  },
  AV_CONTRACT: {
    en: 'AV Contract',
    de: 'AV Vertrag',
    it: 'Contratto AV',
    nl: 'AV Contract'
  },
  PRIVACY_POLICY: {
    en: 'Privacy Policy',
    de: 'Datenschutz',
    it: 'Politica sulla privacy',
    nl: 'Privacybeleid'
  },
  INQUIRY_CONCERNED: {
    en: 'Inquiry Concerned',
    de: 'Anfrage Betroffene',
    it: 'Inchiesta preoccupata',
    nl: 'Onderzoek Bezorgd'
  },
  DATA_CONFIGURATION: {
    en: 'Data Configuration',
    de: 'Daten Konfiguration',
    it: 'Configurazione dati',
    nl: 'Gegevens Configuratie'
  },
  COOKIE_BANNER: {
    en: 'Cookie Banner',
    de: 'Cookie Banner',
    it: 'Biscotto Banner',
    nl: 'Cookie Banner'
  },
  TOPIC: {
    en: 'Topic',
    de: 'Thema',
    it: 'Tema',
    nl: 'Thema'
  },
  READ_MORE: {
    en: 'Read More',
    de: 'Mehr lesen',
    it: 'Per saperne di più',
    nl: 'Meer lezen'
  },
  VIEW_ARTICLE: {
    en: 'View Article',
    de: 'Artikel ansehen',
    it: 'Vedi articolo',
    nl: 'Bekijk artikel'
  },
  PRIVACY_HEADER: {
    en: 'Please key in the privacy policy below',
    de: 'Bitte geben Sie die nachstehenden Datenschutzbestimmungen ein',
    it: 'Si prega di digitare la politica sulla privacy qui sotto',
    nl: 'Gelieve het privacybeleid hieronder in te toetsen'
  },
  POLICY_UPDATE_SUCCESS: {
    en: 'Privacy policy is updated successfully.',
    de: 'Die Datenschutzrichtlinie wurde erfolgreich aktualisiert.',
    it: `L'informativa sulla privacy è stata aggiornata con successo.`,
    nl: 'Privacybeleid is succesvol bijgewerkt.'
  },
  IMPRINT_HEADER: {
    en: 'Please key in the imprint below',
    de: 'Bitte geben Sie das untenstehende Impressum ein',
    it: `Si prega di digitare l'impronta qui sotto`,
    nl: 'Gelieve de opdruk hieronder in te toetsen'
  },
  IMPRINT_UPDATE_SUCCESS: {
    en: 'Imprint is updated successfully.',
    de: 'Das Impressum wurde erfolgreich aktualisiert.',
    it: `L'impronta viene aggiornata con successo.`,
    nl: 'Afdruk is succesvol bijgewerkt.'
  },
  CONSENT_STATEMENT: {
    en: 'I assume responsibility for the legally correct formulation of the text',
    de: 'Ich übernehme die Verantwortung für die rechtlich korrekte Formulierung des Textes',
    it: 'Mi assumo la responsabilità della formulazione giuridicamente corretta del testo',
    nl: 'Ik neem de verantwoordelijkheid voor de juridisch correcte formulering van de tekst'
  },
  ERROR_MESSAGE: {
    en: 'Please agree to assume responsibility for the legally correct formulation of the text before submit',
    de: 'Bitte übernehmen Sie die Verantwortung für die rechtlich korrekte Formulierung des Textes vor dem Absenden',
    it: 'Accetta di assumersi la responsabilità della formulazione legalmente corretta del testo prima di inviare',
    nl: 'Gelieve ermee in te stemmen de verantwoordelijkheid op u te nemen voor de juridisch correcte formulering van de tekst alvorens deze in te dienen'
  },
  AGREED_AT: {
    en: 'Order processing contract agreed at: ',
    de: 'AV Vertrag wurde abgeschlossen am: ',
    it: 'Contratto concordato a: ',
    nl: 'Contract overeengekomen op: '
  },
  AGREED_TO_CONTRACT: {
    en: 'This contract has been agreed.',
    de: 'Dieser Vertrag wurde vereinbart.',
    it: 'Questo contratto è stato concordato.',
    nl: 'Dit contract is overeengekomen.'
  },
  NO_CONTRACT_FOUND: {
    en: 'No contract is found.',
    de: 'Es wurde kein Vertrag gefunden.',
    it: 'Non si trova nessun contratto.',
    nl: 'Er is geen contract gevonden.'
  },
  CONTRACT_CONSENT_STATEMENT: {
    en: 'I have read and agree to the contract above',
    de: 'Ich habe die oben genannten Vertrag gelesen und stimme ihnen zu.',
    it: 'Ho letto e accetto i contratto di cui sopra',
    nl: 'Ik heb de bovenstaande contract gelezen en ga ermee akkoord'
  },
  DATA_CONFIG_WEBFORMS_UPDATE_INSTRUCTION: {
    en: 'Please enter here the number of days after which the data of a request via web forms will be deleted in the system.',
    de: 'Bitte geben Sie hier die Anzahl der Tage ein nach dem die Daten einer Anfrage über Webformulare im System gelöscht werden.',
    it: 'Inserisca il numero di giorni dopo i quali i dati di una richiesta tramite moduli web saranno cancellati dal sistema.',
    nl: 'Geef het aantal dagen op waarna de gegevens van een aanvraag via webformulieren uit het systeem worden gewist.'
  },
  DATA_CONFIG_WEBFORMS: {
    en: 'The data will be deleted after %{days} days.',
    de: 'Die Daten werden nach %{days} Tagen gelöscht.',
    it: 'I dati saranno cancellati dopo %{days} giorni.',
    nl: 'De gegevens worden na %{days} dagen gewist.'
  },
  DATA_CONFIG_SHOP_UPDATE_INSTRUCTION: {
    en: 'Here you can define after how many days an order will be anonymized in the store.',
    de: 'Hier können Sie festlegen nach wie viel Tagen eine Bestellung im Shop anonymisiert wird.',
    it: 'Qui puoi specificare dopo quanti giorni un ordine sarà reso anonimo nel negozio.',
    nl: 'Hier kunt u aangeven na hoeveel dagen een bestelling in de winkel wordt geanonimiseerd.'
  },
  DATA_CONFIG_SHOP: {
    en: 'The data will be anonymized after %{days} days.',
    de: 'Die Daten werden nach %{days} Tagen anonymisiert.',
    it: 'I dati saranno resi anonimi dopo %{days} giorni.',
    nl: 'De gegevens zullen na %{days} dagen worden geanonimiseerd.'
  },
  DATA_CONFIG_MEMBER_LOGIN_UPDATE_INSTRUCTION: {
    en: 'With this configuration you define after how many days a user account will expire and the data will be anonymized.',
    de: 'Mit dieser Konfiguration legen Sie fest nach wie viel Tagen ein User Account, nach Ablauf seiner Mitgliedschaft, erlischt und die Daten anonymisiert werden.',
    it: 'Con questa configurazione, si definisce dopo quanti giorni un account utente scade e i dati vengono resi anonimi.',
    nl: 'Met deze configuratie bepaalt u na hoeveel dagen een gebruikersaccount vervalt en de gegevens worden geanonimiseerd.'
  },
  DATA_CONFIG_MEMBER_LOGIN: {
    en: 'The data will be anonymized after %{days} days a user account expires.',
    de: 'Die Daten werden nach Ablauf der %{days} Tage eines Benutzerkontos anonymisiert.',
    it: 'I dati saranno resi anonimi dopo %{days} giorni dalla scadenza di un account utente.',
    nl: 'De gegevens zullen worden geanonimiseerd na %{days} dagen dat een gebruikersaccount vervalt.'
  },
  COURSES: {
    en: 'Courses',
    de: 'Kurse',
    it: 'Corsi',
    nl: 'Cursussen'
  },
  DATA_CONFIG_COURSES_UPDATE_INSTRUCTION: {
    en: 'Please enter here after how many days a student will be anonymized in the system after the course.',
    de: 'Bitte geben Sie hier an nach wie viel Tagen ein Kursteilnehmer, nach dem Kurs, im System anonymiziert wird.',
    it: 'Inserisca qui dopo quanti giorni un partecipante al corso sarà reso anonimo nel sistema dopo il corso.',
    nl: 'Geef hier aan na hoeveel dagen een cursist in het systeem geanonimiseerd zal zijn na de cursus.'
  },
  DATA_CONFIG_COURSES: {
    en: 'The student data  will be anonymized after %{days} day the course is finished.',
    de: 'Die Daten der Teilnehmer werden nach %{days} Tagen nach Kursende anonymisiert.',
    it: 'I dati degli studenti saranno resi anonimi dopo %{days} giorni dalla fine del corso.',
    nl: 'De gegevens van de studenten worden geanonimiseerd na %{days} dagen dat de cursus is afgelopen.'
  },
  DATA_CONFIG_FUNNEL_UPDATE_INSTRUCTION: {
    en: 'Here you can specify after how many days a user is anonymized in funnel marketing, after completion of a funnel. This has no effect on recipients of the newsletter. They remain active until they unsubscribe, are unsubscribed or change to a blacklist.',
    de: 'Hier können Sie angeben nach wie viel Tagen ein User im Funnelmarketing, nach Beendigung eines Funnels, anonymisiert wird. Dies hat keine Auswirkung auf Empfänger der Newsletter. Diese bleiben aktiv bis sie sich abmelden, abgemeldet werden oder auf eine Blacklist wechseln.',
    it: 'Qui potete specificare dopo quanti giorni un utente sarà reso anonimo nel funnel marketing dopo la fine di un funnel. Questo non ha alcun effetto sui destinatari della newsletter. Rimangono attivi fino a quando non si cancellano, non vengono cancellati o passano a una lista nera.',
    nl: 'Hier kunt u aangeven na hoeveel dagen een gebruiker geanonimiseerd wordt in funnel marketing na het einde van een funnel. Dit heeft geen effect op de ontvangers van de nieuwsbrief. Zij blijven actief tot zij zich uitschrijven, uitgeschreven worden of naar een zwarte lijst overgaan.'
  },
  FUNNEL_MARKETING: {
    en: 'Funnel Marketing',
    de: 'Funnelmarketing',
    it: 'Marketing a imbuto',
    nl: 'Trechter Marketing'
  },
  DATA_CONFIG_FUNNEL: {
    en: 'The user data  will be anonymized after %{days} days when all funnels are completed.',
    de: 'Die Nutzerdaten werden nach %{days} Tagen anonymisiert, wenn alle Funnels abgeschlossen sind.',
    it: 'I dati degli utenti saranno resi anonimi dopo %{days} giorni, quando tutti gli imbuti saranno completati.',
    nl: 'De gebruikersgegevens zullen worden geanonimiseerd na %{days} dagen wanneer alle trechters zijn voltooid.'
  },
  DATA_CONFIG_UPDATE_SUCCESS: {
    en: 'Data configuration is updated successfully.',
    de: 'Die Datenkonfiguration wurde erfolgreich aktualisiert.',
    it: 'La configurazione dei dati viene aggiornata con successo.',
    nl: 'De gegevensconfiguratie is met succes bijgewerkt.'
  },
  COOKIE_CATEGORIES: {
    en: 'Cookie categories',
    de: 'Cookie-Kategorien',
    it: 'Categorie di biscotti',
    nl: 'Koekjescategorieën'
  },
  CREATE_COOKIE_CATEGORY: {
    en: 'Create Cookie Category',
    de: 'Cookie-Kategorie erstellen',
    it: 'Creare una categoria di cookie',
    nl: 'Creëer een cookie-categorie'
  },
  UPDATE_COOKIE_CATEGORY: {
    en: 'Update cookie category',
    de: 'Cookie-Kategorie aktualisieren',
    it: 'Aggiornare la categoria dei cookie',
    nl: 'Update cookie categorie'
  },
  ACISO_ASSUMES_NO_RESPONSIBILITY: {
    en: 'ACISO assumes no responsibility for the legally compliant wording of the information, as you are responsible for creating it. If you have any questions, please do not hesitate to contact your data protection officer or oms@aciso.com',
    de: 'ACISO übernimmt keine Verantwortung für die rechtskonforme Formulierung der Informationen, da Sie für deren Erstellung verantwortlich sind. Wenn Sie Fragen haben, wenden Sie sich bitte an Ihren Datenschutzbeauftragten oder an oms@aciso.com',
    it: 'ACISO non si assume alcuna responsabilità per la formulazione conforme alla legge delle informazioni, in quanto l\'utente è responsabile della loro creazione. Per qualsiasi domanda, non esitate a contattare il vostro responsabile della protezione dei dati o oms@aciso.com.',
    nl: 'ACISO neemt geen verantwoordelijkheid voor de wettelijk voorgeschreven bewoordingen van de informatie, aangezien u verantwoordelijk bent voor het maken ervan. Als u vragen hebt, aarzel dan niet om contact op te nemen met uw gegevensbeschermingsfunctionaris of met oms@aciso.com.'
  },
  COOKIE_TYPE: {
    en: 'Cookie type',
    de: 'Cookie Typ',
    it: 'Tipo di biscotto',
    nl: 'Soort cookie'
  },
  TARGETING: {
    en: 'Targeting/ Advertising',
    de: 'Targeting/ Werbung',
    it: 'Targeting/Pubblicità',
    nl: 'Doelwit/ reclame'
  },
  PRIVACY: {
    en: 'Data protection',
    de: 'Datenschutz',
    it: 'Protezione dei dati',
    nl: 'Gegevensbescherming'
  },
  STRICTLY_NECCESSARY: {
    en: 'Essential',
    de: 'Essenziell',
    it: 'Essenziale',
    nl: 'Essentieel'
  },
  FUNCTIONALITY: {
    en: 'Functionality',
    de: 'Funktionalität',
    it: 'Funzionalità',
    nl: 'Functionaliteit'
  },
  TRACKING: {
    en: 'Tracking',
    de: 'Tracking',
    it: 'Tracking',
    nl: 'Het volgen van'
  },
  INFORMATION: {
    en: 'Information',
    de: 'Information',
    it: 'Informazioni',
    nl: 'Informatie'
  },
  NO_DISPLAY: {
    en: 'Do not display',
    de: 'Nicht anzeigen',
    it: 'Non visualizzare',
    nl: 'Niet weergeven'
  },
  ALWAYS: {
    en: 'Active ( not otherwise )',
    de: 'Activ ( nicht anderbar )',
    it: 'Attivo ( non altrimenti )',
    nl: 'Actief ( niet anders )'
  },
  SWITCH_ON: {
    en: 'Switched on',
    de: 'Eingeschaltet',
    it: 'Acceso',
    nl: 'Ingeschakeld'
  },
  SWITCH_OFF: {
    en: 'Switched off',
    de: 'Ausgeschaltet',
    it: 'Spento',
    nl: 'Uitgeschakeld'
  },
  COOKIE_CONTENT: {
    en: 'Cookie content',
    de: 'Cookie-Inhalt',
    it: 'Contenuto dei biscotti',
    nl: 'Cookie-inhoud'
  },
  SETUP_COOKIE_CATEGORIES_HERE: {
    en: 'Setup cookie categories here',
    de: 'Richten Sie hier Cookie-Kategorien ein',
    it: 'Qui si possono impostare le categorie dei cookie',
    nl: 'Stel hier de cookie-categorieën in'
  },
  SETUP_COOKIE_CONTENT_HERE: {
    en: 'Setup cookie content here',
    de: 'Cookie-Inhalt hier einstellen',
    it: 'Imposta qui il contenuto del cookie',
    nl: 'Setup cookie-inhoud hier'
  },
  CREATE_COOKIE_CONTENT: {
    en: 'Create Cookie Content',
    de: 'Cookie-Inhalt erstellen',
    it: 'Creare il contenuto dei cookie',
    nl: 'Creëer cookie-inhoud'
  },
  UPDATE_COOKIE_CONTENT: {
    en: 'Update cookie content',
    de: 'Cookie-Inhalt aktualisieren',
    it: 'Aggiornare il contenuto dei cookie',
    nl: 'Update cookie-inhoud'
  },
  COOKIE_NAME: {
    en: 'Cookie name',
    de: 'Cookie-Name',
    it: 'Nome del biscotto',
    nl: 'Koekjesnaam'
  },
  COOKIE_CODE: {
    en: 'Cookie code ( script )',
    de: 'Cookie-Code (Skript)',
    it: 'Codice cookie ( script )',
    nl: 'Cookie code ( script )'
  },
  EDITOR: {
    en: 'Editor',
    de: 'Herausgeber',
    it: 'Editore',
    nl: 'Redacteur'
  },
  URL_PUBLISHER: {
    en: 'URL publisher',
    de: 'URL-Verleger',
    it: 'Editore dell\'URL',
    nl: 'URL-uitgever'
  },
  RUNNING_TIME: {
    en: 'Running time',
    de: 'Laufzeit',
    it: 'Tempo di esecuzione',
    nl: 'Looptijd'
  },
  CURRENT_EDITING_LANGUAGE: {
    en: 'Current editing language : ',
    de: 'Aktuelle Bearbeitungssprache : ',
    it: 'Lingua di editing corrente : ',
    nl: 'Huidige bewerkingstaal : '
  },
  PLEASE_HAVE_A_DIFFERENT_POSITION_VALUE: {
    en: 'Please have a different position value',
    de: 'Bitte haben Sie einen anderen Positionswert',
    it: 'Si prega di avere un valore di posizione diverso',
    nl: 'Gelieve een andere positiewaarde te hebben'
  },
  THE_CONTENT_IS_NOT_AVAILABLE: {
    en: 'The content is not available',
    de: 'Der Inhalt ist nicht verfügbar',
    it: 'Il contenuto non è disponibile',
    nl: 'De inhoud is niet beschikbaar'
  },
  TYPE: {
    en: 'Type',
    de: 'Typ',
    it: 'Tipo',
    nl: 'Type'
  },
  STANDARD_CONDITION: {
    en: 'Standard condition',
    de: 'Standard-Bedingung',
    it: 'Condizione standard',
    nl: 'Standaardtoestand'
  },
  POSITION: {
    en: 'Position',
    de: 'Position',
    it: 'Posizione',
    nl: 'Positie'
  },
  FACEBOOK_PIXEL: {
    en: 'Facebook Pixel',
    de: 'Facebook Pixel',
    it: 'Facebook Pixel',
    nl: 'Facebook Pixel'
  },
  GOOGLE_ANALYTICS: {
    en: 'Google Analytics',
    de: 'Google Analytics',
    it: 'Google Analytics',
    nl: 'Google Analytics'
  },
  GOOGLE_TAG_MANAGER: {
    en: 'Google Tag Manager',
    de: 'Google Tag Manager',
    it: 'Google Tag Manager',
    nl: 'Google Tag Manager'
  },
  DEFAULT_COOKIE: {
    en: 'Default cookie',
    de: 'Standard-Cookie',
    it: 'Cookie predefinito',
    nl: 'Standaard cookie'
  },
  DELETE_CONFIRMATION_COOKIE_CATEGORIES: {
    en: 'Are you sure to remove selected cookie category?',
    de: 'Sind Sie sicher, dass Sie die ausgewählte Cookie-Kategorie entfernen möchten?',
    it: 'Sei sicuro di rimuovere la categoria di cookie selezionata?',
    nl: 'Weet u zeker dat u de geselecteerde cookie-categorie wilt verwijderen?'
  },
  DELETE_CONFIRMATION_COOKIE_CONTENT: {
    en: 'Are you sure to remove selected cookie content?',
    de: 'Sind Sie sicher, dass Sie ausgewählte Cookie-Inhalte entfernen wollen?',
    it: 'Sei sicuro di rimuovere il contenuto del cookie selezionato?',
    nl: 'Weet u zeker dat u geselecteerde cookie-inhoud wilt verwijderen?'
  },
  COOKIE_BANNER_DESCRIPTION: {
    en: 'Description',
    de: 'Beschreibung',
    it: 'Descrizione',
    nl: 'Beschrijving'
  },
  MOST_BOOKED: {
    en: 'Most Booked',
    de: 'Meistgebucht',
    it: 'I più prenotati',
    nl: 'Meest Geboekt'
  },
  BUY: {
    en: 'Buy',
    de: 'Interesse',
    it: 'Compra',
    nl: 'Kopen'
  },
  CONSENT_MODAL: {
    en: 'Consent Modal',
    de: 'Einwilligung Modal',
    it: 'Consenso Modale',
    nl: 'Toestemming Modaal'
  },
  WELCOME_MESSAGE: {
    en: 'Hello there, Welcome to ACISO Cockpit !',
    de: 'Hallo, willkommen im ACISO Cockpit!',
    it: 'Salve, benvenuto su ACISO Cockpit!',
    nl: 'Hallo daar, Welkom bij ACISO Cockpit!'
  },
  CONSENT_INSTRUCTION: {
    en: 'Before you can continue to access your Ark Sites and the rest of the modules, please take a moment to do the following :',
    de: 'Bevor Sie auf Ihre Website und die übrigen Module zugreifen können, nehmen Sie sich bitte einen moment Zeit für:',
    it: 'Prima che tu possa continuare ad accedere ai tuoi Siti Arca e al resto dei moduli, prenditi un momento per fare quanto segue:',
    nl: 'Voordat u verder kunt gaan met toegang tot uw Ark Sites en de rest van de modules, moet u even de tijd nemen om het volgende te doen :'
  },
  AGREED_ON_CONTRACT: {
    en: 'Agree on the AV contract',
    de: 'Vertrag zur Auftragsverarbeitung (AV Vertrag)',
    it: 'Concordare il contratto AV',
    nl: 'Overeenstemming bereiken over het AV-contract'
  },
  AV_CONTRACT_STEP_1: {
    en: 'Step 1 : Go to GDPR Center > AV Contract',
    de: 'Schritt 1 : Gehen Sie zum DSGVO Center > AV-Vertrag',
    it: 'Passo 1 : Vai al Centro GDPR > Contratto AV',
    nl: 'Stap 1 : Ga naar GDPR Center > AV Contract'
  },
  AV_CONTRACT_STEP_2: {
    en: 'Step 2 : Study through the full contract',
    de: 'Schritt 2 : Lesen Sie den gesamten Vertrag',
    it: 'Passo 2 : Studiare il contratto completo',
    nl: 'Stap 2 : Het volledige contract doornemen'
  },
  AV_CONTRACT_STEP_3: {
    en: 'Step 3 : Agree & Submit',
    de: 'Schritt 3 : Zustimmen & absenden',
    it: 'Passo 3 : Accettare e inviare',
    nl: 'Stap 3 : Akkoord & verzenden'
  },
  AGREED_TO_POLICY_AND_IMPRINT: {
    en: 'Agree to take over the responsibility of privacy policy & imprint',
    de: 'Impressum & Datenschutz',
    it: `Accettare di assumersi la responsabilità della politica sulla privacy e dell'impronta`,
    nl: 'Akkoord om de verantwoordelijkheid over te nemen van privacy beleid & opdruk'
  },
  POLICY_IMPRINT_STEP_1: {
    en: 'Step 1 : Go to GDPR Center > Privacy Policy & Imprint',
    de: 'Schritt 1 : Gehen Sie zum DSGVO Center > Impressum & Datenschutz',
    it: 'Passo 1 : Vai al Centro GDPR > Privacy Policy & Imprint',
    nl: 'Stap 1 : Ga naar GDPR Center > Privacybeleid & Afdruk'
  },
  POLICY_IMPRINT_STEP_2: {
    en: 'Step 2 : Go through the content with your lawyer',
    de: 'Schritt 2 : Bearbeiten Sie den Inhalt mit Ihrem Datenschutzbeauftragten oder Anwalt',
    it: 'Passo 2 : esaminare il contenuto con il tuo avvocato',
    nl: 'Stap 2 : Neem de inhoud door met uw advocaat'
  },
  POLICY_IMPRINT_STEP_3: {
    en: 'Step 3 : Provide consent upon submission',
    de: 'Schritt 3 : Zustimmen & absenden',
    it: 'Passo 3 : fornire il consenso al momento della presentazione',
    nl: 'Stap 3 : Geef toestemming bij indiening'
  },
  COMPLETED: {
    en: 'Completed',
    de: 'Abgeschlossen',
    it: 'Completato',
    nl: 'Voltooid'
  },
  CONFIRMATION_BOX: {
    en: 'Confirmation Box',
    de: 'Bestätigungsfeld',
    it: 'Casella di conferma',
    nl: 'Bevestigingsvak'
  },
  IMPRINT_ERROR: {
    en: 'Imprint cannot be empty',
    de: 'Impressum kann nicht leer sein',
    it: `L'impronta non può essere vuota`,
    nl: 'Opdruk mag niet leeg zijn'
  },
  POLICY_ERROR: {
    en: 'Privacy policy cannot be empty',
    de: 'Die Datenschutz kann nicht leer sein',
    it: `L'informativa sulla privacy non può essere vuota`,
    nl: 'Privacybeleid kan niet leeg zijn'
  },
  FUNNEL: {
    en: 'Funnel',
    de: 'Email Funnel',
    it: 'Imbuto',
    nl: 'Trechter'
  },
  CREATE_FUNNEL: {
    en: 'Create Funnel',
    de: 'Trichter erstellen',
    it: 'Crea imbuto',
    nl: 'Creëer trechter'
  },
  FUNNELS: {
    en: 'Funnels',
    de: 'Email Funnel',
    it: 'Imbuti',
    nl: 'Trechters'
  },
  CREATE_SITE_FUNNEL: {
    en: 'Create New Funnel',
    de: 'Neuen Trichter erstellen',
    it: 'Creare un nuovo imbuto',
    nl: 'Creëer nieuwe trechterCreare un nuovo imbuto'
  },
  SCENARIO: {
    en: 'Scenario',
    de: 'Szenario',
    it: 'Scenario',
    nl: 'Scenario'
  },
  ARE_YOU_CREATING_FROM_TEMPLATE: {
    en: 'Are you creating from template?',
    de: 'Erstellen Sie aus einer Vorlage?',
    it: 'Stai creando da un modello?',
    nl: 'Creëert u vanuit een sjabloon?'
  },
  MAILING: {
    en: 'Mailing',
    de: 'Mailing',
    it: 'Mailing',
    nl: 'Mailing'
  },
  CREATE_MAILING: {
    en: 'Create Mailing',
    de: 'Mailing erstellen',
    it: 'Creare Mailing',
    nl: 'Mailing maken'
  },
  ACTIVE_RECIPIENTS: {
    "en": "Active Recipients",
    "de": "Active Empfänger",
    "it": "Attivi Destinatari",
    "nl": "Actieve Ontvangers"
  },
  START_IF_TRIGGERED: {
    en: 'Start if triggered',
    de: 'Start bei Auslösung',
    it: 'Iniziare se attivato',
    nl: 'Start indien geactiveerd'
  },
  START_ON_FIX_DAY: {
    en: 'Start on a fix day',
    de: 'Start an einem festen Tag',
    it: 'Iniziare in un giorno fisso',
    nl: 'Begin op een vaste dag'
  },
  HAS_EXPIRY_DATE: {
    en: 'Has Expiry Date',
    de: 'Enddatum festlegen ',
    it: 'Ha una data di scadenza',
    nl: 'Heeft Vervaldatum'
  },
  ENTER_FUNNEL_TITLE: {
    en: 'Enter funnel title',
    de: 'Titel des Trichters eingeben',
    it: 'Inserisci il titolo del funnel',
    nl: 'Titel van trechter invoeren'
  },
  ENTER_FUNNEL_DESC: {
    en: 'Enter funnel description',
    de: 'Beschreibung des Trichters eingeben',
    it: `Inserisci la descrizione dell'imbuto`,
    nl: 'Voer trechterbeschrijving in'
  },
  DESCRIPTION: {
    en: 'Description',
    de: 'Beschreibung',
    it: 'Descrizione',
    nl: 'Beschrijving'
  },
  CREATE_SITE_FUNNEL_SUCCESS: {
    en: 'Site funnel created successfully.',
    de: 'Website-Trichter erfolgreich erstellt.',
    it: 'Funnel del sito creato con successo.',
    nl: 'Site trechter succesvol gemaakt.Funnel del sito creato con successo.'
  },
  UPDATE_SITE_FUNNEL_SUCCESS: {
    en: 'Site funnel updated successfully.',
    de: 'Der Trichter der Website wurde erfolgreich aktualisiert.',
    it: 'Funnel del sito aggiornato con successo.',
    nl: 'Site trechter succesvol bijgewerkt'
  },
  UPDATE_FUNNEL: {
    en: 'Update Funnel',
    de: 'Update-Trichter',
    it: "Aggiornamento dell'imbuto",
    nl: 'Trechter bijwerken'
  },
  DESIGN_MAILING: {
    en: 'Design Mailing',
    de: 'Entwurf Mailing',
    it: 'Design Mailing',
    nl: 'Ontwerp verzendingDesign Mailing'
  },
  UPDATE_MAILING: {
    en: 'Update mailing',
    de: 'Mailing aktualisieren',
    it: 'Aggiornamento Mailing',
    nl: 'Mailing bijwerken'
  },
  SUMMARY: {
    "en": "Summary",
    "de": "Zusammenfassung",
    "it": "Sommario",
    "nl": "Samenvatting"
  },
  DETAILS: {
    "en": "Details",
    "de": "Einzelheiten",
    "it": "Dettagli",
    "nl": "Details"
  },
  EMAIL_CREATED: {
    "en": "Email(s) that created",
    "de": "E-Mail (s), die erstellt wurden",
    "it": "Email che hanno creato",
    "nl": "E-mail (s) die zijn aangemaakt"
  },
  TOTAL_REJECTED: {
    "en": "Total emails rejected",
    "de": "Insgesamt abgelehnte E-Mails",
    "it": "Totale messaggi di posta elettronica rifiutati",
    "nl": "Totaal aantal afgewezen e-mails"
  },
  EMAIL_ACTIVE: {
    "en": "Email(s) that existed as active recipient:",
    "de": "E-Mail (s), die als aktiver Empfänger vorhanden waren:",
    "it": "Email esistenti come destinatari attivi:",
    "nl": "E-mail (s) die bestonden als actieve ontvanger:"
  },
  EMAIL_BLACKLISTED: {
    "en": "Email(s) that existed as blacklisted recipient:",
    "de": "E-Mail (s), die als Empfänger auf der schwarzen Liste vorhanden waren:",
    "it": "Email che esistevano come destinatari nella lista nera:",
    "nl": "E-mail (s) die bestonden als ontvanger op de zwarte lijst:"
  },
  EMAIL_DUPLICATED: {
    "en": "Email(s) that found duplicated:",
    "de": "E-Mail (s), die doppelt gefunden wurden:",
    "it": "Email che sono state trovate duplicate:",
    "nl": "E-mail (s) die gedupliceerd zijn gevonden:"
  },
  BULK_UPLOAD_RECIPIENT: {
    "en": "Bulk upload for recipients",
    "de": "Massen-Upload für Empfänger",
    "it": "Caricamento collettivo per i destinatari",
    "nl": "Bulkupload voor ontvangers"
  },
  CLICK: {
    "en": "Click ",
    "de": "Klicken ",
    "it": "Fare clic ",
    "nl": "Klik "
  },
  HERE: {
    "en": "here ",
    "de": "Sie hier ",
    "it": "qui ",
    "nl": "hier "
  },
  DOWNLOAD_SAMPLE: {
    "en": "to download sample CSV file",
    "de": "um eine CSV-Beispieldatei herunterzuladen",
    "it": "per scaricare un file CSV di esempio",
    "nl": "om een ​​voorbeeld van een CSV-bestand te downloaden"
  },
  CHOOSE_FILE: {
    "en": "Choose a CSV file or Excel file to upload",
    "de": "Wählen Sie eine CSV- oder Excel-Datei zum Hochladen aus",
    "it": "Scegli un file CSV o un file Excel da caricare",
    "nl": "Kies een CSV-bestand of Excel-bestand om te uploaden"
  },
  UPLOAD_FILE: {
    "en": "Upload a file",
    "de": "Eine Datei hochladen",
    "it": "Caricare un file",
    "nl": "Een bestand uploaden"
  },
  UPLOAD_SUCCESS: {
    "en": "Upload success",
    "de": "Erfolgreich hochgeladen",
    "it": "Caricamento riuscito",
    "nl": "Upload geslaagd"
  },
  UPLOAD_COMPLETED: {
    en: 'Upload completed',
    de: 'Upload abgeschlossen',
    it: 'Carica completato',
    nl: 'Upload voltooid'
  },
  RECIPIENT_GROUP: {
    "en": "Recipient group",
    "de": "Empfängergruppe",
    "it": "Gruppo di destinatari",
    "nl": "Ontvangersgroep"
  },
  CREATE_ACTIVE_SUCCESS: {
    "en": "Recipient is created successfully.",
    "de": "Empfänger wird erfolgreich angelegt.",
    "it": "Ontvanger is succesvol aangemaakt.",
    "nl": "De inactieve ontvanger is succesvol aangemaakt."
  },
  CREATE_ACTIVE_FAILED: {
    "en": "Recipient created failed.",
    "de": "Die Erstellung des Empfängers ist fehlgeschlagen.",
    "it": "Destinatario creato non riuscito.",
    "nl": "Ontvanger aangemaakt mislukt."
  },
  UPDATE_ACTIVE_SUCCESS: {
    "en": "Active recipient is updated successfully.",
    "de": "Aktiver Empfänger wird erfolgreich aktualisiert.",
    "it": "Il destinatario attivo viene aggiornato correttamente.",
    "nl": "De actieve ontvanger is succesvol bijgewerkt."
  },
  CREATE_GROUP_SUCCESS: {
    en: 'Recipient group created successfully.',
    de: 'Empfängergruppe erfolgreich erstellt.',
    it: 'Gruppo di destinatari creato con successo.',
    nl: 'Ontvangersgroep succesvol aangemaakt.'
  },
  UPDATE_GROUP_SUCCESS: {
    en: 'Recipient group updated successfully.',
    de: 'Empfängergruppe erfolgreich aktualisiert.',
    nl: 'Ontvangersgroep succesvol bijgewerkt.',
    it: 'Il gruppo di destinatari è stato aggiornato con successo.'
  },
  RECIPIENT_GROUP_DETAILS: {
    en: 'Recipient group details',
    de: 'Angaben zur Empfängergruppe',
    it: 'Dettagli del gruppo destinatario',
    nl: 'Gegevens over de ontvangersgroep'
  },
  FUNNEL_GROUPS: {
    en: 'Funnel group',
    de: 'Trichtergruppe',
    it: 'Gruppo a imbuto',
    nl: 'Trechter groepa'
  },
  CREATE_MAILING_SUCCESS: {
    en: 'Create Mailing success',
    de: 'Mailing erfolgreich erstellen',
    it: 'Creare la mailing con successo',
    nl: 'Mailing met succes aanmaken'
  },
  NO_RESULT_FOUND: {
    en: 'No result found',
    de: 'Kein Ergebnis gefunden',
    it: 'Nessun risultato trovato',
    nl: 'Geen resultaat gevonden'
  },
  EMAIL_INACTIVE: {
    "en": "Email(s) that existed as inactive recipient:",
    "de": "E-Mail (s), die als inaktiver Empfänger vorhanden waren:",
    "it": "Email che esistevano come destinatario inattivo:",
    "nl": "E-mail (s) die bestonden als inactieve ontvanger:"
  },
  GROUPS: {
    en: 'Groups',
    de: 'Gruppen',
    it: 'Gruppi',
    nl: 'Groepen'
  },
  RECIPIENT_GROUP_REQUIRED: {
    en: 'Recipient group is required',
    de: 'Empfängergruppe ist erforderlich',
    it: 'Il gruppo di destinatari è richiesto',
    nl: 'Ontvangersgroep is vereist'
  },
  DOWNLOAD_AV_CONTRACT: {
    en: 'Download AV Contract',
    de: 'Download AV Vertrag',
    it: 'Scarica il contratto AV',
    nl: 'Download AV Contract'
  },
  MEMBER_AREA: {
    en: 'Member Area',
    de: 'Mitgliederbereich',
    it: 'Area membri',
    nl: 'Leden'
  },
  TEST_BUTTON: {
    en: 'Funnel testing',
    de: 'Trichterprüfung',
    it: "Test dell'imbuto",
    nl: 'Trechter testen'
  },
  STOP_FUNNEL_IF: {
    en: 'Stop funnel if',
    de: 'Trichter anhalten, wenn',
    it: "Fermare l'imbuto se",
    nl: 'Stop trechter als'
  },
  USER_ENTER_ONE_FOLLOWING_FUNNEL: {
    en: 'User enters one of the following funnels',
    de: 'Der Benutzer betritt einen der folgenden Trichter',
    it: "L'utente entra in uno dei seguenti imbuti",
    nl: 'Gebruiker komt in een van de volgende trechters'
  },
  START_END_DATE_TITLE: {
    en: 'Time range this funnel is active:',
    de: 'Zeitbereich, in dem dieser Trichter aktiv ist:',
    it: 'Intervallo di tempo in cui questo imbuto è attivo:',
    nl: 'Tijdsspanne waarin deze trechter actief is:'
  },
  END_DATE_REQUIRED: {
    en: 'End date and start date is required.',
    de: 'Enddatum und Startdatum sind erforderlich.',
    it: 'La data di fine e la data di inizio sono richieste.',
    nl: 'Einddatum en begindatum zijn vereist.'
  },
  SHARED_MEDIA: {
    "en": "Shared Media",
    "de": "Gemeinsame Medien",
    "nl": "Gedeelde media",
    "it": "Media condivisi"
  },
  OWN_DATA: {
    "en": "Own Data",
    "de": "Eigene Daten",
    "nl": "Eigen gegevens",
    "it": "Dati propri"
  },
  CENTER_DATA_DRIVE: {
    en: "Center Data Drive",
    de: "Eigene Dateien",
    it: "Unità dati del centro",
    nl: "Centrum Data Drive"
  },
  CURRENT_FOLDER: {
    en: "Current Folder",
    de: "Aktueller Ordner",
    it: "Cartella corrente",
    nl: "Huidige map"
  },
  MEDIAS_INSIDE_FOLDER: {
    en: "Medias inside selected folder",
    de: "Medien im ausgewählten Ordner",
    it: "Media all'interno della cartella selezionata",
    nl: "Media in geselecteerde map"
  },
  ADD_FILE_FOLDER: {
    en: "Add File / Folder",
    de: "Datei / Ordner hinzufügen",
    it: "Aggiungi file / cartella",
    nl: "Voeg bestand / map toe"
  },
  SELECT: {
    en: 'Select',
    de: 'Auswählen',
    it: 'Selezionare',
    nl: 'Selecteer'
  },
  SENT: {
    en: 'Sent',
    de: 'versendet',
    it: 'versendet',
    nl: 'versendet'
  },
  DRAFT: {
    en: 'Draft',
    de: 'Entwurf',
    it: 'Entwurf',
    nl: 'Entwurf'
  },
  SCHEDULED: {
    en: 'Scheduled',
    de: 'terminiert',
    it: 'terminiert',
    nl: 'terminiert'
  },
  IMPORTANT_NOTE_RECIPIENT_ADD: {
    en: 'Important note: All manually created newsletter recipients receive a one-time email with the request to verify the receipt of the newsletter. This procedure is called Double-Opt-In (DOI) and is correct according to data protection law. As long as the verification by the owner of the email address has not taken place, you will find the corresponding email address under the menu item Recipient/DOI. These email addresses will not receive any newsletters as long as they have this status.',
    de: 'Wichtiger Hinweis: Alle manuell angelegten Newsletter Empfänger bekommen einmalig eine Email mit der Bitte den Empfang des Newsletters zu verifzieren. Dieses Verfahren wird Double-Opt-In (DOI) genannt und ist datenschutzrechtlich korrekt. Solange die Verifizierung durch den Besitzer der Email Adresse nicht stattgefunden hat, finden Sie die entsprechende Email Adresse und der dem Menüpunkt Empfänger/DOI. Diese Email Adressen bekommen keine Newsletter zugestellt, sloange sie diesen Status haben.',
    nl: 'Wichtiger Hinweis: Alle manuell angelegten Newsletter Empfänger bekommen einmalig eine Email mit der Bitte den Empfang des Newsletters zu verifzieren. Dieses Verfahren wird Double-Opt-In (DOI) genannt und ist datenschutzrechtlich korrekt. Solange die Verifizierung durch den Besitzer der Email Adresse nicht stattgefunden hat, finden Sie die entsprechende Email Adresse und der dem Menüpunkt Empfänger/DOI. Diese Email Adressen bekommen keine Newsletter zugestellt, sloange sie diesen Status haben.',
    it: 'Wichtiger Hinweis: Alle manuell angelegten Newsletter Empfänger bekommen einmalig eine Email mit der Bitte den Empfang des Newsletters zu verifzieren. Dieses Verfahren wird Double-Opt-In (DOI) genannt und ist datenschutzrechtlich korrekt. Solange die Verifizierung durch den Besitzer der Email Adresse nicht stattgefunden hat, finden Sie die entsprechende Email Adresse und der dem Menüpunkt Empfänger/DOI. Diese Email Adressen bekommen keine Newsletter zugestellt, sloange sie diesen Status haben.'
  },
  CONFIGURE_NOW: {
    en: 'Configure now',
    de: 'Jetzt ausfüllen',
    it: 'Configura ora',
    nl: 'Nu configureren'
  },
  INFORM_OPTIONAL_PAGE: {
    en: 'Filling this page is optional.',
    de: 'Das Ausfüllen dieser Seite ist optional.',
    it: 'La compilazione di questa pagina è opzionale.',
    nl: 'Het invullen van deze pagina is optioneel.'
  },
  ACTIVATE_OPTIONAL_PAGE_INSTRUCTION: {
    en: 'If you would like to order {{pageName}}, please click on "Activate and fill in"',
    de: 'Wenn Sie {{pageName}} bestellen möchten, klicken Sie bitte auf "Aktivieren und ausfüllen"',
    it: 'Se vuoi ordinare {{pageName}}, clicca su "Attiva e compila"',
    nl: 'Als u {{pageName}} wilt bestellen, klik dan op "Activeren en invullen"'
  },
  ACTIVATE_PAGE: {
    en: 'Activate Page',
    de: 'Seite aktivieren',
    it: 'Attivare la pagina',
    nl: 'Pagina activeren'
  },
  ARCHIVED_FUNNELS: {
    en: 'Archived funnels',
    de: 'Archivierte Trichter',
    it: 'Imbuti archiviati',
    nl: 'Gearchiveerde trechters'
  },
  ARCHIVE: {
    en: 'Archive',
    de: 'Archivieren',
    it: 'Archivio',
    nl: 'Archief'
  },
  ARCHIVE_UNARCHIVE_CONFIRMATION: {
    en: 'Are you sure you want to archive/unarchive this content?',
    de: 'Sind Sie sicher, dass Sie diesen Inhalt archivieren/unarchivieren wollen?',
    it: 'Sei sicuro di voler archiviare/disarchiviare questo contenuto?',
    nl: 'Weet u zeker dat u deze inhoud wilt archiveren/unarchiveren?'
  },
  UNARCHIVE: {
    "en": "Unarchive",
    "de": "Nicht archivieren",
    "it": "Unarchive",
    "nl": "Unarchive"
  },
  ARCHIVED_MAILINGS: {
    en: 'Archived mailings',
    de: 'Archivierte Sendungen',
    it: 'Invii archiviati',
    nl: 'Gearchiveerde mailings'
  },
  INFOCENTER: {
    en: 'Infocenter',
    de: 'Infocenter',
    it: 'Infocenter',
    nl: 'Infocenter'
  },
  INFOCENTER_DESCRIPTION: {
    en: 'Note on the information requirements according to Infocenter GDPR <br> With the following texts we inform you according to Infocenter GDPR about the processing of personal data in our systems. You can find all information about the processing as well as the anonymization and deletion concepts by clicking on the corresponding post.',
    de: 'Hinweis zu den Informationspflichten nach Infocenter DSGVO <br> Mit den folgenden Texten informieren wir Sie laut Infocenter DSGVO über die Verarbeitung personenbezogener Daten in unseren Systemen. Sie finden alle Informationen zur Verarbeitung sowie zu den Anonymisierungs- und Löschkonzepten indem Sie auf den entsprechenden Beitrag klicken.',
    it: `Nota sull'obbligo di informazione secondo Infocenter GDPR <br> Con i seguenti testi vi informiamo secondo Infocenter GDPR sul trattamento dei dati personali nei nostri sistemi. Potete trovare tutte le informazioni sul trattamento così come i concetti di anonimizzazione e cancellazione cliccando sul post corrispondente.`,
    nl: 'Opmerking over de informatieverplichtingen volgens Infocenter GDPR <br> Met de volgende teksten informeren wij u volgens Infocenter GDPR over de verwerking van persoonsgegevens in onze systemen. U kunt alle informatie over de verwerking evenals de anonimiserings- en verwijderingsconcepten vinden door op het desbetreffende bericht te klikken.'
  },
  EMAIL_DOI: {
    "en": "Email(s) that existed as DOI:",
    "de": "E-Mail (s), die als DOI existierten:",
    "it": "Email che esistevano come DOI:",
    "nl": "E-mail (s) die bestonden als DOI:"
  },
  START_DATE: {
    en: 'Start date',
    de: 'Startdatum',
    it: 'Data di inizio',
    nl: 'Startdatum'
  },
  END_DATE: {
    en: 'End date',
    de: 'Enddatum',
    it: 'Data di fine',
    nl: 'Einddatum'
  },
  USE_NEWSLETTER_PLACEHOLDER: {
    en: 'You can use the following placeholders in the newsletter contents. They will be automatically replaced.',
    de: 'Sie können die folgenden Platzhalter im Inhalt des Newsletters verwenden. Sie werden dann automatisch ersetzt.',
    it: 'Puoi usare i seguenti segnaposti nei contenuti della newsletter. Saranno sostituiti automaticamente.',
    nl: 'U kunt de volgende plaatshouders gebruiken in de inhoud van de nieuwsbrief. Ze zullen automatisch vervangen worden.'
  },
  Sun:{
    en: 'Sun',
    de: 'So',
    nl: 'Zo.',
    it: 'Do.'
  },
  Mon:{
    en: 'Mon',
    de: 'Mo',
    nl: 'Ma.',
    it: 'Lun.'
  },
  Tue:{
    en: 'Tue',
    de: 'Di',
    nl: 'Di.',
    it: 'Mar.'
  },
  Wed:{
    en: 'Wed',
    de: 'Mi',
    nl: 'Wo.',
    it: 'Mer.'
  },
  Thu:{
    en: 'Thu',
    de: 'Do',
    nl: 'Do.',
    it: 'Gio.'
  },
  Fri:{
    en: 'Fri',
    de: 'Fr',
    nl: 'Vr.',
    it: 'Ven.'
  },
  Sat:{
    en: 'Sat',
    de: 'Sa',
    nl: 'Za.',
    it: 'Sab.'
  },
  ACTIVATE_AND_FILL_IN: {
    en: 'Activate & fill in',
    de: 'Aktivieren & ausfüllen',
    it: 'Attiva e compila',
    nl: 'Activeren en invullen'
  },
  SKIP_AND_CONTINUE: {
    en: 'Skip & continue',
    de: 'Überspringen & fortfahren',
    it: 'Salta e continua',
    nl: 'Overslaan en doorgaan'
  },
  NEWSLETTER_SENDERS_CANNOT_DELETE_DESC: {
    en: 'Please note that once created senders can not be deleted again. A frequent change of the sender would lead to the fact that the dispatch service would consider the Newlsetter as spam and possibly would not be delivered correctly.',
    de: 'Bitte beachten Sie, dass einmal angelegte Absender nicht wieder gelöscht werden können. Ein häufiger Wechsel des Absenders würde dazu führen, dass der Versanddienstleister den Newlsetter als Spam betrachten würde und möglicherweise nicht korrekt zustellt.',
    nl: '',
    it: ''
  },
  DATA_CREATED_SUCCESS: {
    en: 'Data is created successfully.',
    de: 'Daten wurden erfolgreich erstellt',
    nl: '',
    it: ''
  },
  DATA_UPDATED_SUCCESS: {
    en: 'Data is updated successfully.',
    de: 'Die Daten wurden erfolgreich aktualisiert.',
    nl: '',
    it: ''
  },
  DATA_REMOVED_SUCCESS: {
    en: 'Data is removed successfully.',
    de: 'Die Daten wurden erfolgreich entfernt.',
    nl: '',
    it: ''
  },
  ADDED_RECIPIENT_INTO_GROUP: {
    en: 'Added recipient into group',
    de: 'Update des Empfängers erfolgreich',
    it: 'Aggiunta di un destinatario al gruppo',
    nl: 'Ontvanger toegevoegd aan groep',
  },
  EDIT_ORDER_DATA: {
    "en": "Edit order data",
    "de": "Bestelldaten bearbeiten",
    "it": "Modifica dei dati dell'ordine",
    "nl": "Wijzig ordergegevens"
  },
  MOVE_RECIPIENT: {
    en: 'Move Recipient',
    de: 'Empfänger verschieben',
    it: 'Spostare il destinatario',
    nl: 'Verplaats Ontvanger'
  },
  TARGET_RECIPIENT_GROUP: {
    en: 'Target Recipient Group',
    de: 'Ziel-Empfängergruppe',
    it: 'Gruppo di destinatari',
    nl: 'Doelgroep van ontvangers'
  },
  NUMBER_OF_RECIPIENT_ADDED: {
    en: 'Number of recipients added into target group',
    de: 'Anzahl der in die Zielgruppe aufgenommenen Empfänger',
    it: 'Numero di destinatari aggiunti al gruppo target',
    nl: 'Aantal begunstigden die aan de doelgroep zijn toegevoegd'
  },
  NUMBER_OF_RECIPIENT_ALREADY_IN_TARGET_GROUP: {
    en: 'Number of recipients already in target group',
    de: 'Anzahl der Empfänger, die bereits zur Zielgruppe gehören',
    it: 'Numero di destinatari già presenti nel gruppo target',
    nl: 'Aantal begunstigden die reeds tot de doelgroep behoren'
  },
  NUMBER_OF_RECIPIENT_REMOVED: {
    en: 'Number of recipients removed from existing group',
    de: 'Anzahl der aus der bestehenden Gruppe entfernten Empfänger',
    it: 'Numero di destinatari rimossi dal gruppo esistente',
    nl: 'Aantal ontvangers verwijderd uit bestaande groep'
  },
  RECEIVER_MOVE_SUCCESS: {
    en: 'All receivers successfully moved to target recipient group',
    de: 'Alle Empfänger wurden erfolgreich in die Ziel-Empfängergruppe verschoben',
    it: 'Tutti i destinatari sono stati spostati con successo nel gruppo di destinatari target',
    nl: 'Alle ontvangers zijn met succes overgeplaatst naar de beoogde ontvangersgroep'
  },
  REMOVE_EXISTING_RECIPIENT_GROUP: {
    en: 'Remove existing recipient group',
    de: 'Vorhandene Empfängergruppe entfernen',
    it: 'Rimuovere il gruppo di destinatari esistente',
    nl: 'Bestaande ontvangersgroep verwijderen'
  },
  ARE_YOU_SURE_DELETE_RECIPIENT_GROUP: {
    en: 'Are you sure you want to delete this recipient group?',
    de: 'Sind Sie sicher, dass Sie diese Empfängergruppe löschen wollen?',
    it: 'Siete sicuri di voler eliminare questo gruppo di destinatari?',
    nl: 'Weet u zeker dat u deze ontvangersgroep wilt verwijderen?'
  },
  MOVE_RECIPIENT_BEFORE_DELETE: {
    "en": "Do you really want to delete the recipient group? All recipients will remain in the system with their respective status (active, inactive). Please select a group to which the recipients should be moved.",
    "de": "Möchten Sie die Empfängergruppe wirklich löschen? Alle Empfänger bleiben mit dem jeweiligen Status (aktiv, inaktiv) im System bestehen. Bitte wählen Sie eine Gruppe aus in die die Empfänger verschoben werden sollen.",
    "it": "Vuoi davvero eliminare il gruppo di destinatari? Tutti i destinatari rimarranno nel sistema con il rispettivo stato (attivo, inattivo). Seleziona un gruppo in cui spostare i destinatari.",
    "nl": "Wilt u de ontvangergroep echt verwijderen? Alle ontvangers blijven in het systeem met hun respectieve status (actief, inactief). Selecteer een groep waarnaar de ontvangers moeten worden verplaatst."
  },
  REMOVE_RECIPIENT_FROM_GROUP_CONFIRMATION: {
    en: 'The email address is not deleted and keeps its status (active, inactive, DOI). The address will only be removed from this group. Do you want to remove the email address from the group?',
    de: 'Die Email Adresse wird nicht gelöscht und behält ihren Status (aktiv, inaktiv, DOI). Die Adresse wird lediglich aus dieser Gruppe entfernt. Möchten Sie die Email Adresse aus der Gruppe entfernen?',
    it: `L'indirizzo e-mail non viene eliminato e mantiene il suo stato (attivo, inattivo, DOI). L'indirizzo sarà rimosso solo da questo gruppo. Si desidera rimuovere l'indirizzo e-mail dal gruppo?`,
    nl: 'Het e-mailadres wordt niet verwijderd en behoudt zijn status (actief, inactief, DOI). Het adres wordt alleen uit deze groep verwijderd. Wilt u het e-mailadres uit de groep verwijderen?'
  },
  FORM_IS_SUCCESSFULLY_SUBMITTED: {
    en: 'Form is successfully submitted',
    de: 'Das Formular wurde erfolgreich übermittelt',
    it: 'Il modulo è stato inviato con successo',
    nl: 'Formulier is succesvol ingediend'
  },
  LOCAL_LISTINGS_REPORT: {
    en: 'Local Listings Report',
    de: 'Bericht zu den lokalen Einträgen',
    it: 'Rapporto sugli elenchi locali',
    nl: 'Rapport lokale vermeldingen'
  },
  VIEW_REPORTS_FROM_LOCAL_LISTINGS: {
    en: 'View reports from Local Listings.',
    de: 'Berichte von Local Listings anzeigen.',
    it: 'Visualizzare i rapporti delle Inserzioni locali.',
    nl: 'Bekijk rapporten van Lokale Advertenties.'
  },
  HINT_TEXT: {
    en: 'Hint text',
    de: 'Hinweistext',
    it: 'Testo del suggerimento',
    nl: 'Hint tekst'
  },
  IS_COPIED: {
    en: 'is copied',
    de: 'wird kopiert',
    it: 'viene copiato',
    nl: 'wordt gekopieerd'
  },
  COMPANY_NAME: {
    en: 'Company name',
    de: 'Firmenname',
    it: 'Nome della società',
    nl: 'Bedrijfsnaam'
  },
  TOWN: {
    en: 'Town',
    de: 'Ort',
    it: 'Città',
    nl: 'Stad'
  },
  APPLY_VALUE: {
    en: 'Apply values',
    de: 'Werte anwenden',
    it: 'Applicare i valori',
    nl: 'Waarden toepassen'
  },
  VALUES_APPLIED: {
    en: 'Values applied',
    de: 'Angewandte Werte',
    it: 'Valori applicati',
    nl: 'Toegepaste waarden'
  },
  OLS_SETTINGS_CANT_BE_SAVED: {
    en: 'OLS settings cannot be saved, please check all the required fields',
    de: 'OLS-Einstellungen können nicht gespeichert werden, bitte überprüfen Sie alle erforderlichen Felder',
    it: 'Le impostazioni di OLS non possono essere salvate, controllare tutti i campi richiesti.',
    nl: 'OLS instellingen kunnen niet worden opgeslagen, controleer alle verplichte velden'
  },
  OLS_SETTINGS_IS_CREATED_SUCCESSFULLY: {
    en: 'OLS settings is created successfully',
    de: 'OLS-Einstellungen wurden erfolgreich erstellt',
    it: 'Le impostazioni OLS sono state create con successo',
    nl: 'OLS instellingen zijn succesvol aangemaakt'
  },
  CONFIRM_CLOSE_DOF: {
    en: 'This closes the order form. Changes and entries will be lost. Do you really want to close?',
    de: 'Hiermit schließen Sie den Bestellschein. Änderungen und Eingaben gehen verloren. Möchten Sie wirklich schließen?',
    it: `Questo chiude il modulo d'ordine. Le modifiche e gli inserimenti andranno persi. Vuoi davvero chiudere?`,
    nl: 'Hiermee wordt het bestelformulier afgesloten. Wijzigingen en invoer gaan verloren. Wil je echt sluiten?'
  },
  MEDIA_OVERVIEW: {
    en: 'Media overview',
    de: 'Übersicht der Medien',
    it: 'Panoramica dei media',
    nl: 'Overzicht van de media'
  },
  NEXT_MEDIUM: {
    en: 'Next medium',
    de: 'Nächstes Medium',
    it: 'Prossimo mezzo',
    nl: 'Volgende medium'
  },
  TO_THE_SUMMARY: {
    en: 'To the summary',
    de: 'Zur Zusammenfassung',
    it: 'Per riassumere',
    nl: 'Samengevat'
  },
  MEDIUM_ORDERED: {
    en: 'Medium ordered',
    de: 'Medium bestellt',
    it: 'Media ordinata',
    nl: 'Medium besteld'
  },
  MEDIUM_NOT_ORDERED: {
    en: 'Medium not ordered',
    de: 'Medium nicht bestellt',
    it: 'Media non ordinata',
    nl: 'Medium niet besteld'
  },
  INFO_INCOMPLETE_PLEASE_CORRECT: {
    en: 'Information incomplete, please correct',
    de: 'Informationen unvollständig, bitte korrigieren',
    it: 'Informazioni incomplete, correggere',
    nl: 'Informatie onvolledig, gelieve te corrigeren'
  },
  CATEGORY: {
    en: 'Category',
    de: 'Kategorie',
    it: 'Categoria',
    nl: 'Categorie'
  },
  CHECK_FB_ADVERTISING_ACCOUNT_ID: {
    en: 'Check your Facebook advertising account ID:',
    de: 'Hier klicken um Facebook-Werbekonto-ID  an zu zeigen:',
    it: `Controllare l'ID dell'account pubblicitario di Facebook:`,
    nl: 'Controleer uw Facebook-reclameaccount-ID:'
  },
  SUPPORTED_FILE_TYPES: {
    en: 'Supported file types',
    de: 'Unterstützter Dateityp',
    it: 'Tipo di file supportato',
    nl: 'Ondersteund bestandstype'
  },
  PR_NUMBER: {
    en: "PR Number",
    de: 'PR-Nummer',
    it: "Numero PR",
    nl: "PR Nummer"
  },
  APP_ADMIN_PORTAL_FITLINX: {
    en: 'Admin Portal (FITLINX)',
    de: 'Verwaltungsportal (FITLINX)',
    it: 'APP (Portale amministrativo) (FITLINX)',
    nl: 'APP (Admin portaal) (FITLINX)'
  },
  APP_ADMIN_PORTAL_INJOY: {
    en: 'Admin Portal (INJOY)',
    de: 'Verwaltungsportal (INJOY)',
    it: 'APP (Portale amministrativo) (INJOY)',
    nl: 'APP (Admin portaal) (INJOY)'
  },
  APP_ADMIN_PORTAL_ELEMENTS: {
    en: 'Admin Portal (ELEMENTS)',
    de: 'Verwaltungsportal (ELEMENTS)',
    it: 'APP (Portale amministrativo) (ELEMENTS)',
    nl: 'APP (Admin portaal) (ELEMENTS)'
  },
  INJOY_360: {
    en: 'INJOY 360',
    de: 'INJOY 360',
    it: 'INJOY 360',
    nl: 'INJOY 360'
  },
  INJOY360_OPENING_HOUR:{
    en: 'INJOY 360 Opening Hour',
    de: 'INJOY 360 Öffnungszeiten',
    it: 'INJOY 360 Orario di apertura',
    nl: 'INJOY 360 Openingsuur'
  },
  OPENING_HOUR_ERROR: {
    en: 'Opening hour cannot be empty',
    de: 'Öffnungszeiten kann nicht leer sein',
    it: `Orario di apertura non può essere vuota`,
    nl: 'Openingsuur mag niet leeg zijn'
  },
  INJOY360_OPENING_HOUR_CREATED_SUCCESSFULLY:{
    en: 'INJOY 360 Opening Hour Created Successful',
    de: 'INJOY 360 Öffnungszeiten erfolgreich gestaltet',
    it: 'INJOY 360 L\'ora di apertura è stata un successo',
    nl: 'INJOY 360 Succesvol openingsuur'
  },
  INJOY360_OPENING_HOUR_UPDATED_SUCCESSFULLY:{
    en: 'INJOY 360 Opening Hour Updated Successful',
    de: 'INJOY 360 Aktualisierte Öffnungszeiten Erfolgreich',
    it: 'INJOY 360 Orario di apertura aggiornato con successo',
    nl: 'INJOY 360 Openingstijd bijgewerkt Succesvol'
  },
  INJOY360_OPENING_HOUR_HEADER: {
    en: 'Please key in opening hour below',
    de: 'Bitte geben Sie die Öffnungszeiten unten ein',
    it: `Digitare l'ora di apertura qui sotto`,
    nl: 'Vul hieronder het openingsuur in'
  },
  REMAINING_CHARACTERS: {
    en: "Remaining Characters:",
    de: "Verbleibende Zeichen:",
    it: "Personaggi rimanenti:",
    nl: "Overblijvende tekens:"
  },
  EMAILS_ADDED_TO_SELECTED_RECIPIENT_GROUPS: {
    en: "Email(s) added to selected recipient group(s):",
    de: "E-Mail(s) zur ausgewählten Empfängergruppe hinzugefügt:",
    it: "Email aggiunte al gruppo di destinatari selezionati:",
    nl: "E-mail(s) toegevoegd aan de geselecteerde ontvangersgroep(en):"
  },
  OVERVIEW_SITE_VISITS: {
    "en": "Overview site visits",
    "de": "Übersicht",
    "it": "Panoramica delle visite al sito",
    "nl": "Overzicht van sitebezoeken"
  },
  SITES: {
    "en": "Sites",
    "de": "Seiten",
    "it": "Siti",
    "nl": "Websites"
  },
  TRANSITIONS: {
    "en": "Transitions",
    "de": "Nutzerverhalten",
    "it": "Transizioni",
    "nl": "Overgangen"
  },
  VISITOR_LOG: {
    "en": "Visitor log",
    "de": "Besucherprotokoll",
    "it": "Registro dei visitatori",
    "nl": "Bezoekerslogboek"
  },
  TIME_ON_SITE: {
    "en": "Time on site",
    "de": "Verweildauer auf der Website",
    "it": "Tempo sul sito",
    "nl": "Tijd op de site"
  },
  MORE: {
    "en": "More",
    "de": "Mehr",
    "it": "Di più",
    "nl": "Meer"
  },
  SITE_URL: {
    "en": "Site URL",
    "de": "Website-URL",
    "it": "URL del sito",
    "nl": "Website-URL"
  },
  VISITS: {
    "en": "Visits",
    "de": "Besuche",
    "it": "Visite",
    "nl": "Bezoeken"
  },
  AVERAGE_TIME_ON_SITE: {
    "en": "Average time on site",
    "de": "Durchschnittliche Verweildauer auf der Website",
    "it": "Tempo medio sul sito",
    "nl": "Gemiddelde tijd op de site"
  },
  SITE_LOAD_TIME: {
    "en": "Site load time",
    "de": "Durchschnittliche Ladezeit der Website",
    "it": "Tempo di caricamento del sito",
    "nl": "Laadtijd van de site"
  },
  INCOMING_TRAFFIC: {
    "en": "Incoming traffic",
    "de": "Eingehender Datenverkehr",
    "it": "Traffico in ingresso",
    "nl": "Inkomend verkeer"
  },
  OUTGOING_TRAFFIC: {
    "en": "Outgoing traffic",
    "de": "Ausgehender Datenverkehr",
    "it": "Traffico in uscita",
    "nl": "Uitgaand verkeer"
  },
  SITE_VISITS: {
    "en": "Site visits",
    "de": "Besuche auf der Website",
    "it": "Visite al sito",
    "nl": "Websitebezoeken"
  },
  FROM_INTERNAL_SITES: {
    "en": "From internal sites",
    "de": "Von internen Seiten",
    "it": "Da siti interni",
    "nl": "Van interne sites"
  },
  TO_INTERNAL_SITES: {
    "en": "To internal sites",
    "de": "Zu internen Seiten",
    "it": "Verso siti interni",
    "nl": "Naar interne sites"
  },
  OUTLINKS: {
    "en": "Outlinks",
    "de": "Ausgehende Links",
    "it": "Link esterni",
    "nl": "Uitgaande links"
  },
  EXITS: {
    "en": "Exits",
    "de": "Ausstiege",
    "it": "Uscite",
    "nl": "Uitgangen"
  },
  INTERNAL_SEARCHES: {
    "en": "Internal searches",
    "de": "Interne Suchen",
    "it": "Ricerche interne",
    "nl": "Interne zoekopdrachten"
  },
  DOWNLOADS: {
    "en": "Downloads",
    "de": "Downloads",
    "it": "Download",
    "nl": "Downloads"
  },
  VISITS_IN_REAL_TIME: {
    "en": "Visits in real-time",
    "de": "Besuche in Echtzeit",
    "it": "Visite in tempo reale",
    "nl": "Bezoeken in realtime"
  },
  LAST_30_MINUTES: {
    "en": "Last 30 minutes",
    "de": "Letzten 30 Minuten",
    "it": "Ultimi 30 minuti",
    "nl": "Laatste 30 minuten"
  },
  LAST_24_HOURS: {
    "en": "Last 24 hours",
    "de": "Letzten 24 Stunden",
    "it": "Ultime 24 ore",
    "nl": "Laatste 24 uur"
  },
  BROWSER: {
    en: "Browser",
    de: "Browser",
    it: "Browser",
    nl: "Browser"
  },
  BROWSER_ENGINE: {
    en: "Browser engine",
    de: "Browser Engine",
    it: "Motore di navigazione",
    nl: "Browser-engine"
  },
  PLUGINS: {
    en: "Plugins",
    de: "Plug-ins",
    it: "Plugin",
    nl: "Plug-ins"
  },
  OPERATING_SYSTEM: {
    en: "Operating system",
    de: "Betriebssystem",
    it: "Sistema operativo",
    nl: "Besturingssysteem"
  },
  DEVICE_TYPE: {
    en: "Device type",
    de: "Gerätetyp",
    it: "Tipo di dispositivo",
    nl: "Apparaattype"
  },
  DEVICE_BRAND: {
    en: "Device brand",
    de: "Gerätemarke",
    it: "Marca del dispositivo",
    nl: "Apparaatmerk"
  },
  DEVICE_MODEL: {
    en: "Device model",
    de: "Gerätemodell",
    it: "Modello del dispositivo",
    nl: "Apparaatmodel"
  },
  RESOLUTION: {
    en: "Resolution",
    de: "Auflösung",
    it: "Risoluzione",
    nl: "Resolutie"
  },
  PREVIEW: {
    en: 'Preview',
    de: 'Vorschau',
    it: 'Anteprima',
    nl: 'Preview',
  },
  GENERAL_INFO: {
    en: `Info`,
    de: 'Info',
    it: 'Info',
    nl: 'Info'
  },
  BUY_NOW: {
    en: "Buy now",
    de: 'Jetzt kaufen',
    it: 'Acquista ora',
    nl: 'Nu kopen'
  },
  BOOK_NOW: {
    en: "Book now",
    de: 'Jetzt buchen',
    it: 'Jetzt buchen',
    nl: 'Boek nu'
  },
  IS_REQUIRED: {
    en: "is required",
    de: "ist erforderlich",
    it: "è richiesto",
    nl: "is vereist"
  },
  FIELD: {
    en: "Field",
    de: "Feld",
    it: "Campo",
    nl: "Veld"
  },
  MAXIMUM_FILE_SIZE_10_MB: {
    en: 'Maximum file size: 10MB',
    de: 'Maximale Dateigröße: 10MB',
    it: 'Dimensione massima del file: 10MB',
    nl: 'Maximale bestandsgrootte: 10MB'
  },
  ACTIVE_RECIPIENT_IS_DELETED_SUCCESSFULLY: {
    en: "Active recipient is deleted successfully.",
    de: "Aktiver Empfänger wurde erfolgreich gelöscht.",
    it: "Il destinatario attivo è stato eliminato con successo.",
    nl: "Actieve ontvanger is succesvol verwijderd."
  },
  TIME_TO_SEND_THE_MAIL: {
    en: "Time to send the mail",
    de: "Zeitpunkt zum Senden der E-Mail",
    it: "Tempo per inviare la posta",
    nl: "Tijd om de e-mail te verzenden"
  },
  DAYS_SENDING_THE_FIRST_MAIL_IF_0_SEND_IMMEDIATELY: {
    en: "Days(Sending the first mail, if 0, send immediately)",
    de: "Tage (Senden der ersten E-Mail, wenn 0, sofort senden)",
    it: "Giorni (Invio del primo messaggio, se 0, invia immediatamente)",
    nl: "Dagen (Eerste e-mail verzenden, indien 0, onmiddellijk verzenden)"
  }
}

export default Lang
