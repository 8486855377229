import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Card, CardBody,
  Form, FormGroup,
  Container, Row, Col,
  Button, Label, Input, Collapse,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'
import ReactSelect from 'react-select'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import WithBulkUpload from './actions'
import WithRecipientGroup from './actions/recipientGroup'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import 'filepond/dist/filepond.min.css'

import Lang from 'Lang/General'

registerPlugin( FilePondPluginFileValidateType )

class BulkUploadRecipient extends Component {
  state = {
    subscribedPackage: true,
    sampleFiles: [
      {
        url: 'https://ark-assets.s3.eu-central-1.amazonaws.com/recipients-template/en.xlsx',
        language: 'en'
      },
      {
        url: 'https://ark-assets.s3.eu-central-1.amazonaws.com/recipients-template/de.xlsx',
        language: 'de'
      },
      {
        url: 'https://ark-assets.s3.eu-central-1.amazonaws.com/recipients-template/nl.xlsx',
        language: 'nl'
      },
      {
        url: 'https://ark-assets.s3.eu-central-1.amazonaws.com/recipients-template/it.xlsx',
        language: 'it'
      }
    ],
    detailsOpen: false
  }

  componentDidMount = () => {
    this.props.getRecipientGroup( convertSearchObjectToBase64({ is_paginated: false }))
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Newsletters' })
    this.setState({ subscribedPackage: tmpIndex > -1 })
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_create && this.state.subscribedPackage
    }
  }

  renderSummary = () => {
    return(
      <Modal
        isOpen={ this.props.openSummaryModal }
        size={'xl'}
        toggle={ () => this.props.onChangeBulkHOC('openSummaryModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeBulkHOC('openSummaryModal', false ) }>{ Lang['SUMMARY'][this.props.data.languageReducer.userSettings.admin_panel_language] }</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <p style={{ textAlign: 'justify' }} >{ this.props.summary.msg }</p>
              <Button 
                color="link"
                className='p-0 text-success' 
                onClick={ () => this.setState({ detailsOpen: !this.state.detailsOpen })}>
                { Lang['DETAILS'][this.props.data.languageReducer.userSettings.admin_panel_language] }
              </Button>
              <Collapse isOpen={ this.state.detailsOpen } style={{ marginTop: '20px' }}>
                <Card>
                  <CardBody>
                    {
                      this.props.summary.total_emails_created > 0 && (
                        <>
                          <p>{ Lang['EMAIL_CREATED'][this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                          <ul>
                            {
                              this.props.summary.accepted.created_emails.map( item => {
                                return(
                                  <li>{ item }</li>
                                )
                              })
                            }
                          </ul>
                        </>
                      )
                    }
                    {
                      this.props.summary.assigned.length > 0 && (
                        <>
                          <p>{ Lang['EMAILS_ADDED_TO_SELECTED_RECIPIENT_GROUPS'][this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                          <ul>
                            {
                              this.props.summary.assigned.map( item => {
                                return(
                                  <li>{ item }</li>
                                )
                              })
                            }
                          </ul>
                        </>
                      )
                    }
                    {
                      this.props.summary.total_emails_rejected > 0 && (
                        <>
                          <p>{`${ Lang['TOTAL_REJECTED'][this.props.data.languageReducer.userSettings.admin_panel_language]}: ${ this.props.summary.total_emails_rejected }` }</p>
                          <p>{ Lang['EMAIL_ACTIVE'][this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                          <ul>
                            {
                              this.props.summary.rejected.active_emails.map( item => {
                                return(
                                  <li>{ item }</li>
                                )
                              })
                            }
                          </ul>
                          <p>{ Lang['EMAIL_INACTIVE'][this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                          <ul>
                            {
                              this.props.summary.rejected.inactive_emails.map( item => {
                                return(
                                  <li>{ item }</li>
                                )
                              })
                            }
                          </ul>
                          <p>{ Lang['EMAIL_BLACKLISTED'][this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                          <ul>
                            {
                              this.props.summary.rejected.blacklisted_emails.map( item => {
                                return(
                                  <li>{ item }</li>
                                )
                              })
                            }
                          </ul>
                          <p>{ Lang['EMAIL_DOI'][this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                          <ul>
                            {
                              this.props.summary.rejected.doi_emails.map( item => {
                                return(
                                  <li>{ item }</li>
                                )
                              })
                            }
                          </ul>
                          <p>{ Lang['EMAIL_DUPLICATED'][this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                          <ul>
                            {
                              this.props.summary.rejected.duplicated_emails.map( item => {
                                return(
                                  <li>{ item }</li>
                                )
                              })
                            }
                          </ul>
                        </>
                      )
                    }
                  </CardBody>
                </Card>
              </Collapse>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={ () => this.props.onChangeBulkHOC('openSummaryModal', false ) } color="primary">{ Lang['CLOSE'][this.props.data.languageReducer.userSettings.admin_panel_language] }</Button>
        </ModalFooter>
      </Modal>
    )
  }
  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang['BULK_UPLOAD_RECIPIENT'][this.props.data.languageReducer.userSettings.admin_panel_language] }
          icon="pe-7s-cloud-upload icon-gradient bg-happy-itmeo"
          actionsButtons={[]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            <Row>
              <Col md={ 12 }>
                <Card body className="card-shadow-primary mb-3">
                  {
                    !_.isEmpty( _.find( this.state.sampleFiles, { language: this.props.data.languageReducer.userSettings.admin_panel_language } ) ) && (
                      <p>{ Lang['CLICK'][this.props.data.languageReducer.userSettings.admin_panel_language] }<a href={ _.find( this.state.sampleFiles, { language: this.props.data.languageReducer.userSettings.admin_panel_language } ).url } className="alert-link">{ Lang['HERE'][this.props.data.languageReducer.userSettings.admin_panel_language] }</a>{ Lang['DOWNLOAD_SAMPLE'][this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                    )
                  }
                  <Form onSubmit={ e => e.preventDefault() }>
                    <FormGroup className="mt-4">
                      <Label>{ Lang['CHOOSE_FILE'][this.props.data.languageReducer.userSettings.admin_panel_language] }</Label>
                      {
                        this.props.bulkData.raw !== '' && <span style={{ color: 'green', marginLeft: '15px' }}>{ Lang['UPLOAD_COMPLETED'][this.props.data.languageReducer.userSettings.admin_panel_language]}</span>
                      }
                      <FilePond
                        files={ this.props.files }
                        acceptedFileTypes={ ['text/csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] }
                        allowFileTypeValidation={ true }
                        onupdatefiles={ fileItems => {
                          if( fileItems.length > 0 ) {
                            let reader = new FileReader()
                            reader.onload = e => {
                              let tmp = _.cloneDeep( this.props.bulkData )
                              let index = reader.result.indexOf(',')
                              let tmpBase64 = reader.result.substring( index + 1 )
                              tmp['raw'] = tmpBase64
                              tmp['type'] = fileItems[0].fileExtension
                              Promise.all([
                                this.props.onChangeBulkHOC( 'files', fileItems )
                              ]).then(
                                this.props.onChangeBulkHOC( 'bulkData', tmp )
                              )
                            }
                            reader.readAsDataURL( fileItems[0].file )
                          }
                        }}
                        allowMultiple={false}
                      />
                    </FormGroup>
                    <FormGroup className="mt-4">
                      <Label>{ Lang[ 'RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <ReactSelect
                        options={ this.props.recipientGroups }
                        value={ this.props.bulkData.recipient_groups }
                        isMulti
                        onChange={ value => {
                          let tmp = _.cloneDeep( this.props.bulkData )
                          tmp['recipient_groups'] = value
                          this.props.onChangeBulkHOC( 'bulkData', tmp )
                        }}/>
                    </FormGroup>
                    <FormGroup className="mt-4">
                      <Label>{ Lang['STATUS'][this.props.data.languageReducer.userSettings.admin_panel_language] }</Label>
                      <Input
                        type="select"
                        value={ this.props.bulkData.status }
                        onChange={ e => {
                          let tmp = _.cloneDeep( this.props.bulkData )
                          tmp['status'] = e.target.value
                          this.props.onChangeBulkHOC( 'bulkData', tmp )
                        }}>
                        <option value="ACTIVE">{ Lang[ 'ACTIVE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</option>
                        <option value="INACTIVE">{ Lang[ 'INACTIVE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</option>
                        <option value="DOI">{ Lang[ 'DOI_FULL' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</option>
                      </Input>
                    </FormGroup>
                  </Form>
                  <Button 
                    className="mt-3" 
                    onClick={ () => this.props.createBulkRecipient() } 
                    disabled={ !this.handleSecurityModulesCreate() }
                    color="primary">
                    { Lang['SUBMIT'][this.props.data.languageReducer.userSettings.admin_panel_language] }
                  </Button>
                </Card>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
        {
          !_.isEmpty( this.props.summary ) && this.props.openSummaryModal && this.renderSummary()
        }
        {
          ( this.props.onLoadRecipientGroup || this.props.onLoadBulkUpload ) && <LoadingOverlay />
        }
      </>
    )
  }
}

export default compose(
  WithBulkUpload,
  WithRecipientGroup
)( BulkUploadRecipient )
