import React, { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from 'reactstrap'
import _ from 'lodash'
import { compose } from 'redux'
import Moment from 'moment'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel'

import LoadingIndicator from 'components/Indicator/LoadingOverlay'
import LatestCampaignsHOC from './actions'
import ProductRequestHOC from 'containers/Dashboard/OLS/actions'
import ViewAllCampaigns from './components/ViewAll'
import ViewInfo from './components/ViewInfo'
import LandingPage from './components/LandingPage'
import ChangeBudget from './components/ChangeBudget'
import BookCampaign from './components/BookCampaign'
import ProductInfo from './components/ProductInfo'
import defaultLogo from 'assets/images/logo-aciso.png'

import 'pure-react-carousel/dist/react-carousel.es.css'
import Lang from 'Lang/General'

const LatestCampaigns = props => {

  useEffect( () => {
    props.getAllProductRequests()
    props.getProducts()
    props.getRequestedProduct()
    props.getOLSCampaignSettings( false )
  }, [] )

  useEffect( () => {
    if( props.data.selectCenterReducer.selectedCenter.center?.id > 0 ){
      props.onChangeLatestCampaignHOC( 'campaignsCenterId', props.data.selectCenterReducer.selectedCenter.center.id ) 
    }
  }, [ props.data.selectCenterReducer.selectedCenter ] )

  const renderAlertModal = () => {
    let tmpProduct = _.find( props.products, product => product.name.en === 'OLS' )
    return (
      <Modal
        isOpen={ props.showAlertModal }
        size={ 'md' }>
        <ModalHeader toggle={ () => props.onChangeLatestCampaignHOC( 'showAlertModal', false ) }>{ Lang[ 'ALERT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
        <ModalBody>
          <Alert color="info">
            { Lang[ 'BANNER_MESSAGE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
            {
              tmpProduct && (
                <Button 
                  style={{
                    border: 'none',
                    boxShadow: 'none'
                  }}
                  color="link"
                  onClick={ () => {
                    props.onChangeProductRequestsHOC( 'showRequestInfoModal', true )
                    props.getSelectedProduct( tmpProduct.id )
                  }}>{ Lang[ 'CLICK_HERE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
              )
            }
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={ () => props.onChangeLatestCampaignHOC( 'showAlertModal', false ) }>{ Lang[ 'CLOSE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
        </ModalFooter>
        { props.onLoadLatestCampaign && <LoadingIndicator/> }
      </Modal>
    )
  }
  
  return(
    <>
      <div className="carousel-container">
        <div className="custom-card mb-2" style={{ height: '67px', flex: "0" }}>
          <span className="main-section-title">{ Lang[ 'LATEST_OLS_CAMPAIGNS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</span>
        </div>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={110}
          isIntrinsicHeight={ true }
          totalSlides={ props.latestCampaigns ? Math.min( props.latestCampaigns.length, 5 ): 0 }>
          <Slider>
            {
              props.latestCampaigns.length > 0 && props.latestCampaigns.map( ( campaign, index ) => {
                if( index < 5 ) {
                  return(
                    <Slide index={ index } key={ index }>
                      <div className="custom-card">
                        <div className="custom-card-img-container" >
                          <img
                            className="custom-card-img"
                            src={ campaign.teaser_image_url || defaultLogo } 
                            alt="" 
                          />
                          <div className="custom-card-floating-bar">
                            {
                              campaign.is_app_campaign && (
                                <div className="tag">{ Lang[ 'APP_CAMPAIGN' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</div>
                              )
                            }
                            {
                              campaign.is_new && (
                                <div className="tag">{ Lang[ 'NEW_CAMPAIGN' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</div>
                              )
                            }
                          </div>
                        </div>
                        <div className="custom-card-body">
                          <div>
                            <span className="custom-card-title">{ campaign.title }</span>
                            <div className="mt-2">
                              <div>{ `${ Lang[ 'START' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ campaign.start_date ? Moment( campaign.start_date ).format( 'MMMM Do, YYYY' ) : '-' }` }</div>
                              <div>{ `${ Lang[ 'END' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: ${ campaign.end_date ? Moment( campaign.end_date ).format( 'MMMM Do, YYYY' ) : '-' }` }</div>
                              <div>{ `${ Lang[ 'FACEBOOK_BUDGET' ][ props.data.languageReducer.userSettings.admin_panel_language ] }: EUR ${ Number( campaign.fa_budget ).toFixed( 2 ) }` }</div>
                            </div>
                          </div>
                        </div>
                        <div className="custom-card-footer">
                          {
                            campaign.is_booked && !campaign.is_app_campaign ? (
                              <button 
                                className="outline-btn--secondary"
                                onClick={ () => {
                                  props.onChangeLatestCampaignHOC( 'showChangeModal', true )
                                  props.getSelectedBookedCampaign( campaign.booked_campaign_id, )
                                }}>{ Lang[ 'CHANGE_BUDGET' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</button>
                            ) : (
                              <button 
                                className="outline-btn--secondary"
                                onClick={ () => {
                                  props.onChangeLatestCampaignHOC( 'showLandingPageModal', true )
                                  props.getSelectedCampaign( campaign.id )
                                }}>{ Lang[ 'PREVIEW' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</button>
                            )
                          }
                          <button
                            className="outline-btn--secondary"
                            onClick={ () => {
                              props.onChangeLatestCampaignHOC( 'showViewInfoModal', true )
                              props.getSelectedCampaign( campaign.id )
                            }}>{ Lang[ 'GENERAL_INFO' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                          </button>
                          <button  
                            className="btn pill-shaped"
                            onClick={ () => {
                              props.data.selectCenterReducer.selectedCenter.center && _.findIndex( props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'OLS' } ) > -1 
                                ? props.onChangeLatestCampaignHOC( 'showBookModal', true ) 
                                : props.onChangeLatestCampaignHOC( 'showAlertModal', true )
                              props.getSelectedCampaign( campaign.id )
                            }}>{ Lang[ 'BOOK_NOW' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</button>
                        </div>
                      </div>
                    </Slide>
                  )
                }
              })
            }
          </Slider>
          <div className="carousel-button-group">
            <ButtonBack className="transparent-btn">
              <i className="pe-7s-angle-left" />
            </ButtonBack>
            <div className="carousel-dot-list">
            {
              props.latestCampaigns.map( (item, index) => {
                if ( index < 5 ) {
                  return(
                    <Dot slide={ index } className=""/>
                  )
                }
              })
            }
            </div>
            <ButtonNext className="transparent-btn">
              <i className="pe-7s-angle-right" />
            </ButtonNext>
          </div>
        </CarouselProvider>
        <div className="d-flex justify-content-center">
          <button
            className="transparent-btn"
            style={{
              color: 'initial',
              textDecoration: 'underline',
              margin: 'auto'
            }}
            onClick={ () => {
              window.location.href = `${ window.location.origin }/#/dashboard/ols/listings`
            }}>
            { Lang[ 'CAMPAIGN' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
          </button>
        </div>
      </div>
      <ViewAllCampaigns
        allCampaigns={ props.allCampaigns }
        showAll={ props.showAll }
        onChangeLatestCampaignHOC={ props.onChangeLatestCampaignHOC }
        lang={ props.lang } />
      <ViewInfo
        selectedCampaign={ props.selectedCampaign }
        showViewInfoModal={ props.showViewInfoModal }
        onChangeLatestCampaignHOC={ props.onChangeLatestCampaignHOC }
        lang={ props.data.languageReducer.userSettings.admin_panel_language } />
      <ChangeBudget
        isOneTimer={ props.data.roleReducer.role.name === 'OLS One Timer' }
        selectedBookedCampaign={ props.selectedBookedCampaign }
        onChangeLatestCampaignHOC={ props.onChangeLatestCampaignHOC }
        showChangeModal={ props.showChangeModal }
        updateBudget={ props.updateBudget }
        lang={ props.data.languageReducer.userSettings.admin_panel_language }
        onLoadLatestCampaign={ props.onLoadLatestCampaign } />
      <LandingPage
        selectedCampaign={ props.selectedCampaign }
        showLandingPageModal={ props.showLandingPageModal }
        onChangeLatestCampaignHOC={ props.onChangeLatestCampaignHOC }
        lang={ props.data.languageReducer.userSettings.admin_panel_language } />
      <BookCampaign
        { ...props }
        isOneTimer={ props.data.roleReducer.role.name === 'OLS One Timer' }
        lang={ props.data.languageReducer.userSettings.admin_panel_language } />
      <ProductInfo 
        data={ props.data }
        selectedProduct={ props.selectedProduct }
        showRequestInfoModal={ props.showRequestInfoModal }
        onChangeProductRequestsHOC={ props.onChangeProductRequestsHOC }
        createProductsRequest={ props.createProductsRequest }
        newRequest={ props.newRequest }
        onChangeLatestCampaignHOC={ props.onChangeLatestCampaignHOC }
        lang={ props.data.languageReducer.userSettings.admin_panel_language } />
      { renderAlertModal() }
      { props.onLoadLatestCampaign && <LoadingIndicator /> }
    </>
  )
}

export default compose(
  LatestCampaignsHOC,
  ProductRequestHOC
)( LatestCampaigns )
