import {
  BEGIN_AJAX_CALL,
  AJAX_CALL_ERROR,
  AJAX_CALL_SUCCESS
} from "../actions/type";

let initialState = {
  ajaxCallProgress: 0,
  ajaxErrorMessage: "",
  ajaxSuccessMessage: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BEGIN_AJAX_CALL:
      return {
        ...state,
        ajaxCallProgress: state.ajaxCallProgress + 1
      };
    case AJAX_CALL_SUCCESS:
      return {
        ...state,
        ajaxCallProgress: state.ajaxCallProgress - 1,
        ajaxSuccessMessage: action.payload,
        ajaxErrorMessage: ''
      };
    case AJAX_CALL_ERROR:
      return {
        ...state,
        ajaxCallProgress: state.ajaxCallProgress - 1,
        ajaxErrorMessage: action.payload
      };
    default:
      return state;
  }
};
