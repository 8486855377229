import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put, Delete } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

import DefaultRecipients from 'utils/defaultRecipients'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      onLoadActiveRecipient: false,
      errorMessage: {},
      step: 1,
      activeRecipientSearchParams: [
        {
          label: 'Email',
          value: 'email',
          param: ''
        },
      ],
      activeRecipients: [],
      showCreateActiveRecipient: false,
      showUpdateActiveRecipient: false,
      showDeleteActiveRecipientId: 0,
      newActiveRecipient: DefaultRecipients( 'ACTIVE' ),
      selectedActiveRecipient: DefaultRecipients( 'ACTIVE' ),
      errorMessage: {},
      showViewActiveARecipient: false
    }

    onChangeActiveRecipientHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ onLoadActiveRecipient: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error, {
      position: "bottom-right"
    })

    getAllActiveRecipient = ( query ) => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_recipients?query=${ query }`,
      this.getAllActiveRecipientSuccess,
      this.getAllActiveRecipientError,
      this.load
    )
    getAllActiveRecipientSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else{
        this.requestError( Lang['NO_RESULT_FOUND'][this.props.data.languageReducer.userSettings.admin_panel_language] )
      }

      this.setState({ activeRecipients: payload })
    }
    getAllActiveRecipientError = error => this.requestError( error )

    createActiveRecipient = data => Post(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_recipients`,
      data,
      this.createActiveRecipientSuccess,
      this.createActiveRecipientError,
      this.load
    )
    createActiveRecipientSuccess = () => {
      this.setState({ 
        showCreateActiveRecipient: false,
        newActiveRecipient: DefaultRecipients( 'ACTIVE' )
      })
      this.requestSuccess('created successfully.')
      this.getAllActiveRecipient( convertSearchObjectToBase64({ is_paginated: true, page: 1 }) )
    }
    createActiveRecipientError = error => {
      this.setState({ errorMessage: error })
    }

    getSelectedActiveRecipient = id => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_recipients/${ id }`,
      this.getSelectedActiveRecipientSuccess,
      this.getSelectedActiveRecipientError,
      this.load
    )
    getSelectedActiveRecipientSuccess = payload => {
      this.setState({ selectedActiveRecipient: payload })
    }
    getSelectedActiveRecipientError = error => this.requestError( error )

    updateActiveRecipient = ( id, data ) => Put(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_recipients/${ id }`,
      data,
      this.updateActiveRecipientSuccess,
      this.updateActiveRecipientError,
      this.load
    )
    updateActiveRecipientSuccess = () => {
      this.setState({ showUpdateActiveRecipient: false })
      this.getAllActiveRecipient( convertSearchObjectToBase64({ is_paginated: true, page: 1 }) )
      this.requestSuccess( Lang['UPDATE_ACTIVE_SUCCESS'][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
    }
    updateActiveRecipientError = error => {
      this.setState({ errorMessage: error })
    }

    deleteActiveRecipient = () => Delete(
      `/api/v1/funnel_recipients/delete/${ this.state.showDeleteActiveRecipientId }`,
      this.deleteActiveRecipientSuccess,
      this.deleteActiveRecipientError,
      this.load
    )
    deleteActiveRecipientSuccess = () => {
      this.setState({ showDeleteActiveRecipientId: 0 })
      this.getAllActiveRecipient( convertSearchObjectToBase64({ is_paginated: true, page: 1 }) )
      this.requestSuccess( Lang['ACTIVE_RECIPIENT_IS_DELETED_SUCCESSFULLY'][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
    }
    deleteActiveRecipientError = error => this.setState({ errorMessage: error })

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          { ...this.state }
          getAllActiveRecipient={ this.getAllActiveRecipient }
          onChangeActiveRecipientHOC={ this.onChangeActiveRecipientHOC }
          createActiveRecipient={ this.createActiveRecipient }
          getSelectedActiveRecipient={ this.getSelectedActiveRecipient }
          updateActiveRecipient={ this.updateActiveRecipient } 
          deleteActiveRecipient={ this.deleteActiveRecipient }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
