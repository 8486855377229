import React, { Component } from 'react'
import { connect } from 'react-redux'
import MetisMenu from 'react-metismenu'
import _ from 'lodash'

import { setEnableMobileMenu } from 'reducers/ThemeOptions'
import { MainNav } from './MainNav'
import { TePeMainNav } from './TePeMainNav'
import { storeSidemenu } from 'actions/shortcuts'
import Lang from 'Lang/General'
import TeLang from 'Lang/TePe'

class VerticalNavWrapper extends Component {
  state = {
    multilangMainMenu: [],
    multilangSubMenu: [],
    mainMenu: [],
    subMenu: [],
  }

  componentDidUpdate = ( pp ) => {
    if ( pp.data.languageReducer.userSettings.admin_panel_language  !== this.props.data.languageReducer.userSettings.admin_panel_language ) {
      this.setState({ mainMenu: this.convertMultiLangMenuToSingleLangMenu( this.state.multilangMainMenu ) }) 
    }
    if( 
      _.isEmpty( this.state.mainMenu ) &&
      !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) 
    ){
      this.initializeSideMenu()
    }
  }
    
  createShortcutMenus = data => {
    let tmp = []
    data.map( item => {
      if( item.content ) {
        const languages = Object.keys( item.label )
        item.content.map( cont => {
          let label = {}
          languages.map( ( language ) => {
            label[ language ] = `${ item.label[ language ] } - ${ cont.label[ language ] }`
          })
          tmp.push({ ...cont, label: label })
        })
      } else {
        tmp.push( item )
      }
    })
    this.props.storeSidemenu( tmp )
  }

  convertMultiLangMenuToSingleLangMenu = menu => {
    let tmpMenu = _.cloneDeep( menu )
    tmpMenu.forEach( element => {
      element.label = element.label[ this.props.data.languageReducer.userSettings.admin_panel_language ]
      element.content && element.content.forEach( navItem => {
        navItem.label = navItem.label[ this.props.data.languageReducer.userSettings.admin_panel_language ]
        navItem.content && navItem.content.forEach( item => {
          item.label = item.label[ this.props.data.languageReducer.userSettings.admin_panel_language ]
        })
      })
    })
    return tmpMenu
  }

  initializeSideMenu = () => {
    let tmpPlatform = _.find( this.props.selectedRole.platforms, { name: 'Aciso Cockpit' })
    let tmpMainMenuSec = []
    let tmpTePeMainMenuSec = []
    let tmpMainMenu = window.location.host.indexOf( 'tennis' ) > -1 ? _.cloneDeep( TePeMainNav ) : _.cloneDeep( MainNav )
    let tmpLang = window.location.host.indexOf( 'tennis' ) > -1 ? TeLang : Lang
    tmpMainMenu.forEach( element => {
      element.label = tmpLang[ element.label ]
      element.content && element.content.forEach( navItem => {
        navItem.label = tmpLang[ navItem.label ]
        navItem.content && navItem.content.forEach( item => {
          item.label = tmpLang[ item.label ]
        })
      })
    })

    tmpMainMenu.map( item => {
      if( 
        !item.secLabel ||
        ( item.secLabel && _.find( tmpPlatform.modules, { name: item.secLabel } )?.is_read ) ||
        ( item.secLabel === "INJOY 360" && _.findIndex( this.props.data.selectCenterReducer?.selectedCenter?.center?.center_groups, { name: "INJOY 360" }) > -1 )
      ) {
        if( item.content ){
          item.content = item.content.filter( submenu => {
            if ( submenu.secLabel ) {
              let tmpModule = _.find( tmpPlatform.modules, { name: submenu.secLabel } )
              if ( tmpModule && tmpModule.is_read ) {
                return submenu
              }
            } else {
              return submenu
            }
          })
        }
        window.location.host.indexOf( 'tennis' ) > -1 ? tmpTePeMainMenuSec.push( item ) : tmpMainMenuSec.push( item )
      }
    })

    if( this.props.data.selectCenterReducer.selectedCenter.center.center_groups.some( data => data[ 'name' ].indexOf( 'INJOY 360' ) > -1 ) ) {
      tmpMainMenuSec.splice(
        tmpMainMenuSec[ tmpMainMenuSec.length - 1 ].secLabel === 'GDPR Center' 
          ? tmpMainMenuSec.length - 1
          : tmpMainMenuSec.length,
        0,
        {
          icon: 'pe-7s-network',
          label: Lang[ 'INJOY_360' ],
          content: [
            {
              icon: 'pe-7s-map-marker',
              label: Lang[ 'OPENING_HOURS' ],
              to: '#/dashboard/injoy360/opening-hours'
            }
          ]
        }
      )
    }
    if( this.props.data.selectCenterReducer.selectedCenter.center.center_groups.some( data => data[ 'name' ].indexOf( 'FITLINX' ) > -1 ) ) {
      tmpMainMenuSec.push({
        "icon": "pe-7s-link",
        "label": Lang[ 'APP_ADMIN_PORTAL_FITLINX' ],
        "to": "https://fitlinx.netpulse.com/",
        "externalLink": true,
        "isDependCenterGroup": true
      })
    }
    if( this.props.data.selectCenterReducer.selectedCenter.center.center_groups.some( data => data[ 'name' ].indexOf( 'INJOY' ) > -1 ) ) {
      tmpMainMenuSec.push({
        "icon": "pe-7s-link",
        "label": Lang[ 'APP_ADMIN_PORTAL_INJOY' ],
        "to": "https://injoy.netpulse.com/admin/#/",
        "externalLink": true,
        "isDependCenterGroup": true
      })
    }
    if( this.props.data.selectCenterReducer.selectedCenter.center.center_groups.some( data => data[ 'name' ].indexOf( 'ELEMENTS' ) > -1 ) ) {
      tmpMainMenuSec.push({
        "icon": "pe-7s-link",
        "label": Lang[ 'APP_ADMIN_PORTAL_ELEMENTS' ],
        "to": "https://elements.netpulse.com/admin/#/",
        "externalLink": true,
        "isDependCenterGroup": true
      })
    }

    tmpMainMenuSec.push({
      icon: 'pe-7s-info',
      label: Lang[ 'HELP_TUTORIALS' ],
      content: [
        {
          icon: 'pe-7s-video',
          label: Lang[ 'EXPLANATORY_VIDEOS' ],
          to: 'https://tutorials.aciso-suite.com',
          externalLink: true
        },
        {
          icon: 'pe-7s-help1',
          label: Lang[ 'FAQ' ]
        }
      ]
    })

    if( window.location.host.indexOf( 'tennis' ) > -1 ) {
      this.createShortcutMenus( tmpTePeMainMenuSec )
      this.setState({ 
        multilangMainMenu: tmpTePeMainMenuSec,
        mainMenu: this.convertMultiLangMenuToSingleLangMenu( tmpTePeMainMenuSec )
      })
    } else {
      this.createShortcutMenus( tmpMainMenuSec )
      this.setState({ 
        multilangMainMenu: tmpMainMenuSec,
        mainMenu: this.convertMultiLangMenuToSingleLangMenu( tmpMainMenuSec )
      })
    }
  }

  render = () => {
    return (
      <>
        <br />
        <MetisMenu
          ref="menu"
          content={ this.state.mainMenu }
          onSelected={( e, test ) => {
            this.props.setEnableMobileMenu( !this.props.enableMobileMenu )
          }}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down" />
        <br />
        <MetisMenu
          content={ this.state.subMenu }
          onSelected={( e, test ) => {
            this.props.setEnableMobileMenu( !this.props.enableMobileMenu )
          }}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down" />
        <div style={{ height: '100px' }}>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  data: state
})

export default connect( mapStateToProps, {
  setEnableMobileMenu,
  storeSidemenu
})( VerticalNavWrapper )
