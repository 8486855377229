import React, { Component } from 'react'
import { connect } from 'react-redux'

import getDomainURL from 'utils/api'
import { getCipherHeader } from 'utils/cipher-text'
import { setSelectCenter } from 'actions/selectCenter'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedCenter: {}
    }

    getSelectedCenter = async(id, token) => {
      this.setState({ loading: true })
      let tmp = await fetch( `${ getDomainURL() }/api/v1/centers/${ id }`, {
        headers: {
          'Authorization': getCipherHeader()
        }
      })
      let tmpJSON = await tmp.json()
      this.props.setSelectCenter({ 
        center: tmpJSON,
        center_id: tmpJSON.id,
        from: 'ark-site-redirect'
      })
      this.setState({ loading: false }, () => {
        this.props.history.push( '/dashboard/main', {
          from: 'ark-site-redirect'
        })
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadCenter={ this.state.loading }
          getSelectedCenter={ this.getSelectedCenter } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    setSelectCenter
  })( WithHOC )
}

export default HOC