const CookieTemplates = {
  FACEBOOK_PIXEL: {
    title: {
      de: "Facebook Pixel",
      en: "Facebook Pixel",
      es: "Facebook Pixel",
      fr: "Facebook Pixel",
      it: "Facebook Pixel",
      nl: "Facebook Pixel"
    },
    description: {
      de: "Cookie von Facebook, das für Website-Analysen, Ad-Targeting und Anzeigenmessung verwendet wird.",
      en: "Cookie from Facebook used for website analytics, ad targeting, and ad measurement.",
      es: "Cookie de Facebook utilizada para el análisis del sitio web, la orientación de los anuncios y la medición de los mismos.",
      fr: "Cookie de Facebook utilisé pour l'analyse du site web, le ciblage publicitaire et la mesure des publicités.",
      it: "Cookie di Facebook utilizzato per l'analisi del sito web, il targeting degli annunci e la misurazione degli annunci.",
      nl: "Cookie van Facebook, gebruikt voor website-analyse, advertentietargeting en advertentiemeting."
    },
    cookie_name: "_fbp,act,c_user,datr,fr,m_pixel_ration,pl,presence,sb,spin,wd,xs",
    cookie_script: "(function() {let fbScriptContainer = document.createElement(\"SCRIPT\");let fbScriptContent = `!function(f,b,e,v,n,t,s)  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?  n.callMethod.apply(n,arguments):n.queue.push(arguments)};  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';  n.queue=[];t=b.createElement(e);t.async=!0;  t.src=v;s=b.getElementsByTagName(e)[0];  s.parentNode.insertBefore(t,s)}(window, document,'script',  'https://connect.facebook.net/en_US/fbevents.js');  fbq('init', 'FB_ID_HERE');  fbq('track', 'PageView');`;let fbScriptTextNode = document.createTextNode(fbScriptContent);fbScriptContainer.appendChild(fbScriptTextNode);return [fbScriptContainer];}) ();",
    editor: "Facebook",
    url_publisher: "https://www.facebook.com/policies/cookies",
    running_time: "90",
    position: 1,
    status: "ACTIVE",
    center_id: 0,
    cb_category_id: 0
  },
  MATOMO_STATISTICS: {
    title: {
      de: "Matomo Statistiken",
      en: "Matomo Statistics",
      es: "Matomo Estadísticas",
      fr: "Matomo Statistiques",
      it: "Matomo Statistiche",
      nl: "Matomo Statistieken"
    },
    description: {
      de: "Webanalysedienst für Statistiken. Matomo ist auf diesem Server lokal installiert und verwendet die IP Anonymisierung.",
      en: "Web analytics service for statistics. Matomo is installed locally on this server and uses IP anonymization.",
      es: "Servicio de análisis web para estadísticas. Matomo está instalado localmente en este servidor y utiliza la anonimización de IP.",
      fr: "Service d'analyse web pour les statistiques. Matomo est installé localement sur ce serveur et utilise l'anonymat IP.",
      it: "Servizio di analisi web per le statistiche. Matomo è installato localmente su questo server e utilizza l'anonimizzazione dell'IP.",
      nl: "Web analytics dienst voor statistieken. Matomo is lokaal op deze server geïnstalleerd en maakt gebruik van IP-anonimisering."
    },
    cookie_name: "Matomo",
    cookie_script: "var _paq = window._paq = window._paq || [];\n  /* tracker methods like \"setCustomDimension\" should be called before \"trackPageView\" */\n  _paq.push(['trackPageView']);\n  _paq.push(['enableLinkTracking']);\n  (function() {\n    var u=\"//matomo.aciso-suite.com/\";\n    _paq.push(['setTrackerUrl', u+'matomo.php']);\n    _paq.push(['setSiteId', '2']);\n    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);\n  })();",
    editor: "matomo.aciso-suite.com",
    url_publisher: "matomo.aciso-suite.com",
    running_time: "90",
    position: 1,
    status: "ACTIVE",
    center_id: 0,
    cb_category_id: 0
  },
  GOOGLE_ANALYTICS: {
    title: {
      de: "Google Analytics",
      en: "Google Analytics",
      es: "Google Analytics",
      fr: "Google Analytics",
      it: "Google Analytics",
      nl: "Google Analytics"
    },
    description: {
      de: "Cookie von Google zur Steuerung der erweiterten Script- und Ereignisbehandlung.",
      en: "Cookie from Google to control advanced script and event handling.",
      es: "Cookie de Google para controlar el manejo avanzado de scripts y eventos.",
      fr: "Cookie de Google permettant de contrôler le traitement avancé des scripts et des événements.",
      it: "Cookie di Google per controllare la gestione avanzata degli script e degli eventi.",
      nl: "Cookie van Google om geavanceerde script- en eventafhandeling te regelen."
    },
    cookie_name: "Global site tag",
    cookie_script: "<!-- Google Analytics -->\n<script>\n(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\nm=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\nga('create', 'GOOGLE_TAG_HERE', 'auto');\nga('send', 'pageview');\n</script>\n<!-- End Google Analytics -->",
    editor: "Google site tag",
    url_publisher: "https://www.googletagmanager.com/gtag/js?id=GOOGLE_TAG_HERE",
    running_time: "90",
    position: 1,
    status: "ACTIVE",
    center_id: 0,
    cb_category_id: 0
  },
  GOOGLE_TAG_MANAGER: {
    title: {
      de: "Google Tag Manager",
      en: "Google Tag Manager",
      es: "Google Tag Manager",
      fr: "Google Tag Manager",
      it: "Google Tag Manager",
      nl: "Google Tag Manager"
    },
    description: {
      de: "Cookie von Google zur Steuerung der erweiterten Script- und Ereignisbehandlung.",
      en: "Cookie from Google to control advanced script and event handling.",
      es: "Cookie de Google para controlar el manejo avanzado de scripts y eventos.",
      fr: "Cookie de Google permettant de contrôler le traitement avancé des scripts et des événements.",
      it: "Cookie di Google per controllare la gestione avanzata degli script e degli eventi.",
      nl: "Cookie van Google om geavanceerde script- en eventafhandeling te regelen."
    },
    cookie_name: "_ga,_gat,_gid",
    cookie_script: "(function(){let gaScript = document.createElement(\"SCRIPT\");gaScript.src = \"https://www.googletagmanager.com/gtag/js?id=GOOGLE_TAG_HERE\";let gaScript2 = document.createElement(\"SCRIPT\");let gaScript2Content = `window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);}  gtag('js', new Date());  gtag('config', 'GOOGLE_TAG_HERE');`;let gaScript2TextNode = document.createTextNode(gaScript2Content);gaScript2.appendChild(gaScript2TextNode);return [gaScript, gaScript2];}) ();",
    editor: "Google LLC",
    url_publisher: "https://policies.google.com/privacy?hl=de",
    running_time: "90",
    position: 1,
    status: "ACTIVE",
    center_id: 0,
    cb_category_id: 0
  },
  DEFAULT_COOKIE: {
    title: {
      en: '',
      de: '',
      fr: '',
      es: '',
      it: '',
      nl: ''
    },
    description: {
      en: '',
      de: '',
      fr: '',
      es: '',
      it: '',
      nl: ''
    },
    cookie_name: '',
    cookie_script: '',
    editor: '',
    url_publisher: '',
    running_time: '',
    position: 1,
    status: 'ACTIVE',
    cb_category_id: 0
  }
}

export default CookieTemplates
