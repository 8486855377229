import React, { useState } from 'react'
import {
  Input, Form, Label, FormGroup, Button, Container,
  Card, CardBody, Col, Row
}from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import ReactSelect from 'react-select'

import Lang from 'Lang/General'

const AddMailing = props => {
  const [ selectedMailing, setMailing ] = useState({
    value: '',
    id: '',
    label: ''
  })

  const onChangeMailsData = ( key, value ) => {
    let tmpData = _.cloneDeep( props.selectedSiteFunnel )

    tmpData[key] = value

    props.onChangeFunnelsListingHOC( 'selectedSiteFunnel', tmpData )
  }

  return(
    <Container fluid className="mt-3">
      <Card className="main-card mb-3">
        <CardBody>
          <Form>
            {
              props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'system_funnel_mailings'} ).is_editable && (
                <FormGroup>
                  <Label>{'Select a mailing'}</Label>
                  <Row>
                    <Col md={9}>
                      <ReactSelect
                        name="scenario"
                        classNamePrefix="select"
                        value={ selectedMailing }
                        options={ props.systemMailings }
                        onChange={ value => setMailing( value )} 
                      />
                    </Col>
                    <Col md={3}>
                      <Button
                        color="primary"
                        onClick={ () => {
                          let tmp = _.cloneDeep( props.selectedSiteFunnel.mailings )
                          let tmpFound = _.find( props.systemMailings, {id: selectedMailing.id } )
                          if( tmpFound ){
                            tmp.push({
                              mail_id: selectedMailing.id,
                              title: tmpFound.title,
                              send_email_days: 0,
                              time_to_send: '2021-02-02T00:00:00Z'
                            })
                          }
                          onChangeMailsData( 'mailings', tmp )
                          setMailing( {} )
                        }}>
                        { Lang[ 'ADD' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              )
            }
            {
              props.selectedSiteFunnel.mailings && props.selectedSiteFunnel.mailings.length > 0 && props.selectedSiteFunnel.mailings.map( (item, index) => {
                return(
                  <Card className="mt-2">
                    <CardBody>
                      <Label sm={4} style={{ background: '#c2c2c2', marginBottom: '5px' }}>{ item.title }</Label>
                      <div className="d-flex align-items-center">
                        <Input
                          style={{ width: '10%', marginRight: '5px' }}
                          type="number"
                          value={ props.selectedSiteFunnel.mailings[index].send_email_days }
                          onChange={ e => {
                            let tmp = _.cloneDeep( props.selectedSiteFunnel.mailings )
                            tmp[index]['send_email_days'] = parseInt( e.target.value )
                            onChangeMailsData( 'mailings', tmp )
                          }}
                          disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'system_funnel_mailings'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'system_funnel_mailings'} ).is_editable : false }/>
                          { Lang[ 'DAYS_SENDING_THE_FIRST_MAIL_IF_0_SEND_IMMEDIATELY' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                      </div>
                      <div className="d-flex align-items-center">
                        <Input
                          style={{ width: '20%', marginRight: '5px', marginTop: '5px' }}
                          type="time"
                          value={ Moment( props.selectedSiteFunnel.mailings[index].time_to_send ).utcOffset( Moment().utcOffset() ).format( 'HH:mm' ) }
                          onChange={ e => {
                            let tmp = _.cloneDeep( props.selectedSiteFunnel.mailings )
                            tmp[index]['time_to_send'] = Moment(e.target.value, "HH:mm").utc().format()
                            onChangeMailsData( 'mailings', tmp )
                          }}
                          disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'system_funnel_mailings'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'system_funnel_mailings'} ).is_editable : false }/>
                          { Lang[ 'TIME_TO_SEND_THE_MAIL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                      </div>
                      {
                        props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'system_funnel_mailings'} )  && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'system_funnel_mailings'} ).is_editable && (
                          <div style={{ float: 'right' }}>
                            <Button
                              color="danger"
                              onClick={ () => {
                                let tmp = _.cloneDeep( props.selectedSiteFunnel.mailings )
                                tmp.splice( index, 1 )
                                onChangeMailsData( 'mailings', tmp )
                              }}>
                              { Lang[ 'REMOVE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                            </Button>
                          </div>
                        )
                      }
                    </CardBody>
                  </Card>
                )
              })
            }
          </Form>
        </CardBody>
      </Card>
      <Button
        color="primary"
        style={{ float: 'right' }}
        onClick={ () => props.setFunnelMailings( props.selectedSiteFunnel.id, props.selectedSiteFunnel ) }>
        { Lang['SUBMIT'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
      </Button>
    </Container>
  )
}

export default AddMailing
