import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      completedCampaign: [],
      completedCampaignsCenterId: ''
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeCompletedCampaignsHOC = ( key, value ) => this.setState({ [ key ] : value }, () => {
      if( key === 'completedCampaignsCenterId' ){
        this.getCompletedBookedCampaign( false )
      }
    })

    getCompletedBookedCampaign = isOneTimer => Get(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.completedCampaignsCenterId }` }/ols_booked_campaigns?query=${ 
        convertSearchObjectToBase64({ filter: { 'booked.status': 'COMPLETED' } }) }`,
      this.getCompletedBookedCampaignSuccess,
      this.getCompletedBookedCampaignError,
      this.load
    )
    getCompletedBookedCampaignSuccess = payload => {
      let tmpCompletedCampaign = []
      let tmp = {}
      payload && payload.data.map( item => {
        tmp = {
          ...item,
          title: item.campaign.title,
        }
        tmpCompletedCampaign.push( tmp )
      })
      this.setState({ completedCampaign: tmpCompletedCampaign })
    }
    getCompletedBookedCampaignError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCompletedBookedCampaign={ this.state.loading }
            getCompletedBookedCampaign={ this.getCompletedBookedCampaign }
            completedCampaign={ this.state.completedCampaign }
            completedCampaignsCenterId={ this.state.completedCampaignsCenterId }
            onChangeCompletedCampaignsHOC={ this.onChangeCompletedCampaignsHOC } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
