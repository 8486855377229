import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import _ from 'lodash'

import { clearProfile } from 'actions/profile'
import { clearSelectedRole } from 'actions/role'
import { setSelectCenter } from 'actions/selectCenter'
import { updateUserSetting } from 'actions/language'
import Lang from 'Lang/General'
import { SITE_DEPENDENT_ROLES } from './assets'

import 'stylesheets/layout/header/index.scss'

const Userbox = ({
  history,
  profileReducer,
  selectCenterReducer,
  clearProfile,
  clearSelectedRole,
  setSelectCenter,
  lang,
  updateUserSetting
}) => {
  useEffect( () => {
    if( 
      !_.isEmpty( selectCenterReducer.selectedCenter ) &&
      !_.isEmpty( profileReducer.profile ) &&
      _.indexOf( SITE_DEPENDENT_ROLES, profileReducer.profile.role ) > -1 &&
      _.findIndex( profileReducer.profile.centers, { id: selectCenterReducer.selectedCenter.center.id } ) === -1
    ){
      if ( profileReducer.profile.centers.length > 0 ) {
        setSelectCenter( profileReducer.profile.centers[ 0 ] )
      } else {
        clearProfile()
        clearSelectedRole()
        setSelectCenter( {} )
        onClickSignOut()
      }
    }
  }, [ selectCenterReducer, profileReducer ] )

  const onClickSignOut = () => {
    purgeStoredState({
      key: 'aciso-cockpit-store',
      storage
    }).then(() => {
      document.cookie = "ACISO_SESSION_TOKEN=\"\""
      history.push( '/' )
    })
  }

  return (
    <div className="header-btn-lg">
      <span>{ profileReducer.profile.first_name } { profileReducer.profile.last_name }</span>
      <span>
        <select
          type={ 'select' }
          value={ lang }
          onChange={ e => {
            updateUserSetting({ admin_panel_language: e.target.value })
          }}
          style={{ border: 'none', outline: '0px' }}>
          <option value={ 'en' }>{'EN'}</option>
          <option value={ 'de' }>{'DE'}</option>
          <option value={ 'it' }>{'IT'}</option>
          <option value={ 'nl' }>{'NL'}</option>
        </select>
      </span>
      <span
        className="header-userbox"
        onClick = { () => {
          clearProfile()
          clearSelectedRole()
          setSelectCenter( {} )
          onClickSignOut()
        }}>{ Lang[ 'LOGOUT' ][ lang ] }
        <i
          className="pe-7s-power"
          style={{ fontSize: '30px', cursor: 'pointer', verticalAlign: 'middle', marginLeft: '0.5rem' }} />

      </span>

      {/* <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left">
            <UncontrolledButtonDropdown>
              <DropdownToggle color="link" className="p-0">
                <img width={ 42 } className="rounded-circle" src={ avatar1 } alt="" />
                <FontAwesomeIcon className="ml-2 opacity-8" icon={ faAngleDown } />
              </DropdownToggle>
              <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                <div className="dropdown-menu-header">
                  <div className="dropdown-menu-header-inner bg-info">
                    <div className="menu-header-image opacity-2" style={{ backgroundImage: 'url(' + city3 + ')' }} />
                    <div className="menu-header-content text-left">
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left mr-3">
                            <img width={42} className="rounded-circle" src={ avatar1 } alt="" />
                          </div>
                          <div className="widget-content-left">
                            <div className="widget-heading">{ `${ Lang[ 'WELCOME' ][ lang ] }, ${ profileReducer.profile.first_name } ${ profileReducer.profile.last_name }` }</div>
                            <div className="widget-subheading opacity-8">{ profileReducer.profile.role }</div>
                          </div>
                          <div className="widget-content-right mr-2">
                            <Button
                              className="btn-pill btn-shadow btn-shine"
                              color="focus"
                              onClick = { () => {
                                onClickSignOut()
                                clearProfile()
                                clearSelectedRole()
                              }}>{ Lang[ 'LOGOUT' ][ lang ] }</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-area-xs" style={{ height: '150px' }}>
                    <PerfectScrollbar>
                      <Nav vertical>
                        <NavItem className="p-3">
                          <Button onClick={ () => onClickToggleProfileModal() } block className="mb-2 mr-2 btn-shadow btn-gradient-primary">{ Lang[ 'MY_PROFILE' ][ lang ] }</Button>
                        </NavItem>
                      </Nav>
                    </PerfectScrollbar>
                  </div>
                </div>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        </div>
      </div> */}
    </div>
  )
}

const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps, {
  clearProfile,
  clearSelectedRole,
  setSelectCenter,
  updateUserSetting
})( Userbox )
