import {
  STORE_SIDEMENU,
  STORE_ASSIGNED_SIDEMENU
} from './type'

export const storeSidemenu = payload => ({
  type: STORE_SIDEMENU,
  payload
})

export const storeAssignedSidemenu = payload => ({
  type: STORE_ASSIGNED_SIDEMENU,
  payload
})
