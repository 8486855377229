const Lang = {
  NOT_SUBSCRIBED: {
    en: 'You are not subscribed to this module.',
    de: 'Sie sind nicht für dieses Modul angemeldet.',
    nl: 'U bent niet geabonneerd op deze module.',
    it: 'Non sei iscritto a questo modulo.'
  }
}

export default Lang
