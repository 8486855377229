import React, { useState, useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container, Col, Row,
  Form, FormGroup,
  Input, Button,
  Card, CardBody,
  TabContent, TabPane,
  Nav, NavItem, NavLink
} from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FaFolder } from 'react-icons/fa'
import _ from 'lodash'
import classnames from 'classnames'


import LoadingModal from 'components/Indicator/LoadingOverlay'
import ArkPagination from 'components/Pagination'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import WithSharedMedia from './actions'
import WithOwnData from './actions/ownData'
import WithTags from './actions/tags'
import Create from './Create'
import Lang from 'Lang/General'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'

const MediaDatabase = props => { 
  const [ activeTab, updateActiveTab ] = useState( '1' )
  const [ uploadedFiles, updateUploadedFiles ] = useState( [] )
  const [ files, updateFiles ] = useState( [] )

  useEffect( () => {
    let tmp = {
      "short_name": "",
      "page": props.page,
      "limit": 10,
      "is_paginated": true
    }
    props.getImages( convertSearchObjectToBase64( tmp ) )
    props.getCenterMediaDrive()
    props.getTags()
  }, [] )

  useEffect( () => {
    if( !props.data.MediaDatabaseToggle.open ){
      props.onChangeCenterDataDriveHOC( 'newFolderFile', {
        file_name: '',
        type: 'File',
        parent_folder_id: 1
      })
      updateUploadedFiles( [] )
    }
  }, [ props.data.MediaDatabaseToggle.open ] )

  useEffect( () => {
    if( 
      ( props.images.data && props.images.data.length > 0 ) || 
      ( props.mediaDrives.items && props.mediaDrives.items.length > 0 )
    ){
      if( props.emailEditorRef?.current?.editor ) {
        props.emailEditorRef.current.editor.registerCallback( 'selectImage', ( data, done ) => {
          props.toggleMediaDatabase( true )
          props.onChangeCenterDataDriveHOC( 'emailEditorSelectImage', url => done({ url }) )
          let tmp = [ ...document.querySelectorAll( '.btn-select-image' ) ]
          tmp.map( dom => {
            dom.addEventListener( 'click', ( e ) => {
              done({ url: e.target.value })
            })
          })
        })
      }
    }
  }, [ props.images, props.mediaDrives ] )

  const onChange = ( key, value ) => {
    switch( key ){
      case 'activeTab':
        updateActiveTab( value )
        break
      case 'uploadedFiles':
        updateUploadedFiles( value )
        break
      case 'files':
        updateFiles( value )
        break
    }
  }

  const toggle = tab => {
    if ( activeTab !== tab) {
      updateActiveTab( tab )
    }
  }
  return (
    <Modal
      isOpen={ props.data.MediaDatabaseToggle.open }
      size={ 'xl' }
      toggle={() => props.toggleMediaDatabase( false )}>
      <ModalHeader toggle={() => {
        props.toggleMediaDatabase( false )
      }}>
        { Lang[ 'MEDIA_DATABASE' ][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <Nav tabs={true}>
          <NavItem>
            <NavLink href="javascript:void(0);"
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}>
              { Lang[ 'SHARED_MEDIA' ][ props.selectedLanguage ] }
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="javascript:void(0);"
              className={ classnames({ active: activeTab === '2' }) }
              onClick={ () => {
                toggle('2')
              }}>
                { Lang[ 'OWN_DATA' ][ props.selectedLanguage ] }
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={ activeTab }>
          <TabPane tabId="1">
            <Container className="mt-4">
              <Row>
                <Col md={ 12 }>
                  <Form onSubmit={ e => e.preventDefault() }>
                    <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                      <Input
                        type={ 'text' }
                        value={ props.searchParams[ 0 ].param }
                        onChange={ e => {
                          let tmp = _.cloneDeep( props.searchParams )
                          tmp[ 0 ].param = e.target.value
                          return props.onChangeShareMediaHOC( 'searchParams', tmp )
                        }}
                        placeholder={ Lang[ 'SEARCH_WITH_IMAGE_SHORTNAME' ][ props.selectedLanguage ] }
                        style={{ marginRight: '10px' }} />
                      <Button
                        color="primary"
                        onClick={() => {
                          let tmp = {
                            "short_name": props.searchParams[ 0 ].param,
                            "page": props.page,
                            "limit": 10,
                            "is_paginated": true
                          }
                          return props.getImages( convertSearchObjectToBase64( tmp ) )
                        }}>
                          { Lang[ 'SEARCH' ][ props.selectedLanguage ] }
                      </Button>
                      <Button
                        color="danger"
                        className="ml-2"
                        onClick={() => {
                          let tmp = {
                            "short_name": "",
                            "page": props.page,
                            "limit": 10,
                            "is_paginated": true
                          }
                          return props.getImages( convertSearchObjectToBase64( tmp ) )
                        }}>
                          { Lang[ 'RESET' ][ props.selectedLanguage ] }
                      </Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md={ 12 }>
                  {
                    props.images.data && props.images.data.length > 0 && props.images.data.map( item => {
                      return (
                        <Card
                          key={ item.id }
                          style={{
                            display: 'inline-block',
                            marginRight: '10px',
                            marginBottom: '10px'
                          }}>
                          <CardBody className="d-flex flex-column align-items-center justify-content-center">
                            <img src={ item.thumb_url } />
                            <p 
                              className="my-1"
                              style={{ 
                                width: '150px',
                                textAlign: 'center', 
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                              }} >
                              { item.short_name[ 'en' ] }
                            </p>
                            <p style={{ fontStyle: 'italic', textAlign: 'left', fontSize: '12px' }}>{ item.size > 0 ? `${( item.size / 1000 ).toFixed( 2 )} kb` : '' }</p>
                            <div className="d-flex w-100 justify-content-center">
                              <Button
                                color="success" 
                                className="mr-2 btn-select-image" 
                                value={ item.original_url }
                                onClick={ () => {
                                  props.onSelectedImage( item.original_url )
                                }}>
                                { Lang[ 'SELECT' ][ props.selectedLanguage ] }
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      )
                    })
                  }
                  {
                    props.images.data && props.images.data.length > 0 && (
                      <ArkPagination
                        meta={ props.images.meta }
                        onChangePage={ page => {
                          let tmp = {
                            page: page,
                            is_paginated: true,
                          }
                          props.getImages( convertSearchObjectToBase64( tmp ))
                        }} />
                    )
                  }
                </Col>
              </Row>
            </Container>
          </TabPane>
          <TabPane tabId="2">
            <Container>
              <Row>
                <div className="mb-2 p-1 d-flex">
                  {
                    props.path.map( ( item, index ) => {
                      return(
                        <>
                          <Button
                            color="link"
                            onClick={ () => {
                              if( index === 0 ){
                                props.getCenterMediaDrive()
                              } else {
                                props.getSelectedDriveFolder( item )
                              }
                            }}>
                            { item.name }
                          </Button>
                          {
                            index < props.path.length - 1 && (
                              <p
                                style={{
                                  margin: '0px',
                                  padding: '5px 0',
                                  color: '#545CD8' }} >{' >> '}</p>
                            )
                          }
                        </>
                      )
                    })
                  }
                </div>
                <Col lg={ 12 }>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={ 4 } style={{ border: '1px solid #eee', padding: '15px' }}>
                          <h4 style={{ fontWeight: '300' }}>{ Lang[ 'CURRENT_FOLDER' ][ props.selectedLanguage ] }</h4>
                          <Button color="light" className="d-flex w-100 align-items-center mb-2">
                            <FaFolder size={ '24px' } className="mr-2" />
                            { props.path[ props.path.length - 1 ].name }
                          </Button>
                        </Col>
                        <Col lg={ 8 } style={{ border: '1px solid #eee', padding: '15px' }}>
                          <h4 style={{ fontWeight: '300' }}>{ Lang[ 'MEDIAS_INSIDE_FOLDER' ][ props.selectedLanguage ] }</h4>
                          <Row>
                            <Col md={ 12 }>
                              <Button
                                color='primary'
                                className="d-flex w-100 align-items-center justify-content-center mb-2"
                                onClick={ () => props.onChangeCenterDataDriveHOC( 'showCreateModal', true) }>
                                <div className="ml-3">
                                  <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ Lang[ 'ADD_FILE_FOLDER' ][ props.selectedLanguage ] }</p>
                                </div>
                              </Button>
                            </Col>
                            {
                              props.mediaDrives.items && props.mediaDrives.items.map( item => {
                                if( props.allowed.indexOf( item.type ) > -1 ) {
                                  return(
                                    <Col md={ 12 } className="mb-1">
                                      <Button color="light" className="w-100">
                                        <Row className="justify-content-between">
                                          <Col className="flex-grow-1" style={{ minWidth: '210px' }}>
                                            <Row className="align-items-center flex-nowrap m-0" >
                                              <img src={ item.thumb_url } style={{ width: '50px' }} />
                                              <div className="ml-2 text-break">
                                                <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ item.name }</p>
                                                <p style={{ fontStyle: 'italic', textAlign: 'left', fontSize: '12px' }}>{ ( item.file_size / 1000 ).toFixed( 2 ) } kb</p>
                                              </div>
                                            </Row>
                                          </Col>
                                          <Col md={3} style={{ minWidth: '170px' }}>
                                            <Row className="pl-2 pt-2 justify-content-end" >
                                              <Button
                                                color="primary"
                                                className="btn-icon mr-2 btn-select-image"
                                                value={ item.original_url }
                                                onClick={ () => {
                                                  props.onSelectedImage( item.original_url ) 
                                                }}>
                                                { Lang[ 'SELECT' ][ props.selectedLanguage ] }
                                              </Button>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Button>
                                    </Col>
                                  )
                                }
                                if( item.type === 'Video' ){
                                  return(
                                    <Col md={ 12 } className="mb-1">
                                      <Button color="light" className="d-flex w-100 align-items-center">
                                        <img src={ '/static/images/youtube.png' } style={{ width: '50px' }} />
                                        <div className="ml-2">
                                          <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ 'test_video.mp4' }</p>
                                        </div>
                                      </Button>
                                    </Col>
                                  )
                                }
                                if( item.type === 'Folder' ){
                                  return(
                                    <Col md={ 12 } className="mb-1">
                                      <Button
                                        color="light"
                                        className="d-flex w-100 align-items-center"
                                        onClick={ () => props.getSelectedDriveFolder( item ) }>
                                        <FaFolder size={ '35px' } />
                                        <div className="ml-3">
                                          <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ item.name }</p>
                                        </div>
                                      </Button>
                                    </Col>
                                  )
                                }
                              })
                            }
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <Create
              path={ props.path }
              showCreateModal={ props.showCreateModal }
              newFolderFile={ props.newFolderFile }
              onChangeShareMediaHOC={ props.onChangeCenterDataDriveHOC }
              files={ files }
              tags={ props.tags }
              createMedia={ props.createMedia }
              createMediaFolder={ props.createMediaFolder }
              onLoadCenterDataDrive={ props.onLoadCenterDataDrive }
              selectedLanguage={ props.selectedLanguage }
              onChange={ onChange } />
          </TabPane>
        </TabContent>
        { ( props.onLoadImages || props.onLoadCenterDataDrive ) && <LoadingModal /> }
      </ModalBody>
      <ModalFooter>
        {
          uploadedFiles.length > 0 && <Button
            color='primary'
            className='mr-2'
            onClick={ async() => {
              let tmp = _.cloneDeep( props.newFolderFile )
              if( tmp.tags.length > 0 ){
                tmp.tags.map( item => {
                  delete item.label
                  delete item.value
                })
              }

              await props.getCenterMediaDrive()
              const reader = new FileReader()
              reader.onload = e => {
                tmp.media_raw = e.target.result
                let tmpFolder = _.find( props.mediaDrives.items, { name: 'Uploads' } )
                if( !_.isEmpty( tmpFolder ) ){
                  tmp.parent_folder_id = tmpFolder.id
                  props.createMedia( tmp )
                } else {
                  Promise.all([
                    props.createMediaFolder( {
                      folder_name: 'Uploads',
                      parent_folder_id: 1,
                      type: 'Folder'
                    }, tmp )
                  ]).then( () => {
                    tmp.parent_folder_id = props.selectedFolderId
                    props.createMedia( tmp )
                  })
                }
              }
              reader.readAsDataURL( uploadedFiles[ 0 ] )
          }}>
          { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
        </Button>
        }
        <Button color="link" onClick={() => props.toggleMediaDatabase( false )}>
          { Lang[ 'CLOSE' ][ props.selectedLanguage ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  connect( mapStateToProps, {
    toggleMediaDatabase
  }),
  WithSharedMedia,
  WithOwnData,
  WithTags
)( MediaDatabase )