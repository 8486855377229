import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Post, Get } from 'utils/axios-token'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      product: {
        id: 0,
        name: '',
        description: ''
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getProduct = () => Get(
      `/api/v1/admin_panel/products`,
      this.getProductSuccess,
      this.getProductError,
      this.load
    )
    getProductSuccess = payload => {
      payload.data.map( item => {
        if( item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] === 'Local Listing' ){
          this.setState({ product: item })
        }
      })
    }
    getProductError = () => {}

    createProductsRequest = ( data, product_id ) => Post(
      `/api/v1/admin_panel/products/${ product_id }/requests`,
      data,
      this.createProductsRequestSuccess,
      this.createProductsRequestError,
      this.load
    )
    createProductsRequestSuccess = () => {
      this.requestSuccess( Lang['SUCCESS_SEND_REQUEST'][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
    }
    createProductsRequestError = error => this.requestError( error )


    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadRequest={ this.state.loading }
          getProduct={ this.getProduct }
          product={ this.state.product }
          requestInfo={ this.state.requestInfo }
          createProductsRequest={ this.createProductsRequest }/>
      )
    }
  }
  return WithHOC
}

export default HOC
