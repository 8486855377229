import React, { useEffect } from 'react'
import {
  Container, Row, Col,
  Card, Button, UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'

import ARKTable from 'components/Table'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ArticleFourteenHOC from './actions'

import Lang from 'Lang/General'

const ArticleFourteen = props => {
  
  useEffect( () => {
    props.getAllArticles({
      page: 1,
      is_paginated: true
    })
  }, [] )

  const renderViewModal = () => {
    return(
      <Modal isOpen={ props.showViewArticle } size="lg">
        <ModalHeader toggle={ () => props.onChangeArticleFourteenHOC( 'showViewArticle', false ) }>
          { !_.isEmpty( props.selectedArticle ) && props.selectedArticle.topic[ props.data.languageReducer.userSettings.admin_panel_language ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( props.selectedArticle ) &&
              <div>{ ReactHtmlParser( props.selectedArticle.content[ props.data.languageReducer.userSettings.admin_panel_language ] ) }</div>
          }
          { props.onLoadArticles && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => props.onChangeArticleFourteenHOC( 'showViewArticle', false ) }>
            { Lang[ 'CLOSE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'INFOCENTER' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        subheading={ ReactHtmlParser( Lang[ 'INFOCENTER_DESCRIPTION' ][ props.data.languageReducer.userSettings.admin_panel_language ] ) }
        icon="pe-7s-note icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3 p-4">
                <ARKTable
                  data={ props.articles.data }
                  columns={[
                    {
                      Header: Lang[ 'TOPIC' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'topic',
                      Cell: ({ value }) => !_.isEmpty( value[ props.data.languageReducer.userSettings.admin_panel_language ] ) && value[ props.data.languageReducer.userSettings.admin_panel_language ]
                    },
                    {
                      Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'id',
                      Cell: ({ value }) => (
                        <>
                          <Button
                            id={ `Article-${ value }-view` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => props.getSelectedArticle( value ) }>
                            <i className="pe-7s-look"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Article-${ value }-view` }
                            placement="top">
                            { Lang[ 'READ_MORE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                          </UncontrolledTooltip>
                        </>
                      )
                    }
                  ]}
                  totalPages={ props.articleTotalPages }
                  page={ props.articles.meta ? props.articles.meta.current_page : 1 }
                  onChangePage={ pg => {
                    let tmp = {
                      page: pg,
                      is_paginated: true
                    }
                    props.getAllArticles( tmp )
                  }}
                  showPagination={ false } />
              </Card>
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
      { renderViewModal() }
      { props.onLoadArticles && <LoadingOverlay /> }
    </>
  )
}

export default ArticleFourteenHOC( ArticleFourteen )
