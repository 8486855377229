import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  UncontrolledTooltip,
  Form,
  FormGroup,
  CardBody,
  Label,
  Input,
  CardHeader
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import { convertObjToBase64 } from 'utils/objToBase64'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ArkPagination from 'components/Pagination'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import AlertNotSubscribe from 'components/Alert'
import SiteMailingHOC from './actions'
import MailingTemplatesHOC from './actions/templates'
import SenderHOC from 'containers/Dashboard/MarketingFunnels/Senders/actions'
import Create from './Create'
import Update from './Update'
import Archived from './Archived'
import Lang from 'Lang/General'

const SiteMailing = props => {
  const [ subscribedPackage, setSubscribePackage ] = useState( true )

  useEffect( () => {
    props.getAllMailingTemplates( convertObjToBase64({ 
      is_paginated: false,
      filter: {
        status: 'ACTIVE',
        center_id: props.data.selectCenterReducer.selectedCenter.center.id
      }
    }) )
    props.getAllSender()
    let tmpIndex = _.findIndex( props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Marketing Funnel' })
    setSubscribePackage( tmpIndex > -1 )
  }, [])

  useEffect( () => {
    if( !props.showArchivedSiteMailings ){
      props.getAllSiteMailing( 
        convertObjToBase64({ 
          is_paginated: true, 
          page: 1, 
          filter: { status: { $neq: 'ARCHIVED' } },
        }) 
      )
    }
  }, [ props.showArchivedSiteMailings ] )

  useEffect(() => {
    props.onChangeMailingListingHOC( 'step', 1 )
  },[props.showCreateSiteMailing, props.showUpdateSiteMailing])

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_create && subscribedPackage
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_update
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'MAILING' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-mail icon-gradient bg-happy-itmeo"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_MAILING' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            onClick: () => {
              props.onChangeMailingListingHOC( 'showCreateSiteMailing', true )
            },
            display: handleSecurityModulesCreate()
          },
          {
            buttonText: Lang[ 'ARCHIVED_MAILINGS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            onClick: () => {
              props.getAllSiteMailing( 
                convertObjToBase64({ 
                  is_paginated: true, 
                  page: 1, 
                  filter: { status: 'ARCHIVED' },
                }) 
              )
              props.onChangeMailingListingHOC( 'showArchivedSiteMailings', true )
            },
            display: handleSecurityModulesUpdate() && subscribedPackage
          }
        ]} 
      />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          {
            !subscribedPackage && (
              <AlertNotSubscribe { ...props } />
            )
          }
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                <CardBody>
                  <Form onSubmit={ e => e.preventDefault()}>
                    <FormGroup>
                      <Label>{ Lang[ 'TITLE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.siteMailingSearchParams[0].param }
                        onChange={ e => {
                          let tmp = _.cloneDeep( props.siteMailingSearchParams )
                          tmp[ 0 ][ 'param' ] = e.target.value
                          return props.onChangeMailingListingHOC( 'siteMailingSearchParams', tmp )
                        }} />
                    </FormGroup>
                    <div className="d-flex">
                      <Button
                        color="primary"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          let tmp = {
                            is_paginated: true,
                            page: 1,
                            filter: {
                              title: {
                                $like: `%${props.siteMailingSearchParams[0].param}%`
                              }
                            }
                          }
                          props.getAllSiteMailing( convertObjToBase64( tmp ) )
                        }}>{ Lang[ 'SEARCH' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      <Button
                        color="danger"
                        style={{ marginLeft: '10px' }}
                        onClick={() => {
                          let tmp = [
                            {
                              label: 'Title',
                              value: 'title',
                              param: ''
                            }
                          ]
                          props.onChangeMailingListingHOC( 'siteMailingSearchParams', tmp )
                          props.getAllSiteMailing( convertObjToBase64({ page: 1, is_paginated: true }) )
                        }}>{ Lang[ 'RESET' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={ 12 }>
              <Card className="main-card mb-3 p-4">
                <ReactTable
                  NoDataComponent={ () => <span></span> }
                  data={ props.siteMailings.data }
                  columns={[
                    {
                      Header: Lang[ 'TITLE' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'title'
                    },
                    {
                      Header: 'Funnel',
                      accessor: 'funnels',
                      width: 350,
                      Cell: ({value}) => (
                        <ul>
                          {
                            value.map( item => {
                              return(
                                <li>{ item[ props.data.languageReducer.userSettings.admin_panel_language ] }</li>
                              )
                            })
                          }
                        </ul>
                      )
                    },
                    {
                      Header: Lang[ 'STATUS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: 'status',
                      Cell: ({ value }) => Lang[ value ][ props.data.languageReducer.userSettings.admin_panel_language ]
                    },
                    {
                      Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
                      accessor: "id",
                      Cell: ({ row }) => (
                        handleSecurityModulesUpdate() ? (
                          <>
                            <Button
                              id={ `Mailing-${ row.id }-update` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => {
                                props.getSelectedSiteMailing(row.id)
                                props.onChangeMailingListingHOC( 'showUpdateSiteMailing', true )
                              } }
                              disabled={ !subscribedPackage }>
                              <i className="pe-7s-pen"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Mailing-${ row.id }-update` }
                              placement="top">
                              { Lang[ 'UPDATE_MAILING' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                            </UncontrolledTooltip>
                            <Button
                              id={ `site-mailing-${ row.id }-archive` }
                              className='mb-2 mr-2 btn-icon btn-icon-only'
                              color='warning'
                              disabled={ !subscribedPackage }
                              onClick={ () => props.getSelectedSiteMailing( row.id, true ) }>
                              <i className='pe-7s-notebook'> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `site-mailing-${ row.id }-archive` }
                              placement='top'>{ Lang[ 'ARCHIVE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</UncontrolledTooltip>
                          </>
                        ) : (
                          <></>
                        )
                      )
                    }
                  ]}
                  showPagination={ false }
                  defaultPageSize={ 10 } />
                  {
                    props.siteMailings.data && props.siteMailings.data.length > 0 && (
                      <ArkPagination
                        meta={ props.siteMailings.meta }
                        onChangePage={ page => {
                          let tmp = {
                            page: page,
                            is_paginated: true
                          }
                          let tmpFilter = {
                            status: { $neq: 'ARCHIVED' }
                          }
                          props.siteMailingSearchParams.map( searchItem => {
                            if( !_.isEmpty( searchItem.param ) ){
                              tmpFilter = {
                                ...tmpFilter,
                                [searchItem.value]: searchItem.value === 'title' ? { $like: `%${ searchItem.param }%` } : searchItem.param
                              }
                            }
                          })

                          let tmpString = convertObjToBase64( !_.isEmpty( tmpFilter ) ? { ...tmp, filter: tmpFilter } : tmp )
                          props.getAllSiteMailing( tmpString )
                        }} />
                    )
                  }
              </Card>
            </Col>
          </Row>
        </Container>
        <Create {...props} />
        <Update {...props} />
        <Archived { ...props } />
        {
          props.showArchiveSelectedSiteMailing && (
            <DeleteConfirmation
              handleModalClose={ () => props.onChangeMailingListingHOC( 'showArchiveSelectedSiteMailing', false ) }
              deleteOpen={ props.showArchiveSelectedSiteMailing }
              confirmAction={ () => props.updateSiteMailing(
                props.selectedSiteMailing.id,
                { 
                  ...props.selectedSiteMailing,
                  status: props.showArchivedSiteMailings ? 'ACTIVE' : 'ARCHIVED'
                }
              )}
              content={ Lang[ 'ARCHIVE_UNARCHIVE_CONFIRMATION' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
              lang={ props.data.languageReducer.userSettings.admin_panel_language }
            />
          )
        }
      </ReactCSSTransitionGroup>
      { props.onLoadSiteMailings && <LoadingOverlay /> }
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  SiteMailingHOC,
  MailingTemplatesHOC,
  SenderHOC,
  connect( mapStateToProps ),
)( SiteMailing )
