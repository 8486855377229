import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Button,
  Progress
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import ClosingAlert from './components/alert'
import Recipients from './components/recipients'
import Templates from './components/mailingTemplate'
import EmailEditor from './components/EmailEditor'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const CreateMailingForm = ( props ) => {
  const stepTitle = ( step ) => {
    switch (step) {
      case 1:
        return Lang[ 'FILL_IN_DETAILS' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ]
      case 2:
        return Lang[ 'CHOOSE_A_TEMPLATE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ]
      case 3:
        return Lang[ 'DESIGN_MAILING' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ]
      default:
        return ''
    }
  }

  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( props.newSiteMailing )
    if( key === 'recipient_groups' ){
      if( _.find( tmp.recipient_groups, { id: parseInt( value ) } ) ) {
        if( parseInt( value ) === 0 ){
          tmp['recipient_groups'] = []
        } else {
          _.remove( tmp.recipient_groups, { id: parseInt( value ) })
          _.remove( tmp.recipient_groups, { id: 0 } )
        }
      } else {
        if( parseInt( value ) === 0 ){
          props.recipientGroups && props.recipientGroups.length > 0 && props.recipientGroups.map( item => {
            if( !_.find( tmp.recipient_groups, { id: parseInt( item.id ) } )) {
              tmp['recipient_groups'].push({
                id: parseInt( item.id )
              })
            }
          })
        } else {
          tmp['recipient_groups'].push({
            id: parseInt( value )
          })
        }
      }
    } else if ( key === 'is_draft' ) {
      tmp[ 'is_draft' ] = key === 'is_draft'
    } else {
      tmp[ key ] = value
    }
    props.onChangeMailingListingHOC( 'newSiteMailing', tmp )
  }

  return (
    <Modal
      isOpen={ props.showCreateSiteMailing }
      size="xl">
      <ModalHeader toggle={ () => props.onChangeMailingListingHOC( 'showCreateSiteMailing', false ) }>{ Lang[ 'CREATE_MAILING'][props.dataReducer.languageReducer.userSettings.admin_panel_language]}</ModalHeader>
      <ModalBody>
        <div className="text-left">
          {`${ Lang[ 'STEP' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] } ${ props.step }: ${ stepTitle( props.step )}`}
        </div>
        <Progress className="mb-3" value={ props.step } max="3" />
        {
          props.step === 1 &&
           <Recipients
            { ...props }
            data={ props.newSiteMailing }
            selectedLanguage={ props.dataReducer.languageReducer.userSettings.admin_panel_language }
            errorMessage={ {} }
            senders={ props.senders.data }
            onChangeData={ onChangeData } />
        }
        {
          props.step === 2 &&
            <Templates
             { ...props }
             data={ props.newSiteMailing }
             selectedLanguage={ props.dataReducer.languageReducer.userSettings.admin_panel_language }
             errorMessage={ {} }
             templates={ props.mailingTemplates }
             getSelectedMailingTemplate={ props.getSelectedMailingTemplate }
             onChangeData={ onChangeData }/>
        }
        {
          props.step === 3 &&
            <EmailEditor
             { ...props }
             data={ props.newSiteMailing }
             selectedLanguage={ props.dataReducer.languageReducer.userSettings.admin_panel_language }
             errorMessage={ {} }
             templates={ props.mailingTemplates }
             getSelectedMailingTemplate={ props.getSelectedMailingTemplate }
             selectedSiteMailing={ props.selectedSiteMailing }
             onChangeData={ onChangeData }/>
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ () => props.onChangeMailingListingHOC(
            'step', props.step - 1
          )}
          disabled={ props.step === 1 }>{ Lang[ 'BACK' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Button>
        <Button
          color="primary"
          onClick={ () => {
            if( props.step !== 3 ){
              props.onChangeMailingListingHOC( 'step', props.step + 1 )
            } else {
              props.emailEditor.exportHtml( data => {
                let tmp = _.cloneDeep( props.newSiteMailing )
                tmp.json_content = JSON.stringify( data.design )
                tmp.content = data.html
                props.createSiteMailing( tmp )
              })
            }
          }}>{ props.step === 3 ? Lang[ 'SUBMIT' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] : Lang[ 'NEXT' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( CreateMailingForm )
