import React, { Component } from 'react'
import { Button } from 'reactstrap'
import _ from 'lodash'

class Pagination extends Component {
  state = {
    pages: [],
    interval: 10
  }

  componentDidMount = () => {
    this.updatePages()
  }

  componentDidUpdate = pp => {
    if( this.props.meta.last_page !== pp.meta.last_page ) {
      this.updatePages()
    }
  }

  updatePages = () => {
    let tmp = []
    for( let i = 0 ; i < this.props.meta.last_page ; i++ ) {
      tmp.push( i + 1 )
    }
    this.setState({ pages: tmp })
  }

  render = () => {
    return (
      <div className="pt-5 text-center">
        {/* <p className="mb-1 small text-center">{ `Current Page: ${ this.props.metadata.currentPage } of ${ this.props.metadata.totalPages }` }</p> */}
        <div className="pt-1">
          <Button
            className="mr-1"
            color="primary"
            style={{ display: 'inline-block' }}
            disabled={ this.props.meta.current_page === 1 }
            onClick={() => {
              this.props.onChangePage( this.props.meta.current_page - 1 )
            }}>Prev</Button>
          {
            this.state.interval > 10 && (
              <Button
                className="mr-1"
                style={{ display: 'inline-block' }}
                onClick={() => {
                  let tmp = _.cloneDeep( this.state.interval )
                  this.setState({ interval: tmp - 10 })
                }}>
                ...
              </Button>
            )
          }
          {
            this.state.pages.map(( item ) => {
              if(( item > ( this.state.interval - 10 ) ) && ( item <= this.state.interval )) {
                return (
                  <Button
                    key={ `Pagination-${ item }` }
                    outline
                    className="mr-1 border-0 btn-transition"
                    style={{ display: 'inline-block' }}
                    color="dark"
                    onClick={() => this.props.onChangePage( item )}
                    active={ item === this.props.meta.current_page }>
                    { item }
                  </Button>
                )
              }
            })
          }
          {
            ( this.state.interval < this.props.meta.last_page ) && (
              <Button
                className="mr-1"
                style={{ display: 'inline-block' }}
                onClick={() => {
                  let tmp = _.cloneDeep( this.state.interval )
                  this.setState({ interval: tmp + 10 })
                }}>
                ...
              </Button>
            )
          }
          <Button
            className="ml-1"
            style={{ display: 'inline-block' }}
            color="primary"
            disabled={ this.props.meta.current_page === this.props.meta.last_page }
            onClick={() => {
              this.props.onChangePage( this.props.meta.current_page + 1 )
            }}>Next</Button>
        </div>
      </div>
    )
  }
}

export default Pagination
