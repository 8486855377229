import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import './index.scss'

const ImageComponent = ({
  content
}) => {
  return (
    <div 
      style={{ 
        padding: content.padding,
        ...content.style, 
        textAlign: content.mapWith && content.mapWith.indexOf( 'page' ) > -1 ? "center" : "left" }}>
      <img src={ content.url } style={{ width: content.width, height: 'auto' }} />
      {
        content.showQuickInfo && (
          <>
            <i id={`quick-info-tooltip-${content.id}`} className="pe-7s-info ml-2" style={{ fontSize: '20px', cursor: 'pointer' }} />
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          </>
        )
      }
    </div>
  )
}

export default ImageComponent