import React, { Fragment, Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'
import {
    Row, Col,
    Card, CardBody, Button
} from 'reactstrap';

import ReactTable, { usePagination } from "react-table"
import SearchComponent from 'components/Search/search'
import ArkPagination from 'components/Pagination/TablePagination'

class ARKTable extends Component{
  state = {
    searchType: 'Normal Search',
    selectedSearchColumn: [],
    content: [],
    normalSearchValue: '',
  }
  onChange = ( key, value ) => this.setState({ [key]: value })

  updateSelectedSearchColumn = ( key, val ) => {
    if( this.state.selectedSearchColumn.length > 0 ){
      let tmp = _.cloneDeep( this.state.selectedSearchColumn )
      let tmpSearchParam = _.find( tmp, { val: key })
      tmpSearchParam.searchValue = val
      return this.onChange( 'selectedSearchColumn', tmp )
    }
  }

  render = () => {
    let {
      data,
      columns,
      totalPages,
      page,
      onChangePage,
      types,
      onSearch,
      onClickReset,
      searchLabel,
      searchComponent,
      searchColumn,
      lang,
      showPagination,
      getTrProps
    } = this.props
    return(
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          {
            searchComponent && (
              <SearchComponent
                searchType={ this.state.searchType }
                updateSearchType={ this.onChange }
                searchColumn={ searchColumn }
                selectedSearchColumn={ this.state.selectedSearchColumn }
                updateSelectedSearchColumn={ this.updateSelectedSearchColumn }
                normalSearchValue={ this.state.normalSearchValue }
                updateNormalSearchValue={ this.onChange }
                onClickSearch={ onSearch }
                searchLabel={ searchLabel }
                onChange={ this.onChange }
                onClickReset={ onClickReset }
                types={ types }
                page={ page }
                lang={ lang } />
            )
          }
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={ data }
                    columns={ columns }
                    defaultPageSize={ 10 }
                    showPagination={ showPagination }
                    NoDataComponent={ () => <span></span> }
                    className="-striped -highlight"
                    getTrProps={ getTrProps }/>
                  {
                    !showPagination && (
                      <ArkPagination
                        page={ page }
                        totalPages={ totalPages }
                        onChangePage={ onChangePage }
                        onSearch={ onSearch }
                        selectedSearchColumn={ this.state.selectedSearchColumn }
                        searchType={ this.state.searchType }
                        normalSearchValue={ this.state.normalSearchValue }
                        lang={ lang } />
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

export default ARKTable
