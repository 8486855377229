import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const editingLanguages = [ 'en', 'de', 'es', 'fr', 'it', 'nl' ]
const cookieTypeConditions = [
  {
    type: 'TARGETING',
    standardCondition: 'SWITCH_OFF'
  },
  {
    type: 'PRIVACY',
    standardCondition: 'SWITCH_OFF'
  },
  {
    type: 'STRICTLY_NECCESSARY',
    standardCondition: 'ALWAYS'
  },
  {
    type: 'FUNCTIONALITY',
    standardCondition: 'SWITCH_OFF'
  },
  {
    type: 'TRACKING',
    standardCondition: 'SWITCH_OFF'
  },
  {
    type: 'INFORMATION',
    standardCondition: 'SWITCH_OFF'
  },
]
const standardConditions = [ 'SWITCH_OFF', 'ALWAYS', 'NO_DISPLAY', 'SWITCH_ON' ]

const CookieCategoryForm = ({
  currentCookieCategory,
  cookieCategoryType,
  onChangeCookieCategoriesHOC,
  selectedLanguage
}) => {
  const[ editingLanguage, updateEditingLanguage ] = useState( selectedLanguage )

  const updateCurrentCategory = ( key, val ) => {
    let tmpCookieCategory = _.cloneDeep( currentCookieCategory )

    if( key === 'title' || key === 'description' ){
      tmpCookieCategory[ key ][ editingLanguage ] = val
    } else {
      tmpCookieCategory[ key ] = val
    }

    if( key === 'cookie_type' ){
      tmpCookieCategory.standard_condition = _.find( cookieTypeConditions, { type : val } ).standardCondition
    }

    onChangeCookieCategoriesHOC( cookieCategoryType, tmpCookieCategory )
  }
  
  return(
    <Container>
      <Row>
        <Col md={ 12 } >
          <FormGroup>
            <Label style={{ width: "max-content", marginRight: "1rem" }} >{ Lang[ 'CURRENT_EDITING_LANGUAGE' ][ selectedLanguage ] }</Label>
            {
              editingLanguages.map( lang => (
                <Button
                  className='mr-2'
                  color={ editingLanguage === lang ? 'success' : 'primary' }
                  onClick={ () => updateEditingLanguage( lang ) }>{ lang }</Button>
              ))
            }
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={ 8 } >
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'TITLE' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                value={ currentCookieCategory.title[ editingLanguage ] }
                onChange={ e => updateCurrentCategory( 'title', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'COOKIE_BANNER_DESCRIPTION' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                value={ currentCookieCategory.description[ editingLanguage ] }
                onChange={ e => updateCurrentCategory( 'description', e.target.value ) } />
              <Label style={{ fontSize: "70%", paddingTop: "5px" }}>
                { Lang[ 'ACISO_ASSUMES_NO_RESPONSIBILITY' ][ selectedLanguage ] }
              </Label>
            </FormGroup>
            <Row>
              <Col md={ 6 } >
                <FormGroup>
                  <Label>{ Lang[ 'POSITION' ][ selectedLanguage ] }</Label>
                  <Input
                    type='number'
                    min={ 0 }
                    value={ currentCookieCategory.position }
                    onChange={ e => updateCurrentCategory( 'position', parseInt( e.target.value ) ) } />
                </FormGroup>
              </Col>
              <Col md={ 6 } >
                <FormGroup>
                  <Label>{ Lang[ 'COOKIE_TYPE' ][ selectedLanguage ] }</Label>
                  <Input
                    type='select'
                    value={ currentCookieCategory.cookie_type }
                    onChange={ e => updateCurrentCategory( 'cookie_type', e.target.value ) } >
                    { cookieTypeConditions.map( item => <option value={ item.type } >{ Lang[ item.type ][ selectedLanguage ] }</option>) }
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>{ Lang[ 'STANDARD_CONDITION' ][ selectedLanguage ] }</Label>
              <Input
                type='select'
                disabled={ true }
                value={ currentCookieCategory.standard_condition } >
                { standardConditions.map( condition => <option value={ condition }>{ Lang[ condition ][ selectedLanguage ] }</option> ) }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'STATUS' ][ selectedLanguage ] }</Label>
              <Input
                type='select'
                value={ currentCookieCategory.status }
                onChange={ e => updateCurrentCategory( 'status', e.target.value ) }>
                <option value={ 'ACTIVE' } >{ Lang[ 'ACTIVE' ][ selectedLanguage ] }</option>
                <option value={ 'INACTIVE' } >{ Lang[ 'INACTIVE' ][ selectedLanguage ] }</option>
              </Input>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CookieCategoryForm
