import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Card, CardBody, 
  CustomInput, Button, Label, Input, Container,
} from 'reactstrap'
import Moment from 'moment'
import ReactSelect from 'react-select'

import Lang from 'Lang/General'

const FunnelGeneral = ( props ) => {
  const langButton = [ 'en', 'de', 'es', 'it', 'fr', 'nl' ]
  const [ selectedTitleLang, setLangTitle ] = useState( 'en' )
  const [ selectedDescLang, setLangDescription ] = useState( 'en' )

  useEffect( () => {
    setLangTitle( props.dataReducer.languageReducer.userSettings.admin_panel_language )
    setLangDescription( props.dataReducer.languageReducer.userSettings.admin_panel_language )
  }, [] )

  const onChange = ( key, val ) => {
    let tmp = _.cloneDeep( props.selectedSiteFunnel )
    tmp[key] = val
    props.onChangeFunnelsListingHOC( 'selectedSiteFunnel', tmp )
  }

  return(
    <Container fluid className="mt-3">
      <Card className="main-card mb-3">
        <CardBody>
          <Form>
            <table style={{ tableLayout: "auto", width: "100%", borderCollapse: "separate", borderSpacing: "0 10px" }}>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_title" sm={ 2 }>{ Lang['TITLE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] } <span style={{ color: 'red'}}>*</span></Label>
                </td>
                <td>
                  <div className="d-flex pt-1">
                    {
                      langButton.map( button => {
                        return(
                          <Button
                            className="btn-square btn-wide mr-0" size="sm"
                            color={ `${ selectedTitleLang === button ? 'primary' : 'light' }` }
                            onClick={ () => setLangTitle( button ) }>{ button }</Button>
                        )
                      })
                    }
                  </div>
                  <FormGroup className='m-0'>
                    <Input
                      name="funnel_title" id="funnel_title"
                      placeholder={ Lang[ 'ENTER_FUNNEL_TITLE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                      value={ props.selectedSiteFunnel.title[selectedTitleLang] }
                      onChange={ e => {
                        let tmp = _.cloneDeep( props.selectedSiteFunnel.title )
                        tmp[selectedTitleLang] = e.target.value
                        onChange( 'title', tmp )
                      }}
                      disabled={
                        props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'title'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'title'} ).is_editable : false } />
                  </FormGroup>
                  {
                    props.siteFunnelErrMsg.title && (
                      <span style={{ color: 'red' }}>{ props.siteFunnelErrMsg.title }</span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_title" sm={ 2 }>{ Lang['DESCRIPTION'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }<span style={{ color: 'red'}}>*</span></Label>
                </td>
                <td>
                  <div className="d-flex pt-1">
                    {
                      langButton.map( button => {
                        return(
                          <Button
                            className="btn-square btn-wide mr-0" size="sm"
                            color={ `${ selectedDescLang === button ? 'primary' : 'light' }` }
                            onClick={ () => setLangDescription( button ) }>{ button }</Button>
                        )
                      })
                    }
                  </div>
                  <FormGroup className='m-0'>
                    <Input
                      type="textarea"
                      name="funnel_title" id="funnel_title"
                      placeholder={ Lang[ 'ENTER_FUNNEL_DESC' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                      value={ props.selectedSiteFunnel.description[selectedDescLang] }
                      onChange={ e => {
                        let tmp = _.cloneDeep( props.selectedSiteFunnel.description )
                        tmp[selectedDescLang] = e.target.value
                        onChange( 'description', tmp )
                      }}
                      disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'description'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'description'} ).is_editable : false } />
                  </FormGroup>
                  {
                    props.siteFunnelErrMsg.description && (
                      <span style={{ color: 'red', marginBottom: '15px' }}>{ props.siteFunnelErrMsg.description }</span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_title" sm={ 2 }>{ '' }</Label>
                </td>
                <td>
                  <FormGroup className='m-0 ml-3 mt-2'>
                    <CustomInput
                      id="radio-start-if-triggered"
                      type="radio"
                      label={ Lang['START_IF_TRIGGERED'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                      checked={ props.selectedSiteFunnel.start_type === 'start_if_triggered' }
                      onChange={ () => {
                        onChange('start_type', 'start_if_triggered')
                      }}
                      disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'start_type'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'start_type'} ).is_editable : false } />
                    <CustomInput
                      id="radio-start-on-fixed-date"
                      type="radio"
                      label={ Lang['START_ON_FIX_DAY'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                      checked={ props.selectedSiteFunnel.start_type === 'start_on_fixed_date' }
                      onChange={ () => {
                        onChange('start_type', 'start_on_fixed_date')
                      }}
                      disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'start_type'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'start_type'} ).is_editable : false } />
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                {
                  props.selectedSiteFunnel.start_type === 'start_on_fixed_date' && (
                    <FormGroup>
                      <Input
                        type="date"
                        value={ props.selectedSiteFunnel.start_fix_date }
                        min={ Moment().format( 'YYYY-MM-DD' ) }
                        onChange={ e => onChange( 'start_fix_date', e.target.value ) }
                        disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'start_fix_date'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'start_fix_date'} ).is_editable : false } />
                    </FormGroup>
                  )
                }
                </td>
              </tr>
              <tr>
                <td colSpan="7">
                  <hr/>
                </td>
              </tr>
              <tr>
                <td colSpan={ 3 } style={{ width: 'initial', whiteSpace: "nowrap", paddingLeft: "15px" }}>
                  <Label>{ Lang['STOP_FUNNEL_IF'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                </td>
              </tr>
              <tr>
                <td className="col-md-5">
                  <Label
                    checked={ props.selectedSiteFunnel.is_stop_if_enter_funnel }
                    className="ml-1 mb-1 pl-1">
                    <Input
                      type="checkbox" name="radio1"
                      checked={ props.selectedSiteFunnel.is_stop_if_enter_funnel }
                      onChange={ (e) => {
                        onChange( 'is_stop_if_enter_funnel', e.target.checked )
                      }}/>{' '}
                    { Lang['USER_ENTER_ONE_FOLLOWING_FUNNEL'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                  </Label>
                </td>
                <td>
                  <ReactSelect
                    isMulti
                    options={ props.allSiteFunnelSelection.data }
                    value={ props.selectedSiteFunnel.mailing_stops }
                    onChange={ (value, action) => {
                      onChange( 'mailing_stops', value )
                    }} />
                </td>
              </tr>
              <tr>
                <td colSpan="7">
                  <hr/>
                </td>
              </tr>
              {/* <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_title" sm={ 2 }>{ '' }</Label>
                </td>
                <td>
                  <FormGroup className='m-0 ml-3'>
                    <Label
                      check={ props.selectedSiteFunnel.has_expiry_date }
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox" name="radio1"
                        onChange={ (e) => {
                          onChange( 'has_expiry_date', e.target.checked )
                        }}
                        disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'has_expiry_date'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'has_expiry_date'} ).is_editable : false }/>{' '}
                      { Lang['HAS_EXPIRY_DATE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr> */}
              <tr>
                <td colSpan={ 3 }><p style={{ width: 'initial', paddingLeft: '15px' }}>{ Lang['START_END_DATE_TITLE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</p></td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_start_date" sm={ 2 }>{ Lang['START_DATE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                </td>
                <td>
                  <FormGroup className='m-0'>
                    <Input
                      type="date"
                      value={ Moment( props.selectedSiteFunnel.start_date ).format( 'YYYY-MM-DD' ) }
                      min={ Moment( new Date() ).format( 'YYYY-MM-DD' ) }
                      max={ Moment( props.selectedSiteFunnel.end_date ).format( 'YYYY-MM-DD' ) }
                      onChange={ e => {
                        let tmp = Moment( e.target.value ).utc().format()
                        onChange( 'start_date', tmp )
                      }}
                      disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'start_date'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'start_date'} ).is_editable : false} />
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_end_date" sm={ 2 }>{ Lang['END_DATE'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                </td>
                <td>
                  <FormGroup className='m-0'>
                    <Input
                      type="date"
                      value={ Moment( props.selectedSiteFunnel.end_date ).format( 'YYYY-MM-DD' ) }
                      min={ Moment( props.selectedSiteFunnel.start_date ).format( 'YYYY-MM-DD' ) }
                      onChange={ e => {
                        let tmp = Moment( e.target.value ).utc().format()
                        onChange( 'end_date', tmp )
                      }}
                      disabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'end_date'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'end_date'} ).is_editable : false } />
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_end_date" sm={ 2 }>{ Lang['STATUS'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                </td>
                <td>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    invalid={ /*errorMessage && errorMessage.status ? true :*/ false }
                    value={ props.selectedSiteFunnel.status }
                    onChange={ e => onChange( 'status', e.target.value ) }>
                    <option value="ACTIVE">{ Lang[ 'ACTIVE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</option>
                    <option value="INACTIVE">{ Lang[ 'INACTIVE' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</option>
                    <option value="ARCHIVED">{ Lang[ 'ARCHIVED' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</option>
                  </Input>
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_scenario" sm={ 2 }>{ Lang['SCENARIO'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }<span style={{ color: 'red'}}>*</span></Label>
                </td>
                <td>
                  <ReactSelect
                    name="scenario"
                    value={ _.find( props.scenariosForSelect, { id: props.selectedSiteFunnel.scenario_id } ) }
                    options={ props.scenariosForSelect }
                    onChange={ (value, action) => {
                      onChange('scenario_id', value.id )
                    }}
                    isDisabled={ props.selectedSiteFunnel.center_editable_config && _.find( props.selectedSiteFunnel.center_editable_config, {name: 'funnel_scenario_id'} ) ? !_.find( props.selectedSiteFunnel.center_editable_config, {name: 'funnel_scenario_id'} ).is_editable : false}/>
                  {
                    props.siteFunnelErrMsg.scenario_id && (
                      <span style={{ color: 'red', marginBottom: '15px' }}>{ props.siteFunnelErrMsg.scenario_id }</span>
                    )
                  }
                </td>
              </tr>
            </table>
          </Form>
        </CardBody>
      </Card>
      <Button
        color="primary"
        style={{ float: 'right' }}
        onClick={ () => {
          let tmp = _.cloneDeep( props.selectedSiteFunnel )
          tmp.start_fix_date = Moment( tmp.start_fix_date ).utc().format()
          props.updateSiteFunnel( props.selectedSiteFunnel.id, tmp )
        }}>{ Lang['SUBMIT'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }
      </Button>
    </Container>
  )
}

export default FunnelGeneral
