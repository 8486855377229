import React, { Component } from 'react'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container
} from 'reactstrap'
import classnames from 'classnames'
import _ from 'lodash'

import LocationForm from '../components/locationForm'
import OpeningHours from '../components/openingHours'
import Categories from './Categories'
import Contacts from '../components/contacts'
import CurrentOffer from '../components/currentOffer'
import AdditionalInfo from '../components/additionalInfo'
import Images from '../components/images'
import ExternalUrl from '../components/externalUrl'

import Lang from 'Lang/General'

class UpdateLocation extends Component {
  render = () => {
    return(
      <>
        <Nav tabs={ true } >
          <NavItem>
            <NavLink
              href="javascript:void(0);"
              className={ classnames({ active: this.props.activeTab === 0 }) }
              onClick={ () => this.props.onChangeLocations( 'activeTab', 0 ) } >
              { Lang[ 'GENERAL' ][ this.props.selectedLanguage ] }</NavLink>
          </NavItem>
          {
            _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Local Listings' } ) > -1 &&(
              <>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={ classnames({ active: this.props.activeTab === 1 }) }
                    onClick={ () => this.props.onChangeLocations( 'activeTab', 1 ) } >
                    { Lang[ 'OPENING_HOURS' ][ this.props.selectedLanguage ] }</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={ classnames({ active: this.props.activeTab === 2 }) }
                    onClick={ () => this.props.onChangeLocations( 'activeTab', 2 ) } >
                    { Lang[ 'CATEGORIES' ][ this.props.selectedLanguage ] }</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={ classnames({ active: this.props.activeTab === 3 }) }
                    onClick={ () => {
                      this.props.onChangeLocations( 'activeTab', 3 )
                      this.props.getContacts( this.props.selectedLocation.id )
                    }} >
                    { Lang[ 'CONTACTS' ][ this.props.selectedLanguage ] }</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={ classnames({ active: this.props.activeTab === 4 }) }
                    onClick={ () => {
                      this.props.onChangeLocations( 'activeTab', 4 )
                      this.props.getCurrentOffer( this.props.selectedLocation.id )
                    }} >
                    { Lang[ 'CURRENT_OFFER' ][ this.props.selectedLanguage ] }</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={ classnames({ active: this.props.activeTab === 5 }) }
                    onClick={ () => {
                      this.props.onChangeLocations( 'activeTab', 5 )
                      this.props.getAdditionalInfo( this.props.selectedLocation.id )
                    }} >
                    { Lang[ 'ADDITIONAL_INFO' ][ this.props.selectedLanguage ] }</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={ classnames({ active: this.props.activeTab === 6 }) }
                    onClick={ () => {
                      this.props.onChangeLocations( 'activeTab', 6 )
                      this.props.getImages( this.props.selectedLocation.id )
                      this.props.getFbImages( this.props.selectedLocation.id )
                      this.props.getGoogleImages( this.props.selectedLocation.id )
                    }} >
                    { Lang[ 'IMAGES' ][ this.props.selectedLanguage ] }</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={ classnames({ active: this.props.activeTab === 7 }) }
                    onClick={ () => {
                      this.props.onChangeLocations( 'activeTab', 7 )
                      this.props.getExternalUrl( this.props.selectedLocation.id )
                    }} >
                    { Lang[ 'EXTERNAL_URL' ][ this.props.selectedLanguage ] }</NavLink>
                </NavItem>
              </>
            )
          }
        </Nav>
        <Container>
          <TabContent activeTab={ this.props.activeTab } >
            <TabPane tabId={ 0 } className='pt-3'>
              <LocationForm
                currentLocation={ this.props.selectedLocation }
                locationType={ 'selectedLocation' }
                onChangeLocationsHOC={ this.props.onChangeLocationsHOC }
                getStates={ this.props.getStates }
                states={ this.props.states }
                countries={ this.props.countries }
                selectedLanguage={ this.props.selectedLanguage }
                categories={ this.props.categories } />
            </TabPane>
            <TabPane tabId={ 1 } >
              <OpeningHours
                onChangeLocationsHOC={ this.props.onChangeLocationsHOC }
                newOpeningHourData={ this.props.newOpeningHourData }
                site={ this.props.site }
                getSelectedCenterOpeningHours={ this.props.getSelectedCenterOpeningHours }
                selectedOpeningHour={ this.props.selectedOpeningHour }
                selectedLocation={ this.props.selectedLocation }
                selectedLanguage={ this.props.selectedLanguage } />
            </TabPane>
            <TabPane tabId={ 2 } >
              <Categories { ...this.props } />
            </TabPane>
            <TabPane tabId={ 3 } className='pt-3'>
              <Contacts { ...this.props } />
            </TabPane>
            <TabPane tabId={ 4 } className='pt-3'>
              <CurrentOffer { ...this.props } />
            </TabPane>
            <TabPane tabId={ 5 } className='pt-3'>
              <AdditionalInfo { ...this.props } />
            </TabPane>
            <TabPane tabId={ 6 } className='pt-3'>
              <Images { ...this.props } />
            </TabPane>
            <TabPane tabId={ 7 } className='pt-3'>
              <ExternalUrl { ...this.props } />
            </TabPane>
          </TabContent>
        </Container>
      </>
    )
  }
}

export default UpdateLocation
