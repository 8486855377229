import React from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from 'reactstrap'
import Moment from 'moment'

import Lang from 'Lang/General'

const BlacklistedView = ({
  selectedRecipient,
  blacklistedRecipientLogs,
  selectedLanguage,
  showBlacklistedView,
  onChangeRecipientsHOC
}) => {
  return(
    <Modal
      isOpen={ showBlacklistedView }
      toggle={ () => onChangeRecipientsHOC( 'showBlacklistedView', false ) }
      size="xl">
      <ModalHeader toggle={ () => onChangeRecipientsHOC( 'showBlacklistedView', false ) }>{ Lang[ 'VIEW_BLACKLISTED' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 8 }>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <th>{ Lang[ 'FIRST_NAME' ][ selectedLanguage ] }</th>
                    <td>{ selectedRecipient.first_name }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'LAST_NAME' ][ selectedLanguage ] }</th>
                    <td>{ selectedRecipient.last_name }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'EMAIL' ][ selectedLanguage ] }</th>
                    <td>{ selectedRecipient.email }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'SALUTATION' ][ selectedLanguage ] }</th>
                    <td>{ selectedRecipient.salutation }</td>
                  </tr>
                  {/* <tr>
                    <th>{ Lang[ 'TELEPHONE_OR_MOBILE_NUMBER' ][ selectedLanguage ] }</th>
                    <td>{ selectedRecipient.mobile_number }</td>
                  </tr> */}
                </tbody>
              </Table>
            </Col>
            <Col md={ 4 } style={{ overflowY: 'scroll', height: '550px' }}>
            {
              blacklistedRecipientLogs && blacklistedRecipientLogs.map( item => {
                return (
                  <Card key={ item.id } className="mb-2">
                    <CardBody>
                      <p style={{ fontWeight: '600' }}>{ item[ selectedLanguage ] }</p>
                      <span>{ Moment( item.created_at ).format( 'DD MMM YYYY HH:mm:ss' ) }</span>
                    </CardBody>
                  </Card>
                )
              })
            }
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter >
        <Button
          color='danger'
          onClick={ () => onChangeRecipientsHOC( 'showBlacklistedView', false ) }>
          { Lang[ 'CANCEL' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default BlacklistedView
