import React from 'react'
import { connect } from 'react-redux'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap'
import ReactSelect from 'react-select'
import Moment from 'moment'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const SenderForm = ( props ) => {
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( props.newSender )
    tmp[key] = value

    props.onChangeSenderHOC( 'newSender', tmp )
  }

  return(
    <Modal
      isOpen={ props.showCreateSender }
      size="xl">
      <ModalHeader toggle={ () => props.onChangeSenderHOC('showCreateSender', false ) }>{ Lang['CREATE_SENDER'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ]  }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 8 }>
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>{ Lang[ 'NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }<span style={{ color: 'red'}}>*</span></Label>
                      <Input
                        type={ 'text' }
                        value={ props.newSender.name }
                        onChange={ e => {
                          // props.errorMessage["email"] = ""
                          onChangeData( 'name', e.target.value )
                        }}
                        invalid={ props.errorMessage && props.errorMessage.email ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.email ? props.errorMessage.email : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'EMAIL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }<span style={{ color: 'red'}}>*</span></Label>
                      <Input
                        type={ 'email' }
                        value={ props.newSender.email }
                        onChange={ e => {
                          // props.errorMessage["email"] = ""
                          onChangeData( 'email', e.target.value )
                        }}
                        invalid={ props.errorMessage && props.errorMessage.email ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.email ? props.errorMessage.email : '' }</FormFeedback>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => { props.createSender( props.newSender ) } }>{ Lang[ 'SUBMIT' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Button>
        <Button color="primary" onClick={ () => props.onChangeSenderHOC('showCreateSender', false ) }>{ Lang[ 'CANCEL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
      { props.onLoadSenders && <LoadingOverlay /> }
    </Modal>
  )
}

const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( SenderForm )
