import React from 'react'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter, CustomInput,
  Alert
} from 'reactstrap'
import ReactSelect from 'react-select'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const RecipientsForm = ({
  newRecipientData,
  onChangeRecipientsHOC,
  headerText,
  toggle,
  isOpen,
  status,
  onSubmit,
  recipientGroup,
  errorMessage,
  onLoadRecipients,
  selectedLanguage,
  activeRecipientLogs
}) => {
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( newRecipientData )
    let tmpIDs = []
    tmp[key] = value

    onChangeRecipientsHOC( 'newRecipientData', tmp )
  }
  let groupOptions = []
  recipientGroup && recipientGroup.map( item => {
    groupOptions.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })
  const processData = () => {
    let tmpValue = []
    newRecipientData.recipient_groups && newRecipientData.recipient_groups.map( item => {
      tmpValue.push(
        {
          id: item.id,
          label: item.name,
          value: item.name
        }
      )
    })
    return tmpValue
  }
  return(
    <Modal
      toggle={ toggle }
      isOpen={ isOpen }
      size="xl">
      <ModalHeader toggle={ toggle }>{ headerText }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 12 }>
              <Alert color="primary">
                { Lang[ 'IMPORTANT_NOTE_RECIPIENT_ADD' ][ selectedLanguage ] }
              </Alert>
            </Col>
            <Col md={ 12 }>
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>{ Lang[ 'EMAIL' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'email' }
                        value={ newRecipientData.email }
                        onChange={ e => {
                          let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )

                          if( !pattern.test( e.target.value ) ){
                            errorMessage["email"] = "Please enter valid email address."
                          } else {
                            errorMessage["email"] = ""
                          }
                          onChangeData( 'email', e.target.value )
                        }}
                        invalid={ errorMessage && errorMessage.email ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="salutation">{ Lang[ 'SALUTATION' ][ selectedLanguage ] }</Label>
                      <Input
                        type="text"
                        name="salutation"
                        id="salutation"
                        value={ newRecipientData.salutation }
                        onChange={ e => onChangeData( 'salutation', e.target.value ) }/>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'FIRST_NAME' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ newRecipientData.first_name }
                        onChange={ e => onChangeData( 'first_name', e.target.value ) }
                        invalid={ errorMessage && errorMessage.first_name ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.first_name ? errorMessage.first_name : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'LAST_NAME' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ newRecipientData.last_name }
                        onChange={ e => onChangeData( 'last_name', e.target.value ) }
                        invalid={ errorMessage && errorMessage.last_name ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.last_name ? errorMessage.last_name : '' }</FormFeedback>
                    </FormGroup>
                    {/* <FormGroup>
                      <Label>{ Lang[ 'MOBILE_NUMBER' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ newRecipientData.mobile_number }
                        onChange={ e => onChangeData( 'mobile_number', e.target.value ) }
                        invalid={ errorMessage && errorMessage.mobile_number ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.mobile_number ? errorMessage.mobile_number : '' }</FormFeedback>
                    </FormGroup> */}
                    {/* <FormGroup>
                      <Label for="status">{ Lang[ 'STATUS' ][ selectedLanguage ] }</Label>
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        invalid={ false }
                        value={ newRecipientData.status }
                        onChange={ e => onChangeData( 'status', e.target.value ) }>
                        <option value={ 'ACTIVE' }>ACTIVE</option>
                        <option value={ 'INACTIVE' }>INACTIVE</option>
                        <option value={ 'DOI' }>DOI</option>
                      </Input>
                    </FormGroup> */}
                    {
                      ( newRecipientData.status === 'ACTIVE' || newRecipientData.status === 'INACTIVE' ) && (
                        <Alert color="danger">
                          { Lang[ 'NOTIFICATION_LANGUAGE_STATUS_CHANGE' ][ selectedLanguage ] }
                        </Alert>
                      )
                    }
                    <FormGroup>
                      <Label>{ Lang[ 'GROUP' ][ selectedLanguage ] }</Label>
                      <ReactSelect
                        isMulti
                        name="select"
                        placeholder={ Lang[ 'SELECT_GROUP' ][ selectedLanguage ] }
                        options={ groupOptions }
                        className="search-select"
                        classNamePrefix="select"
                        onChange={ selectedValue => {
                          let tmp = []
                          let ids = 0
                          selectedValue &&  selectedValue.map( item => {
                            if( item.id === 0 ){
                              tmp = []
                              groupOptions.map( group => {
                                if( group.id !== 0 ){
                                  tmp.push(
                                    {
                                      id: group.id,
                                      name: group.label,
                                    }
                                  )
                                }
                              })
                            } else {
                              tmp.push(
                                {
                                  id: item.id,
                                  name: item.label,
                                }
                              )
                            }
                          })
                          onChangeData( 'recipient_groups', tmp )
                        }}
                        value={ processData() }/>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ onSubmit }>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
        <Button color="primary" onClick={ toggle }>{ Lang[ 'CANCEL' ][ selectedLanguage ] }</Button>
      </ModalFooter>
      { onLoadRecipients && <LoadingOverlay /> }
    </Modal>
  )
}

export default RecipientsForm
