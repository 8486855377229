import React from 'react'
import { connect } from 'react-redux'
import {
  Button, Table,
  Container, Row, Col,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const ViewRecipient = ( props ) => {

  return(
    <Modal
      isOpen={ props.showViewActiveARecipient }>
      <ModalHeader toggle={ () => props.onChangeActiveRecipientHOC('showViewActiveARecipient', false ) }>{ Lang['VIEW'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ]  }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={12 }>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <th>{ Lang[ 'FIRST_NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedActiveRecipient.first_name }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'LAST_NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedActiveRecipient.last_name }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'EMAIL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedActiveRecipient.email }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'SALUTATION' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedActiveRecipient.salutation }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'TELEPHONE_OR_MOBILE_NUMBER' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedActiveRecipient.mobile_number }</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => { props.onChangeActiveRecipientHOC( 'showViewActiveARecipient', false ) } }>{ 'OK' }</Button>
      </ModalFooter>
      { props.onLoadActiveRecipient && <LoadingOverlay /> }
    </Modal>
  )
}

const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( ViewRecipient )
