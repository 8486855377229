import React, { Component } from 'react'
import {
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Alert
} from 'reactstrap'
import Slider from "react-slick"
import { connect } from 'react-redux'
import {
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation'
import Cookies from 'js-cookie'
import _ from 'lodash'

import TemplateContainerMain from 'Template/main'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import bg1 from 'assets/images/backdrop-conida.svg'
import TennisBg from 'assets/images/tennis.jpg'

import { auth } from 'actions/login'

import { getToken } from 'utils/findCookieToken'
import { ApplyBranding } from 'utils/apply-color'
import { getPlatformConfig } from 'actions/platformConfig'
import { clearProfile } from 'actions/profile'
import { clearSelectedRole } from 'actions/role'
import { setSelectCenter } from 'actions/selectCenter'

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true
}

const SliderImageItemStyle = { 
  opacity: "1", 
  height: "100vh", 
  minHeight: "100vh",
  width: "100%",
  objectFit: "contain",
  objectPosition: "center",
  padding: "1rem"
}

class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  componentDidMount = () => {
    this.props.clearProfile()
    this.props.clearSelectedRole()
    this.props.setSelectCenter( {} )
    if( Cookies.get( 'SHOW_OUTDATED_TOKEN' ) && Cookies.get( 'SHOW_OUTDATED_TOKEN' ) === 'true' ) {
      Cookies.set( 'SHOW_OUTDATED_TOKEN', 'false' )
    }
    this.props.getPlatformConfig()
  }

  componentDidUpdate = pp => {
    if( this.props.ajaxReducer.ajaxCallProgress !== pp.ajaxReducer.ajaxCallProgress ) {
      if( this.props.ajaxReducer.ajaxCallProgress === 0 ) {
        if( getToken( 'ACISO_SESSION_TOKEN' ).length > 2 ) {
          this.props.history.push( '/dashboard/main' )
        }
      }
    }
  }

  handleKeyPress = ( event ) => {
    if( event.key === 'Enter' ){
      this.props.auth({ email: this.state.email, password: this.state.password })
    }
  }

  handleRedirectLink = ( contentType ) => {
    if( window.location.host.indexOf( 'localhost' ) > -1 || window.location.host.indexOf( 'cockpit-staging.aciso-suite.com' ) > -1 || window.location.host.indexOf( 'adminportal.aciso-suite.com' ) > -1 ) {
      return `https://www.aciso.com/${ contentType }`
    } else if( window.location.host.indexOf( 'adminportal.tennis-suite.com' ) > -1 ) {
      return `https://www.tennis-people.com/${ contentType }`
    } else if( window.location.host.indexOf( 'adminportal.proof-point-suite.com' ) > -1 ) {
      return `https://www.proof-point.com/${ contentType }`
    }
  }

  render = () => {
    return (
      <>
        {
          !_.isEmpty( this.props.data.platformConfigReducer.platformConfig ) 
          && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } )
          && <ApplyBranding colors={{ primary: _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } ).value }} />
        }
        <TemplateContainerMain>
          <div style={{ height: "100vh" }} >
            <Row className="no-gutters" style={{ height: "100vh" }}>
              <Col lg="4" className="d-none d-lg-block">
                <div className="slider-light">
                  {
                    window.location.host.indexOf( 'tennis' ) > -1 
                      ? (
                        <Slider { ...SliderSettings }>
                          <div >
                            <img 
                              src={ TennisBg } 
                              style={ SliderImageItemStyle } />
                          </div>
                        </Slider>
                      ) : (
                        <Slider { ...SliderSettings }>
                          <div
                            className="d-flex justify-content-start align-items-start">
                            <img 
                              src={ bg1 } 
                              style={{ 
                                ...SliderImageItemStyle,
                                backgroundColor: "#ffffff"
                              }} 
                            />
                          </div>
                        </Slider>
                      )
                  }
                </div>
              </Col>
              <Col 
                lg="8" 
                md="12" 
                style={{ height: "100vh" }}
                className="d-flex bg-white justify-content-center align-items-center" >
                <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                  <img 
                    src={
                      !_.isEmpty( this.props.data.platformConfigReducer.platformConfig ) 
                      && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ) 
                        ? _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ).value
                        : ''
                    }
                    style={{ width: '150px', marginBottom: '0' }}/>
                  <span style={{ fontSize: '20px', marginLeft: '15px' }}>Cockpit</span>
                  <Row className="divider"/>
                  <AvForm>
                    <Row form>
                      {
                        (
                          ( this.props.ajaxReducer.ajaxErrorMessage !== '' && typeof this.props.ajaxReducer.ajaxErrorMessage !== 'object' ) ||
                          localStorage.getItem( 'IS_TOKEN_EXPIRED' )
                        ) && (
                          <Col md={ 12 }>
                            <Alert color="danger">
                              { 
                                ( localStorage.getItem( 'IS_TOKEN_EXPIRED' ) && 'Ihre Sitzung ist abgelaufen' ) || 
                                JSON.stringify( this.props.ajaxReducer.ajaxErrorMessage ) 
                              }
                            </Alert>
                          </Col>
                        )
                      }
                      <Col md={6}>
                        <AvGroup>
                          <Label for="exampleEmail">Email Adresse</Label>
                          <AvInput
                            onChange={ e => this.setState({ email: e.target.value })}
                            type="email"
                            name="email"
                            value={ this.state.email }
                            onKeyPress={ this.handleKeyPress }
                            required />
                        </AvGroup>
                      </Col>
                      <Col md={ 6 }>
                        <AvGroup>
                          <Label for="exampleEmail">Passwort</Label>
                          <AvInput
                            onChange={ e => this.setState({ password: e.target.value })}
                            type="password"
                            name="password"
                            value={ this.state.password }
                            onKeyPress={ this.handleKeyPress }
                            required />
                        </AvGroup>
                        <FormGroup style={{ textAlign: 'right' }}>
                          <Button color="link" onClick={() => this.props.history.push( '/forget-password' )}>PASSWORT VERGESSEN?</Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="divider" />
                    <div className="d-flex align-items-center">
                      <div className="ml-auto">
                        <Button
                          color="primary"
                          onClick={() => {
                            this.props.auth({ email: this.state.email, password: this.state.password })
                            window.localStorage.removeItem( 'IS_TOKEN_EXPIRED' )
                          }}>Login</Button>
                      </div>
                    </div>
                  </AvForm>
                </Col>
                <div style={{ position: 'fixed', bottom: '30px', fontSize: '17px' }}>
                  <a href={ this.handleRedirectLink( 'impressum' ) } target="_blank" className='mr-3'>Impressum</a>
                  <a href={ this.handleRedirectLink( 'datenschutz' ) } target="_blank">Datenschutz</a>
                </div>
              </Col>
            </Row>
          </div>
          { this.props.ajaxReducer.ajaxCallProgress > 0 && <LoadingOverlay /> }
        </TemplateContainerMain>
      </>
    )
  }
}

const mapStateToProps = state => ({
  data: state,
  ajaxReducer: state.ajaxReducer
})

export default connect( mapStateToProps, {
  auth,
  getPlatformConfig,
  clearProfile,
  clearSelectedRole,
  setSelectCenter
})( Login )
