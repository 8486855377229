import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import { Get, Put, Delete, Post } from 'utils/axios-token'

import Lang from '../Lang'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      bulkData: {
        "raw": "",
        "type": "csv",
        "status": "DOI",
        "site_id": 1,
        "recipient_groups": []
      },
      summary: {},
      openSummaryModal: false,
      files: []
    }

    onChangeBulkHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    createBulkRecipient = () => Post(
      `/api/v1/recipients`,
      {
        ...this.state.bulkData,
        site_id: this.props.data.selectCenterReducer.selectedCenter.center.site.id
      },
      this.createBulkRecipientSuccess,
      this.createBulkRecipientError,
      this.load
    )
    createBulkRecipientSuccess = payload => {
      this.requestSuccess( Lang['UPLOAD_SUCCESS'][this.props.data.languageReducer.userSettings.admin_panel_language] )
      this.setState({
        summary: payload,
        openSummaryModal: true,
        bulkData: {
          "raw": "<base64>",
          "type": "csv",
          "status": "DOI",
          "site_id": 1,
          "recipient_groups": []
        },
        files: []
      })
    }
    createBulkRecipientError = error => {
      if( error.raw === 'raw is required'){
        this.requestError( Lang['UPLOAD_FILE'][this.props.data.languageReducer.userSettings.admin_panel_language] )
      } else if( error.type ) {
        this.requestError( error.type )
      } else {
        this.requestError( error )
      }
    }

    render = () => {
      return(
        <>
          <WrappedComponent
            {...this.props}
            bulkData={ this.state.bulkData }
            onChangeBulkHOC={ this.onChangeBulkHOC }
            summary={ this.state.summary }
            createBulkRecipient={ this.createBulkRecipient }
            openSummaryModal={ this.state.openSummaryModal }
            onLoadBulkUpload={ this.state.loading }
            files={ this.state.files }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}
export default HOC
