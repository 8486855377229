import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import WithRecipients from './actions/blacklist'
import ArkPagination from 'components/Pagination'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import AlertNotSubscribe from 'components/Alert'
import RecipientsForm from './components/blacklistedForm'
import CountryHOC from 'actions/country'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import DictionaryHOC from 'utils/dictionary'
import WithRecipientGroup from '../RecipientGroup/actions'
import BlacklistedView from './components/view'

import Lang from 'Lang/General'

class BlacklistedRecipientsListings extends Component {
  state = {
    view: 'scheduled',
    subscribedPackage: true
  }

  componentDidMount = () => {
    let tmp = {
      is_paginated: true,
      page: 1,
      is_blacklisted: true,
    }
    let tmpString = convertSearchObjectToBase64( tmp )
    this.props.getDictionary()
    this.props.getRecipients( tmpString )
    this.props.getRecipientGroup( tmpString )
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Newsletters' })
    this.setState({ subscribedPackage: tmpIndex > -1 })
    this.props.getCountries()
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_create && this.state.subscribedPackage
    }
  }

  renderCreateModal = () => {
    return(
      <RecipientsForm
        newBlacklistRecipientData={ this.props.newBlacklistRecipientData }
        onChangeRecipientsHOC={ this.props.onChangeRecipientsHOC }
        toggle={ () => this.props.onChangeRecipientsHOC( 'showCreateRecipients', false ) }
        isOpen={ this.props.showCreateRecipients }
        headerText={ Lang[ 'CREATE_BLACKLISTED_RECIPIENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        status={ this.props.dictionaries.status }
        mode="create"
        postRecipient={ this.props.postRecipient }
        recipientGroup={ this.props.recipientGroup.data }
        onLoadRecipients={ this.props.onLoadRecipients }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }
        errorMessage={ this.props.errorMessage }
        countries={ this.props.countries }
        blacklistedRecipientLogs={ this.props.blacklistedRecipientLogs }
        data={ this.props.data } />
    )
  }

  renderView = () => {
    return(
      <BlacklistedView
        selectedRecipient={ this.props.selectedRecipient }
        onChangeRecipientsHOC={ this.props.onChangeRecipientsHOC }
        showBlacklistedView={ this.props.showBlacklistedView }
        onLoadRecipients={ this.props.onLoadRecipients }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }
        errorMessage={ this.props.errorMessage }
        blacklistedRecipientLogs={ this.props.blacklistedRecipientLogs } />
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'BLACKLISTED_RECIPIENTS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-shield icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_RECIPIENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
              onClick: () => {
                this.props.onChangeRecipientsHOC( 'showCreateRecipients', true )
              },
              display: this.handleSecurityModulesCreate()
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            {
              !this.state.subscribedPackage && (
                <AlertNotSubscribe { ...this.props } />
              )
            }
            <Row>
              <Col md={ 12 }>
                <Card className="main-card mb-3">
                  <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                  <CardBody>
                    <Form onSubmit={ e => e.preventDefault()}>
                      <FormGroup>
                        <Label>{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                        <Input
                          type={ 'text' }
                          value={ this.props.searchParams[0].params }
                          onChange={ e => {
                            let tmp = _.cloneDeep( this.props.searchParams )
                            tmp[ 0 ][ 'param' ] = e.target.value
                            return this.props.onChangeRecipientsHOC( 'searchParams', tmp )
                          }} />
                      </FormGroup>
                      <div className="d-flex">
                        <Button
                          color="primary"
                          style={{ marginLeft: 'auto' }}
                          onClick={() => {
                            let tmpSearchParams = {
                              is_paginated: true,
                              page: 1,
                              filter: {
                                email: {
                                  $like: `%${ this.props.searchParams[ 0 ].param }%`
                                },
                              },
                              is_blacklisted: true
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipients( tmpSearchString )
                          }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                        <Button
                          color="danger"
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            let tmpSearchParams = {
                              is_paginated: true,
                              page: 1,
                              is_blacklisted: true
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipients( tmpSearchString )
                          }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col md={ 12 }>
                <Card className="main-card mb-3">
                  <CardBody>
                    <ReactTable
                      NoDataComponent={ () => <span></span> }
                      data={ this.props.recipients.data }
                      columns={[
                        {
                          Header: Lang[ 'EMAIL' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'email'
                        },
                        {
                          Header: Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          Cell: row => (
                            <p>{ row.original.first_name || '-' }</p>
                          )
                        },
                        {
                          Header: Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          Cell: row => (
                            <p>{ row.original.last_name || '-' }</p>
                          )
                        },
                        {
                          Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'id',
                          Cell: ( row ) => (
                            <div className="d-flex flex-row align-items-center justify-content-center w-100">
                              <Button
                                id={ `Blacklist-view-${ row.original.id }` }
                                className="mr-2 btn-icon btn-icon-only"
                                color="primary"
                                onClick={() => {
                                  this.props.onChangeRecipientsHOC( 'showBlacklistedView', true )
                                  this.props.getSelectedRecipient( row.original.id )
                                }}
                                disabled={ !this.state.subscribedPackage }>
                                <i className="pe-7s-look"></i>
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                target={ `Blacklist-view-${ row.original.id }` }>
                                { Lang[ 'VIEW' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                              </UncontrolledTooltip>
                              <Button
                                id={ `Blacklist-delete-${ row.original.id }` }
                                className="mr-2 btn-icon btn-icon-only"
                                color="danger"
                                onClick={() => {
                                  this.props.onChangeRecipientsHOC( 'showDeleteRecipientConfirmation', true )
                                  this.props.getSelectedRecipient( row.original.id )
                                }}
                                disabled={ !this.state.subscribedPackage }>
                                <i className="pe-7s-trash"></i>
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                target={ `Blacklist-delete-${ row.original.id }` }>
                                { Lang[ 'DELETE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                              </UncontrolledTooltip>
                            </div>
                          )
                        }
                      ]}
                      showPagination={ false }
                      defaultPageSize={ 10 } />
                    {
                      this.props.recipients.data && this.props.recipients.data.length > 0 && (
                        <ArkPagination
                          meta={ this.props.recipients.meta }
                          onChangePage={ page => {
                            let tmpSearchParams = {
                              email: this.props.searchParams[ 0 ].param,
                              page: page,
                              is_paginated: true
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipients( tmpSearchString )
                          }} />
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
        {
          this.props.showDeleteRecipientConfirmation && (
            <DeleteConfirmation
               handleModalClose={ () => this.props.onChangeRecipientsHOC( 'showDeleteRecipientConfirmation', false ) }
               deleteOpen={ this.props.showDeleteRecipientConfirmation }
               confirmAction={ () => {
                 this.props.onChangeRecipientsHOC( 'showDeleteRecipientConfirmation', false )
                 this.props.deleteRecipient({
                   page: 1,
                   is_paginated: true,
                   is_blacklisted: true
                 })
               }}
               content={ Lang[ 'ARE_YOU_SURE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
               lang={ this.props.data.languageReducer.userSettings.admin_panel_language }/>
          )
        }
        { this.props.showCreateRecipients && this.renderCreateModal() }
        {  this.renderView() }
        { this.props.onLoadRecipients && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithRecipientGroup,
  WithRecipients,
  DictionaryHOC,
  CountryHOC
)( BlacklistedRecipientsListings )
