import React from 'react'
import { connect } from 'react-redux'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import ArkPagination from 'components/Pagination'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const View = ( props ) => {
  return(
    <Modal
      isOpen={ props.showViewRecipientGroup }
      size="xl">
      <ModalHeader toggle={ () => props.onChangeRecipientGroupHOC( 'showViewRecipientGroup', false ) }>{ Lang[ 'VIEW' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedRecipientGroup ) && <Container>
            <Row>
              <Col md={ 12 }>
                <Card>
                  <CardBody>
                    <Form>
                      <FormGroup row className="pl-3">
                        <Label>{ Lang[ 'NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }: { props.selectedRecipientGroup.name[ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      </FormGroup>
                      <FormGroup row className="pl-3">
                        <Label>{ Lang[ 'STATUS' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }: { props.selectedRecipientGroup.status }</Label>
                      </FormGroup>
                      {
                        props.selectedRecipientGroup.recipients.length > 0 && (
                          <FormGroup>
                            <Label>{ Lang[ 'RECIPIENT_LIST' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                            <ReactTable
                              NoDataComponent={ () => <span></span> }
                              data={ props.selectedRecipientGroup.recipients }
                              columns={[
                                {
                                  Header: Lang[ 'NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ],
                                  accessor: 'first_name'
                                },
                                {
                                  Header: Lang[ 'EMAIL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ],
                                  accessor: 'email',
                                  style: { 'line-break': 'anywhere' }
                                },
                                {
                                  Header: Lang[ 'STATUS' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ],
                                  accessor: 'status',
                                  Cell: row => Lang[ row.original.status ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ]
                                },
                                // {
                                //   Header: Lang[ 'ACTIONS' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ],
                                //   accessor: 'id',
                                //   Cell: ( row ) => (
                                //     <div className="d-flex flex-row align-items-center justify-content-center w-100">
                                //       <Button
                                //         className="mr-2 btn-icon btn-icon-only"
                                //         color="primary"
                                //         onClick={() => {
                                //           onChangeRecipientHOC( 'showEditRecipientModal', true )
                                //           getSelectedRecipient( row.original.id )
                                //         }}>
                                //         <i className="pe-7s-note"></i>
                                //       </Button>
                                //       <Button
                                //         className="mr-2 btn-icon btn-icon-only"
                                //         color="danger"
                                //         onClick={() => {
                                //           onChangeRecipientHOC( 'showDeleteRecipientConfirmation', true )
                                //           getSelectedRecipient( row.original.id )
                                //         }}>
                                //         <i className="pe-7s-trash"></i>
                                //       </Button>
                                //     </div>
                                //   )
                                // }
                              ]}
                              showPagination={ false }
                              defaultPageSize={ 10 } />
                          </FormGroup>
                        )
                      }
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => props.onChangeRecipientGroupHOC( 'showViewRecipientGroup', false ) }>{ 'Ok' }</Button>
      </ModalFooter>
    </Modal>
  )
}
const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( View )
