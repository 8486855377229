import React from 'react'
import { Container, Row } from 'reactstrap'
import _ from 'lodash'

import RenderDOM from '../RenderDOM'
import Lang from 'Lang/General'

const ContainerComponent = ({
  content,
  mode,
  onChangeFormBuilderState,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  removeSubmittedFormMedia,
  uploadedMedia,
  currentPage,
  dom,
  findObjectAndPatch,
  togglePageSelection,
  currentLanguage,
  pageErrors
}) => {
  return content.page ? (
    content.page === currentPage && (
      <Container 
        className="p-0"
        style={
          content.mapWith && content.mapWith === 'cover' ? ({
            display: "flex",
            flex: "1",
            alignItems: "center",
            justifyContent: "center"
          }) : ({
            position: "relative",
            flex: "auto"
          }) 
        } >
        {
          content.children && content.children.map( item => {
            return <RenderDOM
              content={ item }
              onChangeFormBuilderState={ onChangeFormBuilderState }
              mode={ mode }
              formValues={ formValues }
              onPatchFormValues={ onPatchFormValues }
              onClickSubmitForm={ onClickSubmitForm }
              createSubmittedFormMedia={ createSubmittedFormMedia }
              removeSubmittedFormMedia={ removeSubmittedFormMedia }
              uploadedMedia={ uploadedMedia }
              currentPage={ currentPage }
              dom={ dom }
              findObjectAndPatch={ findObjectAndPatch }
              data={ {} }
              isInputDisabled={ content.selectable && !content.selected }
              togglePageSelection={ togglePageSelection }
              currentLanguage={ currentLanguage }
              pageErrors={ pageErrors }
            />
          })
        }
        {
          content.selectable && !content.selected && (
            <div className="form-selectable-page-lightbox-container">
              <div className="lightbox">
                <p>
                  { Lang[ 'INFORM_OPTIONAL_PAGE' ][ currentLanguage ] }
                  <br/>
                  { ( Lang[ 'ACTIVATE_OPTIONAL_PAGE_INSTRUCTION' ][ currentLanguage ]).replace( '{{pageName}}' , content.name[ currentLanguage ].replace(/(&nbsp;|<([^>]+)>)/ig, "") ) }
                </p>
                <Row className="justify-content-center flex-nowrap mt-3">
                  <button className="btn btn-secondary mx-1" onClick={ () => onChangeFormBuilderState( 'currentPage', currentPage + 1 )  }>
                    { Lang[ 'SKIP_AND_CONTINUE' ][ currentLanguage ] }
                  </button>
                  <button className="btn btn-primary mx-1" onClick={ () => togglePageSelection() }>
                    { Lang[ 'ACTIVATE_AND_FILL_IN' ][ currentLanguage ] }
                  </button>
                </Row>
              </div> 
            </div>
          )
        }
      </Container>
    )
  ) : (
    <Container 
      style={ 
        content.mapWith && content.mapWith === 'cover' ? ({
          display: "flex",
          flex: "1",
          alignItems: "center",
          justifyContent: "center"
        }) : ({}) 
      } >
      {
        content.children && content.children.map( item => {
          return <RenderDOM
            content={ item }
            onChangeFormBuilderState={ onChangeFormBuilderState }
            mode={ mode }
            formValues={ formValues }
            onPatchFormValues={ onPatchFormValues }
            onClickSubmitForm={ onClickSubmitForm }
            createSubmittedFormMedia={ createSubmittedFormMedia }
            removeSubmittedFormMedia={ removeSubmittedFormMedia }
            uploadedMedia={ uploadedMedia }
            currentPage={ currentPage }
            dom={ dom }
            findObjectAndPatch={ findObjectAndPatch }
            data={ {} }
            currentLanguage={ currentLanguage }
            pageErrors={ pageErrors }
          />
        })
      }
    </Container>
  )
}

export default ContainerComponent