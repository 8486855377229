import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      step: 1,
      siteFunnelSearchParams: [
        {
          label: 'Title',
          value: 'title',
          param: ''
        },
      ],
      siteFunnels: [],
      selectedSiteFunnel: {
        "title": {
          en: "",
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        },
        "description": {
          en: "",
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        },
        "start_type": "start_on_fixed_date",
        "start_fix_date": Moment().format( 'YYYY-MM-DD' ),
        "start_date": "",
        "end_date": "",
        "has_expiry_date": false,
        "status": "ACTIVE",
        "do_stop_if_enter_funnel": true,
        "scenario_id": 1,
        is_stop_if_enter_funnel: false,
        center_editable_config: [
          {
            name: 'title',
            is_editable: true
          },
          {
            name: 'description',
            is_editable: true
          },
          {
            name: 'start_type',
            is_editable: true
          },
          {
            name: 'start_fix_date',
            is_editable: true
          },
          {
            name: 'start_date',
            is_editable: true
          },
          {
            name: 'end_date',
            is_editable: true
          },
          {
            name: 'has_expiry_date',
            is_editable: true
          },
          {
            name: 'center_groups',
            is_editable: true
          },
          {
            name: 'countries',
            is_editable: true
          },
          {
            name: 'funnel_scenario_id',
            is_editable: true
          },
          {
            name: 'system_funnel_mailings',
            is_editable: true
          }
        ],
        "mailings": [],
        "mailing_stops": []
      },
      newSiteFunnel: {
        "title": {
          en: "",
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        },
        "description": {
          en: "",
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        },
        "start_type": "start_on_fixed_date",
        "start_fix_date": Moment().format( 'YYYY-MM-DD' ),
        "start_date": null,
        "end_date": null,
        "has_expiry_date": false,
        "status": "ACTIVE",
        "do_stop_if_enter_funnel": true,
        "scenario_id": 1,
        center_editable_config: [
          {
            name: 'title',
            is_editable: true
          },
          {
            name: 'description',
            is_editable: true
          },
          {
            name: 'start_type',
            is_editable: true
          },
          {
            name: 'start_fix_date',
            is_editable: true
          },
          {
            name: 'start_date',
            is_editable: true
          },
          {
            name: 'end_date',
            is_editable: true
          },
          {
            name: 'has_expiry_date',
            is_editable: true
          },
          {
            name: 'center_groups',
            is_editable: true
          },
          {
            name: 'countries',
            is_editable: true
          },
          {
            name: 'funnel_scenario_id',
            is_editable: true
          },
          {
            name: 'system_funnel_mailings',
            is_editable: true
          }
        ],
        is_stop_if_enter_funnel: false,
        "mailings": [],
        "mailing_stops": []
      },
      showCreateSiteFunnel: false,
      showUpdateSiteFunnel: false,
      creatingFromTemplate: true,
      siteFunnelErrMsg: {},
      funnelTemplate: {},
      triggerTime: new Date(),
      allSiteFunnelSelection: [],
      showArchivedSiteFunnels: false,
      showArchiveSelectedSiteFunnel: false
    }

    onChangeFunnelsListingHOC = ( key, val ) => this.setState({ [key]: val }, () => {
      if( key === 'showCreateNewsletterModal' ){
        this.setState({ processType: 'create' })
      }
    })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error, {
      position: "bottom-right"
    })

    getAllSiteFunnel = ( query ) => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnels?query=${query}`,
      this.getAllSiteFunnelSuccess,
      this.getAllSiteFunnelError,
      this.load
    )
    getAllSiteFunnelSuccess = payload => {
      let tmp = []
      if( payload.data.length === 0 ){
        this.requestError( Lang['NO_RESULT_FOUND'][this.props.data.languageReducer.userSettings.admin_panel_language] )
      }
      payload.data.map( item => {
        tmp.push({
          ...item,
          label: item.title[this.props.data.languageReducer.userSettings.admin_panel_language],
          value: item.title[this.props.data.languageReducer.userSettings.admin_panel_language]
        })
      })
      let tmpPayload = {
        data: tmp,
        meta: payload.meta
      }
      this.setState({ siteFunnels: tmpPayload })
    }
    getAllSiteFunnelError = error => this.requestError( error )

    getAllSiteFunnelSelect = ( query ) => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnels?query=${query}`,
      this.getAllSiteFunnelSelectSuccess,
      this.getAllSiteFunnelSelectError,
      this.load
    )
    getAllSiteFunnelSelectSuccess = payload => {
      let tmp = []
      if( payload.data.length === 0 ){
        this.requestError( Lang['NO_RESULT_FOUND'][this.props.data.languageReducer.userSettings.admin_panel_language] )
      }
      payload.data.map( item => {
        tmp.push({
          ...item,
          label: item.title[this.props.data.languageReducer.userSettings.admin_panel_language],
          value: item.title[this.props.data.languageReducer.userSettings.admin_panel_language]
        })
      })
      let tmpPayload = {
        data: tmp,
        meta: payload.meta
      }
      this.setState({ allSiteFunnelSelection: tmpPayload })
    }
    getAllSiteFunnelSelectError = error => this.requestError( error )

    createSiteFunnel = (data, template_id ) => {
      if( template_id ) {
        Post(
          `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnels?template_funnel_id=${ template_id }`,
          data,
          this.createSiteFunnelSuccess,
          this.createSiteFunnelError,
          this.load
        )
      } else {
        Post(
          `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnels`,
          {
            ...data,
            center_id: this.props.data.selectCenterReducer.selectedCenter.center.id
          },
          this.createSiteFunnelSuccess,
          this.createSiteFunnelError,
          this.load
        )
      }
    }
    createSiteFunnelSuccess = () => {
      this.setState({ showCreateSiteFunnel: false })
      this.getAllSiteFunnel( 
        convertSearchObjectToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        }) 
      )
      this.requestSuccess( Lang['CREATE_SITE_FUNNEL_SUCCESS'][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
    }
    createSiteFunnelError = error => {
      this.setState({ siteFunnelErrMsg: error })
      this.requestError( error )
    }

    getSelectedSiteFunnel = ( id, isArchiving ) => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/funnels/${ id }?time_zone=${ Intl.DateTimeFormat().resolvedOptions().timeZone }`,
      payload => this.getSelectedSiteFunnelSuccess( payload, isArchiving ),
      this.getSelectedSiteFunnelError,
      this.load
    )
    getSelectedSiteFunnelSuccess = ( payload, isArchiving ) => {
      let tmp = []
      let tmpPayload = _.cloneDeep( payload )
      payload.mailing_stops.map( item => {
        tmp.push({
          ...item,
          label: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
          value: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ]
        })
      })
      tmpPayload.mailing_stops = tmp
      tmpPayload.start_fix_date = Moment( tmpPayload.start_fix_date ).format( 'YYYY-MM-DD' )
      this.setState({ 
        selectedSiteFunnel: isArchiving ? payload : tmpPayload,
        showArchiveSelectedSiteFunnel: isArchiving 
      })
    }
    getSelectedSiteFunnelError = error => this.requestError( error )

    updateSiteFunnel = (id,data) => Put(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnels/${id}`,
      data,
      this.updateSiteFunnelSuccess,
      this.updateSiteFunnelError,
      this.load
    )
    updateSiteFunnelSuccess = () => {
      this.getAllSiteFunnel( 
        convertSearchObjectToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: this.state.showArchivedSiteFunnels
              ? 'ARCHIVED'
              : { $neq: 'ARCHIVED' }
          }
        }) 
      )
      this.requestSuccess( Lang['UPDATE_SITE_FUNNEL_SUCCESS'][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ showArchiveSelectedSiteFunnel: false })
    }
    updateSiteFunnelError = error => {
      this.setState({ siteFunnelErrMsg: error })
      this.requestError( error )
    }

    setFunnelMailings = ( funnel_id, data ) => Put(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id}/funnels/${ funnel_id }/mailings?time_zone=${ Intl.DateTimeFormat().resolvedOptions().timeZone }`,
      data,
      this.setFunnelMailingsSuccess,
      this.setFunnelMailingsError,
      this.load
    )
    setFunnelMailingsSuccess = payload => {
      this.requestSuccess( Lang['UPDATE_SITE_FUNNEL_SUCCESS'][this.props.data.languageReducer.userSettings.admin_panel_language])
      this.getAllSiteFunnel( 
        convertSearchObjectToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        }) 
      )
      if( this.state.showUpdateSiteFunnel ){
        let tmp = []
        let tmpPayload = _.cloneDeep( payload )
        payload.mailing_stops.map( item => {
          tmp.push({
            ...item,
            label: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ],
            value: item.title[ this.props.data.languageReducer.userSettings.admin_panel_language ]
          })
        })
        tmpPayload.mailing_stops = tmp
        tmpPayload.start_fix_date = Moment( tmpPayload.start_fix_date ).format( 'YYYY-MM-DD' )
        this.setState({ selectedSiteFunnel: tmpPayload })
      } 
    }

    setFunnelMailingsError = error => {
      this.setState({ siteFunnelErrMsg: error })
      this.requestError( error )
    }

    funnelTesting = (funnel_id) => Post(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnels/${funnel_id}/test`,
      {
        trigger_time: this.state.triggerTime
      },
      this.funnelTestingSuccess,
      this.funnelTestingError,
      this.load
    )
    funnelTestingSuccess = () => this.requestSuccess( 'Funnel is triggered.' )
    funnelTestingError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadSiteFunnels={ this.state.loading }
          getAllSiteFunnel={ this.getAllSiteFunnel }
          getAllSiteFunnelSelect={ this.getAllSiteFunnelSelect }
          siteFunnels={ this.state.siteFunnels }
          selectedSiteFunnel={ this.state.selectedSiteFunnel }
          newSiteFunnel={ this.state.newSiteFunnel }
          showCreateSiteFunnel={ this.state.showCreateSiteFunnel }
          showUpdateSiteFunnel={ this.state.showUpdateSiteFunnel }
          onChangeFunnelsListingHOC={ this.onChangeFunnelsListingHOC }
          creatingFromTemplate={ this.state.creatingFromTemplate }
          createSiteFunnel={ this.createSiteFunnel }
          siteFunnelErrMsg={ this.state.siteFunnelErrMsg }
          getSelectedSiteFunnel={ this.getSelectedSiteFunnel }
          updateSiteFunnel={ this.updateSiteFunnel }
          funnelTemplate={ this.state.funnelTemplate }
          setFunnelMailings={ this.setFunnelMailings }
          siteFunnelSearchParams={ this.state.siteFunnelSearchParams  }
          funnelTesting={ this.funnelTesting }
          allSiteFunnelSelection={ this.state.allSiteFunnelSelection }
          showArchivedSiteFunnels={ this.state.showArchivedSiteFunnels }
          showArchiveSelectedSiteFunnel={ this.state.showArchiveSelectedSiteFunnel } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
