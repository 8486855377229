import React from 'react'
import { Route } from 'react-router-dom'

import OLSSettings from './Settings'
import OLSListings from './Campaigns'
import CompletedBookedCampaign from './CompletedBookedCampaign'

const OLS = () => {
  return (
    <>
      <Route exact path="/dashboard/ols/settings" component={ OLSSettings } />
      <Route exact path="/dashboard/ols/listings" component={ OLSListings } />
      <Route exact path="/dashboard/ols/completed-campaigns" component={ CompletedBookedCampaign } />
    </>
  )
}

export default OLS
