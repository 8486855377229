import React, { useState, useEffect } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { connect } from 'react-redux'

import Lang from 'Lang/General'

const ArkPagination = ({
  currentPage,
  totalPages,
  onChangePage,
  pages,
  showPageName,
  currentLanguage
}) => {
  const [ interval, updateInterval ] = useState( 10 )

  useEffect( () => {
    if ( currentPage <= ( interval - 10 ) ) {
      updateInterval( interval - 10 )
    } else if ( currentPage > interval ) {
      updateInterval( interval + 10 )
    }
  }, [ currentPage ] )

  const renderPageName = ( pg ) => {
    let pageName = pages[ pg-1 ][ 'name' ][ currentLanguage ].replace(/<\/?[^>]+(>|$)/g, "")
    if ( pageName != "" && pageName != Lang[ 'PAGE' ][ currentLanguage ] ) {
      return pageName 
    } else {
      return `${ Lang[ 'PAGE' ][ currentLanguage ] } ${ pg }`
    }
  }

  return(
    <div className='w-100' style={{ alignItems: 'center', justifyContent: 'center' }}>
      <div className="d-flex flex-wrap justify-content-end w-100">
        {
          currentPage !== 1 && (
            <Button
              className="mb-2 mr-2 btn-icon btn-icon-only"
              onClick={ () => onChangePage( currentPage = currentPage - 1 ) }
              color="light">
              <i className="pe-7s-angle-left btn-icon-wrapper"/>
            </Button> 
          )
        }
        <Button
          className="mb-2 mr-2 btn-icon"
          onClick={ () => updateInterval( interval - 10 ) }
          color="light"
          style={{
          display: `${ interval <= 10 ? 'none' : '' }`
          }}>
          ...
        </Button>
        {
          totalPages && totalPages.map( pg => {
            if(( pg > interval - 10 ) && ( pg <= interval )){
              if ( showPageName ) {
                return (
                  <button
                    id={`pagination-page-${pg}`} 
                    className={`mb-2 mr-2 btn-icon form-pagination-btn ${ currentPage === pg && "activate-page-btn" }`}
                    onClick={ () =>  onChangePage( currentPage = pg ) }>
                    { renderPageName( pg ) }
                  </button> 
                )
              } else {
                return(
                  <>
                    <button
                      id={`pagination-page-no-${pg}`}
                      className={`mb-2 mr-2 btn-icon form-pagination-btn ${ currentPage === pg && "activate-page-btn" }`}
                      onClick={ () =>  onChangePage( currentPage = pg ) }>
                      { pg }
                    </button>
                    {
                      ( !pages[ pg-1 ][ 'mapWith' ] || pages[ pg-1 ][ 'mapWith' ] === "form" ) && (
                        <UncontrolledTooltip
                          target={`pagination-page-no-${pg}`} 
                          placement="top">
                          { renderPageName( pg ) }
                        </UncontrolledTooltip>
                      )
                    }
                  </>
                )
              }
            }
          })
        }
        <Button
          className="mb-2 mr-2 btn-icon"
          onClick={ () => updateInterval( interval + 10 ) }
          color="light"
          style={{ display: `${ interval > totalPages.length ? 'none' : '' }` }}>
          ...
        </Button>
        {
          currentPage !== totalPages.length && (
            <Button
              className="mb-2 mr-2 btn-icon btn-icon-only"
              onClick={ () => onChangePage( currentPage = currentPage + 1 ) }
              color="light">
              <i className="pe-7s-angle-right btn-icon-wrapper"/>
            </Button>
          )
        }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({ data: state })

const mapDispatchToProps = dispatch => ({})

export default connect( mapStateToProps, mapDispatchToProps )(ArkPagination)
