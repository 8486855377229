import {
  BEGIN_AJAX_CALL,
  AJAX_CALL_SUCCESS,
  AJAX_CALL_ERROR
} from "./type";

export const beginAjaxCall = () => {
  return {
    type: BEGIN_AJAX_CALL
  };
};

export const ajaxCallSuccess = () => {
  return {
    type: AJAX_CALL_SUCCESS
  };
};

export const ajaxCallError = ( payload ) => {
  return {
    type: AJAX_CALL_ERROR,
    payload
  };
};
