import React from 'react'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import ReactSelect from 'react-select'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const RecipientsForm = ({
  data,
  onChangeRecipientHOC,
  headerText,
  toggle,
  isOpen,
  status,
  mode,
  onSubmit,
  recipientGroup,
  errorMessage,
  selectedLanguage,
  onLoadRecipientGroup,
  onLoadRecipients
}) => {
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( data )
    let dataKey = ''
    let tmpIDs = []
    tmp[key] = value

    if( mode === 'edit' ){
      dataKey = 'selectedRecipient'
    }else{
      dataKey = 'newRecipientData'
    }

    onChangeRecipientHOC( dataKey, tmp )
  }
  let groupOptions = []
  recipientGroup && recipientGroup.map( item => {
    groupOptions.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })
  const processData = () => {
    let tmpValue = []
    data.recipient_groups && data.recipient_groups.map( item => {
      tmpValue.push(
        {
          id: item.id,
          label: item.name,
          value: item.name
        }
      )
    })
    return tmpValue
  }
  return(
    <Modal
      toggle={ toggle }
      isOpen={ isOpen }
      size="xl">
      <ModalHeader toggle={ toggle }>{ headerText }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 12 }>
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>{ Lang[ 'EMAIL' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'email' }
                        value={ data.email }
                        onChange={ e => onChangeData( 'email', e.target.value ) }
                        invalid={ errorMessage && errorMessage.email ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="salutation">{ Lang[ 'SALUTATION' ][ selectedLanguage ] }</Label>
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        value={ data.salutation }
                        onChange={ e => onChangeData( 'salutation', e.target.value ) }>
                        <option>{ Lang[ 'MR' ][ selectedLanguage ] }</option>
                        <option>{ Lang[ 'MRS' ][ selectedLanguage ] }</option>
                        <option>{ Lang[ 'MR_MRS' ][ selectedLanguage ] }</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'FIRST_NAME' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ data.first_name }
                        onChange={ e => onChangeData( 'first_name', e.target.value ) }
                        invalid={ errorMessage && errorMessage.first_name ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.first_name ? errorMessage.first_name : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'LAST_NAME' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ data.last_name }
                        onChange={ e => onChangeData( 'last_name', e.target.value ) }
                        invalid={ errorMessage && errorMessage.last_name ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.last_name ? errorMessage.last_name : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'MOBILE_NUMBER' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ data.mobile_number }
                        onChange={ e => onChangeData( 'mobile_number', e.target.value ) }
                        invalid={ errorMessage && errorMessage.mobile_number ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.mobile_number ? errorMessage.mobile_number : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="status">{ Lang[ 'STATUS' ][ selectedLanguage ] }</Label>
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        invalid={ /*errorMessage && errorMessage.status ? true :*/ false }
                        value={ data.status }
                        onChange={ e => onChangeData( 'status', e.target.value ) }>
                        {
                          status && Object.keys( status ).map( key => {
                            return (
                              <option key={ key }>{ Lang[ status[key] ][ selectedLanguage ] }</option>
                            )
                          })
                        }
                      </Input>
                      <FormFeedback>{ /*errorMessage && errorMessage.status ? errorMessage.status : '' */}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <ReactSelect
                        isMulti
                        name="select"
                        options={ groupOptions }
                        className="search-select"
                        classNamePrefix="select"
                        onChange={ selectedValue => {
                          let tmp = []
                          selectedValue &&  selectedValue.map( item => {
                            tmp.push(
                              {
                                id: item.id,
                                name: item.label,
                              }
                            )
                          })
                          onChangeData( 'recipient_groups', tmp )
                        }}
                        value={ processData() }/>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        { ( onLoadRecipients || onLoadRecipientGroup ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ onSubmit }>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
        <Button color="primary" onClick={ toggle }>{ Lang[ 'CANCEL' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default RecipientsForm
