import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      latestCampaigns: [],
      allCampaigns: [],
      selectedCampaign: {},
      selectedBookedCampaign: {},
      budgetData: {},
      presentBookedCampaign: false,
      campaignsCenterId: 1,
      bookedCampaignError: '',
      showAll: false,
      showViewInfoModal: false,
      showLandingPageModal: false,
      showChangeModal: false,
      showBookModal: false,
      showAlertModal: false,
      uploadedMedia: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeLatestCampaignHOC = ( key, value ) => this.setState({ [key]: value }, () => {
      if( key === 'campaignsCenterId' ){
        this.getLatestCampaign( false )
      }
    })

    getLatestCampaign = ( isOneTimer ) => Get(
      `/api/v1/ols_dashboard${ isOneTimer
        ? `` : `?center_id=${ this.state.campaignsCenterId }` }`,
      this.getLatestCampaignSuccess,
      this.getLatestCampaignError,
      this.load
    )
    getLatestCampaignSuccess = payload => {
      let tmp = _.cloneDeep( payload )
      if( _.find( tmp, { is_booked: true }) ) {
        this.setState({ presentBookedCampaign: true })
      } else {
        this.setState({ presentBookedCampaign: false })
      }
      let tmpLatest = []
      let bookedCampaigns = _.filter( payload, { is_booked: true })
      if ( bookedCampaigns && bookedCampaigns.length > 0 ) {
        bookedCampaigns.map( item => {
          _.remove( payload, { id: item.id, is_booked: false } )
        })
      }
      payload.map( ( item, index ) => {
        if( index < 5 ) {
          tmpLatest.push( item )
        }
      })
      this.setState({ allCampaigns: payload, latestCampaigns: tmpLatest })
    }
    getLatestCampaignError = error => this.requestError( error )

    getSelectedCampaign = id => Get(
      `/api/v1/ols_campaigns/${ id }`,
      this.getSelectedCampaignSuccess,
      this.getSelectedCampaignError,
      this.load
    )
    getSelectedCampaignSuccess = payload => this.setState({ selectedCampaign: payload, bookedCampaignError: {} })
    getSelectedCampaignError = error => this.requestError( error )

    createBookedCampaign = ( data, isOneTimer ) => Post(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.campaignsCenterId }` }/ols_booked_campaigns`,
      data,
      () => this.createBookedCampaignSuccess( isOneTimer ),
      this.createBookedCampaignError,
      this.load
    )
    createBookedCampaignSuccess = isOneTimer => {
      this.setState({ showBookModal: false })
      this.getLatestCampaign( isOneTimer )
    }
    createBookedCampaignError = error => this.setState({ bookedCampaignError: error })

    getSelectedBookedCampaign = ( booked_campaign_id ) => Get(
      `/api/v1/centers/${ this.state.campaignsCenterId }/ols_booked_campaigns/${ booked_campaign_id }`,
      this.getSelectedBookedCampaignSuccess,
      this.getSelectedBookedCampaignError,
      this.load
    )
    getSelectedBookedCampaignSuccess = payload => this.setState({ selectedBookedCampaign: payload })
    getSelectedBookedCampaignError = error => this.requestError( error )

    updateBudget = ( selectedBookedCampaignID, isOneTimer ) => Put(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.campaignsCenterId }` }/ols_booked_campaigns/${ selectedBookedCampaignID }`,
      {
        fa_budget: this.state.selectedBookedCampaign.fa_budget,
        ga_budget: this.state.selectedBookedCampaign.ga_budget
      },
      () => this.updateBudgetSuccess( isOneTimer ),
      this.updateBudgetError,
      this.load
    )
    updateBudgetSuccess = isOneTimer => {
      this.setState({ showChangeModal: false })
      this.getLatestCampaign( isOneTimer )
    }
    updateBudgetError = error => this.requestError( error )

    getOLSCampaignSettings = ( isOneTimer ) => Get(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ this.state.campaignsCenterId }` }/ols_settings`,
      this.getOLSSettingsSuccess,
      this.getOLSSettingsError,
      this.load
    )
    getOLSSettingsSuccess = payload => {
      let tmp = _.cloneDeep( this.state.budgetData )
      tmp.fa_budget = payload.monthly_fb_click_through_budget
      tmp.ga_budget = payload.monthly_google_budget
      this.setState({ budgetData: tmp })
    }
    getOLSSettingsError = () => this.setState({ showEmptySettings: true })

    createSubmittedFormMedia = ( formContentId, data ) => Post(
      `/api/v1/digital_order_forms_media`,
      data,
      payload => this.createSubmittedFormMediaSuccess( formContentId, data, payload ),
      this.createSubmittedFormMediaError,
      this.load
    )
    createSubmittedFormMediaSuccess = ( formContentId, data, payload ) => {
      let tmpUploadedMedia = _.cloneDeep( this.state.uploadedMedia )
      tmpUploadedMedia.push( {
        id: payload.id,
        formContentId: formContentId,
        url: payload.url,
        filename: data.name
      })
      this.setState({ uploadedMedia: tmpUploadedMedia })
    }
    createSubmittedFormMediaError = error => this.requestError( error )

    removeSubmittedFormMedia = ( id ) => {
      let tmpUploadedMedia = _.cloneDeep( this.state.uploadedMedia )
      _.remove(tmpUploadedMedia, ( media ) => {
        return media.id === id
      })
      this.setState({ uploadedMedia: tmpUploadedMedia })
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            getLatestCampaign={ this.getLatestCampaign }
            getSelectedCampaign={ this.getSelectedCampaign }
            getSelectedBookedCampaign={ this.getSelectedBookedCampaign }
            createBookedCampaign={ this.createBookedCampaign }
            updateBudget={ this.updateBudget }
            latestCampaigns={ this.state.latestCampaigns }
            allCampaigns={ this.state.allCampaigns }
            selectedCampaign={ this.state.selectedCampaign }
            selectedBookedCampaign={ this.state.selectedBookedCampaign }
            onLoadLatestCampaign={ this.state.loading }
            presentBookedCampaign={ this.state.presentBookedCampaign }
            campaignsCenterId={ this.state.campaignsCenterId }
            bookedCampaignError={ this.state.bookedCampaignError }
            budgetData={ this.state.budgetData }
            showAll={ this.state.showAll }
            showViewInfoModal={ this.state.showViewInfoModal }
            showLandingPageModal={ this.state.showLandingPageModal }
            showChangeModal={ this.state.showChangeModal }
            showBookModal={ this.state.showBookModal }
            showAlertModal={ this.state.showAlertModal }
            onChangeLatestCampaignHOC={ this.onChangeLatestCampaignHOC }
            getOLSCampaignSettings={ this.getOLSCampaignSettings }
            uploadedMedia={ this.state.uploadedMedia }
            createSubmittedFormMedia={ this.createSubmittedFormMedia }
            removeSubmittedFormMedia={ this.removeSubmittedFormMedia } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
