import React, { Component } from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel'

import NewsHOC from './actions'
import ViewInfo from './View'
import ViewAll from './ViewAll'

import Lang from 'Lang/General'
import LangTepe from 'Lang/TePe'

import defaultLogo from 'assets/images/logo-aciso.png'

let TmpLang = window.location.host.indexOf( 'tennis' ) > -1 ? LangTepe : Lang

class News extends Component {
  componentDidMount = async () => {
    await this.props.getAllNews()
    if ( window.location.href.indexOf( 'all-news' ) > -1 ) {
      this.props.onChangeNewsHOC( 'showAllNews', true )
    }
    window.addEventListener('hashchange', () => {
      if ( window.location.href.indexOf( 'all-news' ) > -1 ) {
        this.props.onChangeNewsHOC( 'showAllNews', true )
      }
    })
  }
  render = () => {
    return(
      <>
        <div className="carousel-container">
          <div className="custom-card mb-2" style={{ height: '67px', flex: "0" }}>
            <span className="main-section-title">{ TmpLang[ 'NEWS' ][ this.props.lang ] }</span>
          </div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={110}
            isIntrinsicHeight={ true }
            totalSlides={ this.props.allNews ? Math.min( this.props.allNews.length, 5 ): 0 }>
            <Slider>
              {
                this.props.allNews && this.props.allNews.map( ( news, index ) => {
                  if( index < 5 ) {
                    return(
                      <Slide index={ index } key={ index }>
                        <div className="custom-card">
                          <div className="custom-card-img-container" >
                            <img
                              className="custom-card-img"
                              src={ news.image_url || defaultLogo } 
                              alt="" 
                            />
                          </div>
                          <div className="custom-card-body">
                            <div>
                              <span className="custom-card-title">{ news.name[ this.props.lang ] }</span>
                              <div>
                                { 
                                  news.description[ this.props.lang ] && (
                                    news.description[ this.props.lang ].replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '').length > 150
                                    ? news.description[ this.props.lang ].replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '').substr(0, 147) + '...' 
                                    : news.description[ this.props.lang ].replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')
                                  )
                                }
                              </div>
                            </div>
                          </div>
                          <div className="custom-card-footer">
                            <button
                              className="outline-btn--secondary"
                              onClick={ () => {
                                this.props.onChangeNewsHOC( 'showInfo', true )
                                this.props.getSelectedNews( news.id )
                              }}>{ Lang[ 'GENERAL_INFO' ][ this.props.lang ] }
                            </button>
                          </div>
                        </div>
                      </Slide>
                    )
                  }
                })
              }
            </Slider>
            <div className="carousel-button-group">
              <ButtonBack className="transparent-btn">
                <i className="pe-7s-angle-left" />
              </ButtonBack>
              <div className="carousel-dot-list">
              {
                this.props.allNews.map( (item, index) => {
                  if ( index < 5 ) {
                    return(
                      <Dot slide={ index } className=""/>
                    )
                  }
                })
              }
              </div>
              <ButtonNext className="transparent-btn">
                <i className="pe-7s-angle-right" />
              </ButtonNext>
            </div>
          </CarouselProvider>
          <div className="d-flex justify-content-center">
            <button
              className="transparent-btn"
              style={{
                color: 'initial',
                textDecoration: 'underline',
                margin: 'auto'
              }}
              onClick={ () => {
                this.props.onChangeNewsHOC( 'showAllNews', true )
              }}>
              { Lang[ 'NEWS' ][ this.props.lang ] }
            </button>
          </div>
        </div>
        <ViewInfo
          data={ this.props.data }
          selectedNews={ this.props.selectedNews }
          showInfo={ this.props.showInfo }
          onChangeNewsHOC={ this.props.onChangeNewsHOC }
          lang={ this.props.lang } />
        <ViewAll
          data={ this.props.data }
          allNews={ this.props.allNews }
          showAllNews={ this.props.showAllNews }
          onChangeNewsHOC={ this.props.onChangeNewsHOC }
          getSelectedNews={ this.props.getSelectedNews }
          lang={ this.props.lang } />
      </>
    )
  }
}

export default NewsHOC( News )
