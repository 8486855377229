import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Put } from 'utils/axios-template'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      currentOffer: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string' 
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )
    
    onChangeCurrentOfferHOC = ( key, val ) => this.setState({ [ key ]: val })

    getCurrentOffer = location_id => Get(
      `/api/v1/locations/${ location_id }/leadhub_current_offers`,
      this.getCurrentOfferSuccess,
      this.getCurrentOfferError,
      this.load
    )
    getCurrentOfferSuccess = payload => this.setState({ currentOffer: payload })
    getCurrentOfferError = error => this.requestError( error )

    updateCurrentOffer = ( location_id, data ) => Put(
      `/api/v1/locations/${ location_id }/leadhub_current_offers`,
      data,
      this.updateCurrentOfferSuccess,
      this.updateCurrentOfferError,
      this.load
    )
    updateCurrentOfferSuccess = payload => this.requestSuccess( 'Current offer is successfully updated' )
    updateCurrentOfferError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getCurrentOffer={ this.getCurrentOffer }
          updateCurrentOffer={ this.updateCurrentOffer }
          onLoadCurrentOffer={ this.state.loading }
          currentOffer={ this.state.currentOffer }
          onChangeCurrentOfferHOC={ this.onChangeCurrentOfferHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC