import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import { Get } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreateRecipientGroup: false,
      loading: false,
      recipientGroups: [],
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getRecipientGroup = ( query ) => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups?query=${ query }`,
        this.getRecipientGroupSuccess,
        this.getRecipientGroupError,
        this.load
      )
    }
    getRecipientGroupSuccess = payload => {
      let tmpRecipientGroupForSelect = []
      payload.data.map( item => {
        tmpRecipientGroupForSelect.push({
          ...item,
          value: item.id,
          label: item.name
        })
      })

      this.setState({ recipientGroups: tmpRecipientGroupForSelect })
    }
    getRecipientGroupError = error => this.requestError( error.toString() )

    render = () => {
      return(
        <WrappedComponent
          {...this.props}
          getRecipientGroup={ this.getRecipientGroup }
          recipientGroups={ this.state.recipientGroups }
          onLoadRecipientGroup={ this.state.loading } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}
export default HOC
