import React, { Component } from "react";
import _ from "lodash";
import { Row, Col, Button } from "reactstrap";

import KeyFigures from "./Figures";
import ShortcutMenus from "./Shortcut";
import Campaigns from "./LatestCampaigns";
import News from "./News";
import Products from "./Products";

import AssignShortcutMenu from "./Components/AssignShortcut";

import { getToken } from "utils/findCookieToken";

import Lang from "Lang/General";
import LangTepe from "Lang/TePe";

import WithHOC from "./actions";
import "stylesheets/layout/main/index.scss";

let TmpLang = window.location.host.indexOf("tennis") > -1 ? LangTepe : Lang;

class Dashboard extends Component {
	state = {
		showProfileModal: false,
	};

	componentDidMount = () => {
		if (getToken("ACISO_SESSION_TOKEN").length <= 2) {
			this.props.history.push("/");
		} else {
			this.props.getUserProfile();
			this.props.getUserSetting();
		}
	};

	componentDidUpdate = (pp) => {
		if (
			this.props.data.profileReducer.profile.role_id !==
			pp.data.profileReducer.profile.role_id
		) {
			if (this.props.data.profileReducer.profile.role_id > 0) {
				this.props.getSelectedRole(
					this.props.data.profileReducer.profile.role_id
				);
			}
		}
	};

	onClickToggleProfileModal = () =>
		this.setState({ showProfileModal: !this.state.showProfileModal });

	render = () => {
		return (
			<>
				<div className="p-2">
					<div
            className="d-block d-lg-flex align-items-end mb-4"
						style={{
							marginBottom: "0.5rem",
						}}>
						<div style={{ display: "inline-block", width: "9.5rem" }} className="mr-4">
							<span className="main-title">
								{
									TmpLang["DASHBOARD"][
										this.props.data.languageReducer.userSettings
											.admin_panel_language
									]
								}
							</span>
							<Button
								style={{
									background: "#EB641A",
									color: "#fff",
                  width: "100%",
									fontSize: "10px",
									border: "none",
								}}
								onClick={() => this.props.onChangeMainHOC("showAssign", true)}>
								{
									Lang["EDIT"][
										this.props.data.languageReducer.userSettings
											.admin_panel_language
									]
								}
							</Button>
						</div>
            <div className="d-flex flex-wrap mt-2 mt-lg-0" style={{ gap: '0.5rem 0.8rem' }}>
              <ShortcutMenus
                data={this.props.data}
                history={this.props.history}
                lang={
                  this.props.data.languageReducer.userSettings
                    .admin_panel_language
                }
              />
            </div>
					</div>
					{/* <div className="main-dropdown_date">
            <select  
              name="Date" 
              id="date">
                <option value="month">Current Month</option>
                <option value="year">Current Year</option>
            </select>
          </div> */}
					<KeyFigures
						lang={
							this.props.data.languageReducer.userSettings.admin_panel_language
						}
					/>
					<Row className="mx-0">
						<Col className="my-3 px-1" xl="4" lg="6" md="6">
							<Campaigns
								data={this.props.data}
								history={this.props.history}
								lang={
									this.props.data.languageReducer.userSettings
										.admin_panel_language
								}
							/>
						</Col>
						<Col className="my-3 px-1" xl="4" lg="6" md="6">
							<Products
								data={this.props.data}
								history={this.props.history}
								lang={
									this.props.data.languageReducer.userSettings
										.admin_panel_language
								}
							/>
						</Col>
						<Col className="my-3 px-1" xl="4" lg="6" md="6">
							<News
								data={this.props.data}
								history={this.props.history}
								lang={
									this.props.data.languageReducer.userSettings
										.admin_panel_language
								}
							/>
						</Col>
					</Row>
					<AssignShortcutMenu
						onChangeMainHOC={this.props.onChangeMainHOC}
						showAssign={this.props.showAssign}
						allMenus={this.props.data.shortcutReducer.sidemenu}
						sidemenu={this.props.data.shortcutReducer.sidemenu}
						assignedSidemenu={this.props.data.shortcutReducer.assignedSidemenu}
						storeSidemenu={this.props.storeSidemenu}
						storeAssignedSidemenu={this.props.storeAssignedSidemenu}
						lang={
							this.props.data.languageReducer.userSettings.admin_panel_language
						}
					/>
				</div>
			</>
		);
	};
}

export default WithHOC(Dashboard);
