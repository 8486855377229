import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'

import { Get, Post, Put } from 'utils/axios-token'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      recipientLists: [],
      viewedRecipientLists: {
        data: [],
        meta: {}
      },
      selectedRecipient: {},
      showEditRecipientModal: false,
      allRecipients: [],
      assignedRecipients: []
    }

    onChangeRecipientHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getAllRecipients = query => Get(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipients?query=${ query }`,
      this.getAllRecipientsSuccess,
      this.getAllRecipientsError,
      this.load
    )
    getAllRecipientsSuccess = payload => {
      let tmpPayload = []
      payload.data.length > 0 && payload.data.map( item => {
        if( !_.find( this.state.recipientLists, { id: item.id }) ){
          tmpPayload.push({
            id: item.id,
            label: item.email,
            value: item.email
          })
        }
      })
      this.setState({ allRecipients: tmpPayload })
    }
    getAllRecipientsError = error => this.requestError( error )

    postRecipientIntoGroup = group_id => Post(
      `/api/v1/recipient_groups/${ group_id }/recipients`,
      { recipients: this.state.assignedRecipients },
      () => this.postRecipientIntoGroupSuccess( group_id ),
      this.postRecipientIntoGroupError,
      this.load
    )
    postRecipientIntoGroupSuccess = group_id => {
      this.requestSuccess( Lang[ 'ADDED_RECIPIENT_INTO_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ assignedRecipients: [] })
      this.getRecipientLists( convertSearchObjectToBase64({ is_paginated: false, is_blacklisted: false }), group_id )
    }
    postRecipientIntoGroupError = error => this.requestError( error )

    getRecipientLists = ( query, id ) => Get(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipient_groups/${ id }/recipients?query=${ query }`,
      this.getRecipientListsSuccess,
      this.getRecipientListsError,
      this.load
    )
    getRecipientListsSuccess = payload => {
      this.setState({ 
        recipientLists: payload.data || [], 
        viewedRecipientLists: payload 
      })
    }
    getRecipientListsError = error => this.requestError( error )

    getSelectedRecipient = id => {
      Get(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipients/${ id }`,
        this.getSelectedRecipientSuccess,
        this.getSelectedRecipientError,
        this.load
      )
    }
    getSelectedRecipientSuccess = payload => this.setState({ selectedRecipient: payload })
    getSelectedRecipientError = error => this.requestError( error )

    updateRecipient = ( query, id ) => {
      Put(
        `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/recipients/${ this.state.selectedRecipient.id }`,
        this.state.selectedRecipient,
        () => this.updateRecipientSuccess( query, id ),
        this.updateRecipientError,
        this.load
      )
    }
    updateRecipientSuccess = ( query, id ) => {
      this.requestSuccess( Lang[ 'RECEIVER_UPDATE_SUCCESS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ showEditRecipientModal: false })
      this.getRecipientLists( query, id )
    }
    updateRecipientError = error => this.setState({ errorMessage: error })

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadRecipients={ this.state.loading }
          getRecipientLists={ this.getRecipientLists }
          recipientLists={ this.state.recipientLists }
          viewedRecipientLists={ this.state.viewedRecipientLists }
          getSelectedRecipient={ this.getSelectedRecipient }
          updateRecipient={ this.updateRecipient }
          selectedRecipient={ this.state.selectedRecipient }
          showEditRecipientModal={ this.state.showEditRecipientModal }
          onChangeRecipientHOC={ this.onChangeRecipientHOC }
          allRecipients={ this.state.allRecipients }
          getAllRecipients={ this.getAllRecipients }
          assignedRecipients={ this.state.assignedRecipients }
          postRecipientIntoGroup={ this.postRecipientIntoGroup } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
