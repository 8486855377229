import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import _ from 'lodash'
import Lang from 'Lang/General'

const languages = [ 'en', 'de', 'nl', 'it' ]

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#fff" : "transparent",
  height: '100%'
})

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "#F2F4F6" : "transparent",
  // styles we need to apply on draggables
  ...draggableStyle
})

const reorder = ( list, startIndex, endIndex ) => {
  const result = Array.from( list )
  const [removed] = result.splice( startIndex, 1 )
  result.splice( endIndex, 0, removed )
  return result
}

const defaultShortcutMenu = [
  {
    "icon": "pe-7s-map-marker",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'LOCAL_LISTINGS' ][ language ]}`
    }), {}),
    "to": "#/dashboard/local-listing/locations"
  },
  {
    "icon": "pe-7s-ticket",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'CAMPAIGNS' ][ language ]}`
    }), {}),
    "to": "#/dashboard/ols/listings"
  },
  {
    "icon": "pe-7s-news-paper",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'MY_ORDERS' ][ language ]}`
    }), {}),
    "to": "#/dashboard/digital-order-form/listings/submitted_forms"
  },
  {
    "icon": "pe-7s-news-paper",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'NEWSLETTER' ][ language ]}`
    }), {}),
    "to": "#/dashboard/newsletter"
  },
  {
    "icon": "pe-7s-add-user",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'RECIPIENTS' ][ language ]}`
    }), {}),
    "content": [
      {
        "icon": "pe-7s-add-user",
        "label": Lang[ 'ACTIVE' ],
        "to": "#/dashboard/newsletter-active-recipients"
      },
      {
        "icon": "pe-7s-add-user",
        "label": Lang[ 'INACTIVE' ],
        "to": "#/dashboard/newsletter-inactive-recipients"
      },
      {
        "icon": "pe-7s-way",
        "label": Lang[ 'DOI' ],
        "to": "#/dashboard/newsletter-doi-recipients"
      },
      {
        "icon": "pe-7s-shield",
        "label": Lang[ 'BLACKLISTS' ],
        "to": "#/dashboard/newsletter-blacklisted-recipients"
      },
      {
        "icon": "pe-7s-cloud-upload",
        "label": Lang[ 'BULK_UPLOAD' ],
        "to": "#/dashboard/newsletter-bulk-upload"
      }
    ]
  },
  {
    "icon": "pe-7s-disk",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'TEMPLATE' ][ language ]}`
    }), {}),
    "to": "#/dashboard/newsletter-templates"
  },
  {
    "icon": "pe-7s-home",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'LEAD_CENTRE' ][ language ]}`
    }), {}),
    "to": "https://leadcenter.aciso-suite.com/login",
    "externalLink": true
  },
  {
    "icon": "pe-7s-display2",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'MULTITRAINING' ][ language ]}`
    }), {}),
    "to": "https://multitraining.fitness/",
    "externalLink": true
  },
  {
    "icon": "pe-7s-video",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'EXPLANATORY_VIDEOS' ][ language ]}`
    }), {}),
    "to": ""
  },
  {
    "icon": "pe-7s-graph",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'REPORTS' ][ language ]}`
    }), {}),
    "to": ""
  }
]

const defaultTePeShortcutMenu = [
  {
    "icon": "pe-7s-ticket",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'CAMPAIGNS' ][ language ]}`
    }), {}),
    "to": "#/dashboard/ols/listings"
  },
  {
    "icon": "pe-7s-news-paper",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'NEWSLETTER' ][ language ]}`
    }), {}),
    "to": "#/dashboard/newsletter"
  },
  {
    "icon": "pe-7s-add-user",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'RECIPIENTS' ][ language ]}`
    }), {}),
    "content": [
      {
        "icon": "pe-7s-add-user",
        "label": Lang[ 'ACTIVE' ],
        "to": "#/dashboard/newsletter-active-recipients"
      },
      {
        "icon": "pe-7s-add-user",
        "label": Lang[ 'INACTIVE' ],
        "to": "#/dashboard/newsletter-inactive-recipients"
      },
      {
        "icon": "pe-7s-way",
        "label": Lang[ 'DOI' ],
        "to": "#/dashboard/newsletter-doi-recipients"
      },
      {
        "icon": "pe-7s-shield",
        "label": Lang[ 'BLACKLISTS' ],
        "to": "#/dashboard/newsletter-blacklisted-recipients"
      },
      {
        "icon": "pe-7s-cloud-upload",
        "label": Lang[ 'BULK_UPLOAD' ],
        "to": "#/dashboard/newsletter-bulk-upload"
      }
    ]
  },
  {
    "icon": "pe-7s-disk",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]:  `${Lang[ 'TEMPLATE' ][ language ]}`
    }), {}),
    "to": "#/dashboard/newsletter-templates"
  },
  {
    "icon": "pe-7s-home",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'LEAD_CENTRE' ][ language ]}`
    }), {}),
    "to": "https://leadcenter.aciso-suite.com/login",
    "externalLink": true
  },
  {
    "icon": "pe-7s-video",
    "label": languages.reduce((obj, language) => ({ 
      ...obj, [language]: `${Lang[ 'EXPLANATORY_VIDEOS' ][ language ]}`
    }), {}),
    "to": ""
  }
]

class AssignShortcutMenu extends Component {
  state = {
    selectedShortcutMenus: [],
    allMenus: []
  }
  
  componentDidMount = () => {
    if( this.props.assignedSidemenu.length === 0 || !this.checkIfSideMenuIsInCorrectFormat() ) {
      if( window.location.host.indexOf( 'tennis' ) > -1 ) {
        this.props.storeAssignedSidemenu( defaultTePeShortcutMenu )
      } else {
        this.props.storeAssignedSidemenu( defaultShortcutMenu )
      }
    }
  }

  componentDidUpdate = pp => {
    if( this.props.showAssign !== pp.showAssign ) {
      if( this.props.showAssign ) {
        let tmp = _.cloneDeep( this.props.allMenus )
        this.props.assignedSidemenu && this.props.assignedSidemenu.length > 0 && this.props.assignedSidemenu.map( item => {
          _.remove( tmp, { label: { [this.props.lang]: item.label[this.props.lang] } })
        })
        this.props.storeSidemenu( tmp )
        this.setState({ allMenus: tmp, selectedShortcutMenus: this.props.assignedSidemenu })
      }
    }
  }

  checkIfSideMenuIsInCorrectFormat = () => {
    let isCorrect = true
    for (let i = 0; i < this.props.assignedSidemenu.length; i++ ) {
      if ( typeof this.props.assignedSidemenu[i].label !== 'object' ) {
        isCorrect = false
        break
      }
    }
    return isCorrect
  }

  onDragEnd = result => {
    if( !result.destination ) {
      return;
    }
    let tmpReorder = reorder( this.state.selectedShortcutMenus, result.source.index, result.destination.index )
    this.props.storeAssignedSidemenu( tmpReorder )
    this.setState({ selectedShortcutMenus: tmpReorder })
  }

  onDragStart = (e, item ) => {
    e.dataTransfer.setData( 'label', item.label[ this.props.lang ] )
  }

  onDragOver = e => {
    e.preventDefault()
  }

  onDrop = ( e, item ) => {
    let id = e.dataTransfer.getData( "label" )
    let tmpSelectedMenu = _.cloneDeep( this.state.selectedShortcutMenus )
    let tmpAllMenus = _.cloneDeep( this.state.allMenus )
    let tmpFound = _.find( this.state.allMenus, { label: { [this.props.lang]: id } } )

    if( _.findIndex( this.state.selectedShortcutMenus, { label: { [this.props.lang]: id } } ) === -1 ){
      if( tmpFound ){
        let itemIndex = _.findIndex( this.state.allMenus, { label: { [this.props.lang]: id } } )
        tmpSelectedMenu.push( tmpFound )
        tmpAllMenus.splice( itemIndex, 1 )
        this.props.storeSidemenu( tmpAllMenus )
        this.setState({ allMenus: tmpAllMenus })
      }
    }
    this.setState({ selectedShortcutMenus: tmpSelectedMenu })
  }

  removeItem = item => {
    let tmpSelectedMenu = _.cloneDeep( this.state.selectedShortcutMenus )
    let tmpAllMenus = _.cloneDeep( this.state.allMenus )
    if( _.find( tmpSelectedMenu, { label: item.label } ) ){
      let itemIndex = _.findIndex( tmpSelectedMenu, { label: item.label } )
      tmpAllMenus.push( item )
      tmpSelectedMenu.splice( itemIndex, 1 )
      this.setState({ allMenus: tmpAllMenus, selectedShortcutMenus: tmpSelectedMenu })
    }
  }

  render = () => {
    let {
      showAssign,
      allMenus
    } = this.props
    return(
      <Modal
        isOpen={ showAssign }
        toggle={ () => this.props.onChangeMainHOC( 'showAssign', false ) }
        size='xl'>
        <ModalHeader toggle={ () => this.props.onChangeMainHOC( 'showAssign', false ) }>{ Lang[ 'ASSIGN_SHORTCUT_MENU' ][ this.props.lang ] }
          <div style={{ fontSize: 15, color: '#808080' }}>{ Lang[ 'INSTRUCTION' ][ this.props.lang ] }</div>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: '#F2F4F6' }}>
          <div className="container" style={{ backgroundColor: '#F2F4F6' }}>
            <div className="row">
              <div className="col-md-7" style={{ backgroundColor: '#fff', padding: '15px', borderRadius: '5px', marginRight: '100px' }}>
                <div className="d-flex" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                  {
                    allMenus && allMenus.length > 0 && allMenus.map( item => {
                      return(
                        <div
                          draggable
                          onDragStart={ e => this.onDragStart( e, item ) }
                          style={{
                            backgroundColor: '#F2F4F6',
                            display: 'flex',
                            margin: '10px 8px',
                            padding: '6px',
                            cursor: 'pointer',
                            zIndex: 999
                          }}>
                          <i className={ item.icon } style={{ fontSize: '15px', marginRight: '5px', marginTop: '3px' }}/>
                          { item.label[ this.props.lang ] }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div
                className="col-md-3 droppable"
                style={{ backgroundColor: '#fff', padding: '15px', borderRadius: '5px' }}
                onDragOver={ (e) => this.onDragOver(e) }
                onDrop={ (e, item ) => this.onDrop(e, item ) }>
                <div className="d-flex" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <DragDropContext onDragEnd={ this.onDragEnd }>
                    <Droppable droppableId="droppable">
                    {( provided, snapshot ) => (
                      <div
                        { ...provided.droppableProps }
                        ref={ provided.innerRef }
                        style={ getListStyle( snapshot.isDraggingOver )}>
                        {
                          this.state.selectedShortcutMenus.map(( item, index ) => {
                            return(
                              <Draggable key={ item.label[ this.props.lang ] } draggableId={ `shortcut-item-${ item.label[ this.props.lang ] }` } index={ index }>
                                {( provided, snapshot ) => (
                                  <div
                                    ref={ provided.innerRef }
                                    { ...provided.draggableProps }
                                    { ...provided.dragHandleProps }
                                    style={{
                                      padding: '5px',
                                      position: 'relative',
                                      backgroundColor: '#F2F4F6',
                                      display: 'flex',
                                      margin: '10px 8px',
                                      padding: '6px',
                                      cursor: 'pointer',
                                      alignItems: 'center',
                                      zIndex: 999,
                                      ...getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )
                                    }}>
                                    <i className={ item.icon } style={{ fontSize: '15px', marginRight: '5px' }}/>
                                    { item.label[ this.props.lang ] }
                                    <Button
                                      className="btn btn-icon-only"
                                      style={{
                                        position: 'absolute',
                                        right: -8,
                                        top: -10,
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor: '#fff',
                                        padding: 0,
                                        border: 'none'
                                      }}
                                      onClick={ () => this.removeItem( item ) }>
                                      <i className="pe-7s-close"
                                         style={{
                                           fontSize: '15px',
                                           color: 'black'
                                         }}/>
                                    </Button>
                                  </div>
                                )}
                              </Draggable>
                            )
                          })
                        }
                        { provided.placeholder }
                      </div>
                    )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <Button
              color="primary"
              className='mr-2'
              onClick={ () => {
                Promise.all([
                  this.props.onChangeMainHOC( 'showAssign', false )
                ]).then(
                  this.setState({ selectedShortcutMenus: [] })
                )
                this.props.storeSidemenu( this.state.allMenus )
                this.props.storeAssignedSidemenu( this.state.selectedShortcutMenus )
              }}>{ Lang[ 'ASSIGN' ][ this.props.lang ] }</Button>
            <Button
              color="secondary"
              onClick={ () => {
                Promise.all([
                  this.props.onChangeMainHOC( 'showAssign', false )
                ]).then(
                  this.setState({ selectedShortcutMenus: [] })
                )
              }}>{ Lang[ 'CANCEL' ][ this.props.lang ] }</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

export default AssignShortcutMenu
