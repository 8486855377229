import React from 'react'
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const Recipients = ( props ) => {
  return (
    <Container>
      <Form>
        <FormGroup>
          <Label>{ Lang[ 'SUBJECT' ][ props.selectedLanguage ] }</Label>
          <Col md={ 6 } className="pl-0">
            <Input
              type="text"
              onChange={ e => props.onChangeData( 'subject', e.target.value ) }
              value={ props.data.subject }
              invalid={ props.errorMessage && props.errorMessage.subject ? true : false }/>
            <FormFeedback>{ props.errorMessage && props.errorMessage.subject ? props.errorMessage.subject : '' }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'TITLE' ][ props.selectedLanguage ] }</Label>
          <Col md={ 6 } className="pl-0">
            <Input
              type="text"
              onChange={ e => props.onChangeData( 'title', e.target.value ) }
              value={ props.data.title }
              invalid={ props.errorMessage && props.errorMessage.title ? true : false }/>
            <FormFeedback>{ props.errorMessage && props.errorMessage.title ? props.errorMessage.title : '' }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'STATUS' ][ props.selectedLanguage ] }</Label>
          <Col md={ 6 } className="pl-0">
            <Input
              type="select"
              onChange={ e => props.onChangeData( 'status', e.target.value ) }
              value={ props.data.status }
              invalid={ props.errorMessage && props.errorMessage.status ? true : false } >
              <option value="ACTIVE">{ Lang[ 'ACTIVE' ][ props.selectedLanguage ] }</option>
              <option value="INACTIVE">{ Lang[ 'INACTIVE' ][ props.selectedLanguage ] }</option>
              <option value="ARCHIVED">{ Lang[ 'ARCHIVED' ][ props.selectedLanguage ] }</option>
            </Input>
            <FormFeedback>{ props.errorMessage && props.errorMessage.status ? props.errorMessage.status : '' }</FormFeedback>
          </Col>
        </FormGroup>
        <Row className="mb-3">
          <Col md={ 12 }>
            <Label className="mb-1">{ Lang[ 'SELECT_SENDERS' ][ props.selectedLanguage ] }</Label>
          </Col>
          <Col md={ 6 } className="d-inline-block">
            {
              props.senders.map( item => {
                return (
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={ props.data.sender_id === item.id }
                        type="radio"
                        onChange={ () => {
                          props.onChangeData( 'sender_id', item.id )
                        }} />
                      { item.email }
                    </Label>
                  </FormGroup>
                )
              })
            }
          </Col>
        </Row>
        {/*<Row className="mb-3">
          <Col md={ 12 }>
            <Label className="mb-1">{ Lang[ 'SELECT_RECIPIENT_GROUPS' ][ selectedLanguage ] }</Label>
          </Col>
          <Col md={ 6 } className="d-inline-block">
            {
              recipientGroups.map( item => {
                return (
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={ _.find( data.recipient_groups, { id: item.id } ) ? true : false }
                        value={ item.id }
                        type="checkbox"
                        onChange={ e => onChangeData( 'recipient_groups', e.target.value ) } />
                      { item.name }
                    </Label>
                  </FormGroup>
                )
              })
            }
          </Col>
        </Row>*/}
      </Form>
    </Container>
  )
}

export default Recipients
