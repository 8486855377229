import React from 'react'
import { connect } from 'react-redux'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback, Table,
  Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap'
import ReactSelect from 'react-select'
import Moment from 'moment'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const ViewRecipient = ( props ) => {
  return(
    <Modal
      isOpen={ props.showViewBlacklistedRecipient }>
      <ModalHeader toggle={ () => props.onChangeBlacklistedRecipientHOC('showViewBlacklistedRecipient', false ) }>{ Lang['VIEW'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ]  }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={12 }>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <th>{ Lang[ 'FIRST_NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedBlacklistedRecipient.first_name }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'LAST_NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedBlacklistedRecipient.last_name }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'EMAIL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedBlacklistedRecipient.email }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'SALUTATION' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedBlacklistedRecipient.salutation }</td>
                  </tr>
                  <tr>
                    <th>{ Lang[ 'TELEPHONE_OR_MOBILE_NUMBER' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</th>
                    <td>{ props.selectedBlacklistedRecipient.mobile_number }</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => props.onChangeBlacklistedRecipientHOC( 'showViewBlacklistedRecipient', false ) }>{ 'OK' }</Button>
      </ModalFooter>
      { props.onLoadBlacklistedRecipient && <LoadingOverlay /> }
    </Modal>
  )
}

const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( ViewRecipient )
