import React, { useState } from 'react'
import {
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const CurrentOffer = ({
  currentOffer,
  onChangeCurrentOfferHOC,
  selectedLanguage
}) => {

  const updateCurrentOffer = async ( key, val ) => {
    let tmp = _.cloneDeep( currentOffer )
    tmp[ key ] = val
    onChangeCurrentOfferHOC( 'currentOffer', tmp )
  }

  return(
    <Container>
      <Row>
        <Col md={ 8 }>
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'CURRENT_OFFER_TEXT' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'CURRENT_OFFER_TEXT' ][ selectedLanguage ] }
                value={ currentOffer.current_offer_text }
                onChange={ e => updateCurrentOffer( 'current_offer_text', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'CURRENT_OFFER_URL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'CURRENT_OFFER_URL' ][ selectedLanguage ] }
                value={ currentOffer.current_offer ? currentOffer.current_offer.url : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( currentOffer.current_offer )
                  tmp.url = e.target.value
                  updateCurrentOffer( 'current_offer', tmp ) 
                }} />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CurrentOffer