import React, { useEffect } from 'react'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const SenderForm = ({
  data,
  onChangeSendersHOC,
  headerText,
  toggle,
  isOpen,
  mode,
  onSubmit,
  errorMessage,
  selectedLanguage
}) => {
  useEffect( () => {
    onChangeSendersHOC( 'errorMessage', {} )
  }, [] )
  
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( data )
    let dataKey = ''
    tmp[key] = value
    if( mode === 'edit' ){
      dataKey = 'selectedSender'
    }else{
      dataKey = 'newSenderData'
    }

    onChangeSendersHOC( dataKey, tmp )
  }
  
  return(
    <Modal
      toggle={ toggle }
      isOpen={ isOpen }
      size="xl">
      <ModalHeader toggle={ toggle }>{ headerText }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 12 }>
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>{ Lang[ 'NAME' ][ selectedLanguage ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ data.name }
                        onChange={ e => onChangeData( 'name', e.target.value ) }
                        invalid={ errorMessage.name ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.name ? errorMessage.name : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="status">Email</Label>
                      <Input
                        type="email"
                        value={ data.email }
                        onChange={ e => {
                          let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
                          let tmpErrorMessage = _.cloneDeep( errorMessage )
                          if( !pattern.test( e.target.value ) ){
                            tmpErrorMessage["email"] = Lang[ 'PLEASE_ENTER_VALID_EMAIL' ][ selectedLanguage ]
                          } else {
                            tmpErrorMessage["email"] = ""
                          }
                          onChangeData( 'email', e.target.value )
                          onChangeSendersHOC( 'errorMessage', tmpErrorMessage )
                        }}
                        invalid={ errorMessage.email ? true : false } />
                      <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ onSubmit }>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
        <Button color="primary" onClick={ toggle }>{ Lang[ 'CANCEL' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default SenderForm
