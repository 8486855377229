import React from 'react'
import { FormGroup, FormText, Label, Input, UncontrolledTooltip } from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'
import _ from 'lodash'

import RenderDOM from '../RenderDOM'
import { generateFieldLabelStr } from '../utils'

const CheckboxComponent = ({
  content,
  onChangeFormBuilderState,
  mode,
  formValues,
  onPatchFormValues,
  dom,
  findObjectAndPatch,
  isInputDisabled,
  currentLanguage,
  pageErrors,
  createSubmittedFormMedia,
  removeSubmittedFormMedia,
  uploadedMedia
}) => {
  return (
    <FormGroup
      tag="fieldset"
      style={{ padding: content.padding, ...content.style }}>
      <Label className="ck-content" style={{ width: "100%" }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }
        {
          content.showQuickInfo && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      {
        content.selections.map( ( item, index ) => {
          return (
            <>
              <FormGroup
                key={ item.id  }
                check
                disabled={ isInputDisabled && content.mapWith !== 'page-selector' }
                checked={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1 }
                onChange={ () => {
                  if( item.nestedSelections && item.nestedSelections.length === 0 ) {
                    let tmp = _.cloneDeep( formValues[ content.dataMapIndex ][ 'value' ] )
                    if( tmp.indexOf( item.value ) > -1 ) {
                      tmp.splice( tmp.indexOf( item.value ), 1 )
                    } else {
                      tmp.push( item.value )
                    }
                    onPatchFormValues( content.dataMapIndex, 'value', tmp, content.type, item )
                  }
                }}>
                <Label 
                  check
                  style={{ display: 'flex', width: 'fit-content' }}>
                  {
                    <Input
                      type="checkbox" 
                      disabled={ isInputDisabled && content.mapWith !== 'page-selector' }
                      name={ content.id } 
                      value={ item.value }
                      checked={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1 } />
                  }
                  { ' ' }
                  { content.type.indexOf( 'Flexible' ) !== -1 && index === content.selections.length - 1
                    ? <Input
                        type='text'
                        disabled={ isInputDisabled }
                        invalid={ _.findIndex( pageErrors, { id: content.id } ) > -1 }
                        value={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1 ? item.label[ currentLanguage? currentLanguage: 'en' ]: '' }
                        readOnly= { formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1 ? false : true }
                        style={{ 
                          height: "auto",
                          border: "none",
                          borderRadius: "0",
                          padding: "0",
                          fontSize: "14px",
                          borderBottom: "1px dotted #000000" }}
                        onChange={ async e => {
                          let tmpFormValues = _.cloneDeep( formValues )
                          let tmpDOM = _.cloneDeep( dom )
                          let tmpContent = _.cloneDeep( content )
                          let tmpValues = _.cloneDeep( formValues[ content.dataMapIndex ][ 'value' ] )
                          let tmpRequiredSelections = _.cloneDeep( formValues[ content.dataMapIndex ][ 'requiredSelections' ] )
                          let tmpTargetValue = e.target.value.replaceAll( ' ', '-' )

                          if( tmpValues.indexOf( item.value ) > -1 ) {
                            tmpValues.splice( tmpValues.indexOf( item.value ), 1 )
                          }
                          if ( tmpRequiredSelections?.indexOf( item.value ) > -1 ) {
                            tmpRequiredSelections.splice( tmpRequiredSelections.indexOf( item.value ), 1 )
                            tmpRequiredSelections.push( tmpTargetValue )
                          }
                          tmpValues.push( tmpTargetValue )
                          
                          tmpFormValues[ content.dataMapIndex ][ 'value' ] = tmpValues
                          tmpFormValues[ content.dataMapIndex ][ 'flexibleInputValue' ] = e.target.value
                          tmpFormValues[ content.dataMapIndex ][ 'requiredSelections' ] = tmpRequiredSelections

                          tmpContent.selections[ index ][ 'value' ] = e.target.value.replaceAll( ' ', '-' )
                          tmpContent.selections[ index ][ 'label' ][ currentLanguage ] = e.target.value
                          await findObjectAndPatch( tmpDOM, tmpContent )

                          Promise.all([
                            onChangeFormBuilderState( 'dom', tmpDOM ),
                            onChangeFormBuilderState( 'formValues', tmpFormValues )
                          ])
                        }}/>
                    : item.label[ currentLanguage? currentLanguage: 'en' ] }
                    {
                      formValues[ content.dataMapIndex ][ 'requiredSelections' ]?.indexOf( item.value ) > -1 && (
                        <span style={{ color: '#ff0000' }}> * </span>
                      )
                    }
                </Label>
                {
                  item.nestedSelections && item.nestedSelections.length > 0 && item.nestedSelections.map( ( selection, nestedIndex ) => {
                    return (
                      <>
                        <FormGroup
                          key={ selection.label  }
                          check
                          checked={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( selection.value ) > -1 }
                          onChange={ () => {
                            if( selection.subCheckboxes && selection.subCheckboxes.length === 0 ) {
                              let tmp = _.cloneDeep( formValues[ content.dataMapIndex ][ 'value' ] )
                              if( tmp.indexOf( selection.value ) > -1 ) {
                                tmp.splice( tmp.indexOf( selection.value ), 1 )
                                const nestedSelectionsValues = _.map(item.nestedSelections, 'value')
                                const checkedNestedSelection = _.intersection(tmp, nestedSelectionsValues)
                                if ( checkedNestedSelection.length === 0 ) {
                                  tmp.splice( tmp.indexOf( item.value ), 1 )
                                }
                              } else {
                                tmp.push( selection.value ) 
                                if( tmp.indexOf( item.value ) === -1 ) {
                                  tmp.push( item.value )
                                }
                              }
                              onPatchFormValues( content.dataMapIndex, 'value', tmp, content.type, item )
                            }
                          }}>
                          <Label check>
                            <Input
                              type="checkbox" 
                              disabled={ isInputDisabled }
                              name={ selection.label } 
                              value={ selection.value }
                              checked={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( selection.value ) > -1 } />
                            { selection.label[ currentLanguage ] }
                            {
                              formValues[ content.dataMapIndex ][ 'requiredSelections' ]?.indexOf( selection.value ) > -1 && (
                                <span style={{ color: '#ff0000' }}> * </span>
                              )
                            }
                          </Label>
                        </FormGroup>
                        {
                          selection.subCheckboxes && selection.subCheckboxes.length > 0 && selection.subCheckboxes.map( subCheckbox => {
                            return (
                              <>
                                <FormGroup
                                  className="ml-4"
                                  key={ subCheckbox.label  }
                                  check
                                  checked={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( subCheckbox.value ) > -1 }
                                  onChange={ () => {
                                    let tmp = _.cloneDeep( formValues[ content.dataMapIndex ][ 'value' ] )
                                    if( tmp.indexOf( subCheckbox.value ) > -1 ) {
                                      tmp.splice( tmp.indexOf( subCheckbox.value ), 1 )
                                      const subCheckboxesValues = _.map( selection.subCheckboxes, 'value' )
                                      const checkedSubCheckbox = _.intersection( tmp, subCheckboxesValues )
                                      if ( checkedSubCheckbox.length === 0 ) {
                                        tmp.splice( tmp.indexOf( item.value ), 1 )
                                        tmp.splice( tmp.indexOf( selection.value ), 1 )
                                      }
                                    } else {
                                      tmp.push( subCheckbox.value )
                                      if( tmp.indexOf( item.value ) === -1 ) {
                                        tmp.push( item.value )
                                      }
                                      if( tmp.indexOf( selection.value ) === -1 ) {
                                        tmp.push( selection.value )
                                      }
                                    }
                                    onPatchFormValues( content.dataMapIndex, 'value', tmp, content.type, item )
                                  }}>
                                  <Label check>
                                    <Input
                                      type="checkbox" 
                                      disabled={ isInputDisabled }
                                      name={ subCheckbox.label } 
                                      value={ subCheckbox.value }
                                      checked={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( subCheckbox.value ) > -1 } />
                                    { subCheckbox.label[ currentLanguage ] }
                                    {
                                      formValues[ content.dataMapIndex ][ 'requiredSelections' ]?.indexOf( subCheckbox.value ) > -1 && (
                                        <span style={{ color: '#ff0000' }}> * </span>
                                      )
                                    }
                                  </Label>
                                </FormGroup>
                              </>
                            )
                          })
                        }
                      </>
                    )
                  })
                }
              </FormGroup>
              {
                formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1
                && item.dependentField
                && item.dependentField.id !== undefined
                && <RenderDOM
                    content={ item.dependentField }
                    onChangeFormBuilderState={ onChangeFormBuilderState }
                    mode={ mode }
                    formValues={ formValues }
                    onPatchFormValues={ onPatchFormValues }
                    dom={ dom }
                    findObjectAndPatch={ findObjectAndPatch }
                    isInputDisabled={ isInputDisabled }
                    currentLanguage={ currentLanguage  }
                    pageErrors={ pageErrors }
                    createSubmittedFormMedia={ createSubmittedFormMedia }
                    removeSubmittedFormMedia={ removeSubmittedFormMedia }
                    uploadedMedia={ uploadedMedia }
                />
              }
            </>
          )
        })
      }
      <FormText color="danger">
        { 
          (
            _.find( pageErrors, { id: content.id } ) &&
            _.find( pageErrors, { id: content.id } ).validation[ currentLanguage ]
          ) || ''
        }
      </FormText>
    </FormGroup> 
  )
}

export default CheckboxComponent
