import React, { Component } from 'react';
import { toast } from 'react-toastify'

import { Get } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      onLoadImages: false,
      images: [],
      page: 1,
      limit: 10,
      searchParams: [
        {
          value: 'short_name',
          param: ''
        }
      ],
      showCreateModal: false,
      newFolderFile: {
        file_name: '',
        type: 'File',
        media_raw: '',
        parent_folder_id: 0
      }
    }

    load = param => this.setState({ onLoadImages: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeShareMediaHOC = ( key, val ) => this.setState({ [ key ]: val })

    getImages = query => Get(
      `/api/v1/shared_media?query=${ query }`,
      this.getImagesSuccess,
      this.getImagesError,
      this.load
    )
    getImagesSuccess = payload => this.setState({ images: payload })
    getImagesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          { ...this.state }
          getImages={ this.getImages }
          onChangeShareMediaHOC={ this.onChangeShareMediaHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC
