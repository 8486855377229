import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Post, Put, Delete } from 'utils/axios-token'
import { Get } from 'utils/axios-template'

const HOC = WrappedComponent => {
  class WithHOC extends Component{
    state = {
      loading: false,
      showCreateCookieCategory: false,
      showUpdateCookieCategory: false,
      cookieCategories: [],
      selectedCookieCategory: {},
      newCookieCategory: {
        title: {
          en: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          nl: ''
        },
        description: {
          en: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          nl: ''
        },
        position: 1,
        cookie_type: 'TARGETING',
        standard_condition: 'SWITCH_OFF',
        status: 'ACTIVE',
        showPromptModal: false,
        toRemoveID: 0
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeCookieCategoriesHOC = ( key, val ) => this.setState({ [ key ] : val })

    createCookieCategory = data => Post(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookie_categories`,
      data,
      this.createCookieCategorySuccess,
      this.createCookieCategoryError,
      this.load
    )
    createCookieCategorySuccess = () => {
      this.getCookieCategories( this.props.data.selectCenterReducer.selectedCenter.center.id )
      this.requestSuccess( 'Cookie category is successfully created' )
      this.setState({ showCreateCookieCategory: false })
    }
    createCookieCategoryError = error => this.requestError( error )

    getCookieCategories = () => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookie_categories`,
      this.getCookieCategoriesSuccess,
      this.getCookieCategoriesError,
      this.load
    )
    getCookieCategoriesSuccess = payload => this.setState({ cookieCategories: payload })
    getCookieCategoriesError = error => this.requestError( error )

    getSelectedCookieCategory = category_id => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookie_categories/${ category_id }`,
      this.getSelectedCookieCategorySuccess,
      this.getSelectedCookieCategoryError,
      this.load
    )
    getSelectedCookieCategorySuccess = payload => this.setState({ selectedCookieCategory: payload, showUpdateCookieCategory: true })
    getSelectedCookieCategoryError = error => this.requestError( error )

    updateCookieCategory = data => Put(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookie_categories/${ data.id }`,
      data,
      this.updateCookieCategorySuccess,
      this.updateCookieCategoryError,
      this.load
    )
    updateCookieCategorySuccess = () => {
      this.getCookieCategories( this.props.data.selectCenterReducer.selectedCenter.center.id )
      this.requestSuccess( 'Cookie category is successfully updated' )
      this.setState({ showUpdateCookieCategory: false })
    }
    updateCookieCategoryError = error => this.requestError( error )

    removeCookieCategory = category_id => Delete(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookie_categories/${ category_id }`,
      this.removeCookieCategorySuccess,
      this.removeCookieCategoryError,
      this.load
    )
    removeCookieCategorySuccess = () => {
      this.setState({ showPromptModal: false })
      this.getCookieCategories( this.props.data.selectCenterReducer.selectedCenter.center.id )
      this.requestSuccess( 'Cookie category is successfully removed' )
    }
    removeCookieCategoryError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadCookieCategoriesHOC={ this.state.loading }
          onChangeCookieCategoriesHOC={ this.onChangeCookieCategoriesHOC }
          showCreateCookieCategory={ this.state.showCreateCookieCategory }
          showUpdateCookieCategory={ this.state.showUpdateCookieCategory }
          cookieCategories={ this.state.cookieCategories }
          selectedCookieCategory={ this.state.selectedCookieCategory }
          newCookieCategory={ this.state.newCookieCategory } 
          showPromptModal={ this.state.showPromptModal }
          toRemoveID={ this.state.toRemoveID }
          createCookieCategory={ this.createCookieCategory }
          getCookieCategories={ this.getCookieCategories }
          getSelectedCookieCategory={ this.getSelectedCookieCategory }
          updateCookieCategory={ this.updateCookieCategory }
          removeCookieCategory={ this.removeCookieCategory } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
