import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Put } from 'utils/axios-template'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      additionalInfo: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string' 
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )
    
    onChangeAdditionalInfoHOC = ( key, val ) => this.setState({ [ key ]: val })

    getAdditionalInfo = location_id => Get(
      `/api/v1/locations/${ location_id }/leadhub_additional`,
      this.getAdditionalInfoSuccess,
      this.getAdditionalInfoError,
      this.load
    )
    getAdditionalInfoSuccess = payload => this.setState({ additionalInfo: payload })
    getAdditionalInfoError = error => this.requestError( error )

    updateAdditionalInfo = ( location_id, data ) => Put(
      `/api/v1/locations/${ location_id }/leadhub_additional`,
      data,
      this.updateAdditionalInfoSuccess,
      this.updateAdditionalInfoError,
      this.load
    )
    updateAdditionalInfoSuccess = payload => this.requestSuccess( 'Additional info is successfully updated' )
    updateAdditionalInfoError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getAdditionalInfo={ this.getAdditionalInfo }
          updateAdditionalInfo={ this.updateAdditionalInfo }
          onLoadAdditionalInfo={ this.state.loading }
          additionalInfo={ this.state.additionalInfo }
          onChangeAdditionalInfoHOC={ this.onChangeAdditionalInfoHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC