import ReactHTMLParser from 'react-html-parser'
import Moment from 'moment'
import _ from 'lodash'

export const generateFieldLabelStr = ( content, formValues, currentLanguage ) => {
  let labelText = content.label[ currentLanguage? currentLanguage: 'en' ]
  let closingTag = ""

  if ( typeof(ReactHTMLParser( labelText )[0]) === 'object' ) {
    const tag = ReactHTMLParser( labelText )[0].type
    closingTag = "</" + tag + ">"
    labelText = labelText.slice(0, -1*closingTag.length)
  }

  if ( labelText.length !== 0 && formValues && formValues[ content.dataMapIndex ][ 'isRequired' ] ) {
    labelText += `<span style='color: #ff0000;'> * </span>`
  }
  if ( content.showQuickInfo || (formValues && formValues[ content.dataMapIndex ][ 'showQuickInfo' ]) ) {
    labelText += `<i id="quick-info-tooltip-${content.id}" class="pe-7s-info ml-2" style="font-size: 20px; cursor: pointer" />`
  }

  labelText += closingTag
  return labelText
}

export const ParseFormValues = ( dom, formValues, lang ) => {
  if ( dom?.type ) {
    let tmpFormValue = _.find( formValues, { id: dom.id } )?.value
    let tmpLabel = !_.isEmpty( dom.label?.[ lang ] ) ? ProcessFieldLabel( dom.label?.[ lang ] ) : ''
    switch ( dom.type ) {
      case 'Radio':
      case 'RadioFlexible':
        if ( !tmpFormValue ) {
          return {
            id: ProcessFieldLabel( tmpLabel ),
            value: ''
          }
        } else {
          let tmpSelection = _.find( dom.selections, { value: tmpFormValue } )
          let tmpValue = tmpSelection.label[ lang ]
          if( tmpSelection.dependentField?.id ) {
            tmpValue = (
              `${ tmpValue }<br>` + 
              `${ tmpSelection.dependentField.label[ lang ] }: ` + 
              `${ ParseFormValues( tmpSelection.dependentField, formValues, lang ).value }`
            )
          }
          return {
            id: ProcessFieldLabel( tmpLabel ),
            value: tmpValue
          }
        }
      case 'Checkbox':
      case 'CheckboxFlexible':
        if ( tmpFormValue.length > 0 ) {
          return {
            id: ProcessFieldLabel( tmpLabel ),
            value: ParseCheckboxValues( dom.selections, tmpFormValue, formValues, lang, true )
          }
        } else {
          return {
            id: ProcessFieldLabel( tmpLabel ),
            value: ''
          }
        }
      case 'InputFile':
        return {
          id: ProcessFieldLabel( tmpLabel ),
          value: _.reduce( 
            tmpFormValue,
            ( result, file, index ) => {
              let tmpFile = ''
              if ( index === 0 ) {
                tmpFile = '<ul>'
              } 
              tmpFile = `${ tmpFile }<li><a href=${ file.url } target='_blank' >${ file.filename }</a></li>` 
              if ( index === tmpFormValue.length - 1 ) (
                tmpFile = `${ tmpFile }</ul>`
              )
              return `${ result }${ tmpFile }`
            },
            ''
          )
        }
      case 'DateField':
        return {
          id: ProcessFieldLabel( tmpLabel ),
          value: Moment( tmpFormValue  ).format( 'YYYY-MM-DD' )
        }
      case 'TextField':
      case 'TextArea':
        return {
          id: ProcessFieldLabel( tmpLabel ),
          value: tmpFormValue
        }
      case 'Col':
        return ParseFormValues( dom.children[ 0 ], formValues, lang )
      default: 
        return undefined
    }
  } else if ( Array.isArray( dom ) ) {
    let tmpResults = dom.map( child => ParseFormValues( child, formValues, lang ) )
    return tmpResults
  }
}

const ParseCheckboxValues = ( dom, checkboxValues, formValues, lang, isSelection ) => {
  if( Array.isArray( dom ) ) {
    let tmpList = dom.map( selection => ParseCheckboxValues( selection, checkboxValues, formValues, lang, isSelection ) )
    tmpList = _.reduce( 
      tmpList,
      ( result, listItem ) => `${ result }${ listItem }`,
      ''
    )
    return (
      !_.isEmpty( tmpList ) 
        ? `<ul>${ tmpList }</ul>` 
        : ''
    )
  } else if( dom.value ) {
    if( checkboxValues.indexOf( dom.value ) > -1 ){
      let tmpListItem = `<li>${ dom.label[ lang ] }`
      tmpListItem += _.reduce(  
        dom,
        ( result, value ) => Array.isArray( value )
          ? ParseCheckboxValues( value, checkboxValues, formValues, lang, false )
          : result,
        ''
      )
      if( isSelection && dom.dependentField?.id ){
        tmpListItem += (
          `<br>${ dom.dependentField.label[ lang ] }: ` + 
          `${ ParseFormValues( dom.dependentField, formValues, lang ).value }`
        )
      }
      tmpListItem += '</li>'
      return tmpListItem
    } else {
      return ''
    }
  }
}

const ProcessFieldLabel = label => {
  label = label.split( "</p>" )[ 0 ]
  label = label.split( "</div>" )[ 0 ]
  label = label.split( "<br" )[ 0 ]
  label = label.replace( /<[^>]+>/g, '' ).replace( /&nbsp;/g, "" )  
  return label
}