import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-template'
import { Put } from 'utils/axios-template'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      contact: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeContactsHOC = ( key, val ) => this.setState({ [ key ]: val })

    addAlternativePhoneInput = () => {
      let tmpObj = {
        phone_type: '',
        phone: '',
        tracked: false
      }
      let tmp = _.cloneDeep( this.state.contact.alternative_phones )
      tmp.push( tmpObj )
      let tmpContact = _.cloneDeep( this.state.contact )
      tmpContact.alternative_phones = tmp
      return this.setState({ contact: tmpContact })
    }

    getContacts = location_id => Get(
      `/api/v1/locations/${ location_id }/leadhub_contacts`,
      this.getContactsSuccess,
      this.getContactsError,
      this.load
    )
    getContactsSuccess = payload => this.setState({ contact: payload })
    getContactsError = error => this.requestError( error )

    updateContact = ( location_id, data ) => {
      Put(
        `/api/v1/locations/${ location_id }/leadhub_contacts`,
        data,
        this.updateContactSuccess,
        this.updateContactError,
        this.load
      )
    }
    updateContactSuccess = payload => this.requestSuccess( 'Contact is successfully updated' )
    updateContactError = error => {
      if( error.email ) {
        this.requestError( error.email )
      }
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getContacts={ this.getContacts }
          updateContact={ this.updateContact }
          onLoadContacts={ this.state.loading }
          contact={ this.state.contact }
          onChangeContactsHOC={ this.onChangeContactsHOC }
          addAlternativePhoneInput={ this.addAlternativePhoneInput } />
      )
    }
  }
  return WithHOC
}

export default HOC
