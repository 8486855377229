const Lang={
  ARK_MANAGEMENT : {
    en: "Ark Management",
    de: "Ark Management",
    it: "Ark Management",
    nl: "Ark Management"
  },
  DASHBOARD : {
    en: "Dashboard",
    de: "Dashboard",
    it: "Cruscotto",
    nl: "DashBoard"
  },
  DIGITAL_ORDER_FORM: {
    en: "Digital Order Form",
    de: "Digitales Bestellformular",
    it: "Modulo d'ordine digitale",
    nl: "Digitaal bestelformulier"
  },
  FORM_LISTING: {
    en: "Form Listing",
    de: "Formulare",
    it: "Elenco dei moduli",
    nl: "Formulier Lijst"
  },
  FORM_BUILDER: {
    en: "Form Builder",
    de: "Formular Designer",
    it: "Costruttore di moduli",
    nl: "Formulierenbouwer"
  },
  AVAILABLE_FORMS: {
    en: "Available forms",
    de: "Bestellscheine",
    it: "Forme disponibili",
    nl: "Beschikbare formulieren"
  },
  SUBMITTED_FORMS: {
    en: "Submitted forms",
    de: "Laufende Aufträge",
    it: "Moduli inviati",
    nl: "Ingediende formulieren"
  },
  WEBFORMS: {
    en: "Webforms",
    de: "Webformulare",
    it: "Webforms",
    nl: "Webformulieren"
  },
  TENNIS_YOUTUBE_CHANNEL: {
    en: "Tennis Youtube Channel",
    de: "Tennis Youtube Kanal",
    it: "Canale Youtube del tennis",
    nl: "Tennis Youtube Kanaal"
  },
  SEARCH_CENTER: {
    en: "Search Court",
    de: "Anlagen Suche",
    it: "Centro di ricerca",
    nl: "Zoekcentrum"
  },
  BONUS_CLUB : {
    en: "Aciso Bonus Club",
    de: "Aciso Bonus Club",
    it: "Aciso Bonus Club",
    nl: "Aciso Bonus Club"
  },
  LEAD_CENTRE: {
    en: "Lead Centre",
    de: "LeadCenter",
    it: "Centro di comando",
    nl: "Leiding Centrum"
  },
  MULTITRAINING: {
    en: "Multitraining",
    de: "Multitraining",
    it: "Multiformazione",
    nl: "Meervoudige opleiding"
  },
  LINK_APP_PORTAL: {
    en: "Link App Portal",
    de: "Link App Portal",
    it: "Portale App Link",
    nl: "Link App Portaal"
  },
  INJOY_APP: {
    en: "Injoy App",
    de: "Injoy App",
    it: "Injoy App",
    nl: "Injoy App"
  },
  ACISO_APP: {
    en: "Aciso App",
    de: "Aciso App",
    it: "Aciso App",
    nl: "Aciso App"
  },
  APP_ERKLARVIDEOS: {
    en: "Erklarvideos",
    de: "Erklärvideos",
    it: "Erklarvideos",
    nl: "Erklarvideos"
  },
  LOCATIONS: {
    en: "Locations",
    de: "Standort",
    it: "Posizione",
    nl: "Locatie"
  },
  USER_SETTINGS: {
    en: "User Settings",
    de: "Benutzereinstellungen",
    it: "Impostazioni utente",
    nl: "Gebruikers Instellingen"
  },
  SETTINGS: {
    en: "Settings",
    de: "Einstellungen",
    it: "Impostazioni",
    nl: "Instellingen"
  },
  LISTING: {
    en: "Listing",
    de: "Auflistung",
    it: "Lista",
    nl: "Lijst"
  },
  COMPLETED_CAMPAIGN: {
    en: "Completed Campaign",
    de: "Abgeschlossene Kampagne",
    it: "Campagna completata",
    nl: "Voltooide Campagne"
  },
  OLS: {
    en: "OLS",
    de: "OLS",
    it: "OLS",
    nl: "OLS"
  },
  ARK_SITE: {
    en: "Ark Site",
    de: "Ark Seite",
    it: "Ark Sito",
    nl: "Ark Site"
  },
  MAIN_MENU:{
    en: "Main Menu",
    de: "Hauptmenü",
    it: "Menu principale",
    nl: "Hoofdmenu"
  },
  LOCAL_LISTINGS: {
    en: "Local Listings",
    de: "Local Listing",
    it: "Annunci locali",
    nl: "Lokale vermeldingen"
  },
  ONLINE_MARKETING_SERVICE: {
    en: "Online Marketing Service",
    de: "Online Marketing Service",
    it: "Servizio di marketing online",
    nl: "Online Marketing Dienst"
  },
  RESOURCES: {
    en: "Resources",
    de: "Ressourcen",
    it: "Risorse",
    nl: "Middelen"
  },
  MESSAGE_CONSULTANT: {
    "en": "Message Consultant",
    "de": "Berater Nachrichten",
    "it": "Consulente messaggi",
    "nl": "Bericht Consultant"
  },
  OTHERS: {
    "en": "Others",
    "de": "Andere",
    "it": "Altri",
    "nl": "Anderen"
  },
  OLS_CAMPAIGNS: {
    en: "OLS Campaigns",
    de: "OLS Kampagnen",
    it: "Campagne OLS",
    nl: "OLS Campagnes"
  },
  KEY_FIGURES: {
    en: 'Key Figures',
    de: 'Kennziffern',
    it: 'Cifre chiave',
    nl: 'Kerncijfers'
  },
  LEADS : {
    en: 'Leads',
    de: 'Führt',
    it: 'Porta',
    nl: 'Leads'
  },
  LEADS_DESCRIPTION : {
    en: 'Leads across all campaigns in the selected period (for the whole month)',
    de: 'Leads über alle Kampagnen im gewählten Zeitraum (für den gesamten Monat)',
    it: 'Leads di tutte le campagne nel periodo selezionato (per tutto il mese)',
    nl: 'Leads over alle campagnes in de geselecteerde periode (voor de hele maand)'
  },
  REQUEST_FORMS : {
    en: 'Request Forms',
    de: 'Formulare anfordern',
    it: 'Moduli di richiesta',
    nl: 'Aanvraagformulieren'
  },
  INQUIRIES_FORMS : {
    en: 'Inquiries about all forms in the selected period',
    de: 'Anfragen zu allen Formularen im gewählten Zeitraum',
    it: 'Richieste su tutte le forme nel periodo selezionato',
    nl: 'Inlichtingen over alle formulieren in de gekozen periode'
  },
  NUMBER_OF_SALES : {
    en: 'Number of Sales',
    de: 'Anzahl der Verkäufe',
    it: 'Numero di vendite',
    nl: 'Aantal verkopen'
  },
  NUMBER_OF_SALES_DESCRIPTION : {
    en: 'Number of sales in the selected period',
    de: 'Anzahl der Verkäufe im gewählten Zeitraum',
    it: 'Numero di vendite nel periodo selezionato',
    nl: 'Aantal verkopen in de geselecteerde periode'
  },
  SALES : {
    en: 'Sales',
    de: 'Alle',
    it: 'Vendite',
    nl: 'Verkoop'
  },
  SALES_ACCUMULATED : {
    en: 'Sales accumulated in the selected period',
    de: 'Kumulierter Umsatz in der gewählten Periode',
    it: 'Vendite accumulate nel periodo selezionato',
    nl: 'Gecumuleerde verkopen in de geselecteerde periode'
  },
  LATEST_OLS_CAMPAIGNS: {
    en: 'Sales',
    de: 'Alle',
    it: 'Vendite',
    nl: 'Verkoop'
  },
  VIEW_ALL: {
    en: 'Sales',
    de: 'Alle',
    it: 'Vendite',
    nl: 'Verkoop'
  },
  ALL_OLS_CAMPAIGNS: {
    en: 'All OLS Campaigns',
    de: 'Alle OLS-Kampagnen',
    it: 'Tutte le campagne OLS',
    nl: 'Alle OLS-campagnes'
  },
  CLOSE: {
    en: 'Close',
    de: 'Schließen',
    it: 'Chiudere',
    nl: 'Sluiten'
  },
  NEWS: {
    en: 'News',
    de: 'Neuigkeiten',
    it: 'Notizie',
    nl: 'Nieuws'
  },
  NEWS_FROM_ACISO: {
    en: 'News from Tennis People',
    de: 'Neuigkeiten von Tennis People',
    it: 'Notizie da Tennis People',
    nl: 'Nieuws van Tennis People'
  },
  NEWS_INFO: {
    en: 'News Info',
    de: 'Neuigkeiten',
    it: 'Informazioni sulle notizie',
    nl: 'Nieuws Info'
  },
  ALL_NEWS_FROM_ACISO: {
    en: 'All News from Tennis People',
    de: 'Alle Neuigkeiten von Tennis People',
    it: 'Tutte le notizie da Tennis People',
    nl: 'Alle nieuws van Tennis People'
  },
  LATEST_PRODUCTS: {
    en: 'Latest Products',
    de: 'Neueste Produkte',
    it: 'Ultimi prodotti',
    nl: 'Nieuwste producten'
  },
  PRODUCT_INFO: {
    en: 'Product Info',
    de: 'Produkt-Infos',
    it: 'Informazioni sul prodotto',
    nl: 'Product Info'
  },
  ALL_PRODUCTS: {
    en: 'All Products',
    de: 'Alle Produkte',
    it: 'Tutti i prodotti',
    nl: 'Alle Producten'
  },
  ASSIGN_SHORTCUT_MENU: {
    en: 'Assign Shortcut Menu',
    de: 'Kontextmenü zuweisen',
    it: 'Assegnazione del menu di scelta rapida',
    nl: 'Snelmenu toewijzen'
  },
  CANCEL: {
    en: 'Cancel',
    de: 'Abbrechen',
    it: 'Annulla',
    nl: 'Annuleer'
  },
  WATCH_OUT: {
    en: 'WATCH OUT!!! Be sure to fill out all fields!',
    de: 'Achten Sie darauf, alle Felder auszufüllen!',
    it: 'GUARDATE FUORI!!! Assicuratevi di compilare tutti i campi!',
    nl: 'WATCH OUT!!! Vul alle velden in!'
  },
  IS_PURE_WOMAN_STUDIO: {
    en: `Is this pure women's studio`,
    de: 'Ist dies ein reines Frauenstudio',
    it: 'Questo è uno studio di pura femminilità',
    nl: 'Is dit pure vrouwenstudio'
  },
  STUDIO_NAME: {
    en: `Studio name`,
    de: 'Name des Studios',
    it: 'Nome dello studio',
    nl: 'Naam van de studio'
  },
  ROAD: {
    en: `Road`,
    de: 'Straße',
    it: 'Strada',
    nl: 'Weg'
  },
  POSTCODE_OR_TOWN: {
    en: 'Postcode / town',
    de: 'Postleitzahl/Ort',
    it: 'Codice postale / città',
    nl: 'Postcode / plaats'
  },
  CONTACT_PERSON: {
    en: 'Contact person',
    de: 'Kontaktperson',
    it: 'Persona di contatto',
    nl: 'Contactpersoon'
  },
  EMAIL_CONTACT_PERSON: {
    en: 'Email contact person',
    de: 'E-Mail-Kontaktperson',
    it: 'Contatto via e-mail',
    nl: 'Contactpersoon voor e-mail'
  },
  TELEPHONE_CONTACT_PERSON: {
    en: 'Telephone contact person',
    de: 'Telefonische Kontaktperson',
    it: 'Contatto telefonico',
    nl: 'Telefonisch contactpersoon'
  },
  MY_PERSONAL_ADVISOR: {
    en: 'My personal advisor',
    de: 'Mein persönlicher Berater',
    it: 'Il mio consulente personale',
    nl: 'Mijn persoonlijke adviseur'
  },
  SEND_LEAD_EMAIL_ADDRESS: {
    en: 'Email address to which the leads should be sent. Pay attention to correct spelling!',
    de: 'E-Mail-Adresse, an die die Leads gesendet werden sollen. Achten Sie auf die korrekte Schreibweise!',
    it: "Indirizzo e-mail a cui inviare i cavi. Attenzione all'ortografia corretta!",
    nl: 'E-mailadres waarnaar de leads moeten worden gestuurd. Let op de juiste spelling!'
  },
  FB_LINK: {
    en: 'Link to the Facebook fan page',
    de: 'Link zur Facebook-Fanseite',
    it: 'Link alla pagina fan di Facebook',
    nl: 'Link naar de Facebook-fanpagina'
  },
  CAMPAIGN_TO_RUN: {
    en: 'Which campaign do you want to run?',
    de: 'Welche Kampagne möchten Sie durchführen?',
    it: 'Quale campagna vuoi condurre?',
    nl: 'Welke campagne wil je voeren?'
  },
  DATA_PROTECTION_LINK: {
    en: 'Link to the data protection of your website',
    de: 'Link zum Datenschutz Ihrer Website',
    it: 'Link alla protezione dei dati del vostro sito web',
    nl: 'Link naar de gegevensbescherming van uw website'
  },
  FB_MONTHLY_CLICK_BUDGET: {
    en: 'Monthly click-through budget Facebook campaign in euros. Enter the monthly amount that you want to use as a click budget (recommendation: 350 euros). This amount can be increased at any time and / or changed after the first campaign.',
    de: 'Monatliches Klickbudget Facebook-Kampagne in Euro. Geben Sie den monatlichen Betrag ein, den Sie als Klick-Budget verwenden möchten (Empfehlung: mindestens 500 Euro). Dieser Betrag kann jederzeit erhöht und / oder nach der ersten Kampagne geändert werden.',
    it: "Mensile click-through budget della campagna Facebook in euro. Inserite l'importo mensile che volete utilizzare come budget per il click (raccomandazione: 350 euro). Questo importo può essere aumentato in qualsiasi momento e/o modificato dopo la prima campagna.",
    nl: "Maandelijkse doorklikbudget Facebook-campagne in euro's. Voer het maandelijkse bedrag in dat u wilt gebruiken als klikbudget (aanbeveling: 350 euro). Dit bedrag kan op elk moment worden verhoogd en/of gewijzigd na de eerste campagne."
  },
  BEST_PLACE_NAME: {
    en: 'Best place name for text in the advertisement (People from model city and surroundings wanted)',
    de: 'Bester Ortsname für Text in der Anzeige (Menschen aus Modellstadt und Umgebung gesucht)',
    it: `Il miglior nome di luogo per il testo nell'annuncio (Cercasi persone di città modello e dintorni)`,
    nl: 'Beste plaatsnaam voor tekst in de advertentie (Mensen uit modelstad en omgeving gezocht)'
  },
  STANDARD_PLACE_NAME_SURROUNDINGS: {
    en: 'Standard (recommended): Your place name and surroundings (example: "pastures and surroundings")',
    de: 'Standard (empfohlen): Ihr Ortsname und Umgebung (Beispiel: "Weiden und Umgebung")',
    it: 'Standard (consigliato): Il vostro nome del luogo e dintorni (esempio: "pascoli e dintorni")',
    nl: `Standaard (aanbevolen): Uw plaatsnaam en omgeving (voorbeeld: "weiden en omgeving")`
  },
  FB_ADVERTISING_ACCOUNT_ID: {
    en: 'Facebook advertising account ID',
    de: 'Facebook-Werbekonto-ID',
    it: "ID dell'account pubblicitario di Facebook",
    nl: 'Facebook-reclameaccount-ID'
  },
  MONTHLY_CLICK_GOOGLE_ADWORDS: {
    en: 'Monthly click-through budget Google Adwords campaign in euros Enter the monthly amount that you want to use as a click budget (recommendation: 150 euros). This amount represents a margin and it is not certain that everything will be spent. This amount can also be changed at any time.',
    de: 'Monatliches Klickbudget Google Adwords-Kampagne in Euro Geben Sie den monatlichen Betrag ein, den Sie als Click-Budget verwenden möchten (Empfehlung: 150 Euro). Dieser Betrag stellt eine Marge dar und es ist nicht sicher, dass alles ausgegeben wird. Dieser Betrag kann auch jederzeit geändert werden.',
    it: "Budget mensile per la campagna Google Adwords in euro Inserire l'importo mensile che si desidera utilizzare come budget per i clic (raccomandazione: 150 euro). Questo importo rappresenta un margine e non è certo che tutto verrà speso. Anche questo importo può essere modificato in qualsiasi momento.",
    nl: "Maandelijks doorklikbudget Google Adwords-campagne in euro's Voer het maandelijkse bedrag in dat u als klikbudget wilt gebruiken (aanbeveling: 150 euro). Dit bedrag vertegenwoordigt een marge en het is niet zeker dat alles wordt uitgegeven. Dit bedrag kan ook op elk moment worden gewijzigd."
  },
  GOOGLE_ADS_CUSTOMER_NUMBER: {
    en: 'Google Ads customer number',
    de: 'Google Ads-Kundennummer',
    it: 'Numero cliente di Google Ads',
    nl: 'Google Advertentie klantnummer'
  },
  FIND_GOOGLE_ACCOUNT_TOP_RIGHT: {
    en: 'You can find this in your Google Account at the top right under the user name.',
    de: 'Sie finden diese in Ihrem Google-Konto oben rechts unter dem Benutzernamen.',
    it: 'Potete trovarlo nel vostro account Google in alto a destra sotto il nome utente.',
    nl: 'U vindt dit in uw Google-account rechtsboven onder de gebruikersnaam.'
  },
  COMMENT: {
    en: "Comment",
    de: 'Kommentar',
    it: 'Commento',
    nl: 'Commentaar'
  },
  CONSENT_DATA_PROCESSING: {
    en: 'Consent to data processing',
    de: 'Zustimmung zur Datenverarbeitung',
    it: 'Consenso al trattamento dei dati',
    nl: 'Toestemming voor gegevensverwerking'
  },
  YES_CONSENT_STATEMENT: {
    en: 'Yes, I consent to the data I have entered being processed to contact me.',
    de: 'Ja, ich bin damit einverstanden, dass die von mir eingegebenen Daten verarbeitet werden, um mich zu kontaktieren.',
    it: 'Sì, acconsento al trattamento dei dati che ho inserito per contattarmi.',
    nl: 'Ja, ik geef toestemming om de gegevens die ik heb ingevoerd te verwerken om contact met mij op te nemen.'
  },
  OLS_SETTING_HEADING: {
    en: "OLS Settings",
    de: 'OLS-Einstellungen',
    it: 'Impostazioni OLS',
    nl: 'OLS-instellingen'
  },
  OLS_SETTING_SUBHEADING: {
    en: "To start the online lead campaign, we need the following information from you.",
    de: 'Um die Online-Lead-Kampagne zu starten, benötigen wir von Ihnen die folgenden Informationen.',
    it: 'Per iniziare la campagna di lead online, abbiamo bisogno delle seguenti informazioni da voi.',
    nl: 'Om de online lead campagne te starten, hebben wij de volgende informatie van u nodig.'
  },
  SELECT_PLACEHOLDER: {
    en: "Please select one from below",
    de: 'Bitte wählen Sie eine der folgenden Optionen aus',
    it: 'Si prega di selezionare uno dei seguenti',
    nl: 'Kies er een van hieronder'
  },
  CURRENTLY_VIEWING_OLS_CAMPAIGN_SETTINGS_FROM:{
    en: 'Currently viewing ols campaign settings from',
    de: 'Derzeit werden die Kampagneneinstellungen von angezeigt',
    it: 'Stai visualizzando le impostazioni della campagna OL da',
    nl: 'Bekijk momenteel oude campagne-instellingen van'
  },
  CENTER:{
    en: 'Center',
    de: 'Center',
    it: 'Centro',
    nl: 'Centrum'
  },
  CONTRACT_TERM:{
    en: 'Contract term',
    de: 'Vertragslaufzeit',
    it: 'Durata del contratto',
    nl: 'Contracttermijn'
  },
  SUBMIT:{
    en: 'Submit',
    de: 'Senden',
    it: 'Invia a',
    nl: 'Stuur een bericht naar'
  },
  CONFIRMATION_MESSAGE: {
    en: 'You can only fill in this form once. Any changes later require you to speak to your consultant. Do you confirm this is the correct information to submit?',
    de: 'Sie können dieses Formular nur einmal ausfüllen. Bei späteren Änderungen müssen Sie mit Ihrem Berater sprechen. Bestätigen Sie, dass dies die korrekten Informationen zum Einreichen sind?',
    it: 'Puoi compilare questo modulo solo una volta. Qualsiasi cambiamento successivo richiede di parlare con il suo consulente. Conferma che queste sono le informazioni corrette da presentare?',
    nl: 'U kunt dit formulier maar één keer invullen. Voor latere wijzigingen moet u met uw consulent overleggen. Bevestigt u dat dit de juiste informatie is om in te dienen?'
  },
  BANNER_MESSAGE: {
    en: 'You have not subscribed to OLS',
    de: 'Sie haben OLS nicht abonniert',
    it: 'Non ti sei iscritto a OLS',
    nl: 'U bent niet geabonneerd op OLS'
  },
  CLICK_HERE: {
    en: 'Click for more info',
    de: 'Klicken Sie für weitere Informationen',
    it: 'Clicca per maggiori informazioni',
    nl: 'Klik voor meer info'
  },
  SUBSCRIBE_OLS: {
    en: 'You have subcribed to OLS.',
    de: 'Sie haben die OLS abonniert.',
    it: 'Lei si è abbonato a OLS.',
    nl: 'U bent geabonneerd op OLS.'
  },
  NEWSLETTER: {
    en: "Newsletter",
    de: "Newsletter",
    it: "Notiziario",
    nl: "Nieuwsbrief"
  },
  LISTINGS: {
    en: "Listing",
    de: "Mailings",
    it: "Lista",
    nl: "Lijst"
  },
  RECIPIENTS: {
    en: "Recipients",
    de: "Empfänger",
    it: "Destinatari",
    nl: "Ontvangers"
  },
  ACTIVE: {
    en: 'Active',
    de: 'Aktiv',
    nl: 'Actief',
    it: 'Attivo'
  },
  INACTIVE: {
    en: 'Inactive',
    de: 'Inaktiv',
    it: 'Inattivo',
    nl: 'Inactief'
  },
  DOI: {
    en: 'DOI',
    de: 'DOI',
    it: 'DOI',
    nl: 'DOI'
  },
  BULK_UPLOAD: {
    "en": "Bulk Upload",
    "de": "Bulk-Upload",
    "it": "Caricamento collettivo",
    "nl": "Massa upload"
  },
  NON_BLACKLISTED: {
    en: "Non Blacklisted",
    de: "Empfänger",
    it: "Non in lista nera",
    nl: "Niet op de zwarte lijst"
  },
  BLACKLISTS: {
    en: "Blacklists",
    de: "Blacklist",
    it: "Blacklists",
    nl: "Zwarte lijsten"
  },
  GROUPS: {
    en: "Groups",
    de: "Gruppen",
    it: "Gruppi",
    nl: "Groepen"
  },
  SENDER: {
    en: "Sender",
    de: "Sender",
    it: "Mittente",
    nl: "Afzender"
  },
  TEMPLATE: {
    "en": "Template",
    "de": "Vorlage",
    "it": "Modello",
    "nl": "Sjabloon"
  },
  EDIT_DURATION: {
    en: 'Edit Duration',
    de: 'Dauer bearbeiten',
    it: 'Modifica Durata',
    nl: 'Duur bewerken'
  },
  ANNUAL_MARKETING_PLAN: {
    en: 'Annual Marketing Plan',
    de: 'Jährlicher Marketingplan',
    it: 'Piano annuale di marketing',
    nl: 'Jaarlijks marketingplan'
  },
  MY_ORDERS: {
    en: 'My Orders',
    de: 'Meine Befehle',
    it: 'I miei ordini',
    nl: 'Mijn bestellingen'
  },
  REPORTS: {
    en: 'Reports',
    de: 'Berichte',
    it: 'Rapporti',
    nl: 'Verslagen'
  },
  EMAIL_REPORTING: {
    en: 'Email reporting',
    de: 'E-Mail-Berichterstattung',
    it: 'Segnalazione via e-mail',
    nl: 'Rapportage per e-mail'
  },
  WEBSITE: {
    en: 'Website',
    de: 'Website',
    it: 'Sito web',
    nl: 'Website'
  },
  SHOP: {
    en: 'Shop',
    de: 'Shop',
    it: 'Negozio',
    nl: 'Winkelen'
  },
  MEMBERS_AREA: {
    en: 'Members Area',
    de: 'Mitgliederbereich',
    it: 'Area membri',
    nl: 'Leden'
  },
  APP: {
    en: 'App',
    de: 'App',
    it: 'App',
    nl: 'App'
  },
  APP_ADMIN_PORTAL: {
    en: 'APP (Admin Portal)',
    de: 'APP (Verwaltungsportal)',
    it: 'APP (Portale amministrativo)',
    nl: 'APP (Admin portaal)'
  },
  CAMPAIGNS: {
    en: 'Campaigns',
    de: 'Kampagnen',
    it: 'Campagne',
    nl: 'Campagnes'
  },
  HELP_TUTORIALS: {
    en: 'Help/Tutorials',
    de: 'Hilfe/Tutorials',
    it: 'Aiuto/Tutorials',
    nl: 'Help/Gidsen'
  },
  EXPLANATORY_VIDEOS: {
    en: 'Explanatory videos',
    de: 'Erläuternde Videos',
    it: 'Video esplicativi',
    nl: `Verklarende video's`
  },
  FAQ: {
    en: 'FAQ',
    de: 'FAQ',
    it: 'FAQ',
    nl: 'FAQ'
  },
  ACISO_BENEFITS: {
    en: 'ACISO Benefits',
    de: 'ACISO Benefits',
    it: 'Vantaggi ACISO',
    nl: 'ACISO Voordelen'
  },
  ACISO_COCKPIT:{
    en: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/mfile_drive/f29c21d4897f78948b91f03172341b7b/original.jpg',
    de: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/mfile_drive/851ddf5058cf22df63d3344ad89919cf/original.jpg',
    it: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/mfile_drive/f29c21d4897f78948b91f03172341b7b/original.jpg',
    nl: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/mfile_drive/f29c21d4897f78948b91f03172341b7b/original.jpg'
  },
  MATOMO:{
    en: 'Matomo',
    de: 'Matomo',
    it: 'Matomo',
    nl: 'Matomo'
  },
  MATOMO_STATISTICS:{
    en: 'Matomo Statistics',
    de: 'Matomo Statistik',
    it: 'Matomo Statistiches',
    nl: 'Matomo Statistieken'
  },
  MAIL_GUN_STATISTICS:{
    en: 'Statistics',
    de: 'Statistiken',
    it: 'Statistiches',
    nl: 'Statistieken'
  },
  DURATION: {
    en: 'Duration', 
    de: 'Dauer',
    it: 'Durata',
    nl: 'Looptijd'
  },
  DURATION_UNIT: {
    en: 'Duration Unit', 
    de: 'Dauer Einheit', 
    it: 'Unità di durata', 
    nl: 'Duur Eenheid'
  },
  PERCENTAGE: {
    en: 'Percentage',
    de: 'Prozentsatz',
    it: 'Percentuale',
    nl: 'Percentage'
  },
  NO_MAILGUN_STAT:{
    en: 'No Stats available at the moment.',
    de: 'Momentan sind keine Statistiken verfügbar.',
    it: 'Nessuna statistica disponibile al momento.',
    nl: 'Er zijn momenteel geen statistieken beschikbaar.'
  },
  OPENED_MAIL: {
    en: 'Opened Mail',
    de: 'Mail geöffnet',
    it: 'Posta aperta',
    nl: 'geopende e-mail'
  },
  SUBSCRIBED: {
    en: 'Subscribed',
    de: 'Angemeldet',
    it: 'Abbonato',
    nl: 'Geabonneerd'
  },
  UNSUBSCRIBED: {
    en: 'Unsubscribed',
    de: 'Abgemeldet',
    it: 'Iscrizione annullata',
    nl: 'Uitgeschreven'
  },
  COMPLAINED: {
    en: 'Complained',
    de: 'Beschwerte sich',
    it: 'Lamentato',
    nl: 'Geklaagd'
  },
  OPENED_LINK: {
    en: 'Opened Link',
    de: 'Link geöffnet',
    it: 'Collegamento aperto',
    nl: 'Geopende link'
  },
  TOTAL_DELIVERED: {
    en: 'Total Delivered Mail',
    de: 'Zugestellte E-Mails insgesamt',
    it: 'Posta consegnata totale',
    nl: 'Totaal bezorgde post'
  },
  RECEIVED: {
    en: 'Received',
    de: 'Erhalten',
    it: 'Ricevuto',
    nl: 'Hebben ontvangen'
  },
  FAILED_TO_SEND: {
    en: 'Failed To Send',
    de: 'Senden fehlgeschlagen',
    it: 'Impossibile inviare',
    nl: 'Kan niet verzenden'
  },
  DATE:{
    en: 'Date',
    de: 'Datum',
    it: 'Data',
    nl: 'Datum'
  },
  VISIT_FREQUENCIES:{
    en: 'Visit frequencies',
    de: 'Frequenzen besuchen',
    it: 'Visita le frequenze',
    nl: 'Bezoek frequenties'
  },
  USER_CITIES:{
    en: 'User cities',
    de: 'Benutzer Städte',
    it: 'Città dell\'utente',
    nl: 'Gebruiker steden'
  },
  AVERAGE_TIME_ON_SITE: {
    en: 'Average time on site (seconds)',
    de: 'Durchschnittliche Verweildauer',
    it: 'Tempo medio sul sito (secondi)',
    nl: 'Gemiddelde tijd ter plaatse (seconden)'
  },
  SINGLE_PAGE_VIEW_VISIT: {
    en: 'Single page view visit',
    de: 'Besuch einer einzelnen Seite',
    it: 'Visita a pagina singola',
    nl: 'Bezoek aan één enkele pagina'
  },
  MAXIMUM_USER_ACTIONS_TAKEN: {
    en: 'Maximum user actions taken',
    de: 'Maximale Benutzeraktionen',
    it: 'Azioni massime dell\'utente',
    nl: 'Maximale gebruikersacties'
  },
  AVERAGE_ACTIONS_PER_VISIT: {
    en: 'Average actions per visit',
    de: 'Durchschnittliche Besucheraktionen',
    it: 'Media di azioni per visita',
    nl: 'Gemiddelde acties per bezoek'
  },
  TOTAL_VISITS: {
    en: 'Total site visits',
    de: 'Besuche insgesamt',
    it: 'Totale visite in loco',
    nl: 'Totaal bezoeken ter plaatse'
  },
  TOTAL_UNIQUE_VISITS:{
    en: 'Total unique visits',
    de: 'Unabhängige Besuche insgesamt',
    it: 'Visite uniche totali',
    nl: 'Totaal unieke bezoeken'
  },
  TOTAL_ACTIONS:{
    en: 'Total actions',
    de: 'Maßnahmen insgesamt',
    it: 'Azioni totali',
    nl: 'Totaal acties'
  },
  GDPR_CENTER: {
    en: 'GDPR Center',
    de: 'DSGVO Center',
    it: 'Centro GDPR',
    nl: 'GDPR Centrum'
  },
  AV_CONTRACT: {
    en: 'AV Contract',
    de: 'AV Vertrag',
    it: 'Contratto AV',
    nl: 'AV Contract'
  },
  PRIVACY_POLICY: {
    en: 'Privacy Policy',
    de: 'Datenschutz',
    it: 'Politica sulla privacy',
    nl: 'Privacybeleid'
  },
  IMPRINT: {
    en: 'Imprint',
    de: 'Impressum',
    it: 'Impronta',
    nl: 'Imprint'
  },
  INQUIRY_CONCERNED: {
    en: 'Inquiry Concerned',
    de: 'Anfrage Betroffene',
    it: 'Inchiesta preoccupata',
    nl: 'Onderzoek Bezorgd'
  },
  DATA_CONFIGURATION: {
    en: 'Data Configuration',
    de: 'Daten Konfiguration',
    it: 'Configurazione dati',
    nl: 'Gegevens Configuratie'
  },
  COOKIE_BANNER: {
    en: 'Cookie Banner',
    de: 'Cookie Banner',
    it: 'Biscotto Banner',
    nl: 'Cookie Banner'
  },
  CATEGORIES: {
    en: 'Categories',
    de: 'Kategorien',
    it: 'Categorie',
    nl: 'Categorieën'
  },
  CONTENT: {
    en: 'Content',
    de: 'Inhalt',
    it: 'Contenuto',
    nl: 'Inhoud'
  },
  MOST_BOOKED: {
    en: 'Most Booked',
    de: 'Meistgebucht',
    it: 'I più prenotati',
    nl: 'Meest Geboekt'
  },
  BUY: {
    en: 'Buy',
    de: 'Interesse',
    it: 'Compra',
    nl: 'Kopen'
  },
  CONSENT_MODAL: {
    en: 'Consent Modal',
    de: 'Einwilligung Modal',
    it: 'Consenso Modale',
    nl: 'Toestemming Modaal'
  },
  WELCOME_MESSAGE: {
    en: 'Hello there, Welcome to Proof Point Cockpit!',
    de: 'Hallo, willkommen im Proof Point Cockpit!',
    it: 'Salve, benvenuto su Proof Point Cockpit!',
    nl: 'Hallo daar, Welkom bij Proof Point Cockpit!'
  },
  CONSENT_INSTRUCTION: {
    en: 'Before you can continue to access your Ark Sites and the rest of the modules, please take a moment to do the following :',
    de: 'Bevor Sie auf Ihre Website und die übrigen Module zugreifen können, nehmen Sie sich bitte einen moment Zeit für:',
    it: 'Prima che tu possa continuare ad accedere ai tuoi Siti Arca e al resto dei moduli, prenditi un momento per fare quanto segue:',
    nl: 'Voordat u verder kunt gaan met toegang tot uw Ark Sites en de rest van de modules, moet u even de tijd nemen om het volgende te doen :'
  },
  AGREED_ON_CONTRACT: {
    en: 'Agree on the AV contract',
    de: 'Vertrag zur Auftragsverarbeitung (AV Vertrag)',
    it: 'Concordare il contratto AV',
    nl: 'Overeenstemming bereiken over het AV-contract'
  },
  AV_CONTRACT_STEP_1: {
    en: 'Step 1 : Go to GDPR Center > AV Contract',
    de: 'Schritt 1 : Gehen Sie zum DSGVO Center > AV-Vertrag',
    it: 'Passo 1 : Vai al Centro GDPR > Contratto AV',
    nl: 'Stap 1 : Ga naar GDPR Center > AV Contract'
  },
  AV_CONTRACT_STEP_2: {
    en: 'Step 2 : Study through the full contract',
    de: 'Schritt 2 : Lesen Sie den gesamten Vertrag',
    it: 'Passo 2 : Studiare il contratto completo',
    nl: 'Stap 2 : Het volledige contract doornemen'
  },
  AV_CONTRACT_STEP_3: {
    en: 'Step 3 : Agree & Submit',
    de: 'Schritt 3 : Zustimmen & absenden',
    it: 'Passo 3 : Accettare e inviare',
    nl: 'Stap 3 : Akkoord & verzenden'
  },
  AGREED_TO_POLICY_AND_IMPRINT: {
    en: 'Agree to take over the responsibility of privacy policy & imprint',
    de: 'Impressum & Datenschutz',
    it: `Accettare di assumersi la responsabilità della politica sulla privacy e dell'impronta`,
    nl: 'Akkoord om de verantwoordelijkheid over te nemen van privacy beleid & opdruk'
  },
  POLICY_IMPRINT_STEP_1: {
    en: 'Step 1 : Go to GDPR Center > Privacy Policy & Imprint',
    de: 'Schritt 1 : Gehen Sie zum DSGVO Center > Impressum & Datenschutz',
    it: 'Passo 1 : Vai al Centro GDPR > Privacy Policy & Imprint',
    nl: 'Stap 1 : Ga naar GDPR Center > Privacybeleid & Afdruk'
  },
  POLICY_IMPRINT_STEP_2: {
    en: 'Step 2 : Go through the content with your lawyer',
    de: 'Schritt 2 : Bearbeiten Sie den Inhalt mit Ihrem Datenschutzbeauftragten oder Anwalt',
    it: 'Passo 2 : esaminare il contenuto con il tuo avvocato',
    nl: 'Stap 2 : Neem de inhoud door met uw advocaat'
  },
  POLICY_IMPRINT_STEP_3: {
    en: 'Step 3 : Provide consent upon submission',
    de: 'Schritt 3 : Zustimmen & absenden',
    it: 'Passo 3 : fornire il consenso al momento della presentazione',
    nl: 'Stap 3 : Geef toestemming bij indiening'
  },
  COMPLETED: {
    en: 'Completed',
    de: 'Abgeschlossen',
    it: 'Completato',
    nl: 'Voltooid'
  },
  CONFIRMATION_BOX: {
    en: 'Confirmation Box',
    de: 'Bestätigungsfeld',
    it: 'Casella di conferma',
    nl: 'Bevestigingsvak'
  },
  IMPRINT_ERROR: {
    en: 'Imprint cannot be empty',
    de: 'Impressum kann nicht leer sein',
    it: `L'impronta non può essere vuota`,
    nl: 'Opdruk mag niet leeg zijn'
  },
  POLICY_ERROR: {
    en: 'Privacy policy cannot be empty',
    de: 'Die Datenschutz kann nicht leer sein',
    it: `L'informativa sulla privacy non può essere vuota`,
    nl: 'Privacybeleid kan niet leeg zijn'
  },
  FUNNEL: {
    en: 'Funnel',
    de: 'Trichter',
    it: 'Imbuto',
    nl: 'Trechter'
  },
  CREATE_FUNNEL: {
    en: 'Create Funnel',
    de: 'Trichter erstellen',
    it: 'Crea imbuto',
    nl: 'Creëer trechter'
  },
  FUNNELS: {
    en: 'Funnels',
    de: 'Trichter',
    it: 'Imbuti',
    nl: 'Trechters'
  },
  CREATE_SITE_FUNNEL: {
    en: 'Create New Funnel',
    de: 'Neuen Trichter erstellen',
    it: 'Creare un nuovo imbuto',
    nl: 'Creëer nieuwe trechterCreare un nuovo imbuto'
  },
  SCENARIO: {
    en: 'Scenario',
    de: 'Szenario',
    it: 'Scenario',
    nl: 'Scenario'
  },
  ARE_YOU_CREATING_FROM_TEMPLATE: {
    en: 'Are you creating from template?',
    de: 'Erstellen Sie aus einer Vorlage?',
    it: 'Stai creando da un modello?',
    nl: 'Creëert u vanuit een sjabloon?'
  },
  MAILING: {
    en: 'Mailing',
    de: 'Mailing',
    it: 'Mailing',
    nl: 'Mailing'
  },
  CREATE_MAILING: {
    en: 'Create Mailing',
    de: 'Mailing erstellen',
    it: 'Creare Mailing',
    nl: 'Mailing maken'
  },
  CREATE_RECIPIENT: {
    "en": "Create Recipient",
    "de": "Empfänger erstellen",
    "it": "Crea destinatario",
    "nl": "Creëer ontvanger"
  },
  ACTIVE_RECIPIENTS: {
    "en": "Active Recipients",
    "de": "Active Empfänger",
    "it": "Attivi Destinatari",
    "nl": "Actieve Ontvangers"
  },
  BLACKLISTED_RECIPIENTS: {
    "en": "Blacklisted Recipients",
    "de": "Empfänger auf der schwarzen Liste",
    "it": "Destinatari nella lista nera",
    "nl": "Op de zwarte lijst geplaatste ontvangers"
  },
  START_IF_TRIGGERED: {
    en: 'Start if triggered',
    de: 'Start bei Auslösung',
    it: 'Iniziare se attivato',
    nl: 'Start indien geactiveerd'
  },
  START_ON_FIX_DAY: {
    en: 'Start on a fix day',
    de: 'Start an einem festen Tag',
    it: 'Iniziare in un giorno fisso',
    nl: 'Begin op een vaste dag'
  },
  HAS_EXPIRY_DATE: {
    en: 'Has Expiry Date',
    de: 'Enddatum festlegen ',
    it: 'Ha una data di scadenza',
    nl: 'Heeft Vervaldatum'
  },
  ENTER_FUNNEL_TITLE: {
    en: 'Enter funnel title',
    de: 'Titel des Trichters eingeben',
    it: 'Inserisci il titolo del funnel',
    nl: 'Titel van trechter invoeren'
  },
  ENTER_FUNNEL_DESC: {
    en: 'Enter funnel description',
    de: 'Beschreibung des Trichters eingeben',
    it: `Inserisci la descrizione dell'imbuto`,
    nl: 'Voer trechterbeschrijving in'
  },
  DESCRIPTION: {
    en: 'Description',
    de: 'Beschreibung',
    it: 'Descrizione',
    nl: 'Beschrijving'
  },
  CREATE_SITE_FUNNEL_SUCCESS: {
    en: 'Site funnel created successfully.',
    de: 'Website-Trichter erfolgreich erstellt.',
    it: 'Funnel del sito creato con successo.',
    nl: 'Site trechter succesvol gemaakt.Funnel del sito creato con successo.'
  },
  UPDATE_SITE_FUNNEL_SUCCESS: {
    en: 'Site funnel updated successfully.',
    de: 'Der Trichter der Website wurde erfolgreich aktualisiert.',
    it: 'Funnel del sito aggiornato con successo.',
    nl: 'Site trechter succesvol bijgewerkt'
  },
  UPDATE_FUNNEL: {
    en: 'Update Funnel',
    de: 'Update-Trichter',
    it: "Aggiornamento dell'imbuto",
    nl: 'Trechter bijwerken'
  },
  RECIPIENT_GROUPS: {
    en: 'Recipient Groups',
    de: 'Empfängergruppen',
    nl: 'Ontvangersgroepen',
    it: 'Gruppi di destinatari'
  },
  FILL_IN_DETAILS: {
    en: 'Fill in details',
    de: 'Details ausfüllen',
    it: 'Compilare i dettagli',
    nl: 'Vul de gegevens in'
  },
  CHOOSE_A_TEMPLATE: {
    en: 'Choose a template',
    de: 'Wählen Sie eine Vorlage',
    it: 'Scegliere un modello',
    nl: 'Kies een sjabloon'
  },
  DESIGN_MAILING: {
    en: 'Design Mailing',
    de: 'Entwurf Mailing',
    it: 'Design Mailing',
    nl: 'Ontwerp verzendingDesign Mailing'
  },
  STEP: {
    en: 'Step',
    de: 'Schritt',
    it: 'Passo',
    nl: 'Stap'
  },
  BACK:{
    en: 'Back',
    de: 'Zurück',
    it: 'Indietro',
    nl: 'Terug naar'
  },
  UPDATE_MAILING: {
    en: 'Update mailing',
    de: 'Mailing aktualisieren',
    it: 'Aggiornamento Mailing',
    nl: 'Mailing bijwerken'
  },
  SELECT_SENDERS: {
    "en": "Select senders",
    "de": "Absender auswählen",
    "it": "Seleziona mittenti",
    "nl": "Selecteer afzenders"
  },
  SELECT_RECIPIENT_GROUPS: {
    "en": "Select recipients groups",
    "de": "Empfängergruppen auswählen",
    "it": "Seleziona i gruppi di destinatari",
    "nl": "Selecteer ontvangersgroepen"
  },
  SELECT_A_TEMPLATE: {
    en: 'Select a template',
    de: 'Wählen Sie eine Vorlage',
    it: 'Seleziona un modello',
    nl: 'Kies een sjabloon'
  },
  SELECT_TEMPLATE: {
    en: 'Select Template',
    de: 'Vorlage auswählen',
    it: 'Selezionare il modello',
    nl: 'Selecteer Sjabloon'
  },
  CREATE_SENDER: {
    "en": "Create sender",
    "de": "Absender erstellen",
    "it": "Crea mittente",
    "nl": "Afzender aanmaken"
  },
  EDIT_SENDER: {
    "en": "Edit Sender",
    "de": "Absender bearbeiten",
    "it": "Modifica mittente",
    "nl": "Bewerk afzender"
  },
  TEMPLATES: {
    "en": "Templates",
    "de": "Vorlagen",
    "it": "Modelli",
    "nl": "Sjablonen"
  },
  EDIT_TEMPLATE: {
    en: 'Edit template',
    de: 'Vorlage bearbeiten',
    it: 'Modifica modello',
    nl: 'Sjabloon bewerken'
  },
  SETUP_RECIPIENT_GROUPS: {
    "en": "Setup of the recipient groups over here.",
    "de": "Einrichtung der Empfängergruppen hier.",
    "it": "Configurazione dei gruppi di destinatari qui.",
    "nl": "Stel hier de ontvangergroepen in."
  },
  CREATE_RECIPIENT_GROUP: {
    "en": "Create Recipient Group",
    "de": "Empfängergruppe erstellen",
    "it": "Crea gruppo destinatari",
    "nl": "Maak een ontvangersgroep"
  },
  EDIT_RECIPIENT_GROUP: {
    en: 'Edit Recipient Group',
    de: 'Empfängergruppe bearbeiten',
    it: 'Modifica gruppo di destinatari',
    nl: 'Ontvangersgroep bewerken'
  },
  EDIT_RECIPIENT: {
    en: 'Edit Recipient',
    de: 'Empfänger bearbeiten',
    it: 'Modifica destinatario',
    nl: 'Ontvanger bewerken'
  },
  SUMMARY: {
    "en": "Summary",
    "de": "Zusammenfassung",
    "it": "Sommario",
    "nl": "Samenvatting"
  },
  DETAILS: {
    "en": "Details",
    "de": "Einzelheiten",
    "it": "Dettagli",
    "nl": "Details"
  },
  EMAIL_CREATED: {
    "en": "Email(s) that created",
    "de": "E-Mail (s), die erstellt wurden",
    "it": "Email che hanno creato",
    "nl": "E-mail (s) die zijn aangemaakt"
  },
  TOTAL_REJECTED: {
    "en": "Total emails rejected",
    "de": "Insgesamt abgelehnte E-Mails",
    "it": "Totale messaggi di posta elettronica rifiutati",
    "nl": "Totaal aantal afgewezen e-mails"
  },
  EMAIL_ACTIVE: {
    "en": "Email(s) that existed as active recipient:",
    "de": "E-Mail (s), die als aktiver Empfänger vorhanden waren:",
    "it": "Email esistenti come destinatari attivi:",
    "nl": "E-mail (s) die bestonden als actieve ontvanger:"
  },
  EMAIL_BLACKLISTED: {
    "en": "Email(s) that existed as blacklisted recipient:",
    "de": "E-Mail (s), die als Empfänger auf der schwarzen Liste vorhanden waren:",
    "it": "Email che esistevano come destinatari nella lista nera:",
    "nl": "E-mail (s) die bestonden als ontvanger op de zwarte lijst:"
  },
  EMAIL_DUPLICATED: {
    "en": "Email(s) that found duplicated:",
    "de": "E-Mail (s), die doppelt gefunden wurden:",
    "it": "Email che sono state trovate duplicate:",
    "nl": "E-mail (s) die gedupliceerd zijn gevonden:"
  },
  BULK_UPLOAD_RECIPIENT: {
    "en": "Bulk upload for recipients",
    "de": "Massen-Upload für Empfänger",
    "it": "Caricamento collettivo per i destinatari",
    "nl": "Bulkupload voor ontvangers"
  },
  CLICK: {
    "en": "Click ",
    "de": "Klicken ",
    "it": "Fare clic ",
    "nl": "Klik "
  },
  HERE: {
    "en": "here ",
    "de": "Sie hier ",
    "it": "qui ",
    "nl": "hier "
  },
  DOWNLOAD_SAMPLE: {
    "en": "to download sample CSV file",
    "de": "um eine CSV-Beispieldatei herunterzuladen",
    "it": "per scaricare un file CSV di esempio",
    "nl": "om een ​​voorbeeld van een CSV-bestand te downloaden"
  },
  CHOOSE_FILE: {
    "en": "Choose a CSV file or Excel file to upload",
    "de": "Wählen Sie eine CSV- oder Excel-Datei zum Hochladen aus",
    "it": "Scegli un file CSV o un file Excel da caricare",
    "nl": "Kies een CSV-bestand of Excel-bestand om te uploaden"
  },
  UPLOAD_FILE: {
    "en": "Upload a file",
    "de": "Eine Datei hochladen",
    "it": "Caricare un file",
    "nl": "Een bestand uploaden"
  },
  UPLOAD_SUCCESS: {
    "en": "Upload success",
    "de": "Erfolgreich hochgeladen",
    "it": "Caricamento riuscito",
    "nl": "Upload geslaagd"
  },
  UPLOAD_COMPLETED: {
    en: 'Upload completed',
    de: 'Upload abgeschlossen',
    it: 'Carica completato',
    nl: 'Upload voltooid'
  },
  RECIPIENT_GROUP: {
    "en": "Recipient group",
    "de": "Empfängergruppe",
    "it": "Gruppo di destinatari",
    "nl": "Ontvangersgroep"
  },
  SALUTATION: {
    en: 'Salutation',
    de: 'Anrede',
    it: 'Saluto',
    nl: 'Aanhef'
  },
  FIRST_NAME: {
    en: 'First name',
    de: 'Vorname',
    it: 'Nome di battesimo',
    nl: 'Voornaam'
  },
  LAST_NAME: {
    en: 'Last name',
    de: 'Nachname',
    it: 'Cognome',
    nl: 'Achternaam'
  },
  CREATE_ACTIVE_SUCCESS: {
    "en": "Recipient is created successfully.",
    "de": "Empfänger wird erfolgreich angelegt.",
    "it": "Ontvanger is succesvol aangemaakt.",
    "nl": "De inactieve ontvanger is succesvol aangemaakt."
  },
  CREATE_ACTIVE_FAILED: {
    "en": "Recipient created failed.",
    "de": "Die Erstellung des Empfängers ist fehlgeschlagen.",
    "it": "Destinatario creato non riuscito.",
    "nl": "Ontvanger aangemaakt mislukt."
  },
  UPDATE_ACTIVE_SUCCESS: {
    "en": "Active recipient is updated successfully.",
    "de": "Aktiver Empfänger wird erfolgreich aktualisiert.",
    "it": "Il destinatario attivo viene aggiornato correttamente.",
    "nl": "De actieve ontvanger is succesvol bijgewerkt."
  },
  CREATE_GROUP_SUCCESS: {
    en: 'Recipient group created successfully.',
    de: 'Empfängergruppe erfolgreich erstellt.',
    it: 'Gruppo di destinatari creato con successo.',
    nl: 'Ontvangersgroep succesvol aangemaakt.'
  },
  UPDATE_GROUP_SUCCESS: {
    en: 'Recipient group updated successfully.',
    de: 'Empfängergruppe erfolgreich aktualisiert.',
    nl: 'Ontvangersgroep succesvol bijgewerkt.',
    it: 'Il gruppo di destinatari è stato aggiornato con successo.'
  },
  RECIPIENT_GROUP_DETAILS: {
    en: 'Recipient group details',
    de: 'Angaben zur Empfängergruppe',
    it: 'Dettagli del gruppo destinatario',
    nl: 'Gegevens over de ontvangersgroep'
  },
  FUNNEL_GROUPS: {
    en: 'Funnel group',
    de: 'Trichtergruppe',
    it: 'Gruppo a imbuto',
    nl: 'Trechter groepa'
  },
  CREATE_MAILING_SUCCESS: {
    en: 'Create Mailing success',
    de: 'Mailing erfolgreich erstellen',
    it: 'Creare la mailing con successo',
    nl: 'Mailing met succes aanmaken'
  },
  NO_RESULT_FOUND: {
    en: 'No result found',
    de: 'Kein Ergebnis gefunden',
    it: 'Nessun risultato trovato',
    nl: 'Geen resultaat gevonden'
  },
  EMAIL_INACTIVE: {
    "en": "Email(s) that existed as inactive recipient:",
    "de": "E-Mail (s), die als inaktiver Empfänger vorhanden waren:",
    "it": "Email che esistevano come destinatario inattivo:",
    "nl": "E-mail (s) die bestonden als inactieve ontvanger:"
  },
  RECIPIENT_GROUP_REQUIRED: {
    en: 'Recipient group is required',
    de: 'Empfängergruppe ist erforderlich',
    it: 'Il gruppo di destinatari è richiesto',
    nl: 'Ontvangersgroep is vereist'
  },
  MEMBER_AREA: {
    en: 'Member Area',
    de: 'Mitgliederbereich',
    it: 'Area membri',
    nl: 'Leden'
  },
  STOP_FUNNEL_IF: {
    en: 'Stop funnel if',
    de: 'Trichter anhalten, wenn',
    it: "Fermare l'imbuto se",
    nl: 'Stop trechter als'
  },
  USER_ENTER_ONE_FOLLOWING_FUNNEL: {
    en: 'User enters one of the following funnels',
    de: 'Der Benutzer betritt einen der folgenden Trichter',
    it: "L'utente entra in uno dei seguenti imbuti",
    nl: 'Gebruiker komt in een van de volgende trechters'
  },
  EXPERT: {
    en: 'Expert',
    de: 'Experte',
    it: 'Esperto',
    nl: 'Expert'
  },
  TEST_BUTTON: {
    en: 'Funnel testing',
    de: 'Trichterprüfung',
    it: "Test dell'imbuto",
    nl: 'Trechter testen'
  },
  START_END_DATE_TITLE: {
    en: 'Time range this funnel is active:',
    de: 'Zeitbereich, in dem dieser Trichter aktiv ist:',
    it: 'Intervallo di tempo in cui questo imbuto è attivo:',
    nl: 'Tijdsspanne waarin deze trechter actief is:'
  },
  END_DATE_REQUIRED: {
    en: 'End date and start date is required.',
    de: 'Enddatum und Startdatum sind erforderlich.',
    it: 'La data di fine e la data di inizio sono richieste.',
    nl: 'Einddatum en begindatum zijn vereist.'
  },
  INFOCENTER: {
    en: 'Infocenter',
    de: 'Infocenter',
    it: 'Infocenter',
    nl: 'Infocenter'
  },
  INFOCENTER_DESCRIPTION: {
    en: 'Note on the information requirements according to Infocenter GDPR <br> With the following texts we inform you according to Infocenter GDPR about the processing of personal data in our systems. You can find all information about the processing as well as the anonymization and deletion concepts by clicking on the corresponding post.',
    de: 'Hinweis zu den Informationspflichten nach Infocenter DSGVO <br> Mit den folgenden Texten informieren wir Sie laut Infocenter DSGVO über die Verarbeitung personenbezogener Daten in unseren Systemen. Sie finden alle Informationen zur Verarbeitung sowie zu den Anonymisierungs- und Löschkonzepten indem Sie auf den entsprechenden Beitrag klicken.',
    it: `Nota sull'obbligo di informazione secondo Infocenter GDPR <br> Con i seguenti testi vi informiamo secondo Infocenter GDPR sul trattamento dei dati personali nei nostri sistemi. Potete trovare tutte le informazioni sul trattamento così come i concetti di anonimizzazione e cancellazione cliccando sul post corrispondente.`,
    nl: 'Opmerking over de informatieverplichtingen volgens Infocenter GDPR <br> Met de volgende teksten informeren wij u volgens Infocenter GDPR over de verwerking van persoonsgegevens in onze systemen. U kunt alle informatie over de verwerking evenals de anonimiserings- en verwijderingsconcepten vinden door op het desbetreffende bericht te klikken.'
  },
  EMAIL_DOI: {
    "en": "Email(s) that existed as DOI:",
    "de": "E-Mail (s), die als DOI existierten:",
    "it": "Email che esistevano come DOI:",
    "nl": "E-mail (s) die bestonden als DOI:"
  },
  START_DATE: {
    en: 'Start date',
    de: 'Startdatum',
    it: 'Data di inizio',
    nl: 'Startdatum'
  },
  END_DATE: {
    en: 'End date',
    de: 'Enddatum',
    it: 'Data di fine',
    nl: 'Einddatum'
  },
  USE_NEWSLETTER_PLACEHOLDER: {
    en: 'You can use the following placeholders in the newsletter contents. They will be automatically replaced.',
    de: 'Sie können die folgenden Platzhalter im Inhalt des Newsletters verwenden. Sie werden dann automatisch ersetzt.',
    it: 'Puoi usare i seguenti segnaposti nei contenuti della newsletter. Saranno sostituiti automaticamente.',
    nl: 'U kunt de volgende plaatshouders gebruiken in de inhoud van de nieuwsbrief. Ze zullen automatisch vervangen worden.'
  },
  Sun:{
    en: 'Sun',
    de: 'So',
    nl: 'Zo.',
    it: 'Do.'
  },
  Mon:{
    en: 'Mon',
    de: 'Mo',
    nl: 'Ma.',
    it: 'Lun.'
  },
  Tue:{
    en: 'Tue',
    de: 'Di',
    nl: 'Di.',
    it: 'Mar.'
  },
  Wed:{
    en: 'Wed',
    de: 'Mi',
    nl: 'Wo.',
    it: 'Mer.'
  },
  Thu:{
    en: 'Thu',
    de: 'Do',
    nl: 'Do.',
    it: 'Gio.'
  },
  Fri:{
    en: 'Fri',
    de: 'Fr',
    nl: 'Vr.',
    it: 'Ven.'
  },
  Sat:{
    en: 'Sat',
    de: 'Sa',
    nl: 'Za.',
    it: 'Sab.'
  },
  ACTIVATE_AND_FILL_IN: {
    en: 'Activate & fill in',
    de: 'Aktivieren & ausfüllen',
    it: 'Attiva e compila',
    nl: 'Activeren en invullen'
  },
  SKIP_AND_CONTINUE: {
    en: 'Skip & continue',
    de: 'Überspringen & fortfahren',
    it: 'Salta e continua',
    nl: 'Overslaan en doorgaan'
  },
  NEWSLETTER_SENDERS_CANNOT_DELETE_DESC: {
    en: 'Please note that once created senders can not be deleted again. A frequent change of the sender would lead to the fact that the dispatch service would consider the Newlsetter as spam and possibly would not be delivered correctly.',
    de: 'Bitte beachten Sie, dass einmal angelegte Absender nicht wieder gelöscht werden können. Ein häufiger Wechsel des Absenders würde dazu führen, dass der Versanddienstleister den Newlsetter als Spam betrachten würde und möglicherweise nicht korrekt zustellt.',
    nl: '',
    it: ''
  },
  DATA_CREATED_SUCCESS: {
    en: 'Data is created successfully.',
    de: 'Daten wurden erfolgreich erstellt',
    nl: '',
    it: ''
  },
  DATA_UPDATED_SUCCESS: {
    en: 'Data is updated successfully.',
    de: 'Die Daten wurden erfolgreich aktualisiert.',
    nl: '',
    it: ''
  },
  DATA_REMOVED_SUCCESS: {
    en: 'Data is removed successfully.',
    de: 'Die Daten wurden erfolgreich entfernt.',
    nl: '',
    it: ''
  },
  ADDED_RECIPIENT_INTO_GROUP: {
    en: 'Added recipient into group',
    de: 'Update des Empfängers erfolgreich',
    it: 'Aggiunta di un destinatario al gruppo',
    nl: 'Ontvanger toegevoegd aan groep',
  },
  EDIT_ORDER_DATA: {
    "en": "Edit order data",
    "de": "Bestelldaten bearbeiten",
    "it": "Modifica dei dati dell'ordine",
    "nl": "Wijzig ordergegevens"
  },
  MOVE_RECIPIENT: {
    en: 'Move Recipient',
    de: 'Empfänger verschieben',
    it: 'Spostare il destinatario',
    nl: 'Verplaats Ontvanger'
  },
  TARGET_RECIPIENT_GROUP: {
    en: 'Target Recipient Group',
    de: 'Ziel-Empfängergruppe',
    it: 'Gruppo di destinatari',
    nl: 'Doelgroep van ontvangers'
  },
  NUMBER_OF_RECIPIENT_ADDED: {
    en: 'Number of recipients added into target group',
    de: 'Anzahl der in die Zielgruppe aufgenommenen Empfänger',
    it: 'Numero di destinatari aggiunti al gruppo target',
    nl: 'Aantal begunstigden die aan de doelgroep zijn toegevoegd'
  },
  NUMBER_OF_RECIPIENT_ALREADY_IN_TARGET_GROUP: {
    en: 'Number of recipients already in target group',
    de: 'Anzahl der Empfänger, die bereits zur Zielgruppe gehören',
    it: 'Numero di destinatari già presenti nel gruppo target',
    nl: 'Aantal begunstigden die reeds tot de doelgroep behoren'
  },
  NUMBER_OF_RECIPIENT_REMOVED: {
    en: 'Number of recipients removed from existing group',
    de: 'Anzahl der aus der bestehenden Gruppe entfernten Empfänger',
    it: 'Numero di destinatari rimossi dal gruppo esistente',
    nl: 'Aantal ontvangers verwijderd uit bestaande groep'
  },
  RECEIVER_MOVE_SUCCESS: {
    en: 'All receivers successfully moved to target recipient group',
    de: 'Alle Empfänger wurden erfolgreich in die Ziel-Empfängergruppe verschoben',
    it: 'Tutti i destinatari sono stati spostati con successo nel gruppo di destinatari target',
    nl: 'Alle ontvangers zijn met succes overgeplaatst naar de beoogde ontvangersgroep'
  },
  REMOVE_EXISTING_RECIPIENT_GROUP: {
    en: 'Remove existing recipient group',
    de: 'Vorhandene Empfängergruppe entfernen',
    it: 'Rimuovere il gruppo di destinatari esistente',
    nl: 'Bestaande ontvangersgroep verwijderen'
  },
  ARE_YOU_SURE_DELETE_RECIPIENT_GROUP: {
    en: 'Are you sure you want to delete this recipient group?',
    de: 'Sind Sie sicher, dass Sie diese Empfängergruppe löschen wollen?',
    it: 'Siete sicuri di voler eliminare questo gruppo di destinatari?',
    nl: 'Weet u zeker dat u deze ontvangersgroep wilt verwijderen?'
  },
  MOVE_RECIPIENT_BEFORE_DELETE: {
    "en": "Do you really want to delete the recipient group? All recipients will remain in the system with their respective status (active, inactive). Please select a group to which the recipients should be moved.",
    "de": "Möchten Sie die Empfängergruppe wirklich löschen? Alle Empfänger bleiben mit dem jeweiligen Status (aktiv, inaktiv) im System bestehen. Bitte wählen Sie eine Gruppe aus in die die Empfänger verschoben werden sollen.",
    "it": "Vuoi davvero eliminare il gruppo di destinatari? Tutti i destinatari rimarranno nel sistema con il rispettivo stato (attivo, inattivo). Seleziona un gruppo in cui spostare i destinatari.",
    "nl": "Wilt u de ontvangergroep echt verwijderen? Alle ontvangers blijven in het systeem met hun respectieve status (actief, inactief). Selecteer een groep waarnaar de ontvangers moeten worden verplaatst."
  },
  REMOVE_RECIPIENT_FROM_GROUP_CONFIRMATION: {
    en: 'The email address is not deleted and keeps its status (active, inactive, DOI). The address will only be removed from this group. Do you want to remove the email address from the group?',
    de: 'Die Email Adresse wird nicht gelöscht und behält ihren Status (aktiv, inaktiv, DOI). Die Adresse wird lediglich aus dieser Gruppe entfernt. Möchten Sie die Email Adresse aus der Gruppe entfernen?',
    it: `L'indirizzo e-mail non viene eliminato e mantiene il suo stato (attivo, inattivo, DOI). L'indirizzo sarà rimosso solo da questo gruppo. Si desidera rimuovere l'indirizzo e-mail dal gruppo?`,
    nl: 'Het e-mailadres wordt niet verwijderd en behoudt zijn status (actief, inactief, DOI). Het adres wordt alleen uit deze groep verwijderd. Wilt u het e-mailadres uit de groep verwijderen?'
  },
  FORM_IS_SUCCESSFULLY_SUBMITTED: {
    en: 'Form is successfully submitted',
    de: 'Das Formular wurde erfolgreich übermittelt',
    it: 'Il modulo è stato inviato con successo',
    nl: 'Formulier is succesvol ingediend'
  },
  LOCAL_LISTINGS_REPORT: {
    en: 'Local Listings Report',
    de: 'Bericht zu den lokalen Einträgen',
    it: 'Rapporto sugli elenchi locali',
    nl: 'Rapport lokale vermeldingen'
  },
  VIEW_REPORTS_FROM_LOCAL_LISTINGS: {
    en: 'View reports from Local Listings.',
    de: 'Berichte von Local Listings anzeigen.',
    it: 'Visualizzare i rapporti delle Inserzioni locali.',
    nl: 'Bekijk rapporten van Lokale Advertenties.'
  },
  HINT_TEXT: {
    en: 'Hint text',
    de: 'Hinweistext',
    it: 'Testo del suggerimento',
    nl: 'Hint tekst'
  },
  IS_COPIED: {
    en: 'is copied',
    de: 'wird kopiert',
    it: 'viene copiato',
    nl: 'wordt gekopieerd'
  },
  COMPANY_NAME: {
    en: 'Company name',
    de: 'Firmenname',
    it: 'Nome della società',
    nl: 'Bedrijfsnaam'
  },
  TOWN: {
    en: 'Town',
    de: 'Ort',
    it: 'Città',
    nl: 'Stad'
  },
  APPLY_VALUE: {
    en: 'Apply values',
    de: 'Werte anwenden',
    it: 'Applicare i valori',
    nl: 'Waarden toepassen'
  },
  VALUES_APPLIED: {
    en: 'Values applied',
    de: 'Angewandte Werte',
    it: 'Valori applicati',
    nl: 'Toegepaste waarden'
  },
  ARCHIVED_FORMS: {
    en: 'Archived Forms',
    de: 'Archivierte Aufträge',
    it: 'Moduli archiviati',
    nl: 'Gearchiveerde formulieren'
  },
  CHECK_FB_ADVERTISING_ACCOUNT_ID: {
    en: 'Check your Facebook advertising account ID:',
    de: 'Hier klicken um Facebook-Werbekonto-ID  an zu zeigen:',
    it: `Controllare l'ID dell'account pubblicitario di Facebook:`,
    nl: 'Controleer uw Facebook-reclameaccount-ID:'
  },
  SUPPORTED_FILE_TYPES: {
    en: 'Supported file types',
    de: 'Unterstützter Dateityp',
    it: 'Tipo di file supportato',
    nl: 'Ondersteund bestandstype'
  },
  PR_NUMBER: {
    en: "PR Number",
    de: 'PR-Nummer',
    it: "Numero PR",
    nl: "PR Nummer"
  },
  APP_ADMIN_PORTAL_FITLINX: {
    en: 'Admin Portal (FITLINX)',
    de: 'Verwaltungsportal (FITLINX)',
    it: 'APP (Portale amministrativo) (FITLINX)',
    nl: 'APP (Admin portaal) (FITLINX)'
  },
  APP_ADMIN_PORTAL_INJOY: {
    en: 'Admin Portal (INJOY)',
    de: 'Verwaltungsportal (INJOY)',
    it: 'APP (Portale amministrativo) (INJOY)',
    nl: 'APP (Admin portaal) (INJOY)'
  },
  APP_ADMIN_PORTAL_ELEMENTS: {
    en: 'Admin Portal (ELEMENTS)',
    de: 'Verwaltungsportal (ELEMENTS)',
    it: 'APP (Portale amministrativo) (ELEMENTS)',
    nl: 'APP (Admin portaal) (ELEMENTS)'
  },
  PREVIEW: {
    en: 'Preview',
    de: 'Vorschau',
    it: 'Anteprima',
    nl: 'Preview',
  }
}

export default Lang