import React, { Component } from 'react'
import ReactTable from 'react-table'
import { compose } from 'redux'
import { Card, CardBody, CardHeader, Input, Label, Alert, Button } from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'

import WithCompletedBookedCampaign from './actions'
import WithUser from '../Campaigns/actions/User'
import WithProductRequest from '../actions'
import Lang from 'Lang/General'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ProductInfo from '../info'

class CompletedBookedCampaign extends Component{
  state={
    centerOptions: []
  }

  componentDidMount = async() => {
    if( this.props.data.roleReducer.role.name === 'OLS One Timer' ) {
      this.props.getCompletedBookedCampaign( true )
    } else {
      let tmpCenterOptions = []
      await this.props.getSelectedUser( this.props.data.profileReducer.profile.id )
      if( !_.isEmpty( this.props.selectedUser ) && this.props.selectedUser.centers?.length > 0 ){
        this.props.selectedUser.centers.map( center => (
          tmpCenterOptions.push({ 
            id: center.id,
            name: center.center_name
          }) 
        ))
      }
      if( 
        !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter.center ) &&
        _.findIndex( tmpCenterOptions, { id: this.props.data.selectCenterReducer.selectedCenter.center.id } ) === -1
      ){
        tmpCenterOptions.push({ 
          id: this.props.data.selectCenterReducer.selectedCenter.center.id,  
          name: this.props.data.selectCenterReducer.selectedCenter.center.center_name
        })
      }
      this.props.onChangeCompletedCampaignsHOC( 
        'completedCampaignsCenterId',
        this.props.data.selectCenterReducer.selectedCenter.center.id
      )
      this.props.getAllProductRequests()
      this.props.getProducts()
      this.props.getRequestedProduct()
      this.setState({ centerOptions: tmpCenterOptions })
    }
  }

  render = () => {
    let selectedLanguage = this.props.data.languageReducer.userSettings.admin_panel_language
    let tmpProduct = _.find( this.props.products, x => x.name.en === 'OLS' )

    return(
      <>
        <div className="mb-5">
          {
            this.props.data.selectCenterReducer.selectedCenter.center && _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'OLS' } ) < 0 && (
              <>
                <img src={ '' } style={{ width: '100%', marginBottom: '15px'}}/>
                <Alert color="info">
                  { Lang[ 'BANNER_MESSAGE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                  {
                    tmpProduct && (
                      <Button 
                        style={{
                          border: 'none',
                          boxShadow: 'none'
                        }}
                        color="link"
                        onClick={ () => {
                          this.props.onChangeProductRequestsHOC( 'showRequestInfoModal', true )
                          this.props.getSelectedProduct( tmpProduct.id )
                        }}>{ Lang[ 'CLICK_HERE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                    )
                  }
                </Alert>
              </>
            )
          }
        </div>
        <PageTitle
          heading={ Lang[ 'COMPLETED_CAMPAIGN_HEADING' ][ selectedLanguage ] }
          subheading={ Lang[ 'COMPLETED_CAMPAIGN_SUBHEADING' ][ selectedLanguage ] }
          icon="pe-7s-notebook icon-gradient bg-happy-fisher" />
        {
          this.props.data.roleReducer.role.name !== 'OLS One Timer' && <Card className="mb-2">
            <CardHeader>{ Lang[ 'CURRENTLY_VIEWING_COMPLETED_BOOKED_CAMPAIGN_FROM' ][ selectedLanguage ] }</CardHeader>
            <CardBody>
              <Label>{ Lang[ 'CENTER' ][ selectedLanguage ] }</Label>
              <Input
                type='select'
                value={ this.props.completedCampaignsCenterId }
                onChange={ e => this.props.onChangeCompletedCampaignsHOC( 'completedCampaignsCenterId', e.target.value )}>
                { this.state.centerOptions.map( center => <option value={ center.id } >{ center.name }</option> ) }
              </Input>
            </CardBody>
          </Card>
        }
        <Card className="main-card mb-3">
          <CardBody>
            <ReactTable
              NoDataComponent={ () => <span></span> }
              data={ this.props.completedCampaign }
              columns={[
                {
                  Header: Lang[ 'TITLE' ][ selectedLanguage ],
                  accessor: 'title',
                  style: { "line-break": "anywhere" }
                },
                {
                  Header: Lang[ 'FACEBOOK_BUDGET' ][ selectedLanguage ],
                  accessor: 'fa_budget'
                },
                {
                  Header: Lang[ 'NUMBER_OF_LEADS' ][ selectedLanguage ],
                  accessor: 'total_leads'
                },
                {
                  Header: Lang[ 'START_DATE' ][ selectedLanguage ],
                  accessor: 'start_date',
                  Cell: ({ value }) => Moment( value ).format( 'YYYY-MM-DD' )
                },
                {
                  Header: Lang[ 'END_DATE' ][ selectedLanguage ],
                  accessor: 'end_date',
                  Cell: ({ value }) => Moment( value ).format( 'YYYY-MM-DD' )
                }
              ]}
              showPagination={ true }
              defaultPageSize={ 10 } />
          </CardBody>
          <ProductInfo 
            data={ this.props.data }
            selectedProduct={ this.props.selectedProduct }
            showRequestInfoModal={ this.props.showRequestInfoModal }
            onChangeProductRequestsHOC={ this.props.onChangeProductRequestsHOC }
            createProductsRequest={ this.props.createProductsRequest }
            newRequest={ this.props.newRequest }
            lang={ this.props.data.languageReducer.userSettings.admin_panel_language } />
          { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadCompletedBookedCampaign ) && <LoadingOverlay /> }
        </Card>
      </>
    )
  }
}

export default compose( 
  WithCompletedBookedCampaign,
  WithUser,
  WithProductRequest
)( CompletedBookedCampaign )
