import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      tags: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeTagsHOC = ( key, val ) => this.setState({ [ key ] : val })

    getTags = () => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/media_tags`,
      this.getTagsSuccess,
      this.getTagsError,
      this.load
    )
    getTagsSuccess = payload => this.setState({ 
      tags: payload.map( tag => ({
        ...tag,
        label: tag.name,
        value: tag.name
      }) )
    })
    getTagsError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadTags={ this.state.loading }
            getTags={ this.getTags }
            tags={ this.state.tags }
            onChangeTagsHOC={ this.onChangeTagsHOC } 
          />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC
