import React from 'react'
import { connect } from 'react-redux'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import ReactSelect from 'react-select'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const UpdateRecipientsForm = ( props ) => {
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( props.selectedActiveRecipient )
    tmp[key] = value

    props.onChangeActiveRecipientHOC( 'selectedActiveRecipient', tmp )
  }
  
  return(
    <Modal
      isOpen={ props.showUpdateActiveRecipient }
      size="xl">
      <ModalHeader toggle={ () => props.onChangeActiveRecipientHOC('showUpdateActiveRecipient', false ) }>{ Lang['EDIT_ACTIVE_RECIPIENT'][ props.dataReducer.languageReducer.userSettings.admin_panel_language ]  }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 8 }>
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>{ Lang[ 'EMAIL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'email' }
                        value={ props.selectedActiveRecipient.email }
                        onChange={ e => {
                          props.errorMessage["email"] = ""
                          onChangeData( 'email', e.target.value )
                        }}
                        invalid={ props.errorMessage && props.errorMessage.email ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.email ? props.errorMessage.email : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="salutation">{ Lang[ 'SALUTATION' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type="text"
                        name="salutation"
                        id="salutation"
                        value={ props.selectedActiveRecipient.salutation }
                        onChange={ e => onChangeData( 'salutation', e.target.value ) }/>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'FIRST_NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.selectedActiveRecipient.first_name }
                        onChange={ e => onChangeData( 'first_name', e.target.value ) }
                        invalid={ props.errorMessage && props.errorMessage.first_name ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.first_name ? props.errorMessage.first_name : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'LAST_NAME' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.selectedActiveRecipient.last_name }
                        onChange={ e => onChangeData( 'last_name', e.target.value ) }
                        invalid={ props.errorMessage && props.errorMessage.last_name ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.last_name ? props.errorMessage.last_name : '' }</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'MOBILE_NUMBER' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type={ 'text' }
                        value={ props.selectedActiveRecipient.mobile_number }
                        onChange={ e => onChangeData( 'mobile_number', e.target.value ) }
                        invalid={ props.errorMessage && props.errorMessage.mobile_number ? true : false } />
                      <FormFeedback>{ props.errorMessage && props.errorMessage.mobile_number ? props.errorMessage.mobile_number : '' }</FormFeedback>
                    </FormGroup>
                    {/*<FormGroup>
                      <Label for="status">{ Lang[ 'STATUS' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        invalid={ errorMessage && errorMessage.status ? true : false }
                        value={ props.selectedActiveRecipient.status }
                        onChange={ e => onChangeData( 'status', e.target.value ) }>
                        {
                          props.status && Object.keys( props.status ).map( key => {
                            if( key !== 'archived' ) {
                              return (
                                <option key={ key }>{ props.status[key] }</option>
                              )
                            }
                          })
                        }
                      </Input>
                      <FormFeedback>{ errorMessage && errorMessage.status ? errorMessage.status : '' }</FormFeedback>
                    </FormGroup>*/}
                    <FormGroup>
                      <Label>{ Lang[ 'GROUP' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Label>
                      <ReactSelect
                        isMulti
                        name="select"
                        options={ props.recipientGroupForSelect }
                        className="search-select"
                        classNamePrefix="select"
                        onChange={ selectedValue => {
                          onChangeData( 'recipient_groups', selectedValue )
                        }}
                        value={ props.selectedActiveRecipient.recipient_groups }/>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={ 4} style={{ overflowY: 'scroll', height: '650px' }}>
            {
              // activeRecipientLogs && activeRecipientLogs.map( item => {
              //   return (
              //     <Card key={ item.id } className="mb-2">
              //       <CardBody>
              //         <p style={{ fontWeight: '600' }}>{ item[ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</p>
              //         <span>{ Moment( item.created_at ).format( 'DD MMM YYYY HH:mm:ss' ) }</span>
              //       </CardBody>
              //     </Card>
              //   )
              // })
            }
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => { props.updateActiveRecipient( props.selectedActiveRecipient.id, props.selectedActiveRecipient ) } }>{ Lang[ 'SUBMIT' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Button>
        <Button color="primary" onClick={ () => {} }>{ Lang[ 'CANCEL' ][ props.dataReducer.languageReducer.userSettings.admin_panel_language ] }</Button>
      </ModalFooter>
      { props.onLoadRecipients && <LoadingOverlay /> }
    </Modal>
  )
}

const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps )( UpdateRecipientsForm )
