import React from 'react'
import {
  Row, Col,
} from 'reactstrap'

import Lang from 'Lang/General'

const TableOfContents = ( props ) => {
  
  const renderPageCompletionStatus = ( pageDom ) => {
    const IconStyle = {
      fontSize: "20px", 
      fontWeight: "600"
    }

    if ( pageDom.completionStatus === 'NOT_ORDERED' ) {
      return (
        <div class="d-flex flex-column align-items-center" style={{ color: "#5cb85c" }}>
          <i className="pe-7s-check" style={ IconStyle }></i> 
          <div>{ Lang[ "MEDIUM_NOT_ORDERED" ][ props.currentLanguage ] }</div>
        </div>
      )
    } else if ( pageDom.completionStatus === 'COMPLETED' ) {
      return (
        <div class="d-flex flex-column align-items-center" style={{ color: "#5cb85c" }}>
          <i className="pe-7s-check" style={ IconStyle }></i> 
          <div>{ Lang[ "MEDIUM_ORDERED" ][ props.currentLanguage ] }</div>
        </div>
      )
    } else {
      return (
        <div class="d-flex flex-column align-items-center" style={{ color: "#d9534f" }}>
          <i className="pe-7s-close" style={ IconStyle }></i> 
          <div>{ Lang[ "INFO_INCOMPLETE_PLEASE_CORRECT" ][ props.currentLanguage ] }</div>
        </div>
      )
    }
  }

  return (
    <Col md={ 12 }>
      {
        props.dom[0].children
        .filter( pageDom => !pageDom.mapWith || pageDom.mapWith === "form" )
        .map((pageDom) => {
          return (
            <Row className="justify-content-between align-items-center position-relative my-2">
              <Col md={ 5 }>
                <div className='form-content-item'>
                  {
                    pageDom.settings.thumbnailImg && pageDom.settings.thumbnailImg.length > 0 ? (
                      <img 
                        className='col-5 page-thumbnail-img'
                        src={ pageDom.settings.thumbnailImg }
                        alt=""
                      />
                    ) : (
                      <div className='col-5 d-flex'>
                        <i className='pe-7s-news-paper pe-5x page-thumbnail-img m-auto'></i>
                      </div>
                    )
                  }
                  <div className='col-6'>
                    <span className='page-number'>
                      { Lang[ 'PAGE' ][ props.currentLanguage ] } { pageDom.page }
                    </span>
                    <span className='page-title'>
                      {  
                        ( pageDom.name[ props.currentLanguage ] !== "" && pageDom.name[ props.currentLanguage ] !== Lang[ 'PAGE' ][ props.currentLanguage ] ) 
                        ? pageDom.name[ props.currentLanguage ]
                        : ""
                      }
                    </span>
                  </div>
                </div>
              </Col>
              {
                props.isShowProgress && renderPageCompletionStatus( pageDom )
              }
              <Col md={ 3 } >
                <button 
                  className='form-page-link-btn'
                  onClick={ () => props.onChangeFormBuilderState( 'currentPage', parseInt( pageDom.page ) ) }>
                  { Lang[ 'EDIT_ORDER_DATA' ][ props.currentLanguage ] }
                </button>
              </Col>
            </Row>
          )
        })
      }
    </Col>

  )
}

export default TableOfContents