import React from 'react'

import RenderDOM from '../RenderDOM'

const RowComponent = ({
  content,
  mode,
  onChangeFormBuilderState,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  removeSubmittedFormMedia,
  uploadedMedia,
  currentPage,
  dom,
  findObjectAndPatch,
  isInputDisabled,
  togglePageSelection,
  currentLanguage,
  pageErrors
}) => {
  return (
    <div className="row w-100 m-auto">
      {
        content.children && content.children.map(( item, index ) => {
          return <RenderDOM
            content={ { ...item, rowComponentIndex: index } }
            onChangeFormBuilderState={ onChangeFormBuilderState }
            mode={ mode }
            formValues={ formValues }
            onPatchFormValues={ onPatchFormValues }
            onClickSubmitForm={ onClickSubmitForm }
            createSubmittedFormMedia={ createSubmittedFormMedia }
            removeSubmittedFormMedia={ removeSubmittedFormMedia }
            uploadedMedia={ uploadedMedia }
            currentPage={ currentPage }
            dom={ dom }
            findObjectAndPatch={ findObjectAndPatch }
            data={ {} }
            isInputDisabled={ isInputDisabled }
            togglePageSelection={ togglePageSelection }
            currentLanguage={ currentLanguage }
            pageErrors={ pageErrors }
          /> 
        })
      }
    </div>
  )
}

export default RowComponent