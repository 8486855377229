import React from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const LandingPages = ({
  onChangeCampaignHOC,
  showLandingPageModal,
  userSettings,
  selectedCampaign
}) => {
  return (
    <Modal
      isOpen={ showLandingPageModal }
      size={ 'md' }
      toggle={ () => onChangeCampaignHOC( 'showLandingPageModal', false ) }>
      <ModalHeader toggle={ () => onChangeCampaignHOC( 'showLandingPageModal', false ) }>{ Lang[ 'PREVIEW' ][ userSettings.admin_panel_language ] }</ModalHeader>
      <ModalBody>
        <Label className="w-100">{ selectedCampaign.preview_text?.[ userSettings.admin_panel_language ] }</Label>
        <img
          src={ selectedCampaign.preview_image_url }
          style={{ width: '100%' }}
        />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = state => ({
  userSettings: state.languageReducer.userSettings
})

export default connect( mapStateToProps )( LandingPages )
