import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Get } from 'utils/axios-template'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      locations: [],
      selectedLocation: {}
    }

    load = param => this.setState({ loading: param })

    getLocations = () => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/locations`,
      this.getLocationsSuccess,
      this.getLocationsError,
      this.load
    )
    getLocationsSuccess = payload => this.setState({ locations: payload.data })
    getLocationsError = () => {}

    getLocations = () => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/locations`,
      this.getLocationsSuccess,
      this.getLocationsError,
      this.load
    )
    getLocationsSuccess = payload => this.setState({ locations: payload.data })
    getLocationsError = () => {}

    getSelectedLocation = location_id => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/locations/${ location_id }`,
      this.getSelectedLocationSuccess,
      this.getSelectedLocationError,
      this.load
    )
    getSelectedLocationSuccess = payload => this.setState({ selectedLocation: payload })
    getSelectedLocationError = () => {}

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadLocationsHOC={ this.state.loading }
          locations={ this.state.locations }
          selectedLocation={ this.state.selectedLocation }
          getLocations={ this.getLocations }
          getSelectedLocation={ this.getSelectedLocation }
        />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
