import Axios from 'axios'
import _ from 'lodash'

import { getCipherHeader } from 'utils/cipher-text'
import getDomainURL from 'utils/api'
import { SELECT_CENTER } from './type'
import {
  beginAjaxCall,
  ajaxCallSuccess,
  ajaxCallError
} from './ajax'

export const setSelectCenter = ( payload, isReload ) => {
  return dispatch => {
    dispatch( beginAjaxCall() )
    if( !_.isEmpty( payload ) && !payload.center.center_groups ){
      Axios.defaults.headers = {
        common: {
          Authorization: getCipherHeader()
        }
      }
      Axios.get(
        `${ getDomainURL() }/api/v1/centers/${ payload.center.id }`,
        {
          headers: {
            'X-Host': ''
          }
        }
      ).then( response => {
        let tmp = { 
          ...payload, 
          center: {
            ...response.data,
            value: response.data.id,
            label: response.data.center_name
          } 
        }
        dispatch( selectCenterComplete( tmp ) )
        dispatch( ajaxCallSuccess() )
        if( isReload ){
          setTimeout(() => {
            window.location.reload()
          }, 400)
        }
      }).catch( error => {
        dispatch( selectCenterComplete( {} ) )
        dispatch( ajaxCallError( error ) )
        if( isReload ){
          setTimeout(() => {
            window.location.reload()
          }, 400)
        }
      })
    } else {
      dispatch( selectCenterComplete( payload ) )
      dispatch( ajaxCallSuccess() )
      if( isReload ){
        setTimeout(() => {
          window.location.reload()
        }, 400)
      }
    }
  }
}

const selectCenterComplete = payload => {
  return {
    type: SELECT_CENTER,
    payload
  }
}