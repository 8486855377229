import React, { Component } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Container,
  Col
} from 'reactstrap'
import _ from 'lodash'
import ReactHtmlParser from "react-html-parser"
import Lang from 'Lang/General'

class ViewInfo extends Component {
  render = () => {
    return(
      <Modal isOpen={ this.props.showInfo } size="xl" toggle={ () => this.props.onChangeProductsHOC( 'showInfo', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeProductsHOC( 'showInfo', false ) }>{ Lang[ 'PRODUCT_INFO' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedProduct ) && (
              <Container className="d-flex p-2">
                <Col md={6}>
                  <img src={ this.props.selectedProduct.image_url } style={{ width: '300px', height: '100%', marginBottom: '20px' }}/>
                </Col>
                <Col md={6}>
                  <h2>{ this.props.selectedProduct.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] }</h2>
                  <span>
                    {
                      ReactHtmlParser( this.props.selectedProduct.description[ this.props.data.languageReducer.userSettings.admin_panel_language ] )
                    }
                  </span>
                  <p className="mt-3">
                    {
                      !_.isEmpty( this.props.selectedProduct.pdf ) && this.props.selectedProduct.pdf_url && this.props.selectedProduct.pdf_url !== "" && (
                        <a href={ this.props.selectedProduct.pdf_url } target="_blank" className="mb-2">{ Lang['DOWNLOAD_PDF'][this.props.data.languageReducer.userSettings.admin_panel_language] }</a>
                      )
                    }
                  </p>
                </Col>
              </Container>
            )
          }
          {
            this.props.selectedProduct.is_requested &&
              <p style={{ color: 'red' }}>
                { Lang[ 'ALREADY_REQUESTED_INFO' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
              </p>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            style={{
              display: this.props.selectedProduct.is_requested ? 'none' : ''
            }}
            onClick={ () => {
              let tmp = _.cloneDeep( this.props.newRequest )
              tmp = {
                client_id: this.props.data.selectCenterReducer.selectedCenter.center.client_id,
                consultant_id: this.props.data.selectCenterReducer.selectedCenter.center.consultant_id,
                user_id: this.props.data.profileReducer.profile.id,
                status_id: 1,
                product_id: this.props.selectedProduct.id,
                center_id: this.props.data.selectCenterReducer.selectedCenter.center.id
              }
              this.props.onChangeRequestHOC( 'newRequest', tmp )
              this.props.onChangeProductsHOC( 'showInfo', false )
              this.props.createProductsRequest( tmp, this.props.selectedProduct.id, false )
            } }>{ Lang['REQUEST_INFO' ][ this.props.data.languageReducer.userSettings.admin_panel_language ]}</Button>
          <Button onClick={ () => this.props.onChangeProductsHOC( 'showInfo', false ) }>{ Lang[ 'CLOSE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ]}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default ViewInfo
