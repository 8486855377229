import React from 'react'
import { Button } from 'reactstrap'
import { MdEdit } from 'react-icons/md'
import { FaRegTrashAlt } from 'react-icons/fa'

import RenderDOM from '../RenderDOM'

import Lang from 'Lang/General'

const ColumnComponent = props => {
  let {
    content,
    mode,
    onChangeFormBuilderState,
    data
  } = props

  return (
    <div 
      className={ `col-md-${ content.grid }` } 
      style={{ position: 'relative' }}>
      {
        content.children && content.children.map( item => {
          return <RenderDOM { ...props } content={ item } isInputDisabled={ false } />
        })
      }
      {
        !content.children && (
          <div>
            <span>{ Lang[ 'PUT_SOMETHING_HERE' ][ data.languageReducer.lang ] }</span>
          </div>
        )
      }
      {
        mode === 'edit' && <Button 
          style={{ position: 'absolute', top: 5, right: 5 }}
          onClick={ async() => {
            await onChangeFormBuilderState( 'selectedComponentToEdit', {
              ...content
            })
            await onChangeFormBuilderState( 'showUpdateColumnModal', true )
          }}>
          <MdEdit color="#fff" size={ 16 } />
        </Button>
      }
      {
        mode === 'edit' && <Button
          color="danger"
          style={{ position: 'absolute', top: 5, right: 50 }}
          onClick={ async() => {
            await onChangeFormBuilderState( 'selectedComponentToRemove', content )
            await onChangeFormBuilderState( 'showConfirmDelete', true )
          }}>
          <FaRegTrashAlt color="#fff" size={ 16 } />
        </Button>
      }
    </div>
  )
}

export default ColumnComponent