import React, { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import _ from 'lodash'

import SiteSettingsHOC from 'actions/SiteSettings'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const InitialConsent = ({
  data,
  contract,
  imprint,
  policy,
  closeInitialConsentModal,
  lang,
  siteSettings,
  getSiteSettings,
  onLoadSiteSettingsHOC
}) => {
  useEffect( () => {
    if( !_.isEmpty( data.selectCenterReducer.selectedCenter ) ) {
      getSiteSettings( data.selectCenterReducer.selectedCenter.center.site.id )
    }
  }, [ data.selectCenterReducer.selectedCenter ] )

  return (
    <>
      <Modal 
        size='lg'
        isOpen={ siteSettings.is_show_data_protection_agreement }>
        <ModalHeader toggle={ () => closeInitialConsentModal() }>{ Lang[ 'CONSENT_MODAL' ][ lang ] }</ModalHeader>
        <ModalBody>
          { Lang[ 'WELCOME_MESSAGE' ][ lang ] }
          <p>{ Lang[ 'CONSENT_INSTRUCTION' ][ lang ] }</p>
          <div className="m-4">
            {
              !_.isEmpty( contract ) && (
                <>
                  <span className="font-weight-bold">{ Lang[ 'AGREED_ON_CONTRACT' ][ lang ] }</span>
                  {
                    !_.isEmpty( contract ) && contract.is_agreed ? (
                      <span className="badge badge-pill badge-success p-2 ml-2" style={{ fontSize: '12px' }}>{ Lang[ 'COMPLETED' ][ lang ] }</span>
                    ) : (
                      <ul>
                        <li>{ Lang[ 'AV_CONTRACT_STEP_1' ][ lang ] }</li>
                        <li>{ Lang[ 'AV_CONTRACT_STEP_2' ][ lang ] }</li>
                        <li>{ Lang[ 'AV_CONTRACT_STEP_3' ][ lang ] }</li>
                      </ul>
                    )
                  }
                </>
              )
            }
          </div>
          <div className="m-4">
            {
              ( ( !_.isEmpty( imprint ) && !Object.values( imprint.content ).every( item => item === '' ) ) || 
              ( !_.isEmpty( policy ) && !Object.values( policy.content ).every( item => item === '' ) ) ) && (
                <>
                  <span className="font-weight-bold">{ Lang[ 'AGREED_TO_POLICY_AND_IMPRINT' ][ lang ] }</span>
                  {
                    policy.is_signed_policy && imprint.is_signed_imprint ? (
                      <span className="badge badge-pill badge-success p-2 ml-2" style={{ fontSize: '12px' }}>{ Lang[ 'COMPLETED' ][ lang ] }</span>
                    ) : (
                      <ul>
                        <li>{ Lang[ 'POLICY_IMPRINT_STEP_1' ][ lang ] }</li>
                        <li>{ Lang[ 'POLICY_IMPRINT_STEP_2' ][ lang ] }</li>
                        <li>{ Lang[ 'POLICY_IMPRINT_STEP_3' ][ lang ] }</li>
                      </ul>
                    )
                  }
                </>
              )
            }
          </div>
        </ModalBody>
      </Modal>
      { onLoadSiteSettingsHOC && <LoadingOverlay/> }
    </>
  )
}

export default SiteSettingsHOC( InitialConsent )
