import React from "react";
import {
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom"

import ForgetPassword from './containers/ForgetPassword'
import Login from './containers/Login'
import LoginStoreToken from './containers/Login/StoreToken'
import LoginUser from './containers/Login/User'
import DigitalOrderFormExternalLogin from './containers/DigitalOrderForm/ExternalLink'
import SetPassword from './containers/SetPassword'
import Dashboard from './containers/Dashboard'

import { getToken } from 'utils/findCookieToken'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      ( getToken( 'ACISO_SESSION_TOKEN' ).length > 2 )
        ? <Component {...props} />
        : <Redirect to='/' />
    )} />
  )
}

const ACMainRouter = ({ location }) => {
  return (
    <Switch>
      <Route exact path="/" component={ Login } initialPath />
      <Route path="/login/:id/:center_id" component={ LoginStoreToken } />
      <Route path="/login-user/:id/" component={ LoginUser } />
      <Route exact path="/forget-password" component={ ForgetPassword } />
      <Route exact path="/forget-password/redirect/:resetPasswordToken" component={ ForgetPassword } />
      <Route exact path="/member/forget-password/redirect/:resetPasswordToken" component={ ForgetPassword } />
      <Route exact path="/digital-order-form/external/:id" component={ DigitalOrderFormExternalLogin } />
      <Route exact path="/digital-order-form/external/:id/:order_id" component={ DigitalOrderFormExternalLogin } />
      <Route exact path="/user/first-time/:setPasswordToken" component={ SetPassword } />
      <PrivateRoute path="/dashboard" component={ Dashboard } />
    </Switch>
  )
}

export default withRouter( ACMainRouter )