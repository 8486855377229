import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios-token'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      messages: [],
      attachments: [],
      newMessage: {
        from_user_id: 0,
        to_user_id: null,
        form_result_id: 0,
        text: ''
      },
      newAttachment: {
        form_result_id: 0,
        attachment: {
          raw: '',
          filename: ''
        }
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error !== 'string' 
      ? Object.values( error ).map( val => toast.error( val ) )
      : toast.error( error )

    onChangeMessagesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getMessages = () => Get(
      `/api/v1/submitted_digital_order_forms/${ this.props.selectedSubmittedForm.id }/messages`,
      this.getMessagesSuccess,
      this.getMessagesError,
      this.load
    )
    getMessagesSuccess = payload => {
      this.setState({ messages: payload })
    }
    getMessagesError = error => this.requestError( error )

    getAttachments = () => Get(
      `/api/v1/submitted_digital_order_forms/${ this.props.selectedSubmittedForm.id }/message_files`,
      this.getAttachmentsSuccess,
      this.getAttachmentsError,
      this.load
    )
    getAttachmentsSuccess = payload => {
      this.setState({
        attachments: payload
      })
    }
    getAttachmentsError = error => this.requestError( error )

    createMessage = data => Post(
      `/api/v1/submitted_digital_order_forms/${ this.props.selectedSubmittedForm.id }/messages`,
      data,
      this.createMessageSuccess,
      this.createMessageError,
      this.load
    )
    createMessageSuccess = payload => {
      this.setState({
        newMessage: {
          from_user_id: 0,
          to_user_id: 0,
          form_result_id: 0,
          text: ''
        }
      }, () => {
        this.getMessages()
        this.props.updateSubmittedForm( this.props.selectedSubmittedForm.center_id, this.props.selectedSubmittedForm.id, {
          ...this.props.selectedSubmittedForm,
          is_new_message: true,
          status_id: this.props.selectedSubmittedForm.status.id,
          digital_order_form_id: this.props.selectedSubmittedForm.digital_order_form.id
        })
      })
    }
    createMessageError = error => this.requestError( error )

    createAttachment = data => Post(
      `/api/v1/submitted_digital_order_forms/${ this.props.selectedSubmittedForm.id }/message_files`,
      data,
      this.createAttachmentSuccess,
      this.createAttachmentError,
      this.load
    )
    createAttachmentSuccess = payload => {
      this.setState({
        newAttachment: {
          form_result_id: 0,
          attachment: {
            raw: ''
          }
        }
      }, () => {
        this.getAttachments()
      })
    }
    createAttachmentError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadMessages={ this.state.loading }
          messages={ this.state.messages }
          newMessage={ this.state.newMessage }
          attachments={ this.state.attachments }
          newAttachment={ this.state.newAttachment }
          onChangeMessagesHOC={ this.onChangeMessagesHOC }
          getMessages={ this.getMessages }
          getAttachments={ this.getAttachments }
          createMessage={ this.createMessage }
          createAttachment={ this.createAttachment } />
      )
    }
  }
  return WithHOC
}

export default HOC