import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import ReactTable from 'react-table'
import Moment from 'moment'

import Lang from 'Lang/General'

const Sites = props => {
  const [ pages, setPages ] = useState( [ 1 ] )
  const [ currentPage, setCurrentPage ] = useState( 1 )

  useEffect( () => {
    if ( props.matomoSearchParams.filterOffset === 0 ) {
      setPages( [ 1 ] )
      setCurrentPage( 1 )
    } else if ( props.matomoSearchParams.filterOffset < props.matomoVisitsByPage.length ) {
      setPages( 
        Array.from(
          { length: props.matomoSearchParams.filterOffset/props.PAGE_SIZE + 1 },
          ( _, index ) => index + 1
        ) 
      )
      setCurrentPage( props.matomoSearchParams.filterOffset/props.PAGE_SIZE + 1 )
    }
  }, [ props.matomoVisitsByPage ] )

  return(
    <>
      <ReactTable
        showPagination={ false }
        defaultPageSize={ props.PAGE_SIZE }
        NoDataComponent={ () => <span></span> }
        data={ props.matomoVisitsByPage.slice( 
          currentPage > 1 
            ? ( currentPage - 1 ) * props.PAGE_SIZE - 1
            : 0, 
          currentPage * props.PAGE_SIZE
        )}
        columns={[
          {
            Header: Lang[ 'SITE_URL' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            accessor: 'label',
            Cell: ({ value }) => <span style={{ wordBreak: 'break-word' }} >{ value === '/index' ? '/' : value }</span>
          },
          {
            Header: Lang[ 'VISITS' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            accessor: 'nb_hits'
          },
          {
            Header: Lang[ 'AVERAGE_TIME_ON_SITE' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            accessor: 'avg_time_on_page',
            Cell: ({ value }) => Moment.utc( value * 1000 ).format( 'HH:mm:ss' )
          },
          {
            Header: Lang[ 'SITE_LOAD_TIME' ][ props.data.languageReducer.userSettings.admin_panel_language ],
            accessor: 'avg_page_load_time',
            Cell: ({ value }) => `${ parseFloat( value ).toFixed( 2 ) }s`
          },
        ]}
      />
      <div className="pt-5 text-center">
        <div className="pt-1">
          <Button
            className="mr-1"
            color="primary"
            style={{ display: 'inline-block' }}
            disabled={ currentPage === 1 }
            onClick={ () => setCurrentPage( currentPage - 1 ) }>
            { Lang[ 'PREV' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
          </Button>
          {
            pages.map(( item ) => {
              return (
                <Button
                  key={ `Pagination-${ item }` }
                  outline
                  className="mr-1 border-0 btn-transition"
                  style={{ display: 'inline-block' }}
                  color="dark"
                  onClick={() => setCurrentPage( item )}
                  active={ item === currentPage }>
                  { item }
                </Button>
              )
            })
          }
          {
            props.matomoSearchParams.filterOffset + props.PAGE_SIZE <= props.matomoVisitsByPage.length && (
              <Button
                className="ml-1"
                style={{ display: 'inline-block' }}
                color="primary"
                onClick={ () => props.getMatomoStats({ 
                  ...props.matomoSearchParams, 
                  filterOffset: props.matomoSearchParams.filterOffset + props.PAGE_SIZE
                })}>
                { Lang[ 'MORE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
              </Button>
            )
          }
        </div>
      </div>
    </>
  )
}

export default Sites