import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Post, Put, Delete } from 'utils/axios-token'
import { Get } from 'utils/axios-template'

const HOC = WrappedComponent => {
  class WithHOC extends Component{
    state = {
      loading: false,
      showCreateCookieContent: false,
      showUpdateCookieContent: false,
      cookieContents: [],
      selectedCookieContent: {},
      newCookieContent: {
        title: {
          en: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          nl: ''
        },
        description: {
          en: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          nl: ''
        },
        cookie_name: '',
        cookie_script: '',
        editor: '',
        url_publisher: '',
        running_time: '',
        position: 1,
        status: 'ACTIVE',
        cb_category_id: 0,
        showPromptModal: false,
        toRemoveID: 0
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeCookieContentHOC = ( key, val ) => this.setState({ [ key ] : val })

    createCookieContent = data => Post(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookies`,
      data,
      this.createCookieContentSuccess,
      this.createCookieContentError,
      this.load
    )
    createCookieContentSuccess = () => {
      this.getCookieContents( this.props.data.selectCenterReducer.selectedCenter.center.id )
      this.setState({ showCreateCookieContent: false })
      this.requestSuccess( 'Cookie content is successfully created' )
    }
    createCookieContentError = error => this.requestError( error )

    getCookieContents = () => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookies`,
      this.getCookieContentsSuccess,
      this.getCookieContentsError,
      this.load
    )
    getCookieContentsSuccess = payload => this.setState({ cookieContents: payload })
    getCookieContentsError = error => this.requestError( error )

    getSelectedCookieContent = content_id => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookies/${ content_id }`,
      this.getSelectedCookieContentSuccess,
      this.getSelectedCookieContentError,
      this.load
    )
    getSelectedCookieContentSuccess = payload => this.setState({ selectedCookieContent: payload, showUpdateCookieContent: true })
    getSelectedCookieContentError = error => this.requestError( error )

    updateCookieContent = data => Put(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookies/${ data.id }`,
      data,
      this.updateCookieContentSuccess,
      this.updateCookieContentError,
      this.load
    )
    updateCookieContentSuccess = () => {
      this.getCookieContents( this.props.data.selectCenterReducer.selectedCenter.center.id )
      this.setState({ showUpdateCookieContent: false })
      this.requestSuccess( 'Cookie content is successfully updated' )
    }
    updateCookieContentError = error => this.requestError( error )

    removeCookieContent = content_id => Delete(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/cookies/${ content_id }`,
      this.removeCookieContentSuccess,
      this.removeCookieContentError,
      this.load
    )
    removeCookieContentSuccess = () => {
      this.setState({ showPromptModal: false })
      this.getCookieContents( this.props.data.selectCenterReducer.selectedCenter.center.id )
      this.requestSuccess( 'Cookie content is successfully removed' )
    }
    removeCookieContentError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadCookieContentHOC={ this.state.loading }
          onChangeCookieContentHOC={ this.onChangeCookieContentHOC }
          showCreateCookieContent={ this.state.showCreateCookieContent }
          showUpdateCookieContent={ this.state.showUpdateCookieContent }
          cookieContents={ this.state.cookieContents }
          selectedCookieContent={ this.state.selectedCookieContent }
          newCookieContent={ this.state.newCookieContent }
          showPromptModal={ this.state.showPromptModal }
          toRemoveID={ this.state.toRemoveID }
          createCookieContent={ this.createCookieContent }
          getCookieContents={ this.getCookieContents }
          getSelectedCookieContent={ this.getSelectedCookieContent }
          updateCookieContent={ this.updateCookieContent }
          removeCookieContent={ this.removeCookieContent } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
