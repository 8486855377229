import React from 'react'
import {
  Input, Label,
  FormGroup, FormFeedback, UncontrolledTooltip
} from 'reactstrap'
import Moment from 'moment'
import ReactHTMLParser from 'react-html-parser'
import _ from 'lodash'

import { generateFieldLabelStr } from '../utils'

const DateField = ({
  content,
  formValues,
  onPatchFormFormValues,
  isInputDisabled,
  currentLanguage,
  pageErrors
}) => {
  return(
    <FormGroup id={ content.id } style={{ padding: content.padding, marginBottom: 0, ...content.style }}>
      <Label className="ck-content" style={{ fontSize: content.fontSize, width: "100%" }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }
        {
          content.showQuickInfo && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      <Input
        type="date"
        disabled={ isInputDisabled }
        invalid={ _.findIndex( pageErrors, { id: content.id } ) > -1 }
        value={ Moment( formValues[ content.dataMapIndex ][ 'value' ] ).format( 'YYYY-MM-DD' ) }
        onChange={ e =>{
          onPatchFormFormValues( 
          content.dataMapIndex, 
          'value', 
          Moment( e.target.value )._isValid
            ? Moment( e.target.value ).utc().format() 
            : ''
        )}}/>
      <FormFeedback>
        { 
          _.find( pageErrors, { id: content.id } ) &&
          _.find( pageErrors, { id: content.id } ).validation[ currentLanguage ] 
        }
      </FormFeedback>
    </FormGroup>
  )
}

export default DateField