import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios-token'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      RGErrorMessage: {},
      step: 1,
      searchParams: [
        {
          label: 'Name',
          value: 'name',
          param: ''
        },
        {
          label: 'Status',
          value: 'status',
          param: ''
        }
      ],
      recipientGroups: [],
      selectedRecipientGroup: {
        "name": {
           "en": "",
           de: '',
           fr: '',
           nl: '',
           it: '',
           es: ''
        },
        "status": "ACTIVE",
        recipients: []
      },
      newRecipientGroup: {
        "name": {
           "en": "",
           de: '',
           fr: '',
           nl: '',
           it: '',
           es: ''
        },
        "status": "ACTIVE"
      },
      showCreateRecipientGroup: false,
      showUpdateRecipientGroup: false,
      recipientGroupForSelect: [],
      showViewRecipientGroup: false
    }

    onChangeRecipientGroupHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error, {
      position: "bottom-right"
    })

    getAllRecipientGroup = ( query ) => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_recipient_groups?query=${query}`,
      this.getAllRecipientGroupSuccess,
      this.getAllRecipientGroupError,
      this.load
    )
    getAllRecipientGroupSuccess = payload => {
      let tmp = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmp.push({
            ...item,
            label: item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ],
            value: item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ]
          })
        })
      } else {
        this.requestError( Lang['NO_RESULT_FOUND'][this.props.data.languageReducer.userSettings.admin_panel_language])
      }
      this.setState({ recipientGroups: payload, recipientGroupForSelect: tmp })
    }
    getAllRecipientGroupError = error => this.requestError( error )

    createRecipientGroup = ( data ) => Post(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_recipient_groups`,
      data,
      this.createRecipientGroupSuccess,
      this.createRecipientGroupError,
      this.load
    )
    createRecipientGroupSuccess = () => {
      this.setState({ showCreateRecipientGroup: false })
      this.getAllRecipientGroup( convertSearchObjectToBase64({ is_paginated: true, page:1 }) )
      this.requestSuccess( Lang['CREATE_GROUP_SUCCESS'][ this.props.data.languageReducer.userSettings.admin_panel_language ])
    }
    createRecipientGroupError = ( error ) => this.requestError( error )

    getSelectedRecipientGroup = id => Get(
      `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_recipient_groups/${id}`,
      this.getSelectedRecipientGroupSuccess,
      this.getSelectedRecipientGroupError,
      this.load
    )
    getSelectedRecipientGroupSuccess = payload => {
      this.setState({ selectedRecipientGroup: payload })
    }
    getSelectedRecipientGroupError = error => this.requestError( error )

    updateRecipientGroup = (id, data) => {
      Put(
        `/api/v1/centers/${this.props.data.selectCenterReducer.selectedCenter.center.id}/funnel_recipient_groups/${id}`,
        data,
        this.updateRecipientGroupSuccess,
        this.updateRecipientGroupError,
        this.load
      )
    }
    updateRecipientGroupSuccess = () => {
      this.requestSuccess( Lang['UPDATE_GROUP_SUCCESS'][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.setState({ showUpdateRecipientGroup: false })
      this.getAllRecipientGroup( convertSearchObjectToBase64({ is_paginated: true, page: 1 }) )
    }
    updateRecipientGroupError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadRecipientGroup={ this.state.loading }
          getAllRecipientGroup={ this.getAllRecipientGroup }
          recipientGroups={ this.state.recipientGroups }
          selectedRecipientGroup={ this.state.selectedRecipientGroup }
          newRecipientGroup={ this.state.newRecipientGroup }
          showCreateRecipientGroup={ this.state.showCreateRecipientGroup }
          showUpdateRecipientGroup={ this.state.showUpdateRecipientGroup }
          onChangeRecipientGroupHOC={ this.onChangeRecipientGroupHOC }
          step={ this.state.step }
          emailEditor={ this.state.emailEditor }
          createRecipientGroup={ this.createRecipientGroup }
          getSelectedRecipientGroup={ this.getSelectedRecipientGroup }
          updateRecipientGroup={ this.updateRecipientGroup }
          RGErrorMessage={ this.state.RGErrorMessage }
          recipientGroupForSelect={ this.state.recipientGroupForSelect }
          showViewRecipientGroup={ this.state.showViewRecipientGroup }
          searchParams={ this.state.searchParams } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
