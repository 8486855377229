import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      consultants: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )

    getConsultants = ( query ) => Get(
      `/api/v1/consultants?query=${ query }`,
      this.getConsultantsSuccess,
      this.getConsultantsError,
      this.load
    )
    getConsultantsSuccess = payload => {
      let tmp = []
      payload.data && payload.data.map( item => {
        tmp.push({
          id: item.id,
          label: `${ item.user.first_name } ${ item.user.last_name }`,
          value: item.id
        })
      })
      this.setState({ consultants: _.orderBy( tmp, [ 'label' ] ) })
    }
    getConsultantsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getConsultants={ this.getConsultants }
          consultants={ this.state.consultants }
          onLoadConsultants={ this.state.loading } />
      )
    }
  }
  return WithHOC
}

export default HOC