export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const AJAX_CALL_SUCCESS = "AJAX_CALL_SUCCESS";
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";

export const AUTH_USER = "AUTH_USER";

export const GET_DICTIONARY = 'GET_DICTIONARY'
export const GET_PROFILE = 'GET PROFILE'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'

export const GET_SELECTED_ROLE = 'GET_SELECTED_ROLE'
export const CLEAR_SELECTED_ROLE = 'CLEAR_SELECTED_ROLE'

export const STORE_LANG = 'STORE_LANG'

export const TOGGLE_MEDIA_DATABASE = 'TOGGLE_MEDIA_DATABASE'

export const STORE_SIDEMENU = 'STORE_SIDEMENU'
export const STORE_ASSIGNED_SIDEMENU = 'STORE_ASSIGNED_SIDEMENU'

export const GET_PLATFORM_CONFIG = 'GET_PLATFORM_CONFIG'

export const SELECT_CENTER = 'SELECT_CENTER'

export const TOGGLE_LOGIN_BOX = 'TOGGLE_LOGIN_BOX'