import React from "react"
import { Modal, ModalBody} from "reactstrap"

const Banner = (props) => {
  return (
    <>
      <style>{`.shortcut-image-modal .modal-content{background:transparent;border:none;width:90%;}`}</style>
      <Modal
        className="shortcut-image-modal"
        isOpen={props.isOpen}
        centered
        style={{ maxWidth: "unset", boxShadow: "unset", justifyContent:"center" }}
        toggle={props.toggleModalState}
      >
        <ModalBody style={{textAlign:"center"}}>
          <img alt={props.desc} src={props.src} style={{width:"100%"}} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default Banner
