import React, { Component } from 'react'
import {
  FormGroup,
  Label,
  Input
} from 'reactstrap'

class NormalSearch extends Component {
  render = () => {
    return(
      <FormGroup>
        <Label>{ this.props.searchLabel }</Label>
        <Input
          name="search-value" id="searchValue"
          value={ this.props.normalSearchValue }
          onKeyDown={ this.props.handleKeyPress }
          onChange={ e => this.props.updateNormalSearchValue( 'normalSearchValue', e.target.value ) }/>
      </FormGroup>
    )
  }
}
export default NormalSearch
