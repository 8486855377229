import Axios from 'axios'
import {
  STORE_LANG
} from './type'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax';
import { getToken } from 'utils/findCookieToken'
import getDomainURL from 'utils/api';
import { ToastContainer, toast } from 'react-toastify'

export const getUserSetting = () => {
  return dispatch => {
    dispatch( beginAjaxCall() )
    Axios.get(
      `${ getDomainURL() }/api/v1/user_settings`
    ).then( response => {
      dispatch( getUserSettingSuccess( response.data ) )
      dispatch( ajaxCallSuccess() )
    }).catch( error => {
      dispatch( ajaxCallError( error ) )
    })
  }
}

const getUserSettingSuccess = payload => {
  return {
    type: STORE_LANG,
    payload
  }
}

export const updateUserSetting = data => {
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${ getToken( 'ACISO_SESSION_TOKEN' ) }`
    }
  }
  return dispatch => {
    dispatch( beginAjaxCall() )
    Axios.put(
      `${ getDomainURL() }/api/v1/user_settings`,
      data
    ).then( response => {
      dispatch( ajaxCallSuccess( 'update successfully') )
      dispatch( getUserSetting() )
      dispatch( toast.success( 'update successfully' ) )
    }).catch( error => {
      dispatch( ajaxCallError( error ) )
    })
  }
}
