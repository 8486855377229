import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  UncontrolledTooltip,
  Form,
  FormGroup,
  CardBody,
  Label,
  Input,
  CardHeader
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import Select from 'react-select'
import Moment from 'moment'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateNewsletters from './Create'
import NewsletterView from './View'
import ArkPagination from '../components/pagination'
import AlertNotSubscribe from 'components/Alert'

import WithTemplates from './actions/templates'
import WithNewsletters from './actions'
import WithContentServices from './actions/contentServices'
import SenderHOC from '../Senders/actions'
import RecipientGroupHOC from '../RecipientGroup/actions'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

class NewsletterListings extends Component {
  state = {
    subscribedPackage: true
  }

  componentDidMount = () => {
    let tmp = {
      is_paginated: true,
      page: 1,
      is_blacklisted: false
    }
    let tmpSenderString = convertSearchObjectToBase64({
      ... tmp,
      is_paginated: false,
    })
    let tmpString = convertSearchObjectToBase64( tmp )
    this.props.getRecipientGroup( convertSearchObjectToBase64({ is_paginated: false, status: 'ACTIVE' }) )
    this.props.getNewsletter( tmpString )
    this.props.getSenders( tmpSenderString )
    this.props.data.selectCenterReducer.selectedCenter.center?.site?.id && this.props.getNews()
    this.props.getContentServices( convertSearchObjectToBase64({ 
      is_paginated: false, 
      filter: {
        status: 'ACTIVE',
        ...( 
          this.props.data.selectCenterReducer.selectedCenter.center.center_groups && this.props.data.selectCenterReducer.selectedCenter.center.center_groups.length > 0 && (
            {
              $or: [
                ...( this.props.data.selectCenterReducer.selectedCenter.center.center_groups.map( item => {
                  return { center_group_id: item.id }
                }))
              ]
            }
          )
        )
      }
    }) )
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Newsletters' })
    this.setState({ subscribedPackage: tmpIndex > -1 })

    this.props.getTemplates( convertSearchObjectToBase64({
      is_paginated: false,
      filter: {
        '$or': this.props.data.selectCenterReducer.selectedCenter.center.center_groups.map( item => ({ center_group_id: item.id }) )
      }
    }))
  }

  componentDidUpdate = pp => {
    if( pp.showCreateFromTemplate && !this.props.showCreateFromTemplate ){
      this.props.onChangeNewsletterHOC( 'newNewsletterData', {
        subject: "",
        title:"",
        html_content: "",
        json_content: "",
        do_save_json: false,
        do_send_now: true,
        is_draft: false,
        is_scheduled: false,
        sender_id: 0,
        recipient_groups: [],
        delivered_at: Moment().add( 5, 'minutes' ),
        image: {
          raw: ''
        }
      })
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_create && this.state.subscribedPackage
    }
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'NEWSLETTER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-news-paper icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_NEWSLETTER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
              onClick: () => {
                this.props.onChangeNewsletterHOC( 'showCreateFromTemplate', true )
                this.props.onChangeNewsletterHOC( 'processType', 'create' )
              },
              display: this.handleSecurityModulesCreate()
            }
          ]}
           />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            {
              !this.state.subscribedPackage && (
                <AlertNotSubscribe { ...this.props } />
              )
            }
            <Row>
              <Col md={ 12 }>
                <Card className="main-card mb-3">
                  <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                  <CardBody>
                    <Form onSubmit={ e => e.preventDefault()}>
                      <FormGroup>
                        <Label>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                        <Input
                          type={ 'text' }
                          value={ this.props.searchParams[0].param }
                          onChange={ e => {
                            let tmp = _.cloneDeep( this.props.searchParams )
                            tmp[ 0 ][ 'param' ] = e.target.value
                            return this.props.onChangeNewsletterHOC( 'searchParams', tmp )
                          }} />
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'RECIPIENT_GROUPS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                        <Select
                          isMulti
                          options={ this.props.recipientGroupForSelect }
                          className="basic-multi-select"
                          value={ this.props.searchParams[ 1 ].param }
                          onChange={ val => {
                            let tmp = _.cloneDeep( this.props.searchParams )
                            let tmpValue = []
                            val && val.map( item => {
                              tmpValue.push({
                                recipient_group_id: item.id
                              })
                            })
                            tmp[ 1 ][ 'ids' ] = tmpValue
                            tmp[ 1 ][ 'param' ] = val
                            return this.props.onChangeNewsletterHOC( 'searchParams', tmp )
                          }} />
                      </FormGroup>
                      <FormGroup>
                        <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                        <Input
                          type="select"
                          name="status"
                          id="status"
                          invalid={ /*errorMessage && errorMessage.status ? true :*/ false }
                          value={ this.props.searchParams[ 2 ].param }
                          onChange={ e => {
                            let tmp = _.cloneDeep( this.props.searchParams )
                            tmp[ 2 ][ 'param' ] = e.target.value
                            return this.props.onChangeNewsletterHOC( 'searchParams', tmp )
                          }}>
                          <option>{'Please select one from below'}</option>
                          <option value={ 'draft' }>{ 'Draft' }</option>
                          <option value={ 'scheduled' }>{ 'Scheduled' }</option>
                          <option value={ 'sent' }>{ 'Sent' }</option>
                          <option value={ 'fail' }>{ 'Fail' }</option>
                        </Input>
                      </FormGroup>
                      <div className="d-flex">
                        <Button
                          color="primary"
                          style={{ marginLeft: 'auto' }}
                          onClick={() => {
                            let tmp = {}
                            this.props.searchParams.map( searchItem => {
                              if( !_.isEmpty( searchItem.param ) && searchItem.value !== 'recipient_groups' ){
                                tmp = {
                                  ...tmp,
                                  [searchItem.value]: searchItem.value === 'title' ? { $like: `%${ searchItem.param }%` } : searchItem.param
                                }
                              }
                              if( searchItem.value === 'recipient_groups' && !_.isEmpty( searchItem.param ) ){
                                tmp = {
                                  ...tmp,
                                  $or: searchItem.ids
                                }
                              }
                            })
                            let query = {
                              page: 1,
                              is_paginated: true,
                              filter: tmp
                            }
                            this.props.getNewsletter( convertSearchObjectToBase64( query ) )
                          }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                        <Button
                          color="danger"
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            let tmp = [
                              {
                                label: 'Title',
                                value: 'title',
                                param: ''
                              },
                              {
                                label: 'Recipient Groups',
                                value: 'recipient_groups',
                                param: [],
                                ids: []
                              },
                              {
                                label: 'Status',
                                value: 'status',
                                param: ''
                              }
                            ]
                            this.props.onChangeNewsletterHOC( 'searchParams', tmp )
                            this.props.getNewsletter( convertSearchObjectToBase64({ page: 1, is_paginated: true }) )
                          }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col md={ 12 }>
                <Card className="main-card mb-3 p-4">
                  <ReactTable
                    NoDataComponent={ () => <span></span> }
                    data={ this.props.newsletters.data }
                    columns={[
                      {
                        Header: Lang[ 'TITLE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                        accessor: 'title'
                      },
                      {
                        Header: Lang[ 'DELIVERED_AT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                        accessor: 'delivered_at',
                        Cell: row => {
                          return Moment( row.original.delivered_at ).format( 'DD/MM/YYYY HH:mm' )
                        }
                      },
                      {
                        Header: Lang[ 'RECIPIENT_GROUPS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                        accessor: 'current_recipient_group_data',
                        style: { lineBreak: 'anywhere' },
                        Cell: ({ value }) => <ul>
                          {
                            value && value.map( item => (
                              <li key={ item.id }>{ item.name } ( { item.active_recipient_count } )</li>
                            ))
                          }
                        </ul>
                      },
                      {
                        Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                        Cell: ( row ) => (
                          <>
                            {
                              row.original.status === 'sent' && (
                                <span>{ Lang[ 'SENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</span>
                              )
                            }
                            {
                              row.original.status === 'draft' && (
                                <span>{ Lang[ 'DRAFT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</span>
                              )
                            }
                            {
                              row.original.status === 'scheduled' && (
                                <span>{ Lang[ 'SCHEDULED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</span>
                              )
                            }
                            {
                              row.original.status === 'fail' && (
                                <span>{ Lang[ 'FAILED_TO_SEND' ][ this.props.data.languageReducer.userSettings.admin_panel_language] }</span>
                              )
                            }
                          </>
                        )
                      },
                      {
                        Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                        accessor: "id",
                        Cell: (row) => (
                          <div>
                            <Button
                              id={ `Newsletters-${ row.original.id }-view` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => this.props.getSelectedNewsletter( row.original.id, 'view' ) }
                              disabled={ !this.state.subscribedPackage }>
                              <i className="pe-7s-look"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Newsletters-${ row.original.id }-view` }
                              placement="top">
                              { Lang[ 'VIEW_NEWSLETTER_DETAILS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                            </UncontrolledTooltip>
                            {
                              (row.original.status === 'draft' || row.original.status === 'scheduled') && <>
                                <Button
                                  id={ `Newsletters-${ row.original.id }-update` }
                                  className="mb-2 mr-2 btn-icon btn-icon-only"
                                  color="primary"
                                  onClick={ () => this.props.getSelectedNewsletter( row.original.id, 'update' ) }
                                  disabled={ !this.state.subscribedPackage }>
                                  <i className="pe-7s-pen"> </i>
                                </Button>
                                <UncontrolledTooltip
                                  target={ `Newsletters-${ row.original.id }-update` }
                                  placement="top">
                                  { Lang[ 'UPDATE_NEWSLETTER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                </UncontrolledTooltip>
                              </>
                            }
                            {
                              row.original.status === 'fail' && (
                                <Button
                                  id={ `Newsletters-${ row.original.id }-resend` }
                                  className="mb-2 mr-2 btn-icon btn-icon-only"
                                  color="warning"
                                  onClick={ () => {
                                    let tmp = {
                                      page: this.props.newsletters.meta.current_page,
                                      is_paginated: true
                                    }
                                    this.props.resendNewsletter( row.original.id, convertSearchObjectToBase64( tmp ) )
                                  }}
                                  disabled={ !this.state.subscribedPackage }>
                                  { Lang[ 'RESEND' ][ this.props.data.languageReducer.userSettings.admin_panel_language] }
                                </Button>
                              )
                            }
                          </div>
                        )
                      }
                    ]}
                    showPagination={ false }
                    defaultPageSize={ 10 } />
                    {
                      this.props.newsletters.data && this.props.newsletters.data.length > 0 && (
                        <ArkPagination
                          meta={ this.props.newsletters.meta }
                          onChangePage={ page => {
                            let tmp = {
                              page: page,
                              is_paginated: true
                            }
                            let tmpFilter = {}

                            this.props.searchParams.map( searchItem => {
                              if( !_.isEmpty( searchItem.param ) && searchItem.value !== 'recipient_groups' ){
                                tmpFilter = {
                                  ...tmpFilter,
                                  [searchItem.value]: searchItem.value === 'title' ? { $like: `%${ searchItem.param }%` } : searchItem.param
                                }
                              }
                              if( searchItem.value === 'recipient_groups' && !_.isEmpty( searchItem.param ) ){
                                tmpFilter = {
                                  ...tmpFilter,
                                  $or: searchItem.ids
                                }
                              }
                            })

                            let tmpString = convertSearchObjectToBase64( !_.isEmpty( tmpFilter ) ? { ...tmp, filter: tmpFilter } : tmp )
                            this.props.getNewsletter( tmpString )
                          }} />
                      )
                    }
                </Card>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
        {
          this.props.showCreateFromTemplate && <CreateNewsletters
            { ...this.props }
            onChangeNewsletterHOC={ this.props.onChangeNewsletterHOC }
            increment={ 1 }
            newNewsletterData={ this.props.newNewsletterData }
            senders={ (this.props.senders && this.props.senders.data ) ? this.props.senders.data : [] }
            recipientGroups={ this.props.recipientGroup && this.props.recipientGroup.data ? this.props.recipientGroup.data : [] }
            postNewsletter={ this.props.postNewsletter }
            errorMessage={ this.props.errorMessage }
            step={ this.props.step }
            showCreateFromTemplate={ this.props.showCreateFromTemplate }
            showCreateFromContentService={ this.props.showCreateFromContentService }
            contentServices={ this.props.contentServices }
            categories={ this.props.getCategories }
            getContentServices={ this.props.getContentServices }
            getSelectedContentService={ this.props.getSelectedContentService }
            onChangeContentServicesHOC={ this.props.onChangeContentServicesHOC }
            selectedContentService={ this.props.selectedContentService }
            newNewsletterFromCS={ this.props.newNewsletterFromCS }
            site={ this.props.site }
            siteSettings={ this.props.siteSettings }
            onLoadContentServices={ this.props.onLoadContentServices }
            getNewsletter={ this.props.getNewsletter }
            templates={ this.props.templates }
            getSelectedTemplate={ this.props.getSelectedTemplate }
            onLoadTemplates={ this.props.onLoadTemplates }
            selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }
            closingConfirmation={ this.props.closingConfirmation }
            onLoadNewsletters={ this.props.onLoadNewsletters }
            processType={ this.props.processType }
            updateNewsletter={ this.props.updateNewsletter }
             />
        }
        <NewsletterView
          data={ this.props.selectedNewsletter }
          toggle={ () => this.props.onChangeNewsletterHOC( 'viewNewsletterModal', false )}
          viewNewsletterModal={ this.props.viewNewsletterModal }
          selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language } />
        { ( this.props.onLoadNewsletters || this.props.onLoadTemplates ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  SenderHOC,
  RecipientGroupHOC,
  WithContentServices,
  WithTemplates,
  WithNewsletters
)( NewsletterListings )
