import React, { Component } from 'react'

import CreateFromTemplate from './FromTemplate'
// import CreateFromContentService from './FromContentService'

class CreateNewsletter extends Component {
  state = {
    choice: ''
  }

  render = () => {
    return (
      <>
        {
          this.props.showCreateFromTemplate && (
            <CreateFromTemplate
              data={ this.props.data }
              showCreateFromTemplate={ this.props.showCreateFromTemplate }
              onChangeNewsletterHOC={ this.props.onChangeNewsletterHOC }
              increment={ this.props.increment }
              newNewsletterData={ this.props.newNewsletterData }
              senders={ this.props.senders }
              recipientGroups={ this.props.recipientGroups }
              postNewsletter={ this.props.postNewsletter }
              errorMessage={ this.props.errorMessage }
              step={ this.props.step }
              templates={ this.props.templates }
              getSelectedTemplate={ this.props.getSelectedTemplate }
              selectedLanguage={ this.props.selectedLanguage }
              closingConfirmation={ this.props.closingConfirmation }
              onLoadNewsletters={ this.props.onLoadNewsletters }
              processType={ this.props.processType }
              updateNewsletter={ this.props.updateNewsletter }
              contentServices={ this.props.contentServices }
              centerNews={ this.props.centerNews }
              getSelectedContentService={ this.props.getSelectedContentService }
              selectedContentService={ this.props.selectedContentService }
              onChangeContentServicesHOC={ this.props.onChangeContentServicesHOC }
              toggleMediaDatabase={ this.props.toggleMediaDatabase } />
          )
        }
      </>
    )
  }
}

export default CreateNewsletter
