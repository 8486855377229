import Axios from 'axios'

import {
  GET_PROFILE,
  CLEAR_PROFILE
} from './type'
import {
  beginAjaxCall,
  ajaxCallSuccess,
  ajaxCallError
} from './ajax'
import { getToken } from 'utils/findCookieToken'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import getDomainURL from 'utils/api'

export const getUserProfile = () => {
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${ getToken( 'ACISO_SESSION_TOKEN' ) }`
    }
  }
  return dispatch => {
    dispatch( beginAjaxCall() )
    Axios.get(
      `${ getDomainURL() }/api/v1/profiles/me`
    ).then( response => {
      let tmp = []
      if ( response.data.role === 'OLS Team' || response.data.role === 'Management Admin' ) {
        return Axios.get(
          `${ getDomainURL() }/api/v1/centers?query=${ convertSearchObjectToBase64({
            is_paginated: false,
            filter: {
              '$or': [
                { status: 'ACTIVE' },
                { status: 'TRIAL' }
              ]
            }
          }) }`
        ).then ( centers => {
          tmp = centers.data.data.map( center => ({ ...center, label: center.center_name, value: center.id }) )
          dispatch( getProfileSuccess({ ...response.data, centers: tmp }) )
          dispatch( ajaxCallSuccess() )
        })
      } else {
        if( response.data.centers && response.data.centers.length > 0 ) {
          response.data.centers.map( center => {
            tmp.push({ ...center, label: center.center_name, value: center.id })
          })
        }
        dispatch( getProfileSuccess({ ...response.data, centers: tmp }) )
        dispatch( ajaxCallSuccess() )
      }
    }).catch( error => {
      dispatch( ajaxCallError( error ) )
    })
  }
}

const getProfileSuccess = payload => {
  return {
    type: GET_PROFILE,
    payload
  }
}

export const updateProfile = data => {
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${ getToken( 'ACISO_SESSION_TOKEN' ) }`
    }
  }
  return dispatch => {
    dispatch( beginAjaxCall() )
    Axios.put(
      `${ getDomainURL() }/api/v1/profiles/me`,
      data
    ).then( response => {
      dispatch( ajaxCallSuccess( 'update successfully') )
      dispatch( getUserProfile() )
    }).catch( error => {
      dispatch( ajaxCallError( error ) )
    })
  }
}

export const clearProfile = () => ({
  type: CLEAR_PROFILE
})
