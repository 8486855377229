import React, { useState, useEffect } from 'react'
import { 
  FormGroup, FormFeedback,
  Label, Input, UncontrolledTooltip 
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import { generateFieldLabelStr } from '../utils'
import Lang from 'Lang/General'

const TextField = ({
  content,
  formValues,
  onPatchFormValues,
  isInputDisabled,
  currentLanguage,
  pageErrors
}) => {
  const [ maxLength, setMaxLength ] = useState( 0 )
  const [ charsRemaining, setCharsRemaining ] = useState( 0 )

  useEffect( () => {
    if( content.hasMaxLength && parseInt( content.maxLength ) > 0 ) {
      setMaxLength( parseInt( content.maxLength ) )
    }
  }, [] )

  useEffect( () => {
    maxLength > 0 && setCharsRemaining( maxLength - formValues[ content.dataMapIndex ][ 'value' ].length )
  }, [ formValues[ content.dataMapIndex ][ 'value' ], maxLength ] )

  return (
    <FormGroup id={ content.id } style={{ padding: content.padding, marginBottom: 0, ...content.style }}>
      <Label>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }
        {
          ( content.showQuickInfo || formValues[ content.dataMapIndex ][ 'showQuickInfo' ] ) && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`}
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      <Input
        type={ content.type }
        disabled={ isInputDisabled }
        maxLength={ maxLength > 0 ? maxLength : null }
        invalid={ _.findIndex( pageErrors, { id: content.id } ) > -1 }
        value={ formValues[ content.dataMapIndex ][ 'value' ] }
        onChange={ e => {
          onPatchFormValues( content.dataMapIndex, 'value', e.target.value )
        }} />
      <FormFeedback>
        { 
          _.find( pageErrors, { id: content.id } ) &&
          _.find( pageErrors, { id: content.id } ).validation[ currentLanguage ] 
        }
      </FormFeedback>
      {
        maxLength > 0 && charsRemaining <=5 && (
          <Label style={{ fontSize: "80%", marginBottom: "0" }} >
            { `${ Lang[ 'REMAINING_CHARACTERS' ][ currentLanguage ] } ${ charsRemaining }` }
          </Label>
        )
      }
    </FormGroup>
  )
}

export default TextField