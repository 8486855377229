import React, { useState } from 'react'
import {
  Container, Row, Col, Form, Card, CardBody,
  FormGroup, Input, Label, Button, FormFeedback,
  Modal, ModalBody, ModalHeader, ModalFooter,
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import Select from 'react-select'
import _ from 'lodash'

import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const RecipientGroupForm = ({
  data,
  onChangeRecipientGroupHOC,
  headerText,
  toggle,
  isOpen,
  mode,
  onSubmit,
  selectedLanguage,
  allRecipients,
  assignedRecipients,
  postRecipientIntoGroup,
  onChangeRecipientHOC,
  recipientLists,
  emptyRecipientFromGroup,
  confirmEmptyGroup,
  onLoadRecipientGroup,
  onLoadRecipients
}) => {
  const [ activeTab, updateActiveTab ] = useState( '1' )
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( data )
    let dataKey = ''
    tmp[key] = value
    if( mode === 'edit' ){
      dataKey = 'selectedRecipientGroup'
    }else{
      dataKey = 'newRecipientGroupData'
    }

    onChangeRecipientGroupHOC( dataKey, tmp )
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      updateActiveTab( tab )
    }
  }

  return(
    <Modal
      toggle={ toggle }
      isOpen={ isOpen }
      size="xl">
      <ModalHeader toggle={ toggle }>{ headerText }</ModalHeader>
      <ModalBody>
        {
          mode === 'edit' && (
            <>
              <Nav tabs={ true }>
                <NavItem>
                  <NavLink href="javascript:void(0);"
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggleTab('1')
                    }}>{ Lang[ 'GENERAL' ][ selectedLanguage ] }</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="javascript:void(0);"
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggleTab('2')
                    }}>{ Lang[ 'ADD_RECIPIENT' ][ selectedLanguage ] }</NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={ activeTab }>
                <TabPane tabId="1">
                  <Container className="mt-3 mb-5">
                    <Row>
                      <Col md={ 12 }>
                        <Card>
                          <CardBody>
                            <Form>
                              <FormGroup>
                                <Label>{ Lang[ 'NAME' ][ selectedLanguage ] }</Label>
                                <Input
                                  type={ 'text' }
                                  value={ data.name }
                                  onChange={ e => onChangeData( 'name', e.target.value ) } />
                              </FormGroup>
                              <FormGroup>
                                <Label for="status">{ Lang[ 'STATUS' ][ selectedLanguage ] }</Label>
                                <Input
                                  type="select"
                                  name="status"
                                  id="status"
                                  invalid={ /*errorMessage && errorMessage.status ? true :*/ false }
                                  value={ data.status }
                                  onChange={ e => onChangeData( 'status', e.target.value ) }
                                >
                                  <option value={ 'ACTIVE' }>{ Lang[ 'ACTIVE' ][ selectedLanguage ] }</option>
                                  <option value={ 'INACTIVE' }>{ Lang[ 'INACTIVE' ][ selectedLanguage ] }</option>
                                </Input>
                                <FormFeedback>{ /*errorMessage && errorMessage.status ? errorMessage.status : '' */}</FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Label>{ 'Recipients' }</Label>
                                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  {
                                    recipientLists.length > 0 && recipientLists.map( item => {
                                      return(
                                        <div
                                          style={{
                                            background: '#c2c2c2',
                                            color: '#000',
                                            height: '35px',
                                            borderRadius: '5px',
                                            width: 'fit-content',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                          className="mr-2 p-2 mt-2">
                                          <span style={{ textAlign: 'center' }}>{ item.email }</span>
                                        </div>
                                      )
                                    })
                                  }
                                  </div>
                              </FormGroup>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    {
                      confirmEmptyGroup && <DeleteConfirmation
                         handleModalClose={ () => onChangeRecipientGroupHOC( 'confirmEmptyGroup', false ) }
                         deleteOpen={ confirmEmptyGroup }
                         confirmAction={ () => {
                           onChangeRecipientGroupHOC( 'confirmEmptyGroup', false )
                           emptyRecipientFromGroup()
                         }}
                         content={ Lang[ 'SURE_TO_EMPTY_GROUP' ][ selectedLanguage ] }
                         lang={ selectedLanguage }/>
                    }

                  </Container>
                  <Button className="mr-2" color="primary" onClick={ onSubmit } style={{ float: 'right' }}>{ Lang[ 'SUBMIT_GEN_INFO' ][ selectedLanguage ] }</Button>
                  <Button className="mr-2" color="link" onClick={ () => { onChangeRecipientGroupHOC('confirmEmptyGroup', true ) } } style={{ float: 'right' }}>{ Lang[ 'EMPTY_GROUP' ][ selectedLanguage ] }</Button>
                </TabPane>
                <TabPane tabId="2">
                  <Container className="mt-3 mb-5">
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            <Form onSubmit={ e => e.preventDefault() }>
                              <FormGroup>
                                <Label>{ Lang[ 'RECIPIENT' ][ selectedLanguage ] }</Label>
                                <Select
                                  isMulti
                                  options={ allRecipients }
                                  value={ assignedRecipients }
                                  onChange={ value => {
                                    onChangeRecipientHOC( 'assignedRecipients', value )
                                  }} />
                              </FormGroup>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                  <Button className="mr-2" color="primary" onClick={ () => postRecipientIntoGroup( data.id ) } style={{ float: 'right' }}>{ Lang[ 'ADD_RECIPIENT' ][ selectedLanguage ] }</Button>
                </TabPane>
              </TabContent>
            </>
          )
        }
        {
          mode === 'create' && (
            <>
              <Container className="mt-3 mb-5">
                <Row>
                  <Col md={ 12 }>
                    <Card>
                      <CardBody>
                        <Form>
                          <FormGroup>
                            <Label>{ Lang[ 'NAME' ][ selectedLanguage ] }</Label>
                            <Input
                              type={ 'text' }
                              value={ data.name }
                              onChange={ e => onChangeData( 'name', e.target.value ) } />
                          </FormGroup>
                          <FormGroup>
                            <Label for="status">{ Lang[ 'STATUS' ][ selectedLanguage ] }</Label>
                            <Input
                              type="select"
                              name="status"
                              id="status"
                              invalid={ /*errorMessage && errorMessage.status ? true :*/ false }
                              value={ data.status }
                              onChange={ e => onChangeData( 'status', e.target.value ) }
                            >
                              <option value={ 'ACTIVE' }>{ Lang[ 'ACTIVE' ][ selectedLanguage ] }</option>
                              <option value={ 'INACTIVE' }>{ Lang[ 'INACTIVE' ][ selectedLanguage ] }</option>
                            </Input>
                            <FormFeedback>{ /*errorMessage && errorMessage.status ? errorMessage.status : '' */}</FormFeedback>
                          </FormGroup>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <Button className="mr-2" color="primary" onClick={ onSubmit } style={{ float: 'right' }}>{ Lang[ 'SUBMIT_GEN_INFO' ][ selectedLanguage ] }</Button>
            </>
          )
        }
        { ( onLoadRecipients || onLoadRecipientGroup ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button className="mr-2" color="secondary" onClick={ toggle }>{ Lang[ 'CANCEL' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default RecipientGroupForm
