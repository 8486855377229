import React from 'react'
import { Button, FormGroup } from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

const ButtonComponent = ({
  onClickSubmitForm,
  onClickEditor,
  content,
  style,
  mode,
  currentLanguage,
  onChangeFormBuilderState,
}) => {
  return (
    <FormGroup 
      style={{ padding: content.padding, ...content.style }}>
      <Button 
        color={ content.color }
        onClick={
          content.mapWith && content.mapWith.toLowerCase().indexOf( 'page' ) > -1
          ? () => onChangeFormBuilderState( 'currentPage', parseInt( content.mapWith.toLowerCase().replace( 'page', '' ) ) )
          : mode === 'edit' 
            ? content.onClickEditor 
            : onClickSubmitForm 
        }
        className={ content.mapWith && content.mapWith.toLowerCase().indexOf( 'page' ) > -1 && "form-page-link-btn"}
        style={ content.style }>
        { ReactHTMLParser( content.content[ currentLanguage ? currentLanguage : 'en' ] ) }
      </Button>
    </FormGroup>
  )
}

export default ButtonComponent