import React from 'react'
import {
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

import 'semantic-ui-css/semantic.min.css'

const MessageConsultantForm = ({
  newMessageConsultant,
  onChangeMessageConsultantHOC,
  data,
  selectedLanguage
}) => {

  const updateMessageConsultant = async ( key, val ) => {
    let tmp = _.cloneDeep( newMessageConsultant )
    tmp[ key ] = val
    onChangeMessageConsultantHOC( 'newMessageConsultant', tmp )
  }

  return(
    <Container>
      <Row>
        <Col md={ 8 }>
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'CONSULTANT' ][ selectedLanguage ] }</Label>
              <Input
                disabled
                type='text'
                value={ `${ data.selectCenterReducer.selectedCenter.center.consultant.user.first_name } ${ data.selectCenterReducer.selectedCenter.center.consultant.user.last_name }` } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'SUBJECT' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'SUBJECT' ][ selectedLanguage ].toLowerCase() }
                value={ newMessageConsultant.subject }
                onChange={ e => updateMessageConsultant( 'subject', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'MESSAGE' ][ selectedLanguage ] }</Label>
              <Input
                type='textarea'
                placeholder={ Lang[ 'WRITE_MESSAGE_HERE' ][ selectedLanguage ] }
                style={{ height: 200 }}
                value={ newMessageConsultant.message }
                onChange={ e => updateMessageConsultant( 'message', e.target.value ) } />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default MessageConsultantForm