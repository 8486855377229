import React, { Component } from 'react'
import { connect } from 'react-redux'
import Hamburger from 'react-hamburgers'
import _ from 'lodash'

import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall
} from 'reducers/ThemeOptions'

import MobileMenu from '../Menu/MobileMenu'

class Logo extends Component {
  state = {
    active: false,
    mobile: false,
    activeSecondaryMenuMobile: false
  }

  render = () => {
    return (
      <>
        <div
          className="app-header__logo"
          style={{
            width: this.props.enableClosedSidebar ? '150px' : '280px',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          {/* <div className="logo-src" /> */}
          <img
            style={{ 
              display: 'flex', 
              height: '90%', 
              objectFit: 'contain', 
              objectPosition: 'left center', 
              maxWidth: '100px' 
            }}
            src={
              !_.isEmpty( this.props.data.platformConfigReducer.platformConfig )
              && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } )
                ? _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ).value
                : ''
            }
          />
          <div className="header__pane ml-1">
            <div onClick={() => this.props.setEnableClosedSidebar( !this.props.enableClosedSidebar )}>
              <Hamburger
                active={ this.props.enableClosedSidebar }
                type="elastic"
                onClick={() => this.setState({ active: !this.state.active })}
              />
            </div>
          </div>
          {
            // this.props.enableClosedSidebar ? (
            //   <div className="header__pane ml-5">
            //     <div onClick={() => this.props.setEnableClosedSidebar( !this.props.enableClosedSidebar )}>
            //       <Hamburger
            //         active={ !this.props.enableClosedSidebar }
            //         type="elastic"
            //         onClick={() => this.setState({ active: !this.state.active })}
            //       />
            //     </div>
            //   </div>
            // ) : (
            //   <div className="header__pane ml-1" >
            //     <div onClick={() => this.props.setEnableClosedSidebar( !this.props.enableClosedSidebar )}>
            //       <Hamburger
            //         active={ !this.props.enableClosedSidebar }
            //         type="elastic"
            //         onClick={() => this.setState({ active: !this.state.active })}
            //       />
            //     </div>
            //   </div>
            // )
          }

        </div>
        <MobileMenu />
      </>
    )
  }
}

const mapStateToProps = state => ({
  data: state,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
})

export default connect( mapStateToProps, {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall
})( Logo )
