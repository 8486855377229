import React, { Component } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from 'reactstrap'
import _ from 'lodash'
import { compose } from 'redux'
import ReactTable from 'react-table'
import Lang from 'Lang/General'

class ViewAllProducts extends Component {

  componentDidMount = () => {

  }

  render = () => {
    return (
      <Modal isOpen={ this.props.showAllProducts } size="xl" toggle={ () => this.props.onChangeProductsHOC( 'showAllProducts', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeProductsHOC( 'showAllProducts', false ) }>{ Lang[ 'ALL_PRODUCTS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</ModalHeader>
        <ModalBody style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            this.props.allProducts.length > 0 && this.props.allProducts.map( item => {
              return(
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    margin: '35px',
                    alignItems: 'center',
                    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.2)',
                    border: 'none',
                    borderRadius: '5px',
                    width: '250px',
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                  onClick={ () => {
                    this.props.getSelectedProduct( item.id, false )
                  }}>
                  <img src={ item.image_url } style={{ width: '100%', height: '200px'}}/>
                  <p style={{
                      position: 'absolute',
                      top: -16,
                      right: -13,
                      background: '#EA5B0C',
                      display: !item.is_new ? 'none' : '',
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      fontSize: '10px',
                      color: '#fff',
                      padding: '8px 5px',
                      border: 'solid #fff 3px'
                    }}>{ item.is_new ? 'NEW' : '' }</p>
                  <p className="p-2 mt-2">{ item.name[ this.props.data.languageReducer.userSettings.admin_panel_language ] }</p>
                  <p style={{
                    background: 'rgba( 255, 0, 0, 0.8 )',
                    color: '#fff',
                    fontSize: '12px',
                    marginBottom: '10px',
                    padding: '5px 15px' }}>
                    <p className="mb-0">{ item.currency_price_1[this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                    <p>{ item.currency_price_2[this.props.data.languageReducer.userSettings.admin_panel_language] }</p>
                  </p>
                </div>
              )
            })
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={ () => this.props.onChangeProductsHOC( 'showAllProducts', false ) }>{ Lang[ 'CLOSE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ViewAllProducts
