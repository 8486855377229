import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Lang from 'Lang/General'

const ViewCampaign = ({
  selectedCampaign,
  onChangeLatestCampaignHOC,
  showViewInfoModal,
  lang
}) => {
  return(
    <Modal
      isOpen={ showViewInfoModal }
      toggle={ () => onChangeLatestCampaignHOC( 'showViewInfoModal', false )}
      size="xl">
      <ModalHeader toggle={ () => onChangeLatestCampaignHOC( 'showViewInfoModal', false )}>{ Lang[ 'CAMPAIGN_INFO' ][ lang ] }</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-row flex-nowrap">
          <img 
            className='mr-4'
            style={{ 
              maxWidth: '45%',
              maxHeight: '70vh', 
              objectFit: 'contain',
              objectPosition: 'top'
            }}
            src={ selectedCampaign.info_image_url } 
          />
          <div className="d-flex flex-column flex-wrap text-break">
            <h2>{ selectedCampaign.title }</h2>
            <p>{ ReactHtmlParser( selectedCampaign.description ) }</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={ () => onChangeLatestCampaignHOC( 'showViewInfoModal', false )}>{ Lang[ 'CLOSE' ][ lang ]}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewCampaign
