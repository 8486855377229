import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card
} from 'reactstrap'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import FiguresHOC from './actions'

import Lang from 'Lang/General'

class KeyFigures extends Component {
  componentDidMount = () => {
    if( this.props.data.profileReducer.profile.role_id === 6 ) {
      this.props.getFigures()
    } else {
      if( 
        this.props.data.profileReducer.profile.centers &&
        this.props.data.selectCenterReducer.selectedCenter.center
      ) {
        this.props.getFigures( this.props.data.selectCenterReducer.selectedCenter.center.id )
      }
    }
  }

  componentDidUpdate = (pp) => {
    if( pp.data.profileReducer.profile.role_id !== this.props.data.profileReducer.profile.role_id ){
      if( this.props.data.profileReducer.profile.role_id === 6 ) {
        this.props.getFigures()
      } else {
        if( 
          this.props.data.profileReducer.profile.centers &&
          this.props.data.selectCenterReducer.selectedCenter.center
        ) {
          this.props.getFigures( this.props.data.selectCenterReducer.selectedCenter.center.id )
        }
      }
    }
  }

  render = () => {
    return(
      <Card style={{ background: '#fff', width: '100%', padding: '15px' }}>
        <Container>
          <Row>
            <Col md="3" className="text-center">
              <span style={{
                fontSize: '10px'
              }}>{ Lang[ 'LEADS' ][ this.props.lang ] }</span>
              <div className="d-flex align-items-center justify-content-center" style={{ height: '3rem' }}>
                <i className="pe-7s-rocket" style={{
                  fontSize: '30px',
                  color: '#EA5B0C',
                  marginRight: '10px',
                  alignSelf: 'center'
                }}/>
                <p style={{
                  fontSize: '35px',
                  color: '#EA5B0C',
                }}>{ this.props.figures.total_lead }</p>
              </div>
            </Col>
            <Col md="3" className="text-center">
              <span style={{
                fontSize: '10px',
                alignSelf: 'center'
              }}>{ Lang[ 'REQUEST_FORMS' ][ this.props.lang ] }</span>
              <div className="d-flex align-items-center justify-content-center" style={{ height: '3rem', marginTop: '13pxa', marginBottom: '16pxa' }}>
                <i className="pe-7s-note2" style={{
                  fontSize: '30px',
                  color: '#EA5B0C',
                  marginRight: '10px',
                  alignSelf: 'center'
                }}/>
              <span style={{
                fontSize: '35px',
                color: '#EA5B0C',
              }}>{ this.props.figures.total_request_form }</span>
              </div>
            </Col>
            <Col md="3" className="text-center">
              <span style={{
                fontSize: '10px',
                alignSelf: 'center'
              }}>{ Lang[ 'NUMBER_OF_SALES' ][ this.props.lang ] }</span>
              <div className="d-flex align-items-center justify-content-center" style={{ height: '3rem', marginTop: '13pxa', marginBottom: '16pxa' }}>
                <i className="pe-7s-cart" style={{
                  fontSize: '30px',
                  color: '#EA5B0C',
                  marginRight: '10px',
                  alignSelf: 'center'
                }}/>
                <p style={{
                  fontSize: '35px',
                  color: '#EA5B0C',
                }}>{ this.props.figures.total_sales_count }</p>
              </div>
            </Col>
            <Col md="3" className="text-center">
              <span style={{
                fontSize: '10px',
                alignSelf: 'center'
              }}>{ Lang[ 'SALES' ][ this.props.lang ] }</span>
              <div className="d-flex align-items-center justify-content-center" style={{ height: '3rem', marginTop: '13pxa', marginBottom: '16pxa' }}>
                <i className="pe-7s-note2" style={{
                  fontSize: '30px',
                  color: '#EA5B0C',
                  marginRight: '10px',
                  alignSelf: 'center'
                }}/>
              <p style={{
                fontSize: '35px',
                color: '#EA5B0C',
              }}>{ `${ this.props.figures.total_sales } €` } </p>
              </div>
            </Col>
          </Row>
        </Container>
        { this.props.onLoadFigures && <LoadingOverlay /> }
      </Card>
    )
  }
}
export default FiguresHOC( KeyFigures )
