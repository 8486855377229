import React, { Component } from 'react'
import Axios from 'axios'

import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      error: '',
      token: '',
      success: ''
    }

    onChangePasswordHOC = ( key, val ) => this.setState({ [ key ]: val })

    sendEmailPasswordReset = async ( email ) => {
      this.setState({
        loading: true
      }, async () => {
        let sendEmail = await Axios.put( `${ getDomainURL() }/passwords/reset`, { email }).catch( error => error )
        if( sendEmail.isAxiosError ) {
          this.setState({ error: sendEmail.response.data.Error, loading: false })
        } else {
          this.setState({ success: sendEmail.data.msg, loading: false })
        }
      })
    }

    sendPassword = async( password, password_confirmation, password_reset_token ) => {
      this.setState({
        loading: true
      }, async () => {
        let sendPassword = await Axios.put( `${ getDomainURL() }${ window.location.href.indexOf( 'member' ) > -1 ? '/member/update_password' : '/passwords' }`, { 
          password,
          password_confirmation,
          password_reset_token
        }).catch( error => error )
        if( sendPassword.isAxiosError ) {
          let tmpResponse = ( 
            sendPassword.response.data.Error || 
            sendPassword.response.Errors || 
            sendPassword.response.data.Errors 
          )
          this.setState({ 
            error: tmpResponse ? ( 
              typeof tmpResponse === 'string' 
                ? tmpResponse 
                : Object.values( tmpResponse )[ 0 ] 
                  ? Object.values( tmpResponse )[ 0 ] 
                  : ''
            ) : ( '' ), 
            loading: false })
        } else {
          this.setState({ 
            loading: false, 
            success: window.location.href.indexOf( 'member' ) > -1 ? sendPassword.data.message : sendPassword.data,
            error: ''
          })
        }
      })
    }

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadPassword={ this.state.loading }
          token={ this.state.token }
          error={ this.state.error }
          success={ this.state.success }
          sendEmailPasswordReset={ this.sendEmailPasswordReset }
          sendPassword={ this.sendPassword }
          onChangePasswordHOC={ this.onChangePasswordHOC } />
      )
    }
  } 
  return WithHOC
}

export default HOC