import React, { useEffect } from 'react'
import {
  Container, Row, Col, Button,
  Card, CardHeader, CardBody,
  Input
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DataConfigurationHOC from './actions'

import Lang from 'Lang/General'


const DataConfiguration = props => {

  useEffect( () => {
    props.getGDPRDataConfigs()
  }, [] )

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_update
    }
  }
  
  return (
    <>
      <PageTitle
        heading={ Lang[ 'DATA_CONFIGURATION' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-settings icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          {
            !_.isEmpty( props.savedGdprDataConfig ) && (
              Object.keys( props.gdprDataConfigSettings ).map( ( configKey, index ) => {
                let config = props.gdprDataConfigSettings[ configKey ]
                return (
                  <Row>
                    <Col md={ 12 }>
                      <Card className="main-card mb-3">
                        <CardHeader>
                          { Lang[ config.topic ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                        </CardHeader>
                        <CardBody>
                          <p>{ Lang[ config.instruction ][ props.data.languageReducer.userSettings.admin_panel_language ] }</p>
                          <br/>
                          <p>
                            { Lang[ config.statement ][ props.data.languageReducer.userSettings.admin_panel_language ].split( '%{days}' )[0] } 
                            {
                              config.isEditing 
                                ? <span><Input
                                    type="number"
                                    style={{ width: '80px', display: 'inline' }}
                                    value={ config.value }
                                    min={ 0 }
                                    onChange={ e => {
                                      let tmp = _.cloneDeep( props.gdprDataConfigSettings )
                                      tmp[ configKey ].value = e.target.value
                                      props.onChangeDataConfigurationHOC( 'gdprDataConfigSettings', tmp )
                                    }} 
                                  /></span>
                                : <span>{ props.savedGdprDataConfig[ configKey ] }</span>
                            }
                            { Lang[ config.statement ][ props.data.languageReducer.userSettings.admin_panel_language ].split( '%{days}' )[1] } 
                            {
                              handleSecurityModulesUpdate() && (
                                <span className="ml-4">
                                  {
                                    !config.isEditing
                                      ? <Button
                                          className="mb-2 mr-2 mt-2 btn-icon btn-icon-only"
                                          color='primary'
                                          onClick={ () => {
                                            let tmp = _.cloneDeep( props.gdprDataConfigSettings )
                                            tmp[ configKey ].isEditing = !config.isEditing
                                            props.onChangeDataConfigurationHOC( 'gdprDataConfigSettings', tmp )
                                          } } >
                                            <i className="pe-7s-pen btn-icon-wrapper" />
                                        </Button> 
                                      : <Row className="no-wrap d-inline-flex">
                                          <Button
                                            className="mb-2 mr-2 mt-2 btn-icon btn-icon-only"
                                            color='primary'
                                            onClick={ () => {
                                              props.updateGDPRDataConfigs( configKey )
                                            }} >
                                              { Lang[ 'SAVE' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                                          </Button>
                                          <Button
                                            className="mb-2 mr-2 mt-2 btn-icon btn-icon-only"
                                            color='secondary'
                                            onClick={ () => {
                                              let tmp = _.cloneDeep( props.gdprDataConfigSettings )
                                              tmp[ configKey ].value = props.savedGdprDataConfig[ configKey ]
                                              tmp[ configKey ].isEditing = false
                                              props.onChangeDataConfigurationHOC( 'gdprDataConfigSettings', tmp )
                                            }} >
                                              { Lang[ 'CANCEL' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
                                          </Button>
                                        </Row>
                                  }
                                </span>
                              )
                            }
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )
              })
            )
          }
        </Container>
      </ReactCSSTransitionGroup>
      { props.onLoadGDPRDataConfigs && <LoadingOverlay /> }
    </>
  )
}

export default DataConfigurationHOC( DataConfiguration )