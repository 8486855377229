import React, { useState } from 'react'
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container,
  Row,
  Col,
  Label
} from 'reactstrap'
import { FixedSizeList } from "react-window"
import _ from 'lodash'

import Lang from 'Lang/General'

import 'semantic-ui-css/semantic.min.css'

const LocationForm = ({
  currentLocation,
  locationType,
  onChangeLocationsHOC,
  states,
  countries,
  selectedLanguage
}) => {
  const [ category, updateCategory ] = useState( '' )

  const updateCurrentLocation = async ( key, val ) => {
    let tmp = _.cloneDeep( currentLocation )
    tmp[ key ] = val
    if( key === 'country' ){
      onChangeLocationsHOC( locationType, tmp, true )
    } else {
      onChangeLocationsHOC( locationType, tmp )
    }
  }

  const getCallingCode = () => {
    let tmpCallingCode = '-'
    if( !_.isEmpty( currentLocation.country ) ){
      let tmpCountry = _.find( countries, { name: currentLocation.country } )
      tmpCallingCode = tmpCountry.calling_code[0]
    }
    return tmpCallingCode
  }

  const MenuList = props => {
    const { options, children, maxHeight, getValue } = props
    const [ value ] = getValue()
    const initialOffset = options.indexOf(value) * 35

    return (
      <FixedSizeList
        height={ maxHeight }
        itemCount={ children.length }
        itemSize={ 35 }
        initialScrollOffset={ initialOffset } >
        { ({ index, style }) => <div style={ style }>{ children[ index ] }</div> }
      </FixedSizeList>
    )
  }

  return(
    <Container>
      <Row>
        <Col md={ 8 }>
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'NAME' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'NAME' ][ selectedLanguage ].toLowerCase() }
                value={ currentLocation.name }
                onChange={ e => updateCurrentLocation( 'name', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'EXTRA_FIELD' ][ selectedLanguage ] } 1</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'EXTRA_FIELD' ][ selectedLanguage ].toLowerCase() + ' 1' }
                value={ currentLocation.ext_field_1 }
                onChange={ e => updateCurrentLocation( 'ext_field_1', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'EXTRA_FIELD' ][ selectedLanguage ] } 2</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'EXTRA_FIELD' ][ selectedLanguage ].toLowerCase() + ' 2' }
                value={ currentLocation.ext_field_2 }
                onChange={ e => updateCurrentLocation( 'ext_field_2', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'COUNTRY' ][ selectedLanguage ] }</Label>
              <Input
                type='select'
                value={ currentLocation.country }
                onChange={ async e => {
                  let tmpCountry = _.find( countries, { name: e.target.value } )
                  updateCurrentLocation( 'country', tmpCountry.name ) }} >
                { countries.map( country => <option value={ country.name }  >{ country.name }</option> ) }</Input>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'STATE' ][ selectedLanguage ] }</Label>
              <Input
                type='select'
                value={ currentLocation.state }
                onChange={ e => updateCurrentLocation( 'state', e.target.value ) } >
                { states.map( state => <option value={ states.name } >{ state.name }</option> ) }</Input>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'CITY' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'CITY' ][ selectedLanguage ].toLowerCase() }
                value={ currentLocation.city }
                onChange={ e => updateCurrentLocation( 'city', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'POSTCODE' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'POSTCODE' ][ selectedLanguage ].toLowerCase() }
                value={ currentLocation.postcode }
                onChange={ e => updateCurrentLocation( 'postcode', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'STREET' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'STREET' ][ selectedLanguage ].toLowerCase() }
                value={ currentLocation.street }
                onChange={ e => updateCurrentLocation( 'street', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'HOUSE_NUMBER' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'HOUSE_NUMBER' ][ selectedLanguage ].toLowerCase() }
                value={ currentLocation.house_number }
                onChange={ e => updateCurrentLocation( 'house_number', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'PHONE_NUMBER' ][ selectedLanguage ] }</Label>
              <InputGroup>
                <Input
                  disabled
                  type='text'
                  value={ getCallingCode() } />
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText 
                    style={{ 
                      background: 'none',
                      borderLeft: "none" }}>
                    -</InputGroupText>
                </InputGroupAddon>
                <Input
                  type='text'
                  placeholder={ Lang[ 'AREA_CODE' ][ selectedLanguage ].toLowerCase() }
                  value={ currentLocation.area_code }
                  onChange={ e => updateCurrentLocation( 'area_code', e.target.value ) } />
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText 
                    style={{ 
                      background: 'none',
                      borderLeft: "none" }}>
                    -</InputGroupText>
                </InputGroupAddon>
                <Input
                  type='text'
                  placeholder={ Lang[ 'ENTER_PHONE_NUMBER' ][ selectedLanguage ].toLowerCase() }
                  value={ currentLocation.phone_number }
                  onChange={ e => updateCurrentLocation( 'phone_number', e.target.value ) } />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'EMAIL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'EMAIL' ][ selectedLanguage ].toLowerCase() }
                value={ currentLocation.email }
                onChange={ e => updateCurrentLocation( 'email', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'FAX_NUMBER' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'FAX_NUMBER' ][ selectedLanguage ].toLowerCase() }
                value={ currentLocation.fax_number }
                onChange={ e => updateCurrentLocation( 'fax_number', e.target.value ) } />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default LocationForm