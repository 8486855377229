import React, { useState } from 'react'
import {
  Row, Col,
  FormGroup, Label,
  Button, Input,
  Card, CardBody, CardFooter
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const Categories = props => {
  const [ category, updateCategory ] = useState( '' )
  const [ filteredCategories, updateFilteredCategories ] = useState( [] )
  const [ selectedCategoryID, updateSelectedCategoryID ] = useState( '' )
  const [ selectedCategory, updateSelectedCategory ] = useState( {} )

  let location = _.isEmpty( props.selectedLocation ) ? props.newLocation : props.selectedLocation

  return (
    <Row>
      <Col md={ 12 }>
        <br />
        <FormGroup>
          <Label>{ Lang[ 'CATEGORIES' ][ props.selectedLanguage ] }</Label>
          <div className="d-flex">
            <Input
              type="text"
              className="mr-2 w-50"
              value={ category }
              onChange={ e => {
                updateCategory( e.target.value )
              }} />
            <Button
              color="primary"
              onClick={() => {
                let tmpRes = []
                let tmp = _.cloneDeep( props.categories )
                tmp.map( item => {
                  if( item.value.indexOf( category ) > -1 ) {
                    tmpRes.push( item )
                  }
                })
                updateFilteredCategories( tmpRes )
              }}>
              { Lang[ 'SEARCH' ][ props.selectedLanguage ] }
            </Button>
          </div>
          {
            filteredCategories.length > 0 && (
              <div className="d-flex" style={{ marginTop: 15 }}>
                <Input
                  type="select"
                  className="mr-2 w-50"
                  value={ selectedCategoryID }
                  onChange={ e => {
                    updateSelectedCategoryID( e.target.value )
                    let tmp = _.find( filteredCategories, { key: parseInt( e.target.value, 10 ) } )
                    updateSelectedCategory( tmp )
                  }}>
                  <option></option>
                  {
                    filteredCategories.map( item => {
                      if( _.findIndex( location.categories, { key: item.key }) < 0 ) {
                        return (
                          <option key={ item.key } value={ item.key }>{ item.value }</option>
                        )
                      }
                    })
                  }
                </Input>
                <Button
                  color="primary"
                  onClick={() => {
                    let tmp = _.cloneDeep( props.locationCategories )
                    let tmpCategories = tmp.categories ? tmp.categories : tmp.categories = []
                    tmpCategories.push( selectedCategory )
                    console.log( tmp )
                    props.onChangeCategoriesHOC( 'locationCategories', tmp )
                  }}>
                  { Lang[ 'ADD_CATEGORY' ][ props.selectedLanguage ] }
                </Button>
              </div>
            )
          }

        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'SELECTED_CATEGORIES' ][ props.selectedLanguage ] }</Label>
          {
            props.locationCategories.categories && props.locationCategories.categories.length > 0 && props.locationCategories.categories.map(( item, index ) => {
              return (
                <Card key={ item.id }>
                  <CardBody className="d-flex align-items-center">
                    <span>{ item.value }</span>
                    {
                      index > 0 && (
                        <Button
                          color="danger"
                          className="ml-auto"
                          onClick={() => {
                            let tmp = _.cloneDeep( location )
                            _.remove( tmp.categories, { key: item.key } )
                            props.onChangeLocationsHOC( _.isEmpty( props.selectedLocation ) ? 'newLocation' : 'selectedLocation', tmp )
                          }}>
                          <i className="pe-7s-trash" />
                        </Button>
                      )
                    }
                  </CardBody>
                </Card>
              )
            })
          }
        </FormGroup>
      </Col>
    </Row>
  )
}

export default Categories
