import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Put, Post } from 'utils/axios-token'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      isNewOpeningHour: false,
      openingHour: ""
    }

    onChangeOpeningHourHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getOpeningHour = () => Get(
      `/api/v1/centers/${ this.props.selectedCenter.center.id }/injoy_opening_hours`,
      this.getOpeningHourSuccess,
      this.getOpeningHourError,
      this.load
    )
    getOpeningHourSuccess = payload => this.setState({ openingHour: payload.opening_hour, isNewOpeningHour: false })
    getOpeningHourError = () => this.setState({ openingHour: "", isNewOpeningHour: true })

    createOpeningHour = data => Post(
      `/api/v1/centers/${ this.props.selectedCenter.center.id }/injoy_opening_hours`,
      { opening_hour: data },
      this.createOpeningHourSuccess,
      this.createOpeningHourError,
      this.load
    )
    createOpeningHourSuccess = () => {
      this.requestSuccess( Lang[ 'INJOY360_OPENING_HOUR_CREATED_SUCCESSFULLY' ][ this.props.selectedLanguage ] )
    }
    createOpeningHourError = error => this.requestError( error )

    updateOpeningHour = data => Put(
      `/api/v1/centers/${ this.props.selectedCenter.center.id }/injoy_opening_hours`,
      { opening_hour: data },
      this.updateOpeningHourSuccess,
      this.updateOpeningHourError,
      this.load
    )
    updateOpeningHourSuccess = () => {
      this.requestSuccess( Lang[ 'INJOY360_OPENING_HOUR_UPDATED_SUCCESSFULLY' ][ this.props.selectedLanguage ] )
    }
    updateOpeningHourError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            openingHour={ this.state.openingHour }
            isNewOpeningHour={ this.state.isNewOpeningHour }
            onChangeOpeningHourHOC={ this.onChangeOpeningHourHOC }
            getOpeningHour={ this.getOpeningHour }
            createOpeningHour={ this.createOpeningHour }
            updateOpeningHour={ this.updateOpeningHour }
            onLoadOpeningHour={ this.state.loading } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ 
    data: state,
    selectedCenter: state.selectCenterReducer.selectedCenter,
    selectedLanguage: state.languageReducer.userSettings.admin_panel_language,
    profile: state.profileReducer.profile
  })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
