import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { connect } from 'react-redux'

import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import { Get, Put, Delete } from 'utils/axios-token'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={
      loading: false,
      templates: [],
      selectedTemplate: {},
      showUpdateTemplate: false,
      showDeleteTemplate: false,
      ownTemplates: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item ) )

    onChangeTemplatesHOC = ( key, val ) => this.setState({ [ key ] : val })

    getOwnTemplates = () => Get( 
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/newsletter_templates`,
      this.getOwnTemplatesSuccess,
      this.getOwnTemplatesError,
      this.load
    )
    getOwnTemplatesSuccess = payload => this.setState({ ownTemplates: payload })
    getOwnTemplatesError = error => this.requestError( error )

    updateOwnTemplate = data => Put( 
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/newsletter_templates/${ data.id }`,
      data,
      this.updateOwnTemplateSuccess,
      this.updateOwnTemplateError,
      this.load
    )
    updateOwnTemplateSuccess = () => {
      this.requestSuccess( Lang[ 'TEMPLATE_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getOwnTemplates()
      this.setState({ showUpdateTemplate: false})
    }
    updateOwnTemplateError = error => this.requestError( error )

    getTemplates = () => Get(
      `/api/v1/newsletter_templates?query=${ convertSearchObjectToBase64({
        is_paginated: false,
        filter: {
          '$or': this.props.data.selectCenterReducer.selectedCenter.center.center_groups.map( item => ({ center_group_id: item.id }) )
        }
      }) }`,
      this.getTemplatesSuccess,
      this.getTemplatesError,
      this.load
    )
    getTemplatesSuccess = payload => this.setState({ templates: payload.data })
    getTemplatesError = error => this.requestError( error )

    getSelectedTemplate = template_id => Get(
      `/api/v1/newsletter_templates/${ template_id }`,
      this.getSelectedTemplateSuccess,
      this.getSelectedTemplateError,
      this.load
    )
    getSelectedTemplateSuccess = payload => this.setState({ selectedTemplate: payload, showUpdateTemplate: true })
    getSelectedTemplateError = error => this.requestError( error )

    updateTemplate = data => Put(
      `/api/v1/newsletter_templates/${ data.id }`,
      data,
      this.updateTemplateSuccess,
      this.updateTemplateError,
      this.load
    )
    updateTemplateSuccess = () => {
      this.requestSuccess( Lang[ 'TEMPLATE_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getTemplates()
      this.setState({ showUpdateTemplate: false })
    }
    updateTemplateError = error => this.requestError( error )

    removeTemplate = () => Delete(
      `/api/v1/newsletter_templates/${ this.state.selectedTemplate.id }`,
      this.removeTemplateSuccess,
      this.removeTemplateError,
      this.load
    )
    removeTemplateSuccess = () => {
      this.requestSuccess( Lang[ 'TEMPLATE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] )
      this.getTemplates()
      this.getOwnTemplates()
      this.setState({ showDeleteTemplate: false, selectedTemplate: {} })
    }
    removeTemplateError = error => this.requestError( error )

    render = () => {
      return <WrappedComponent
        { ...this.props }
        onLoadTemplatesHOC={ this.state.loading }
        onChangeTemplatesHOC={ this.onChangeTemplatesHOC }
        templates={ this.state.templates }
        selectedTemplate={ this.state.selectedTemplate }
        getTemplates={ this.getTemplates }
        getSelectedTemplate={ this.getSelectedTemplate }
        updateTemplate={ this.updateTemplate }
        showUpdateTemplate={ this.state.showUpdateTemplate }
        removeTemplate={ this.removeTemplate }
        showDeleteTemplate={ this.state.showDeleteTemplate }
        getOwnTemplates={ this.getOwnTemplates }
        ownTemplates={ this.state.ownTemplates }
        updateOwnTemplate={ this.updateOwnTemplate } />
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
