import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      settings: {},
      showEmptySettings: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getOLSSettings = ( isOneTimer, center_id ) => Get(
      `/api/v1/${ isOneTimer
        ? `one_timers/${ this.props.data.profileReducer.profile.id }`
        : `centers/${ center_id }` }/ols_settings`,
      this.getOLSSettingsSuccess,
      this.getOLSSettingsError,
      this.load
    )
    getOLSSettingsSuccess = payload => this.setState({ settings: payload })
    getOLSSettingsError = () => this.setState({ showEmptySettings: true })

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getOLSSettings={ this.getOLSSettings }
          onLoadSettings={ this.state.loading }
          showEmptySettings={ this.state.showEmptySettings } />
      )
    }
  }
  return WithHOC
}

export default HOC