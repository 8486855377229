import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { storeSidemenu, storeAssignedSidemenu } from 'actions/shortcuts'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    onChangeShortcutHOC = ( key, value ) => this.setState({ [key]: value })

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onChangeShortcutHOC={ this.onChangeShortcutHOC } />
        </>
      )
    }

  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    storeSidemenu,
    storeAssignedSidemenu
  })( WithHOC )
}

export default HOC
