import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'
import { 
  Container, Row, Col, 
  Card, CardHeader, CardBody, CardFooter, 
  Button, Input, UncontrolledTooltip, FormGroup, Label
} from 'reactstrap'
import _ from 'lodash'


import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import LocationsHOC from './actions/locations'
import Lang from 'Lang/General'

const Reports = props => {
  useEffect( () => {
    props.getLocations()
  }, [] )

  useEffect( () => {
    if( props.locations.length > 0 ){
      props.getSelectedLocation( props.locations[ 0 ].id )
    }
  }, [ props.locations ] )

  return (
    <>
      <PageTitle
        heading={ Lang[ 'LOCAL_LISTINGS_REPORT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        subheading={ Lang[ 'VIEW_REPORTS_FROM_LOCAL_LISTINGS' ][ props.data.languageReducer.userSettings.admin_panel_language ] }
        icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
        buttons={[]} />
      <Container fluid>
        <Row>
          <Col md={ 12 }>
            <Card className='mb-3' >
              <CardHeader>{ Lang[ 'HINT_TEXT' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>{ Lang[ 'SELECT_LOCATION' ][ props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                  <Input
                    type='select'
                    value={ props.selectedLocation.id }
                    style={{ maxWidth: '100%', width: 'min-content' }}
                    onChange={ e => props.getSelectedLocation( e.target.value ) }>
                    { 
                      props.locations.map( location => (
                        <option value={ location.id } >{ `${ location.name }, ${ location.street }, ${ location.city }` }</option> 
                      )) 
                    }
                  </Input>
                </FormGroup>
                <FormGroup>
                  <table>
                    { renderHintRow( 'COMPANY_NAME', props.selectedLocation.name, props.data.languageReducer.userSettings.admin_panel_language ) }
                    { renderHintRow( 'STREET', props.selectedLocation.street, props.data.languageReducer.userSettings.admin_panel_language ) }
                    { renderHintRow( 'HOUSE_NUMBER', props.selectedLocation.house_number, props.data.languageReducer.userSettings.admin_panel_language ) }
                    { renderHintRow( 'POSTCODE', props.selectedLocation.postcode, props.data.languageReducer.userSettings.admin_panel_language ) }
                    { renderHintRow( 'TOWN', props.selectedLocation.city, props.data.languageReducer.userSettings.admin_panel_language ) }
                    { renderHintRow( 'PHONE', props.selectedLocation.phone_number, props.data.languageReducer.userSettings.admin_panel_language ) }
                  </table>
                </FormGroup>
              </CardBody>
              {
                !_.isEmpty( props.selectedLocation ) && (
                  <CardFooter className='d-flex w-100 justify-content-end' >
                    <Button 
                      color='primary'
                      onClick={ () => {
                        document.getElementById( 'searchterm' ).value = props.selectedLocation.name
                        document.getElementById( 'street' ).value = props.selectedLocation.street
                        document.getElementById( 'house' ).value = props.selectedLocation.house_number
                        document.getElementById( 'zip' ).value = props.selectedLocation.postcode
                        document.getElementById( 'city' ).value = props.selectedLocation.city
                        document.getElementById( 'phone' ).value = props.selectedLocation.phone_number
                        toast.success( Lang[ 'VALUES_APPLIED' ][ props.data.languageReducer.userSettings.admin_panel_language ] )
                      }}>
                      { Lang[ 'APPLY_VALUE' ][ props.data.languageReducer.userSettings.admin_panel_language] }
                    </Button>
                  </CardFooter>
                )
              }
            </Card>
            <Card className='main-card mb-3'>
              <div id="scanDiv"></div>
              <Helmet>
                <script>
                  {`
                    window['lhScan'] = new LHScan();
                    lhScan.host = 'https://listing.lead-hub.de/';
                    lhScan.key = '9682cfee-9a59-4e3d-a83a-a7de61414ad7';
                    lhScan.divContainer = 'scanDiv';
                    lhScan.showPortalsWithErrors = true;
                    lhScan.activeProducts = true;
                    lhScan.init();
                  `}
                </script>
              </Helmet>
            </Card>
          </Col>
        </Row>
        { props.onLoadLocationsHOC && <LoadingOverlay /> }
      </Container>
    </>
  )
}

const renderHintRow = ( hintLabel, hintValue, admin_panel_language ) => (
  <tr className='align-top' >
    <td className='pr-2 pb-2'>
      <div className='d-flex w-100 justify-content-between flex-nowrap' >
        <span className='mr-2' >{ Lang[ hintLabel ][ admin_panel_language ] }</span>
        <span>:</span>
      </div>
    </td>
    <td className='pr-2 pb-2'>{ hintValue }</td>
    <td className='pr-2 pb-2'>
      <Button
        id={ `hint-text-${ hintLabel }` }
        color='primary'
        onClick={ () => {
          navigator.clipboard.writeText( hintValue )
          toast.success( `${ Lang[ hintLabel ][ admin_panel_language ] } ${ Lang[ 'IS_COPIED' ][ admin_panel_language ] }` )
        }}>
        <i className="pe-7s-copy-file"/>
      </Button>
      <UncontrolledTooltip
        target={ `hint-text-${ hintLabel }` }
        placement="top">
        { `${ Lang[ 'COPY' ][ admin_panel_language ] } ${ Lang[ hintLabel ][ admin_panel_language ] }` }
      </UncontrolledTooltip>
    </td>
  </tr>
)

export default LocationsHOC( Reports )