import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      templates: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error, {
      position: "bottom-right"
    })

    getTemplates = query => Get(
      `/api/v1/newsletter_templates?query=${ query }`,
      this.getTemplatesSuccess,
      this.getTemplatesError,
      this.load
    )
    getTemplatesSuccess = async( payload ) => await this.getSiteTemplates( payload.data )
    getTemplatesError = error => this.requestError( error )

    getSiteTemplates = center_group_templates => Get(
      `/api/v1/sites/${ this.props.data.selectCenterReducer.selectedCenter.center.site.id }/newsletter_templates`,
      payload => this.getSiteTemplatesSuccess( _.concat( payload, center_group_templates ) ),
      this.getSiteTemplatesError,
      this.load
    )
    getSiteTemplatesSuccess = result => this.setState({ templates: result })
    getSiteTemplatesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getTemplates={ this.getTemplates }
          templates={ this.state.templates }
          onLoadTemplates={ this.state.loading }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
  }

  export default HOC
