import React from 'react'
import {
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label,
  Button
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const ContactForm = ({
  contact,
  onChangeContactsHOC,
  addAlternativePhoneInput,
  selectedLanguage
}) => {

  const updateContact = async ( key, val ) => {
    let tmp = _.cloneDeep( contact )
    tmp[ key ] = val
    onChangeContactsHOC( 'contact', tmp )
  }

  const updateAlternativePhones = ( index, key, val ) => {
    let tmp = _.cloneDeep( contact.alternative_phones )
    tmp[ index ][ key ] = val
    let tmpContact = _.cloneDeep( contact )
    tmpContact.alternative_phones = tmp
    onChangeContactsHOC( 'contact', tmpContact )
  }

  const removeAlternativePhones = index => {
    let tmp = _.cloneDeep( contact.alternative_phones )
    tmp.splice( index, 1 )
    let tmpContact = _.cloneDeep( contact )
    tmpContact.alternative_phones = tmp
    onChangeContactsHOC( 'contact', tmpContact )
  }

  return(
    <Container>
      <Row>
        <Col md={ 8 } >
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'MAIN_PHONE' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'MAIN_PHONE' ][ selectedLanguage ] }
                value={ contact.main_phone ? contact.main_phone.phone : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( contact.main_phone )
                  tmp.phone = e.target.value
                  updateContact( 'main_phone', tmp ) } 
                } />
            </FormGroup>
            {/* <FormGroup check>
              <Input
                type="checkbox"
                checked={ contact.main_phone && contact.main_phone.tracked  }
                onChange={ e => {
                  let tmp = _.cloneDeep( contact.main_phone )
                  tmp.tracked = e.target.checked
                  updateContact( 'main_phone', tmp ) } 
                }/>
              <Label>{ Lang[ 'TRACKED' ][ selectedLanguage ] }</Label>
            </FormGroup> */}
            <hr />
            <FormGroup>
              <Label>{ Lang[ 'ALTERNATIVE_PHONE' ][ selectedLanguage ] }</Label>
            </FormGroup>
            {
              contact.alternative_phones && contact.alternative_phones.length > 0 && contact.alternative_phones.map( ( item, index ) => {
                return (
                  <div key={ index } style={{ display: 'inlineBlock', borderBottom: '1px solid #c2c2c2', paddingBottom: '11px', paddingTop: '11px', marginBottom: '10px' }}>
                    {
                      Object.keys( item ).map(( key ) => {
                        if( key !== 'id' ) {
                          if( key === 'phone' ) {
                            return (
                              <FormGroup>
                              <Label>{ Lang[ 'PHONE' ][ selectedLanguage ] }</Label>
                              <Input
                                type='text'
                                placeholder={ Lang[ 'PHONE' ][ selectedLanguage ] }
                                value={ contact.alternative_phones[ index ][ key ] }
                                onChange={ e => updateAlternativePhones( index, key, e.target.value ) } />
                            </FormGroup>
                            )
                          } else if ( key === 'phone_type' ) {
                            return (
                              <FormGroup>
                                <Label>{ Lang[ 'PHONE_TYPE' ][ selectedLanguage ] }</Label>
                                <Input
                                  type="select"
                                  onChange={ e => updateAlternativePhones( index, key, e.target.value ) }
                                  value={ contact.alternative_phones[ index ][ key ] } >
                                  <option value={ '' }>{ '' }</option>
                                  <option value={ 'landLine' }>{ Lang[ 'LANDLINE' ][ selectedLanguage ] }</option>
                                  <option value={ 'fax' }>{ Lang[ 'FAX' ][ selectedLanguage ] }</option>
                                  <option value={ 'mobile' }>{ Lang[ 'MOBILE' ][ selectedLanguage ] }</option>
                                </Input>
                              </FormGroup>
                            )
                          } else if ( key === 'tracked' ) {
                            return (
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  checked={ contact.alternative_phones[ index ][ key ] }
                                  onChange={ e => updateAlternativePhones( index, key, e.target.checked ) } />
                                <Label>{ Lang[ 'TRACKED' ][ selectedLanguage ] }</Label>
                              </FormGroup>
                            )
                          }
                        }
                      })
                    }
                    <div>
                      {
                        index === contact.alternative_phones.length - 1 && (
                          <Button
                            color={ 'primary' }
                            style={{ margin: '15px 0' }}
                            onClick={ () => addAlternativePhoneInput() }>
                            { Lang[ 'ADD' ][ selectedLanguage ] }
                          </Button>
                        )
                      }
                      {
                        index === contact.alternative_phones.length - 1 && contact.alternative_phones[ contact.alternative_phones.length - 1 ].phone === '' && (
                          <Button
                            color={ 'secondary' }
                            style={{ margin: '15px 0 15px 10px' }}
                            onClick={ () => removeAlternativePhones( index ) }>
                            { Lang[ 'REMOVE' ][ selectedLanguage ] }
                          </Button>
                        )
                      }
                    </div>
                  </div>
                )
              })
            }
            {
              contact.alternative_phones && contact.alternative_phones.length == 0 && (
                <Button
                  color={ 'primary' }
                  style={{ marginBottom: '15px' }}
                  onClick={ () => addAlternativePhoneInput() }>
                  { Lang[ 'ADD' ][ selectedLanguage ] }
                </Button> 
              )
            }
            <FormGroup>
              <Label>Email</Label>
              <Input
                type='text'
                placeholder={ 'Email' }
                value={ contact.email ? contact.email : '' }
                onChange={ e => updateContact( 'email', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'WEBSITE_URL' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'WEBSITE_URL' ][ selectedLanguage ] }
                value={ contact.website ? contact.website.url : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( contact.website )
                  tmp.url = e.target.value
                  updateContact( 'website', tmp ) } 
                } />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'WEBSITE_DISPLAY' ][ selectedLanguage ] }</Label>
              <Input
                type='text'
                placeholder={ Lang[ 'WEBSITE_DISPLAY' ][ selectedLanguage ] }
                value={ contact.website ? contact.website.display : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( contact.website )
                  tmp.display = e.target.value
                  updateContact( 'website', tmp ) } 
                } />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactForm