import React from 'react'
import _ from 'lodash'
import { Progress } from 'reactstrap'
import Lang from 'Lang/General'

const StatsCard = ({
  item,
  admin_panel_language
}) => {
  return (
    <>
      <div className="d-flex mb-4">
        <i 
          style={{ fontSize: '2.2rem', padding: '0.8rem', opacity: 0.8, borderRadius: '0.5rem', height: 'fit-content' }}
          className={ `text-white ${ item.icon } bg-primary` }
        />
        <div className='d-flex ml-auto' style={{ flexDirection: 'column', textAlign: 'end' }}>
          <h6 style={{ opacity: 0.5 }}>{ item.title }</h6>
          <h1 style={{ fontWeight: 'bold' }}>{ item.total_value }</h1>
        </div>
      </div>
      {
        item.children.map( childItem => (
          <>
            <div className="card widget-content mb-2">
              <div className="widget-content-outer w-100">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                        <div className="widget-heading">
                          { childItem.progress_label }
                        </div>
                    </div>
                    <div className="widget-content-right">
                        <div className={ `widget-numbers text-${ childItem.color }` }>
                          { `${ childItem.value || 0 }` }
                        </div>
                    </div>
                </div>
                <div className="widget-progress-wrapper" style={{ marginTop: '0.6rem' }}>
                  <Progress 
                    className="progress-bar-sm" 
                    color={ childItem.color } value={ 
                      childItem.value > 0 
                        ? ( childItem.value/item.total_value * 100 ).toFixed( 2 ) 
                        : 0
                    }/>
                  <div className="progress-sub-label">
                      <div className="sub-label-left">
                        <span style={{ fontSize: '0.8rem' }}>{ Lang[ 'PERCENTAGE' ][ admin_panel_language ] }</span>
                      </div>
                      <div className="sub-label-right">
                        { 
                          item.total_value 
                            ? `${( childItem.value/item.total_value * 100 ).toFixed( 2 ) }%`
                            : '0%'
                        }
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))
      }
    </>
  )
}

export default StatsCard;