import React, { Component, Fragment } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios-template'

const CountryHOC = ( WrappedComponent ) => {
  class CountryWrappedComponent extends Component {
    state = {
      countries: [],
      states: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getCountries = () => Get(
      `/api/v1/countries`,
      this.getCountriesSuccess,
      this.getCountriesError,
      this.load
    )
    getCountriesSuccess = payload => this.setState({ countries: payload }, () => {
      this.getStates( payload[0].id )
    })
    getCountriesError = error => this.requestError( error )

    getStates = country_id => Get(
      `/api/v1/countries/${ country_id }/states`,
      this.getStatesSuccess,
      this.getStatesError,
      this.load
    )
    getStatesSuccess = payload => this.setState({ states: payload })
    getStatesError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            onLoadCountries={ this.state.loading }
            countries={ this.state.countries }
            states={ this.state.states }
            getCountries={ this.getCountries }
            getStates={ this.getStates } />
        </Fragment>
      )
    }
  }
  return CountryWrappedComponent
}

export default CountryHOC
