import React, { Component } from 'react'
import {
  Row,
  Col,
  Button,
  Input,
  Label,
  CustomInput
} from 'reactstrap'
import { MdAdd } from 'react-icons/md'
import Moment from 'moment'
import _ from 'lodash'

import TimeRangePicker from './timeRangePicker'
// import CreateGroup from './createGroup'
import Lang from 'Lang/General'

class OpeningHours extends Component {
  renderClosedDay = () => {
    return(
      <span style={{ color: "#cecece" }}>
        <strong style={{ color: "#b83c3b" }} >{ Lang[ 'CLOSED' ][ this.props.selectedLanguage ] } </strong>
        ( { Lang[ 'ADD_TIME_WINDOW' ][ this.props.selectedLanguage ] } )
      </span>
    )
  }

  sortTimeIntervals = ( newOpeningHourData, dayIndex, intervalId ) => {
    let tmpSelectedGroup = _.cloneDeep( newOpeningHourData )
    tmpSelectedGroup.days.map( day => day.timeIntervals.sort( ( a, b ) => (
        new Date( a.from ).getHours() < new Date( b.from ).getHours()
        ? -1
        : new Date( a.from ).getHours() > new Date( b.from ).getHours()
        ? 1
        : new Date( a.from ).getHours() === new Date( b.from ).getHours()
        ? new Date( a.from ).getMinutes() < new Date( b.from ).getMinutes()
          ? -1
          : new Date( a.from ).getMinutes() > new Date( b.from ).getMinutes() ? 1 : 0
        : 0
      )
    ))

    if( dayIndex >= 0 && intervalId >= 0 ){
      let removeInterval = false
      let intervalIndex = tmpSelectedGroup.days[ dayIndex ].timeIntervals.findIndex( x => x.id === intervalId )
      let currentInterval = tmpSelectedGroup.days[ dayIndex ].timeIntervals[ intervalIndex ]

      if( !_.isEmpty( tmpSelectedGroup.days[ dayIndex ].timeIntervals[ intervalIndex + 1 ] ) ){
        let laterInterval = tmpSelectedGroup.days[ dayIndex ].timeIntervals[ intervalIndex + 1 ]

        if( Moment( currentInterval.to ).diff( Moment( laterInterval.from ) ) >=0 ){
          currentInterval.to = Moment( laterInterval.from ).subtract( 'minutes', 1 )

          if( Moment( currentInterval.from ).diff( Moment( currentInterval.to ) ) >=0 ){
            removeInterval = true
          }
        }
      }

      if( !_.isEmpty( tmpSelectedGroup.days[ dayIndex ].timeIntervals[ intervalIndex - 1 ] ) ){
        let previousInterval = tmpSelectedGroup.days[ dayIndex ].timeIntervals[ intervalIndex - 1 ]

        if( Moment( previousInterval.to ).diff( Moment( currentInterval.from ) ) >=0 ){
          currentInterval.from = Moment( previousInterval.to ).add( 'minutes', 1 )

          if( Moment( currentInterval.from ).diff( Moment( currentInterval.to ) ) >=0 ){
            removeInterval = true
          }
        }
      }

      if( removeInterval ){
        tmpSelectedGroup.days[ dayIndex ].timeIntervals.splice( intervalIndex, 1 )
      }
    }

    this.props.onChangeLocationsHOC( 'newOpeningHourData', tmpSelectedGroup )
  }

  render = () => {
    return(
      <Col md={ 12 } >
        {
          _.isEmpty( this.props.selectedOpeningHour.opening_hour ) && this.props.newOpeningHourData.days.map( ( day, index ) => <Row style={{
            border: "1px solid #cecece",
            borderLeft: "none",
            borderRight: "none",
            borderTop: index === 0 ? "1px" : "none",
            padding: "10px 0",
            margin: "0",
            alignItems: "center" }} >
            <Col md={ 1 } style={{ paddingLeft: "0" }} >{ Lang[ day.day ][ this.props.selectedLanguage ] }</Col>
            <Col md={ 3 } style={{ paddingLeft: "0" }}>
              <Input
                value={ day.openingDayType }
                type="select"
                onChange={ e => {
                  let tmpSelectedOpeningHour = _.cloneDeep( this.props.newOpeningHourData )
                  let tmpDay = _.cloneDeep( day )
                  tmpDay.openingDayType = e.target.value
                  tmpDay.timeIntervals = []
                  let tmpDayIndex = tmpSelectedOpeningHour.days.findIndex( x => x.day === tmpDay.day )
                  tmpSelectedOpeningHour.days[ tmpDayIndex ] = tmpDay
                  this.props.onChangeLocationsHOC( 'newOpeningHourData', tmpSelectedOpeningHour )
                }}>
                <option value="singleInterval">{ Lang[ 'SINGLE_INTERVAL' ][ this.props.selectedLanguage ] }</option>
                <option value="doubleInterval">{ Lang[ 'DOUBLE_INTERVAL' ][ this.props.selectedLanguage ] }</option>
                <option value="closed">{ Lang[ 'CLOSED' ][ this.props.selectedLanguage ] }</option>
              </Input>
            </Col>
            {
              day.openingDayType !== 'closed' && ( (day.openingDayType === 'singleInterval' && day.timeIntervals.length < 1 ) ||
                (day.openingDayType === 'doubleInterval' && day.timeIntervals.length < 2 ) ) && (
                  <Col md={ 1 } style={{ padding: "0" }} >
                    <Button
                      color='primary'
                      style={{
                        width: "max-content",
                        padding: "5px",
                        border: "1px solid #cecece",
                        borderRadius: "3px",
                        justifyContent: "center"}}
                      onClick={ () => {
                        let tmpGroup = _.cloneDeep( this.props.newOpeningHourData )
                        let tmpDay = _.cloneDeep( day )
                        let tmpInterval = {
                          id: 0,
                          from: day.timeIntervals[0] ? Moment( day.timeIntervals[0].from ).subtract( 'minutes', 2 ).format() : Moment().format(),
                          to: day.timeIntervals[0] ? Moment( day.timeIntervals[0].from ).subtract( 'minutes', 1 ).format() : Moment().add( 'hours', 1 ).format()
                        }
                        tmpDay.timeIntervals.unshift( tmpInterval )
                        let tmpDayIndex = tmpGroup.days.findIndex( x => x.day === tmpDay.day )
                        tmpGroup.days[ tmpDayIndex ] = tmpDay
                        tmpGroup.days[ tmpDayIndex ].timeIntervals.map( ( interval, index ) => interval.id = index )
                        this.sortTimeIntervals( tmpGroup )
                        this.props.onChangeLocationsHOC( 'newOpeningHourData', tmpGroup )
                      }}>
                      <MdAdd size={ 20 }/>
                    </Button>
                  </Col>
                )
            }
            <Col md={ 7 }>
              <Row>
              {
                day.timeIntervals.length > 0
                ?  day.timeIntervals.map
                (
                  interval => <TimeRangePicker
                    newOpeningHourData={ this.props.newOpeningHourData }
                    day={ day }
                    interval={ interval }
                    from={ interval.from }
                    to={ interval.to }
                    onChangeLocationsHOC={ this.props.onChangeLocationsHOC }
                    sortTimeIntervals={ this.sortTimeIntervals }
                    selectedOpeningHour={ this.props.selectedOpeningHour } />
                )
                : this.renderClosedDay()
              }
              </Row>
            </Col>
          </Row> )
        }
        {
          !_.isEmpty( this.props.selectedOpeningHour.opening_hour ) && this.props.selectedOpeningHour.opening_hour.days.map( ( day, index ) => <Row style={{
            border: "1px solid #cecece",
            borderLeft: "none",
            borderRight: "none",
            borderTop: index === 0 ? "1px" : "none",
            padding: "10px 0",
            margin: "0",
            alignItems: "center" }} >
            <Col md={ 1 } style={{ paddingLeft: "0" }} >{ Lang[ day.day ][ this.props.selectedLanguage ] }</Col>
            <Col md={ 3 } style={{ paddingLeft: "0" }}>
              <Input
                value={ day.openingDayType }
                type="select"
                onChange={ e => {
                  let tmpSelectedOpeningHour = _.cloneDeep( this.props.selectedOpeningHour.opening_hour )
                  let tmpDay = _.cloneDeep( day )
                  tmpDay.openingDayType = e.target.value
                  tmpDay.timeIntervals = []
                  let tmpDayIndex = tmpSelectedOpeningHour.days.findIndex( x => x.day === tmpDay.day )
                  tmpSelectedOpeningHour.days[ tmpDayIndex ] = tmpDay
                  let tmp = {
                      ...this.props.selectedOpeningHour,
                      opening_hour: tmpSelectedOpeningHour
                    }
                  this.props.onChangeLocationsHOC( 'selectedOpeningHour', tmp )
                }}>
                <option value="singleInterval">{ Lang[ 'SINGLE_INTERVAL' ][ this.props.selectedLanguage ] }</option>
                <option value="doubleInterval">{ Lang[ 'DOUBLE_INTERVAL' ][ this.props.selectedLanguage ] }</option>
                <option value="closed">{ Lang[ 'CLOSED' ][ this.props.selectedLanguage ] }</option>
              </Input>
            </Col>
            {
              day.openingDayType !== 'closed' && ( (day.openingDayType === 'singleInterval' && day.timeIntervals.length < 1 ) ||
                (day.openingDayType === 'doubleInterval' && day.timeIntervals.length < 2 ) ) && (
                  <Col md={ 1 } style={{ padding: "0" }} >
                    <Button
                      color='primary'
                      style={{
                        width: "max-content",
                        padding: "5px",
                        border: "1px solid #cecece",
                        borderRadius: "3px",
                        justifyContent: "center"}}
                      onClick={ () => {
                        let tmpGroup = _.cloneDeep( this.props.selectedOpeningHour.opening_hour )
                        let tmpDay = _.cloneDeep( day )
                        let tmpInterval = {
                          id: 0,
                          from: '00:00',
                          to: '00:00'
                        }
                        tmpDay.timeIntervals.unshift( tmpInterval )
                        let tmpDayIndex = tmpGroup.days.findIndex( x => x.day === tmpDay.day )
                        tmpGroup.days[ tmpDayIndex ] = tmpDay
                        tmpGroup.days[ tmpDayIndex ].timeIntervals.map( ( interval, index ) => interval.id = index )
                        this.sortTimeIntervals( tmpGroup )
                        let tmp = {
                          ...this.props.selectedOpeningHour,
                          opening_hour: tmpGroup
                        }
                        this.props.onChangeLocationsHOC( 'selectedOpeningHour', tmp )
                      }}>
                      <MdAdd size={ 20 }/>
                    </Button>
                  </Col>
                )
            }
            <Col md={ 7 }>
              <Row>
              {
                day.timeIntervals.length > 0
                ?  day.timeIntervals.map
                (
                  interval => <TimeRangePicker
                    day={ day }
                    interval={ interval }
                    from={ interval.from }
                    to={ interval.to }
                    onChangeLocationsHOC={ this.props.onChangeLocationsHOC }
                    sortTimeIntervals={ this.sortTimeIntervals }
                    selectedOpeningHour={ this.props.selectedOpeningHour } />
                )
                : this.renderClosedDay()
              }
              </Row>
            </Col>
          </Row> )
        }
        {/* <CreateGroup
          newGroup={ this.props.newGroup }
          showCreateGroup={ this.props.showCreateGroup }
          onChangeLocationsHOC={ this.props.onChangeLocationsHOC }/> */}
      </Col>
    )
  }
}

export default OpeningHours