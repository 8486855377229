import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Col
} from 'reactstrap'
import _ from 'lodash'
import ReactHtmlParser from "react-html-parser"
import Lang from 'Lang/General'

const ProductInfo = ({
  data,
  onChangeProductRequestsHOC,
  showRequestInfoModal,
  selectedProduct,
  newRequest,
  createProductsRequest,
  onChangeLatestCampaignHOC,
  lang
}) => {
  return (
    <Modal
      isOpen={ showRequestInfoModal }
      size={ 'lg' }
      toggle={ () => onChangeProductRequestsHOC( 'showRequestInfoModal', false ) }>
      <ModalHeader toggle={ () => onChangeProductRequestsHOC( 'showRequestInfoModal', false ) }>{ Lang[ 'PRODUCT_INFO' ][ lang ] }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( selectedProduct ) && (
            <Container className="d-flex p-2">
              <Col md={6}>
                <img src={ selectedProduct.image_url } style={{ width: '300px', height: '100%', marginBottom: '20px' }}/>
              </Col>
              <Col md={6}>
                <h2>{ selectedProduct.name[ lang ] }</h2>
                <span>
                  {
                    ReactHtmlParser( selectedProduct.description[ lang ] )
                  }
                </span>
                <p className="mt-1">
                  {
                    `${ selectedProduct.currency_price_1[ lang ] || `xx,xxx` }, ${ selectedProduct.currency_price_2[ lang ] || `USD`  }`
                  }
                </p>
                <p className="mt-3">
                  {
                    !_.isEmpty( selectedProduct.pdf ) && selectedProduct.pdf_url && selectedProduct.pdf_url !== "" && (
                      <a href={ selectedProduct.pdf_url } target="_blank" className="mb-2">{ Lang['DOWNLOAD_PDF'][lang] }</a>
                    )
                  }
                </p>
              </Col>
            </Container>
          )
        }
        {
          selectedProduct.is_requested &&
            <p style={{ color: 'red' }}>
              { Lang[ 'ALREADY_REQUESTED_INFO' ][ lang ] }
            </p>
        }
      </ModalBody>
      <ModalFooter>
        <Button onClick={ () => onChangeProductRequestsHOC( 'showRequestInfoModal', false ) }>{ Lang[ 'CLOSE' ][ lang ]}</Button>
        <Button
          color="primary"
          style={{
            display: selectedProduct.is_requested ? 'none' : ''
          }}
          onClick={ () => {
            let tmp = _.cloneDeep( newRequest )
            tmp = {
              client_id: data.selectCenterReducer.selectedCenter.center.client_id,
              consultant_id: data.selectCenterReducer.selectedCenter.center.consultant_id,
              user_id: data.profileReducer.profile.id,
              status_id: 1,
              product_id: selectedProduct.id,
              center_id: data.selectCenterReducer.selectedCenter.center.id
            }
            onChangeProductRequestsHOC( 'newRequest', tmp )
            createProductsRequest( tmp, selectedProduct.id )
            onChangeLatestCampaignHOC( 'showAlertModal', false )
          } }>{ Lang['REQUEST_INFO' ][ lang ]}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ProductInfo
