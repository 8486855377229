import {
  beginAjaxCall,
  ajaxCallSuccess
} from './ajax';
import { GET_PLATFORM_CONFIG } from './type'
import getDomainURL from 'utils/api';
import { getCipherHeader } from '../utils/cipher-text';

export const getPlatformConfig = () => {
  return async( dispatch ) => {
    dispatch( beginAjaxCall( false ) );
    let platformConfigsJSON = []
    const platformConfigs = await fetch( `${ getDomainURL() }/api/v1/platform_config`, { headers: { Authorization: getCipherHeader() } } )
    platformConfigsJSON = await platformConfigs.json()
    if( platformConfigsJSON.length > 0 ){
      dispatch( getPlatformConfigSuccess( platformConfigsJSON ) )
    }
    dispatch( ajaxCallSuccess() )
  };
}

const getPlatformConfigSuccess = payload => ({
  type: GET_PLATFORM_CONFIG,
  payload
})