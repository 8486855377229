import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import moment from 'moment'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import WithRecipientGroup from './actions'
import ArkPagination from 'components/Pagination'
import DeleteConfirmation from 'components/Modal/deleteConfirmation'
import AlertNotSubscribe from 'components/Alert'
import { convertSearchObjectToBase64 } from 'utils/objToBase64'
import DictionaryHOC from 'utils/dictionary'
import RecipientHOC from './actions/recipient'
import RecipientsForm from './components/recipientForm'
import RecipientGroupForm from './components/form'
import View from './components/view'

import Lang from 'Lang/General'

class RecipientGroupListings extends Component {
  state = {
    subscribedPackage: true,
    selectedRecipientToRemove: {
      recipient_group_id: 0,
      recipient_id: 0
    },
    targetRecipientGroupId: 0
  }

  componentDidMount = () => {
    let tmp = {
      is_paginated: true,
      page: 1,
    }
    let tmpString = convertSearchObjectToBase64( tmp )
    this.props.getDictionary()
    this.props.getRecipientGroup( tmpString )
    let tmpIndex = _.findIndex( this.props.data.selectCenterReducer.selectedCenter.center.modules, { name: 'Newsletters' })
    this.setState({ subscribedPackage: tmpIndex > -1 })
  }

  componentDidUpdate = pp => {
    if( 
      !this.props.showViewRecipientGroupModal &&
      this.props.recipientLists !== pp.recipientLists
    ){
      this.props.getAllRecipients( convertSearchObjectToBase64({
        is_blacklisted: false,
        status: 'ACTIVE',
        is_paginated: false,
      }) )
    }
    if( pp.showCreateRecipientGroup && !this.props.showCreateRecipientGroup ) {
      this.props.onChangeRecipientGroupHOC( 'newRecipientGroupData', { name: '', status: 'ACTIVE' } )
    }
    if( pp.showMoveRecipientModal && !this.props.showMoveRecipientModal ) {
      this.props.onChangeRecipientGroupHOC( 'summary', {} )
      this.setState({ targetRecipientGroupId: 0 })
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_create && this.state.subscribedPackage
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Aciso Cockpit' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletters' })
      return tmpModules.is_delete
    }
  }

  renderCreateModal = () => {
    return(
      <RecipientGroupForm
        data={ this.props.newRecipientGroupData }
        onChangeRecipientGroupHOC={ this.props.onChangeRecipientGroupHOC }
        toggle={ () => this.props.onChangeRecipientGroupHOC( 'showCreateRecipientGroup', false ) }
        isOpen={ this.props.showCreateRecipientGroup }
        headerText={ Lang[ 'CREATE_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        mode="create"
        onSubmit={ this.props.postRecipientGroup }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }
        allRecipients={ this.props.allRecipients }
        assignedRecipients={ this.props.assignedRecipients }
        onLoadRecipientGroup={ this.props.onLoadRecipientGroup }
        onLoadRecipients={ this.props.onLoadRecipients } />
    )
  }
  renderEditModal = () => {
    return(
      <RecipientGroupForm
        data={ this.props.selectedRecipientGroup }
        onChangeRecipientGroupHOC={ this.props.onChangeRecipientGroupHOC }
        toggle={ () => this.props.onChangeRecipientGroupHOC( 'showEditModal', false ) }
        isOpen={ this.props.showEditModal }
        headerText={ Lang[ 'EDIT_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        mode="edit"
        onSubmit={ this.props.updateRecipientGroup }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }
        allRecipients={ this.props.allRecipients }
        assignedRecipients={ this.props.assignedRecipients }
        postRecipientIntoGroup={ this.props.postRecipientIntoGroup }
        onChangeRecipientHOC={ this.props.onChangeRecipientHOC }
        recipientLists={ this.props.recipientLists }
        emptyRecipientFromGroup={ this.props.emptyRecipientFromGroup }
        confirmEmptyGroup={ this.props.confirmEmptyGroup }
        onLoadRecipientGroup={ this.props.onLoadRecipientGroup }
        onLoadRecipients={ this.props.onLoadRecipients } />
    )
  }

  renderViewModal = () => {
    return(
      <View
        { ...this.props }
        data={ this.props.selectedRecipientGroup }
        toggle={ () => this.props.onChangeRecipientGroupHOC( 'showViewRecipientGroupModal', false ) }
        isOpen={ this.props.showViewRecipientGroupModal }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language } />
    )
  }

  renderEditRecipientModal = () => {
    return(
      <RecipientsForm
        data={ this.props.selectedRecipient }
        onChangeRecipientHOC={ this.props.onChangeRecipientHOC }
        toggle={ () => this.props.onChangeRecipientHOC( 'showEditRecipientModal', false ) }
        isOpen={ this.props.showEditRecipientModal }
        headerText={ Lang[ 'EDIT_RECIPIENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        status={ this.props.dictionaries.status }
        mode="edit"
        onSubmit={ () => {
          let tmp = {
            page: 1,
            is_paginated: true
          }
          if( this.props.showViewRecipientGroupModal ){
            tmp.is_blacklisted = false
          }
          this.props.updateRecipient( convertSearchObjectToBase64( tmp ), this.props.selectedRecipientGroup.id )
        }}
        recipientGroup={ this.props.recipientGroup.data }
        selectedLanguage={ this.props.data.languageReducer.userSettings.admin_panel_language }
        onLoadRecipientGroup={ this.props.onLoadRecipientGroup }
        onLoadRecipients={ this.props.onLoadRecipients } />
    )
  }

  renderMoveRecipientModal = () => {
    return(
      <Modal isOpen={ this.props.showMoveRecipientModal } size='lg'>
        <ModalHeader toggle={ () => this.props.onChangeRecipientGroupHOC( 'showMoveRecipientModal', false ) }>
          { Lang[ 'MOVE_RECIPIENT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
        </ModalHeader>
        <ModalBody>
          <p>{ Lang[ 'MOVE_RECIPIENT_BEFORE_DELETE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</p>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>{ Lang[ 'TARGET_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
              <Input
                type='select'
                disabled={ !_.isEmpty( this.props.summary ) }
                value={ this.state.targetRecipientGroupId }
                onChange={ e => this.setState({ targetRecipientGroupId: e.target.value }) }>
                <option value={ 0 } >{ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</option>
                { 
                  _.filter( this.props.recipientGroupWithoutPagination, group => group.id !== this.props.selectedRecipientGroup.id ).map( item => {
                    return <option value={ item.id } >{ item.name }</option>
                  }) 
                }
              </Input>   
            </FormGroup>
          </Form>
          {
            !_.isEmpty( this.props.summary ) && (
              <Card>
                <CardHeader>{ Lang[ 'SUMMARY' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                <CardBody>
                  <p>{ Lang[ 'NUMBER_OF_RECIPIENT_ADDED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }: { this.props.summary.number_of_users_added }</p>
                  <p>{ Lang[ 'NUMBER_OF_RECIPIENT_ALREADY_IN_TARGET_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }: { this.props.summary.number_of_users_already_in_target_group }</p>
                  <p>{ Lang[ 'NUMBER_OF_RECIPIENT_REMOVED' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }: { this.props.summary.number_of_users_removed }</p>
                </CardBody>
              </Card>
            )
          }
          { ( this.props.onLoadRecipientGroup || this.props.onLoadRecipients ) && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          {
            _.isEmpty( this.props.summary ) ? (
              <Button 
                color='primary'
                disabled={ this.state.targetRecipientGroupId === 0 }
                onClick={ () => this.props.moveRecipientToAnotherGroup( this.props.selectedRecipientGroup.id, this.state.targetRecipientGroupId ) }>
                { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
              </Button>
            ) : (
              <Button 
                color='danger'
                onClick={ () => this.props.deleteRecipientGroup() }>
                { Lang[ 'REMOVE_EXISTING_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
              </Button>
            )
          }
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'RECIPIENT_GROUPS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
          icon="pe-7s-news-paper icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
              onClick: () => {
                this.props.onChangeRecipientGroupHOC( 'showCreateRecipientGroup', true )
              },
              display: this.handleSecurityModulesCreate()
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }>
          <Container fluid>
            {
              !this.state.subscribedPackage && (
                <AlertNotSubscribe { ...this.props } />
              )
            }
            <Row>
              <Col md={ 12 }>
                <Card className="main-card mb-3">
                  <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</CardHeader>
                  <CardBody>
                    <Form onSubmit={ e => e.preventDefault() }>
                      <FormGroup>
                        <Label>{ Lang[ 'NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Label>
                        <Input
                          type={ 'text' }
                          value={ this.props.searchParamsRG[0].params }
                          onChange={ e => {
                            let tmp = _.cloneDeep( this.props.searchParamsRG )
                            tmp[ 0 ][ 'param' ] = e.target.value
                            return this.props.onChangeRecipientGroupHOC( 'searchParamsRG', tmp )
                          }} />
                      </FormGroup>
                      <div className="d-flex">
                        <Button
                          color="primary"
                          style={{ marginLeft: 'auto' }}
                          onClick={() => {
                            let tmpSearchParams = {
                              is_paginated: true,
                              page: 1,
                              name: this.props.searchParamsRG[ 0 ].param,
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipientGroup( tmpSearchString )
                          }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                        <Button
                          color="danger"
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            let tmpSearchParams = {
                              is_paginated: true,
                              page: 1,
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipientGroup( tmpSearchString )
                          }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }</Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col md={ 12 }>
                <Card className="main-card mb-3">
                  <CardBody>
                    <ReactTable
                      NoDataComponent={ () => <span></span> }
                      data={ this.props.recipientGroup.data }
                      columns={[
                        {
                          Header: Lang[ 'NAME' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'name',
                          Cell: (row) => <span>{ `${ row.original.name }(${ row.original.active_recipient_count })` }</span>
                        },
                        {
                          Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'status',
                          Cell: row => Lang[ row.original.status ][ this.props.data.languageReducer.userSettings.admin_panel_language ]
                        },
                        {
                          Header: Lang[ 'CREATED_AT' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'created_at',
                          Cell: row => {
                            return moment( row.original.created_at ).format( 'DD/MM/YYYY HH:mm' )
                          }
                        },
                        {
                          Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.userSettings.admin_panel_language ],
                          accessor: 'id',
                          Cell: ( row ) => (
                            <div className="d-flex flex-row align-items-center justify-content-center w-100">
                            {
                              row.original.name !== 'Alle' && <>
                                <Button
                                  id={ `Recipient-group-${ row.original.id }-view` }
                                  className="mr-2 btn-icon btn-icon-only"
                                  color="primary"
                                  onClick={ () => {
                                    let tmpFilter = {
                                      page: 1,
                                      is_paginated: true,
                                      is_blacklisted: false
                                    }
                                    this.props.onChangeRecipientGroupHOC( 'showViewRecipientGroupModal', true )
                                    this.props.onChangeRecipientGroupHOC( 'selectedRecipientGroup', row.original )
                                    this.props.getRecipientLists( convertSearchObjectToBase64( tmpFilter ), row.original.id )
                                  }}
                                  disabled={ !this.state.subscribedPackage }>
                                  <i className="pe-7s-look"></i>
                                </Button>
                                <UncontrolledTooltip
                                  target={ `Recipient-group-${ row.original.id }-view` }
                                  placement="top">
                                  { Lang[ 'VIEW_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                </UncontrolledTooltip>
                              </>
                            }
                            {
                              this.handleSecurityModulesUpdate() && row.original.name !== 'Alle' && row.original.name !== 'Default' && <>
                                <Button
                                  id={ `Recipient-group-${ row.original.id }-update` }
                                  disabled={ !this.state.subscribedPackage }
                                  className="mr-2 btn-icon btn-icon-only"
                                  color="primary"
                                  onClick={() => {
                                    let tmpFilter = {
                                      is_paginated: false,
                                      is_blacklisted: false
                                    }
                                    this.props.onChangeRecipientGroupHOC( 'showEditModal', true )
                                    this.props.getSelectedRecipientGroup( row.original.id )
                                    this.props.getRecipientLists( convertSearchObjectToBase64( tmpFilter ), row.original.id )
                                  }}>
                                  <i className="pe-7s-note"></i>
                                </Button>
                                <UncontrolledTooltip
                                  target={ `Recipient-group-${ row.original.id }-update` }
                                  placement="top">
                                  { Lang[ 'EDIT_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                </UncontrolledTooltip>
                              </>
                            }
                            {
                              this.handleSecurityModulesDelete() && row.original.name !== 'Alle' && row.original.name !== 'Default' && <>
                                <Button
                                  id={ `Recipient-group-${ row.original.id }-delete` }
                                  disabled={ !this.state.subscribedPackage }
                                  className="mr-2 btn-icon btn-icon-only"
                                  color="danger"
                                  onClick={() => {
                                    if( row.original.total_recipient_count > 0  ) {
                                      this.props.onChangeRecipientGroupHOC( 'showMoveRecipientModal', true )
                                      this.props.getRecipientGroupWithoutPagination( convertSearchObjectToBase64({ is_paginated: false }) )
                                    } else {
                                      this.props.onChangeRecipientGroupHOC( 'showDeleteConfirmation', true )
                                    }
                                    this.props.getSelectedRecipientGroup( row.original.id )
                                  }}>
                                  <i className="pe-7s-trash"></i>
                                </Button>
                                <UncontrolledTooltip
                                  target={ `Recipient-group-${ row.original.id }-delete` }
                                  placement="top">
                                  { Lang[ 'REMOVE_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
                                </UncontrolledTooltip>
                              </>
                            }
                            </div>
                          )
                        }
                      ]}
                      showPagination={ false }
                      defaultPageSize={ 10 } />
                    {
                      this.props.recipientGroup.data && this.props.recipientGroup.data.length > 0 && (
                        <ArkPagination
                          meta={ this.props.recipientGroup.meta }
                          onChangePage={ page => {
                            let tmpSearchParams = {
                              email: this.props.searchParamsRG[ 0 ].param,
                              page: page,
                              is_paginated: true
                            }
                            let tmpSearchString = convertSearchObjectToBase64( tmpSearchParams )
                            this.props.getRecipientGroup( tmpSearchString )
                          }} />
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
        { this.renderCreateModal() }
        { this.renderEditModal() }
        { this.renderViewModal() }
        { this.renderEditRecipientModal() }
        { this.renderMoveRecipientModal() }
        {
          this.props.showDeleteConfirmation && (
            <DeleteConfirmation
              handleModalClose={ () => this.props.onChangeRecipientGroupHOC( 'showDeleteConfirmation', false ) }
              deleteOpen={ this.props.showDeleteConfirmation }
              confirmAction={ () => {
                this.props.onChangeRecipientGroupHOC( 'showDeleteConfirmation', false )
                this.props.deleteRecipientGroup()
              }}
              title={ Lang[ 'DELETE_RECIPIENTS_GROUP_TITLE' ][ this.props.data.languageReducer.userSettings.admin_panel_language ]}
              content={ Lang[ 'ARE_YOU_SURE_DELETE_RECIPIENT_GROUP' ][ this.props.data.languageReducer.userSettings.admin_panel_language ] }
              lang={ this.props.data.languageReducer.userSettings.admin_panel_language }/>
          )
        }
        { ( this.props.onLoadRecipientGroup || this.props.onLoadRecipients ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  RecipientHOC,
  WithRecipientGroup,
  DictionaryHOC
)( RecipientGroupListings )
