import React, { Component } from 'react'
import { compose } from 'redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel'
import _ from 'lodash'
import Moment from 'moment'

import LoadingIndicator from 'components/Indicator/LoadingOverlay'
import BookConfirmationModal from 'components/Indicator/Prompt'
import ProductsHOC from './actions'
import RequestHOC from './actions/requestInfo'
import ViewInfo from './View'
import ViewAll from './ViewAll'
import defaultLogo from 'assets/images/logo-aciso.png'
import Lang from 'Lang/General'

class Products extends Component {
  componentDidMount = () => {
    this.props.getRequestedProduct()
  }
  componentDidUpdate = pp => {
    if( this.props.requestedProduct !== pp.requestedProduct ){
      this.props.getAllProducts()
    }
  }

  onClickBookProduct = () => {
    let tmp = {
      client_id: this.props.data.selectCenterReducer.selectedCenter.center.client_id,
      consultant_id: this.props.data.selectCenterReducer.selectedCenter.center.consultant_id,
      user_id: this.props.data.profileReducer.profile.id,
      status_id: 5,
      product_id: this.props.selectedProduct.id,
      center_id: this.props.data.selectCenterReducer.selectedCenter.center.id,
      from_date: Moment( new Date() ).format( 'YYYY-MM-DD' )
    }
    this.props.onChangeProductsHOC( 'showBookConfirmation', false )
    this.props.createProductsRequest( tmp, this.props.selectedProduct.id, true )
  }

  render = () => {
    const {
      languageReducer
    } = this.props.data
    return(
      <>
        <div className="carousel-container">
          <div className="custom-card mb-2" style={{ height: '67px', flex: "0" }}>
            <span className="main-section-title">{ Lang[ 'PRODUCTS' ][ languageReducer.userSettings.admin_panel_language ] }</span>
          </div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={110}
            isIntrinsicHeight={ true }
            totalSlides={ this.props.allProducts ? Math.min( this.props.allProducts.length, 5 ): 0 }>
            <Slider>
              {
                this.props.allProducts && this.props.allProducts.map( ( product, index ) => {
                  if( index < 5 ) {
                    return(
                      <Slide index={ index } key={ index }>
                        <div className="custom-card">
                          <div className="custom-card-img-container" >
                            <img
                              className="custom-card-img"
                              src={ product.image_url || defaultLogo } 
                              alt="" 
                            />
                            <div className="custom-card-floating-bar">
                              {
                                product.is_most_booked_products && (
                                  <div className="tag">{ Lang[ 'MOST_BOOKED' ][ languageReducer.userSettings.admin_panel_language ] }</div>
                                )
                              }
                              {
                                product.is_new && (
                                  <div className="tag">{ Lang[ 'NEW_CAMPAIGN' ][ languageReducer.userSettings.admin_panel_language ] }</div>
                                )
                              }
                            </div>
                          </div>
                          <div className="custom-card-body">
                            <div>
                              <span className="custom-card-title">{ product.name[ languageReducer.userSettings.admin_panel_language ] }</span>
                              <div>
                                { 
                                  product.description[ languageReducer.userSettings.admin_panel_language ] && (
                                    product.description[ languageReducer.userSettings.admin_panel_language ].replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '').length > 50 
                                    ? product.description[ languageReducer.userSettings.admin_panel_language ].replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '').substr(0, 47) + '...' 
                                    : product.description[ languageReducer.userSettings.admin_panel_language ].replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')
                                  )
                                }
                                <br/>
                                <span className="custom-card-title">
                                  { product.currency_price_1[ languageReducer.userSettings.admin_panel_language ]  } { ' '}
                                  { product.currency_price_2[ languageReducer.userSettings.admin_panel_language ]  }
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="custom-card-footer">
                            {
                              product.pdf_url && (
                                <button
                                  className="filled-btn"
                                  style={{
                                    backgroundColor: '#FF0000',
                                    borderColor: '#FF0000'
                                  }}
                                  onClick={ () => window.open( product.pdf_url ) }>PDF</button>
                              )
                            }
                            <button
                              className="outline-btn--secondary"
                              onClick={ () => {
                                this.props.getSelectedProduct( product.id, false )
                              }}>{ Lang[ 'GENERAL_INFO' ][ languageReducer.userSettings.admin_panel_language ] }
                            </button>
                            <button 
                              disabled={ _.find( 
                                this.props.requestedProduct, 
                                { 
                                  product_id: product.id, 
                                  center_id: !_.isEmpty( this.props.data.selectCenterReducer.selectedCenter ) && this.props.data.selectCenterReducer.selectedCenter.center.id 
                                }
                              )}
                              className="btn pill-shaped"
                              onClick={ () => this.props.getSelectedProduct( product.id, true ) }>
                              { Lang[ 'BUY_NOW' ][ languageReducer.userSettings.admin_panel_language ] }
                            </button>
                          </div>
                        </div>
                      </Slide>
                    )
                  }
                })
              }
            </Slider>
            <div className="carousel-button-group">
              <ButtonBack className="transparent-btn">
                <i className="pe-7s-angle-left" />
              </ButtonBack>
              <div className="carousel-dot-list">
              {
                this.props.allProducts.map( (item, index) => {
                  if ( index < 5 ) {
                    return(
                      <Dot slide={ index } className=""/>
                    )
                  }
                })
              }
              </div>
              <ButtonNext className="transparent-btn">
                <i className="pe-7s-angle-right" />
              </ButtonNext>
            </div>
          </CarouselProvider>
          <div className="d-flex justify-content-center">
            <button
              className="transparent-btn"
              style={{
                color: 'initial',
                textDecoration: 'underline',
                margin: 'auto'
              }}
              onClick={ () => {
                this.props.onChangeProductsHOC( 'showAllProducts', true )
              }}>
              { Lang[ 'PRODUCTS' ][ languageReducer.userSettings.admin_panel_language ] }
            </button>
          </div>
        </div>
        <ViewInfo
          data={ this.props.data }
          selectedProduct={ this.props.selectedProduct }
          showInfo={ this.props.showInfo }
          onChangeProductsHOC={ this.props.onChangeProductsHOC }
          createProductsRequest={ this.props.createProductsRequest }
          onChangeRequestHOC={ this.props.onChangeRequestHOC }
          newRequest={ this.props.newRequest } />
        <ViewAll
          data={ this.props.data }
          allProducts={ this.props.allProducts }
          showAllProducts={ this.props.showAllProducts }
          onChangeProductsHOC={ this.props.onChangeProductsHOC }
          getSelectedProduct={ this.props.getSelectedProduct } />
        <BookConfirmationModal
          showPromptModal={ this.props.showBookConfirmation }
          content={ Lang[ 'BOOK_PRODUCT_CONFIRMATION' ][ languageReducer.userSettings.admin_panel_language ] }
          onClickYes={() => this.onClickBookProduct()}
          onClickNo={() => this.props.onChangeProductsHOC( 'showBookConfirmation', false ) }
          lang={ languageReducer.userSettings.admin_panel_language } 
        />
        { this.props.onLoadProducts && <LoadingIndicator /> }
      </>
    )
  }
}

export default compose(
  RequestHOC,
  ProductsHOC
)( Products )
