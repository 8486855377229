import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post } from 'utils/axios-token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      onLoadCenterDataDrive: false,
      mediaDrives: [],
      path: [
        {
          id: 1,
          name: 'Home'
        }
      ],
      newFolderFile: {
        file_name: '',
        type: 'File',
        parent_folder_id: 1
      },
      selectedFolderId: 0,
      showCreateModal: false,
      emailEditorSelectImage: () => {}
    }

    load = param => this.setState({ onLoadCenterDataDrive: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeCenterDataDriveHOC = ( key, value ) => this.setState({ [key]: value })

    getCenterMediaDrive = () => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/drive`,
      this.getCenterMediaDriveSuccess,
      this.getCenterMediaDriveError,
      this.load
    )
    getCenterMediaDriveSuccess = payload => this.setState({ 
      mediaDrives: payload.data,
      path: [
        {
          id: payload.data.parent_folder_id,
          name: 'Home'
        }
      ]
    })
    getCenterMediaDriveError = error => this.requestError( error )

    getSelectedDriveFolder = folder => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/drive/folders/${ folder.id }`,
      payload => this.getSelectedDriveFolderSuccess( payload, folder ),
      this.getSelectedDriveFolderError,
      this.load
    )
    getSelectedDriveFolderSuccess = ( payload, folder ) => {
      let tmpPath = _.cloneDeep( this.state.path )
      let tmpIndex = _.findIndex( tmpPath, { id: folder.id } )
      if( tmpIndex > -1 ){
        tmpPath = _.slice( tmpPath, 0, tmpIndex + 1 )
      } else {
        tmpPath.push({ 
          id: folder.id,
          name: folder.name
        })
      }
      this.setState({ 
        mediaDrives: payload,
        path: tmpPath,
        showCreateModal: false
      })
    }
    getSelectedDriveFolderError = error => this.requestError( error )

    getSelectedMedia = id => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/media/${ id }`,
      this.getSelectedMediaSuccess,
      this.getSelectedMediaError,
      this.load
    )
    getSelectedMediaSuccess = payload => this.setState({ selectedFileFolder: payload })
    getSelectedMediaError = error => this.requestError( error )

    createMedia = ( data ) => Post(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/media`,
      data,
      this.createMediaSuccess,
      this.createMediaError,
      this.load
    )
    createMediaSuccess = payload => {
      this.setState({ showCreateModal: false })
      this.props.onSelectedImage( payload.original.url )
      this.state.emailEditorSelectImage( payload.original.url )
      this.getSelectedDriveFolder( this.state.path[ this.state.path.length - 1 ] )
    }
    createMediaError = error => this.requestError( error )

    getSelectedFolder = id => Get(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/folders/${ id }`,
      this.getSelectedFolderSuccess,
      this.getSelectedFolderError,
      this.load
    )
    getSelectedFolderSuccess = payload => this.setState({ selectedFileFolder: payload })
    getSelectedFolderError = error => this.requestError( error )

    createMediaFolder = ( data, id ) => Post(
      `/api/v1/centers/${ this.props.data.selectCenterReducer.selectedCenter.center.id }/folders`,
      data,
      payload => this.createMediaFolderSuccess( payload, id ),
      this.createMediaFolderError,
      this.load
    )
    createMediaFolderSuccess = async() => await this.getSelectedDriveFolder( this.state.path[ this.state.path.length - 1 ] )
    createMediaFolderError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          { ...this.state }
          createMedia={ this.createMedia }
          onChangeCenterDataDriveHOC={ this.onChangeCenterDataDriveHOC }
          getCenterMediaDrive={ this.getCenterMediaDrive }
          getSelectedDriveFolder={ this.getSelectedDriveFolder }
          getSelectedMedia={ this.getSelectedMedia }
          createMediaFolder={ this.createMediaFolder }
          getSelectedFolder={ this.getSelectedFolder }
        />
      )
    }
  }
  return WithHOC
}

export default HOC
