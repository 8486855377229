import React from 'react'
import {
  Row,
  Col,
  Label,
  Button,
  Alert,
  FormGroup,
  Input
} from 'reactstrap'
import {
  AvForm
} from 'availity-reactstrap-validation'

const InputPassword = ({
  error,
  success,
  password,
  confirmPassword,
  token,
  sendPassword,
  onChangePassword,
  onChangeConfirmPassword,
  history
}) => {
  return (
    <AvForm>
      <Row form>
        {
          error !== '' && (
            <Col md={ 12 }>
              <Alert color="danger">
                { error }
              </Alert>
            </Col>
          )
        }
        {
          success !== '' && (
            <Col md={ 12 }>
              <Alert color="success">
                { success }
                {
                  window.location.href.indexOf( 'member' ) === -1 &&<Button 
                    color="success" 
                    onClick={() => history.push( '/' )} style={{ marginLeft: '10px' }}>
                    Zurück zum Login</Button>
                }
              </Alert>
            </Col>
          )
        }
        <Col md={ 6 }>
          <FormGroup>
            <Label for="exampleEmail">Password</Label>
            <Input
              type={ 'password' }
              value={ password }
              onChange={ e => {
                onChangePassword( e.target.value )
              }} />
          </FormGroup>
        </Col>
        <Col md={ 6 }>
          <FormGroup>
            <Label for="exampleEmail">Confirm Password</Label>
            <Input
              type={ 'password' }
              value={ confirmPassword }
              onChange={ e => {
                onChangeConfirmPassword( e.target.value )
              }} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="divider" />
      <div className="d-flex align-items-center">
        <div className="ml-auto">
          <Button color="link" onClick={() => history.push( '/' )}>Zurück zum Login</Button>
          <Button
            color="primary"
            onClick={() => {
              sendPassword( password, confirmPassword, token )
            }}>Submit</Button>
        </div>
      </div>
    </AvForm>
  )
}

export default InputPassword