import React from 'react'
import {
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { FilePond } from 'react-filepond'
import _ from 'lodash'

import Lang from 'Lang/General'

const Images = ({
  contents,
  contentName,
  imageKey,
  modalHeader,
  onChangeImagesHOC,
  showFilePondModal,
  updateImages,
  updateFbImages,
  updateGoogleImages,
  openModal,
  selectedLocation,
  selectedLanguage
}) => {
  return (
    <Modal isOpen={ showFilePondModal && openModal === modalHeader } size='xl' >
      <ModalHeader toggle={ () => onChangeImagesHOC( 'showFilePondModal', false ) }>
        { Lang[ 'UPLOAD_IMAGES' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        <FormGroup 
          tag="fieldset"
          style={{ padding: 10, marginBottom: 0 }}>
          <Label style={{ fontSize: 20 }}>{ modalHeader }</Label>
          <FilePond
            allowMultiple={ false }
            allowFileTypeValidation={ true }
            allowImagePreview={ true }
            acceptedFileTypes={[ 'image/png', 'image/gif' ]}
            onaddfile={ ( error, file ) => {
              const reader = new FileReader()
              reader.onload = e => {
                let tmp = _.cloneDeep( contents )
                if ( contentName === 'images' ) {
                  if ( tmp[ imageKey ] !== null && tmp[ imageKey ].length > -1 ) {
                    tmp[ imageKey ].push({
                      data: e.target.result
                    })
                  } 
                  else {
                    tmp[ imageKey ] = {
                      data: e.target.result
                    }
                  }
                } else {
                  tmp[ imageKey ] = {
                    image_data: e.target.result
                  }
                }
                onChangeImagesHOC( contentName, tmp )
              }
              reader.readAsDataURL( file.file )
            }}
            onremovefile={ e => {
              let tmp = _.cloneDeep( contents )
              onChangeImagesHOC( contentName, tmp )
            }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          style={{ marginRight: 10 }}
          onClick={ () => {
            onChangeImagesHOC( 'showFilePondModal', false ) 
          }} >
          { Lang[ 'CANCEL' ][ selectedLanguage ] }
        </Button>
        <Button
          color='primary'
          onClick={ () => {
            contentName === 'images' && updateImages( selectedLocation.id, contents ) 
            contentName === 'fbImages' && updateFbImages( selectedLocation.id, contents ) 
            contentName === 'googleImages' && updateGoogleImages( selectedLocation.id, contents )
            onChangeImagesHOC( 'showFilePondModal', false )
          }}>
          { Lang[ 'SUBMIT' ][ selectedLanguage ] }
        </Button>
      </ModalFooter>
    </Modal>   
  )
}

export default Images